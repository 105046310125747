import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import novel2Css from '../../css/novelCreate2.module.css'
import URL from "../../constants/url";
import { apiServerState } from "../../constants/recoilData";
import defaultImg from "../../css/images/illustDefault.png";


// 내그림 선택 컴포넌트
const NovelCreate2SectionList = ({setImgMy, setImgId}) => {
    
    const [isMounted, setIsMounted] = useState(false);
    const [imgList, setImgList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const paginationTag = [];
    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }


    const config = {
        headers: {
            "Content-Type": "application/json",
            "authorization": token,
        }
    };

    // 내 그림 api 호출 함수
    const myImgListAxios = async (pageNum) => {
        const url = apiServer + "/api/asiac_novel/get_my_imgs?page=" + pageNum + "&row=8";

        axios
        .get(url, config)
        .then((response) => {
            if (response?.data?.success ) {
                setImgList(response?.data?.data?.imgList);
                setTotalPage(response?.data?.data?.totalPages);
                setIsMounted(true);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                    alert("다시 로그인 해주세요.");
                    logout();
                    navigate(URL.LOGIN);
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    navigate(URL.MAIN);
                } else {
                    alert(msg);
                    navigate(URL.MAIN);
                }
                console.log("로딩 실패")
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    };

    // 내 그림 api 호출 함수(pageNum)
    const myImgList = async (pageNum) => {
        const url = apiServer + "/api/asiac_novel/get_my_imgs?page=" + pageNum + "&row=8";

        await axios
        .get(url, config)
        .then((response) => {
            if (response?.data?.success ) {
                setImgList(response?.data?.data?.imgList);
                setTotalPage(response?.data?.data?.totalPages);
                setIsMounted(true);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                    alert("다시 로그인 해주세요.");
                    logout();
                    navigate(URL.LOGIN);
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    navigate(URL.MAIN);
                } else {
                    alert(msg);
                    navigate(URL.MAIN);
                }
                console.log("로딩 실패")
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    };

    // ====================  페이징 처리  ====================

    const currentPageNo = page;
    const pageSize = 5;
    const totalPageCount = totalPage;

    const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
    let currentLastPage = currentFirstPage + pageSize - 1;

    currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

    if (totalPageCount > pageSize) {
        // 첫 페이지 이동
        const firstPageTag = <><PaginationLink onClick={() => {pageCall(1)}}>&lt;&lt;</PaginationLink></>;
        paginationTag.push(firstPageTag);

        // 이전 페이지 이동
        const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
        const previousPageTag = <><PaginationLink onClick={() => {pageCall(prevPageIndex)}}>&lt;</PaginationLink></>;
        paginationTag.push(previousPageTag);
    }

    for (let i = currentFirstPage; i <= currentLastPage; i++) {
        if (i === currentPageNo) {
            // 현재 페이지
            const currentPage = <><PaginationLink className={novel2Css.current_page + " current_page current-page"} onClick={() => {pageCall(i)}}>{i}</PaginationLink></>;
            paginationTag.push(currentPage);
        } else {
            // 다른 페이지
            const otherPage = <><PaginationLink onClick={() => {pageCall(i)}}>{i}</PaginationLink></>;
            paginationTag.push(otherPage);
        }
    }
    if (totalPageCount > pageSize) {
        // 다음 페이지 이동
        const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
        const nextPageTag = <><PaginationLink onClick={() => {pageCall(nextPageIndex)}}>&gt;</PaginationLink></>;
        paginationTag.push(nextPageTag);
        
        // 마지막 페이지 이동
        const lastPageTag = <><PaginationLink onClick={() => {pageCall(totalPage)}}>&gt;&gt;</PaginationLink></>;
        paginationTag.push(lastPageTag);
    }

    const pageCall = (pageNum) => {
        setImgList([]);
        setPage(pageNum);
        myImgListAxios(pageNum);
    }

    // ======================  페이징 처리 끝  ===========================


    // 라디오 버튼 선택
    const handleClickRadioBtn = (e) => {
        const img = e.target.value
        const imgId = e.target.id
        setImgMy(img);
        setImgId(imgId);

    }


    // 최초 랜더링시 이미지 리스트 셋팅
    useEffect(() => {
        myImgList(1);
    }, []);

    const noImage = (e) => {
        e.target.src = defaultImg
    }


    return (
        <>
            {isMounted === true ? <DefaultDiv className={novel2Css.illu_me_list + " illu_me_list illu-me-list"}>
                {imgList && imgList.map((img) => {return(
                    <IlluMeListColDiv className={novel2Css.col + " col"} key={"imgKey" + img?.id}>
                        <IlluMeListColImg src={img?.path !== '' ? img?.path : defaultImg} aria-valuetext={img?.path} alt={`illu_${img?.id}`} onError={noImage}/>
                        <IlluMeRadioInput
                            type="radio"
                            name="option"
                            value={img?.path}
                            id={img?.id}
                            className="illu-me-radio"
                            onChange={handleClickRadioBtn}
                        />
                    </IlluMeListColDiv>
                )})}
                <PaginationDiv className={novel2Css.pagination + " pagination"}>
                    {paginationTag}
                </PaginationDiv>
            </DefaultDiv> : <></>}
        </>
    );
};

export default NovelCreate2SectionList;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** div 태그 .illu-me-list > .col css */
const IlluMeListColDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-right: 3px;
    display: inline-grid;
`;

/** img 태그 .illu-me-list > .col > img css */
const IlluMeListColImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    max-height: 200px;
    margin-top: 20px;
    @media (min-width: 100px) and (max-width: 768px) {
        width: 100px; /* 이미지 크기 조정 */
        height: 100px;
    }
`;

/** input 태그 .illu-me-radio css */
const IlluMeRadioInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 8px !important;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    margin-bottom: 8px;
`;

/** div 태그 .pagination css */
const PaginationDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

/** a 태그 .pagination a css */
const PaginationLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    text-decoration: none;
    margin: 5px;
    padding: 5px 10px;
    color: #333;
    font-family: "Min Sans-Light";
    font-size: 14px;
    &:hover {
        background-color: #fff;
        color: #ff5f37;
    }
`;