import { Link, useNavigate } from "react-router-dom";
import URL from "../../constants/url";
import novel1Css from '../../css/novelCreate1.module.css';
import styled from "@emotion/styled";


const LeftAsideNavMenu = () => {
    const navigate = useNavigate();

    const onClickMain = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.MAIN)
    }
    
    const onClickVeteran = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.LIST_VETERAN);
    }
    
    const onClickFreezon = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.LIST_FREEZON);
    }
    
    const onClickDrawingStore = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.LIST_DRAWING_STORE)
    }
    
    const onClickNotice = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.LIST_NOTICE);
    }
    
    const onClickCreateMain = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.CREATE_MAIN);
    }
    
    const onClickChargeShop = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.LIST_CHARGE_SHOP);
    }
    
    const onClickMyPage = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.MY_PAGE_MY_INFO);
    }

    return(
        <>
            <ListGroupDiv className={novel1Css.list_group + " list_group list-group"}>
                <div onClick={onClickMain} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>랭킹</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickFreezon} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>프리존</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickVeteran} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>베테랑</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickDrawingStore} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>그림스토어</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickCreateMain} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"} >▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>집필실</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickNotice} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>공지사항</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickChargeShop} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>충전소</DefaultDiv>
                    </ListLink>
                </div>
                <div onClick={onClickMyPage} style={{marginBottom : "0px"}}>
                    <ListLink className={novel1Css.list + " list"} style={{marginBottom : "0px"}}>
                        <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                        <DefaultDiv className={novel1Css.title + " title"}>마이페이지</DefaultDiv>
                    </ListLink>
                </div>
            </ListGroupDiv>
        </>
    );
};
export default LeftAsideNavMenu;

/** div 태그 .list-group css */
const ListGroupDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    &:hover {color: #ff5f37;}
    cursor: pointer;
`;

/** a 태그 .list css */
const ListLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    display: flex;
    color: #fff;
    margin-bottom: 0px;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    &:first-of-type {border-top: 1px solid #2c2c34;}
    &:last-of-type {margin-bottom: 42px;}
    cursor: pointer;
    &:hover {color: #ff5f37;}
`;

/** div 태그 .cate css */
const CateDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-right: 4px;
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;