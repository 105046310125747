import { Link, useNavigate } from "react-router-dom";
import sign2Css from '../../css/sign2.module.css'
import styled from "@emotion/styled";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import URL from "../../constants/url";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { apiServerState, businessServiceKeyState } from "../../constants/recoilData";
import Swal from "sweetalert2";
import novel2Css from'../../css/novelCreate2.module.css';
import join_2Css from '../../css/join_2.module.css';
import $ from 'jquery';
import bank_nh from '../../css/images/bank_img/nh.png';
import bank_kb from '../../css/images/bank_img/kb.png';
import bank_ka from '../../css/images/bank_img/ka.png';
import bank_wr from '../../css/images/bank_img/wr.png';
import bank_ib from '../../css/images/bank_img/ib.png';
import bank_to from '../../css/images/bank_img/to.png';
import bank_sh from '../../css/images/bank_img/sh.png';
import bank_ha from '../../css/images/bank_img/ha.png';
import bank_k from '../../css/images/bank_img/k.png';
import bank_gj from '../../css/images/bank_img/gj.png';
import bank_ci from '../../css/images/bank_img/ci.png';
import bank_sc from '../../css/images/bank_img/sc.png';
import bank_su from '../../css/images/bank_img/su.png';
import bank_sj from '../../css/images/bank_img/sj.png';
import bank_ke from '../../css/images/bank_img/ke.png';
import bank_wo from '../../css/images/bank_img/wo.png';
import bank_sm from '../../css/images/bank_img/sm.png';
import bank_bu from '../../css/images/bank_img/bu.png';
import bank_dg from '../../css/images/bank_img/dg.png';


const Sign2 = () => {

    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);

    const [nameRegExCheckBool, setNameRegExCheckBool] = useState(true);
    const [nameRegExCheckMessage, setNameRegExCheckMessage] = useState("");

    // 이름 정규식 체크
    const nameRegExCheck = (e) => {
        const nameRegEx = new RegExp(/^[가-힣].{1,9}$/)
        const userName = e.target.value;
        const bool = nameRegEx.test(userName);
        if (bool) {
            setNameRegExCheckMessage("사용할수 있는 이름입니다.");
        } else {
            setNameRegExCheckMessage("사용할수 없는 이름입니다. 이름은 한글 2~10자만 가능합니다.");
        }
        setNameRegExCheckBool(bool);
    }

    const [emailRegExCheckBool, setEmailRegExCheckBool] = useState(true);
    const [emailRegExCheckMessage, setEmailRegExCheckMessage] = useState("");
    const emailCheckRef = useRef();

    // 이메일 정규식 체크
    const emailRegExCheck = (e) => {
        setEmailCheckMessage('');
        const emailRegEx = new RegExp(/^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        const userEmail = e.target.value;
        const bool = emailRegEx.test(userEmail);
        if (bool) {
            setEmailRegExCheckMessage("사용할수 있는 이메일(아이디)입니다.");
        } else {
            setEmailRegExCheckMessage("이메일이 바르지 않습니다");
        }
        if (userEmail === '') {
            setEmailRegExCheckMessage('');
        }
        setEmailRegExCheckBool(bool);
    }

    const [nickNameRegExCheckBool, setNickNameRegExCheckBool] = useState(true);
    const [nickNameRegExCheckMessage, setNickNameRegExCheckMessage] = useState("");

    // 닉네임 정규식 체크
    const nickNameRegExCheck = (e) => {
        setNickNameCheckMassege('');
        const nickNameRegEx = new RegExp(/^[가-힣a-zA-Z0-9]{2,10}$/)
        const userNickName = e.target.value;
        const bool = nickNameRegEx.test(userNickName);
        if (bool) {
            setNickNameRegExCheckMessage("사용가능한 필명 양식 입니다.");
        } else {
            setNickNameRegExCheckMessage("필명이 바르지 않습니다");
        }
        if (userNickName === '') {
            setNickNameRegExCheckMessage('');
        }
        setNickNameRegExCheckBool(bool);
    }

    const [passwordRegExCheckBool, setPasswordRegExCheckBool] = useState(true);
    const [passwordRegExCheckMessage, setPasswordRegExCheckMessage] = useState("");

    // 비밀번호 정규식 체크
    const passwordRegExCheck = (e) => {
        const passwordRegEx = new RegExp(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*\d).{8,20}$/)
        const userPassword = e.target.value;
        const bool = passwordRegEx.test(userPassword);
        if (bool) {
            setPasswordRegExCheckMessage("사용할수 있는 비밀번호입니다.");
        } else {
            setPasswordRegExCheckMessage("비밀번호가 바르지 않습니다. 비밀번호는 숫자 + 영어 + 특수문자(!@#$%^&*) 8~20자만 가능합니다.");
        }
        if (userPassword === '') {
            setPasswordRegExCheckMessage('');
        } 
        setPasswordRegExCheckBool(bool);
        if (userPassword !== password2) {
            setPassword2RegExCheckMessage("비밀번호가 다릅니다.");
            setPassword2RegExCheckBool(false);
        } else {
            setPassword2RegExCheckMessage("");
            setPassword2RegExCheckBool(true);
        }
    }

    const [password2RegExCheckBool, setPassword2RegExCheckBool] = useState(true);
    const [password2RegExCheckMessage, setPassword2RegExCheckMessage] = useState("");

    // 비밀번호 확인, 비밀번호 동일여부 체크
    const password2RegExCheck = (e) => {
        const userPassword2 = e.target.value;
        if (password === userPassword2) {
            setPassword2RegExCheckMessage("");
            setPassword2RegExCheckBool(true);
        } else {
            setPassword2RegExCheckMessage("비밀번호가 다릅니다.");
            setPassword2RegExCheckBool(false);
        }

    }

    const token = getCookie('token');

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 토큰 검사
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        const token = getCookie('token');
        if (token !== undefined) {
            navigate(URL.MAIN);
        }
    }, [])

    const [name, setName] = useState('');
    // 이름 변경 입력 함수
    const onChangeName = (e) => {
        setName(e.target.value);
    };

    const [nickName, setNickName] = useState('');
    // 닉네임 변경 입력 함수
    const onChangeNickName = (e) => {
        setNickName(e.target.value);
        setNickNameCheck(false);
        nickNameCheckRef.current.style.display = 'block';
    };

    const [nickNameCheck, setNickNameCheck] = useState(false);
    const [nickNameCheckMassege, setNickNameCheckMassege] = useState('');
    const nickNameCheckRef = useRef();

    // 닉네임 중복여부 체크 버튼
    const onClickNickNameCheck = () => {

        if (nickName !== '') {
            if (nickNameRegExCheckBool) {
                const form = {nickname : nickName};
    
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };
    
                axios
                .post(apiServer + "/api/asiac_login/check_nickname", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        nickNameCheckRef.current.style.display = "none";
                        setNickNameCheckMassege("사용가능한 필명 입니다.")
                        setNickNameCheck(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === 'duplicate nickname') {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("중복된 닉네임 입니다.");
                        } else if (msg === 'invalid nickname') {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("잘못된 형식의 닉네임 입니다.");
                        } else if (msg === 'router err') {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("서버오류");
                        } else {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("서버오류");
                        }
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            }
        } 

    }

    const [email, setEmail] = useState('');
    // 이메일 변경 입력 함수
    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        emailCheckRef.current.style.display = "block";
    };

    const [emailCheck, setEmailCheck] = useState(false);
    const [emailCheckMessage, setEmailCheckMessage] = useState('');

    // 이메일 중복여부 체크 버튼
    const onClickEmailCheck = () => {
        if (email !== '') {
            if (emailRegExCheckBool) {
                const form = {email : email};
    
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };
    
                axios
                .post(apiServer + "/api/asiac_login/check_email", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        emailCheckRef.current.style.display = "none";
                        setEmailCheckMessage("사용가능한 email 입니다.")
                        setEmailCheck(true);
                    } else {
                        if (response?.data?.custMsg === 'duplicate user') {
                            emailCheckRef.current.style.display = "none";
                            setEmailCheckMessage("중복된 email 입니다.");
                        } else if (response?.data?.custMsg === 'invalid email') {
                            emailCheckRef.current.style.display = "none";
                            setEmailCheckMessage("잘못된 형식의 email 입니다.");
                        } else if (response?.data?.custMsg === 'router err'){
                            emailCheckRef.current.style.display = "none";
                            setEmailCheckMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        } else {
                            emailCheckRef.current.style.display = "none";
                            setEmailCheckMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        }
                        console.log(response?.data?.custMsg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            }
        } 
    }

    const [password, setPassword] = useState('');
    // 비밀번호 변경 입력 함수
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const [password2, setPassword2] = useState('');
    // 비밀번호 확인 변경 입력 함수
    const onChangePassword2 = (e) => {
        setPassword2(e.target.value);
    };

    const [errorMessage, setErrorMessage] = useState('');

    // 회원가입 버튼
    const onClickSign = () => {
        var formData = new FormData();
        const nameCheck = (String(name)).trim();
        if (nameCheck === "") {
            alert("이름을 입력해주세요.");
            return;
        }
        if (name === '') {
            alert("이름을 입력해주세요.");
            return;
        } else if (name === null || name === undefined) {
            alert("이름을 입력해주세요.");
            return;
        }
        if (nameRegExCheckBool === false) {
            alert("이름이 올바르지 않습니다.");
            return;
        }
        if (nickName === '') {
            alert("필명을 입력해주세요.");
            return;
        } else if (nickName === null || nickName === undefined) {
            alert("필명을 입력해주세요.");
            return;
        }
        if (nickNameCheck === false) {
            alert("필명 중복검사를 해주세요.");
            return;
        }

        if (ikind === null || ikind === undefined) {
            alert("가입 유형을 선택해주세요.");
            return;
        } else {
            if (authorKind === null || authorKind === undefined) {
                alert("작가 유형을 선택해주세요.");
                return;
            }
            if (ikind !== "normal") {
                if (authorKind === "") {
                    alert("작가 유형을 선택해주세요.");
                    return;    
                }
            }
            if (authorKind === "solo_proprietor") {
                const coRegNoCheck = (String(coRegNo)).trim();
                if (coRegNoCheck === "") {
                    alert("사업자 등록번호를 입력 해주세요.");
                    return;
                }
                if(coRegNo === "") {
                    alert("사업자 등록번호를 입력 해주세요.");
                    return;
                } else if (coRegNo === null || coRegNo === undefined) {
                    alert("사업자 등록번호를 입력 해주세요.");
                    return;
                }
                if(coRegNoCheck === false) {
                    alert("사업자 등록번호 인증검사를 해주세요.");
                    return;
                }
                if(accountImgFile === null) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;
                }
            } else if (authorKind === "corp_business") {
                const coRegNoCheck2 = (String(coRegNo)).trim();
                if (coRegNoCheck2 === "") {
                    alert("사업자 등록번호를 입력 해주세요.");
                    return;
                }
                if(coRegNo === "") {
                    alert("사업자 등록번호를 입력 해주세요.");
                    return;
                } else if (coRegNo === null || coRegNo === undefined) {
                    alert("사업자 등록번호를 입력 해주세요.");
                    return;
                }
                if(coRegNoCheck === false) {
                    alert("사업자 등록번호 인증검사를 해주세요.");
                    return;
                }
                const corpRegNoCheck = (String(corpRegNo)).trim();
                if (corpRegNoCheck === "") {
                    alert("법인 이름을 입력해주세요.");
                    return;
                }
                if (corpRegNo === '') {
                    alert("법인 이름을 입력해주세요.");
                    return;
                } else if (corpRegNo === null || corpRegNo === undefined) {
                    alert("법인 이름을 입력해주세요.");
                    return;
                }
                if(accountImgFile === null) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;
                }
            }
            const imgFile = $("#file-upload")[0].files[0];
            if (imgFile === null) {
                alert("사업자 통장 사본을 입력해주세요.");
                return;
            }
        }
        const emailCheck2 = (String(email)).trim();
        if (emailCheck2 === "") {
            alert("이메일을 입력해주세요.");
            return;
        }
        if (email === '') {
            alert("이메일을 입력해주세요.");
            return;
        } else if (email === null || email === undefined) {
            alert("이메일을 입력해주세요.");
            return;
        }
        if (emailCheck === false) {
            alert("이메일 중복검사를 해주세요.");
            return;
        }
        if (emailRegExCheckBool === false) {
            alert("이메일이 올바르지 않습니다.");
            return;
        }
        if(password === '') {
            alert("비밀번호를 입력해주세요.");
            return;
        } else if (password === null || password === undefined) {
            alert("비밀번호를 입력해주세요.");
            return;
        }
        if (passwordRegExCheckBool === false) {
            alert("비밀번호가 올바르지 않습니다.");
            return;
        }
        if(password2 === '') {
            alert("비밀번호 확인을 입력해주세요.");
            return;
        } else if (password2 === null || password2 === undefined) {
            alert("비밀번호 확인을 입력해주세요.");
            return;
        }
        if (password2RegExCheckBool === false) {
            alert("비밀번호가 다릅니다.");
            return;
        }

        if (password === password2) {
            const imgFile = $("#file-upload")[0].files[0];
            formData.append("name", name);
            formData.append("nickName", nickName);
            formData.append("email", email);
            formData.append("password", password);
            formData.append("ikind", ikind);
            formData.append("proficiency", proficiency);
            
            if(ikind === "normal") {
                if (bankName === "") {
                    if (accountNumber !== "") {
                        formData.append("accountNumber", accountNumber);
                    }
                } else {
                    formData.append("bankName", bankName);
                    formData.append("bankCode", bankCode);
                    if (accountNumber !== "") {
                        if(accountNumberRegCheck === false) {
                            alert("계좌 번호는 숫자만 가능합니다.");
                            return;
                        } else {
                            formData.append("accountNumber", accountNumber);
                        }
                    }
                }
                
            } else {
                if(authorKind === "solo_free") {
                    formData.append("authorKind", authorKind);
                    if(bankName === "") {
                        if(accountNumber !== "") {
                            formData.append("accountNumber", accountNumber);
                        }
                    } else {
                        formData.append("bankName", bankName);
                        formData.append("bankCode", bankCode);
                        if(accountNumber !== "") {
                            if(accountNumberRegCheck === false) {
                                alert("계좌 번호는 숫자만 가능합니다.");
                                return;
                            } else {
                                formData.append("accountNumber", accountNumber);
                            }
                        }
                    }
                } else if (authorKind === "solo_proprietor") {
                    formData.append("coRegNo", coRegNo);
                    formData.append("image", imgFile);
                    formData.append("authorKind", authorKind);
                    if(bankName === "") {
                        if(accountNumber !== "") {
                            formData.append("accountNumber", accountNumber);
                        }
                    } else {
                        formData.append("bankName", bankName);
                        formData.append("bankCode", bankCode);
                        if(accountNumber !== "") {
                            if(accountNumberRegCheck === false) {
                                alert("계좌 번호는 숫자만 가능합니다.");
                                return;
                            } else {
                                formData.append("accountNumber", accountNumber);
                            }
                        }
                    }
                } else if (authorKind === "corp_business") {
                    formData.append("coRegNo", coRegNo);
                    formData.append("corpRegNo", corpRegNo);
                    formData.append("image", imgFile);
                    formData.append("authorKind", authorKind);
                    if(bankName === "") {
                        if(accountNumber !== "") {
                            formData.append("accountNumber", accountNumber);
                        }
                    } else {
                        formData.append("bankName", bankName);
                        formData.append("bankCode", bankCode);
                        if (accountNumber !== "") {
                            if(accountNumberRegCheck === false) {
                                alert("계좌 번호는 숫자만 가능합니다.");
                                return;
                            } else {
                                formData.append("accountNumber", accountNumber);
                            }
                        }
                    }
                }
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "authorization": token,
                }
            };

            axios
            .post(apiServer + "/api/asiac_login/register", formData, config)
            .then((response) => {
                if (response?.data?.success ) {
                    Swal.fire({
                        title : "신규 가입자 보상",
                        text: "신규 가입자 이벤트 5000코인이 적립되었습니다 (사용기한 30일)",
                        confirmButtonColor: "#FF5F37",
                        confirmButtonText: "확인",
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.MAIN);
                        } 
                    });
                } else {
                    const msg = response?.data?.custMsg;
                    if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해 주세요.");
                        logout();
                    } else if(msg === 'invalid email') {
                        setErrorMessage("email을 다시 입력해주세요.");
                    } else if (msg === 'invalid password') {
                        setErrorMessage("비밀번호를 다시 입력해주세요.");
                    } else if (msg === 'invalid name or nickanme') {
                        setErrorMessage("닉네임을 다시 입력해주세요.");
                    } else if (msg === 'invalid info') {
                        setErrorMessage("가입유형을 입력해주세요.");
                    } else if (msg === 'duplicate user') {
                        setErrorMessage("중복된 회원입니다.")
                    } else if (msg === 'router err'){
                        setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        } 
    }
    const [ikind, setIkind] = useState(null);
    const [proficiency, setProficiency] = useState(null);
    const typeBtnRef = useRef([]);

    // 가입유형 선택 버튼
    const onClickActionBtn = (e) => {
        $('.actionBtn').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setIkind(val);
        if (val === "author") {
            authorKindTypeRef.current.style.display = "block";
        } else {
            $('.writerBtn').removeClass("active");
            authorKindTypeRef.current.style.display = "none";
            coRegNoRef.current.style.display = "none";
            corpRegNoRef.current.style.display = "none";
            fileRef.current.style.display = "none";
            setAuthorKind("");
            setCoRegNo("");
            setCorpRegNo("");
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("novice");
        }
    }

    const [authorKind, setAuthorKind] = useState(null);
    const authorKindTypeRef = useRef();
    const authorKindBtnRef = useRef([]);

    // 작가 유형 선택 버튼
    const onClickWriterTypeBtn = (e) => {
        $('.writerBtn').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setAuthorKind(val);
        if (val === "solo_proprietor") {
            coRegNoRef.current.style.display = "block";
            corpRegNoRef.current.style.display = "none";
            fileRef.current.style.display = "block";
            setCoRegNo("");
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("veteran");
        } else if (val === "corp_business") {
            coRegNoRef.current.style.display = "block";
            corpRegNoRef.current.style.display = "block";
            fileRef.current.style.display = "block";
            setCoRegNo("");
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("veteran");
        } else {
            coRegNoRef.current.style.display = "none";
            corpRegNoRef.current.style.display = "none";
            fileRef.current.style.display = "none";
            setCoRegNo("");
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("novice");
        }
    }


    const [coRegNo, setCoRegNo] = useState('');
    const coRegNoRef = useRef();

    // 사업자 번호 변경 입력 함수
    const onChangecoRegNo = (e) => {
        setCoRegNo(e.target.value);
        setCoRegNoCheck(false);
    };

    const [coRegNoCheck, setCoRegNoCheck] = useState(false);
    const [coRegNoCheckMessage, setCoRegNoCheckMessage] = useState('');
    const serviceKey = useRecoilValue(businessServiceKeyState);

    // 사업자 번호 유효성 검사 버튼
    const onClickCoRegNoCheckCheck = () => {
        let form;
        if (coRegNo === null) {
            alert("사업자 등록번호를 입력해주세요.");
            return;
        } else if (coRegNo === "") {
            alert("사업자 등록번호를 입력해주세요.");
            return;
        } else {
            form = {
                "b_no": [
                    coRegNo
                ]
            }
        }
    
        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };

        const url = "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=" + serviceKey

        axios
        .post(url, form, config)
        .then((response) => {
            if (response?.data?.status_code === "OK") {
                const b_stt_cd = response?.data?.data[0].b_stt_cd;
                if(b_stt_cd === "01") {
                    setCoRegNoCheckMessage("사용가능한 사업자 번호 입니다.")
                    setCoRegNoCheck(true);
                } else if (b_stt_cd === "02") {
                    setCoRegNoCheckMessage("휴업 사업자 번호로 사용불가한 사업자 번호 입니다.");
                    setCoRegNoCheck(false);
                } else if (b_stt_cd === "03") {
                    setCoRegNoCheckMessage("폐업 사업자 번호로 사용불가한 사업자 번호 입니다.");
                    setCoRegNoCheck(false);
                } else if (b_stt_cd === "") {
                    setCoRegNoCheckMessage("국세청에 등록되지 않은 사업자등록번호입니다.");
                    setCoRegNoCheck(false);
                }
            } else {
                setCoRegNoCheckMessage("사용불가 사업자 번호 입니다.");
                setCoRegNoCheck(false);
                console.log(response?.data?.custMsg);
            }
        })
        .catch((e) => {
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });

    }

    const [corpRegNo, setCorpRegNo] = useState('');
    const corpRegNoRef = useRef();
    // 법인 이름 변경 입력 함수
    const onChangeCorporationNumber = (e) => {
        setCorpRegNo(e.target.value);
    }

    // 사업자 번호 정규식 체크(숫자)
    const coRegNoRegExCheck = (e) => {
        setCoRegNoCheckMessage('');
        const coRegNoRegEx = new RegExp(/^[0-9]+$/)
        const coRegNo = e.target.value;
        const bool = coRegNoRegEx.test(coRegNo);
        if (bool) {
            setCoRegNoCheckMessage("정상적인 사업자 번호 양식입니다.");
        } else {
            setCoRegNoCheckMessage("사업자 번호는 숫자만 가능합니다.");
        }
        if (coRegNo === '') {
            setCoRegNoCheckMessage('');
        }
    }


    const fileRef = useRef();

    const [accountImgFile, setAccountImgFile] = useState(null);
    
    // 사업자 통장 사본 이미지 파일 업로드
    const onChangeUpdateFileName = () => {
        var input = $("#file-upload")[0];
        var fileName = $("#file-name")[0];
        var fileImg = $("#file_img");
        
        if(input.files && input.files[0]) {
            var reader = new FileReader();
            
            reader.onload = function (e) {
                if (input.files[0].type.includes("image")) {
                    fileImg.attr("src", e.target.result);
                    setAccountImgFile(input.files[0]);
                }
                
                fileName.innerHTML = input.files[0].name;
            };
            
            reader.readAsDataURL(input.files[0]);
        } else {
            fileName.innerHTML = "파일을 업로드해주세요";
        }
    }


    const [bankName, setBankName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberRegMsg, setAccountNumberRegMsg] = useState("");
    const [accountNumberRegCheck, setAccountNumberRegCheck] = useState(true);
    const [bankCode, setBankCode] = useState("");
    const [bankImg, setBankImg] = useState(null);

    // 정산은행 셋팅 함수
    const onClickBankName = (name, code, img) => {
        setBankName(name);
        setBankCode(code);
        setBankImg(img);
        dropdownContentRef.current.style.display = "none";
    }

    const dropdownContentRef = useRef();
    const dropdown = useRef();


    // 드롭다운 열기/닫기 버튼
    const onClickDropdownSelected = () => {
        if(dropdownContentRef.current.style.display === "none") {
            dropdownContentRef.current.style.display = "block";
        } else {
            dropdownContentRef.current.style.display = "none";
        }
    }

    // 모달 외 클릭시(닫기)
    useEffect(() => {
        const handleClick = (e) => {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(e.target)) {
                if(dropdown.current && !dropdown.current.contains(e.target)) {
                    dropdownContentRef.current.style.display = "none";
                }
            }
        };
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, [dropdownContentRef]);

    // 계좌번호 변경 입력 함수
    const onChangeAccountNumber = (e) => {
        setAccountNumber(e.target.value);
    }

    // 계좌번호 정규식 체크(숫자)
    const accountNumberExCheck = (e) => {
        const accountNumberRegEx = new RegExp(/^\d{1,20}$/)
        const accountNumber = e.target.value;
        const bool = accountNumberRegEx.test(accountNumber);
        if (bool) {
            setAccountNumberRegMsg("정상적인 계좌 번호 양식입니다.");
        } else {
            setAccountNumberRegMsg("계좌 번호는 20자리이내 숫자만 가능합니다.");
        }
        if (accountNumber === '') {
            setAccountNumberRegMsg('');
        }
        setAccountNumberRegCheck(bool);
    }
    
    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    return(
        <>
            <div id="wrap" >
                <JoinContainerDiv id="joinContainer">
                    <JoinBoxDiv className={sign2Css.joinBox + " joinBox"}>
                        <TitleDiv className={sign2Css.title + " title"}>
                            <TitleH4H4>아이작 회원가입</TitleH4H4>
                            <TitleH2H2>회원정보 입력</TitleH2H2>
                        </TitleDiv>
                        <UserInputDiv className={sign2Css.userInput + " userInput"}>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <LobelLabel htmlFor="realName" className={sign2Css.label + " label"}>이름(실명)</LobelLabel>
                                <UserInputTextInput type="text" id="realName" placeholder="반드시 실명을 입력해주세요" style={{width: "100%"}} onChange={(e) => {onChangeName(e); nameRegExCheck(e)}} value={name}/>
                                {nameRegExCheckBool === false ? <div className={sign2Css.descript + " descript"} style={{marginTop : "5px"}}>
                                    <DescriptSpanSpan >{nameRegExCheckMessage}</DescriptSpanSpan>
                                </div> : <></>}
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <div className={sign2Css.descript + " descript"}>
                                    <LobelLabel htmlFor="nickname" className={sign2Css.label + " label"}>활동할 필명을 입력해주세요</LobelLabel>
                                    <DescriptSpanSpan>한글/영어/숫자만 가능 최소 2자~최대10자</DescriptSpanSpan>
                                </div>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <UserInputTextInput type="text" id="nickname" placeholder="필명을 입력해주세요" onChange={(e) => {onChangeNickName(e); nickNameRegExCheck(e);}} value={nickName}/>
                                    <CheckButtonButton id="nicknameCheck" className={sign2Css.checkButton + " checkButton"} onClick={onClickNickNameCheck}>중복검사</CheckButtonButton>
                                </OverlapDiv>
                                <div className={sign2Css.descript + " descript"} ref={(el) => {nickNameCheckRef.current = el}}>
                                    {nickNameRegExCheckMessage === '사용가능한 필명 양식 입니다.' ? <DescriptSpanSpan style={{color: "green"}}>{nickNameRegExCheckMessage}</DescriptSpanSpan> : nickNameRegExCheckMessage === '' ? <></> : <DescriptSpanSpan>{nickNameRegExCheckMessage}</DescriptSpanSpan>}
                                </div>
                                {nickNameCheckMassege !== '' ? <div className={sign2Css.descript + " descript"}>
                                    {nickNameCheckMassege === '사용가능한 필명 입니다.' ? <DescriptSpanSpan style={{color: "green"}}>{nickNameCheckMassege}</DescriptSpanSpan> :
                                    <DescriptSpanSpan>{nickNameCheckMassege}</DescriptSpanSpan>}
                                </div> : <></>}
                            </InputRowDiv>
                            <DefaultHr/>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} >
                                <div className={sign2Css.descript + " descript"}>
                                    <LobelLabel htmlFor="nickname" className={sign2Css.label + " label"}>가입 유형을 선택해주세요</LobelLabel>
                                    <DescriptSpanSpan>유형은 마이페이지에서 수정하실 수 있습니다.</DescriptSpanSpan>
                                </div>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn"} id={"type0"} ref= {(el) => {typeBtnRef.current[0] = el}} value={"author"} onClick={(e) => {onClickActionBtn(e)}} >작가로 가입해요.</CategoryBtnButton>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn"} id={"type1"} ref= {(el) => {typeBtnRef.current[1] = el}} value={"normal"} onClick={(e) => {onClickActionBtn(e)}} >독자로 가입해요.</CategoryBtnButton>
                                </OverlapDiv>
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(el) => authorKindTypeRef.current = el} style={{display : "none"}}>
                                <div className={sign2Css.descript + " descript"}>
                                    <LobelLabel htmlFor="nickname" className={sign2Css.label + " label"}>작가 유형을 선택해주세요</LobelLabel>
                                </div>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " writerBtn"} id={"writer0"} ref= {(el) => {authorKindBtnRef.current[0] = el}} value={"solo_free"} onClick={(e) => {onClickWriterTypeBtn(e)}} >개인 프리랜서</CategoryBtnButton>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " writerBtn"} id={"writer1"} ref= {(el) => {authorKindBtnRef.current[1] = el}} value={"solo_proprietor"} onClick={(e) => {onClickWriterTypeBtn(e)}} >개인 사업자</CategoryBtnButton>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " writerBtn"} id={"writer2"} ref= {(el) => {authorKindBtnRef.current[2] = el}} value={"corp_business"} onClick={(e) => {onClickWriterTypeBtn(e)}} >법인 사업자</CategoryBtnButton>
                                </OverlapDiv>
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(el) => {coRegNoRef.current = el}} style={{display:"none", marginTop : "0px"}}>
                                <LabelEmailLabel htmlFor="private" className={sign2Css.label + " label"}>사업자 번호를 입력해주세요.</LabelEmailLabel>
                                <DescriptSpanSpan>숫자만 입력해주세요.</DescriptSpanSpan>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <UserInputTextInput type="text" id="privateBusinessNumber" placeholder="사업자 번호를 입력해주세요." onChange={(e) => {onChangecoRegNo(e); coRegNoRegExCheck(e)}} value={coRegNo}/>
                                    <CheckButtonButton id="privateBusinessNumberCheck" className={sign2Css.checkButton + " checkButton"} onClick={onClickCoRegNoCheckCheck}>인증검사</CheckButtonButton>
                                </OverlapDiv>
                                {coRegNoCheckMessage !== '' ?<div className={sign2Css.descript + " descript"} >
                                    {coRegNoCheckMessage === '사용가능한 사업자 번호 입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{coRegNoCheckMessage}</DescriptSpanSpan> : 
                                    coRegNoCheckMessage === '정상적인 사업자 번호 양식입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{coRegNoCheckMessage}</DescriptSpanSpan> :
                                    <DescriptSpanSpan>{coRegNoCheckMessage}</DescriptSpanSpan>}
                                </div> : <></>}
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(el) => {corpRegNoRef.current = el}} style={{display:"none"}}>
                                <LabelEmailLabel htmlFor="private" className={sign2Css.label + " label"}>법인 이름을 입력해주세요.</LabelEmailLabel>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <UserInputTextInput type="text" id="corporationNumber" style={{width : "100%"}} placeholder="법인 이름을 입력해주세요." onChange={(e) => {onChangeCorporationNumber(e)}} value={corpRegNo}/>
                                </OverlapDiv>
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(e) => {fileRef.current = e}} style={{display : "none", marginBottom : "0px"}}>
                                <div className={sign2Css.descript + " descript"}>
                                    <label htmlFor="nickname" className={sign2Css.label + " label"}>사업자 통장 사본을 업로드해주세요</label>
                                </div>
                                <div className={sign2Css.overlap + " overlap"}>
                                    <div className={join_2Css.file_upload_wrapper + " file_upload_wrapper file-upload-wrapper"} style={{display: "flex"}}>
                                        <label htmlFor="file-upload" className={join_2Css.custom_file_upload + " custom_file_upload custom-file-upload"}>
                                            파일업로드
                                        </label>
                                        <FileInput
                                            id="file-upload"
                                            type="file"
                                            name="file-upload"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            onChange={onChangeUpdateFileName}
                                        />
                                        <UploadInstructionDiv id="file-name" className="upload-instruction">
                                            파일을 업로드해주세요
                                        </UploadInstructionDiv>
                                    </div>
                                </div>
                                <div id="file-preview" style={{marginTop: "12px"}}>
                                    <img src="" id="file_img"/>
                                </div>
                            </InputRowDiv>
                            <DefaultHr />
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <div className={sign2Css.descript + " descript"}>
                                    <PassBookLabel htmlFor="passbook" className={sign2Css.label + " label"}>(선택) 정산 은행을 선택해주세요</PassBookLabel>
                                </div>
                                <div className={join_2Css.dropdown + " dropdown"} >
                                    {bankName === "" ? <div className={join_2Css.dropdown_selected + " dropdown_selected dropdown-selected"} onClick={onClickDropdownSelected} ref={(el) => dropdown.current = el}>정산 은행을 선택해주세요</div> :
                                    <div className={join_2Css.dropdown_selected + " dropdown_selected dropdown-selected"} onClick={onClickDropdownSelected} ref={(el) => dropdown.current = el}><img src={bankImg} />{bankName}</div>}
                                    <div className={join_2Css.dropdown_content + " dropdown_content dropdown-content"} ref={(el) => dropdownContentRef.current = el}>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="농협은행" onClick={() => {onClickBankName("농협은행", "011", bank_nh)}}>
                                                <img src={bank_nh} alt="bank_img"/>농협은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="국민은행" onClick={() => {onClickBankName("국민은행", "004", bank_kb)}}>
                                                <img src={bank_kb} alt="bank_img"/>국민은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="카카오뱅크" onClick={() => {onClickBankName("카카오뱅크", "090", bank_ka)}}>
                                                <img src={bank_ka} alt="bank_img"/>카카오뱅크
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="우리은행" onClick={() => {onClickBankName("우리은행", "020", bank_wr)}}>
                                                <img src={bank_wr} alt="bank_img"/>우리은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="기업은행" onClick={() => {onClickBankName("기업은행", "003", bank_ib)}}>
                                                <img src={bank_ib} alt="bank_img"/>기업은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="토스뱅크" onClick={() => {onClickBankName("토스뱅크", "092", bank_to)}}>
                                                <img src={bank_to} alt="bank_img"/>토스뱅크
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="신한은행" onClick={() => {onClickBankName("신한은행", "088", bank_sh)}}>
                                                <img src={bank_sh} alt="bank_img"/>신한은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="하나은행" onClick={() => {onClickBankName("하나은행", "081", bank_ha)}}>
                                                <img src={bank_ha} alt="bank_img"/>하나은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="케이뱅크" onClick={() => {onClickBankName("케이뱅크", "089", bank_k)}}>
                                                <img src={bank_k} alt="bank_img"/>케이뱅크
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="광주은행" onClick={() => {onClickBankName("광주은행", "034", bank_gj)}}>
                                                <img src={bank_gj} alt="bank_img"/>광주은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="씨티은행" onClick={() => {onClickBankName("씨티은행", "027", bank_ci)}}>
                                                <img src={bank_ci} alt="bank_img"/>씨티은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="SC은행" onClick={() => {onClickBankName("SC은행", "023", bank_sc)}}>
                                                <img src={bank_sc} alt="bank_img"/>SC은행
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="산업은행" onClick={() => {onClickBankName("산업은행", "002", bank_su)}}>
                                                <img src={bank_su} alt="bank_img"/>산업은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="신협중앙회" onClick={() => {onClickBankName("신협중앙회", "048", bank_sj)}}>
                                                <img src={bank_sj} alt="bank_img"/>신협중앙회
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="KEB외환" onClick={() => {onClickBankName("KEB외환", "081", bank_ke)}}>
                                                <img src={bank_ke} alt="bank_img"/>KEB외환
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="우체국" onClick={() => {onClickBankName("우체국", "032", bank_wo)}}>
                                                <img src={bank_wo} alt="bank_img"/>우체국
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="새마을금고" onClick={() => {onClickBankName("새마을금고", "045", bank_sm)}}>
                                                <img src={bank_sm} alt="bank_img"/>새마을금고
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="부산은행" onClick={() => {onClickBankName("부산은행", "032", bank_bu)}}>
                                                <img src={bank_bu} alt="bank_img"/>부산은행
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="경남은행" onClick={() => {onClickBankName("경남은행", "039", bank_bu)}}>
                                                <img src={bank_bu} alt="bank_img"/>경남은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="대구은행" onClick={() => {onClickBankName("대구은행", "031", bank_dg)}}>
                                                <img src={bank_dg} alt="bank_img"/>대구은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="전북은행" onClick={() => {onClickBankName("전북은행", "037", bank_gj)}}>
                                                <img src={bank_gj} alt="bank_img"/>전북은행
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="제주은행" onClick={() => {onClickBankName("제주은행", "035", bank_sh)}}>
                                                <img src={bank_sh} alt="bank_img"/>제주은행
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="passbookselect" id="passbookselect" />
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <div className={sign2Css.descript + " descript"}>
                                    <PassBook2Label htmlFor="passbook2" className={sign2Css.label + " label"}>(선택) 정산 계좌를 입력해주세요</PassBook2Label>
                                    <DescriptSpanSpan>숫자만 입력해주세요.</DescriptSpanSpan>
                                </div>
                                <input
                                    type="text"
                                    id="accountNumber"
                                    placeholder="계좌 번호를 입력해주세요"
                                    onChange={(e) => {onChangeAccountNumber(e); accountNumberExCheck(e)}}
                                    value={accountNumber}
                                />
                                {accountNumberRegMsg !== '' ?<div className={sign2Css.descript + " descript"}>
                                    {accountNumberRegMsg === '정상적인 계좌 번호 양식입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{accountNumberRegMsg}</DescriptSpanSpan> :
                                    <DescriptSpanSpan>{accountNumberRegMsg}</DescriptSpanSpan>}
                                </div> : <></>}
                            </InputRowDiv>
                            <DefaultHr />
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <LabelEmailLabel htmlFor="email" className={sign2Css.label + " label"}>아이디(이메일)</LabelEmailLabel>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <UserInputTextInput type="email" id="email" placeholder="이메일을 입력해주세요" onChange={(e) => {onChangeEmail(e); emailRegExCheck(e)}} value={email}/>
                                    <CheckButtonButton id="emailCheck" className={sign2Css.checkButton + " checkButton"} onClick={onClickEmailCheck}>중복검사</CheckButtonButton>
                                </OverlapDiv>
                                    <div className={sign2Css.descript + " descript"} style={{marginTop : "5px"}} ref={(el) => {emailCheckRef.current = el}}>
                                        {emailRegExCheckMessage === "사용할수 있는 이메일(아이디)입니다." ?<DescriptSpanSpan style={{color : 'green'}} >{emailRegExCheckMessage}</DescriptSpanSpan>
                                         : <DescriptSpanSpan >{emailRegExCheckMessage}</DescriptSpanSpan>}
                                    </div>
                                {emailCheckMessage !== '' ?<div className={sign2Css.descript + " descript"}>
                                    {emailCheckMessage === '사용가능한 email 입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{emailCheckMessage}</DescriptSpanSpan> : <DescriptSpanSpan>{emailCheckMessage}</DescriptSpanSpan>}
                                </div> : <></>}
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <div className={sign2Css.descript + " descript"}>
                                    <LobelLabel htmlFor="password" className={sign2Css.label + " label"}>비밀번호</LobelLabel>
                                    <DescriptSpanSpan>8~20자, 숫자+영어+특수문자(!@#$%^&*) 혼합</DescriptSpanSpan>
                                </div>
                                <UserInputInput type="password" id="password" placeholder="비밀번호를 입력해주세요" onChange={(e) => {onChangePassword(e); passwordRegExCheck(e)}} value={password}/>
                                {passwordRegExCheckBool === false ? <div className={sign2Css.descript + " descript"} style={{marginTop : "5px"}}>
                                    <DescriptSpanSpan >{passwordRegExCheckMessage}</DescriptSpanSpan>
                                </div> : <></>}
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <LobelLabel htmlFor="confirmPassword" className={sign2Css.label + " label"}>비밀번호 확인</LobelLabel>
                                <UserInputInput type="password" id="confirmPassword" placeholder="비밀번호를 한번 더 입력해주세요" onChange={(e) => {onChangePassword2(e); password2RegExCheck(e)}} value={password2}/>
                                {password2RegExCheckBool === false ? <div className={sign2Css.descript + " descript"} style={{marginTop : "5px"}}>
                                    <DescriptSpanSpan >{password2RegExCheckMessage}</DescriptSpanSpan>
                                </div> : <></>}
                            </InputRowDiv>
                            {errorMessage !== '' ? <div className={sign2Css.descript + " descript"}>
                                <DescriptSpanSpan >{errorMessage}</DescriptSpanSpan>
                            </div> : <></>}
                        </UserInputDiv>
                        <SaveNextBtnButton type="submit" className={sign2Css.saveNextBtn + " saveNextBtn"} onClick={onClickSign}><DefaultALink>회원가입</DefaultALink></SaveNextBtnButton>
                    </JoinBoxDiv>
                </JoinContainerDiv>
            </div>
        </>
    );
};
export default Sign2;

/** div 태그 #joinContainer css */
const JoinContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .joinBox css */
const JoinBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    @media (max-width: 1400px) {
        padding-top: 120px;
    }
    @media(max-width:568px) {
        padding-top: 70px;
    }
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    text-align: center;
    margin-bottom: 82px;  
    @media(max-width:568px) {
        margin-bottom: 62px;
    }
`;

/** h4 태그 .title h4 css */
const TitleH4H4 = styled.h4`
    font-family: "Min Sans-bold";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom:8px;
    @media(max-width:568px) {
        font-size: 18px;
    }
`;

/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media(max-width:568px) {
        font-size: 36px;
    }
`;

/** div 태그 .userInput css */
const UserInputDiv = styled.div`
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    @media(max-width:568px) {
        margin: 0;
    }
`;

/** input 태그 .userInput input css */
const UserInputInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
`;

/** input 태그 .userInput type="text" input css */
const UserInputTextInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
    width: 80%;
`;

/** div 태그 .inputRow css */
const InputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-of-type{
        margin-bottom: 0;
    }
    &:nth-of-type(2){
        margin-bottom: 42px;
    }
    @media(max-width:568px) {
        margin-bottom: 24px;
        &:nth-of-type(2){
            margin-bottom: 0;
        }
    }
`;

/** label 태그 .label css */
const LobelLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
`;

/** label 태그 .label label[for="email"] css */
const LabelEmailLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    @media(max-width:568px){
        margin-top: 0;
    }
`;

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;

/** div 태그 .overlap css */
const OverlapDiv = styled.div`
    display: flex;
`;

/** button 태그 .checkButton css */
const CheckButtonButton = styled.button`
    margin-left: 0px;
    border: none;
    background-color: #ff562c;
    padding: 18px 22px;
    color: #fff;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    &:hover{
        background-color: #ff7957;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    color: #d9d9d9;
    @media(max-width:568px) {
        margin: 42px 0;
    }
`;

/**button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 52px;
    margin-bottom: 24px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
    @media (max-width: 560px) {
        margin-top: 42px;
        margin-bottom: 40px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;


/** button 태그 .category-btn > button css */
const CategoryBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: rgba(130, 130, 132, 1);
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    width: 50%;
    height: 4rem;
    cursor: pointer;
    &:hover {
        border: 1px solid #ff562c;
        background-color: #ff562c;
        color: #fff;
    }
    &.active {
        border: 1px solid #ff562c;
        color: #fff;
        background-color: #ff562c;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right: 4px;
    }
`;

/** input 태그 file input css */
const FileInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

/** div 태그 .upload-instruction css  */
const UploadInstructionDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #dcdcdc;
    padding: 6px 12px;
    margin-left: -5px;
    background: #f0f0f0;
    height: 60px;
    width: 375px;
    color: #828284;
    @media (max-width: 568px) {
        width: auto;
    }
`;

/** label 태그 for=passbook css */
const PassBookLabel = styled.label`
    margin-top: 42px;
    opacity: 70%;
`;

/** label 태그 for=passbook2 css */
const PassBook2Label = styled.label`
    opacity: 70%;
`;