import PaymentSection from '../../components/common/PaymentSection';
import $ from 'jquery'
import mypageListCss from '../../css/mypage_list.module.css'
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import PaymentSection2 from 'components/common/PaymentSection2';
import { useEffect, useRef, useState } from 'react';


const PaymentDetail = () => {
    const [page, setPage] = useState(null);

    // 판매/ 결재 내역 카테고리 선택
    $(document).ready(function () {
        $(".synop_select_tab_nav a").click(function () {
            var targetId = $(this).attr("aria-valuetext");
            $(".synop_select_tab_content > div").hide();
            $(targetId).show();
            let idData = "";
            switch (targetId) {
                case "#synop01" : {
                    idData = "synop01";
                    break;
                }
                case "#synop02" : {
                    idData = "synop02";
                    break;
                }
                case "#synop03" : {
                    idData = "synop03";
                    break;
                }
                case "#synop04" : {
                    idData = "synop04";
                    break;
                }
            }
            const data = {"category" : idData, "page" : 1};
            setPage(1);
            localStorage.setItem("categoryPay", JSON.stringify(data));
            $(".synop_select_tab_nav a").removeClass("active");
            $(this).addClass("active");
        });
    });

    const synopRef = useRef([]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        const cate = JSON.parse(localStorage.getItem("categoryPay"));
        if(cate !== null && cate !== undefined) {
            $(".synop_select_tab_content > div").hide();
            $(".synop_select_tab_nav a").removeClass("active");
            console.log(cate.category);
            switch (cate.category) {
                case "synop01" : {
                    synopRef.current[0].style.display = "block"
                    synopRef.current[1].style.display = "none"
                    synopRef.current[2].style.display = "none"
                    synopRef.current[3].style.display = "none"
                    $(cate).show();
                    $("#synop1").addClass("active");
                    console.log("1");
                    break;
                }
                case "synop02" : {
                    synopRef.current[0].style.display = "none"
                    synopRef.current[1].style.display = "block"
                    synopRef.current[2].style.display = "none"
                    synopRef.current[3].style.display = "none"
                    $(cate).show();
                    $("#synop2").addClass("active");
                    console.log("2");
                    break;
                }
                case "synop03" : {
                    synopRef.current[0].style.display = "none"
                    synopRef.current[1].style.display = "none"
                    synopRef.current[2].style.display = "block"
                    synopRef.current[3].style.display = "none"
                    $(cate).show();
                    $("#synop3").addClass("active");
                    console.log("3");
                    break;
                }
                case "synop04" : {
                    synopRef.current[0].style.display = "none"
                    synopRef.current[1].style.display = "none"
                    synopRef.current[2].style.display = "none"
                    synopRef.current[3].style.display = "block"
                    $(cate).show();
                    $("#synop4").addClass("active");
                    console.log("4");
                    break;
                }
            }
        } else {
            $(".synop_select_tab_content > div").hide();
            $(".synop_select_tab_nav a").removeClass("active");
            synopRef.current[0].style.display = "block"
            synopRef.current[1].style.display = "none"
            synopRef.current[2].style.display = "none"
            synopRef.current[3].style.display = "none"
            $("#synop1").addClass("active");
            $("#synop01").show();
            console.log("5");
        }
    }, []) 
    
    return (
        <>
            <ContainerDiv id="container">
                <section className={mypageListCss.synop_select + " synop_select synop-select"}>
                    <SynopSelectTitleH1 className={mypageListCss.synop_select_title + " synop_select_title synop-select-title"}>판매 · 결제내역 조회</SynopSelectTitleH1>
                    <div className={mypageListCss.synop_select_tab + " synop_select_tab synop-select-tab"}>
                        <SynopSelectTabNavUl className={mypageListCss.synop_select_tab_nav + " synop_select_tab_nav synop-select-tab-nav"}>
                            <SynopSelectTabNavLiLi><SynopSelectTabNavLiALink id='synop1'  aria-valuetext='#synop01' className="active">전체내역</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                            <SynopSelectTabNavLiLi><SynopSelectTabNavLiALink id='synop2'  aria-valuetext='#synop02' >코인사용내역</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                            <SynopSelectTabNavLiLi><SynopSelectTabNavLiALink id='synop3'  aria-valuetext='#synop03' >정산가능내역</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                            <SynopSelectTabNavLiLi><SynopSelectTabNavLiALink id='synop4'  aria-valuetext='#synop04' >결제내역</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                        </SynopSelectTabNavUl>
                        <div className={mypageListCss.synop_select_tab_content + " synop_select_tab_content synop-select-tab-content"}>
                            <div id="synop01" ref={(el) => {synopRef.current[0] = el}} style={{display : "block"}}>
                                <PaymentSection category={"all"} pageNum={page} setPageNum={setPage}/>
                            </div>
                            <div id="synop02" ref={(el) => {synopRef.current[1] = el}}>
                                <PaymentSection2 category={"coin"} pageNum={page} setPageNum={setPage}/>
                            </div>
                            <div id="synop03" ref={(el) => {synopRef.current[2] = el}}>
                                <PaymentSection category={"point"} pageNum={page} setPageNum={setPage}/>
                            </div>
                            <div id="synop04" ref={(el) => {synopRef.current[3] = el}}>
                                <PaymentSection2 category={"purchase"} pageNum={page} setPageNum={setPage}/>
                            </div>
                        </div>
                    </div>
                </section>
            </ContainerDiv>
        </>
    );
}
export default PaymentDetail;


/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 180px;
    padding-top: 104px;
    overflow-y: auto;
    @media (max-width: 1400px) {
        padding: 104px 80px 0px;
        padding-top: 104px;
    }
    @media (min-width: 768px) and (max-width: 1000px) {
        padding: 0 10px;
        padding-top: 70px;
    }
    @media (min-width: 100px) and (max-width: 767px) {
        padding: 0 22px;
        padding-top: 70px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;

/** ul 태그 .synop_select_tab > ul .synop_select_tab_nav css */
const SynopSelectTabNavUl = styled.ul`
    list-style: none;
    margin-top: 52px;
    margin-bottom: 32px;
    padding-left: 0px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
        margin-bottom: 20px;
    }
`;

/** li 태그 .synop_select_tab_nav li css */
const SynopSelectTabNavLiLi = styled.li`
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
`;

/** a 태그 .synop_select_tab_nav li a css */
const SynopSelectTabNavLiALink = styled(Link)`
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #22222a;
        color: #fff;
        border: 1px solid #22222a;
    }
    &.active {
        border-bottom: 1px solid #fff;
        background: #22222a;
        color: #fff;
        border: 1px solid #22222a;
    }
    @media (max-width: 1400px) {
        padding: 8px 16px;
        font-size: 14px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        margin-right: 6px;
    }
`;