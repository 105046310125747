import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import ListSection from "../../components/common/ListSection";
import veteranCss from '../../css/veteran.module.css'
import createNovel5Css from '../../css/createNovel5.module.css'
import URL from "constants/url";
import { getCookie } from "hooks/Cookie";


const ListSelectTab = (type) => {

    const [sort, setSort] = useState(null);
    const [category, setCategory] = useState(null);
    const [page, setPage] = useState(null);
    const path = location.pathname;
    const [pathName] = useState(path);
    
    const localData = JSON.parse(localStorage.getItem("pageData"));

    useEffect(() => {
        if(localData !== null && localData !== undefined) {
            setSort(localData.sort);
            setCategory(localData.category);
            setPage(localData.page);
            $(".synop_select_tab_nav a").removeClass("active");
            $(".synop_select_tab_nav_sort a").removeClass("active");
            switch (localData.category) {
                case 0 : {
                    $("#synop0").addClass("active");
                    break;
                }
                case 1 : {
                    $("#synop1").addClass("active");
                    break;
                }
                case 2 : {
                    $("#synop2").addClass("active");
                    break;
                }
                case 3 : {
                    $("#synop3").addClass("active");
                    break;
                }
                case 4 : {
                    $("#synop4").addClass("active");
                    break;
                }
                case 5 : {
                    $("#synop5").addClass("active");
                    break;
                }
            }
            switch (localData.sort) {
                case "recent" : {
                    $("#recent").addClass("active");
                    break;
                }
                case "recommend" : {
                    $("#recommend").addClass("active");
                    break;
                }
                case "sold" : {
                    $("#recommend").addClass("active");
                    break;
                }
                case "view" : {
                    $("#view").addClass("active");
                    break;
                }
            }
        } else {
            setSort("recent");
            setCategory(0);
            setPage(1);
        }
    }, [])

    // 카테고리 클릭
    $(document).ready(function () {
        $(".synop_select_tab_nav a").click(function () {
            var targetId = $(this).attr("aria-valuetext");
            $(".synop_select_tab_nav a").removeClass("active");
            $(this).addClass("active");
            const categorydata = parseInt(targetId);
            setCategory(categorydata);
            setPage(1);
            localStorage.removeItem("pageData");
            localStorage.removeItem("pageDataSynop");
            localStorage.removeItem("categoryNotice");
            localStorage.removeItem("categoryPay");
            localStorage.removeItem("myPicturePageData");
            localStorage.removeItem("rowCnt");
            if(localData !== null && localData !== undefined) {
                const copy = {...localData, "category" : categorydata, "page" : 1}
                localStorage.setItem("pageData", JSON.stringify(copy));
            } else {
                const data = {"type" : type, "category" : categorydata, "page" : 1, "sort" : sort}
                localStorage.setItem("pageData", JSON.stringify(data))
            }
        });
        $(".synop_select_tab_nav_sort a").click(function () {
            const val = $(this).attr("aria-valuetext");
            setSort(val);
            setPage(1);
            $(".synop_select_tab_nav_sort a").removeClass("active");
            $(this).addClass("active");
            localStorage.removeItem("pageData");
            localStorage.removeItem("pageDataSynop");
            localStorage.removeItem("categoryNotice");
            localStorage.removeItem("categoryPay");
            localStorage.removeItem("myPicturePageData");
            localStorage.removeItem("rowCnt");
            if(localData !== null && localData !== undefined) {
                const copy = {...localData, "sort" : val, "page" : 1}
                localStorage.setItem("pageData", JSON.stringify(copy));
            } else {
                const data = {"type" : type, "category" : category, "page" : 1, "sort" : val}
                localStorage.setItem("pageData", JSON.stringify(data))
            }
        })
    });

    const navigate = useNavigate();
    const token = getCookie('token');

    // 내 그림 판매하기 버튼
    const onClickMyPictureBtn = () => {
        if(token !== undefined) {
            navigate(URL.MODIFY_MY_PICTURE_MODIFY);
        } else {
            alert("로그인 후 이용가능합니다.");
        }
    }


    return (
        <>
            <div className={veteranCss.synop_select_tab + " synop_select_tab"}>
                <SelectTabDiv className={veteranCss.selectTab + " selectTab"}>
                    <SynopSelectTabNavUl className={veteranCss.synop_select_tab_nav + " " + veteranCss.category + " synop_select_tab_nav category"}>
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="synop0" aria-valuetext="0" className="active">전체 카테고리</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="synop1" aria-valuetext="1" >로맨스</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="synop2" aria-valuetext="2" >판타지</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="synop3" aria-valuetext="3" >스릴러</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="synop4" aria-valuetext="4" >무협</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="synop5" aria-valuetext="5" >기타</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                    </SynopSelectTabNavUl>
                    <SynopSelectTabNavSortUl className={"synop_select_tab_nav_sort latest " + veteranCss.latest} >
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="recent" aria-valuetext="recent" className="active">최신순</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                        {pathName !== URL.LIST_DRAWING_STORE ? <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="recommend" aria-valuetext="recommend" >추천순</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi> :
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="recommend" aria-valuetext="sold" >판매순</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>}
                        <SynopSelectTabNavLiLi><SynopSelectTabNavLiLinkA id="view" aria-valuetext="view" >조회순</SynopSelectTabNavLiLinkA></SynopSelectTabNavLiLi>
                    </SynopSelectTabNavSortUl>
                    {type.type === "illust" ? <NextSceneDiv className={createNovel5Css.next_scene + " next_scene next-scene"} onClick={onClickMyPictureBtn}>내 그림 판매하기</NextSceneDiv> : <></>}
                </SelectTabDiv>
                <SynopSelectTabContentDiv className={veteranCss.synop_select_tab_content + " synop_select_tab_content"}>
                    <DefaultDiv id="synop01">
                        <ListSection type={type} category={category} sort={sort} page={page} setPage={setPage} key={"synop01"}/>
                    </DefaultDiv>
                </SynopSelectTabContentDiv>
            </div>
        </>
    );
}

export default ListSelectTab;

/** div 태그 .selectTab css */
const SelectTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    display: flex;
    gap: 48px;
    @media (min-width: 100px) and (max-width: 768px) {
        gap: 0px;
        display: block;
    }
`;

/** ul 태그 .synop-select-tab-nav css */
const SynopSelectTabNavUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    display: flex;
    padding: 0;
    margin-bottom: 40px;
    @media (min-width: 360px) and (max-width: 768px) {
        margin: 0px 0px 20px;
    }
    @media (min-width: 100px) and (max-width: 430px) {
        margin: 0px 0px 20px;
        display : grid;
    }
`;

/** li 태그 .synop-select-tab-nav li css */
const SynopSelectTabNavLiLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
    @media (min-width: 430px) and (max-width: 400px) {
        margin: 0px 0px 10px;
        display : grid;
    }
    @media (min-width: 100px) and (max-width: 430px) {
        margin-bottom: 10px;
    }
`;

/** a 태그 .synop-select-tab-nav li a css */
const SynopSelectTabNavLiLinkA = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        margin-right: 2px;
    }
`;

/** ul 태그 .synop-select-tab-nav-sort css */
const SynopSelectTabNavSortUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    padding: 0;
    margin-bottom: 24px;
`;

/** div 태그 .synop-select-tab-content css */
const SynopSelectTabContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    border-top: none;
`;

/** div 태그 디폴트 css  */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: block;
`;

/** div 태그 .next-scene css */
const NextSceneDiv = styled.div`
    padding-top: 8px;
    margin-right: 30px;
    margin-left: auto;
    height: 39px;
    margin-bottom: 30px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding-top: 8px;
        margin-right: 0px;
        margin-bottom: 10px;
    }
`;