import { Route, Routes } from "react-router";
import Footer from "../../components/common/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import URL from "../../constants/url";
import Header from "../../components/common/Header";
import TopButton from "../../components/common/TopButton";
import { useEffect, useState } from "react";
import ChargeShopCheckOut from "components/charge_shop/ChargeShopCheckOut";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ChargeShopSuccess from "components/charge_shop/ChargeShopSuccess";
import ChargeShopFail from "components/charge_shop/ChargeShopFail";
import { getCookie, removeCookie, removeCookieOption } from "hooks/Cookie";
import { useRecoilValue } from "recoil";
import { apiServerState } from "constants/recoilData";


const AizacChargeShop = () => {

    const [isAlone,] = useState(true);

    const queryClient = new QueryClient();

    const [userInfo, setUserInfo] = useState(null);
    const [clientData, setClientData] = useState(null);
    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    useEffect(() => {
        const form = {};

        if(token === undefined) {
            alert("로그인후 이용하실수 있습니다.");
            navigate(URL.LOGIN);
        } else {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
    
            axios
            .post(apiServer + "/api/aizac_trade/get_toss_pay_clientKey", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    setClientData(response?.data?.data);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === 'invalid user') {
                        alert("없는 유저입니다.");
                    } else if (msg === 'invalid data') {
                        alert("가격, 코인 정보가 없습니다. 다시시도해주세요.")
                    } else if (msg === 'axios err') {
                        alert("토스 페이먼츠 연결 실패")
                    } else if (msg === 'invalid payment') {
                        alert("토스 페이먼츠 오류")
                    } else if (msg === "router err") {
                        alert("서버오류");
                    } else {
                        alert(msg);
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    
            const url = apiServer + "/api/asiac_login/get_my_info";
    
            axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success ) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        }
    

    }, [])

    return (
        <>
            <TopButton isAlone={isAlone}/>
            <Header/>
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path={URL.CHECK_OUT} element={<ChargeShopCheckOut userInfo={userInfo} clientData={clientData}/>}/>
                    <Route path={URL.SUCCESS} element={<ChargeShopSuccess />}/>
                    <Route path={URL.FAIL} element={<ChargeShopFail/>}/>
                </Routes>
            </QueryClientProvider>
            <Footer/>
        </>
    )
};
export default AizacChargeShop;