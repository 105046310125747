import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import $ from 'jquery';
import eye4 from '../../css/images/eye 4.svg'
import shoppingCart12 from '../../css/images/shopping-cart-12.svg'
import styled from "@emotion/styled";
import illstCss from '../../css/drawing_datail.module.css'
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import axios from "axios";
import URL from "../../constants/url";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import defaultImg from "../../css/images/illustDefault.png"
import { apiServerState } from "../../constants/recoilData";
import Swal from "sweetalert2";


// 그림 상세 페이지 컴포넌트
const Illust = () => {

    let { illustId } = useParams();
    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");

    const modalRef = useRef();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 작품신고 버튼(모달 오픈)
    const onClickModal = () => {
        if (userInfo === null || userInfo === undefined) {
            alert("로그인 후 이용하실수 있습니다.");
            navigate(URL.LOGIN);
        }
        $("#modalText").text("작품을 신고하시겠습니까?");
        $("#cancelBtn").css("display", "inline");
        $("#reportBtn").css("display", "inline");
        modalRef.current.style.display = 'block';
    }

    // 작품 신고 모달 취소 버튼
    const onClickCancel = () => {
        $("#modalText").text("작품을 신고하시겠습니까?");
        $("#cancelBtn").css("display", "inline");
        $("#reportBtn").css("display", "inline");
        modalRef.current.style.display = 'none';
    }

    // 작품 신고 모달 신고 버튼
    const onClickReport = () => {
        if (token === undefined) {
            navigate(URL.LOGIN);
        }

        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        let form = { "aiImgId": parseInt(illustId) };

        axios
            .post(apiServer + "/api/aizac/report/report_ai_img", form, config)
            .then((response) => {
                if (response?.data?.success ) {
                    $("#modalText").text("신고가 완료되었습니다.");
                    $("#cancelBtn").css("display", "none");
                    $("#reportBtn").css("display", "none");
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "already reported") {
                        $("#modalText").text("이미 신고한 그림입니다.");
                        $("#cancelBtn").css("display", "none");
                        $("#reportBtn").css("display", "none");
                    } else if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        $("#modalText").text("다시 로그인 후 이용해주세요.");
                        $("#cancelBtn").css("display", "none");
                        $("#reportBtn").css("display", "none");
                        logout();
                    } else {
                        $("#modalText").text("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        $("#cancelBtn").css("display", "none");
                        $("#reportBtn").css("display", "none");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }

    // 작품 신고 모달 닫기 버튼
    const onClickCloseModal = () => {
        modalRef.current.style.display = 'none';
    }

    
    const [illustIdData] = useState(illustId);
    const [isMounted, setIsMounted] = useState(false);
    const [imgData, setImgData] = useState(null);
    const categoryName = ["전체", "로맨스", "판타지", "스릴러", "무협", "기타"];
    const [userInfo, setUserInfo] = useState(null);

    // 최초 랜더링시 img, 유저정보 셋팅
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };
    
        const update = () => {

            const urlillust = apiServer + "/api/aizac_ai_img/ai_a_img?id=" + illustIdData;
            const urlUser = apiServer + "/api/asiac_login/get_my_info";
            
            
            const urlIllustCall = async () => {
                axios
                    .get(urlillust, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            if(response?.data?.data?.img?.bShow === 0) {
                                alert("비공개 작품입니다.");
                                navigate(URL.LIST_DRAWING_STORE);
                            }
                            setImgData(response?.data?.data?.img);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if(msg === "invalid img") {
                                alert("비정상적인 접근입니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };

            const userInfo = async () => {
                const token = getCookie("token");
                const config = {
                    headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                    }
                };

                await axios
                .get(urlUser, config)
                .then((response) => {
                    if (response?.data?.success) {
                        setUserInfo(response?.data?.data?.user);
                        setIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else if (msg === "invalid user") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            };

        
            urlIllustCall();
            if(token !== undefined) {
                userInfo();
            }
        };
        update();

        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })

    }, [])

    const buyModalRef = useRef();

    // 구매하기 버튼(모달 오픈)
    const onClickBuyModal = () => {
        if (userInfo === null || userInfo === undefined) {
            alert("로그인 후 이용하실수 있습니다.");
            navigate(URL.LOGIN);
        }
        $("#modalTextBuy").text("작품을 구매하시겠습니까?");
        $("#cancelBtnBuy").css("display", "inline");
        $("#reportBtnBuy").css("display", "inline");
        buyModalRef.current.style.display = 'block';
    }

    // 구매하기 모달 취소 버튼
    const onClickBuyCancel = () => {
        buyModalRef.current.style.display = 'none';
    }

    // 구매하기 모달 외부 영역 클릭시 닫기
    const onClickCloseBuyModal = () => {
        buyModalRef.current.style.display = 'none';
    }

    // 구매하기 모달 구매 버튼
    const onClickBuyReport = (price) => {
        if (token === undefined) {
            alert("로그인 후 이용하실수 있습니다.");
            navigate(URL.LOGIN);
        }
        if (userInfo === null || userInfo === undefined) {
            alert("로그인 후 이용하실수 있습니다.");
            navigate(URL.LOGIN);
        } else if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
        (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= parseInt(price)) {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  "authorization": token,
                }
            };
            let form = { "aiImgId": parseInt(illustId) };
            axios
                .post(apiServer + "/api/aizac_ai_img/buy_ai_img", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        $("#modalTextBuy").text("구매가 완료되었습니다.");
                        $("#cancelBtnBuy").css("display", "none");
                        $("#reportBtnBuy").css("display", "none");
                    } else {
                        if (response?.data?.custMsg === "already bought image") {
                            $("#modalTextBuy").text("이미 구매한 그림입니다.");
                            $("#cancelBtnBuy").css("display", "none");
                            $("#reportBtnBuy").css("display", "none");
                        } else {
                            $("#modalTextBuy").text("구매에 실패하였습니다. 다시 구매해주세요.");
                            $("#cancelBtnBuy").css("display", "none");
                            $("#reportBtnBuy").css("display", "none");
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 이용해주세요.");
                                logout();
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            }
                            console.log(msg);
                        }
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        } else {
            Swal.fire({
                title : "작품 구매",
                text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "코인 충전",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    navigate(URL.LIST_CHARGE_SHOP);
                } 
            });
        }

    }

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return(
        <>
            <div id="wrap">
                <ContainerDiv id="container">
                    {isMounted !== false ? <TopInfoDiv className={illstCss.topInfo + " topInfo"}>
                        <BookImgDiv className={illstCss.bookImg + " bookImg"}>
                            {imgData?.imgUrl ? <BookImgImg src={imgData?.imgUrl} alt="book_img" onError={noImage}/> : <BookImgImg src={defaultImg} alt="book_default_img"/>}
                        </BookImgDiv>
                        <BookTextDiv className={illstCss.bookText + " bookText"}>
                            <CateSpan className={illstCss.cate + " cate"}>{categoryName[imgData?.categoryId]}</CateSpan>
                            <BookTitleDiv className={illstCss.bookTitle + " bookTitle"}>{(String(imgData?.title)).trim()}</BookTitleDiv>
                            <SeriesInfoDiv className={illstCss.seriesInfo + " seriesInfo"}>
                                <AutorNameSpan className={illstCss.autorName + " autorName"}>{imgData?.nickname} 작가</AutorNameSpan>
                            </SeriesInfoDiv>
                            <StoryDiv className={illstCss.story + " story"}>
                                {imgData?.synopsis}
                            </StoryDiv>
                            <div className={illstCss.bookIcon + " bookIcon"}>
                                <BookIconSpanSpan className={illstCss.view + " view"}>
                                    <img src={eye4} alt="eye4"/>
                                    <BookIconSpan>조회수 {parseInt(imgData?.viewCnt) >= 1000 ? (parseInt(imgData?.viewCnt) / 1000).toFixed(1) + "k" : parseInt(imgData?.viewCnt) }</BookIconSpan>
                                </BookIconSpanSpan>
                                <BookIconSpanSpan className={illstCss.butNum + " butNum"}>
                                    <img src={shoppingCart12} alt="shoppungCart12_img"/>
                                    <BookIconSpan>판매수 {imgData?.soldCnt}</BookIconSpan>
                                </BookIconSpanSpan>
                            </div>  
                            <PriceDiv className={illstCss.price + " price"}>
                                <PriceSpan>판매가</PriceSpan>
                                <PriceH1>{imgData?.price}c</PriceH1>
                            </PriceDiv>
                                
                            <UserActionDiv className={illstCss.userAction + " userAction"}>
                                {userInfo?.id !== imgData?.userId ?<UserActionBuyButton type="button" className={illstCss.buy + " buy"} onClick={onClickBuyModal}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g style={{clipPath: "url(#clip0_1_4981)"}}>
                                            <path d="M6.75 16.5C7.16421 16.5 7.5 16.1642 7.5 15.75C7.5 15.3358 7.16421 15 6.75 15C6.33579 15 6 15.3358 6 15.75C6 16.1642 6.33579 16.5 6.75 16.5Z" style={{stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round"}}/>
                                            <path d="M15 16.5C15.4142 16.5 15.75 16.1642 15.75 15.75C15.75 15.3358 15.4142 15 15 15C14.5858 15 14.25 15.3358 14.25 15.75C14.25 16.1642 14.5858 16.5 15 16.5Z" style={{stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round"}}/>
                                            <path d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5" style={{stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round"}}/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_4340">
                                                <rect width="18" height="18" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                        
                                    <UserActionSpan ><DefaultALink >구매하기</DefaultALink></UserActionSpan>
                                </UserActionBuyButton> : <></>}
                                <UserActionDeclareBtnButton type="button" className={illstCss.declareBtn + " declareBtn"}  id="declareButton" onClick={onClickModal}>
                                    <UserActionSpan>작품 신고</UserActionSpan>
                                </UserActionDeclareBtnButton>
                            </UserActionDiv>
                            <ModalDiv id="myModal" className={illstCss.modal + " modal"} onClick={onClickCloseModal} ref={modalRef}>
                                <ModalContentDiv className={illstCss.modal_content + " modal_content"} onClick={(e) => e.stopPropagation()} >
                                    <ModalP id="modalText">작품을 신고하시겠습니까?</ModalP>
                                    <div className={illstCss.selectBtn + " selectBtn"}>
                                        <ModalCancelBtnButton id="cancelBtn" onClick={onClickCancel}>취소</ModalCancelBtnButton>
                                        <ModalReportBtnButton id="reportBtn" onClick={onClickReport}>신고</ModalReportBtnButton>
                                    </div>
                                </ModalContentDiv>
                            </ModalDiv>
                            <ModalDiv id="buyModal" className={illstCss.modal + " modal"} onClick={onClickCloseBuyModal} ref={buyModalRef}>
                                <ModalContentDiv className={illstCss.modal_content + " modal_content"} onClick={(e) => e.stopPropagation()} >
                                    <ModalP id="modalTextBuy">작품을 구매하시겠습니까?</ModalP>
                                    <div className={illstCss.selectBtn + " selectBtn"}>
                                        <ModalCancelBtnButton id="cancelBtnBuy" onClick={onClickBuyCancel}>취소</ModalCancelBtnButton>
                                        <ModalReportBtnButton id="reportBtnBuy" onClick={() => {onClickBuyReport(imgData.price)}}>구매</ModalReportBtnButton>
                                    </div>
                                </ModalContentDiv>
                            </ModalDiv>
                        </BookTextDiv>
                    </TopInfoDiv> : <></>}
                </ContainerDiv>
            </div>
        </>
    );
}
export default Illust;

/** div 태그 .bookText css */
const BookTextDiv = styled.div`
    @media (min-width: 100px) and (max-width: 768px) {
        width: auto;
    }
`;

/** div 태그 .bookImg css */
const BookImgDiv = styled.div`
    min-width: 300px;
    height: 476px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: 360px) and (max-width: 768px) {
        width: 166px;
        height: auto;
    }
    @media (min-width: 100px) and (max-width: 359px) {
        min-width: 100px;
        width: 166px;
        height: auto;
    }
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
    @media (max-width: 1400px) {
        padding: 0 140px;
    }
    @media(min-width:100px) and (max-width:768px) {
        padding: 0 22px;
    }
`;

/** div 태그 .topInfo css */
const TopInfoDiv = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 38px;
    margin-bottom: 124px;
    max-width: 100%;
    height: auto;
    margin-top: 46px;
    @media(min-width:100px) and (max-width:768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    @media(min-width:100px) and (max-width:768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    @media(min-width:100px) and (max-width:768px) {
        margin-bottom: 80px;
    }
`;

/** img 태그 .bookImg img css */
const BookImgImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    @media(min-width:360px) and (max-width:768px) {
        max-width: 100%;
        height: 100%;
    }
    @media(min-width:100px) and (max-width:359px) {
        max-width: 100%;
        height: 100%;
    }
`;

/** span 태그 .cate css */
const CateSpan = styled.span`
    font-size: 16px;
    background-color: #22222a;
    color: #fff;
    padding: 6px 16px;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    @media(min-width:100px) and (max-width:768px) {
        font-size: 13px;
    }
`;

/** div 태그 .bookTitle css */
const BookTitleDiv = styled.div`
    font-size: 38px;
    color: #050409;
    font-weight: 700;
    letter-spacing: -0.03em;
    margin-bottom: 8px;;
    margin-top: 12px;
    font-family: "Min Sans-bold";
    word-break: break-all;
    @media(min-width:100px) and (max-width:768px) {
        font-size: 22px;
    }
`;

/** div 태그 .seriesInfo css */
const SeriesInfoDiv = styled.div`
    font-size: 18px;
    letter-spacing: -0.03em;
    padding-bottom: 10px;
    font-family: "Min Sans-Regular";
    @media(min-width:100px) and (max-width:768px) {
        font-size: 13px;
    }
`;

/** span 태그 .autorName css */
const AutorNameSpan = styled.span`
    color: #22222a;
    padding-right: 8px;
    letter-spacing: -0.03em;
`;

/** div 태그 .story css */
const StoryDiv = styled.div`
    color: #22222a;
    line-height: 1.6;
    font-size: 16px;
    letter-spacing: -0.03em;
    padding-bottom: 16px;
    font-family: "Min Sans-Light";
    word-break: break-all;
    @media(min-width:100px) and (max-width:768px) {
        font-size: 14px;
    }
`;

/** span 태그 .bookIcon > span css */
const BookIconSpanSpan = styled.span`
    padding-right: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    @media(min-width:100px) and (max-width:768px) {
        font-size: 13px;
    }
`;

/** span 태그 .bookIcon span css */
const BookIconSpan = styled.span`
    @media(min-width:100px) and (max-width:768px) {
        font-size: 13px;
    }
`;

/** div 태그 .price css */
const PriceDiv = styled.div`
    color:#FF5F37;
    font-family: "min sans-regular";
    display: flex;
    align-items: center;
    gap:4px;
    letter-spacing: -0.3px;
    margin-top: 20px;
`;

/** span 태그 .price span css */
const PriceSpan = styled.span`
    color:#FF5F37;
    font-size: 16px;
`;

/** h1 태그 .price h1 css */
const PriceH1 = styled.h1`
    color:#FF5F37;
    font-size: 28px;
    font-family: "min sans-bold";
`;

/** div 태그 .userAction css */
const UserActionDiv = styled.div`
    margin-top: 10px;
`;

/** button 태그 .buy .userAction button css */
const UserActionBuyButton = styled.button`
    cursor: pointer;
    border-radius: 26px;
    margin: 0;
    margin-right: 6px;
    background-color: #FF5F37;
    border: none;
    color:#fff;
    border: 1px solid #FF5F37;
    padding: 12px 50px;
`;

/** button 태그 .declareBtn .userAction button css */
const UserActionDeclareBtnButton = styled.button`
    cursor: pointer;
    border-radius: 26px;
    margin: 0;
    margin-right: 6px;
    background-color: #f6f6f6;
    color: #A8A8A9;
    border: 1px solid #f6f6f6;
    padding: 12px 20px;
`;

/** span 태그 .userAction span css */
const UserActionSpan = styled.span`
    font-size: 15px;
    letter-spacing: -0.3px;
    font-family: "Min Sans-Medium";
    @media(min-width:100px) and (max-width:768px) {
        font-size: 15px;
    }
`;

/** div 태그 .modal css */
const ModalDiv = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 배경색 및 투명도 설정 */
    z-index: 1; /* 모달을 다른 요소 위에 표시 */
`;

/** div 태그 .modal_content css */
const ModalContentDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 20px;
    max-width: 330px;
`;

/** p 태그 .modal p css */
const ModalP = styled.p`
    font-size: 18px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    margin-bottom: 24px;
`;

/** button 태그 .modal button #cancelBtn css */
const ModalCancelBtnButton = styled.button`
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
`;
/** button 태그 .modal button #reportBtn css */
const ModalReportBtnButton = styled.button`
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
    background-color: #FF5F37;
    color: #fff;
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;