import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import withDrawalCss from '../../css/withDrawal.module.css'
import Swal from "sweetalert2";
import axios from "axios";
import { getCookie, removeCookie, removeCookieOption } from "hooks/Cookie";
import { useRecoilValue } from "recoil";
import { apiServerState } from "constants/recoilData";
import { useEffect, useState } from "react";
import URL from "constants/url";


const WithDrawal2 = () => {

    const navigate = useNavigate();

    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);

    const [userInfo, setUserInfo] = useState(null);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 유저정보 셋팅
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        
        if(token !== undefined) {
            userInfo();
        } else {
            alert("로그인 후 이용해주세요.");
            navigate(URL.LOGIN);
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    // 모두 확인 버튼(탈퇴 버튼)
    const onClickBtn = () => {

        Swal.fire({
            title: "비밀번호를 입력해 주세요",
            input: "password",
            inputPlaceholder: "비밀번호 입력",
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#BDBDBD",
            confirmButtonText: "탈퇴",
            cancelButtonText: "취소",
            reverseButtons: true,
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return "비밀번호를 입력해 주세요!";
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
                
                const data = {
                    "name": userInfo?.name
                    ,"nickName": userInfo?.nickName
                    ,"email": userInfo?.email
                    ,"password" : result.value
                }
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };

                axios
                    .post(apiServer + "/api/asiac_login/withdraw", data, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            removeCookie("token");
                            removeCookieOption("token", {path : "/"});
                            const token = getCookie("token");
                            if (token !== undefined) {
                                removeCookieOption("token", {path : "/"});
                            }
                            alert("탈퇴하였습니다.");
                            navigate('/withdrawal/3');
                        } else {
                            const msg = response?.data?.custMsg;
                            if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 이용해 주세요.");
                                logout();
                            } else if (msg === 'invalid user') {
                                alert("비밀번호가 틀리거나 회원을 찾을 수 없습니다.");
                            } else if (msg === 'router err') {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.")
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            }
          });
    }


    return (
        <>
            <div id="wrap">
                <ContainerDiv id="container">
                    <TitleDiv className={withDrawalCss.title + " title"}>
                        <TitleH4H4>아이작 회원탈퇴</TitleH4H4>
                        <TitleH2H2>탈퇴약관 동의</TitleH2H2>
                    </TitleDiv>
                    <div className={withDrawalCss.reOk + " reOk"}>
                        <ReOk1Div className={withDrawalCss.reOK_1 + " reOK_1 reOK-1"}>
                            회원 탈퇴 시 보유한 아이작 코인과 등록한 게시글이<br />모두
                            삭제됩니다. 해당 내용을 확인하셨나요?
                        </ReOk1Div>
                        <ReOk2Div className={withDrawalCss.reOK_2 + " reOK_2 reOK-2"}>
                            ※게시글의 경우 제3자에 의해 스크랩 등이 되어 재게시된 게시물 제외
                        </ReOk2Div>
                    </div>
                    <SaveNextBtnButton type="submit" className={withDrawalCss.saveNextBtn + " saveNextBtn"} onClick={onClickBtn}>
                        <Link>모두 확인했습니다</Link>
                    </SaveNextBtnButton>
                </ContainerDiv>
            </div>
        </>
    );
};
export default WithDrawal2;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    max-width: 100%;
    width: auto;
    height: auto;
    padding: 0px 324px;
    padding-top: 120px;
    @media (max-width: 1400px) {
        max-width: 100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-top: 120px;
    }
    @media (max-width: 568px) {
        padding: 0 22px;
    }
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    text-align: center;
    margin-bottom: 82px;
    @media (max-width: 568px) {
        margin-bottom: 62px;
        margin-top: 70px;
    }
`;

/** h4 태그 .title h4 css */
const TitleH4H4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: "Min Sans-bold";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom: 8px;
`;

/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media (max-width: 568px) {
        font-size: 36px;
    }
`;

/** div 태그 .reOk-1 css */
const ReOk1Div = styled.div`
    text-align: center;
    font-family: "Min Sans-Medium";
    color: #050409;
    font-size: 20px;
    letter-spacing: -0.3px;
    margin-bottom: 16px;
`;

/** div 태그 .reOK-2 css */
const ReOk2Div = styled.div`
    text-align: center;
    font-family: "Min Sans-Regular";
    color: #828284;
    font-size: 16px;
    letter-spacing: -0.3px;
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222a;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 82px;
    margin-bottom: 120px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #303039;
    }
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
    @media (max-width: 568px) {
        margin-top: 42px;
        margin-bottom: 80px;
    }
`;