
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import styled from "@emotion/styled";
import URL from "constants/url";


const ChargeShopFail = () => {

    const [searchParams] = useSearchParams();

    return (
        <>
            <WrapperDiv className="result wrapper">
                <BoxSectionDiv className="box_section">  
                    <h2 style={{padding: "20px 0px 10px 0px"}}>
                        <img
                            width="30px"
                            src="https://static.toss.im/3d-emojis/u1F6A8-apng.png"
                        />
                        결제 실패
                    </h2>
                    <p>{`${searchParams.get("message")}`}</p>

                    <WrapperDiv className="result wrapper">
                        <Link to={URL.MAIN}>
                            <ButtonButton className="button" style={{ marginTop: '30px', marginRight: '10px' }}>
                                메인 페이지 이동
                            </ButtonButton>
                        </Link>
                        <Link to={URL.LIST_CHARGE_SHOP}>
                            <ButtonButton className="button" style={{ marginTop: '30px', backgroundColor: '#e8f3ff', color: '#1b64da', marginRight: '10px' }}>
                                충전소 이동
                            </ButtonButton>
                        </Link>
                    </WrapperDiv>   
                </BoxSectionDiv>
            </WrapperDiv>
        </>
    )
};
export default ChargeShopFail;

/** div 태그 .wrapper css */
const WrapperDiv = styled.div`
    max-width: 800px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-wrap: balance;
`;

/** div 태그 .box_section css */
const BoxSectionDiv = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 40px 30px 50px 30px; 
    margin-top:30px; 
    margin-bottom:50px; 
    color: #333D4B
`;

/** button 태그 .button css */
const ButtonButton = styled.div`
    color: #f9fafb;
    background-color: #3182f6;
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    /* vertical-align: middle; */
    cursor: pointer;
    border: 0 solid transparent;
    user-select: none;
    transition: background 0.2s ease, color 0.1s ease;
    text-decoration: none;
    border-radius: 7px;
    padding: 11px 16px;
    &:hover {
        color: #fff;
        background-color: #1b64da;
    }
`;