import { Link, useNavigate } from "react-router-dom";
import sign2Css from '../../css/sign2.module.css'
import styled from "@emotion/styled";
import URL from "../../constants/url";
import bank_nh from '../../css/images/bank_img/nh.png';
import bank_kb from '../../css/images/bank_img/kb.png';
import bank_ka from '../../css/images/bank_img/ka.png';
import bank_wr from '../../css/images/bank_img/wr.png';
import bank_ib from '../../css/images/bank_img/ib.png';
import bank_to from '../../css/images/bank_img/to.png';
import bank_sh from '../../css/images/bank_img/sh.png';
import bank_ha from '../../css/images/bank_img/ha.png';
import bank_k from '../../css/images/bank_img/k.png';
import bank_gj from '../../css/images/bank_img/gj.png';
import bank_ci from '../../css/images/bank_img/ci.png';
import bank_sc from '../../css/images/bank_img/sc.png';
import bank_su from '../../css/images/bank_img/su.png';
import bank_sj from '../../css/images/bank_img/sj.png';
import bank_ke from '../../css/images/bank_img/ke.png';
import bank_wo from '../../css/images/bank_img/wo.png';
import bank_sm from '../../css/images/bank_img/sm.png';
import bank_bu from '../../css/images/bank_img/bu.png';
import bank_dg from '../../css/images/bank_img/dg.png';
import join_2Css from '../../css/join_2.module.css';
import { useEffect, useRef, useState } from "react";
import { getCookie, removeCookie, removeCookieOption } from "hooks/Cookie";
import { useRecoilValue } from "recoil";
import { apiServerState } from "constants/recoilData";
import axios from "axios";


const UserAccountChange = () => {

    const navigate = useNavigate();
    const token = getCookie("token");
    // 토큰 검사
    if(token === undefined) {
        navigate(URL.LOGIN);
    }
    const apiServer = useRecoilValue(apiServerState);
    const [userInfo, setUserInfo] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 저장 버튼
    const onClickSaveBtn = () => {
        const bankNameCheck = (String(bankName)).trim();
        if (bankNameCheck === "") {
            alert("정산 은행을 입력해주세요.");
            return;
        }
        if (bankName === "") {
            alert("정산 은행을 선택해주세요.");
            return;
        } else if (bankName === null || bankName === undefined) {
            alert("정산 은행을 선택해주세요.");
            return;
        }
        const accountNumberCheck = (String(accountNumber)).trim();
        if (accountNumberCheck === "") {
            alert("정산 계좌를 입력해주세요.");
            return;
        }
        if(accountNumber === "") {
            alert("정산 계좌를 입력해주세요.");
            return;
        } else if (accountNumber === null || accountNumber === undefined) {
            alert("정산 계좌를 입력해주세요.");
            return;
        }
        if(accountNumberRegCheck === true) {
            const form = {
                "accountNumber" : accountNumber,
                "bankName" : bankName,
                "bankCode" : bankCode
            }
    
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
    
            axios
                .post(apiServer + "/api/asiac_login/update_bank_account", form, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        alert("정산 계좌가 변경되었습니다.");
                        navigate(URL.MY_PAGE_MY_INFO);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인 후 이용해주세요.");
                            logout();
                        } else if (msg === 'invalid user') {
                            alert("유저 id가 잘못되었습니다. 다시로그인 해주세요.");
                        } else if (msg === 'router err') {
                            alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                        } else {
                            alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                        }
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            navigate(URL.MY_PAGE_MY_INFO);
        }

    }

    const [bankName, setBankName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberRegMsg, setAccountNumberRegMsg] = useState("");
    const [accountNumberRegCheck, setAccountNumberRegCheck] = useState(true);
    const [bankCode, setBankCode] = useState("");
    const [bankImg, setBankImg] = useState(null);

    // 정산 은행 입력 함수
    const onClickBankName = (name, code, img) => {
        setBankName(name);
        setBankCode(code);
        setBankImg(img);
        dropdownContentRef.current.style.display = "none";
    }

    const dropdownContentRef = useRef();
    const dropdown = useRef();

    // 드롭다운 열기/닫기 함수
    const onClickDropdownSelected = () => {
        if(dropdownContentRef.current.style.display === "none") {
            dropdownContentRef.current.style.display = "block";
        } else {
            dropdownContentRef.current.style.display = "none";
        }
    }
    // 모달 외 클릭시(닫기)
    useEffect(() => {
        const handleClick = (e) => {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(e.target)) {
                if(dropdown.current && !dropdown.current.contains(e.target)) {
                    dropdownContentRef.current.style.display = "none";
                }
            }
        };
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, [dropdownContentRef]);

    // 계좌번호 변경 입력 함수
    const onChangeAccountNumber = (e) => {
        setAccountNumber(e.target.value);
    }

    // 계좌번호 정규식 체크(숫자)
    const accountNumberExCheck = (e) => {
        const accountNumberRegEx = new RegExp(/^\d{1,20}$/)
        const accountNumber = e.target.value;
        const bool = accountNumberRegEx.test(accountNumber);
        if (bool) {
            setAccountNumberRegMsg("정상적인 계좌 번호 양식입니다.");
        } else {
            setAccountNumberRegMsg("계좌 번호는 20자리이내 숫자만 가능합니다.");
        }
        if (accountNumber === '') {
            setAccountNumberRegMsg('');
        }
        setAccountNumberRegCheck(bool);
    }

    // 최초 랜더링시 토큰검사, 유저정보 셋팅
    useEffect(() => {
        if (token === undefined) {
            navigate(URL.MAIN);
        }
        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success ) {
                    setUserInfo(response?.data?.data?.user);
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [])

    // userInfo 셋팅후 은행정보, 계좌번호 셋팅 유지
    useEffect(() => {
        if(userInfo !== null) {
            setAccountNumber(userInfo?.accountNumber);
            setBankName(userInfo?.bankName);
            setBankCode(userInfo?.bankCode);
            const bankNameCopy = userInfo?.bankName;

            switch (bankNameCopy) {
                case "농협은행" : {
                    setBankImg(bank_nh);
                    break;
                }
                case "국민은행" : { 
                    setBankImg(bank_nh);
                    break;
                }
                case "카카오뱅크" : { 
                    setBankImg(bank_ka);
                    break;
                }
                case "우리은행" : { 
                    setBankImg(bank_wr);
                    break;
                }
                case "기업은행" : { 
                    setBankImg(bank_ib);
                    break;
                }
                case "토스뱅크" : { 
                    setBankImg(bank_to);
                    break;
                }
                case "신한은행" : { 
                    setBankImg(bank_sh);
                    break;
                }
                case "하나은행" : { 
                    setBankImg(bank_ha);
                    break;
                }
                case "케이뱅크" : { 
                    setBankImg(bank_k);
                    break;
                }
                case "광주은행" : { 
                    setBankImg(bank_gj);
                    break;
                }
                case "씨티은행" : { 
                    setBankImg(bank_ci);
                    break;
                }
                case "SC은행" : { 
                    setBankImg(bank_sc);
                    break;
                }
                case "산업은행" : { 
                    setBankImg(bank_su);
                    break;
                }
                case "신협중앙회" : { 
                    setBankImg(bank_sj);
                    break;
                }
                case "KEB외환" : { 
                    setBankImg(bank_ke);
                    break;
                }
                case "우체국" : { 
                    setBankImg(bank_wo);
                    break;
                }
                case "새마을금고" : { 
                    setBankImg(bank_sm);
                    break;
                }
                case "부산은행" : { 
                    setBankImg(bank_bu);
                    break;
                }
                case "경남은행" : { 
                    setBankImg(bank_bu);
                    break;
                }
                case "대구은행" : { 
                    setBankImg(bank_dg);
                    break;
                }
                case "전북은행" : { 
                    setBankImg(bank_gj);
                    break;
                }
                case "제주은행" : { 
                    setBankImg(bank_sh);
                    break;
                }
            }
        }
    }, [userInfo])

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    
    return(
        <>
            <div id="wrap">
                {isMounted === true ? <JoinContainerDiv id="joinContainer">
                    <JoinBoxDiv className={sign2Css.joinBox + " joinBox"}>
                        <TitleDiv className={sign2Css.title + " title"}>
                            <TitleH2H2>정산 계좌 등록</TitleH2H2>
                        </TitleDiv>
                        <UserInputDiv className={sign2Css.userInput + " userInput"}>
                        <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <div className={sign2Css.descript + " descript"}>
                                    <PassBookLabel htmlFor="passbook" className={sign2Css.label + " label"}>정산 은행을 선택해주세요</PassBookLabel>
                                </div>
                                <div className={join_2Css.dropdown + " dropdown"} >
                                    {bankName === "" ? <div className={join_2Css.dropdown_selected + " dropdown_selected dropdown-selected"} onClick={onClickDropdownSelected} ref={(el) => dropdown.current = el}>정산 은행을 선택해주세요</div> :
                                    <div className={join_2Css.dropdown_selected + " dropdown_selected dropdown-selected"} onClick={onClickDropdownSelected} ref={(el) => dropdown.current = el}><img src={bankImg} />{bankName}</div>}
                                    <div className={join_2Css.dropdown_content + " dropdown_content dropdown-content"} ref={(el) => dropdownContentRef.current = el}>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="농협은행" onClick={() => {onClickBankName("농협은행", "011", bank_nh)}}>
                                                <img src={bank_nh} alt="bank_img"/>농협은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="국민은행" onClick={() => {onClickBankName("국민은행", "004", bank_kb)}}>
                                                <img src={bank_kb} alt="bank_img"/>국민은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="카카오뱅크" onClick={() => {onClickBankName("카카오뱅크", "090", bank_ka)}}>
                                                <img src={bank_ka} alt="bank_img"/>카카오뱅크
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="우리은행" onClick={() => {onClickBankName("우리은행", "020", bank_wr)}}>
                                                <img src={bank_wr} alt="bank_img"/>우리은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="기업은행" onClick={() => {onClickBankName("기업은행", "003", bank_ib)}}>
                                                <img src={bank_ib} alt="bank_img"/>기업은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="토스뱅크" onClick={() => {onClickBankName("토스뱅크", "092", bank_to)}}>
                                                <img src={bank_to} alt="bank_img"/>토스뱅크
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="신한은행" onClick={() => {onClickBankName("신한은행", "088", bank_sh)}}>
                                                <img src={bank_sh} alt="bank_img"/>신한은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="하나은행" onClick={() => {onClickBankName("하나은행", "081", bank_ha)}}>
                                                <img src={bank_ha} alt="bank_img"/>하나은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="케이뱅크" onClick={() => {onClickBankName("케이뱅크", "089", bank_k)}}>
                                                <img src={bank_k} alt="bank_img"/>케이뱅크
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="광주은행" onClick={() => {onClickBankName("광주은행", "034", bank_gj)}}>
                                                <img src={bank_gj} alt="bank_img"/>광주은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="씨티은행" onClick={() => {onClickBankName("씨티은행", "027", bank_ci)}}>
                                                <img src={bank_ci} alt="bank_img"/>씨티은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="SC은행" onClick={() => {onClickBankName("SC은행", "023", bank_sc)}}>
                                                <img src={bank_sc} alt="bank_img"/>SC은행
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="산업은행" onClick={() => {onClickBankName("산업은행", "002", bank_su)}}>
                                                <img src={bank_su} alt="bank_img"/>산업은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="신협중앙회" onClick={() => {onClickBankName("신협중앙회", "048", bank_sj)}}>
                                                <img src={bank_sj} alt="bank_img"/>신협중앙회
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="KEB외환" onClick={() => {onClickBankName("KEB외환", "081", bank_ke)}}>
                                                <img src={bank_ke} alt="bank_img"/>KEB외환
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="우체국" onClick={() => {onClickBankName("우체국", "032", bank_wo)}}>
                                                <img src={bank_wo} alt="bank_img"/>우체국
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="새마을금고" onClick={() => {onClickBankName("새마을금고", "045", bank_sm)}}>
                                                <img src={bank_sm} alt="bank_img"/>새마을금고
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="부산은행" onClick={() => {onClickBankName("부산은행", "032", bank_bu)}}>
                                                <img src={bank_bu} alt="bank_img"/>부산은행
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="경남은행" onClick={() => {onClickBankName("경남은행", "039", bank_bu)}}>
                                                <img src={bank_bu} alt="bank_img"/>경남은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="대구은행" onClick={() => {onClickBankName("대구은행", "031", bank_dg)}}>
                                                <img src={bank_dg} alt="bank_img"/>대구은행
                                            </div>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="전북은행" onClick={() => {onClickBankName("전북은행", "037", bank_gj)}}>
                                                <img src={bank_gj} alt="bank_img"/>전북은행
                                            </div>
                                        </div>
                                        <div className={join_2Css.dropdown_flex + " dropdown_flex dropdown-flex"}>
                                            <div className={join_2Css.dropdown_item + " dropdown_item dropdown-item"} data-value="제주은행" onClick={() => {onClickBankName("제주은행", "035", bank_sh)}}>
                                                <img src={bank_sh} alt="bank_img"/>제주은행
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="passbookselect" id="passbookselect" />
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                                <div className={sign2Css.descript + " descript"}>
                                    <PassBook2Label htmlFor="passbook2" className={sign2Css.label + " label"}>정산 계좌를 입력해주세요</PassBook2Label>
                                    <DescriptSpanSpan>숫자만 입력해주세요.</DescriptSpanSpan>
                                </div>
                                <input
                                    type="text"
                                    id="accountNumber"
                                    placeholder="정산 계좌를 입력해주세요"
                                    onChange={(e) => {onChangeAccountNumber(e); accountNumberExCheck(e)}}
                                    value={accountNumber}
                                />
                                {accountNumberRegMsg !== '' ?<div className={sign2Css.descript + " descript"}>
                                    {accountNumberRegMsg === '정상적인 계좌 번호 양식입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{accountNumberRegMsg}</DescriptSpanSpan> :
                                    <DescriptSpanSpan>{accountNumberRegMsg}</DescriptSpanSpan>}
                                </div> : <></>}
                            </InputRowDiv>
                        </UserInputDiv>
                        <SaveNextBtnButton type="submit" className={sign2Css.saveNextBtn + " saveNextBtn"} onClick={onClickSaveBtn}><DefaultALink>저장 </DefaultALink></SaveNextBtnButton>
                    </JoinBoxDiv>
                </JoinContainerDiv> : <></>}
            </div>
        </>
    );
};
export default UserAccountChange;

/** div 태그 #joinContainer css */
const JoinContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .joinBox css */
const JoinBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    @media (max-width: 1400px) {
        padding-top: 120px;
    }
    @media(max-width:568px) {
        padding-top: 70px;
    }
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    text-align: center;
    margin-bottom: 82px;  
    @media(max-width:568px) {
        margin-bottom: 62px;
    }
`;


/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media(max-width:568px) {
        font-size: 36px;
    }
`;

/** div 태그 .userInput css */
const UserInputDiv = styled.div`
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    @media(max-width:568px) {
        margin: 0;
    }
`;

/**button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 52px;
    margin-bottom: 24px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
    @media (max-width: 560px) {
        margin-top: 42px;
        margin-bottom: 40px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;


/** div 태그 .inputRow css */
const InputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-of-type{
        margin-bottom: 0;
    }
    &:nth-of-type(2){
        margin-bottom: 42px;
    }
    @media(max-width:568px) {
        margin-bottom: 24px;
        &:nth-of-type(2){
            margin-bottom: 0;
        }
    }
`;

/** label 태그 for=passbook css */
const PassBookLabel = styled.label`
    margin-top: 42px;
`;

/** label 태그 for=passbook2 css */
const PassBook2Label = styled.label`
`;

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;