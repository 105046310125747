import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import find2Css from '../../css/find_password_2.module.css'
import URL from "../../constants/url";
import { getCookie } from "hooks/Cookie";
import { useRecoilValue } from "recoil";
import { tempPasswordState } from "constants/recoilData";
import { useEffect } from "react";
import mypageCss from '../../css/mypage_account.module.css'


const FindPassword2 = () => {
    const navigate = useNavigate();

    // 최초 랜더링시 로그인 검사
    useEffect(() => {
        const token = getCookie("token");
        if (token !== undefined) {
            navigate(URL.MAIN);
        }
    }, [])

    const tempPassword = useRecoilValue(tempPasswordState);

    // 로그인 버튼
    const onClickLogin = () => {
        navigate(URL.LOGIN);
    }

    const handleCopyClipBoard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          alert('클립보드에 링크가 복사되었습니다.');
        } catch (e) {
          alert('복사에 실패하였습니다');
        }
    };

    return (
        <>
            <div id="wrap">
                <FindContainerDiv id="findContainer">
                    <FindBoxDiv className={find2Css.findBox + " findBox"}>
                        <DefaultH4>비밀번호 찾기</DefaultH4>
                        <PasswordDiv className={find2Css.password + " password"}>
                            <DefaultP>회원님의 비밀번호는  
                                <span style={{fontSize: "28px", userSelect: "text"}}>{tempPassword}</span>
                             입니다
                                <CheckButtonButton id="userTypeBtn" className={mypageCss.checkButton + " checkButton"} onClick={() => {handleCopyClipBoard(tempPassword)}}>copy</CheckButtonButton>
                            </DefaultP>
                        </PasswordDiv>
                        <SaveNextBtnButton type="submit" className={find2Css.saveNextBtn + " saveNextBtn"} onClick={onClickLogin}><DefaultALink >로그인</DefaultALink></SaveNextBtnButton>
                    </FindBoxDiv>
                </FindContainerDiv>
            </div>
        </>
    );
};
export default FindPassword2;


/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** div 태그 .findContainer css */
const FindContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .findBox css */
const FindBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
`;

/** h4 태그 디폴트 css */
const DefaultH4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-top: 140px;
`;

/** div 태그 .password css */
const PasswordDiv = styled.div`
    padding: 150px 0;
`;

/** p 태그 디폴트 css */
const DefaultP = styled.p`
    font-family: "Min Sans-regular";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #ff562c;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
`;

/** button 태그 .checkButton css */
const CheckButtonButton = styled.button`
    margin-left: 10px;
    color: #fff;
    background-color: #FF5F37;
    font-size: 16px;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
    padding: 18px 20px;
    border: 1px solid #ff5f37;
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover{
        background-color: #ff7957;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;