
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import URL from "../../constants/url";
import synopDefault from '../../css/images/synopDefault.png'
import axios from "axios";
import { useRecoilValue } from "recoil";
import novel1Css from '../../css/novelCreate1.module.css'
import { getCookie, removeCookieOption, setCookieOption } from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";


const NovelCreate1Section = ({category, createType, pageNum, setPageNum}) => {

    const naviRef = useRef([]);

    const [synopList, setSynopList] = useState([]);
    
    const [totalPage, setTotalPage] = useState(1);
    let paginationTag = [];
    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    
    const config = {
        headers: {
            "Content-Type": "application/json",
            "authorization": token,
        }
    };

    // 그림 만들기용 시놉 api 확인
    const synop = async (category, page) => {
        if(category === null || category === undefined) {
            return;
        }
        if(page === null || page === undefined) {
            return;
        }
        const url = apiServer + "/api/asiac_novel/get_synopsis_list?categoryId=" + category + "&page=" + page;

        await axios
        .get(url, config)
        .then((response) => {
            if (response?.data?.success ) {
                setSynopList(response?.data?.data?.synopsisList);
                setTotalPage(response?.data?.data?.totalPages);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(msg);
                }
                navigate(URL.MAIN);
                console.log("로딩 실패")
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    };

    // 그림 만들기용 시놉 페이지num api 호출(카테고리 유지)
    const pagingSynop = async (category, page) => {

        if(category === null || category === undefined) {
            return;
        }
        if(page === null || page === undefined) {
            return;
        }
        const url = apiServer + "/api/asiac_novel/get_synopsis_list?categoryId=" + category + "&page=" + page;

        await axios
        .get(url, config)
        .then((response) => {
            if (response?.data?.success ) {
                setSynopList(response?.data?.data?.synopsisList);
                setTotalPage(response?.data?.data?.totalPages);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(msg);
                }
                navigate(URL.MAIN);
                console.log("로딩 실패")
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    };

    
    
    // 최초 랜더링시 시놉시스 셋팅
    useEffect(() => {
        synop(category, pageNum);
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [synopList])

    useEffect(() => {
        pagingSynop(category, pageNum);
    }, [category, pageNum]);

    // ================  페이징 처리  =======================

    const currentPageNo = pageNum;
    const pageSize = 5;
    const totalPageCount = totalPage;

    const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
    let currentLastPage = currentFirstPage + pageSize - 1;

    currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

    if (totalPageCount > pageSize) {
        // 첫 페이지 이동
        const firstPageTag = <><PaginationLink onClick={() => {pageCall(category, 1)}}>&lt;&lt;</PaginationLink></>;
        paginationTag.push(firstPageTag);

        // 이전 페이지 이동
        const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
        const previousPageTag = <><PaginationLink onClick={() => {pageCall(category, prevPageIndex)}}>&lt;</PaginationLink></>;
        paginationTag.push(previousPageTag);
    }

    for (let i = currentFirstPage; i <= currentLastPage; i++) {
        if (i === currentPageNo) {
            // 현재 페이지
            const currentPage = <><PaginationLink className={novel1Css.current_page + " current_page"} onClick={() => {pageCall(category, i)}}>{i}</PaginationLink></>;
            paginationTag.push(currentPage);
        } else {
            // 다른 페이지
            const otherPage = <><PaginationLink onClick={() => {pageCall(category, i)}}>{i}</PaginationLink></>;
            paginationTag.push(otherPage);
        }
    }
    if (totalPageCount > pageSize) {
        // 다음 페이지 이동
        const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
        const nextPageTag = <><PaginationLink onClick={() => {pageCall(category, nextPageIndex)}}>&gt;</PaginationLink></>;
        paginationTag.push(nextPageTag);
        
        // 마지막 페이지 이동
        const lastPageTag = <><PaginationLink onClick={() => {pageCall(category, totalPage)}}>&gt;&gt;</PaginationLink></>;
        paginationTag.push(lastPageTag);
    }

    const pageCall = (category1, page) => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        const copy = {"category" : category1, "page" : page}
        localStorage.setItem("pageDataSynop", JSON.stringify(copy));
        setPageNum(page);
        pagingSynop(category1, page);
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    // =======================  페이징 처리 끝  ==============================

    // 시놉시스 클릭
    const onClickSynop = (synop1, createType1) => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        setCookieOption("synop", synop1, {path : ""});
        removeCookieOption("picture3Data", {path : ""});
        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };

        const url = apiServer + "/api/asiac_novel/update_synopsis_view"
        
        const form = {"synopsisId": synop1?.id}
        
        axios
        .post(url, form, config)
        .then((response) => {
            if (response?.data?.success) {
                console.log("success");
            } else {
                console.log(response?.data?.custMsg);
            }
        })
        .catch((e) => {
            console.log(e);
        });
        
        const urlHistory = apiServer + "/api/asiac_novel/update_synopsis_use_history"
        axios
        .post(urlHistory, form, config)
        .then((response) => {
            if (response?.data?.success) {
                console.log("success");
            } else {
                console.log(response?.data?.custMsg);
            }
        })
        .catch((e) => {
            console.log(e);
        });

        if (createType1 === "novel") {
            navigate(URL.CREATE_NOVEL2)
        } else {
            navigate(URL.CREATE_PICTURE2)
        }
    }

    const noImage = (e) => {
        e.target.src = synopDefault
    }
 
    return (
        <>
            <RankSynopSection className={novel1Css.rank_synop + " rank_synop rank-synop"}>
                    {synopList && synopList.map((synop) => {return(<ColumnDiv className={novel1Css.column + " column"} style={{width: '50%', display: 'inline-block'}} key={synop?.id}>
                    <ColDiv className={novel1Css.col + " col"} onClick={() => {onClickSynop(synop, createType)}}>
                        <DefaultLink >
                            <DefaultDiv className={novel1Css.synop_view + " synop_view p-3 synop-view"}>
                                <SynopImgDiv className={" synop_img synop-img"}>
                                    <SynopIlluImg src={synop?.imgUrl !== null ? synop?.imgUrl : synopDefault} alt="synopImg" onError={noImage} style={{height:'278.204px'}} className={novel1Css.synop_illu + " synop_illu synop-illu"} ref={el => (naviRef.current[0] = el)}/>
                                    <GradientOverlayDiv className={novel1Css.gradient_overlay + " gradient_overlay gradient-overlay"}></GradientOverlayDiv>
                                    <SynopOverlayDiv className={novel1Css.synop_overlay + " synop_overlay synop-overlay"}>
                                        <SynopTitleDiv className={novel1Css.synop_title + " synop_title synop-title"}>{synop?.title}</SynopTitleDiv>
                                        <SynopSubDiv className={novel1Css.synop_sub + " synop_sub synop-sub"}>
                                            {synop?.shortSynopsis}
                                        </SynopSubDiv>
                                    </SynopOverlayDiv>
                                </SynopImgDiv>
                            </DefaultDiv>
                        </DefaultLink>
                    </ColDiv>
                </ColumnDiv>)})}
                <PaginationDiv className={novel1Css.pagination + " pagination"}>
                    {paginationTag}
                </PaginationDiv>
            </RankSynopSection>
        </>
    );
};

export default NovelCreate1Section;

/** section 태그 .rank-synop css */
const RankSynopSection = styled.section`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0px 0px;
    padding-bottom: 120px;
    max-width: 1320px;
    margin: 0 auto;
    @media (min-width: 100px) and (max-width: 768px) {
        padding-bottom: 80px;
    }
`;

/** div 태그 .column css */
const ColumnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    column-count: 1;
    margin-bottom: 12px;
    @media (max-width: 1400px) {
        column-count: 1;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** div 태그 .col css */
const ColDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 1400px) {
        margin-bottom: 12px;
    }
`;

/** a 태그 디폴트 css */
const DefaultLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
`;

/** div 태그 .synop-img css */
const SynopImgDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    position: relative;
`;

/** img 태그 .synop-illu css */
const SynopIlluImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    width: 100%;

`;

/** div 태그 .gradient-overlay css */
const GradientOverlayDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.8)
    );
    pointer-events: none;
`;

/** div 태그 .synop-overlay css */
const SynopOverlayDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 20px;
    color: #fff;
    padding: 10px;
`;

/** div 태그 .synop-title css */
const SynopTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.3px;
    opacity: 0.9;
    margin-bottom: 6px;
`;

/** div 태그 .synop-sub css */
const SynopSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.3px;
    opacity: 0.7;
    width: 356px;
`;

/** div 태그 .pagination */
const PaginationDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

/** a 태그 .pagination a css */
const PaginationLink = styled(Link)`
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    margin: 5px;
    padding: 5px 10px;
    color: #333;
    font-family: "Min Sans-Light";
    font-size: 14px;
    :hover {
        background-color: #fff;
        color: #ff5f37;
    };
`;

