import { useEffect, useRef, useState } from 'react';
import modalCss from '../../css/AiEditorSummaryModal.module.css';
import { getCookie, removeCookie, removeCookieOption } from 'hooks/Cookie';
import { useRecoilValue } from 'recoil';
import { apiServerState } from 'constants/recoilData';
import axios from 'axios';
import AiEditorSummaryListModalBook from './AiEditorSummaryListModalBook';
import styled from '@emotion/styled';
import $ from 'jquery';
import Swal from "sweetalert2";
import URL from 'constants/url';
import { useNavigate } from "react-router";


const AiEditorSummaryListModal = () => {

    // 모달 닫기(리스트 모달)
    const onClickClose = () => {
        var modal = document.getElementById("relist");
        modal.style.display = "none";
        setAddEpisodeList([]);
        const checkedFalse = $(".relistcheck");
        for(let i = 0; i< checkedFalse.length; i++) {
            checkedFalse[i].checked = false;
        }
    }


    const modalRef = useRef();

    // 모달 외 클릭시 닫기
    useEffect(() => {
        const handleClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClickClose();
            }
        };
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, [modalRef]);

    const apiServer = useRecoilValue(apiServerState);
    const [publishedNovels, setPublishedNovels] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [addEpisodeList, setAddEpisodeList] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();

    // 최초 랜더링시 유저정보 상태 저장
    useEffect(() => {
        const token = getCookie("token");
        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        const update = () => {
            const url = apiServer + "/api/asiac_novel/get_user_overall_info";
        
            const updateList = async () => {
                await axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success) {
                        setPublishedNovels(response?.data?.data?.publishedNovels);
                        setUserInfo(response?.data?.data?.userInfo)
                        setIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                            alert("다시 로그인 해주세요.");
                            removeCookie("token");
                            removeCookieOption("token", {path : "/"});
                            localStorage.removeItem("pageData");
                            localStorage.removeItem("pageDataSynop");
                            localStorage.removeItem("categoryNotice");
                            localStorage.removeItem("categoryPay");
                            localStorage.removeItem("myPicturePageData");
                            localStorage.removeItem("rowCnt");
                            window.localStorage.clear();
                            const token = getCookie("token");
                            if (token !== undefined) {
                                removeCookieOption("token", {path : "/"});
                                navigate(URL.MAIN);
                            } else {
                                navigate(URL.MAIN);
                            }
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state : { msg : e.message}});
                });
            };

            updateList();

        };
        update();

    }, []);

    // 리스트 제거
    const handleCheck = (bookId, episode) => {
        const data = {"episodeId" : episode.id, "bookId" : bookId, "episode" : episode, "chapter" : episode.chapter}
        const copy = [...addEpisodeList];
        for (let i = 0; i < copy.length; i++) {
            if (copy[i].episodeId === data.episodeId) {
                copy.splice(i, 1);
            }
        }
        setAddEpisodeList(copy);
        $('#relistcheck' + bookId + "_" + episode?.id)[0].checked = false;
        if (copy.length === 0) {
            $("#selectall" + bookId)[0].checked = false;
        }
    }

    // 스토리 재생성 시작 버튼 
    const onClickSummaryBtn = () => {

        if (addEpisodeList.length === 0) {
            alert("재생성할 회차를 추가해주세요.");
        } else if (addEpisodeList.length > 100) {
            alert("스토리 재생성 회차는 최대 100개까지 가능합니다.");
        }else {
            Swal.fire({
                title : "요금 안내",
                text: "소설 재생성은 1회 당 50000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50000 || userInfo?.coinAuth === "unlimited") {
                        const token = getCookie("token");
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                            }
                        };
                        const update = () => {
                            const url = apiServer + "/api/asiac_nestyle/edit/recreate";
                            const dataList = [];
                            if(addEpisodeList.length <= 0) {
                                alert("1개이상의 회차를 선택해주세요.");
                                return;
                            } else {

                                for (let i = 0; i < addEpisodeList.length;i++) {
                                    let copy = {
                                        "id" : addEpisodeList[i].episode.id,
                                        "title" : addEpisodeList[i].episode.title,
                                        "content" : addEpisodeList[i].episode.content
                                    }
                                    dataList.push(copy);
                                }
                                
                                let form = {
                                    "title" : addEpisodeList[0]?.book?.title,
                                    "userId": addEpisodeList[0]?.book?.userId ,
                                    "episodes" : dataList
                                }
    
    
                                const updateList = async () => {
                                    await axios
                                    .post(url, form, config)
                                    .then((response) => {
                                        if (response?.data?.success) {
                                            Swal.fire({
                                                title : "안내",
                                                text: "스토리 재생성 완료 후 \n 자동으로 편집 보관함에 저장됩니다.",
                                                confirmButtonColor: "#FF5F37",
                                                confirmButtonText: "확인",
                                            }).then((result) => {
                                                if (result.dismiss === Swal.DismissReason.confirm) {
                                                    console.log("스토리 재생성 시작");
                                                } 
                                            });
                                            const copy = [];
                                            setAddEpisodeList(copy);
                                            const checkedFalse = $(".relistcheck");
                                            for(let i = 0; i< checkedFalse.length; i++) {
                                                checkedFalse[i].checked = false;
                                            }
                                            onClickClose();
                                        } else {
                                            const msg = response?.data?.custMsg;
                                            if (msg.includes("timeout")) {
                                                alert("요청 시간이 초과되었습니다.");
                                            } else if (msg.includes("exceeded length")) {
                                                const title = response?.data?.data?.title;
                                                if (title !== undefined && title !== null) {
                                                    alert(`${title} 파일이 4000자 이상입니다.`);
                                                } else {
                                                    alert(`파일이 4000자 이상입니다.`);
                                                }
                                            } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                removeCookie("token");
                                                removeCookieOption("token", {path : "/"});
                                                localStorage.removeItem("pageData");
                                                localStorage.removeItem("pageDataSynop");
                                                localStorage.removeItem("categoryNotice");
                                                localStorage.removeItem("categoryPay");
                                                localStorage.removeItem("myPicturePageData");
                                                localStorage.removeItem("rowCnt");
                                                window.localStorage.clear();
                                                const token = getCookie("token");
                                                if (token !== undefined) {
                                                    removeCookieOption("token", {path : "/"});
                                                    navigate(URL.MAIN);
                                                } else {
                                                    navigate(URL.MAIN);
                                                }
                                            } else if (msg === "need more coin") {
                                                alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                            } else if (msg === "axios err" || msg === "ai generator err") {
                                                alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                            } else if (msg === "router err") {
                                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                            } else if (msg === "ikind invalid") {
                                                alert("법인 유저만 이용할수 있습니다.");
                                            } else {
                                                alert(msg);
                                            }
                                            console.log(msg);
                                        }
                                    })
                                    .catch(e => {  // API 호출이 실패한 경우
                                        alert(e.message);
                                        console.log(e);
                                    });
                                };
    
                                updateList();
                            }

                        };
                        update();
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                    }

                } 
            });
        }
    }

    return (
        <>
            {isMounted === true ? <ModalContentDiv className={modalCss.modal_content + " modal_content modal-content"} ref={(e) => {modalRef.current = e}}>
                <CloseSpan className={modalCss.close + " close"} onClick={onClickClose}>&times;</CloseSpan>
                <ReupLoadTitleDiv className={modalCss.reuploadtitle + " reuploadtitle"}>내 글 리스트에서 선택</ReupLoadTitleDiv>
                <ReuploadinfoDiv className={modalCss.reuploadinfo + " reuploadinfo"}>
                    스토리를 재생성할 작품과 회차를 선택해 주세요.<br />하나의 작품의
                    회차만 추가해주세요. 여러 작품이 섞일 시 정상적인 스토리 생성이
                    불가합니다.<br/>최대 100개까지 가능 합니다. 한 회차당 글자수는 최대 4000자입니다.
                </ReuploadinfoDiv>
                <RelistcontainorDiv className={modalCss.relistcontainor + " relistcontainor"}>
                    <RelistmylistDiv className={modalCss.relistmylist + " relistmylist"}>
                        <RelistmylistTitDiv className={modalCss.tit + " tit"}>
                            <svg
                                width="25"  
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.20833 19.677H6.68147L16.3832 9.97524L14.9101 8.5021L5.20833 18.2039V19.677ZM21.875 21.7604H3.125V17.341L17.1198 3.34612C17.5267 2.93932 18.1861 2.93932 18.5929 3.34612L21.5393 6.29239C21.946 6.69919 21.946 7.35873 21.5393 7.76553L9.62775 19.677H21.875V21.7604ZM16.3832 7.02896L17.8564 8.5021L19.3295 7.02896L17.8564 5.55583L16.3832 7.02896Z"
                                    fill="#8F8F99"
                                />
                            </svg>
                            <RelistmylistTitPP>내 글</RelistmylistTitPP>
                        </RelistmylistTitDiv>
                        
                        {publishedNovels && publishedNovels.map((book) => {return(<>
                            <AiEditorSummaryListModalBook key={"bookListModal" + book.id} book={book} addEpisodeList={addEpisodeList} setAddEpisodeList={setAddEpisodeList}/>
                        </>)})}
                        
                    </RelistmylistDiv>
                    <RelistaddarrowDiv className={modalCss.relistaddarrow + " relistaddarrow"}>▶</RelistaddarrowDiv>
                    <RelistaddlistDiv className={modalCss.relistaddlist + " relistaddlist"}>
                        <RelistaddlistTitDiv className={modalCss.tit + " tit"}>
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.20833 19.677H6.68147L16.3832 9.97524L14.9101 8.5021L5.20833 18.2039V19.677ZM21.875 21.7604H3.125V17.341L17.1198 3.34612C17.5267 2.93932 18.1861 2.93932 18.5929 3.34612L21.5393 6.29239C21.946 6.69919 21.946 7.35873 21.5393 7.76553L9.62775 19.677H21.875V21.7604ZM16.3832 7.02896L17.8564 8.5021L19.3295 7.02896L17.8564 5.55583L16.3832 7.02896Z"
                                    fill="#8F8F99"
                                />
                            </svg>
                            <RelistaddlistTitPP>재생성할 회차</RelistaddlistTitPP>
                        </RelistaddlistTitDiv>
                        <RelistDiv className={modalCss.relist + " relist"}>
                            <RecateDiv className={modalCss.recate + " recate"}>▶</RecateDiv>
                            <div className={modalCss.listInfo +  " listInfo"}>
                                {addEpisodeList.length === 0 ? <p className={modalCss.title + " title"}>회차를 선택해주세요</p> : <p className={modalCss.title + " title"} style={{textAlign: "left"}}>{addEpisodeList[0].book?.title}</p>}
                                <AddhiddenListUl className={modalCss.addhidden_list + " addhidden_list addhidden-list"} id="bookList2">
                                    {addEpisodeList && addEpisodeList.map((item) => {return(<AddhiddenListLiLI key={"addList" + item.bookId + "_" + item.episodeId}>
                                        <RelistItemLabel className={modalCss.relist_item + " relist_item relist-item"}>
                                            <RelistcheckInput
                                                className={modalCss.relistcheck + " relistcheck relistcheckAdd" + item?.bookId}
                                                type="checkbox"
                                                value={item?.episodeId}
                                                checked
                                                onChange={() => {handleCheck(item?.bookId, item?.episode)}}/>
                                            <span>{item?.chapter}화</span>
                                        </RelistItemLabel>
                                    </AddhiddenListLiLI>)})}
                                </AddhiddenListUl>
                            </div>
                        </RelistDiv>
                    </RelistaddlistDiv>
                </RelistcontainorDiv>
                <ReuploadbtnButton className={modalCss.reuploadbtn + " reuploadbtn"} onClick={onClickSummaryBtn}>스토리 재생성 시작</ReuploadbtnButton>
            </ModalContentDiv> : <></> }
        </>
    );
};
export default AiEditorSummaryListModal;

/** div 태그 .modal-content css */
const ModalContentDiv = styled.div`
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
    text-align: center;
`;

/** span 태그 .close css */
const CloseSpan = styled.span`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width : 5%;
    margin-left : 95%;
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

/** div 태그 .reuploadtitle css */
const ReupLoadTitleDiv = styled.div`
    font-family: "Min Sans-bold";
    font-size: 24px;
    margin-bottom: 24px;
`;

/** div 태그 .reuploadinfo css */
const ReuploadinfoDiv = styled.div`
    font-family: "Min Sans-regular";
    font-size: 16px;
    color: #828284;
`;

/** div 태그 .relistcontainor css */
const RelistcontainorDiv = styled.div`
    display: flex;
`;

/** div 태그 .relistmylist css */
const RelistmylistDiv = styled.div`
    width: 47%;
    padding: 32px;
    border: 1px solid #dbdbdb;
    margin: 32px 0px 32px 32px;
`;

/** div 태그 .relistmylist .tit css */
const RelistmylistTitDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

/** p 태그 .relistmylist .tit p css */
const RelistmylistTitPP = styled.p`
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #8f8f99;
    margin-left: 4px;
`;

/** div 태그 .relistaddarrow css */
const RelistaddarrowDiv = styled.div`
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #989898;
`;

/** div 태그 .relistaddlist css */
const RelistaddlistDiv = styled.div`
    width: 47%;
    padding: 32px;
    border: 1px solid #dbdbdb;
    margin: 32px 32px 32px 0px;
`;

/** div 태그 .relistaddlist .tit css */
const RelistaddlistTitDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

/** p 태그 .relistaddlist .tit p css */
const RelistaddlistTitPP = styled.p`
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #8f8f99;
    margin-left: 4px;
`;

/** div 태그 .relist css */
const RelistDiv = styled.div`
    display: flex;
    color: #1a1a1a;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    margin-bottom: 32px;
    &:first-of-type {
        border-top: 1px solid #2c2c34;
    }
    &:last-of-type {
        margin-bottom: 42px;
    }
`;

/** div 태그 .recate css */
const RecateDiv = styled.div`
    margin-right: 4px;
`;

/** ul 태그 .addhidden-list css */
const AddhiddenListUl = styled.ul`
    display: block;
    margin-top: 6px;
`;

/** li 태그 .addhidden-list li css */
const AddhiddenListLiLI = styled.li`
    padding: 6px 0px;
`;

/** button 태그 .reuploadbtn css */
const ReuploadbtnButton = styled.button`
    padding: 14px 110px;
    display: inline;
    margin-bottom: 20px;
    border: none;
    background-color: #ff562c;
    color: #fff;
    border-radius: 4px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    width: 40%;
    margin-left: 30%;
`;

/** label 태그 .relist-item css */
const RelistItemLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

/** input 태그 .relistcheck css */
const RelistcheckInput = styled.input`
    margin-right: 8px;
    width: 20px;
    height: 20px;
`;
