import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";


const ModifyTab = ({action, novelId}) => {
    let action1Bool = false;
    let action2Bool = false;

    // action 값에 따른 탭 진행 활성화
    switch (action) {
        case 1:
            action1Bool = true;
            action2Bool = false;
            break;
        case 2:
            action1Bool = false;
            action2Bool = true;
            break;
    }

    const navigate = useNavigate();
    
    // 작품 기본 정보 수정 버튼
    const onClickNovel = () => {
        navigate('/modify/novel/' + novelId);
    }

    // 작품 등장인물 수정 버튼
    const onClickCharacter = () => {
        navigate('/modify/character/' + novelId);
    }

    return (
        <>
            <SynopTabSection className="synop-tab">
                <SynopTabGroupDiv className="synop-tab-group" onClick={onClickNovel}>
                    {action1Bool === true ? <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>기본정보 수정</SynopTabBtnULink></SynopTabBtnUDiv> :
                    <SynopTabBtnUDiv className="synop-tab-btn"><SynopTabBtnLink >기본정보 수정</SynopTabBtnLink></SynopTabBtnUDiv>}
                    {action1Bool === true ? <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv> : 
                    <SynopTabBtnNlineDiv className="synop-tab-btn-nline"></SynopTabBtnNlineDiv>}
                </SynopTabGroupDiv>
                <SynopTabGroupDiv className="synop-tab-group" onClick={onClickCharacter}>
                    {action2Bool === true ? <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>등장인물 수정</SynopTabBtnULink></SynopTabBtnUDiv>
                     : <SynopTabBtnDiv className="synop-tab-btn"><SynopTabBtnLink >등장인물 수정</SynopTabBtnLink></SynopTabBtnDiv>}
                    {action2Bool === true ? <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv> :
                    <SynopTabBtnNlineDiv className="synop-tab-btn-nline"></SynopTabBtnNlineDiv>}
                </SynopTabGroupDiv>
            </SynopTabSection>
        </>
    );
}
export default ModifyTab;

/** section 태그 .synop-tab css */
const SynopTabSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 108px;
    display: flex;
    justify-content: center;
`;

/** div 태그 .synop-tab-group css */
const SynopTabGroupDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
`;

/** a 태그 .synop-tab-btn > a css */
const SynopTabBtnLink = styled(Link)`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #a8a8a9;
    position: relative;
    letter-spacing: -0.3px;
`;

/** div 태그 .synop-tab-btn-nline css */
const SynopTabBtnNlineDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    height: 4px;
    background-color: #ededed;
    margin-top: 12px;
`;

/** div 태그 .synop-tab-btn-line css */
const SynopTabBtnLineDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    height: 4px;
    background-color: #ff5f37;
    margin-top: 12px;
`;

/** div 태그 .synop-tab-btn-u css */
const SynopTabBtnUDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: center;
`;

/** div 태그 .synop-tab-btn css */
const SynopTabBtnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: center;
`;

/** a 태그 .synop-tab-btn-u > a css */
const SynopTabBtnULink = styled(Link)`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #ff5f37;
    position: relative;
    letter-spacing: -0.3px;
    text-decoration: none;
`;
