import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import changePasswordCss from '../../css/chage_password.module.css'
import { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import sign2Css from '../../css/sign2.module.css'
import { apiServerState } from "../../constants/recoilData";


// 비밀 번호 변경 컴포넌트
const ChangePassword = () => {

    const [nowPassword, setNowPassword] = useState('');

    // 현재 비밀번호 입력 변경 함수
    const onChangeNowPassword = (e) => {
        setNowPassword(e.target.value);
    };

    const [password, setPassword] = useState('');

    // 비밀번호 입력 변경 함수
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const [password2, setPassword2] = useState('');

    // 비밀번호 확인 입력 변경 함수
    const onChangePassword2 = (e) => {
        setPassword2(e.target.value);
    };

    const [errorMessage, setErrorMessage] = useState('');
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();
    const token = getCookie("token");

    const [passwordRegExCheckBool, setPasswordRegExCheckBool] = useState(true);
    const [passwordRegExCheckMessage, setPasswordRegExCheckMessage] = useState("");

    // 비밀번호 정규식 체크 함수
    const passwordRegExCheck = (e) => {
        const passwordRegEx = new RegExp(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*\d).{8,20}$/)
        const userPassword = e.target.value;
        const bool = passwordRegEx.test(userPassword);
        if (bool) {
            setPasswordRegExCheckMessage("사용할수 있는 비밀번호입니다.");
        } else {
            setPasswordRegExCheckMessage("비밀번호가 바르지 않습니다. 비밀번호는 숫자 + 영어 + 특수문자(!@#$%^&*) 8~20자만 가능합니다.");
        }
        setPasswordRegExCheckBool(bool);
    }

    const [password2RegExCheckBool, setPassword2RegExCheckBool] = useState(true);
    const [password2RegExCheckMessage, setPassword2RegExCheckMessage] = useState("");

    // 비밀번호 확인 비밀번호 비교 체크 함수
    const password2RegExCheck = (e) => {
        const userPassword2 = e.target.value;
        if (password === userPassword2) {
            setPassword2RegExCheckMessage("");
            setPassword2RegExCheckBool(true);
        } else {
            setPassword2RegExCheckMessage("비밀번호가 다릅니다.");
            setPassword2RegExCheckBool(false);
        }
        
    }

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 비밀번호 변경 버튼
    const onClickChangePassword = () => {
        if (passwordRegExCheckBool === false) {
            alert(`비밀번호 형식이 바르지 않습니다.`);
            return;
        }
        if(nowPassword === "") {
            alert("현재 비밀번호를 입력해주세요")
            return;
        } else if (nowPassword === null || nowPassword === undefined) {
            alert("현재 비밀번호를 입력해주세요")
            return;
        }
        if (password === "") {
            alert("새로운 비밀번호를 입력해주세요")
            return;
        } else if (password === null || password === undefined) {
            alert("새로운 비밀번호를 입력해주세요")
            return;
        }
        if (password !== password2) {
            alert(`새로운 비밀번호가 일치하지 않습니다.`);
            return;
        }

        const form = {oldPassword: nowPassword, plainPassword: password};

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        };

        axios
        .post(apiServer + "/api/asiac_login/change_password", form, config)
        .then((response) => {
            if (response?.data?.success) {
                alert(`정상적으로 비밀번호를 변경했습니다.`);
                navigate(URL.LOGIN);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                    alert("다시 로그인 후 이용해주세요.");
                    setErrorMessage("다시 로그인 후 이용해주세요.");
                    logout();
                } else if (msg === 'invalid user') {
                    alert(`비밀번호 다르거나 없는 유저입니다.`);
                    setErrorMessage("비밀번호 다르거나 없는 유저입니다.");
                } else if (msg === 'router err') {
                    alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                    setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                    setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                }
                console.log(msg);
            }
        })
        .catch((e) => {
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    }

    // 비밀번호 변경 입력 후 enter 입력시 비밀번호 변경 함수 실행
    const handleOnKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickChangePassword();
        }
    }

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    return (
        <>
            <div id="wrap">
                <FindContainerDiv id="findContainer">
                    <FindBoxDiv className={changePasswordCss.findBox + " findBox"}>
                        <DefaultH4>비밀번호 변경</DefaultH4>
                        <UserInputDiv className={changePasswordCss.userInput + " userInput"}> 
                            <InputRowDiv className={changePasswordCss.inputRow + " inputRow"}>
                                <LabelLabel htmlFor="nowPassword" className={changePasswordCss.label + " label"}>현재 비밀번호</LabelLabel>
                                <UserInputInput type="password" id="nowPassword" placeholder="현재 비밀번호를 입력해주세요" onChange={onChangeNowPassword}/>
                            </InputRowDiv>
                            <DefaultHr/>
                            <InputRowDiv className={changePasswordCss.inputRow + " inputRow"}>
                                <div className={changePasswordCss.descript + " descript"}>
                                    <LabelLabel htmlFor="password" className={changePasswordCss.label + " label"}>새로운 비밀번호</LabelLabel>
                                    <DescriptSpanSpan>8~20자, 숫자+영어+특수문자(!@#$%^&*) 혼합</DescriptSpanSpan>
                                </div>
                                <UserInputPasswordInput type="password" id="password" placeholder="비밀번호를 입력해주세요" onChange={(e) => {onChangePassword(e); passwordRegExCheck(e)}}/>
                                {passwordRegExCheckBool === false ? <div className={sign2Css.descript + " descript"} style={{marginTop : "10px", marginBottom: "24px"}}>
                                    <DescriptSpanSpan >{passwordRegExCheckMessage}</DescriptSpanSpan>
                                </div> : <div style={{marginBottom: "24px"}}></div>}
                            </InputRowDiv>
                            <InputRowDiv className={changePasswordCss.inputRow + " inputRow"}>
                                <LabelLabel htmlFor="confirmPassword" className={changePasswordCss.input + " label"}>비밀번호 한번 더 입력</LabelLabel>
                                <UserInputInput type="password" id="confirmPassword" placeholder="비밀번호를 한번 더 입력해주세요" onChange={(e) => {onChangePassword2(e); password2RegExCheck(e)}} onKeyPress={handleOnKeyPress}/>
                                {password2RegExCheckBool === false ? <div className={sign2Css.descript + " descript"} style={{marginTop : "10px"}}>
                                    <DescriptSpanSpan >{password2RegExCheckMessage}</DescriptSpanSpan>
                                </div> : <></>}
                            </InputRowDiv>
                            {errorMessage !== '' ?<div className={changePasswordCss.descript + " descript"} style={{textAlign:"center", marginTop: "10px"}}>
                                <DescriptSpanSpan style={{textAlign:"center"}}>{errorMessage}</DescriptSpanSpan>
                            </div> : <></>}
                            <SaveNextBtnButton type="submit" className={changePasswordCss.saveNextBtn + " saveNextBtn"} onClick={onClickChangePassword}><DefaultALink >비밀번호 변경</DefaultALink></SaveNextBtnButton>
                        </UserInputDiv>
                    </FindBoxDiv>
                </FindContainerDiv>
            </div>
        </>
    );
}
export default ChangePassword;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** div 태그 .findContainer css */
const FindContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .findBox css */
const FindBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
`;

/** h4 태그 디폴트 css */
const DefaultH4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-top: 140px;
    margin-bottom: 82px;
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    color: #d9d9d9;
    margin: 42px 0;
`;

/** div 태그 .userInput css */
const UserInputDiv = styled.div`
    width: 100%;
`;

/** input 태그 .userInput input css */
const UserInputInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
`;
/** input 태그 .userInput #password input css */
const UserInputPasswordInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-bottom: 0px;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
`;

/** div 태그 .inputRow css */
const InputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

/** label 태그 .label css */
const LabelLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
`;

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 52px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    margin-bottom: 240px;
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
`;