import styled from "@emotion/styled";
import MyPageLeftAside from "../../components/common/MyPageLeftAside";
import MyInfo from "../../components/my/MyInfo";
import PaymentDetail from "../../components/my/PaymentDetail";
import URL from "../../constants/url";
import { useState } from "react";
import { Route, Routes } from "react-router";


const AizacMyPage = () => {

    const [changeBool, setChangeBool] = useState(false);


    return (
        <>
            <WrapDiv id="wrap">
                <MyPageLeftAside changeBool={changeBool}/>
                <Routes>
                    <Route path={URL.MY_INFO} element={<MyInfo changeBool={changeBool} setChangeBool={setChangeBool}/>}/>
                    <Route path={URL.PAYMENT_DETAIL} element={<PaymentDetail />}/>
                </Routes>
            </WrapDiv>
        </>
    );
}
export default AizacMyPage;

/** div 태그 #wrap css */
const WrapDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto; 
    height: 100vh;
    @media(max-width:767px) {
        display: block;
    }
`;