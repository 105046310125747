import styled from "@emotion/styled";
import aiEditor2CSs from '../../css/ai_editor2.module.css'
import { useNavigate, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import URL from "../../constants/url";
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import axios from "axios";
//import ReactTextareaAutosize from "react-textarea-autosize";
import AiEditorRightAside from "../../components/common/AiEditorRightAside";
import Swal from "sweetalert2";
import AiEditorSummaryListModal from "./AiEditorSummaryListModal";
import AiEditorSummaryFileModal from "./AiEditorSummaryFileModal";
import $ from "jquery"; 


const AiEditorCreateNovel = ({isUpdateBool, setIsUpdateBool, textTitle, setTextTitle, textContent, setTextContent}) => {

    const params = useParams();
    const novelId = params.novelId;
    const sessionId = params.sessionId;

    const [novelData, setNovelData] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [episodeData, setEpisodeData] = useState(null);
    const [scenesData, setScenesData] = useState([{"episodeId": sessionId,
        "scene": textContent,
        "title": textTitle}]);
    const [isType,] = useState(true);

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie('token');
    const navigate = useNavigate();
    const [isExtendCheck, setIsExtendCheck] = useState(false);

    const textAreaRef = useRef(null);

    // 글 내용이 변경되면 자동으로 textarea 스크롤 맨위로 이동 

    const config = {
        headers: {
          "Content-Type": "application/json",
          "authorization": token,
        }
    };

    // 제목, 내용 변경시 실행
    useEffect(() => {
        const copy = [{"episodeId": sessionId,
        "scene": textContent,
        "title": textTitle}];
        setScenesData(copy);
    }, [textTitle, textContent])

    // 에피소드 상세 내역 상태 저장, 유저정보 상태 저장 api 호출(novelId, sessionId 변경시 호출)
    useEffect(() => {
        const update = () => {
            const url = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + sessionId;
        
            const editorUpdate = async () => {
                await axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success) {
                        console.log(response?.data?.data?.episode?.title);
                        setTextTitle(response?.data?.data?.episode?.title);
                        setTextContent(response?.data?.data?.episode?.content);
                        setNovelData(response?.data?.data?.novel);
                        setEpisodeData(response?.data?.data?.episode);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "invalid novel") {
                            alert("없는 소설 또는 비공개 소설입니다.");
                            navigate(URL?.MAIN);
                        } else if (msg === "invalid episode") {
                            alert("없는 에피소드 또는 비공개 에피소드입니다.");
                            navigate(URL?.MAIN);
                        } else if (msg === "need more coin") {
                            alert("코인이 부족합니다.");
                            navigate(URL?.LIST_CHARGE_SHOP);
                        } else if (msg === "router err") {
                            alert("서버 에러");
                            navigate("/list/novel/" + novelId);
                        } else {
                            alert(msg);
                            navigate("/list/novel/" + novelId);
                        }
                        console.log("로딩 실패!");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e?.message);
                    console.log(e);
                    navigate(URL?.ERROR, {state: {msg: e?.message}});
                });
            };

            const updateUserInfo = () => {
                const urlUserInfo = apiServer + "/api/asiac_login/get_my_info";
            
                const userInfo = async () => {
                    await axios
                    .get(urlUserInfo, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setUserInfo(response?.data?.data?.user);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                removeCookie("token");
                                removeCookieOption("token", {path : "/"});
                                localStorage.removeItem("pageData");
                                localStorage.removeItem("pageDataSynop");
                                localStorage.removeItem("categoryNotice");
                                localStorage.removeItem("categoryPay");
                                localStorage.removeItem("myPicturePageData");
                                localStorage.removeItem("rowCnt");
                                window.localStorage.clear();
                                navigate(URL?.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL?.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                removeCookie("token");
                                removeCookieOption("token", {path : "/"});
                                localStorage.removeItem("pageData");
                                localStorage.removeItem("pageDataSynop");
                                localStorage.removeItem("categoryNotice");
                                localStorage.removeItem("categoryPay");
                                localStorage.removeItem("myPicturePageData");
                                localStorage.removeItem("rowCnt");
                                window.localStorage.clear();
                                navigate(URL?.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL?.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e?.message);
                        console.log(e);
                        navigate(URL?.ERROR, {state: {msg: e?.message}});
                    });
                };
            
                if (token !== undefined) {
                    userInfo();
                }
            };
            updateUserInfo();
        
    
            editorUpdate();

        };
        update();
        textAreaRef.current.scrollTop = 0;
        setKey(key + 1);
        $('#yourTextareaId').scrollTop(0);
    }, [novelId, sessionId])
    
    // 유저 id와 소설 id 비교 후 자신의 작품이 아니면 실행(유저정보, 소설정보 상태 업데이트시 실행)
    useEffect(() => {
        if (userInfo !== null && novelData !== null) {
            if(userInfo?.id !== novelData?.userId) {
                alert("비정상적인 접근입니다.");
                navigate(URL?.MAIN);
            }
        }
        textAreaRef.current.scrollTop = -1000;
        setKey(key + 1);
    }, [userInfo, novelData])

    // 제목 상태 변경 함수
    const onChangeTitle = (e) => {
        setTextTitle(e.target.value);
    };

    // 내용 상태 변경 함수
    const onChangeText = (e) => {
        setTextContent(e.target.value);
    };

    // 저장 api 호출 
    const SaveBtn = async () => {
        // 추가 기능 사항 예정
        if (isExtendCheck === true) {
            alert("생성중입니다. 생성 후 저장해주세요.");
            return;
        }

        const confirmationMessage =
            "아이작에 집필한 회차를 공개하시겠습니까?";
            // 저장 버튼 클릭시 sweetalert2 호출
            const scenesDataCheck = (String(scenesData[0].title)).trim();
            if (scenesDataCheck === "") {
                alert("내용을 입력해주세요.");
                return;
            }
        Swal.fire({
            title: "저장 안내",
            text: confirmationMessage,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "공개 저장",
            denyButtonText: `비공개 저장`,
            reverseButtons: true,
        }).then((result) => {
            // 공개 저장 버튼 누를시 (저장 api 호출)
            if (result.isConfirmed) {
                const textTitleCheck = (String(textTitle)).trim();
                if (textTitleCheck === "") {
                    alert("제목을 입력해 주세요!");
                    return;
                }
                if (String(textTitle).length > 250) {
                    alert("제목은 250자 이내로 입력해 주세요!");
                    return;
                }
                if(!(textTitleCheck.length > 0)) {
                    alert("제목을 입력해주세요.");
                    return;
                } else if (textTitleCheck === null || textTitleCheck === undefined) {
                    alert("제목을 입력해주세요.");
                    return;
                }
                const textContentCheck = (String(textContent)).trim();
                if (textContentCheck === "") {
                    alert("내용을 입력해 주세요!");
                    return;
                }
                if(!(textContentCheck.length > 0)) {
                    alert("내용을 입력해주세요.");
                    return;
                } else if (textContentCheck === null || textContentCheck === undefined) {
                    alert("내용을 입력해주세요.");
                    return;
                }
                if (!(scenesData.length > 0)) { 
                    alert("씬 내용을 입력해주세요.");
                    return;
                } else  if (scenesData === null || scenesData === undefined) {
                    alert("씬 내용을 입력해주세요.");
                    return;
                }
                let form = {"id": parseInt(sessionId),
                        "novelId": novelId
                        ,"chapter": sessionId === "0" ? (parseInt(novelData?.episodeCnt) + 1) : episodeData?.chapter
                        ,"title": textTitleCheck
                        ,"story": novelData?.synopsis
                        ,"pubState" : "public"
                        ,"scenes": scenesData};
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };

                axios
                    .post(apiServer + "/api/asiac_novel/update_episode", form, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            alert("저장하였습니다.");
                            console.log("저장 완료");
                            navigate(URL?.CREATE_MAIN);
                        } else {
                            const msg = response?.data?.custMsg
                            if(msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 이용해주세요.");
                                removeCookie("token");
                                removeCookieOption("token", {path : "/"});
                                localStorage.removeItem("pageData");
                                localStorage.removeItem("pageDataSynop");
                                localStorage.removeItem("categoryNotice");
                                localStorage.removeItem("categoryPay");
                                localStorage.removeItem("myPicturePageData");
                                localStorage.removeItem("rowCnt");
                                window.localStorage.clear();
                                const token = getCookie("token");
                                if (token !== undefined) {
                                    removeCookieOption("token", {path : "/"});
                                    navigate(URL?.MAIN);
                                } else {
                                    navigate(URL?.MAIN);
                                }
                            } else if (msg === "invalid novel") {
                                alert("비정상적인 접근입니다.");
                            } else if (msg === "router failed") {
                                alert("서버 에러");
                            } else {
                                alert("저장 실패");
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e?.message);
                        console.log(e);
                        navigate(URL?.ERROR, {state: {msg: e?.message}});
                    });

            } else if(result.isDenied) {
                const textTitleCheck = (String(textTitle)).trim();
                if (textTitleCheck === "") {
                    alert("제목을 입력해 주세요!");
                    return;
                }
                if (String(textTitle).length > 250 ) {
                    alert("제목은 250자 이내로 입력해 주세요!");
                    return;
                }
                if(!(textTitleCheck.length > 0)) {
                    alert("제목을 입력해주세요.");
                    return;
                } else if (textTitleCheck === null || textTitleCheck === undefined) {
                    alert("제목을 입력해주세요.");
                    return;
                }
                const textContentCheck = (String(textContent)).trim();
                if (textContentCheck === "") {
                    alert("내용을 입력해 주세요!");
                    return;
                }
                if(!(textContentCheck.length > 0)) {
                    alert("내용을 입력해주세요.");
                    return;
                } else if (textContentCheck === null || textContentCheck === undefined) {
                    alert("내용을 입력해주세요.");
                    return;
                }
                if (scenesData.length === 0) {
                    alert("씬 내용을 입력해주세요.");
                    return;
                } else  if (scenesData === null || scenesData === undefined) {
                    alert("씬 내용을 입력해주세요.");
                    return;
                }
                let form = {"id": parseInt(sessionId),
                        "novelId": novelId
                        ,"chapter": sessionId === "0" ? (parseInt(novelData?.episodeCnt) + 1) : episodeData?.chapter
                        ,"title": textTitleCheck
                        ,"story": novelData?.synopsis
                        ,"pubState" : "private"
                        ,"scenes": scenesData};
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };

                axios
                    .post(apiServer + "/api/asiac_novel/update_episode", form, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            alert("비공개 저장하였습니다.");
                            console.log("저장 완료");
                            navigate(URL?.CREATE_MAIN);
                        } else {
                            const msg = response?.data?.custMsg
                            if(msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 이용해주세요.");
                                removeCookie("token");
                                removeCookieOption("token", {path : "/"});
                                localStorage.removeItem("pageData");
                                localStorage.removeItem("pageDataSynop");
                                localStorage.removeItem("categoryNotice");
                                localStorage.removeItem("categoryPay");
                                localStorage.removeItem("myPicturePageData");
                                localStorage.removeItem("rowCnt");
                                window.localStorage.clear();
                                const token = getCookie("token");
                                if (token !== undefined) {
                                    removeCookieOption("token", {path : "/"});
                                    navigate(URL.MAIN);
                                } else {
                                    navigate(URL.MAIN);
                                }
                            } else if (msg === "invalid novel") {
                                alert("비정상적인 접근입니다.");
                            } else if (msg === "router failed") {
                                alert("서버 에러");
                            } else {
                                alert("저장 실패");
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e?.message);
                        console.log(e);
                        navigate(URL?.ERROR, {state: {msg: e?.message}});
                    });
            } else { // 취소버튼 누를시
                console.log("취소하였습니다.");
            }
        });
    }

    // 다른 이름 저장
    const onClickNewNameSaveBtn = () => {
        if (isExtendCheck === true) {
            alert("생성중입니다. 생성 후 저장해주세요.");
            return;
        }
        // sweetalert 호출
        Swal.fire({
            title: "제목을 입력해 주세요",
            input: "text",
            inputPlaceholder: "제목 입력",
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#BDBDBD",
            confirmButtonText: "저장",
            cancelButtonText: "취소",
            reverseButtons: true,
            showCancelButton: true,
            inputValidator: (value) => {
                // 제목 미입력시
                if (!value) {
                    return "제목을 입력해 주세요!";
                } else if(value ===  null || value === undefined) {
                    return "제목을 입력해 주세요!";
                }
                if (String(value).length > 250) {
                    return "제목은 250자 이내로 입력해 주세요!";
                }
            },
          }).then((result) => {
            // 저장 버튼(업데이트 ai 에디터 api 호출)
            if (result.isConfirmed) {
                const sceneCheck = (String(scenesData[0]?.scene)).trim();
                if (sceneCheck === "") {
                    alert("내용을 입력해주세요.");
                    return;
                }
                if (String(scenesData[0]?.scene).length <= 0) {
                    alert("내용을 입력해주세요.");
                    return;    
                }
                if (scenesData.length === 0) {
                    alert("내용을 입력해주세요.");
                }
                const data = {
                    "id": 0
                    ,"title": result.value
                    ,"content": scenesData[0]?.scene
                }
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };

                axios
                    .post(apiServer + "/api/aizac_editor/update_editor", data, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            alert("저장하였습니다.");
                            console.log("저장 완료");
                            navigate(URL?.CREATE_MAIN);
                        } else {
                            const msg = response?.data?.custMsg;
                            if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 진행해주세요.");
                                removeCookie("token");
                                removeCookieOption("token", {path : "/"});
                                localStorage.removeItem("pageData");
                                localStorage.removeItem("pageDataSynop");
                                localStorage.removeItem("categoryNotice");
                                localStorage.removeItem("categoryPay");
                                localStorage.removeItem("myPicturePageData");
                                localStorage.removeItem("rowCnt");
                                window.localStorage.clear();
                                const token = getCookie("token");
                                if (token !== undefined) {
                                    removeCookieOption("token", {path : "/"});
                                    navigate(URL?.MAIN);
                                } else {
                                    navigate(URL?.MAIN);
                                }
                            } else if (msg === "invalid info") {
                                alert("제목, 내용을 확인해주세요.");
                            } else if (msg === "router failed") {
                                alert("서버 에러");
                            } else {
                                alert(msg);
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e?.message);
                        console.log(e);
                        navigate(URL?.ERROR, {state: {msg: e?.message}});
                    });
            }
          });
    }

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        if (textAreaRef.current) {
            textAreaRef.current.scrollTop = -1000;
        }
        $('#yourTextareaId').scrollTop(0);
    }, []);
    const [key, setKey] = useState(0);

    return(
        <>
            <ContainerDiv id="container">
                <TopDiv className={aiEditor2CSs.top + " top"}>
                    <LeftDiv className={aiEditor2CSs.left + " left"}>
                        <ContainerH2Input type="text" id="title" name="title" placeholder="제목을 입력해주세요" value={textTitle} onChange={(e) => {onChangeTitle(e)}}/>
                    </LeftDiv>
                    <RightDiv className={aiEditor2CSs.right + " right"}>
                        <p style={{marginTop: "inherit", marginBottom: "0"}}>글자수( {textContent !== null ? textContent.length : 0} 자 )</p>
                        <SaveBtnButton type="button" className={aiEditor2CSs.saveBtn + " saveBtn"} onClick={SaveBtn}>저장</SaveBtnButton>
                        <SaveBtnButton type="button" className={aiEditor2CSs.saveBtn + " saveBtn"} onClick={onClickNewNameSaveBtn}>다른 이름으로 저장</SaveBtnButton>
                    </RightDiv>
                </TopDiv>
                <ContentDiv className={aiEditor2CSs.content + " content"}>
                    <textarea value={textContent} id="yourTextareaId" key={key} style={{fontFamily: "Min Sans-Light",
                        color:"#22222A",
                        letterSpacing: "-0.03em",
                        lineHeight: "2",
                        width : "100%",
                        height : "85%",
                        fontSize: "18px",
                        whiteSpace: "pre-wrap",
                        outline: "none",
                        borderWidth: "0"}} onChange={(e) => {onChangeText(e)}} ref={(el) => {textAreaRef.current = el}}>
                    </textarea>
                </ContentDiv>
            </ContainerDiv>
            <AiEditorRightAside textData={textContent} setTextData={setTextContent} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} isType={isType} setIsExtendCheck={setIsExtendCheck}/>
            <ModalDiv id="relist" className="modal" >
                <AiEditorSummaryListModal/>
            </ModalDiv>
            <ModalDiv id="reupload" className="modal" >
                <AiEditorSummaryFileModal/>
            </ModalDiv>
        </>
    );
};
export default AiEditorCreateNovel;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 140px;
    overflow-y: clip;
    @media(min-width: 100px) and (max-width:768px) {
        padding: 0 22px;
    }
`;

/** div 태그 .top css */
const TopDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 10px;
    letter-spacing: -0.03em;
    padding-top: 54px;
    position: sticky;
    top: 0;
    background-color: #fff;
    @media(min-width:100px) and (max-width:768px) {
        flex-direction: column;
    }
`;

/** div 태그 .left css */
const LeftDiv = styled.div`
    width: 50%;
    margin-right: 0px;
    display: flex;
    letter-spacing: -0.3px;
    gap: 4px;
`;

/** h2 태그 #container h2 css 
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-weight: inherit;
    font-size: inherit;
    font-family: "Min Sans-Medium";
    font-size: 32px;
    letter-spacing: -0.03em;
    margin-left: 4px;
    @media(min-width:100px) and (max-width:768px) {
        font-size: 24px;
        padding-bottom: 17px;
        font-family: "min sans-bold";
    }
`;*/

/** input 태그 #container h2 css */
const ContainerH2Input = styled.input`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-weight: inherit;
    font-size: inherit;
    font-family: "Min Sans-Medium";
    font-size: 24px;
    letter-spacing: -0.03em;
    margin-left: 4px;
    outline: none;
    border-width: 0;
    width: 100%;
    &:focus {outline: none;}
    @media(min-width:100px) and (max-width:768px) {
        font-size: 24px;
        padding-bottom: 17px;
        font-family: "min sans-bold";
    }
`;

/** div 태그 .right css */
const RightDiv = styled.div`
    display: flex;
    font-size: 15px;
    font-family: "Min Sans-Medium";
    gap: 10px;
    color: #828284;
    align-items: center;
    letter-spacing: -0.3px;
    @media(min-width:100px) and (max-width:768px) {
        margin-left: auto;
    }
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 24px;
    padding-bottom: 40px;
    height: 100%;
    @media(min-width:100px) and (max-width:768px) {
        padding-top: 42px;
    }
`;

/** p 태그 .content p css 
const ContentPP = styled.p`
    font-family: "Min Sans-Light";
    color:#22222A;
    letter-spacing: -0.03em;
    line-height: 2;
    font-size: 18px;
    white-space: pre-wrap;
`;*/

/** button 태그 .saveBtn css */
const SaveBtnButton = styled.button`
    background-color: #fff;
    color: #828284;
    border: 1px solid #BDBDBD;
    border-radius: 20px;
    padding: 8px 21px;
    font-family: "Min Sans-Medium";
    cursor: pointer;
    letter-spacing: -0.3px;
    &:hover{
        background-color: #22222A;
        color: #fff;
        border: 1px solid #22222A;
        transition: ease-in 0.15s;
    }
`;
/** div 태그 .modal css */
const ModalDiv = styled.div`
    display: none; /* 기본 상태는 보이지 않음 */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;