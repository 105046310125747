import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import menu_line from '../../css/images/menu-line.png';
import logo_1 from '../../css/images/logo-l.png';
import user_1 from '../../css/images/user-1.svg';
import URL from '../../constants/url';
import styled from '@emotion/styled';
import headerCss from "../../css/main.module.css";
import { getCookie } from '../../hooks/Cookie';
import $ from 'jquery';


// 메인 상위 header 컴포넌트
const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const token = getCookie("token");

  const navigate = useNavigate();

  const onClickMain = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    navigate(URL.MAIN)
  }
  
  const onClickVeteran = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    navigate(URL.LIST_VETERAN);
  }
  
  const onClickFreezon = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    navigate(URL.LIST_FREEZON);
  }
  
  const onClickDrawingStore = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    navigate(URL.LIST_DRAWING_STORE)
  }
  
  const onClickNotice = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    const data = {"category" : "synop01", "page" : 1};
    localStorage.setItem("categoryNotice", JSON.stringify(data));
    navigate(URL.LIST_NOTICE);
  }
  
  const onClickCreateMain = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    navigate(URL.CREATE_MAIN);
  }
  
  const onClickLogin = () => {
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    navigate(URL.LOGIN);
  }

  $(document).ready(function(){
    $('.nav-item').on('click', function(){
      $('.navbar-toggler').click() //bootstrap 3.x by Richard
    });
    $('.nav-link').on('click', function(){
      $('.navbar-toggler').click() //bootstrap 3.x by Richard
    });
  
   });


  return (
    <>
      <CustomNavbarNav className={"custom_navbar navbar navbar-expand-md navbar-dark " + headerCss.custom_navbar + " " + headerCss.navbar + " " + headerCss.navbar_expand_md + " " + headerCss.navbar_dark}>
        <div className={headerCss.container + " container"}>
          <NavbarBrandLink className={headerCss.navbar_brand + " navbar_brand"} to={URL.MAIN}>
            <NavLogoImg src={logo_1} alt="logo_1" className={headerCss.nav_logo + ' nav_logo'}/>
          </NavbarBrandLink>
          <CustomNavbarTogglerButton
            className={headerCss.navbar_toggler + " navbar-toggler"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
            collapseOnSelect
          >
            <DefaultImg src={menu_line} alt="menu_line" />
          </CustomNavbarTogglerButton>
          <NavbarCollapseDiv className={headerCss.collapse + " " + headerCss.navbar_collapse + " collapse navbar-collapse"} id="navbarsFurni">
            <CustomNavbarNavUl className={headerCss.custom_navbar_nav + " " + headerCss.navbar_nav + " " + " custom_navbar_nav navbar-nav ms-auto mb-2 mb-md-0"}>
              {pathname === URL.MAIN ? <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickMain}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link nav_link"}>
                  랭킹
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi onClick={onClickMain}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                  랭킹
                </CustomNavbarNavLiALink>
              </DefaultLi>}
              {pathname === URL.LIST_VETERAN ? <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickVeteran}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  베테랑
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi onClick={onClickVeteran}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  베테랑
                </CustomNavbarNavLiALink>
              </DefaultLi>}
              {pathname === URL.LIST_FREEZON ? <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickFreezon}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                  프리존
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi onClick={onClickFreezon}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  프리존
                </CustomNavbarNavLiALink>
              </DefaultLi>}
              {pathname === URL.LIST_DRAWING_STORE ? <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickDrawingStore}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  그림스토어
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi onClick={onClickDrawingStore}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  그림스토어
                </CustomNavbarNavLiALink>
              </DefaultLi>}
              {pathname === URL.CREATENOVEL ? <> {token !== undefined ?<DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickCreateMain}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  집필실
                </CustomNavbarNavLiALink>
              </DefaultLi> :
              <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickLogin}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  집필실
                </CustomNavbarNavLiALink>
              </DefaultLi>}</> : <> {token !== undefined ? <DefaultLi onClick={onClickCreateMain}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                  집필실
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi onClick={onClickLogin}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                  집필실
                </CustomNavbarNavLiALink>
              </DefaultLi>} </>}
              {pathname === URL.LIST_NOTICE ? <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"} onClick={onClickNotice}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                공지사항
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi onClick={onClickNotice}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} >
                  공지사항
                </CustomNavbarNavLiALink>
              </DefaultLi> }
              {pathname === URL.LIST_CHARGE_SHOP ? <DefaultLi className={headerCss.nav_item + " " + headerCss.active + " nav-item active"}>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} to={URL.LIST_CHARGE_SHOP}>
                  충전소
                </CustomNavbarNavLiALink>
              </DefaultLi> : <DefaultLi>
                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"} to={URL.LIST_CHARGE_SHOP}>
                  충전소
                </CustomNavbarNavLiALink>
              </DefaultLi>}
            </CustomNavbarNavUl>
            <CustomNavbarCtaUl className={headerCss.custom_navbar_cta + " " + headerCss.navbar_nav + " custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5"}>
              {token === undefined ? <CustomNavbarCtaLi>
                <NavLinkALink className={headerCss.nav_link + " nav-link"} to={URL.LOGIN}>
                  <DefaultImg src={user_1} alt="user_1" />
                </NavLinkALink>
              </CustomNavbarCtaLi> : <CustomNavbarCtaLi>
                <NavLinkALink className={headerCss.nav_link + " nav-link"} to={URL.MY_PAGE_MY_INFO}>
                  <DefaultImg src={user_1} alt="user_1" />
                </NavLinkALink>
              </CustomNavbarCtaLi>}
            </CustomNavbarCtaUl>
          </NavbarCollapseDiv>
        </div>
      </CustomNavbarNav>
    </>
  );
};

export default Header;

/** li 태그 default css */
const DefaultLi = styled.li`
  @media (min-width: 768px) and (max-width: 780px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

/** img 태그 디폴트 css */
const DefaultImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** img 태그 .nav-logo css */
const NavLogoImg = styled.img`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  height: 24px;
  @media (max-width: 780px) {
    height: 18px;
  }
`;

/** nav 태그 .custom-navbar css */
const CustomNavbarNav = styled.nav`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  background: #fff !important;
  height: 120px;
  @media (max-width: 780px) {
    background-color: #fff;
    height: 50px;
    padding: 0 18px;
    margin: 0 auto;
    margin-bottom: 12px;
  }
  
`;

/** button 태그 .custom-navbar .navbar-toggler css */
const CustomNavbarTogglerButton = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  border-color: transparent;
  :active {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  };
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
  @media (max-width: 780px) {
    padding: 4px 0px;
  }
`;

/** a 태그 .navbar-brand css */
const NavbarBrandLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    margin-left: 16px;
    @media (max-width: 780px) {
      margin-left: 0px;
    }
    @media (min-width: 769px) and (max-width: 780px) {
      padding-right: 0px;
    }
`;

/** a 태그 .custom-navbar .custom-navbar-nav li a css */
const CustomNavbarNavLiALink = styled(Link)`
  text-decoration: none;
  color: #050409 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
  font-display: optional;
  &:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 8px;
    right: 8px;
    background: #ff562c;
    height: 2px;
    opacity: 1;
    visibility: visible;
    width: 0;
    -webkit-transition: 0.15s all ease-out;
    -o-transition: 0.15s all ease-out;
    transition: 0.15s all ease-out;
  }
  &:hover {
    opacity: 1;
  }
  &:hover:before {
    width: calc(100% - 16px);
  }
  @media (min-width: 768px) and (max-width: 780px) {
    font-size: 12px;
  }
`;

/** a 태그 .custom-navbar .custom-navbar-nav li a css 
const CustomNavbarNavLiActiveALink = styled(Link)`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  list-style: none;
  text-decoration: none;
  color: #050409 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
  opacity: 1;
  :before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 8px;
    right: 8px;
    background: #ff562c;
    height: 2px;
    opacity: 1;
    visibility: visible;
    width: 0;
    -webkit-transition: 0.15s all ease-out;
    -o-transition: 0.15s all ease-out;
    transition: 0.15s all ease-out;
    width: calc(100% - 16px);
  }
  &:hover {
    opacity: 1;
  }
  &:hover:before {
    width: calc(100% - 16px);
  }
  @media (max-width:1400px) {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0px 10px;
  }
  @media (max-width: 560px) {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 12px 18px;
    &:before {
      margin: 10px 14px;
      bottom: -16px;
    }
  }
  
`;*/

/** ul 태그 .custom-navbar .custom-navbar-cta css */
const CustomNavbarCtaUl = styled.ul`
  margin-left: 20px !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
`;

/** li 태그 .custom-navbar .custom-navbar-cta li css */
const CustomNavbarCtaLi = styled.li`
    margin-left: 0px;
    margin-right: 0px;
    &:first-of-type {
      margin-right: 20px;
    }
`;

/** div 태그 .navbar > .container css 
const NavbarContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 560px) {
      padding: 0px;
    }
    @media (max-width: 568px) {
      padding: 0 18px;
    }
`;*/

/** div 태그 .navbar-collapse css */
const NavbarCollapseDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 768px) {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      top: 60px;
      right: 0px;
      width: 100%;
    }
    @media (min-width: 769px) and (max-width: 780px) {
      font-size: 12px;
      width: 75%;
      top: 0px;
      right: 25%;
      padding-left: 0px;
    }
    @media (min-width: 768px) and (max-width: 768px) {
      font-size: 12px;
      width: 75%;
      top: 0px;
      right: 30%;
      padding-left: 40px;
    }
    @media (min-width: 568px) and (max-width: 768px) {
      padding-left: 10%;
      padding-right: 10%;
    }
    @media (max-width: 568px) {
      padding-left: 0px;
      padding-right: 0px;
    }
`;

/** ul 태그 .custom-navbar-nav 기본 디폴트 css  */
const CustomNavbarNavUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** li 태그 .custom-navbar-nav li css 
const CustomNavbarNavLiLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    &.active a {
      opacity: 1;
      &:before {
        width: calc(100% - 16px);
      }
    }
`;*/

/** a 태그 .nav-link css */
const NavLinkALink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    padding: 0px 22px;
`;