import styled from "@emotion/styled";
import SynopTab from "../../components/common/SynopTab";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import novel3Css from '../../css/novelCreate3.module.css'
import { apiServerState } from "../../constants/recoilData";
import Select from "react-select";
import Swal from "sweetalert2";


const NovelCreate3 = ({isUpdateBool, setIsUpdateBool}) => {

    const params = useParams();

    // 최초 랜더링시 소설정보 셋팅
    useEffect(() => {
        const urlNovel = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + params.novelId;

        const novelInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlNovel, config)
            .then((response) => {
                if (response?.data?.success) {
                    setNovel(response?.data?.data?.novel);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "invalid novel") {
                        alert("없는 소설 또는 비공개 소설 입니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        novelInfo();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    
    const apiServer = useRecoilValue(apiServerState);
    
    const token = getCookie("token");
    
    const [novel, setNovel] = useState(null);
    
    const [state, setState] = useState([])
    
    const [userInfo, setUserInfo] = useState(null);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }
    
    // 소설정보, 유저정보 업데이트시 소설 userId, 유저 id 비교(비정상접근 방지)
    useEffect(() => {
        if(novel !== null && userInfo !== null) {
            if(novel.userId !== userInfo.id) {
                alert("비정상적인 접근입니다.");
                navigate(URL.MAIN);
            }
        }
    }, [novel, userInfo])

    // isUpdateBool 변경시 유저정보 업데이트(api 코인차감 업데이트 용도)
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])

    // index 역할 변경 입력 함수
    const onChangeRole = (e, index) => {
        const copy = [...state];
        const role = e.value;
        copy[index].role = role;
        setState(copy);
    }

    // index 이름 변경 입력 함수
    const onChangeName = (e, index) => {
        let copy = [...state];
        const name = e.target.value;
        copy[index].name = name;
        setState(copy);
    }

    // index 성별 변경 입력 함수
    const onChangeGender = (e, index) => {
        let copy = [...state];
        const gender = e.target.value;
        copy[index].gender = gender;
        setState(copy);
    }
    
    // index 나이 변경 입력 함수
    const onChangeAge = (e, index) => {
        let copy = [...state];
        const age2 = (e.target.value).replace(/[^0-9]/g, "")
        copy[index].age = age2;
        setState(copy);
    }
    
    // index 직업 변경 입력 함수
    const onChangeJob = (e, index) => {
        let copy = [...state];
        const job = e.target.value;
        copy[index].job = job;
        setState(copy);
    }
    
    // index 트라우마 변경 입력 함수
    const onChangeTrauma = (e, index) => {
        let copy = [...state];
        const trauma = e.target.value;
        copy[index].trauma = trauma;
        setState(copy);
    }

    // index 캐릭터 특징 변경 입력 함수
    const onChangeCharacteristics = (e, index) => {
        let copy = [...state];
        const characteristics = e.target.value;
        copy[index].characteristics = characteristics;
        setState(copy);
    }

    // 등장인물 추가 버튼
    const onClickAddButton = () => {
        const data = {"id": 0, "state": true, "novelId": novel.id, "role": '', "name": '', "gender": '', "age": '', "job":'', "trauma": '', "characteristics" : '',};
        let copy = [...state]
        copy.push(data);
        setState(copy);
    }

    // index 캐릭터 삭제 버튼
    const onClickDeleteButton = (indexNum) => {
        let copy = [...state]
        copy = copy.filter(function(_, index) {
            return index !== indexNum;
        })
        setState(copy);
    }

    const navigate = useNavigate();

    // 이대로 저장 버튼
    const onClickSaveButton = () => {
        const stateLength = state.length;
        if(stateLength === 0) {
            alert("등장인물을 추가해주세요.");
            return;
        }
        let form = {novelId: novel.id, characters:[]};
        let characterList = [];
        for (let i = 0; i < stateLength; i++ ) {
            if (state[i].state === true) {
                //let characterForm = new FormData();
                //characterForm.append("id", 0);
                //characterForm.append("novelId", novel.id);
                const nameCheck = (String(state[i].name)).trim();
                if (nameCheck === "") {
                    alert(`등장인물#${(i + 1)} 이름을 입력해주세요.`);
                    return;
                }
                if (state[i].name === '') {
                    alert(`등장인물#${(i + 1)} 이름을 입력해주세요.`);
                    return
                } else if (state[i].name === null || state[i].name === undefined) {
                    alert(`등장인물#${(i + 1)} 이름을 입력해주세요.`);
                    return
                }
                const genderCheck = (String(state[i].gender)).trim();
                if (genderCheck === "") {
                    alert(`등장인물#${(i + 1)} 성별을 입력해주세요.`);
                    return;
                }
                if (state[i].gender === '') {
                    alert(`등장인물#${(i + 1)} 성별을 입력해주세요.`);
                    return
                } else if (state[i].gender === null || state[i].gender === undefined) {
                    alert(`등장인물#${(i + 1)} 성별을 입력해주세요.`);
                    return
                }
                const ageCheck = (String(state[i].age)).trim();
                if (ageCheck === "") {
                    alert(`등장인물#${(i + 1)} 나이를 입력해주세요.`);
                    return;
                }
                if (state[i].age === '') {
                    alert(`등장인물#${(i + 1)} 나이를 입력해주세요.`);
                    return
                } else if (state[i].age === null || state[i].age === undefined) {
                    alert(`등장인물#${(i + 1)} 나이를 입력해주세요.`);
                    return
                }
                const jobCheck = (String(state[i].job)).trim();
                if (jobCheck === "") {
                    alert(`등장인물#${(i + 1)} 직업을 입력해주세요.`);
                    return;
                }
                if (state[i].job === '') {
                    alert(`등장인물#${(i + 1)} 직업을 입력해주세요.`);
                    return
                } else if (state[i].job === null || state[i].job === undefined) {
                    alert(`등장인물#${(i + 1)} 직업을 입력해주세요.`);
                    return
                }
                const characterForm = {id: 0, novelId: novel.id, role: state[i].role, name: state[i].name, gender: state[i].gender, age: state[i].age, job: state[i].job, trauma: state[i].trauma, characteristics : state[i].characteristics};
                characterList.push(characterForm);
            }
            
        }
        form.novelId = novel.id;
        form.characters = characterList;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };

        axios
            .post(apiServer + "/api/asiac_novel/update_characters", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    setIsUpdateBool(!isUpdateBool);
                    navigate(URL.CREATE_NOVEL3_1 + novel.id);
                } else {
                    const msg = response?.data?.custMsg
                    if(msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === "invalid novel") {
                        alert("비정상적인 접근입니다.");
                    } else if (msg === "router failed") {
                        alert("서버 에러");
                    } else {
                        alert("저장 실패");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }
    
    const [isMountedCreateCharacter, setIsMountedCreateCharacter] = useState(true);

    // 캐릭터 ai 생성 버튼
    const onClickCreateCharacterBtn = () => {
        Swal.fire({
            title : "요금 안내",
            text: "자동 생성은 1회 당 50코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50 || userInfo?.coinAuth === "unlimited") {
                    setIsMountedCreateCharacter(false);
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            "authorization": token,
                        }
                    };
    
                    let form = {synopsis: ""};
                    form.synopsis = novel.synopsis;
                    axios
                        .post(apiServer + "/api/asiac_nestyle/initiate/characters", form, config)
                        .then((response) => {
                            if (response?.data?.success ) {
                                setIsMountedCreateCharacter(true)
                                const characters = response.data.data.characters
                                const charactersLength = characters.length;

                                let dataCopy = [...state];
    
                                for(let i = 0; i < charactersLength; i++) {
                                    const data = {"id": characters[i].id, "state": true,
                                                "novelId": novel.id, 
                                                "role": characters[i].role, 
                                                "name": characters[i].name, 
                                                "gender": characters[i].gender, 
                                                "age": characters[i].age, 
                                                "job": characters[i].job, 
                                                "trauma": characters[i].trauma,
                                                "characteristics" : characters[i].characteristics,};
                                    dataCopy.push(data);
                                }

                                setState(dataCopy);
                                setIsUpdateBool(!isUpdateBool);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용 해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다.");
                                } else if (msg === "axios err" || msg === "ai generator err") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("서버 에러");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsMountedCreateCharacter(true);
                                setIsUpdateBool(!isUpdateBool);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setIsMountedCreateCharacter(true);
                        });
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
        
        
    }

    const roleData = [{value : "주인공", label : "주인공"}, {value : "조력자", label : "조력자"}, {value : "적대자", label : "적대자"}, {value : "기타", label : "기타"}];

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    return(
        <>
            <ContainerDiv id="container">
                <SynopTab action={3} />
                <SynopSelectSection className={novel3Css.synop_select + " synop_select synop-select"} >
                    <SynopSelectTitleH1 className={novel3Css.synop_select_title + " synop_select_title synop-select-title"} >등장인물 정보를 입력해주세요</SynopSelectTitleH1>
                    <DefaultHr />
                    {state && state.map((data, index) => { return(<CharacterDiv id={("character-" + index)} className={novel3Css.character + " character"} style={{display: "block"}} key={("state" + index)}>
                        <CharacterTitleDiv className={novel3Css.character_title + " character_title character-title"}>
                            <SynopSelectSubDiv className={novel3Css.synop_select_sub + " synop_select_sub synop-select-sub"}>등장인물 #{(index + 1)}</SynopSelectSubDiv>
                        </CharacterTitleDiv>
                        <CharacterBoxDiv className={novel3Css.character_box + " character_box character-box"}>
                            <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>역할</SubTitleDiv>
                            {data?.role === "기타" ? <NovelNameInputSelect className={novel3Css.novel_name_input + " novel_name_input novel-name-input"} name="role" defaultValue={roleData[3]} options={roleData} onChange={(e) => {onChangeRole(e, index)}} styles={{control: base => ({
                                    ...base,
                                    border: 0,
                                    boxShadow: 'none'
                                })}}/>
                            : data?.role === "조력자" ? <NovelNameInputSelect className={novel3Css.novel_name_input + " novel_name_input novel-name-input"} name="role" defaultValue={roleData[1]} options={roleData} onChange={(e) => {onChangeRole(e, index)}} styles={{control: base => ({
                                ...base,
                                border: 0,
                                boxShadow: 'none'
                            })}}/>
                            : data?.role === "적대자" ? <NovelNameInputSelect className={novel3Css.novel_name_input + " novel_name_input novel-name-input"} name="role" defaultValue={roleData[2]} options={roleData} onChange={(e) => {onChangeRole(e, index)}} styles={{control: base => ({
                                ...base,
                                border: 0,
                                boxShadow: 'none'
                            })}}/>
                            : <NovelNameInputSelect className={novel3Css.novel_name_input + " novel_name_input novel-name-input"} name="role" defaultValue={roleData[0]} options={roleData} onChange={(e) => {onChangeRole(e, index)}} styles={{control: base => ({
                                ...base,
                                border: 0,
                                boxShadow: 'none'
                            })}}/>}
                            {/*<NovelNameInputInput
                                type="text"
                                className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                placeholder="역할을 입력해주세요(ex 주인공, 서브 주인공 등)"
                                onChange={(e) => {onChangeRole(e, index)}}
                                value={data.role}
                            />*/}
                            <ColumnDiv className={novel3Css.column + " column"}>
                                <DefaultDiv className={novel3Css.col + " col"}>
                                    <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>이름</SubTitleDiv>
                                    <NovelNameInputInput
                                        type="text"
                                        className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                        placeholder="이름을 입력해주세요"
                                        onChange={(e) => {onChangeName(e, index)}}
                                        value={data?.name}
                                    />
                                <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>성별</SubTitleDiv>
                                    <NovelNameInputInput
                                        type="text"
                                        className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                        placeholder="성별을 입력해주세요 ex)여성, 남성"
                                        onChange={(e) => {onChangeGender(e, index)}}
                                        value={data?.gender}
                                        defaultValue="미정"
                                    />
                                <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>나이</SubTitleDiv>
                                    <NovelNameInputInput
                                        type="number"
                                        min={0}
                                        className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                        placeholder="나이를 입력해주세요"
                                        onChange={(e) => {onChangeAge(e, index)}}
                                        value={data?.age}
                                    />
                                <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>직업</SubTitleDiv>
                                    <NovelNameInputInput
                                        type="text"
                                        className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                        placeholder="직업을 입력해주세요"
                                        onChange={(e) => {onChangeJob(e, index)}}
                                        value={data?.job}
                                    />
                                </DefaultDiv>
                            </ColumnDiv>
                            <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>캐릭터 특징</SubTitleDiv>
                            <NovelNameInputInput
                                type="text"
                                className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                placeholder="인물의 특징을 입력해주세요(ex 특징 소개)"
                                onChange={(e) => {onChangeCharacteristics(e, index)}}
                                value={data?.characteristics}
                            />
                            <SubTitleDiv className={novel3Css.sub_title + " sub_title sub-title"}>트라우마</SubTitleDiv>
                            <NovelNameInputInput
                                type="text"
                                className={novel3Css.novel_name_input + " novel_name_input novel-name-input"}
                                placeholder="인물의 트라우마, 배경을 입력해주세요(ex 이세계에서 유명한 일류 귀족 가문의 천재 마법사 등)"
                                onChange={(e) => {onChangeTrauma(e, index)}}
                                value={data?.trauma}
                            />
                        </CharacterBoxDiv>
                        <TextEndDiv className={novel3Css.text_end + " text_end text-end"} style={{display: "block"}} >
                            <CharacterDeleteButton className={novel3Css.character_delete + " character_delete character-delete"} id="delete-character-1" onClick={() => {onClickDeleteButton(index)}}>
                                삭제
                            </CharacterDeleteButton>
                        </TextEndDiv>
                    </CharacterDiv>)})}
                    {isMountedCreateCharacter !== false ? <CharacterAddButton className={novel3Css.character_add + " character_add character-add"} onClick={onClickAddButton} >
                        +등장인물 추가
                    </CharacterAddButton> : <></>}
                    {isMountedCreateCharacter !== false ? <CharacterAddButton className={novel3Css.character_add + " character_add character-add"} onClick={onClickCreateCharacterBtn}>
                        + 캐릭터 ai생성
                    </CharacterAddButton>: <SynopSelectSubDiv className={novel3Css.synop_select_sub + " synop_select_sub synop-select-sub"} style={{marginTop : "10px"}}>캐릭터 생성중입니다 잠시만 기다려주세요.</SynopSelectSubDiv>}
                    <EditNextButton className={novel3Css.edit_next + " edit_next edit-next"} type="button" onClick={onClickSaveButton}>저장하고 다음으로</EditNextButton>
                </SynopSelectSection>
            </ContainerDiv>
        </>
    );
};

export default NovelCreate3;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    overflow: scroll;
    padding: 0 140px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** section 태그 .synop-select css  */
const SynopSelectSection = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 76px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 60px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: none;
    height: 1px;
    background-color: #ededed;
    width: 100%;
    margin: 32px 0px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 18px;
        margin-bottom: 42px;
    }
`;

/** div 태그 .character css */
const CharacterDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    margin-bottom: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-bottom: 0;
    }
`;

/** div 태그 .Character-title css */
const CharacterTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

/** div 태그 .synop-select-sub css */
const SynopSelectSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 18px;
    color: #050409;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 16px;
    }
`;

/** div 태그 .character-box css */
const CharacterBoxDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    margin: 10px 0px 16px 0px;
    padding: 38px 42px;
    border: 1px solid #22222a;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 20px 18px;
    }
`;

/** div 태그 .sub-title css */
const SubTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 14px;
    color: #828284;
    @media (max-width: 1400px) {
        padding-top: 10px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        color: #050409;
        padding-top: 0;
    }
`;

/** input 태그 .novel-name-input css */
const NovelNameInputInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    border: 1px solid #bdbdbd;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    color: #050409;
    padding: 16px 16px;
    letter-spacing: -0.3px;
    margin-top: 4px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 14px;
        padding: 12px 16px;
        margin-bottom: 16px;
    }
`;

/** select 태그 .novel-name-input css */
const NovelNameInputSelect = styled(Select)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    border: 1px solid #bdbdbd;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    color: #050409;
    padding: 16px 16px;
    letter-spacing: -0.3px;
    margin-top: 4px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 14px;
        padding: 12px 16px;
        margin-bottom: 16px;
    }
    & .Select {
        border-color: white;
        border : none;
        &control {
            border : none;
            box-shadow: none;
            border-color: white;
        }
        &__control {
            border : none;
            box-shadow: none;
            border-color: white;
            &--is-focused {
                border: none;
                box-shadow: none;
                border-color: white;
            }
            &--menu-is-open {
                border : none;
                box-shadow: none;
                border-color: white;
                &:hover {
                    border : none;
                    box-shadow: none;
                    border-color: white;
                }
            }
        }
        &__menu {
            border : none;
            box-shadow: none;
            border-color: white;
        }
        &__option {
            border : none;
            box-shadow: none;
            border-color: white;
            &--is-selected {
                border : none;
                box-shadow: none;
                border-color: white;
            }
            &--is-focused {
                border: none;
                box-shadow: none;
                border-color: white;
            }
        }
    }
`;

/** div 태그 .column css */
const ColumnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    column-count: 4;
    margin: 12px 0px 12px 0px;
    @media (max-width: 1400px) {
        column-count: 2;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        margin: 0;
        column-count: 1;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** div 태그 .text-end css */
const TextEndDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: end;
`;

/** button 태그 .character-delete css */
const CharacterDeleteButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 10px 30px;
    border-radius: 20px;
    background-color: #f6f6f6;
    color: #a8a8a9;
    font-size: 15px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
        background-color: #e8e8e8;
    }
`;

/** button 태그 .character-add css */
const CharacterAddButton = styled.button`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 10px 30px;
    border-radius: 20px;
    background-color: #ff5f37;
    color: #fff;
    font-size: 15px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
        background-color: #ff7957;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 10px 40px;
    }
`;

/** button 태그 .edit-next css */
const EditNextButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    background-color: #22222a;
    color: #fff;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 50px 0px 120px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #303039;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 40px;
        margin-bottom: 80px;
    }
`;

