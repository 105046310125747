import veteranCss from '../../css/veteran.module.css';
import styled from "@emotion/styled";
import 'bootstrap/dist/css/bootstrap.min.css';
import ListSelectTab from '../../components/common/ListselectTab';
import { useEffect } from 'react';


// 베테랑 페이지 컴포넌트
const Veteran = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    return (
        <>
            <div className={veteranCss.container + " container"}>
                <RankVeteranSection className={veteranCss.rank_veteran + " rank-veteran"}>
                    <section className={veteranCss.synop_select + " synop-select"}>
                        <RankTitleDiv className={veteranCss.rank_title + " rank-title"}>
                            <RankTitleH2H2>프로작가 연재 게시판</RankTitleH2H2>
                            <RankTitleH1H1>아이작 베테랑</RankTitleH1H1>
                        </RankTitleDiv>
                        <ListSelectTab type={"veteran"}/>
                    </section>
                </RankVeteranSection>
            </div>
        </>
    );
};

export default Veteran;

/** section 태그 .rank-veteran css */
const RankVeteranSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0px 0px;
    margin-bottom: 120px;

`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 60px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 20px 0px 0px;
    }
`;

/** h1 태그 .rank-title h1 css */
const RankTitleH1H1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 46px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    padding-bottom: 22px;
    padding-top: 8px;
`;

/** h2 태그 .rank-title h2 css */
const RankTitleH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 20px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 0;
`;
