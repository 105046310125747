import { Link } from "react-router-dom";
import eye10 from "../../css/images/eye-10.svg"
import shoppingCart11 from "../../css/images/shopping-cart-11.svg"
import styled from "@emotion/styled";
import storeCss from '../../css/drawing_store.module.css'
import URL from "../../constants/url";
import defaultImg from '../../css/images/illustDefault.png'

const ColIllustration = ({imgData, type}) => {

    const noImage = (e) => {
        e.target.src = defaultImg
      }
    
    return(
        <>
            {imgData && <ColDiv className={storeCss.col + " col"} key={`${type.type}img_${imgData?.id}_`}>
                <DefaultLinkA to={`${URL.LIST_ILLUST_ID_INPUT}${imgData?.id}`}>
                    <IlluViewDiv className={storeCss.illu_view + " p-3 illu-view"}>
                        {imgData?.imgUrl !== "" ? <IlluViewImgImg src={imgData?.imgUrl} alt={`${type.type}_${imgData.id}`} onError={noImage}/> : <IlluViewImgImg src={defaultImg} alt={`defaltImg`}/>}
                        {(String(imgData?.title)).length < 15 ? <NovelViewTitleDiv className={storeCss.novel_view_title + " novel_view_title"}>{(String(imgData?.title)).trim()}</NovelViewTitleDiv> :
                        <NovelViewTitleDiv className={storeCss.novel_view_title + " novel_view_title"}>{((String(imgData?.title)).trim()).substring(0,13) + " ..."}</NovelViewTitleDiv>}
                        <NovelViewSubDiv className={storeCss.novel_view_sub + " novel-view-sub"}>
                            <DefaultSpan>{imgData?.nickname} 작가</DefaultSpan>
                        </NovelViewSubDiv>
                        <IlluViewSubDiv className={storeCss.illu_view_sub + " illu_view_sub"}>
                            <DefaultDiv className="icon">
                                <DefaultSpan><DefaultImg src={eye10} alt="eye10"/></DefaultSpan>
                                <IlluViewCountSpan className={storeCss.illu_view_count + " illu_view_count"}>{parseInt(imgData?.viewCnt) >= 1000 ? (parseInt(imgData?.viewCnt) / 1000).toFixed(1) + "k" : parseInt(imgData?.viewCnt) }</IlluViewCountSpan>
                                <DefaultSpan><DefaultImg src={shoppingCart11} alt="cart"/></DefaultSpan>
                                <IlluSellCountSpan className={storeCss.illu_sell_count + " illu_sell_count"}>{imgData?.soldCnt}</IlluSellCountSpan>
                            </DefaultDiv>
                            <IlluPriceSpan className={storeCss.illu_price + " illu_price"}>{imgData?.price}c</IlluPriceSpan>
                        </IlluViewSubDiv>
                    </IlluViewDiv>
                </DefaultLinkA>
            </ColDiv>}
        </>
    );
};

export default ColIllustration;


/** div 태그 .col css */
const ColDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0px;
`;

/** div 태그 .illu-view css */
const IlluViewDiv = styled.div`
    width: 330px;
    height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

/** a 태그 디폴트 css */
const DefaultLinkA = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
`;

/** img 태그 .illu-view > img css */
const IlluViewImgImg = styled.img`
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
`;

/** div 태그 .novel-view-title css */
const NovelViewTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    font-size: 17px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-top: 12px;
`;

/** div 태그 .novel-view-sub css */
const NovelViewSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #9b9b9d;
    letter-spacing: -0.3px;
`;

/** span 태그 디폴트 css */
const DefaultSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
`;

/** div 태그 .illu-view-sub css */
const IlluViewSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #050409;
    letter-spacing: -0.3px;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

/** span 태그 .illu-view-count css */
const IlluViewCountSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    margin: 0px 2px;
    
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    @media (max-width: 450px) {
        display: grid;
    }
`;

/** img 태그 디폴트 css */
const DefaultImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** span 태그 .illu-sell-count css */
const IlluSellCountSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin: 0px 2px;
`;

/** span 태그 .illu-price css */
const IlluPriceSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    float: right;
    font-size: 20px;
    color: #ff562c;
    font-family: "Min Sans-bold";
`;