import { Route, Routes } from "react-router";
import Footer from "../../components/common/Footer";

import URL from "../../constants/url";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../components/common/Header";
import Freezon from "../../components/list/Freezon";
import DrawingStore from "../../components/list/DrawingStore";
import Notice from "../../components/list/Notice";
import Veteran from "../../components/list/Veteran";
import TopButton from "../../components/common/TopButton";
import ChargeShop1 from "../../components/list/ChargeShop1";
import Novel from "../../components/list/Novel";
import Illust from "../../components/list/Illust";
import NoticeDetail from "../../components/list/NoticeDetail";
import { useState } from "react";


const AizacList = () => {

    const [isAlone,] = useState(true);

    return (
        <>
            <TopButton isAlone={isAlone}/>
            <Header/>
            <Routes>
                <Route path={URL.VETERAN} element={<Veteran/>}/>
                <Route path={URL.FREEZON} element={<Freezon/>}/>
                <Route path={URL.DRAWING_STORE} element={<DrawingStore/>}/>
                <Route path={URL.NOTICE} element={<Notice/>}/>
                <Route path={URL.NOTICE_ID} element={<NoticeDetail/>}/>
                <Route path={URL.CHARGE_SHOP} element={<ChargeShop1/>}/>
                <Route path={URL.NOVELID} element={<Novel/>}/>
                <Route path={URL.ILLUSTID} element={<Illust/>}/>
            </Routes>
            <Footer/>
        </>
    )
}

export default AizacList;