import styled from '@emotion/styled';
import mypageCss from '../../css/mypage_account.module.css'
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import { useRecoilValue } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import URL from '../../constants/url';
import { useNavigate } from 'react-router';
import { apiServerState } from '../../constants/recoilData';
import sign2Css from '../../css/sign2.module.css'
import MyPageAction from './MyPageAction';


const MyPageUserInput = ({changeBool, setChangeBool}) => {

    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    
    const [user, setUser] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [nickName, setNickname] = useState("");

    const [isEditBtnBool, setEditBtnBool] = useState(false);
    const [isCheckNickName, setIsCheckNickName] = useState(false);
    const [nickNameCheckMassege, setNickNameCheckMassege] = useState('');

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 로그인 검사
    useEffect(() => {
        const token = getCookie("token");
        if (token === undefined) {
            navigate("/login");
        }
    }, [])

    // 최초 랜더링시 로그인 상태 유저 정보 셋팅
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
        const update = () => {

            const urlEpi = apiServer + "/api/asiac_login/get_my_info";
            
            const urlUserCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUser(response?.data?.data?.user);
                            setNickname(response?.data?.data?.user?.nickname);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };

            if(token !== undefined) {
                urlUserCall();
            }
            
        };
        update();
        
    }, [])
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
        const update = () => {

            const urlEpi = apiServer + "/api/asiac_login/get_my_info";
            
            const urlUserCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUser(response?.data?.data?.user);
                            setNickname(response?.data?.data?.user?.nickname);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };

            if(token !== undefined) {
                urlUserCall();
            }
            
        };
        update();
        
    }, [changeBool])

    // 닉네임 저장 버튼
    const onChangeNickName = (e) => {
        setNickname(e.target.value);
        setIsCheckNickName(false);
        nickNameCheckRef.current.style.display = "block";
    };

    // 닉네임 수정/저장 버튼
    const onClickEdit = () => {
        if ($("#nickNameCheck").text() === "수정") {
            $('#nickName').attr("disabled",false);
            setEditBtnBool(true);
            $('#nickNameCheck').text("저장");
        } else {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : token,
                }
            };
            if (isCheckNickName === false) {
                alert('닉네임 중복확인을 해주세요');
                return;
            } else {
                const update = () => {
                    if (nickName === "") {
                        alert("필명을 입력해주세요.");
                        return;
                    } else if (nickName === null || nickName === undefined) {
                        alert("필명을 입력해주세요.");
                        return;
                    }
                    const nickNameCheck2 = (String(nickName)).trim();
                    if (nickNameCheck2 === "") {
                        alert("필명을 입력해주세요!");
                        return;
                    }
                    const urlEpi = apiServer + "/api/asiac_login/change_nickname";
                    const form = {"nickname" : nickName};
                    
                    const urlChangeNickNameCall = async () => {
                        axios
                            .post(urlEpi, form, config)
                            .then((response) => {
                                if (response?.data?.success) {
                                    setChangeBool(!changeBool);
                                    setEditBtnBool(false);
                                    setIsCheckNickName(true);
                                    return;
                                } else {
                                    const msg = response?.data?.custMsg;
                                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                        alert("다시 로그인 후 이용해주세요.");
                                        logout();
                                    } else if (msg === 'invalid nickname') {
                                        alert("닉네임을 입력해주세요.");
                                    } else if (msg === 'duplicate nickname') {
                                        alert("닉네임이 중복되었습니다 다시 입력해주세요.");
                                    } else if (msg === 'invalid user') {
                                        alert("없는 유저입니다. 다시 로그인해주세요.");
                                    }  else if (msg === 'router err') {
                                        alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                                    } else {
                                        alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                                    }
                                    console.log(msg);
                                }
                            })
                            .catch(e => {  // API 호출이 실패한 경우
                                alert(e.message);
                                console.log(e);
                                navigate(URL.ERROR, {state : {msg : e.message}});
                            });
                    };
        
                    urlChangeNickNameCall();
                    
                };
                update();
                $('#nickName').attr("disabled", true);
                $('#nickNameCheck').text("수정");
                setNickNameCheckMassege('');
            }
            
        }
    }

    // 닉네임 중복확인 버튼
    const onClickNickNameCheck = () => {

        if (nickName !== '') {
            if (nickNameRegExCheckBool) {
                const form = {"nickname" : nickName};
    
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };
    
                axios
                .post(apiServer + "/api/asiac_login/check_nickname", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        nickNameCheckRef.current.style.display = "none";
                        setNickNameCheckMassege("사용가능한 닉네임 입니다.")
                        setIsCheckNickName(true);
                        alert("사용가능한 닉네임 입니다.");
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === 'duplicate nickname') {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("중복된 닉네임 입니다.");
                        } else if (msg === 'invalid nickname') {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("잘못된 형식의 닉네임 입니다.");
                        } else if (msg === 'router err') {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("서버오류");
                        } else {
                            nickNameCheckRef.current.style.display = "none";
                            setNickNameCheckMassege("서버오류");
                        }
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            }
        } 

    }

    const [nickNameRegExCheckBool, setNickNameRegExCheckBool] = useState(true);
    const [nickNameRegExCheckMessage, setNickNameRegExCheckMessage] = useState("");
    const nickNameCheckRef = useRef();

    // 닉네임 정규식 검사
    const nickNameRegExCheck = (e) => {
        setNickNameCheckMassege('');
        const nickNameRegEx = new RegExp(/^[가-힣a-zA-Z0-9]{2,10}$/)
        const userNickName = e.target.value;
        const bool = nickNameRegEx.test(userNickName);
        if (bool) {
            setNickNameRegExCheckMessage("사용가능한 닉네임양식 입니다.");
        } else {
            setNickNameRegExCheckMessage("닉네임이 바르지 않습니다");
        }
        if (userNickName === '') {
            setNickNameRegExCheckMessage('');
        }
        setNickNameRegExCheckBool(bool);
    }

    // 유저 타입 변경 버튼
    const onClickUsertypeBtn = () => {
        navigate(URL.SIGN_USER_TYPE);
    }

    // 계좌 변경 버튼
    const onClickEditAccount = () => {
        navigate(URL.SIGN_ACCOUNT_CHANGE);
    }

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (user?.ikind === "author") {
            if (user?.authorKind === "solo_free") {
                setInputValue("작가(개인 프리랜서)");
            } else if (user?.authorKind === "solo_proprietor") {
                setInputValue("개인 사업자");
            } else if (user?.authorKind === "corp_business") {
                setInputValue("법인 사업자");
            } else {
                setInputValue("작가");
            }
        } else if (user?.ikind === "normal") {
            setInputValue("유저");
        }
    }, [user, changeBool])

    return (
        <>
            {isMounted !== false ? <><UserInputSection className={mypageCss.userInput + " userInput"}>
                <TopDiv className={mypageCss.top + " top"}>
                    <TopInputRowDiv className={mypageCss.inputRow + " inputRow"}>
                        <LabelLabel htmlFor="email" className={mypageCss.label + " label"}>아이디</LabelLabel>
                        <DefaultInput type="email" id="email" placeholder="aizacaizac0000@gmail.com" disabled defaultValue={user?.email}/>      
                    </TopInputRowDiv>
                    <TopInputRowDiv className={mypageCss.inputRow + " inputRow"}>
                        <LabelLabel htmlFor="realName" className={mypageCss.label + " label"}>이름</LabelLabel>
                        <DefaultInput type="text" id="realName" placeholder="김철수" disabled defaultValue={user?.name}/>
                    </TopInputRowDiv>
                </TopDiv>
                <BottomDiv className={mypageCss.bottom + " bottom"}>
                    <BottomInputRowDiv className={mypageCss.inputRow + " inputRow"}>
                        <LabelLabel htmlFor="userType" className={mypageCss.label + " label"}>계정 유형</LabelLabel>
                        <OverlapDiv className={mypageCss.overlap + " overlap"}>
                            <OverlapNickNameInput type="userType" id="userType" placeholder="아이작" disabled defaultValue={inputValue} />
                            <CheckButtonButton id="userTypeBtn" className={mypageCss.checkButton + " checkButton"} onClick={onClickUsertypeBtn}>수정</CheckButtonButton>
                        </OverlapDiv>
                    </BottomInputRowDiv>
                    <BottomInputRowDiv className={mypageCss.inputRow + " inputRow"}>
                        <LabelLabel htmlFor="nickname" className={mypageCss.label + " label"}>필명</LabelLabel>
                        <OverlapDiv className={mypageCss.overlap + " overlap"}>
                            <OverlapNickNameInput type="nickname2" id="nickName" placeholder="아이작" disabled defaultValue={nickName} onChange={(e) => {onChangeNickName(e); nickNameRegExCheck(e)}}/>
                            {isEditBtnBool === true ? <CheckButtonButton id="nickNameCheckBtn" className={mypageCss.checkButton + " checkButton"} onClick={onClickNickNameCheck}>중복확인</CheckButtonButton> : <></>}
                            <CheckButtonButton id="nickNameCheck" className={mypageCss.checkButton + " checkButton"} onClick={onClickEdit}>수정</CheckButtonButton>
                        </OverlapDiv>
                        <div className={sign2Css.descript + " descript"} ref={(el) => {nickNameCheckRef.current = el}}>
                            {nickNameRegExCheckMessage === '사용가능한 닉네임양식 입니다.' ? <DescriptSpanSpan style={{color: "green"}}>{nickNameRegExCheckMessage}</DescriptSpanSpan> : nickNameRegExCheckMessage === '' ? <></> : <DescriptSpanSpan>{nickNameRegExCheckMessage}</DescriptSpanSpan>}
                        </div>
                        {nickNameCheckMassege !== '' ? <div className={sign2Css.descript + " descript"}>
                            {nickNameCheckMassege === '사용가능한 닉네임 입니다.' ? <DescriptSpanSpan style={{color: "green"}}>{nickNameCheckMassege}</DescriptSpanSpan> : <DescriptSpanSpan>{nickNameCheckMassege}</DescriptSpanSpan>}
                        </div> : <></>}
                    </BottomInputRowDiv>
                </BottomDiv>
                <BottomDiv className={mypageCss.bottom + " bottom"}>
                    <BottomInputRowDiv className={mypageCss.inputRow + " inputRow"}>
                        <LabelLabel htmlFor="userType" className={mypageCss.label + " label"}>정산 계좌</LabelLabel>
                        <OverlapDiv className={mypageCss.overlap + " overlap"}>
                            {user?.accountNumber !== null ? user?.accountNumber !== '' ? <OverlapNickNameInput type="text" id="userAccountNumber" disabled defaultValue={user?.accountNumber} /> :
                            <OverlapNickNameInput type="text" id="userAccountNumber" disabled placeholder='계좌 미등록' /> : 
                            <OverlapNickNameInput type="text" id="userAccountNumber"  disabled placeholder='계좌 미등록' />}
                            <CheckButtonButton id="userAccountNumberBtn" className={mypageCss.checkButton + " checkButton"} onClick={onClickEditAccount}>수정</CheckButtonButton>
                        </OverlapDiv>
                    </BottomInputRowDiv>
                </BottomDiv>
            </UserInputSection>
            <MyPageAction/></> : <></>}
        </>
    );
};
export default MyPageUserInput;

/** section 태그 .userInput css */
const UserInputSection = styled.section`
    padding:32px 0;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    @media(max-width:568px) {
        padding: 42px 0;
    }
`;

/** div 태그 .top css */
const TopDiv = styled.div`
    display: flex;
    gap: 28px;
    @media (max-width: 1400px) {
        flex-direction: column;
        gap: 0;
    }
    @media(max-width:568px) {
        flex-direction: column;
        gap: 0;
    }
`;

/** div 태그 .top .inputRow css */
const TopInputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
`;

/** label 태그 .label css */
const LabelLabel = styled.label`
    margin-bottom: 8px;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    font-family: "min sans-regular";
`;

/** input 태그 디폴트 css */
const DefaultInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border-radius: 4px;
    border: 1px solid #BDBDBD;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    font-family: "min sans-regular";
    &::placeholder{
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
        font-family: "min sans-regular";
    }
    &:disabled {
        background-color: #EDEDED;
    }
`;

/** input 태그 .overlap type="nickname" css */
const OverlapNickNameInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border-radius: 4px;
    border: 1px solid #BDBDBD;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    font-family: "min sans-regular";
    flex: 1;
    width: auto;
    margin-right: 12px;
    &::placeholder{
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
        font-family: "min sans-regular";
    }
    &:disabled {
        background-color: #EDEDED;
    }
    @media (min-width: 359px) and (max-width: 768px) {
        width: 100%;
    }
    @media (min-width: 100px) and (max-width: 359px) {
        width: 100%;
        margin-bottom: 10px;
    }
`;

/** div 태그 .bottom css */
const BottomDiv = styled.div`
    width: 100%;
    display: flex;
    gap: 28px;
    @media (max-width: 1400px) {
        flex-direction: column;
        gap: 0;
    }
    @media(max-width:568px) {
        width: 100%;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        display: block;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        display: block;
    }
`;

/** div 태그 .bottom .inputRow css */
const BottomInputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 12px);
    margin-bottom: 24px;
    @media (max-width: 1400px) {
        width: 100%;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        display: block;
    }
`;

/** div 태그 .overlap css */
const OverlapDiv = styled.div`
    display: flex;
    @media (min-width: 100px) and (max-width: 359px) {
        display: block;
    }
`;

/** button 태그 .checkButton css */
const CheckButtonButton = styled.button`
    color: #fff;
    background-color: #FF5F37;
    font-size: 16px;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
    padding: 18px 20px;
    border: 1px solid #ff5f37;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    &:hover{
        background-color: #ff7957;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

/** 추가 */

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;