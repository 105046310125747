import styled from "@emotion/styled";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ColNovel from "./ColNovel";
import URL from "../../constants/url";
import ColIllustration from "./ColIllustration";
import veteranCss from '../../css/veteran.module.css'
import { useRecoilValue } from "recoil";
import axios from "axios";
import { apiServerState } from "../../constants/recoilData";
import { getCookie } from "../../hooks/Cookie";

const ListSection = ({ type, category, sort, page, setPage}) => {

    const location = useLocation();
    const path = location.pathname;
    const [pathName] = useState(path);
    const localData = JSON.parse(localStorage.getItem("pageData"));
    

    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    const [novelList, setNovelList] = useState(null);
    const [imgList, setImgList] = useState(null);
    
    const [isMounted, setIsMounted] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const token = getCookie("token");
    
    // 페이지 버튼 클릭
    const pageCall = (category, page, sort) => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        setPage(page);
        updatePaging(category, page, sort);
        const data = {"type" : type, "category" : category, "page" : page, "sort" : sort}
        localStorage.setItem("pageData", JSON.stringify(data))

    }

    const config = {
        headers: {
            "Content-Type": "application/json",
            "authorization": token,
        }
    };

    // 페이지 num, sort 변경시 api 호출
    const update = () => {
        
        let url = ""
        if (type.type === "veteran") {
            url = apiServer + "/api/aizac/veteran_free_zone/get_novel_list?proficiency=veteran&order=" + sort + "&categoryId=" + category + "&page=" + page + "&row=30";
        } else if (type.type === "novice") {
            url = apiServer + "/api/aizac/veteran_free_zone/get_novel_list?proficiency=novice&order=" + sort + "&categoryId=" + category + "&page=" + page + "&row=30";
        } else {
            url = apiServer + "/api/aizac_ai_img/ai_img_list?page=" + page + "&categoryId=" + category + "&order=" + sort + "&row=20";
        }
        setNovelList([]);
        setImgList([]);
    
        const urlCall = async () => {
            if (type.type === "illust") {
                axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setTotalPage(response?.data?.data?.totalPages);
                            setImgList(response?.data?.data?.imgList);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            } else {
                axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setTotalPage(response?.data?.data?.totalPages);
                            setNovelList(response?.data?.data?.novelList);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            }
        };
    
        urlCall();
    
    };

    // 페이지 업데이트
    const updatePaging = (category, page, sortValue) => {
        let url = ""
        if (type.type === "veteran") {
            url = apiServer + "/api/aizac/veteran_free_zone/get_novel_list?proficiency=veteran&order=" + sortValue + "&categoryId=" + category + "&page=" + page + "&row=30";
        } else if (type.type === "novice") {
            url = apiServer + "/api/aizac/veteran_free_zone/get_novel_list?proficiency=novice&order=" + sortValue + "&categoryId=" + category + "&page=" + page + "&row=30";
        } else {
            url = apiServer + "/api/aizac_ai_img/ai_img_list?page=" + page + "&categoryId=" + category + "&order=" + sortValue + "&row=20";
        }
        setNovelList([]);
        setImgList([]);
    
        const urlCall = async () => {
            if (type.type === "illust") {
                axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setTotalPage(response?.data?.data?.totalPages);
                            setImgList(response?.data?.data?.imgList);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            } else {
                axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setTotalPage(response?.data?.data?.totalPages);
                            setNovelList(response?.data?.data?.novelList);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e}});
                    });
            }
        };
    
        urlCall();
    
    };

    // 정렬 type 변경시 재랜더링
    useEffect(() => {
        if(sort !== null) {
            if(page !== null) {
                if(category !== null) {
                    update();
                }
            }
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [category, sort, page]);


    // =============  페이징 처리  =====================
    useEffect(() => {
        let pageNum2;
        if (localData !== null) {
            pageNum2 = localData?.page
        } else {
            pageNum2 = page
        }
        let paginationTag = [];
        const currentPageNo = pageNum2;
        const pageSize = 5;
        const totalPageCount = totalPage;

        const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
        let currentLastPage = currentFirstPage + pageSize - 1;

        currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

        if (totalPageCount > pageSize) {
            // 첫 페이지 이동
            const firstPageTag = <><PaginationLink onClick={() => {pageCall(category, 1, sort)}}>&lt;&lt;</PaginationLink></>;
            paginationTag.push(firstPageTag);

            // 이전 페이지 이동
            const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
            const previousPageTag = <><PaginationLink onClick={() => {pageCall(category, prevPageIndex, sort)}}>&lt;</PaginationLink></>;
            paginationTag.push(previousPageTag);
        }

        for (let i = currentFirstPage; i <= currentLastPage; i++) {
            if (i === currentPageNo) {
                // 현재 페이지
                const currentPage = <><PaginationLink className={veteranCss.current_page + " current_page current-page"} onClick={() => {pageCall(category, i, sort)}}>{i}</PaginationLink></>;
                paginationTag.push(currentPage);
            } else {
                // 다른 페이지
                const otherPage = <><PaginationLink onClick={() => {pageCall(category, i, sort)}}>{i}</PaginationLink></>;
                paginationTag.push(otherPage);
            }
        }
        if (totalPageCount > pageSize) {
            // 다음 페이지 이동
            const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
            const nextPageTag = <><PaginationLink onClick={() => {pageCall(category, nextPageIndex, sort)}}>&gt;</PaginationLink></>;
            paginationTag.push(nextPageTag);
            
            // 마지막 페이지 이동
            const lastPageTag = <><PaginationLink onClick={() => {pageCall(category, totalPage, sort)}}>&gt;&gt;</PaginationLink></>;
            paginationTag.push(lastPageTag);
        }
        setPagination(paginationTag);
    }, [category, page, sort, totalPage]);

    
    
    //  ================== 패아장 처리 끝  ==========================

    return (
        <>
            {isMounted === true ? <section className={veteranCss.rank_synop + " rank_synop"}>
                {pathName !== URL.LIST_DRAWING_STORE ? <><MobileDiv><div className={"row row-cols-2 row-cols-lg-5 g-2 g-lg-3 novel_list " + veteranCss.novel_list}> 

                    {novelList && novelList.map((novel) => {return(<ColNovel novelData={novel} type={type} key={`${type.type}_${category}_novel_${novel?.id}`}/>)})}
                </div></MobileDiv></> : <><MobileDiv><div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3"> 

                    {imgList && imgList.map((img) => {return(<ColIllustration imgData= {img} type={type}  key={`${type.type}_${category}_img_${img?.id}`}/>)})}
                </div></MobileDiv></>}
            
                <PaginationDiv className={veteranCss.pagination + " pagination"}>
                    {pagination}
                </PaginationDiv>
            </section> : <></>}
        </>
    );
};

export default ListSection;

/**div 태그 mobile css */
const MobileDiv = styled.div`
  @media (min-width: 768px) and (max-width: 1400px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    display: block;
  }
`;


/** a 태그 .pagination a css */
const PaginationLink = styled(Link)`
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    margin: 5px;
    padding: 5px 10px;
    color: #333;
    font-family: "Min Sans-Light";
    font-size: 14px;
    :hover {
        background-color: #fff;
        color: #ff5f37;
    };
`;

/** div 태그 .pagination */
const PaginationDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

