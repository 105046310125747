import { Link, useNavigate } from "react-router-dom";
import editPictureCss from '../../css/drawing_modify.module.css'
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import axios from "axios";
//import reactTextareaAutosize from "react-textarea-autosize";
import $ from 'jquery';
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";
import novel2Css from'../../css/novelCreate2.module.css';
import logo_1 from '../../css/images/logo-l.png';
import headerCss from "../../css/main.module.css";
import { isMobile } from 'react-device-detect';


// 내 그림 올리기 페이지 메인 컴포넌트
const ModifyMyPicture = ({isUpdateBool, setIsUpdateBool}) => {

    const [img, setImg] = useState(null);
    const [category, setCategory] = useState(0);
    const [title, setTitle] = useState("");
    const [synopsis, setSynopsis] = useState("");
    const [price, setPrice] = useState(0);
    const [priceCheck, setPriceCheck] = useState(false);

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    // 카테고리 입력시 카테고리 유지
    useEffect (() => {
        switch (category) {
            case 1 : {
                $('.actionBtn').removeClass("active");
                $("#category1").addClass("active");
                $("#category1").addClass("select");
                $("#category1").select();
                break;
            }
            case 2 : {
                $('.actionBtn').removeClass("active");
                $("#category2").addClass("active");
                $("#category2").addClass("select");
                $("#category2").select();
                break;
            }
            case 3 : {
                $('.actionBtn').removeClass("active");
                $("#category3").addClass("active");
                $("#category3").addClass("select");
                $("#category3").select();
                break;
            }
            case 4 : {
                $('.actionBtn').removeClass("active");
                $("#category4").addClass("active");
                $("#category4").addClass("select");
                $("#category4").select();
                break;
            }
            case 5 : {
                $('.actionBtn').removeClass("active");
                $("#category5").addClass("active");
                $("#category5").addClass("select");
                $("#category5").select();
                break;
            }
        } 

    }, [category]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    // 제목 변경 입력 함수
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };
    // 시놉시스 스토리 변경 입력 함수
    const onChangeSynopsis = (e) => {
        setSynopsis(e.target.value);
    };
    // 가격 변경 입력 함수
    const onChangePrice = (e) => {
        setPrice(e.target.value);
    };

    // 카테고리 클릭
    $(document).ready(function () {
        $(".actionBtn").click(function () {
            $('.actionBtn').removeClass("active");
            $(this).addClass("active");
            const val = $(this).val();
            setCategory(val);
        });
    });

    // 가격 정규식 체크
    const priceRegExCheck = (e) => {
        const priceRegEx = new RegExp(/^\d+$/)
        const price = e.target.value;
        const bool = priceRegEx.test(price);

        setPriceCheck(bool);
    }

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 이대로 저장 버튼
    const onSaveClick = () => {
        if (isBtnBool) {
            const fileInput = $("#file-input")[0];
            if (!(fileInput.files.length > 0)) {
                alert("그림을 업로드하세요.");
                return;
            }
            if (category === 0) {
                alert("카테고리를 입력하세요.");
                return;
            }
            const titleCheck = (String(title)).trim();
            if (titleCheck === "") {
                alert("제목을 입력해 주세요!");
                return;
            }
            if (title === '') {
                alert("제목을 입력하세요.");
                return;
            } else if (title.length < 2 ) {
                alert("제목은 2자 ~ 30자 이내로 입력해주세요.");
                return;
            }
            const synopsisCheck = (String(synopsis)).trim();
            if (synopsisCheck === "") {
                alert("그림 소개문을 입력하세요.");
                return;
            }
            if (synopsis === '') {
                alert("그림 소개문을 입력하세요.");
                return;
            } else if (synopsis.length < 2 ) {
                alert("소개문은 2자 ~ 150자 이내로 입력해주세요.");
                return;
            } 
            if (priceCheck === false) {
                alert("그림가격은 숫자만 입력하세요.");
                return;
            }
            if (price === 0) {
                alert("그림가격을 입력하세요.");
                return;
            } else if (price === "") {
                alert("그림가격을 입력하세요.");
                return;
            }  else if (price > 1000000000) {
                alert("그림가격 입력이 초과되었습니다.");
                return;
            } else if (price < 10) {
                alert("그림가격 입력은 10원 이상입니다.");
                return;
            }
            setIsBtnBool(false);
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
    
                    "authorization": token,
                }
            };
            var formData = new FormData();
            formData.append("image", img);
            formData.append("categoryId", category);
            formData.append("title", title);
            formData.append("content", synopsis);
            formData.append("price", price);
    
            axios
                .post(apiServer + "/api/aizac_ai_img/upload_custom_storeimg", formData, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        console.log("저장하였습니다.");
                        alert("저장하였습니다.")
                        setIsUpdateBool(!isUpdateBool);
                        navigate(URL.LIST_DRAWING_STORE);
                    } else {
                        const msg = response?.data?.custMsg;
                        if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인 후 이용해 주세요.");
                            logout();
                        } else if (msg === "image upload failed") {
                            alert("이미지 업로드에 실패하였습니다.");
                        } else if (msg === `invalid data`) {
                            alert("카테고리 선택이 않되어 있거나 제목이 없습니다. 다시 입력해주세요.");
                        } else if (msg.includes("upload custom storeimgur")) {
                            alert("서버 에러")
                        } else if(msg === "router err") {
                            alert("서버 에러");
                        }
                        setIsBtnBool(true);
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    setIsBtnBool(true);
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        }
    }

    // 파일 입력 함수
    const handleFileInput = () => {
        const fileInput = $("#file-input")[0];
        const imagePreview = $("#image-preview");
        const synop01 = $("#synop01");
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];
            setImg(file);

            if (
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/webp" 
            ) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    imagePreview.attr("src",e.target.result);
                    imagePreview.css("display", "block");
                };
                reader.readAsDataURL(file);
                synop01.css("display", "block");
                $("#image-preview-container").css("display", "block");
            } else {
                alert("png, jpeg, jpg, webp 형태의 이미지 파일만 이용하실 수 있습니다.");
                fileInput.value = "";
            }
        } else {
            imagePreview.attr("src", "");
            imagePreview.css("display", "none");
        }

    }

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    const [isBtnBool, setIsBtnBool] = useState(true);

    return (
        <>
            <ContainerDiv id="container">
                {isMobile === true ? <NavbarBrandLink className={headerCss.navbar_brand + " navbar_brand"} to={URL.MAIN}>
                    <NavLogoImg src={logo_1} alt="logo_1" className={headerCss.nav_logo + ' nav_logo'}/>
                </NavbarBrandLink> : <></>}
                <SynopTabSection className={editPictureCss.synop_tab + " synop_tab"}>
                    <SynopTabGroupDiv className={editPictureCss.synop_tab_group + " synop_tab_group"}>
                        <SynopTabBtnUDiv className={editPictureCss.synop_tab_btn_u + " synop_tab_btn_u"}><SynopTabBtnUALink >내 그림 판매하기</SynopTabBtnUALink></SynopTabBtnUDiv>
                        <SynopTabBtnLineDiv className={editPictureCss.synop_tab_btn_line + " synop_tab_btn_line"}></SynopTabBtnLineDiv>
                    </SynopTabGroupDiv>
                </SynopTabSection>
                <ContainerH2H2>그림 정보 수정</ContainerH2H2>
                <ContentDiv className={editPictureCss.content + " content"}>
                    <DefaultDiv id="synop01">
                        <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                            <DefaultFrom
                                encType="multipart/form-data"
                                action="#"
                                method="post"
                                id="upload-form"
                            >
                                <DefaultInput
                                    type="file"
                                    name="file"
                                    id="file-input"
                                    accept=".png, .jpg, .jpeg, .webp"
                                    onChange={handleFileInput}
                                />
                            </DefaultFrom>
                            <DefaultDiv id="image-preview-container" style={{display: "block"}}>
                                <ImagePreviewImg src="" alt="Image Preview" id="image-preview" />
                            </DefaultDiv>
                            <ImguploadIntDiv className={novel2Css.imgupload_int + " imgupload_int imgupload-int"}>
                            ※업로드 파일: 최대 50mb, jpg, jpeg, png, webp 파일 지원<br />※가로9:세로16
                            비율의 이미지가 표지로 가장 적합합니다
                            </ImguploadIntDiv>
                        </DefaultSection>
                    </DefaultDiv>
                    <CateSelectDiv className={editPictureCss.cateSelect + " cateSelect"}>
                        <CateSelectPP>카테고리를 선택해주세요</CateSelectPP>
                        <CateSelectUlUl>
                            <CateSelectUlLiLi id="category1" className=" actionBtn" value={1}>로맨스</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category2" className=" actionBtn" value={2}>판타지</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category3" className=" actionBtn" value={3}>스릴러</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category4" className=" actionBtn" value={4}>무협</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category5" className=" actionBtn" value={5}>기타</CateSelectUlLiLi>
                        </CateSelectUlUl>
                    </CateSelectDiv>

                    <DefaultLabel htmlFor="imageTitle">그림 제목을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※제목은 2자~30자 이내로 입력해주세요</DescriptionTextSpan>
                    <ImageTitleInput type="text"id="imageTitle" placeholder="제목을 입력해주세요" maxLength="30" onChange={onChangeTitle} defaultValue={img?.title}/>

                    <DefaultLabel htmlFor="imageDescription">그림 소개문을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※소개문은 2자~150자 이내로 입력해주세요</DescriptionTextSpan>
                    <ImageDescriptionTextarea 
                        id="imageDescription" 
                        name="imageDescription" 
                        rows="4" 
                        cols="50" 
                        maxLength="150" 
                        required 
                        placeholder="소개문을 입력해주세요"
                        onChange={onChangeSynopsis}
                        defaultValue={img?.synopsis}></ImageDescriptionTextarea>
                    <br/>
                    <br/>
                    <DefaultLabel htmlFor="imagePrice">그림 가격을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※그림 가격은 1,000,000,000 이내로 입력해주세요</DescriptionTextSpan>
                    <InputContainerDiv className={editPictureCss.input_container + " input_container"}>
                        <ImagePriceInput type="number" id="imagePrice" placeholder="가격을 입력해주세요" onChange={(e) => {onChangePrice(e); priceRegExCheck(e)}} defaultValue={img?.price}/>
                    </InputContainerDiv>
                    <SaveNextBtn type="submit" className={editPictureCss.saveNextBtn + " saveNextBtn"} onClick={onSaveClick}><DefaultALink >이대로 저장</DefaultALink></SaveNextBtn>
                </ContentDiv>
            </ContainerDiv>
        </>
    );
};
export default ModifyMyPicture;


/** a 태그 .navbar-brand css */
const NavbarBrandLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    margin-left: 16px;
    margin-top: 30px;
    margin-bottom: 0px;
    @media (max-width: 560px) {
      margin-left: 0px;
    }
`;

/** img 태그 .nav-logo css */
const NavLogoImg = styled.img`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  height: 24px;
  margin-top: 30px;
  margin-bottom: 0px;
  @media (max-width: 560px) {
    height: 18px;
  }
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    overflow-y: auto; 
    padding: 0 140px;
    @media(max-width:1400px) {
        padding: 0 80px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** h2 태그 #container h2 css */
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-top: 60px;
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    font-weight: bold;
    @media(max-width:568px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;

/**section 태그 .synop_tab css */
const SynopTabSection = styled.section`
    margin-top: 108px;
    display: flex;
    justify-content: center;
    @media(max-width:768px) {
        margin-top: 30px;
    }
`;

/** div 태그 .synop_tab_group css */
const SynopTabGroupDiv = styled.div`
    width: 100%;
`;

/** div 태그 .synop_tab_btn_u css */
const SynopTabBtnUDiv = styled.div`
    text-align: center;
`;

/** a 태그 .synop_tab_btn_u > a css */
const SynopTabBtnUALink = styled(Link)`
    text-decoration: none;
    color: inherit;
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #ff5f37;
    position: relative;
    letter-spacing: -0.3px;
`;

/** div 태그 .synop_tab_btn_line css */
const SynopTabBtnLineDiv = styled.div`
    width: 100%;
    height: 4px;
    background-color: #ff5f37;
    margin-top: 12px;
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 52px;
`;

/** div 태그 .bookCover css 
const BookCoverDiv = styled.div`
    width: 300px;
    height: 476px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;*/

/** img 태그 .bookCover img css 
const BookCoverImgImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    @media(max-width:568px) {
        margin-bottom: 42px;
        max-width:52%
    }
`;*/

/** div 태그 .cateSelect css */
const CateSelectDiv = styled.div`
    margin-bottom: 60px;
    margin-top: 52px;
    @media(max-width:568px) {
        margin: 42px 0;
    }
`;

/** p 태그 .cateSelect p css */
const CateSelectPP = styled.p`
    font-size: 18px;
    color: #050409;
    font-family: "Min Sans-Light";
    margin-bottom: 12px;
`;

/** ul 태그 .cateSelect ul css */
const CateSelectUlUl = styled.ul`
    display: flex;
    gap: 10px;
    padding-left: 0;
    @media (min-width: 100px) and (max-width: 359px) {
        display: grid;
    }
`;

/** li 태그 .cateSelect ul li css */
const CateSelectUlLiLi = styled.li`
    padding: 8px 20px;
    border: 1px solid #BDBDBD;
    border-radius: 19px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    text-align: center;
    color: #828284;
    cursor: pointer;
    &.selected {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &.active {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &:hover {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    @media(max-width:568px) {
        font-size: 13px;
        padding: 8px 12px;
    }
`;

/** label 태그 디폴트 css */
const DefaultLabel = styled.label`
    color: #050409;
    font-size: 18px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
`;

/** span 태그 .description_text css */
const DescriptionTextSpan = styled.span`
    font-size: 14px;
    color: #828284;
    font-family: "Min Sans-Light";
    padding-left: 4px;
    @media(max-width:568px) {
        padding: 0;
        display: block;
        padding-top: 4px;
    }
`;

/** input 태그 #imageTitle css */
const ImageTitleInput = styled.input`
    border: 1px solid #BDBDBD;
    padding: 22px 24px;
    width: 100%;
    color: #22222a;
    outline: none;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
    &::placeholder{
        font-size: 24px;
        color: #9B9B9D;
        font-family: "Min Sans-Light";
    }
    margin-top: 12px;
    margin-bottom: 60px;
    border: 1px solid #BDBDBD;
    @media(max-width:568px) {
        font-size: 18px;
        padding: 22px 16px;
        margin-bottom: 42px;
    }
`;

/** textarea 태그 #imageDescription css */
const ImageDescriptionTextarea = styled.textarea`
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    &::placeholder {
        color: #9B9B9D;
    }
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
    margin-top: 12px;
    margin-bottom: 40px;
    @media(max-width:568px) {
        margin-bottom: 20px;
    }
`;

/** div 태그 .input_container css */
const InputContainerDiv = styled.div`
    border-bottom: 1px solid #EDEDED;
    position: relative;
    &::after {
        content: "C";
        position: absolute;
        top: 36px;
        right: 24px; 
        color: #ff5f37;
        font-size: 24px;
        font-family: "Min Sans-Light";
    }
    @media(max-width:568px) {
        &::after{
            top: 32px;
        }
    }
`;

/** input 태그 #imagePrice css */
const ImagePriceInput = styled.input`
    border: 1px solid #BDBDBD;
    padding: 22px 24px;
    width: 100%;
    color: #22222a;
    outline: none;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
    &::placeholder{
        font-size: 24px;
        color: #9B9B9D;
        font-family: "Min Sans-Light";
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        display: none;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        display: none;
    }
    margin-bottom: 52px;
    border: 1px solid #BDBDBD;
    margin-top: 12px;
    @media(max-width:568px) {
        margin-bottom: 42px;
        font-size: 18px;
        padding: 22px 16px;
        &::placeholder{
            font-size: 18px;
            color: #9B9B9D;
            font-family: "Min Sans-Light";
        }
    }
    @media (min-width: 100px) and (max-width: 359px) {
        font-size: 18px;
        &::placeholder{
            font-size: 18px;
            color: #9B9B9D;
            font-family: "Min Sans-Light";
        }
    }
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtn = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    margin-bottom: 120px;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media(max-width:1400px) {
        margin-bottom: 120px;
    }
    @media(max-width:568px) {
        margin-top: 42px;
        margin-bottom: 80px;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: block;
`;

/** section 태그 디폴트 css */
const DefaultSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** form 태그 디폴트 css */
const DefaultFrom = styled.form`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** input 태그 디폴트 css */
const DefaultInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** img 태그 #image-preview css */
const ImagePreviewImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    max-width: 100%;
    max-height: 200px;
    margin-top: 20px;
    display: none;
    @media (min-width: 100px) and (max-width: 768px) {
        width: 100px; /* 이미지 크기 조정 */
        height: 100px;
    }
`;

/** div 태그 .imgupload-int css */
const ImguploadIntDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #828284;
    letter-spacing: -0.3px;
    margin-top: 10px;
    line-height: 24px;
`;

/** ul 태그 .synop-select-tab > ul css 
const StnopSelectTabUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
`;*/


/** li 태그 .synop-select-tab-nav > li css 
const SynopSelectTabNavLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
`;*/

/** a 태그 .synop-select-tab-nav li a css 
const SynopSelectTabNavLiLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    &.active {
        border-bottom: 1px solid #fff;
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        color: #828284;
        border: 1px solid #bdbdbd;
    }
`;*/