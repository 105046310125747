import { Link, useNavigate } from "react-router-dom";
import logo_d2 from "../../css/images/logo-d2.png"
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import URL from "../../constants/url";
import { useRecoilValue } from "recoil";
import axios from "axios";
import novel1Css from '../../css/novelCreate1.module.css';
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";
import LeftAsideBookListAiEditor from "./LeftAsideBookListAiEditor";
import LeftAsideNavMenu from "./LeftAsideNavMenu";
import $ from 'jquery';


const LeftAside2 = ({isUpdateBool, setIsUpdateBool, setTextTitle, setTextContent}) => {
    //const bookList = [{id:1, title: "눈에 보이지 않는 적", series: [1,2,3,4,5,6,7,8]}, {id:2, title: "흑룡의 후예", series: [1,2,3]}];

    const barsIcon = useRef();
    const xmarkIcon = useRef();
    const navMenu = useRef();
    const nnavMenu = useRef();

    // 햄버거 bars 클릭
    const barsIconCilck = () => {
        barsIcon.current.style.display = "none";
        nnavMenu.current.style.display = "none";
        xmarkIcon.current.style.display = "block";
        navMenu.current.style.display = "block";
    }

    // X 마크 클릭
    const xmarkIconClick = () => {
        xmarkIcon.current.style.display = "none";
        nnavMenu.current.style.display = "block";
        barsIcon.current.style.display = "block";
        navMenu.current.style.display = "none";
    }

    
    
    const apiServer = useRecoilValue(apiServerState);

    const [isMounted, setIsMounted] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [publishedNovels, setPublishedNovels] = useState(null);
    const [editorList, setEditorList] = useState(null);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }
    
    const navigate = useNavigate();
    
    // 최초 랜더링시 상태 셋팅
    useEffect(() => {
        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  "authorization": token,
                }
            };
        
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_user_overall_info";
                const urlUser = apiServer + "/api/asiac_login/get_my_info";
                const urlEditorList = apiServer + "/api/aizac_editor/get_eidtor_list";
            
                const verteran = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setPublishedNovels(response?.data?.data?.publishedNovels);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                                alert("다시 로그인 해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
                const editorListApi = async () => {
                    await axios
                    .get(urlEditorList, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setEditorList(response?.data?.data?.editorList);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message)
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };

                const userInfo = async () => {
                    await axios
                    .get(urlUser, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUserInfo(response?.data?.data?.user);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };

                verteran();
                editorListApi();
                if(token !== undefined) {
                    userInfo();
                }
            };
            update();
        }
        
    }, []);

    
    // 로고 클릭
    const onClickLogo = () => {
        navigate("/");
    }
      
    const [rendering, setRendering] = useState(false);

    // 코인 클릭
    const onClickCoinBlock = () => {
        navigate(URL.LIST_CHARGE_SHOP);
    }
    
    // 에디터 목록 클릭
    const onClickEditor = (novelId) => {
        const url = URL.AI_EDITOR_NOVEL_EDIT_INPUT + novelId;
        navigate(url);
        //navigate('/pass/ai-editor/' + novelId);
    }

    // isUpdateBool 변경시 상태 셋팅 업데이트(api 코인 차감 업데이트 용도)
    useEffect(() => {
        const token = getCookie("token");
        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        const url = apiServer + "/api/asiac_novel/get_user_overall_info";
        const urlUser = apiServer + "/api/asiac_login/get_my_info";
        const urlEditorList = apiServer + "/api/aizac_editor/get_eidtor_list";

        const verteran = async () => {
            await axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success) {
                    setPublishedNovels(response?.data?.data?.publishedNovels);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                        alert("다시 로그인 해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state : {msg : e.message}});
            });
        };
        const editorListApi = async () => {
            await axios
            .get(urlEditorList, config)
            .then((response) => {
                if (response?.data?.success) {
                    setEditorList(response?.data?.data?.editorList);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };

        const userInfo = async () => {
            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        verteran();
        editorListApi();
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])

    const onClickMyPicture = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        navigate(URL.CREATE_MY_PICTURE);
    }

    const bookListRef = useRef();
    const arrowRef = useRef();

    // 작품 제목 클릭시 화살표 모양 변경
    const toggleList = (editor) => {

      var list = $((".bookList" + editor.id));

      var arrow = $((".arrow" + editor.id));
      if (list.css("display") === "none") {
        list.css("display", "block");
        arrow.css("transform", "rotate(180deg)");
      } else {
        list.css("display", "none");
        arrow.css("transform", "rotate(0deg)");
      }
    }

    // 에디터 클릭
    const onClickEditor2 = (novelId, episodeId) => {
        navigate('/ai-editor/episode/' + novelId + "/" + episodeId);
    }

    return(
        <>
            <LeftAsideAside className={novel1Css.leftAside + " leftAside"}> 
                <ContentDiv className={novel1Css.content + " content"}>
                    <TopDiv className={novel1Css.top + " top"}>
                        <NavNav className={novel1Css.nav + " nav"}>
                            <DefaultI
                                className={" fa-solid fa-bars"}
                                id="bars-icon"
                                onClick={barsIconCilck}
                                ref={barsIcon}
                                style={{cursor: "pointer"}}
                            ></DefaultI>
                            <XmarkIconI
                                className={" fa-solid fa-xmark"}
                                id="xmark-icon"
                                onClick={xmarkIconClick}
                                ref={xmarkIcon}
                                style={{cursor: "pointer"}}
                            ></XmarkIconI>
                        </NavNav>
                        <LogoDiv className={novel1Css.logo + " logo"} id="logo" onClick={onClickLogo}>
                            <LogoImg src={logo_d2} alt="logo_d2"/>
                        </LogoDiv>
                    </TopDiv>
                    <NavMenuDiv className={novel1Css.nav_menu + " nav_menu nav-menu"} id="nav-menu" ref={navMenu}>
                        <LeftAsideNavMenu/>
                    </NavMenuDiv>
                    <DefaultDiv id="nnav-menu" ref={nnavMenu}>
                        {isMounted === true ? <ProfileDiv className={novel1Css.profile + " profile"}>
                            <TopProfileDiv className={novel1Css.topProfile + " topProfile"}>
                                <DefaultP className={novel1Css.level + " level"}>Lv.{userInfo?.level}</DefaultP>
                                <DefaultP className={novel1Css.name + " name"}>{userInfo?.nickname}</DefaultP>
                            </TopProfileDiv>
                            <ButtomProfileDiv className={novel1Css.bottomProfile + " bottomProfile"}>
                                <CoinBlockDiv className={novel1Css.coin + " coin"}>
                                    <DefaultP>보유코인 :</DefaultP>
                                    <RightP className={novel1Css.right + " right"} onClick={onClickCoinBlock}>{parseInt(userInfo?.coins) !== null ? parseInt(userInfo?.coins) : 0}c</RightP>
                                </CoinBlockDiv>
                                <CoinBlockDiv className={novel1Css.coin + " coin"}>
                                    <DefaultP>보유포인트 :</DefaultP>
                                    <RightP className={novel1Css.right + " right"} onClick={onClickCoinBlock}>{parseInt(userInfo?.freeCoin) !== null ? parseInt(userInfo?.freeCoin) : 0}p</RightP>
                                </CoinBlockDiv>
                            </ButtomProfileDiv>
                        </ProfileDiv> : <></>}
                        <DefaultDiv className={novel1Css.my + " " + novel1Css.Write + " my Write"}>
                            <TitDiv className={novel1Css.tit + " tit"}>
                                <DefaultSvg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <DefaultPath
                                        d="M5.20833 19.677H6.68147L16.3832 9.97524L14.9101 8.5021L5.20833 18.2039V19.677ZM21.875 21.7604H3.125V17.341L17.1198 3.34612C17.5267 2.93932 18.1861 2.93932 18.5929 3.34612L21.5393 6.29239C21.946 6.69919 21.946 7.35873 21.5393 7.76553L9.62775 19.677H21.875V21.7604ZM16.3832 7.02896L17.8564 8.5021L19.3295 7.02896L17.8564 5.55583L16.3832 7.02896Z"
                                        fill="#8F8F99"
                                    />
                                </DefaultSvg>
                                <MyTitP>내 글</MyTitP>
                            </TitDiv>
                            <ListGroupDiv className={novel1Css.list_group + " list_group list-group"}>
                                {publishedNovels && publishedNovels.map((book) => <LeftAsideBookListAiEditor key={book?.id} book={book} setRendering={setRendering} rendering={rendering} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setTextTitle={setTextTitle} setTextContent={setTextContent}/>)}
                            </ListGroupDiv>
                        </DefaultDiv>
                        <DefaultDiv className={novel1Css.my + " " + novel1Css.Drawing + " my Drawing"}>
                            <TitDiv className={novel1Css.tit + " tit"}>
                                <DefaultSvg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <DefaultPath
                                        d="M5.20833 11.563L7.29167 9.47969L13.0208 15.2089L16.6667 11.563L19.7917 14.688V5.20833H5.20833V11.563ZM5.20833 14.5093V19.7917H8.43802L11.5477 16.682L7.29167 12.4259L5.20833 14.5093ZM11.3843 19.7917H19.7917V17.6343L16.6667 14.5093L11.3843 19.7917ZM4.16667 3.125H20.8333C21.4086 3.125 21.875 3.59137 21.875 4.16667V20.8333C21.875 21.4086 21.4086 21.875 20.8333 21.875H4.16667C3.59137 21.875 3.125 21.4086 3.125 20.8333V4.16667C3.125 3.59137 3.59137 3.125 4.16667 3.125ZM16.1458 10.4167C15.2829 10.4167 14.5833 9.71711 14.5833 8.85417C14.5833 7.99122 15.2829 7.29167 16.1458 7.29167C17.0087 7.29167 17.7083 7.99122 17.7083 8.85417C17.7083 9.71711 17.0087 10.4167 16.1458 10.4167Z"
                                        fill="#8F8F99"
                                    />
                                </DefaultSvg>
                                <MyTitP>내 그림</MyTitP>
                            </TitDiv>
                            <ListGroupDiv  className={novel1Css.list_group + " list_group list-group"} onClick={onClickMyPicture}>
                                <ListLink className={novel1Css.list + " list"}>
                                    <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                                    <DefaultDiv className={novel1Css.title + " title"}>내 그림 리스트</DefaultDiv>
                                </ListLink>
                            </ListGroupDiv>
                        </DefaultDiv>
                        <DefaultDiv className={novel1Css.my + " " + novel1Css.edit + " my edit"}>
                            <TitDiv className={novel1Css.tit + " tit"}>
                                <DefaultSvg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <DefaultPath
                                        d="M21.8747 3.125C22.45 3.125 22.9163 3.59137 22.9163 4.16667V20.8333C22.9163 21.4086 22.45 21.875 21.8747 21.875H3.12467C2.54938 21.875 2.08301 21.4086 2.08301 20.8333V4.16667C2.08301 3.59137 2.54938 3.125 3.12467 3.125H21.8747ZM7.72472 14.5833H4.16634V19.7917H20.833V14.5833H17.2747C16.4709 16.4228 14.6354 17.7083 12.4997 17.7083C10.3639 17.7083 8.52842 16.4228 7.72472 14.5833ZM20.833 5.20833H4.16634V12.5H9.37467C9.37467 14.2259 10.7737 15.625 12.4997 15.625C14.2256 15.625 15.6247 14.2259 15.6247 12.5H20.833V5.20833Z"
                                        fill="#8F8F99"
                                    />
                                </DefaultSvg>
                                <MyTitP>편집 보관함</MyTitP>
                            </TitDiv>
                            <ListGroupDiv className={novel1Css.list_group + " list_group list-group"}>
                                {editorList && editorList.map((editor) => {return(editor?.ikind === "single" ? <ListDiv className={novel1Css.list + " list"} key={"editor" + editor?.id} onClick={() => {onClickEditor(editor?.id)}}>
                                    <CateDiv className={novel1Css.cate + " cate"}>▶</CateDiv>
                                    <TitleDiv className={novel1Css.title + " title"}>
                                        <DefaultSpan className={novel1Css.day + " day"}>{editor?.title}</DefaultSpan>
                                    </TitleDiv>
                                </ListDiv> : <ListDiv2 className={novel1Css.list + " list"} id={`list${editor?.id}`} >
                                    <CateDiv2 className={novel1Css.cate + " cate"} onClick={() => {toggleList(editor)}}>▶</CateDiv2>
                                    <DefaultDiv2 className={novel1Css.listInfo + " listInfo"}>
                                        <ListInfoTitleP className={novel1Css.title + " title"} onClick={() => {toggleList(editor)}}>{editor?.title}</ListInfoTitleP>
                                        <HiddenListUl className={novel1Css.hidden_list + " hidden_list hidden-list " + "bookList" + editor?.id} id="bookList" ref={bookListRef}>
                                            {true && editor?.tAiEditorEpisodes.map((item) => <HiddenListUlLi key={item?.id} onClick={() => {onClickEditor2(editor?.id, item?.id)}}><DefaultLink >{item?.title}</DefaultLink></HiddenListUlLi>)}
                                        </HiddenListUl>
                                    </DefaultDiv2>
                                    <ListGroupDownDiv className={novel1Css.down + " down " + "arrowIcon" + editor?.id} id="arrowIcon" ref={arrowRef}>
                                        <DefaultSvg2
                                            width="16"
                                            height="10"
                                            viewBox="0 0 16 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={"arrow" + editor?.id}
                                        >
                                            <DefaultMask
                                                id="mask0_1_7899"
                                                style={{maskType: 'alpha'}}
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="16"
                                                height="10"
                                            >
                                                <DefaultRect width="16" height="10" fill="#D9D9D9" />
                                            </DefaultMask>
                                            <DefaultG mask="url(#mask0_1_7899)">
                                                <DefaultPath2
                                                    d="M1.25 2.1167L2.34508 1L8 6.76659L13.637 1L14.75 2.11671L8 9L1.25 2.1167Z"
                                                    fill="#71717D"
                                                />
                                            </DefaultG>
                                        </DefaultSvg2>
                                    </ListGroupDownDiv>
                                </ListDiv2>)})}
                            </ListGroupDiv>
                        </DefaultDiv>
                    </DefaultDiv>
                </ContentDiv>
            </LeftAsideAside>
        </>
    );
};

export default LeftAside2;

/** i 태그 디폴트 css */
const DefaultI = styled.i`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: block;
    color: #ffffff;
    font-size: 22px;
    width: 19.5px;
`;

const XmarkIconI = styled.i`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: #ffffff;
    font-size: 22px;
    width: 19.5px;
    display: none;
`;

/** aside 태그 .leftAside css */
const LeftAsideAside = styled.aside`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #22222a;
    height: 100%;
    overflow-y: auto;
    min-width: 320px;
    @media (min-width: 100px) and (max-width: 768px) {
        display: none;
    }
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 54px 40px 0 40px;
`;

/** div 태그 .top css */
const TopDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    gap: 12px;
    align-items: center;
`;

/** nav 태그 .nav css */
const NavNav = styled.nav`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    cursor: pointer;
`;

/** div 태그 .logo css */
const LogoDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: 24px;
    cursor: pointer;
`;

/** img 태그 .logo > img css */
const LogoImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: 100%;
`;

/** div 태그 .nav-menu css */
const NavMenuDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 46px;
    display: none;
`;

/** div 태그 .list-group css */
const ListGroupDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    &:hover {color: #ff5f37;}
    cursor: pointer;
`;

/** a 태그 .list css */
const ListLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    &:first-of-type {border-top: 1px solid #2c2c34;}
    &:last-of-type {margin-bottom: 42px;}
    cursor: pointer;
    &:hover {color: #ff5f37;}
`;

/** div 태그 .cate css */
const CateDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-right: 4px;
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;


/** div 태그 .profile css */
const ProfileDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
`;

/** div 태그 .topProfile css */
const TopProfileDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
`;

/** div 태그 .bottomProfile css */
const ButtomProfileDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
`;

/** p 태그 디폴트 css */
const DefaultP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** p 태그 .right css */
const RightP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
`;

/** div 태그 .coin, .block css */
const CoinBlockDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: space-between;
`;

/** div 태그 .tit css */
const TitDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

/** svg 태그 디폴트 css */
const DefaultSvg = styled.svg`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** p 태그 .my .tit p css */
const MyTitP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #8f8f99;
    margin-left: 4px;
`;

/** path 태그 디폴트 css */
const DefaultPath = styled.path`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** div 태그 .list css */
const ListDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    color: #8f8f99;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    opacity: 2;
    &:first-of-type {border-top: 1px solid #2c2c34;}
    &:last-of-type {margin-bottom: 42px;}
    &:hover * {color: #ff5f37;}
`;

/** span 태그 디폴트 css */
const DefaultSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    color: #8f8f99;
`;


/** div 태그 .list css */
const ListDiv2 = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    &:first-of-type {border-top: 1px solid #2c2c34;}
    &:last-of-type {margin-bottom: 36px;}
    &:hover {color: #ff5f37;}
    cursor: pointer;
`;

/** div 태그 .cate css */
const CateDiv2 = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-right: 4px;
    &:hover {color: #ff5f37;}
`;

/** svg 태그 디폴트 css */
const DefaultSvg2 = styled.svg`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** div 태그 디폴트 css */
const DefaultDiv2 = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** p 태그 .listInfo .title css */
const ListInfoTitleP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    margin-bottom: 6px;
    &:hover {color: #ff5f37;}
`;

/** ul 태그 .hidden-list css */
const HiddenListUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
    &:hover {color: #ff5f37;}
`;

/** div 태그 .listGroup .down css */
const ListGroupDownDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-left: auto;
    &:hover {color: #ff5f37;}
`;

/** mask 태그 디폴트 css */
const DefaultMask = styled.mask`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** rect 태그 디폴트 css */
const DefaultRect = styled.rect`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** g 태그 디폴트 css */
const DefaultG = styled.g`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** path 태그 디폴트 css */
const DefaultPath2 = styled.path`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;


/** a 태그 디폴트 css */
const DefaultLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    &:hover {color: #ff5f37;}
`;

/** li 태그 .hidden-list li css */
const HiddenListUlLi = styled.li`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    padding: 6px 0;
    color: #8f8f99;
`;

