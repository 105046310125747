import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import noticeCss from "../../css/notice.module.css";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import URL from "../../constants/url";
import { apiServerState } from "../../constants/recoilData";


const Notice = () => {

    const [pageNumNotice, setPageNumNotice] = useState(null);
    const [pageNumNews, setPageNumNews] = useState(null);
    const [totalPageNotice, setTotalPageNotice] = useState(1);
    const [totalPageNews, setTotalPageNews] = useState(1);

    const [paginationTagNoticeList, setPaginationTagNoticeList] = useState([]);
    const [paginationTagNewsList, setPaginationTagNewsList] = useState([]);
    const localData = JSON.parse(localStorage.getItem("categoryNotice"));

    useEffect(() => {
        console.log(localData);
        if(localData !== null && localData !== undefined) {
            switch (localData.category) {
                case "synop01" : {
                    setPageNumNotice(localData.page);
                    setPageNumNews(1);
                    setIsNews(false);
                    setIsMountedNotice(false);
                    $(".tab_link").removeClass("active");
                    $("#notice1").addClass("active");
                    $("#synop02").css("display", "none");
                    $("#synop01").css("display", "block");
                    $(".new").css("display", "none");
                    $("#newDiv").css("display", "none");
                    break;
                }
                case "synop02" : {
                    setPageNumNotice(1);
                    setPageNumNews(localData.page);
                    setIsNews(true);
                    setIsMountedNews(false);
                    $(".tab_link").removeClass("active");
                    $("#notice2").addClass("active");
                    $("#synop02").css("display", "Block");
                    $("#synop01").css("display", "none");
                    $("#newDiv").css("display", "block");
                    break;
                }
            }
        } else {
            setPageNumNotice(1);
            setPageNumNews(1);
        }
    }, [])
    

    // ========================  type별 페이징 처리 함수  ===============================

    const pageTag = (type) => {
        let paginationTagNotice = [];
        let paginationTagNews = [];
        if (type === "공지사항") {
            const currentPageNo = pageNumNotice;
            const pageSize = 5;
            const totalPageCount = totalPageNotice;
            const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
            let currentLastPage = currentFirstPage + pageSize - 1;

            currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

            if (totalPageCount > pageSize) {
                // 첫 페이지 이동
                const firstPageTag = <><PaginationLink onClick={() => {pageCall("공지사항", 1)}}>&lt;&lt;</PaginationLink></>;
                paginationTagNotice.push(firstPageTag);

                // 이전 페이지 이동
                const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
                const previousPageTag = <><PaginationLink onClick={() => {pageCall("공지사항", prevPageIndex)}}>&lt;</PaginationLink></>;
                paginationTagNotice.push(previousPageTag);
            }

            for (let i = currentFirstPage; i <= currentLastPage; i++) {
                if (i === currentPageNo) {
                    // 현재 페이지
                    const currentPage = <><PaginationLink className={noticeCss.current_page + " current_page"} onClick={() => {pageCall("공지사항", i)}}>{i}</PaginationLink></>;
                    paginationTagNotice.push(currentPage);
                } else {
                    // 다른 페이지
                    const otherPage = <><PaginationLink onClick={() => {pageCall("공지사항", i)}}>{i}</PaginationLink></>;
                    paginationTagNotice.push(otherPage);
                }
            }
            if (totalPageCount > pageSize) {
                // 다음 페이지 이동
                const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
                const nextPageTag = <><PaginationLink onClick={() => {pageCall("공지사항", nextPageIndex)}}>&gt;</PaginationLink></>;
                paginationTagNotice.push(nextPageTag);
                
                // 마지막 페이지 이동
                const lastPageTag = <><PaginationLink onClick={() => {pageCall("공지사항", totalPageNotice)}}>&gt;&gt;</PaginationLink></>;
                paginationTagNotice.push(lastPageTag);
            }
            setPaginationTagNoticeList(paginationTagNotice);
        } else if (type === "1:1문의") {
            const currentPageNo = pageNumNews;
            const pageSize = 5;
            const totalPageCount = totalPageNews;
            const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
            let currentLastPage = currentFirstPage + pageSize - 1;

            currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

            if (totalPageCount > pageSize) {
                // 첫 페이지 이동
                const firstPageTag = <><PaginationLink onClick={() => {pageCall("1:1문의", 1)}}>&lt;&lt;</PaginationLink></>;
                paginationTagNews.push(firstPageTag);

                // 이전 페이지 이동
                const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
                const previousPageTag = <><PaginationLink onClick={() => {pageCall("1:1문의", prevPageIndex)}}>&lt;</PaginationLink></>;
                paginationTagNews.push(previousPageTag);
            }

            for (let i = currentFirstPage; i <= currentLastPage; i++) {
                if (i === currentPageNo) {
                    // 현재 페이지
                    const currentPage = <><PaginationLink className={noticeCss.current_page + " current_page"} onClick={() => {pageCall("1:1문의", i)}}>{i}</PaginationLink></>;
                    paginationTagNews.push(currentPage);
                } else {
                    // 다른 페이지
                    const otherPage = <><PaginationLink onClick={() => {pageCall("1:1문의", i)}}>{i}</PaginationLink></>;
                    paginationTagNews.push(otherPage);
                }
            }
            if (totalPageCount > pageSize) {
                // 다음 페이지 이동
                const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
                const nextPageTag = <><PaginationLink onClick={() => {pageCall("1:1문의", nextPageIndex)}}>&gt;</PaginationLink></>;
                paginationTagNews.push(nextPageTag);
                
                // 마지막 페이지 이동
                const lastPageTag = <><PaginationLink onClick={() => {pageCall("1:1문의", totalPageNews)}}>&gt;&gt;</PaginationLink></>;
                paginationTagNews.push(lastPageTag);
            }
            setPaginationTagNewsList(paginationTagNews);
        }
    }

    
    
    // 페이징 넘버, type에 따른 업데이트 함수
    const pageCall = (typeNotice, page) => {
        if (typeNotice === "1:1문의") {
            setPageNumNews(page);
            if (token === undefined) {
                navigate(URL.LOGIN);
            }
            $(".tab_link").removeClass("active");
            $("#notice2").addClass("active");
            $("#synop02").css("display", "Block");
            $("#synop01").css("display", "none");
            $("#newDiv").css("display", "block");
            setIsNews(true);
            const data = {"category" : "synop02", "page" : page};
            localStorage.setItem("categoryNotice", JSON.stringify(data));
            update(1, page);
        } else {
            setPageNumNotice(page);
            $(".tab_link").removeClass("active");
            $("#notice1").addClass("active");
            $("#synop02").css("display", "none");
            $("#synop01").css("display", "block");
            $(".new").css("display", "none");
            $("#newDiv").css("display", "none");
            setIsNews(false);
            const data = {"category" : "synop01", "page" : page};
            localStorage.setItem("categoryNotice", JSON.stringify(data));
            update(page, 1);
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }
    
    // 공지사항 버튼
    const onClickNotice = () => {
        $(".tab_link").removeClass("active");
        $("#notice1").addClass("active");
        $("#synop02").css("display", "none");
        $("#synop01").css("display", "block");
        $(".new").css("display", "none");
        $("#newDiv").css("display", "none");
        setIsNews(false);
        const data = {"category" : "synop01", "page" : 1};
        localStorage.setItem("categoryNotice", JSON.stringify(data));
        setPageNumNotice(1);
        setPageNumNews(1);
    }
    const token = getCookie("token");
    const navigate = useNavigate();
    
    // 1:1 문의 버튼
    const onClickNews = () => {
        
        if (token === undefined) {
            navigate(URL.LOGIN);
        }
        $(".tab_link").removeClass("active");
        $("#notice2").addClass("active");
        $("#synop02").css("display", "Block");
        $("#synop01").css("display", "none");
        $("#newDiv").css("display", "block");
        setIsNews(true);
        const data = {"category" : "synop02", "page" : 1};
        localStorage.setItem("categoryNotice", JSON.stringify(data));
        setPageNumNotice(1);
        setPageNumNews(1);
    }

    const apiServer = useRecoilValue(apiServerState);
    const [noticeList, setNoticeList] = useState([]);
    const [newsList, setNewsList] = useState([]);
    const [isMountedNotice, setIsMountedNotice] = useState(false);
    const [isMountedNews, setIsMountedNews] = useState(false);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }


    // 최초 랜더링시 공지사항, 1:1 문의 셋팅
    useEffect(() => {

        if (pageNumNotice !== null && pageNumNotice !== undefined && pageNumNews !== null && pageNumNews !==undefined) {
            update(pageNumNotice, pageNumNews);
        }
    
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, []);

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const update = (pageNotice, pageNew) => {

        let urlNotice = apiServer + "/api/aizac_notice/get_notice_list?page=" + pageNotice + "&row=10";
        let urlNews = apiServer + "/api/aizac_notice/get_inquiry_list?page=" + pageNew + "&row=10";
    
        const urlCall = async () => {
            await axios
                .get(urlNotice, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setTotalPageNotice(response?.data?.data?.totalPages);
                        setNoticeList(response?.data?.data?.noticeList);
                        setIsMountedNotice(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        } else {
                            alert(msg);
                        }
                        navigate(URL.MAIN);
                        console.log("로딩 실패");
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            await axios
                .get(urlNews, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setTotalPageNews(response?.data?.data?.totalPages);
                        setNewsList(response?.data?.data?.inquiryList);
                        setIsMountedNews(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        } else {
                            alert(msg);
                        }
                        navigate(URL.MAIN);
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        };
    
        urlCall();
    
    };

    // 각 공지사항, 1:1 문의 페이징 넘버 변경시 업데이트
    useEffect(() => {
        if(pageNumNotice !== null && pageNumNotice !== undefined && pageNumNews !== null && pageNumNews !== undefined) {
            update(pageNumNotice, pageNumNews);
        }
    }, [pageNumNotice, pageNumNews]);

    // 각 현재 페이지, 총 페이지 수 셋팅시 페이징 처리
    useEffect(() => {
        pageTag("공지사항");
        pageTag("1:1문의");
    }, [totalPageNews, totalPageNotice, pageNumNews, pageNumNotice])

    const [isNews, setIsNews] = useState(false);

    // 공지사항 상세 클릭시(공지사항 클릭시)
    const onClickNoticeDetail = (noticeId) => {
        const url = URL.LIST_NOTICE + "/" + noticeId;
        navigate(url);
    }

    // 1:1 문의 상세 클릭시
    const onClickNewsDetail = (newsId) => {
        if ($(("#que_content" + newsId)).css("display") === "none") {
            $(("#que_content" + newsId)).css("display", "block");
        } else {
            $(("#que_content" + newsId)).css("display", "none")
        }
    }

    // 새로운 문의 작성 버튼
    const onClickNewInquiry = () => {
        if (isQnaBool) {
            qnaRef.current.style.display = "none";
            setIsQnaBool(!isQnaBool);
        } else {
            qnaRef.current.style.display = "grid";
            setIsQnaBool(!isQnaBool);
        }
    }
    // 새로운 문의 작성 버튼
    const onClickNewInquiry2 = () => {
        if (isQnaBool) {
            qnaRef2.current.style.display = "none";
            setIsQnaBool(!isQnaBool);
        } else {
            qnaRef2.current.style.display = "grid";
            setIsQnaBool(!isQnaBool);
        }
    }

    const [qnaTitle, setQnaTitle] = useState('');
    const [qnaContent, setQnaContent] = useState('');
    const [isQnaBool, setIsQnaBool] = useState(false);
    const qnaRef = useRef();
    const qnaRef2 = useRef();

    // 새로운 문의 제목 변경 입력 함수
    const onChangeQnaTitle = (e) => {
        setQnaTitle(e.target.value);
    }


    // 새로운 문의 내용 변경 입력 함수
    const onChangeQnaContent = (e) => {
        setQnaContent(e.target.value);
    }

    // 문의하기 버튼
    const onClickQnaSaveBtn = () => {
        if (qnaTitle !== '') {
            if(qnaContent === "") {
                alert("1:1 문의 내용을 입력해주세요.");
                return;
            }
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization" : token,
                }
            };

            const form = {
                "id" : 0,
                "title" : qnaTitle,
                "content" : qnaContent
            }

            axios
                .post(apiServer + "/api/aizac_notice/update_inquiry", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        qnaRef.current.style.display = "none";
                        qnaRef2.current.style.display = "none";
                        setQnaTitle('');
                        setQnaContent('');
                        setIsQnaBool(!isQnaBool);
                    } else {
                        const msg = response?.data?.custMsg;
                        if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인 후 이용해 주세요.");
                            logout();
                        } else if (msg === "invalid data") {
                            alert("제목 또는 내용이 없습니다 다시 입력해주세요.");
                        } else if(msg === "router err") {
                            alert("서버 에러");
                        }
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        } else {
            alert(`1:1 문의 제목을 입력해주세요.`);
            return;
        }
    }

    // isQnaBool 변경시 업데이트
    useEffect(() => {
        if(pageNumNotice !== null && pageNumNotice !== undefined && pageNumNews !== null & pageNumNews !== undefined) {
            update(pageNumNotice, pageNumNews);
            pageTag("공지사항");
            pageTag("1:1문의");
        }
    }, [isQnaBool]);


    return(
        <>
            <ContainerDiv className={noticeCss.container + " container"}>
                <DefaultSection className={noticeCss.rank_veteran + " rank_veteran"}>
                    <DefaultSection className={noticeCss.synop_select + " synop_select"}>
                        <RankTitleDiv className={noticeCss.rank_title + " rank_title"}>
                            {isNews === false ? <RankTitleH1H1>공지사항</RankTitleH1H1> : <RankTitleH1H1>1:1 문의</RankTitleH1H1>}
                        </RankTitleDiv>
                        <DefaultDiv className={noticeCss.synop_select_tab + " synop_select_tab"}>
                            <StnopSelectTabNavDiv className={noticeCss.synop_select_tab_nav + " synop_select_tab_nav"}>
                                <DefaltUl>
                                    <SynopSelectTabNavLiLi  onClick={onClickNotice} ><SynopSelectTabNavLiALink aria-valuetext="synop01" id="notice1" className={noticeCss.tab_link + " tab_link active"}>공지사항</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                                    <SynopSelectTabNavLiLi  onClick={onClickNews} ><SynopSelectTabNavLiALink aria-valuetext="synop02" id="notice2" className={noticeCss.tab_link + " tab_link"}>1:1 문의</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                                </DefaltUl>
                                <NewDiv className={noticeCss.new + "new"} id="newDiv" onClick={onClickNewInquiry}>
                                    <NewWriteButton type="button" id="newWrite">새로운 문의 작성</NewWriteButton>
                                </NewDiv>
                            </StnopSelectTabNavDiv>
                            <SynopSelectTabContentDiv className={noticeCss.synop_select_tab_content + " synop_select_tab_content"}>
                                <DefaultDiv id="synop01">
                                    {isMountedNotice !== false ? <RankSynopSection className={noticeCss.rank_synop + " rank_synop"}>
                                        <NoticeUl className={noticeCss.notice + " notice"}>
                                            {noticeList && noticeList.map((notice) => {return(<NoticeLiLi key={"notice" + notice?.id} onClick={() => onClickNoticeDetail(notice?.id)}>
                                                <NoticeLiALink>
                                                    <NoticeTitleH2 className={noticeCss.title + " title"}>{notice?.title}</NoticeTitleH2>
                                                    <NoticeDateH4 className={noticeCss.date + " date"}>{notice?.createdAt?.substring(0, 10)}</NoticeDateH4>
                                                </NoticeLiALink>
                                            </NoticeLiLi>)})}
                                        </NoticeUl>
                                        <PaginationDiv className={noticeCss.pagination + " pagination"}>
                                            {paginationTagNoticeList}
                                        </PaginationDiv>
                                    </RankSynopSection> : <></>}
                                </DefaultDiv>
                                <Synop02Div id="synop02">
                                    {isMountedNews !== false ? <RankSynopSection className={noticeCss.rank_synop + " rank_synop"}>
                                        <QnaRegDiv className={noticeCss.qna_reg + " qna_reg qna-reg"} ref={(e) => {qnaRef.current = e}}>
                                            <QnaRegInputInput placeholder="제목을 입력해주세요" value={qnaTitle} onChange={(e) => {onChangeQnaTitle(e)}}/>
                                            <QnaRegTextareaTextarea placeholder="내용을 입력해주세요" value={qnaContent} onChange={(e) => {onChangeQnaContent(e)}}>문의하실 내용을 입력해주세요</QnaRegTextareaTextarea>
                                            <NewregButton type="button" id="newreg" onClick={onClickQnaSaveBtn}>문의하기</NewregButton>
                                        </QnaRegDiv>
                                        <NoticeUl className={noticeCss.notice + " notice"}>
                                            {newsList && newsList.map((news) => {return(<NoticeLiLi key={"news" + news?.id} onClick={() => {onClickNewsDetail(news?.id)}}>
                                                <DefaultDiv className={noticeCss.questionTitle + " questionTitle"}>
                                                    <QuestionH4 className={noticeCss.question + " question"}><NoticeLiALink >{news?.title}</NoticeLiALink></QuestionH4>
                                                    {news?.answerBool !== false ?<FinishDiv className={noticeCss.finish + " finish"}>답변완료</FinishDiv>: <FinishDiv className={noticeCss.finish + " " + noticeCss.wait + " finish wait"}>대기 중</FinishDiv>}
                                                    <QueContentDiv className={" que_content que-content"} id={("que_content" + news?.id)}>
                                                        <QueContentQDiv className="que-content-q">Q</QueContentQDiv>
                                                        <QueContentNDiv className="que-content-n">
                                                            {news?.content}
                                                        </QueContentNDiv>
                                                        <QueContentADiv className="que-content-a">A</QueContentADiv>
                                                        <QueContentNDiv className="que-content-n">
                                                            {news?.answer}
                                                        </QueContentNDiv>
                                                    </QueContentDiv>
                                                </DefaultDiv>
                                            </NoticeLiLi>)})}
                                        </NoticeUl>
                                        <InquireBtnButton type="button" className={noticeCss.inquireBtn + " inquireBtn"} onClick={onClickNewInquiry2}>1:1 문의하기</InquireBtnButton>
                                        <QnaRegDiv className={noticeCss.qna_reg + " qna_reg qna-reg"} style={{display : "none"}} ref={(e) => {qnaRef2.current = e}}>
                                            <QnaRegInputInput placeholder="제목을 입력해주세요" value={qnaTitle} onChange={(e) => {onChangeQnaTitle(e)}}/>
                                            <QnaRegTextareaTextarea placeholder="내용을 입력해주세요" value={qnaContent} onChange={(e) => {onChangeQnaContent(e)}}>문의하실 내용을 입력해주세요</QnaRegTextareaTextarea>
                                            <NewregButton type="button" id="newreg" onClick={onClickQnaSaveBtn}>문의하기</NewregButton>
                                        </QnaRegDiv>
                                        <PaginationDiv className={noticeCss.pagination + " pagination"}>
                                            {paginationTagNewsList}
                                        </PaginationDiv>
                                    </RankSynopSection> : <></>}
                                </Synop02Div>
                            </SynopSelectTabContentDiv>
                        </DefaultDiv>
                    </DefaultSection>
                </DefaultSection>
            </ContainerDiv>
        </>
    );
};

export default Notice;

/** div 태그 .que-content css */
const QueContentDiv = styled.div`
    display: none;
    margin-bottom: 24px;
    word-break: break-all;
`;

/** div 태그 .que-content-q css */
const QueContentQDiv = styled.div`
    color: #050409;
    font-family: "Min Sans-Bold";
    font-size: 18px;
    letter-spacing: -0.3px;
`;

/** div 태그 .que-content-n css */
const QueContentNDiv = styled.div`
    color: #050409;
    font-family: "Min Sans-Regular";
    font-size: 15px;
    letter-spacing: -0.3px;
    word-break: break-all;
`;

/** div 태그 .que-content-a css */
const QueContentADiv = styled.div`
    color: #ff562c;
    font-family: "Min Sans-Bold";
    font-size: 18px;
    letter-spacing: -0.3px;
    margin-top: 12px;
`;

/** a 태그 .pagination a css */
const PaginationLink = styled(Link)`
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    margin: 5px;
    padding: 5px 10px;
    color: #333;
    font-family: "Min Sans-Light";
    font-size: 14px;
    &:hover {
        background-color: #fff;
        color: #ff5f37;
    };
    &.current-page {
        color: #ff5f37 !important;
    }
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    padding: 0;
    @media (max-width:1400px) {
        padding: 0 120px;
    }
    @media (max-width: 568px) {
        padding: 0 18px;
    }
`;

/** section 태그 디폴트 css */
const DefaultSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 60px;
    @media (max-width: 560px) {
        padding-top: 120px;
    }
    @media (max-width: 568px) {
        padding-top:40px;
    }
`;

/** h1 태그 .rank-title h1 css */
const RankTitleH1H1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 46px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 22px;
    @media (max-width: 568px) {
        font-size: 26px;
        margin-bottom: 20px;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** section 태그 .rank-synop css */
const RankSynopSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 1400px) {
        max-width: 1296px;
    }
    @media (max-width: 560px) {
        max-width: 540px;
    }
`;

/** div 태그 .synop-select-tab-nav css */
const StnopSelectTabNavDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 24px;
    @media (max-width: 568px) {
        margin-bottom: 10px;
    }
`;

/** ul 태그 디폴트 css */
const DefaltUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** li 태그 .synop-select-tab-nav li css */
const SynopSelectTabNavLiLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
`;

/** a 태그 .synop-select-tab-nav li a css */
const SynopSelectTabNavLiALink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    letter-spacing: -0.3px;
    &:hover {
        background: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &.active {
        background: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    @media (max-width: 568px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right: 6px;
    }
`;

/** div 태그 .new css */
const NewDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
    &#synop01.active {
        display: block;
    }
`;

/** button 태그 #newWrite css */
const NewWriteButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #fff;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid #ff5f37;
    background-color: #ff5f37;;
    font-family: "min sans-regular";
    letter-spacing: -0.3px;
    white-space: nowrap;
    @media (max-width:1400px) {
        display: none;
    }
    @media (min-width: 1401px) {
        display: block;
    }
`;

/** div 태그 .synop-select-tab-content css */
const SynopSelectTabContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    border-top: none;
    margin-bottom: 120px;
    @media (max-width: 568px) {
        margin-bottom: 80px;
    }
`;

/** div 태그 #synop02 css */
const Synop02Div = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
    &.notice li:first-of-type {
        border-top: 1px solid #ededed;
    }
`;

/** ul 태그 .notice css */
const NoticeUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0;
    margin-bottom: 100px;
    @media (max-width:1400px) {
        margin-bottom: 32px;
    }  
`;

/** li 태그 .notice li css */
const NoticeLiLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    border-bottom: 1px solid #ededed;
    cursor: pointer;
`;

/** a 태그 .notice li a css */
const NoticeLiALink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;
    word-break: break-all;
`;

/** h2 태그 .notice .title css */
const NoticeTitleH2 = styled.h2`
    margin: 0;
    box-sizing: border-box;
    word-break: break-all;
    padding: 28px 0;
    color: #050409;
    font-family: "Min Sans-bold";
    font-size: 28px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    @media (max-width: 568px) {
        font-size: 16px;
        padding: 16px 0;
    }
`;

/** h4 태그 .notice .date css */
const NoticeDateH4 = styled.h4`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #050409;
    font-family: "Min Sans-bold";
    font-size: 18px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    @media (max-width: 568px) {
        display: none;
    }
`;

/** div 태그 .pagination css */
const PaginationDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

/** h4 태그 .question css */
const QuestionH4 = styled.h4`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding: 24px 0;
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    display: inline-block;
    word-break: break-all;
    @media (max-width: 568px) {
        display: block;
        padding-top: 16px;
        padding-bottom: 10px;
    }
`;

/** div 태그 .finish css */
const FinishDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #fff;
    background-color: #ff562c;
    border:1px solid #ff5f37;
    letter-spacing: -0.3px;
    font-size: 13px;
    padding: 4px 12px;
    border-radius:19px;
    display: inline-block;
    margin-bottom: 10px;
    &.wait {
        color: #9B9B9D;
        background-color: #fff;
        border: 1px solid #9B9B9D;
        padding:  4px 16px;
        white-space: nowrap;
    }
    @media (max-width: 568px) {
        margin-bottom: 16px;
    }
`;

/** button 태그 .inquireBtn css */
const InquireBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
    @media (max-width: 1400px) {
        font-size: 17px;
        background-color: #22222a;
        border-radius: 20px;
        padding: 24px 0;
        width: 100%;
        color: #fff;
        font-family: "min sans-regular";
        letter-spacing: -0.03px;
        cursor: pointer;
        text-align: center;
        display: block;
        border: none;
        &:hover {
            background-color: #303039;
        }
    }
    @media (min-width: 1401px) {
        display: none;
    }
`;

/** div 태그 .clock css 
const ClockDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 568px) {
        display: inline-block;
        float: right;
        color: #050409;
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        padding-top: 2px;
    }
    @media (min-width:  569px) {
        display: none;
    }
`;*/

/** div 태그 .qna-reg css */
const QnaRegDiv = styled.div`
    display: none;
    margin-bottom: 66px;
`;

/** input 태그 .qna-reg input css */
const QnaRegInputInput = styled.input`
    height: 52px;
    border: #bdbdbd 1px solid;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 8px 16px;
    font-family: "Min Sans-Regular";
    color: #050409;
    font-size: 16px;
    &:focus {
        outline: none;
    }
`;

/** textarea 태그 .qna-reg textarea css */
const QnaRegTextareaTextarea = styled.textarea`
    height: 250px;
    border: #bdbdbd 1px solid;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 8px 16px;
    font-family: "Min Sans-Regular";
    color: #050409;
    font-size: 16px;
    word-wrap: break-word;
    &:focus {
        outline: none;
    }
`;

/** button 태그 #newreg css */
const NewregButton = styled.button`
    color: #fff;
    padding: 8px 26px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid #ff5f37;
    background-color: #ff5f37;
    font-family: "min sans-regular";
    letter-spacing: -0.3px;
    white-space: nowrap;
    width: 300px;
    margin: 0 auto;
`;