import { Link, useNavigate } from 'react-router-dom';
import startImg from '../../css/images/start.png'
import nextImg from '../../css/images/next.png'
import blockImg from '../../css/images/block.png'
import typographyImg from '../../css/images/typography 1.png'
import gallery1Img from '../../css/images/gallery 1.png'
import writeroomCss from '../../css/writeroom_1.module.css'
import styled from '@emotion/styled';
import URL from '../../constants/url';
import { removeCookieOption, setCookieOption } from 'hooks/Cookie';
import { useEffect } from 'react';


const CreateMain = ({setIsCreateImg}) => {

    
    const navigate = useNavigate();
    setIsCreateImg(false);
    
    
    // 나만의 세계관으로 새롭게 시작 버튼 
    const onClickNovel2 = () => {
        const form  = {
            categoryId : 0,
            categoryName : "",
            category_id : 0,
            fileId : 0,
            id : 0,
            imgUrl : "",
            shortSynopsis : "",
            synopsis : "",
            title : ""
        }
        setCookieOption("synop", form, {path: ""});
        navigate(URL.CREATE_NOVEL2)
    }
    
    // 아이작 시놉시스로 간편하게 시작 버튼
    const onClickNovel1 = () => {
        removeCookieOption("synop", {path : ""});
        navigate(URL.CREATE_NOVEL1)
    }
    
    // AI 그림만들기로 간편하게 제작 버튼
    const onClickPicture1 = () => {
        removeCookieOption("synop", {path : ""});
        navigate(URL.CREATE_PICTURE1)
    }
    
    // AI 편집기 버튼
    const onClickAiEditor = () => {
        removeCookieOption("aiEditorContent", {path : ""});
        navigate(URL.AI_EDITOR_MAIN_EDIT)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])


    return (
        <>
            <ContainerDiv id="container">
                <section>
                    <TitleH2 className={writeroomCss.title + " title"}>아이작으로 글쓰기</TitleH2>
                    <WriteDiv className={writeroomCss.write + " write"}>
                        <StartDiv className={writeroomCss.start + " start"}>
                            <img src={startImg} alt='start_img'/>
                            <DownDiv className={writeroomCss.down + " down"}>
                                <div>
                                    <ContentsP className={writeroomCss.contents + " contents"}>세계관 설정이 어려운 초보라면?</ContentsP>
                                    <TitH2 className={writeroomCss.tit + " tit"}>아이작 시놉시스로 간편하게 시작</TitH2>
                                </div>
                                <EnterDiv className={writeroomCss.enter + " enter"} onClick={onClickNovel1}>
                                    <DefaultALink ><img src={nextImg} alt='next_img'/></DefaultALink>
                                </EnterDiv> 
                            </DownDiv>
                        </StartDiv>
                        <StartDiv className={writeroomCss.start + " start"}>
                            <img src={blockImg} alt='block_img'/>
                            <DownDiv className={writeroomCss.down + " down"}>
                                <div>
                                    <ContentsP className={writeroomCss.contents + " contents"}>상상하고 있는 세계관이 있다면?</ContentsP>
                                    <TitH2 className={writeroomCss.tit + " tit"}>나만의 세계관으로 새롭게 시작</TitH2>
                                </div>
                                <EnterDiv className={writeroomCss.enter + " enter"} onClick={onClickNovel2}>
                                    <DefaultALink ><img src={nextImg} alt='next_img_2'/></DefaultALink>
                                </EnterDiv> 
                            </DownDiv>
                        </StartDiv>
                    </WriteDiv>
                </section>
                <BottomSections className={writeroomCss.bottom + " bottom"}>
                    <AiCutDiv className={writeroomCss.aiCut + " aiCut"}>
                        <TitleH2 className={writeroomCss.title + " title"}>아이작 AI로 글 편집하기</TitleH2>
                        <CutDiv className={writeroomCss.cut + " cut"}>
                            <img src={typographyImg} alt='typography_img'/>
                            <DownDiv className={writeroomCss.down + " down"}>
                                <div>
                                    <ContentsP className={writeroomCss.contents + " contents"}>똑똑한 아이작 AI 편집기</ContentsP>
                                    <TitH2 className={writeroomCss.tit + " tit"}>AI 편집기</TitH2>
                                </div>
                                <EnterDiv className={writeroomCss.enter + " enter"} onClick={onClickAiEditor}>
                                    <DefaultALink><img src={nextImg} alt='next_img_3'/></DefaultALink>
                                </EnterDiv> 
                            </DownDiv>
                        </CutDiv>
                    </AiCutDiv>
                    <DrawingDiv className={writeroomCss.drawing + " drawing"}>
                        <TitleH2 className={writeroomCss.title + " title"}>아이작으로 그림 만들기</TitleH2>
                        <CutDiv className={writeroomCss.cut + " cut"}>
                            <img src={gallery1Img} alt='gallery_img'/>
                            <DownDiv className={writeroomCss.down + " down"}>
                                <div>
                                    <ContentsP className={writeroomCss.contents + " contents"}>상상만으로 그림이 그려지는 아이작</ContentsP>
                                    <TitH2 className={writeroomCss.tit + " tit"}>AI 그림만들기로 간편하게 제작</TitH2>
                                </div>
                                <EnterDiv className={writeroomCss.enter + " enter"} onClick={onClickPicture1}>
                                    <DefaultALink><img src={nextImg} alt='next_img_4'/></DefaultALink>
                                </EnterDiv> 
                            </DownDiv>
                        </CutDiv>
                    </DrawingDiv>
                </BottomSections>
            </ContainerDiv>
        </>
    );
}
export default CreateMain;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 140px;
    padding-top: 120px;
    @media(max-width:1400px) {
        padding: 0 80px;
        padding-top: 120px;
        overflow-y: auto; 
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 30px;
        padding-top: 120px;
        overflow-y: auto; 
    }
    @media(max-width:568px) {
        padding: 0 22px;
        padding-top: 70px;
    }
`;

/** h2 태그 .title css */
const TitleH2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 24px;
    font-family: "Min Sans-bold";
    padding-bottom: 20px;
    letter-spacing: -0.03em;
    color: #050409;
`;

/** div 태그 .write css */
const WriteDiv = styled.div`
    display: flex;
    gap: 24px;
    @media(max-width:568px) {
        flex-direction: column;
    }
`;

/** div 태그 .start css */
const StartDiv = styled.div`
    padding: 38px 42px;
    border: 1px solid #ff5f37;
    border-radius: 20px;
    width: 50%;
    @media(max-width:1400px) {
        padding: 38px 20px;
    }
    @media(max-width:568px) {
        width: 100%;
    }
`;

/** div 태그 .down css */
const DownDiv = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

/** p 태그 .contents css */
const ContentsP = styled.p`
    font-size: 14px;
    font-family: "Min Sans-regular";
    letter-spacing: -0.03em;
    color: #22222A;
    margin-top: 24px;
    margin-bottom: 4px;
`;

/** h2 태그 .tit css */
const TitH2 = styled.h2`
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #ff5f37;
`;

/** div 태그 .enter css */
const EnterDiv = styled.div`
    @media(max-width:1400px) {
        display: block;
    }
`;

/** section 태그 .bottom css */
const BottomSections = styled.section`
    display: flex;
    gap: 24px;
    margin-top: 80px;
    @media(max-width:568px) {
        flex-direction: column;
        margin-top: 60px;
        gap: 60px;
    }
`;

/** div 태그 .aiCut css */
const AiCutDiv = styled.div`
    width: 50%;
    @media(max-width:568px) {
        width: 100%;
    }
`;
/** div 태그 .drawing css */
const DrawingDiv = styled.div`
    width: 50%;
    @media(max-width:568px) {
        width: 100%;
    }
`;

/** div 태그 .cut css */
const CutDiv = styled.div`
    padding: 38px 42px;
    border: 1px solid #ff5f37;
    border-radius: 20px;
    @media(max-width:1400px) {
        padding: 38px 20px;
    }
`;

