import styled from "@emotion/styled";
import LevelBar from "../../components/common/LevelBar";
import MyPageUserInput from "../../components/common/MyPageUserInput";
import { getCookie } from "hooks/Cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import logo_1 from '../../css/images/logo-l.png';
import { Link } from 'react-router-dom';
import headerCss from "../../css/main.module.css";
import URL from "constants/url";


const MyInfo = ({changeBool, setChangeBool}) => {

    const token = getCookie("token");
    const navigate = useNavigate();
    // 비로그인시 로그인 페이지 이동
    if(token === undefined) {
        navigate("/login");
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])
    
    return (
        <>
            <ContainerDiv id="container">
                <NavbarBrandLink className={headerCss.navbar_brand + " navbar_brand"} to={URL.MAIN}>
                    <NavLogoImg src={logo_1} alt="logo_1" className={headerCss.nav_logo + ' nav_logo'}/>
                </NavbarBrandLink>
                <ContainerH2H2>계정 정보 관리</ContainerH2H2>
                <LevelBar/>
                <MyPageUserInput changeBool={changeBool} setChangeBool={setChangeBool} />
            </ContainerDiv>
        </>
    );
}
export default MyInfo;

/** a 태그 .navbar-brand css */
const NavbarBrandLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    margin-left: 16px;
    display: none;
    margin-top: 0px;
    margin-bottom: 30px;
    @media (max-width: 560px) {
      margin-left: 0px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        display: block;
    }
`;

/** img 태그 .nav-logo css */
const NavLogoImg = styled.img`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  height: 24px;
  @media (max-width: 560px) {
    height: 18px;
  }
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 208px;
    padding-top: 104px;
    padding-bottom: 80px;
    @media(max-width:1400px) {
        padding: 0 80px;
        padding-top: 104px;
        width: 700px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
    }
    @media (min-width: 100px) and (max-width: 767px) {
        width: 100%;
        height: 150%;
    }
`;


/** h2 태그 #container h2 css */
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    font-weight: bold;
    @media(max-width:568px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;