import styled from "@emotion/styled";
import LeftAside from "../../components/common/LeftAside";
import ModifyCharacter from "../../components/modify/ModifyCharacter";
import ModifyNovel from "../../components/modify/ModifyNovel";
import URL from "../../constants/url";
import { useState } from "react";
import { Route, Routes } from "react-router";
import ModifyMyPicture from "components/modify/ModifyMyPicture";


const AizacModify = () => {

    const [isUpdateBool, setIsUpdateBool] = useState(false);

    
    return (
        <>
            <WrapDiv id="wrap">
                    <LeftAside isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>
                    <Routes>
                        <Route path={URL.NOVEL_MODIFY} element={<ModifyNovel isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} />} />
                        <Route path={URL.CHARACTER_MODIFY} element={<ModifyCharacter isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} />} />
                        <Route path={URL.MY_PICTURE_MODIFY} element={<ModifyMyPicture isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} />} />
                    </Routes>
            </WrapDiv>
        </>
    );
};
export default AizacModify;


/** div 태그 #wrap css */
const WrapDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
    height: 100vh;
    @media (max-width: 1400px) {
        display: grid;
        grid-template-columns: 1.5fr 4fr;
        grid-template-rows: auto;
        height: 100vh;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
`;