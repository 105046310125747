import { Link } from 'react-router-dom';
import myPictuerCss from '../../css/writeroom_mydrwaing.module.css'
import styled from '@emotion/styled';
import $ from 'jquery'
import { useEffect, useState } from 'react';
import MyPictureSection from 'components/common/MyPictureSection';

const MyPicture = () => {

    const [category, setCategory] = useState(null);
    const [page, setPage] = useState(null);
    
    const localData = JSON.parse(localStorage.getItem("myPicturePageData"));
    // 카테고리 클릭 
    $(document).ready(function () {
        $(".my_picture_tab_nav a").click(function () {
            var targetId = $(this).attr("aria-valuetext");
            $(".my_picture_tab_nav a").removeClass("active");
            $(this).addClass("active");
            setCategory(targetId);
            setPage(1);
            localStorage.removeItem("pageData");
            localStorage.removeItem("pageDataSynop");
            localStorage.removeItem("categoryNotice");
            localStorage.removeItem("categoryPay");
            localStorage.removeItem("myPicturePageData");
            localStorage.removeItem("rowCnt");
            const data = {"section" : targetId, "page" : 1}
            localStorage.setItem("myPicturePageData", JSON.stringify(data))
        });
    });

    useEffect(() => {
        console.log(localData);
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        if(localData !== null && localData !== undefined) {
            setCategory(localData.section);
            setPage(localData.page);
            $(".synop_select_tab_nav a").removeClass("active");
            switch (localData.section) {
                case "my" : {
                    $("#synop2").removeClass("active");
                    $("#synop1").addClass("active");
                    break;
                }
                case "buy" : {
                    $("#synop1").removeClass("active");
                    $("#synop2").addClass("active");
                    break;
                }
            }
        } else {
            $("#synop1").addClass("active");
            setCategory("my");
            setPage(1);
        }
    }, [])

    useEffect(() => {

        console.log("category : " + category + " page : " + page);
    }, [category, page])

    return(
        <>
            <ContainerDiv id="container">
                <section className={myPictuerCss.synop_select + " synop_select synop-select"}>
                    <SynopSelectNavDiv className={myPictuerCss.synop_select_tab + " my_picture_tab"}>
                        <SynopSelectTabUlUl className={myPictuerCss.my_picture_tab_nav + " my_picture_tab_nav"}>
                            <SynopSelectTabNavLiLi><SynopSelectTabNavLiALink id='synop1' aria-valuetext="my" className={"active"}>내 그림 리스트</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                            <SynopSelectTabNavLiLi><SynopSelectTabNavLiALink id='synop2' aria-valuetext="buy">구매한 그림</SynopSelectTabNavLiALink></SynopSelectTabNavLiLi>
                        </SynopSelectTabUlUl>
                        <SynopSelectTabContentDiv className={myPictuerCss.synop_select_tab_content + " synop_select_tab_content synop-select-tab-content"}>
                            <div id="synop01" style={{display : "block"}}>
                                <MyPictureSection category={category} pageNum={page} setPageNum={setPage}/>
                            </div>
                        </SynopSelectTabContentDiv>
                    </SynopSelectNavDiv>
                </section>
            </ContainerDiv>
        </>
    );
};
export default MyPicture;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 134px;
    padding-top: 120px;
    overflow-y: auto;
    @media(max-width:1400px) {
        padding: 0 80px;
        padding-top: 120px;
        overflow-y: auto; 
    }
    @media(max-width:568px) {
        padding: 0 22px;
        padding-top: 70px;
    }
`;

/** div 태그 .synop-select-nav css */
const SynopSelectNavDiv = styled.div`
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

/** ul 태그 .synop-select-tab > ul synop-select-tab-nav css */
const SynopSelectTabUlUl = styled.ul`
    list-style: none;
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
`;

/** li 태그 .synop-select-tab-nav li css */
const SynopSelectTabNavLiLi = styled.li`
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-bold";
    font-size: 24px;
    letter-spacing: -0.3px;
    color: #BDBDBD;
`;
/** li 태그 .synop-select-tab-nav li a css */
const SynopSelectTabNavLiALink = styled(Link)`
    text-decoration: none;
    color: inherit;
    margin-right: 18px;
    &:hover{
        color: #050409;
    }
    &.active {
        color: #050409;
    }
`;

/** div 태그 .synop-select-tab-content css */
const SynopSelectTabContentDiv = styled.div`
    height: auto;
    border-top: none;
`;