import styled from '@emotion/styled';
import withDrawalCss from '../../css/withDrawal.module.css'
import { useEffect } from 'react';


const WithDrawal3 = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])
    
    return (
        <>
            <div id="wrap">
                <ContainerDiv id="container">
                    <div className={withDrawalCss.title + " title"}>
                        <TitleH4H4>아이작 회원탈퇴</TitleH4H4>
                        <TitleH2H2>회원탈퇴가 완료되었습니다</TitleH2H2>
                    </div>
                    <div className={withDrawalCss.reOk + " reOk"}>
                        <div className={withDrawalCss.reOK_1 + " reOK_1 reOK-1"}>
                            그동안 아이작을 이용해주셔서 감사합니다.<br />
                            더 좋은 서비스로 다시 만나요!
                        </div>
                    </div>
                </ContainerDiv>
            </div>
        </>
    );
};
export default WithDrawal3;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    max-width: 100%;
    width: auto;
    height: auto;
    padding: 0px 324px;
    padding-top: 120px;
    @media (max-width: 1400px) {
        max-width: 100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-top: 120px;
    }
    @media (max-width: 568px) {
        padding: 0 22px;
    }
`;

/** h4 태그 .title h4 css */
const TitleH4H4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: "Min Sans-bold";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom: 8px;
`;

/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media (max-width: 568px) {
        font-size: 36px;
    }
`;