import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import createNovel5Css from '../../css/createNovel5.module.css'
import axios from "axios";
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { useNavigate } from "react-router";
import URL from "../../constants/url";
import $ from 'jquery';
import Swal from "sweetalert2";
import V1CteateNovelRightAsideSceneDetail from "./V1CteateNovelRightAsideSceneDetail";


const V1CreateNovelRightAsideSceneCreate = ({lastScene, novelDetail, characters, selectBool, setSelectBool, dataList, setDataList, isUpdateBool, setIsUpdateBool, setLengthBool}) => {

    /** 상위 컴포넌트에서 lastScene text 내용, setLastScene 설정함수, setSelectScene 선택 씬 설정 함수,  
        씬리스트의 리스트(재생성시 리스트 추가)생성결과 및 좌 우 버튼 클릭시 리스트 순서대로 보여주기(index설정 usestate)
        씬 클릭시 5개 별로 api호출   */

    
    
    const sceneDetails = useRef([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);

    // 씬 선택지 라디오 버튼 선택
    const handleChange = (e, index) => {

        if (e.target.checked === true) {
            sceneCheckClick(parseInt(e.target.value), index);
        }
    }

    // 씬 선택지 index에 따라 seneDetail index 컴포넌트 화면 보이기
    const sceneCheckClick = (id, index) => {

        for(let i = 0; i < sceneTitleListList.length; i++) {
            for (let j = 0; j < 6; j++) {
                sceneDetails.current[i][j].style.display = "none";
            }
        }
        if (id === 0) {
            sceneDetails.current[0][0].style.display = "block";
        } else {
            sceneDetails.current[index][id].style.display = "block";
        }
    }

    const inputRef = useRef();

    // 직접 입력 버튼
    const onClickCreateInput = () => {
        if (inputRef.current.style.display === "flex") {
            inputRef.current.style.display = "none"
        } else {
            inputRef.current.style.display = "flex"
        }
    }

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    const [sceneTitleListList, setSceneTitleListList] = useState([]);
    const [sceneTitleListIndex, setSceneTitleListIndex] = useState(0);
    const sceneTitleListIndexRef1 = useRef([]);
    const [isMountCreateLoding, setIsMountCreateLoding] = useState(false);
    const [isClickCreate, setIsClickCreate] = useState(true);
    const [isClickReCreate, setIsClickReCreate] = useState(true);

    const [isMountOnClickBoolList, setIsMountOnClickBoolList] = useState([[false, false, false, false, false, false]]);
    const [isMountOnClickBoolcheckList, setIsMountOnClickCheckBoolList] = useState([[false, false, false, false, false, false]]);
    const [userInfo, setUserInfo] = useState(null);
    const [previousText, setPreviousText] = useState('');

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 에피소드 화수에 따른 전체 내용 텍스트 셋팅
    useEffect(() => {
        const url = apiServer + "/api/asiac_novel/get_merge_scences_by_chapter?novelId=" + novelDetail.id +"&chapter=" + novelDetail.episodeCnt;

        const apiText = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };
            
            await axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success) {
                    setPreviousText(response?.data?.data?.mergedScene);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        }
        apiText();
    }, [])

    // isUpdateBool 변경시 유저 정보 업데이트(api 코인 차감 업데이트 용도)
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])


    // 씬 선택지 리스트 변경시 리스트 index에 따른 display 변경
    useEffect(() => {
        if (sceneTitleListList.length > 0) {
            sceneTitleListIndexRef1.current[sceneTitleListIndex].style.display = "block";
        }
    }, [sceneTitleListList])

    // 소설 상세 정보 변경시 최초 씬 선택지 초기화
    useEffect(() => {
        setSceneTitleListList([]);
    }, [novelDetail])

    // 다음 씬 생성하기 버튼
    const onClickCreateScene = () => {
        Swal.fire({
            title : "요금 안내",
            text: "씬 생성은 1회 당 100코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 100 || userInfo?.coinAuth === "unlimited") {
                    if (isClickCreate) {
                        if(dataList.length > 0) {
                            const lastSceneData = (String(lastScene.scene)).trim();
                            if(lastScene.scene === "") {
                                alert("씬 내용이 없습니다. 씬 내용을 입력하거나 씬을 삭제해주세요.");
                                return;
                            }
                            if (lastSceneData === "") {
                                alert("씬 내용이 없습니다. 씬 내용을 입력하거나 씬을 삭제해주세요.");
                                return;
                            }
                        }
                        setIsClickCreate(false);
                        setIsMountCreateLoding(true);
                        const config = {
                            headers: {
                              "Content-Type": "application/json",
                              "authorization": token,
                            }
                        };
                        
                        const form = {synopsis: "", reference: "", keywords: "", characters: ""};
                        form.synopsis = novelDetail.synopsis;
                        if (lastScene.scene === '') {
                            if(previousText === '') {
                                form.reference = "첫장면";
                            } else {
                                form.reference = previousText;
                            }
                        } else {
                            form.reference = lastScene.scene;
                        }
                        form.keywords = "";
                        form.characters = characters;
                        
                        axios
                        .post(apiServer + "/api/asiac_nestyle/scene/recommend/suggestions", form, config)
                        .then((response) => {
                            if (response?.data?.success ) {
                                const copyList = [];
                                copyList.push(response.data.data.choices);
                                setSceneTitleListList(copyList);
                                setSceneTitleListIndex(0);
                                setIsMountCreateLoding(false);
                                const copy = [];
                                copy.push([false, false, false, false, false, false])
                                setIsMountOnClickBoolList(copy);
                                setIsClickCreate(true);
                                setIsUpdateBool(!isUpdateBool);
                            } else {
                                const msg = response.data.custMsg;

                                if ((msg).includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "axios err" || msg === "ai generator err") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsMountCreateLoding(false);
                                setIsClickCreate(true);
                                setIsUpdateBool(!isUpdateBool);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                        });
                    } else {
                        alert("생성중입니다. 잠시만 기다려주세요.");
                        console.log("생성중...");
                    }
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
    }

    // 씬 선택지 재생성 버튼
    const onClickReCreate = () => {
        Swal.fire({
            title : "요금 안내",
            text: "씬 생성은 1회 당 100코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 100 || userInfo?.coinAuth === "unlimited") {
                    if (isClickReCreate) {
                        setIsMountCreateLoding(true);
                        const config = {
                            headers: {
                              "Content-Type": "application/json",
                              "authorization": token,
                            }
                        };
                
                        const form = {synopsis: "", reference: "", keywords: "", characters: ""};
                        form.synopsis = novelDetail.synopsis;
                        if (lastScene.scene === '') {
                            form.reference = previousText;
                        } else {
                            form.reference = lastScene.scene;
                        }
                        form.keywords = "";
                        form.characters = characters;
                    
                        axios
                            .post(apiServer + "/api/asiac_nestyle/scene/recommend/suggestions", form, config)
                            .then((response) => {
                                if (response?.data?.success) {
                                    const copyList = [...sceneTitleListList];
                                    copyList.push(response.data.data.choices);
                                    setSceneTitleListList(copyList);
                                    const copuNum = sceneTitleListIndex + 1;
                                    setSceneTitleListIndex(copuNum);
                                    setIsMountCreateLoding(false);
                                    const copy = [...isMountOnClickBoolList];
                                    const copy2 = [...isMountOnClickBoolcheckList];
                                    copy.push([false, false, false, false, false, false])
                                    copy2.push([false, false, false, false, false, false])
                                    setIsMountOnClickBoolList(copy);
                                    setIsMountOnClickCheckBoolList(copy2);
                                    setIsClickReCreate(true);
                                    setIsUpdateBool(!isUpdateBool);
                                } else {
                                    const msg = response.data.custMsg;
                                    if ((msg).includes("timeout")) {
                                        alert("요청 시간이 초과되었습니다.");
                                    } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                        alert("다시 로그인 후 이용해주세요.");
                                        logout();
                                    } else if (msg === "need more coin") {
                                        alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                    } else if (msg === "axios err" || msg === "ai generator err") {
                                        alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                    } else if (msg === "router err") {
                                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                    } else {
                                        alert(msg);
                                    }
                                    console.log(msg);
                                    setIsMountCreateLoding(false);
                                    setIsClickReCreate(true);
                                    setIsUpdateBool(!isUpdateBool);
                                }
                            })
                            .catch((e) => {
                                alert(e.message);
                                console.log(e);
                            });
                        
                    } else {
                        alert("씬 선택지가 재생성중입니다. 잠시만 기다려주세요.");
                        console.log("재생성중....");
                    }
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
        
    }
    
    // 씬 선택지 리스트 page 넘버 증가 버튼
    const onClickIncrease = () => {
        if (sceneTitleListList.length > sceneTitleListIndex + 1) {
            let copyNum = sceneTitleListIndex + 1;
            setSceneTitleListIndex(copyNum);
            $(".radioSceneName").checked = false;
            for (let i = 0; i < sceneTitleListList.length; i++) {
                sceneTitleListIndexRef1.current[i].style.display = "none";
            }
            sceneTitleListIndexRef1.current[copyNum].style.display = "block";
        }
    }
    
    // 씬 선택지 리스트 page 넘버 감소 버튼
    const onClickDecrease = () => {
        if (sceneTitleListIndex > 0) {
            let copyNum = sceneTitleListIndex - 1;
            setSceneTitleListIndex(copyNum);
            $(".radioSceneName").checked = false;
            for (let i = 0; i < sceneTitleListList.length; i++) {
                sceneTitleListIndexRef1.current[i].style.display = "none";
            }
            sceneTitleListIndexRef1.current[copyNum].style.display = "block";
        }
    }
    
    // 씬 선택지 선택시 활성화 함수
    const onClickSelect = (number, boolCheck, index) => {
        if (!boolCheck) {
            Swal.fire({
                title : "요금 안내",
                text: "씬 스토리 생성은 1회 당 200코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 200 || userInfo?.coinAuth === "unlimited") {
                        switch (number) {
                            case 0: {
                                let copy = [...isMountOnClickBoolList];
                                let copy2 = [...isMountOnClickBoolcheckList];
                                copy[index][0] = true;
                                copy2[index][0] = true;
                                setIsMountOnClickBoolList(copy);
                                setIsMountOnClickCheckBoolList(copy2);
                                break;
                            }
                            case 1: {
                                let copy = [...isMountOnClickBoolList];
                                let copy2 = [...isMountOnClickBoolcheckList];
                                copy[index][1] = true;
                                copy2[index][1] = true;
                                setIsMountOnClickBoolList(copy);
                                setIsMountOnClickCheckBoolList(copy2);
                                break;
                            }
                            case 2: {
                                let copy = [...isMountOnClickBoolList];
                                let copy2 = [...isMountOnClickBoolcheckList];
                                copy[index][2] = true;
                                copy2[index][2] = true;
                                setIsMountOnClickBoolList(copy);
                                setIsMountOnClickCheckBoolList(copy2);
                                break;
                            }
                            case 3: {
                                let copy = [...isMountOnClickBoolList];
                                let copy2 = [...isMountOnClickBoolcheckList];
                                copy[index][3] = true;
                                copy2[index][3] = true;
                                setIsMountOnClickBoolList(copy);
                                setIsMountOnClickCheckBoolList(copy2);
                                break;
                            }
                            case 4: {
                                let copy = [...isMountOnClickBoolList];
                                let copy2 = [...isMountOnClickBoolcheckList];
                                copy[index][4] = true;
                                copy2[index][4] = true;
                                setIsMountOnClickBoolList(copy);
                                setIsMountOnClickCheckBoolList(copy2);
                                break;
                            }
                            case 5: {
                                let copy = [...isMountOnClickBoolList];
                                let copy2 = [...isMountOnClickBoolcheckList];
                                copy[index][5] = true;
                                copy2[index][5] = true;
                                setIsMountOnClickBoolList(copy);
                                setIsMountOnClickCheckBoolList(copy2);
                                break;
                            }
                        }
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else {
                                switch (number) {
                                    case 0: {
                                        let copy = [...isMountOnClickBoolList];
                                        copy[index][0] = false;
                                        setIsMountOnClickBoolList(copy);
                                        break;
                                    }
                                    case 1: {
                                        let copy = [...isMountOnClickBoolList];
                                        copy[index][1] = false;
                                        setIsMountOnClickBoolList(copy);
                                        break;
                                    }
                                    case 2: {
                                        let copy = [...isMountOnClickBoolList];
                                        copy[index][2] = false;
                                        setIsMountOnClickBoolList(copy);
                                        break;
                                    }
                                    case 3: {
                                        let copy = [...isMountOnClickBoolList];
                                        copy[index][3] = false;
                                        setIsMountOnClickBoolList(copy);
                                        break;
                                    }
                                    case 4: {
                                        let copy = [...isMountOnClickBoolList];
                                        copy[index][4] = false;
                                        setIsMountOnClickBoolList(copy);
                                        break;
                                    }
                                    case 5: {
                                        let copy = [...isMountOnClickBoolList];
                                        copy[index][5] = false;
                                        setIsMountOnClickBoolList(copy);
                                        break;
                                    }
                                }
                            }
                        });
                    }
                } else {
                    switch (number) {
                        case 0: {
                            let copy = [...isMountOnClickBoolList];
                            copy[index][0] = false;
                            $("#s0")[0].checked = false;
                            setIsMountOnClickBoolList(copy);
                            break;
                        }
                        case 1: {
                            let copy = [...isMountOnClickBoolList];
                            copy[index][1] = false;
                            setIsMountOnClickBoolList(copy);
                            break;
                        }
                        case 2: {
                            let copy = [...isMountOnClickBoolList];
                            copy[index][2] = false;
                            setIsMountOnClickBoolList(copy);
                            break;
                        }
                        case 3: {
                            let copy = [...isMountOnClickBoolList];
                            copy[index][3] = false;
                            setIsMountOnClickBoolList(copy);
                            break;
                        }
                        case 4: {
                            let copy = [...isMountOnClickBoolList];
                            copy[index][4] = false;
                            setIsMountOnClickBoolList(copy);
                            break;
                        }
                        case 5: {
                            let copy = [...isMountOnClickBoolList];
                            copy[index][5] = false;
                            setIsMountOnClickBoolList(copy);
                            break;
                        }
                    }
                }
            });
        }
        
    }
    
    const [inputTitle, setInputTitle] = useState('');

    // 직접 입력 변경 함수
    const onChangeInputTitle = (e) => {
        setInputTitle(e.target.value);
    };

    const onClickNewScene = () => {
        let copy = [...dataList];
        const copyData = { title: "", scene: ""};
        copy.push(copyData);
        setDataList(copy);
        setLengthBool(false);
    }
    
    
    return (
        <>
            <div className={createNovel5Css.tit + " tit"}>
                <svg
                    width="25"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    style={{fill : "#bcbcbf"}}>
                    <path
                        d="M64 96c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V352H512V96H128V352H64V96zM0 403.2C0 392.6 8.6 384 19.2 384H620.8c10.6 0 19.2 8.6 19.2 19.2c0 42.4-34.4 76.8-76.8 76.8H76.8C34.4 480 0 445.6 0 403.2zM281 209l-31 31 31 31c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-48-48c-9.4-9.4-9.4-24.6 0-33.9l48-48c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM393 175l48 48c9.4 9.4 9.4 24.6 0 33.9l-48 48c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l31-31-31-31c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"
                        />
                </svg>
                <p style={{marginBottom : "0"}}>씬 생성</p>
            </div>
            <div className={createNovel5Css.scene_btn + " scene_btn scene-btn"}>
                <div className={createNovel5Css.next_scene + " next_scene next-scene"} onClick={onClickNewScene}>글 직접입력하기+</div>
            </div>
            <div className={createNovel5Css.scene_btn + " scene_btn scene-btn"}>
                <div className={createNovel5Css.next_scene + " next_scene next-scene"} onClick={onClickCreateScene}>다음 씬 생성하기+</div>
                <div className={createNovel5Css.re_scene + " re_scene re-scene"} onClick={onClickCreateInput}>직접 입력</div>
            </div>
                <InputAreaDiv id="input-area" ref={inputRef}>
                    <textarea className={createNovel5Css.scene_n_input + " scene_n_input scene-n-input"} onChange={(e) => {onChangeInputTitle(e)}} value={inputTitle}></textarea>
                    <input
                        type="radio"
                        name="select"
                        value={0}
                        id="s0"
                        style={{marginLeft : "20px", backgroundColor : "#fff"}}
                        onChange={(e) => {handleChange(e, 0); onClickSelect(0, isMountOnClickBoolcheckList[0][0], 0)}}
                        className={createNovel5Css.scene_n_toggle + " scene_n_toggle scene-n-toggle"}/>
                </InputAreaDiv>
            {isMountCreateLoding === true ? <div className={createNovel5Css.scene_n + " scene_n scene-n"}>씬이 생성중입니다.</div> : <></>}
            {isMountCreateLoding === false ?  <> <div className={createNovel5Css.scene_main + " scene_main scene-main"}>
                {sceneTitleListList && sceneTitleListList.map((sceneTitleList, index) => {return( <><div key={"scene" + index} ref={(e) => (sceneTitleListIndexRef1.current[index] = e)} style={{display : "none"}}> 
                <div className={createNovel5Css.scene_main_list + " scene_main_list scene-main-list"}>
                    <label htmlFor={'s' + (index * 5 + 1)}>{sceneTitleList?.choice1}</label>
                    <input type="radio" name="select" style={{backgroundColor : "#fff"}} value={1} className="radioSceneName" id={"s" + (index * 5 + 1)} onChange={(e) => {handleChange(e, index); onClickSelect(1, isMountOnClickBoolcheckList[index][1], index)}}/>
                </div>
                <div className={createNovel5Css.scene_main_list + " scene_main_list scene-main-list"}>
                    <label htmlFor={'s' + (index * 5 + 2)}>{sceneTitleList?.choice2}</label>
                    <input type="radio" name="select" style={{backgroundColor : "#fff"}} value={2} className="radioSceneName" id={"s" + (index * 5 + 2)} onChange={(e) => {handleChange(e, index); onClickSelect(2, isMountOnClickBoolcheckList[index][2], index)}} />
                </div>
                <div className={createNovel5Css.scene_main_list + " scene_main_list scene-main-list"}>
                    <label htmlFor={'s' + (index * 5 + 3)}>{sceneTitleList?.choice3}</label>
                    <input type="radio" name="select" style={{backgroundColor : "#fff"}} value={3} className="radioSceneName" id={"s" + (index * 5 + 3)} onChange={(e) => {handleChange(e, index); onClickSelect(3, isMountOnClickBoolcheckList[index][3], index)}}/>
                </div>
                <div className={createNovel5Css.scene_main_list + " scene_main_list scene-main-list"}>
                    <label htmlFor={'s' + (index * 5 + 4)}>{sceneTitleList?.choice4}</label>
                    <input type="radio" name="select" style={{backgroundColor : "#fff"}} value={4} className="radioSceneName" id={"s" + (index * 5 + 4)} onChange={(e) => {handleChange(e, index); onClickSelect(4, isMountOnClickBoolcheckList[index][4], index)}}/>
                </div>
                <div className={createNovel5Css.scene_main_list + " scene_main_list scene-main-list"}>
                    <label htmlFor={'s' + (index * 5 + 5)}>{sceneTitleList?.choice5}</label>
                    <input type="radio" name="select" style={{backgroundColor : "#fff"}} value={5} className="radioSceneName" id={"s" + (index * 5 + 5)} onChange={(e) => {handleChange(e, index); onClickSelect(5, isMountOnClickBoolcheckList[index][5], index)}}/>
                </div></div></>)})}
            </div> 
            {sceneTitleListList.length !== 0 ? <div className={createNovel5Css.scene_main_select + " scene_main_select scene-main-select"}>
                <div className={createNovel5Css.scene_main_select_1 + " scene_main_select_1 scene-main-select-1"}>
                    <div>생성결과 {sceneTitleListIndex + 1}/{sceneTitleListList?.length}</div>
                    <button onClick={onClickDecrease}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18px"
                            height="18px"
                            viewBox="0 0 320 512"
                            style={{fill : "#bcbcbf"}}>
                            <path
                                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                            />
                        </svg>
                    </button>
                    <button onClick={onClickIncrease}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18px"
                            height="18px"
                            viewBox="0 0 320 512"
                            style={{fill : "#bcbcbf"}}>
                            <path
                                d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                            />
                        </svg>
                    </button>
                </div>
                <div className={createNovel5Css.rere_scene + " rere_scene rere-scene"} onClick={onClickReCreate}>재생성</div>
            </div> : <></>} </>: <></>}
            <hr className={createNovel5Css.edit_hr + " edit_hr edit-hr"} />
            <div className={createNovel5Css.scene_select + " scene_select scene-select"}>
                <div className={createNovel5Css.tit + " tit"}>
                    <svg
                        width="25"
                        height="25"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        style={{fill : "#bcbcbf"}}
                    >
                        <path
                        d="M64 96c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V352H512V96H128V352H64V96zM0 403.2C0 392.6 8.6 384 19.2 384H620.8c10.6 0 19.2 8.6 19.2 19.2c0 42.4-34.4 76.8-76.8 76.8H76.8C34.4 480 0 445.6 0 403.2zM281 209l-31 31 31 31c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-48-48c-9.4-9.4-9.4-24.6 0-33.9l48-48c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM393 175l48 48c9.4 9.4 9.4 24.6 0 33.9l-48 48c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l31-31-31-31c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"
                        />
                    </svg>
                    <p style={{marginBottom : "0"}}>스토리 생성</p>
                </div>
                    <div className={createNovel5Css.scene_select_list + " scene_select_list scene-select-list"} id={"s0c"} ref={el => (sceneDetails.current[0][0] = el)}>
                        <V1CteateNovelRightAsideSceneDetail synopsis={novelDetail?.synopsis} scene_recommend={inputTitle} characters={characters} number={0} index={0} isMountOnClickBool={isMountOnClickBoolList[0][0]} isMountOnClickBoolList={isMountOnClickBoolList} setIsMountOnClickBoolList={setIsMountOnClickBoolList} selectBool={selectBool} setSelectBool={setSelectBool} dataList={dataList} setDataList={setDataList}  setSceneTitleListList={setSceneTitleListList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setIsMountOnClickCheckBoolList={setIsMountOnClickCheckBoolList} setInputTitle={setInputTitle} lastScene={lastScene} previousText={previousText} setLengthBool={setLengthBool}/>
                    </div>
                {sceneTitleListList && sceneTitleListList.map((sceneTitleList, index) => {return(<>
                    {index !== 0 ? <div ref={el => (sceneDetails.current[index][0] = el)}></div> : <></>}
                    <div className={createNovel5Css.scene_select_list + " scene_select_list scene-select-list"} id={("s" + ((index * 5) + 1) + "c")} ref={el => (sceneDetails.current[index][1] = el)}>
                        <V1CteateNovelRightAsideSceneDetail synopsis={novelDetail?.synopsis} scene_recommend={sceneTitleList?.choice1} characters={characters} number={1} index={index} isMountOnClickBool={isMountOnClickBoolList[index][1]} isMountOnClickBoolList={isMountOnClickBoolList} setIsMountOnClickBoolList={setIsMountOnClickBoolList} selectBool={selectBool} setSelectBool={setSelectBool} dataList={dataList} setDataList={setDataList}  setSceneTitleListList={setSceneTitleListList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setIsMountOnClickCheckBoolList={setIsMountOnClickCheckBoolList} setInputTitle={setInputTitle} lastScene={lastScene} previousText={previousText} setLengthBool={setLengthBool}/>
                    </div>
                    <div className={createNovel5Css.scene_select_list + " scene_select_list scene-select-list"} id={("s" + ((index * 5) + 2) + "c")} ref={el => (sceneDetails.current[index][2] = el)}>
                        <V1CteateNovelRightAsideSceneDetail synopsis={novelDetail?.synopsis} scene_recommend={sceneTitleList?.choice2} characters={characters} number={2} index={index} isMountOnClickBool={isMountOnClickBoolList[index][2]} isMountOnClickBoolList={isMountOnClickBoolList} setIsMountOnClickBoolList={setIsMountOnClickBoolList} selectBool={selectBool} setSelectBool={setSelectBool} dataList={dataList} setDataList={setDataList}  setSceneTitleListList={setSceneTitleListList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setIsMountOnClickCheckBoolList={setIsMountOnClickCheckBoolList} setInputTitle={setInputTitle} lastScene={lastScene} previousText={previousText} setLengthBool={setLengthBool}/>
                    </div>
                    <div className={createNovel5Css.scene_select_list + " scene_select_list scene-select-list"} id={("s" + ((index * 5) + 3) + "c")} ref={el => (sceneDetails.current[index][3] = el)}>
                        <V1CteateNovelRightAsideSceneDetail synopsis={novelDetail?.synopsis} scene_recommend={sceneTitleList?.choice3} characters={characters} number={3} index={index} isMountOnClickBool={isMountOnClickBoolList[index][3]} isMountOnClickBoolList={isMountOnClickBoolList} setIsMountOnClickBoolList={setIsMountOnClickBoolList} selectBool={selectBool} setSelectBool={setSelectBool} dataList={dataList} setDataList={setDataList}  setSceneTitleListList={setSceneTitleListList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setIsMountOnClickCheckBoolList={setIsMountOnClickCheckBoolList} setInputTitle={setInputTitle} lastScene={lastScene} previousText={previousText} setLengthBool={setLengthBool}/>
                    </div>
                    <div className={createNovel5Css.scene_select_list + " scene_select_list scene-select-list"} id={("s" + ((index * 5) + 4) + "c")} ref={el => (sceneDetails.current[index][4] = el)}>
                        <V1CteateNovelRightAsideSceneDetail synopsis={novelDetail?.synopsis} scene_recommend={sceneTitleList?.choice4} characters={characters} number={4} index={index} isMountOnClickBool={isMountOnClickBoolList[index][4]} isMountOnClickBoolList={isMountOnClickBoolList} setIsMountOnClickBoolList={setIsMountOnClickBoolList} selectBool={selectBool} setSelectBool={setSelectBool} dataList={dataList} setDataList={setDataList}  setSceneTitleListList={setSceneTitleListList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setIsMountOnClickCheckBoolList={setIsMountOnClickCheckBoolList} setInputTitle={setInputTitle} lastScene={lastScene} previousText={previousText} setLengthBool={setLengthBool}/>
                    </div>
                    <div className={createNovel5Css.scene_select_list + " scene_select_list scene-select-list"} id={("s" + ((index * 5) + 5) + "c")} ref={el => (sceneDetails.current[index][5] = el)}>
                        <V1CteateNovelRightAsideSceneDetail synopsis={novelDetail?.synopsis} scene_recommend={sceneTitleList?.choice5} characters={characters} number={5} index={index} isMountOnClickBool={isMountOnClickBoolList[index][5]} isMountOnClickBoolList={isMountOnClickBoolList} setIsMountOnClickBoolList={setIsMountOnClickBoolList} selectBool={selectBool} setSelectBool={setSelectBool} dataList={dataList} setDataList={setDataList}  setSceneTitleListList={setSceneTitleListList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setIsMountOnClickCheckBoolList={setIsMountOnClickCheckBoolList} setInputTitle={setInputTitle} lastScene={lastScene} previousText={previousText} setLengthBool={setLengthBool}/>
                    </div>
                     
                </>)})}
            </div>
        </>
    );
};
export default V1CreateNovelRightAsideSceneCreate;

/** div 태그 #input-area css */
const InputAreaDiv = styled.div`
    display: none;
    align-items: center;
    margin: 10px 0px;
    justify-content: space-between;
`;