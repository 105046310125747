import { useEffect, useRef, useState } from "react";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { useNavigate, useParams } from 'react-router-dom'



const selector = "#payment-widget";



const ChargeShopCheckOut = ({userInfo, clientData}) => {

    let { priceData } = useParams();
    const navigate = useNavigate();

    const { data: paymentWidget } = usePaymentWidget(clientData?.clientKey, clientData?.customerKey);
    // const paymentWidget = usePaymentWidget(clientKey, ANONYMOUS); // 비회원 결제
    const paymentMethodsWidgetRef = useRef(null);
    const [price, ] = useState(parseInt(priceData));

    useEffect(() => {
        if (paymentWidget == null) {
            return;
        }
        // ------  결제위젯 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
        const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        selector,
        { value: price },
        { variantKey: "DEFAULT" }
        );

        // ------  이용약관 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자
        paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

        paymentMethodsWidgetRef.current = paymentMethodsWidget;
    }, [paymentWidget]);

    useEffect(() => {
        const paymentMethodsWidget = paymentMethodsWidgetRef.current;

        if (paymentMethodsWidget == null) {
            return;
        }

        // ------ 금액 업데이트 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
        paymentMethodsWidget.updateAmount(parseInt(price));
    }, [price]);

    return (
        <>
            <WrapperDiv className="wrapper">
                <BoxSectionDiv className="box_section">
                    <div id="payment-widget" />
                    <div id="agreement" />
                    <ResultWrapperDiv className="result wrapper">
                        <ButtonButton
                            className="button"
                            style={{ marginTop: "30px" }}
                            onClick={async () => {
                            try {
                                // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                                // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
                                await paymentWidget?.requestPayment({
                                    orderId: clientData?.orderId,
                                    orderName: (`내스타일 일반결제 ${price}원`),
                                    customerName: userInfo?.name,
                                    customerEmail: userInfo?.email,
                                    successUrl: `${window.location.origin}/charge-shop/success`,
                                    failUrl: `${window.location.origin}/charge-shop/fail`,
                                });
                            } catch (e) {
                                // 에러 처리하기
                                console.error(e);
                                if (e.message === "카드 결제 정보를 선택해주세요." || e.message === "필수 약관에 동의해주세요.") {
                                    alert(e.message);
                                } else {
                                    navigate(`/charge-shop/fail?message=${e.message}`)
                                }
                            }
                            }}
                        >
                            결제하기
                        </ButtonButton>
                    </ResultWrapperDiv>
                </BoxSectionDiv>
            </WrapperDiv>
        </>
    )
};
export default ChargeShopCheckOut;

function usePaymentWidget(clientKey, customerKey) {
    return useQuery({
      queryKey: ["payment-widget", clientKey, customerKey],
      queryFn: () => {
        // ------  결제위젯 초기화 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
        return loadPaymentWidget(clientKey, customerKey);
      },
    });
}

/** div 태그 .wrapper css */
const WrapperDiv = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;
/** div 태그 .result wrapper css */
const ResultWrapperDiv = styled.div`
    max-width: 800px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-wrap: balance;
`;

/** div 태그 .box_section css */
const BoxSectionDiv = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 40px 30px 50px 30px; 
    margin-top:30px; 
    margin-bottom:50px; 
    color: #333D4B
`;

/** button 태그 .button css */
const ButtonButton = styled.div`
    color: #f9fafb;
    background-color: #3182f6;
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    /* vertical-align: middle; */
    cursor: pointer;
    border: 0 solid transparent;
    user-select: none;
    transition: background 0.2s ease, color 0.1s ease;
    text-decoration: none;
    border-radius: 7px;
    padding: 11px 16px;
    &:hover {
        color: #fff;
        background-color: #1b64da;
    }
`;