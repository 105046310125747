import { Link } from "react-router-dom";
import $ from 'jquery';
import styled from "@emotion/styled";
import NovelCreate1Section from "../../components/common/NovelCreate1Section";
import SynopTab from "../../components/common/SynopTab";
import novel1Css from '../../css/novelCreate1.module.css';
import { useEffect, useRef, useState } from "react";


const NovelCreate1 = () => {

    const [category, setCategory] = useState(null);
    const [page, setPage] = useState(null);
    
    const localData = JSON.parse(localStorage.getItem("pageDataSynop"));

    useEffect(() => {
        console.log(localData);
        if(localData !== null && localData !== undefined) {
            setCategory(localData?.category);
            setPage(localData?.page);
            $(".synop_select_tab_nav a").removeClass("active");
            switch (localData?.category) {
                case 0 : {
                    $("#synop0").addClass("active");
                    break;
                }
                case 1 : {
                    $("#synop1").addClass("active");
                    break;
                }
                case 2 : {
                    $("#synop2").addClass("active");
                    break;
                }
                case 3 : {
                    $("#synop3").addClass("active");
                    break;
                }
                case 4 : {
                    $("#synop4").addClass("active");
                    break;
                }
                case 5 : {
                    $("#synop5").addClass("active");
                    break;
                }
            }
        } else {
            setCategory(0);
            setPage(1);
        }
    }, [])

    // 카테고리 클릭 
    $(document).ready(function () {
        $(".synop_select_tab_nav a").click(function () {
            var targetId = $(this).attr("aria-valuetext");
            $(".synop_select_tab_nav a").removeClass("active");
            $(this).addClass("active");
            const categorydata = parseInt(targetId);
            setCategory(categorydata);
            setPage(1);
            localStorage.removeItem("pageData");
            localStorage.removeItem("pageDataSynop");
            localStorage.removeItem("categoryNotice");
            localStorage.removeItem("categoryPay");
            localStorage.removeItem("myPicturePageData");
            localStorage.removeItem("rowCnt");
            if(localData !== null && localData !== undefined) {
                const copy = {...localData, "category" : categorydata, "page" : 1}
                localStorage.setItem("pageDataSynop", JSON.stringify(copy));
            } else {
                const data = {"category" : categorydata, "page" : 1}
                localStorage.setItem("pageDataSynop", JSON.stringify(data))
            }
        });
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    useEffect(() => {
        containerRef.current.scrollTop = 0;
    }, [page]);

    const containerRef = useRef();

    return(
        <>
            <ContainerDiv id="container" ref={containerRef}>
                <SynopTab />
                <SynopSelectSection className={novel1Css.synop_select + " synop_select synop-select"}>
                    <SynopSelectTitleH1 className={novel1Css.synop_select_title + " synop_select_title synop-select-title"}>시놉시스를 선택해주세요</SynopSelectTitleH1>
                    <SynopSelectTabDiv className={novel1Css.synop_select_tab + " synop_select_tab synop-select-tab"}>
                        <SynopSelectTabNavUl className={novel1Css.synop_select_tab_nav + " synop_select_tab_nav synop-select-tab-nav"}>
                            <SynopSelectTabNavLi><SynopSelectTabNavLiALink id="synop0" aria-valuetext="0" className="active">전체 카테고리</SynopSelectTabNavLiALink></SynopSelectTabNavLi>
                            <SynopSelectTabNavLi><SynopSelectTabNavLiALink id="synop1" aria-valuetext="1">로맨스</SynopSelectTabNavLiALink></SynopSelectTabNavLi>
                            <SynopSelectTabNavLi><SynopSelectTabNavLiALink id="synop2" aria-valuetext="2">판타지</SynopSelectTabNavLiALink></SynopSelectTabNavLi>
                            <SynopSelectTabNavLi><SynopSelectTabNavLiALink id="synop3" aria-valuetext="3">스릴러</SynopSelectTabNavLiALink></SynopSelectTabNavLi>
                            <SynopSelectTabNavLi><SynopSelectTabNavLiALink id="synop4" aria-valuetext="4">무협</SynopSelectTabNavLiALink></SynopSelectTabNavLi>
                            <SynopSelectTabNavLi><SynopSelectTabNavLiALink id="synop5" aria-valuetext="5">기타</SynopSelectTabNavLiALink></SynopSelectTabNavLi>
                        </SynopSelectTabNavUl>
                        <SynopSelectTabContentDiv className={novel1Css.synop_select_tab_content + " synop_select_tab_content synop-select-tab-content"}>
                            <DefaultDiv id="synop01">
                                <NovelCreate1Section  category={category} createType={"novel"} pageNum={page} setPageNum={setPage}/>
                            </DefaultDiv>
                        </SynopSelectTabContentDiv>
                    </SynopSelectTabDiv>
                </SynopSelectSection>
            </ContainerDiv>
        </>
    );
};

export default NovelCreate1;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    overflow: scroll;
    padding: 0 140px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** section 태그 .synop-select css */
const SynopSelectSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 76px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 60px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: normal;
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
    }
`;

/** div 태그 .synop-select-tab css */
const SynopSelectTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

/** ul 태그 .synop-select-tab-nav css */
const SynopSelectTabNavUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
    @media (min-width: 100px) and (max-width: 768px) {
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 12px;
    }
`;

/** li 태그  .synop-select-tab-nav li css */
const SynopSelectTabNavLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
    &.active {
        background: #ff5f37;
        color: #fff;
        border: 1px solid #ff5f37;
    }
`;

/** a 태그 .synop-select-tab-nav li a css */
const SynopSelectTabNavLiALink = styled(Link)`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #ff5f37;
        color: #fff;
        border: 1px solid #ff5f37;
    };
    &.active {
        background: #ff5f37;
        color: #fff;
        border: 1px solid #ff5f37;
    }
    @media (max-width: 1400px) {
        padding: 8px 16px;
        font-size: 14px;
    }
`;

/** div 태그 .synop-select-tab-content css */
const SynopSelectTabContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    border-top: none;
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: block;
`;
