import { useRef } from 'react';
import modalCss from '../../css/AiEditorSummaryModal.module.css';
import styled from '@emotion/styled';
import $ from 'jquery';


const AiEditorSummaryListModalBook = ({book, addEpisodeList, setAddEpisodeList}) => {

    const episodeListRef = useRef();

    // 클릭시 화살표 모양 변경
    const toggleList = () => {
        var list = $(("#bookList" + book.id));

        var arrow = $(("#arrow" + book.id));
        if (list.css("display") === "none") {
            list.css("display", "block");
            arrow.css("transform", "rotate(180deg)");
        } else {
            list.css("display", "none");
            arrow.css("transform", "rotate(0deg)");
        }
    }

    // 체크 박스 클릭시 추가 및 다른 소설 회차 클릭시 전체 삭제 후 추가
    const handleCheck = (e, bookId, episode, book) => {
        const checked = e.target.checked;6
        if (checked) {
            const data = {"episodeId" : episode.id, "bookId" : bookId, "episode" : episode, "chapter" : episode.chapter, "book" : book}
            let copy = [...addEpisodeList];
            for (let i = 0; i < copy.length; i++) {
                if (copy[i].bookId !== data.bookId) {
                    const checkedFalse = $(".relistcheck");
                    for(let i = 0; i< checkedFalse.length; i++) {
                        checkedFalse[i].checked = false;
                    }
                    for (let j = 0; j < copy.length; j++) {
                        const idName = "#relistcheck" + copy[j].bookId + "_" + copy[j].episodeId;
                        $(idName)[0].checked = false;
                    }
                    copy = [];
                }
            }
            e.target.checked = true;
            copy.push(data);
            copy.sort((a, b) => a.chapter - b.chapter);
            setAddEpisodeList(copy);
        } else {
            const data = {"episodeId" : episode.id, "bookId" : bookId, "episode" : episode, "chapter" : episode.chapter, "book" : book}
            const copy = [...addEpisodeList];
            for (let i = 0; i < copy.length; i++) {
                if (copy[i].episodeId === data.episodeId) {
                    copy.splice(i, 1);
                }
            }
            setAddEpisodeList(copy);
            if (copy.length === 0) {
                $("#selectall" + bookId)[0].checked = false;
            }
        }
    }

    // 전체버튼 (리스트 추가 다른 소설 버튼 클릭시 리스트 초기화 후 소설 전체 회차 추가)
    const toggleAllChecks = (e, book) => {
        const checked = e.target.checked;
        const episodes = book.episodes
        const copyData = addEpisodeList;
        if (checked) {
            const copy = [];
            if(copyData.length > 0) {
                if(copyData[0].bookId !== book.id) {
                    const checkedFalse = $(".relistcheck");
                    for(let i = 0; i< checkedFalse.length; i++) {
                        checkedFalse[i].checked = false;
                    }
                    setAddEpisodeList([]);
                }
            }
            for (let i = 0; i < episodes.length; i++) {
                const data = {"episodeId" : episodes[i].id, "bookId" : book.id, "episode" : episodes[i], "chapter" : episodes[i].chapter, "book" : book}
                copy.push(data);
                const idName = "#relistcheck" + episodes[i].novelId + "_" + episodes[i].id;
                $(idName)[0].checked = true;
            }
            copy.sort((a, b) => a.chapter - b.chapter);
            setAddEpisodeList(copy);
            e.target.checked = true;
        } else {
            for (let i = 0; i < episodes.length; i++) {
                const idName = "#relistcheck" + episodes[i].novelId + "_" + episodes[i].id;
                $(idName)[0].checked = false;
            }
            const copy = [];
            setAddEpisodeList(copy);
        }
    }

    return (
        <>
            <RelistDiv className={modalCss.relist + " relist"}>
                <RecateDiv className={modalCss.recate + " recate"}>▶</RecateDiv>
                <div className={modalCss.listInfo + " listInfo"}>
                    <p className={modalCss.title + " title"} style={{wordBreak: "break-all", cursor: "pointer", textAlign: "left"}} onClick={toggleList}>{book?.title}</p>
                    <HiddenListUl className={modalCss.hidden_list + " hidden_list hidden-list"} id={"bookList" + book?.id} ref={(el) => {episodeListRef.current = el}}>
                        {book?.episodes?.length > 0 ?<HiddenListLiLi>
                            <RelistItemLabel className={modalCss.relist_item + " relist_item relist-item"}>
                                <input
                                    className={modalCss.relistcheck + " " + modalCss.all_check + " relistcheck all-check"}
                                    type="checkbox"
                                    id={"selectall" + book?.id}
                                    style={{cursor : "pointer"}}
                                    onChange={(e) => {toggleAllChecks(e, book)}}/>
                                <span style={{cursor : "pointer"}}>전체 회차 추가</span>
                            </RelistItemLabel>
                        </HiddenListLiLi> : <></>}
                        {true && book?.episodes.map((episode) => {return(<>
                        <HiddenListLiLi>
                            <RelistItemLabel className={modalCss.relist_item + " relist_item relist-item"}>
                                <RelistcheckInput
                                    className={modalCss.relistcheck + " relistcheck relistcheck" + episode?.id}
                                    type="checkbox"
                                    value={episode?.id}
                                    id={"relistcheck" + episode?.novelId + "_" + episode?.id}
                                    onChange={(e) => {handleCheck(e, episode?.novelId, episode, book)}}/>
                                <span style={{cursor : "pointer"}}>{episode?.chapter}화</span>
                            </RelistItemLabel>
                        </HiddenListLiLi></>)})}
                    </HiddenListUl>
                </div>
                <DownArrow1Div className={modalCss.down + " down"} style={{marginLeft : "5px"}} id="downArrow1">
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    id={'arrow' + book?.id}
                  >
                    <mask
                      id="mask0_1_7899"
                      style={{maskType: "alpha"}}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="10"
                    >
                      <rect width="16" height="10" fill="#D9D9D9" />
                    </mask>
                    <g
                      mask="url(#mask0_1_7899)"
                      transform="rotate(0 8 5)"
                      id="arrowIcon1"
                    >
                      <path
                        d="M1.25 2.1167L2.34508 1L8 6.76659L13.637 1L14.75 2.11671L8 9L1.25 2.1167Z"
                        fill="#71717D"
                      />
                    </g>
                  </svg>
                </DownArrow1Div>
            </RelistDiv>
        </>
    );
};
export default AiEditorSummaryListModalBook;

/** div 태그 .relist css */
const RelistDiv = styled.div`
    display: flex;
    color: #1a1a1a;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    margin-bottom: 32px;
    &:first-of-type {
        border-top: 1px solid #2c2c34;
    }
    &:last-of-type {
        margin-bottom: 42px;
    }
`;

/** div 태그 .recate css */
const RecateDiv = styled.div`
    margin-right: 4px;
`;

/** ul 태그 .hidden-list css */
const HiddenListUl = styled.ul`
    display: none;
    margin-top: 6px;
`;

/** li 태그 .hidden-list li css */
const HiddenListLiLi = styled.li`
    padding: 6px 0;
    color: #8F8F99;
    &:hover{
        color: #fff;
    }
`;

/** label 태그 .relist-item css */
const RelistItemLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

/** input 태그 .relistcheck css */
const RelistcheckInput = styled.input`
    margin-right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

/** div 태그 #downArrow1 css */
const DownArrow1Div = styled.div`
    margin-left: auto;
`;