import { Link, useNavigate } from 'react-router-dom';
import logo_d from '../../css/images/logo-d.png';
import styled from '@emotion/styled';
import footerCss from '../../css/main.module.css';
import URL from '../../constants/url';


// 하단부 footer
const Footer = () => {

  const navigate = useNavigate();

  // 버튼 type에 따른 url 이동
  const onClickBtn = (type) => {
    if (type === 1) {
      navigate(URL.TERM_IN_PERSONAL_INFORMATION);
    } else if (type === 2) {
      navigate(URL.TERM_IN_TERMS_SERVICE);
    } else if (type === 3) {
      navigate(URL.TERM_IN_YOUTH_PROTECTION_POLICY);
    } else if (type === 4) {
      navigate(URL.TERM_IN_WRITER_REGISTRATION);
    } else if (type === 5) {
      navigate(URL.TERM_IN_WRITER_USE);
    }
  }

  // 로고 클릭
  const onClickLogo = () => {
    navigate(URL.MAIN);
  }

  return (
    <>
      <ContainerDDiv>
        <ContainerDiv className={footerCss.container + " container"}>
          <FooterSections>
            <LogoDiv onClick={onClickLogo} >
              <FooterImg src={logo_d} alt="logo-d" />
            </LogoDiv>
            <FooterText1Div>
              나도 작가다, 아이작 aizac
              <br />
              (주)내스타일 제공
            </FooterText1Div>
            <FooterText2Div>
              주식회사 내스타일 │ 대표자: 이용균 │ 사업자번호: 382-81-01860 │ 통신판매업 신고번호: 제 2022-서울서초-1973호
            </FooterText2Div>
            <FooterText2Div>
              이메일: contact@nestyle.ai │ 전화: 02-2155-2040 │ 주소: 서울 서초구 매헌로 16, 1201호
            </FooterText2Div>
            <FooterText3Div className="d-flex">
              <div onClick={() => {onClickBtn(2)}}><FooterText3Link >서비스 이용약관</FooterText3Link></div> 
              <div onClick={() => {onClickBtn(1)}}><FooterText3Link>개인정보 처리방침</FooterText3Link></div>
              <div onClick={() => {onClickBtn(3)}}><FooterText3Link >청소년 보호정책</FooterText3Link></div> 
              <div onClick={() => {onClickBtn(4)}}><FooterText3Link >작가 가입 약관</FooterText3Link></div>
              <div onClick={() => {onClickBtn(5)}}><FooterText3Link >작가 이용 가이드</FooterText3Link></div>
            </FooterText3Div>
          </FooterSections>
        </ContainerDiv>
      </ContainerDDiv>
    </>
  );
};

export default Footer;

/**div 태그 로고 css */
const LogoDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 568px) {
    padding: 0 18px;
    overflow-x: hidden;
  }
`;

/** a 태그 디폴트 css */
const FooterText3Link = styled(Link)`
  color: #050409;
  text-decoration: none;
  margin-right: 12px;
  font-size: 16px;;
  @media (min-width: 100px) and (max-width: 310px) {
    font-size: 12px;
  }
`;

/** div 태그 .container-l css  */
const ContainerDDiv = styled.div`
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 568px) {
    max-width: 540px;
  }
`;

/** section 태그 .footer css */
const FooterSections = styled.section`
  padding: 44px 0px;
  max-width: 1320px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    max-width: 1296px;
  }
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 568px) {
    max-width: 540px;
  }
`;

/** img 태그 .footer-img css */
const FooterImg = styled.img`
  height: 20px;
  width: auto;
  margin-bottom: 20px;
  cursor: pointer;
`;

/** div 태그 .footer-text-1 css */
const FooterText1Div = styled.div`
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #050409;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
`;

/** div 태그 .footer-text-1 css */
const FooterText2Div = styled.div`
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #050409;
  letter-spacing: -0.3px;
  opacity: 0.5;
`;

/** div 태그 .footer-text-1 css */
const FooterText3Div = styled.div`
  margin-top: 20px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  font-size: 16px;
  @media (min-width: 100px) and (max-width: 359px) {
    display: block;
  }
`;

