import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import myPageCss from '../../css/mypage_account.module.css'
import novel1Css from '../../css/novelCreate1.module.css'
import ailLogo from '../../css/images/ai1-logo.png'
import styled from "@emotion/styled";
import URL from "../../constants/url";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";
import LeftAsideNavMenu from "./LeftAsideNavMenu";


// 마이페이지 용 좌측 사이드
const MyPageLeftAside = ({changeBool}) => {

    const barsIcon = useRef();
    const xmarkIcon = useRef();
    const navMenu = useRef();
    const nnavMenu = useRef();

    // 햄버거 bars 버튼
    const barsIconCilck = () => {
        barsIcon.current.style.display = "none";
        nnavMenu.current.style.display = "none";
        xmarkIcon.current.style.display = "block";
        navMenu.current.style.display = "block";
    }

    // X 마크 버튼
    const xmarkIconClick = () => {
        xmarkIcon.current.style.display = "none";
        nnavMenu.current.style.display = "block";
        barsIcon.current.style.display = "block";
        navMenu.current.style.display = "none";
    }
    
    const apiServer = useRecoilValue(apiServerState);

    const [isMounted, setIsMounted] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [, setPublishedNovels] = useState(null);
    const [, setMyPictures] = useState(null);
    const [, setDraftNovels] = useState(null);
    const token = getCookie("token");
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    
    // changeBool 변경시 업데이트
    useEffect(() => {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "authorization": token,
          }
        };
      
        const update = () => {
          const url = apiServer + "/api/asiac_novel/get_user_overall_info";
          const urlUser = apiServer + "/api/asiac_login/get_my_info";
      
          const overAllInfo = async () => {
            await axios
              .get(url, config)
              .then((response) => {
                if (response?.data?.success ) {
                  setPublishedNovels(response?.data?.data?.publishedNovels);
                  setMyPictures(response?.data?.data?.myPictures);
                  setDraftNovels(response?.data?.data?.draftNovels);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                        alert("다시 로그인 해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                }
              })
              .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
              });
          };

          const userInfo = async () => {
            await axios
              .get(urlUser, config)
              .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
              })
              .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
              });
          };
      
          if(token !== undefined) {
              userInfo();
              overAllInfo();
          }
      
        };
        update();
    }, [changeBool]);

    
    // 로고 클릭
    const onClickLogo = () => {
        navigate("/");
    }

    // 계정 정보 클릭
    const onClickUserInfo = () => {
        navigate(URL.MY_PAGE_MY_INFO);
    }

    // 판매/결제 내역 클릭
    const onClickPaymentInfo = () => {
        const data = {"category" : "synop01", "page" : 1};
        localStorage.setItem("categoryPay", JSON.stringify(data));
        navigate(URL.MY_PAGE_PAYMENT_DETAIL);
    }

    // 코인 클릭
    const onClickCoin = () => {
        navigate(URL.LIST_CHARGE_SHOP);
    }


    return (
        <>
            <LeftAsideAside className={myPageCss.leftAside + " leftAside"}>
                <ContentDiv className={myPageCss.content + " content"}>
                    <LeftAsideTopDiv className={myPageCss.top + " top"}>
                        <NavNav className={novel1Css.nav + " nav"}>
                            <DefaultI
                                className={" fa-solid fa-bars"}
                                id="bars-icon"
                                onClick={barsIconCilck}
                                ref={barsIcon}
                                style={{cursor: "pointer"}}
                            ></DefaultI>
                            <XmarkIconI
                                className={" fa-solid fa-xmark"}
                                id="xmark-icon"
                                onClick={xmarkIconClick}
                                ref={xmarkIcon}
                                style={{cursor: "pointer"}}
                            ></XmarkIconI>
                        </NavNav>
                        <div className={myPageCss.logo + " logo"}onClick={onClickLogo} >
                            <DefaultImg src={ailLogo} alt="ai_logo"/>
                        </div>
                    </LeftAsideTopDiv>
                    <NavMenuDiv className={novel1Css.nav_menu + " nav_menu nav-menu"} id="nav-menu" ref={navMenu}>
                        <LeftAsideNavMenu/>
                    </NavMenuDiv>
                    <DefaultDiv id="nnav-menu" ref={nnavMenu}>
                        {isMounted === true ? <ProfileDiv className={myPageCss.profile + " profile"}>
                            <TopProfileDiv className={myPageCss.topProfile + " topProfile"}>
                                <p className={myPageCss.level + " level"}>Lv.{userInfo?.level}</p>
                                <p className={myPageCss.name + " name"}>{userInfo?.nickname}</p>
                            </TopProfileDiv>
                            <BottomProfileDiv className={myPageCss.bottomProfile + " bottomProfile"}>
                                <CoinBlockDiv className={myPageCss.coin + " coin"} onClick={onClickCoin}>
                                    <p>보유코인 :</p>
                                    <RightP className={myPageCss.right + " right"}>{parseInt(userInfo?.coins) === null ? 0 : parseInt(userInfo?.coins)}c</RightP>
                                </CoinBlockDiv>
                                <CoinBlockDiv className={myPageCss.coin + " coin"} onClick={onClickCoin}>
                                    <p>보유포인트 :</p>
                                    <RightP className={myPageCss.right + " right"}>{parseInt(userInfo?.freeCoin) === null ? 0 : parseInt(userInfo?.freeCoin)}p</RightP>
                                </CoinBlockDiv>
                            </BottomProfileDiv>
                        </ProfileDiv> : <ProfileDiv className={myPageCss.profile + " profile"}>
                            <TopProfileDiv className={myPageCss.topProfile + " topProfile"}>
                                <p className={myPageCss.level + " level"}>로딩 중...</p>
                                <p className={myPageCss.name + " name"}></p>
                            </TopProfileDiv>
                            <BottomProfileDiv className={myPageCss.bottomProfile + " bottomProfile"}>
                                <CoinBlockDiv className={myPageCss.coin + " coin"}>
                                    <p></p>
                                    <RightP className={myPageCss.right + " right"}></RightP>
                                </CoinBlockDiv>
                                <CoinBlockDiv className={myPageCss.block + " block"}>
                                    <p></p>
                                    <RightP className={myPageCss.right + " right"}></RightP>
                                </CoinBlockDiv>
                            </BottomProfileDiv>
                        </ProfileDiv>}
                        <div className={myPageCss.my + " " + myPageCss.Drawing + " my Drawing"}>
                            <MyTitDiv className={myPageCss.tit + " tit"}>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.20833 19.677H6.68147L16.3832 9.97524L14.9101 8.5021L5.20833 18.2039V19.677ZM21.875 21.7604H3.125V17.341L17.1198 3.34612C17.5267 2.93932 18.1861 2.93932 18.5929 3.34612L21.5393 6.29239C21.946 6.69919 21.946 7.35873 21.5393 7.76553L9.62775 19.677H21.875V21.7604ZM16.3832 7.02896L17.8564 8.5021L19.3295 7.02896L17.8564 5.55583L16.3832 7.02896Z" fill="#8F8F99"/>
                                </svg>           
                                <MyTitP>마이페이지</MyTitP>    
                            </MyTitDiv>
                            <ListGroupDiv className={myPageCss.list_group + " list_group"}>
                                <ListDiv className={myPageCss.list + " list"} onClick={onClickUserInfo}>
                                    <CateDiv className={myPageCss.cate + " cate"}>▶</CateDiv>
                                    <div className={myPageCss.title + " title"}><DefaultALink >계정 정보</DefaultALink></div>
                                </ListDiv>

                                <ListDiv className={myPageCss.list + " list"} onClick={onClickPaymentInfo}>
                                    <CateDiv className={myPageCss.cate + " cate"}>▶</CateDiv>
                                    <div className={myPageCss.title + " title"}><DefaultALink >판매/결제 내역</DefaultALink></div>
                                </ListDiv>
                            </ListGroupDiv>
                        </div>
                    </DefaultDiv>
                </ContentDiv>
            </LeftAsideAside>
        </>
    );
}
export default MyPageLeftAside;

/** aside 태그 .leftAside css */
const LeftAsideAside = styled.aside`
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
    min-width: 320px;
    @media (min-width: 1001px) and (max-width: 1024px) {
        min-width: 270px;
        height: 150%;
    }
    @media (min-width: 768px) and (max-width: 1000px) {
        min-width: 270px;
        height: 120%;
    }
    @media(max-width:767px) {
        height: 150%;
        display: none;
    }
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding: 54px 40px 0 40px;
    padding-top: 52px;
    @media (min-width: 768px) and (max-width: 820px) {
        padding: 10px 10px 0 40px;
    }
`;

/** div 태그 .top css */
const LeftAsideTopDiv = styled.div`
    display: flex;
    gap: 12px;
`;

/** div 태그 .profile css */
const ProfileDiv = styled.div`
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
`;

/** div 태그 .topProfile css */
const TopProfileDiv = styled.div`
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
`;

/** div 태그 .bottomProfile css */
const BottomProfileDiv = styled.div`
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
`;

/** div 태그 .coin, .block css */
const CoinBlockDiv = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

/** p 태그 .right css */
const RightP = styled.p`
    text-align: right;
    text-decoration: underline;
`;

/** div 태그 .my .tit css */
const MyTitDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

/** p 태그 .my .tit p css */
const MyTitP = styled.p`
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
`;

/** div 태그 .list_group css */
const ListGroupDiv = styled.div`
    font-family: "Min Sans-Regular";
`;

/** div 태그 .list css */
const ListDiv = styled.div`
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;
    cursor: pointer;
    &:first-of-type{
        border-top: 1px solid #2C2C34;
    }
    &:last-of-type{
        margin-bottom: 42px;
    }
    &:hover {
        color: #FF5F37;
    }
`;

/** div 태그 .cate css */
const CateDiv = styled.div`
    padding-right: 4px;
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/**img 태그 디폴트 css */
const DefaultImg = styled.img`
    &:hover {
        cursor: pointer;
    }
`;

/** nav 태그 .nav css */
const NavNav = styled.nav`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    cursor: pointer;
`;

/** i 태그 디폴트 css */
const DefaultI = styled.i`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: block;
    color: #ffffff;
    font-size: 22px;
    width: 19.5px;
`;

const XmarkIconI = styled.i`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: #ffffff;
    font-size: 22px;
    width: 19.5px;
    display: none;
`;

/** div 태그 .nav-menu css */
const NavMenuDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 46px;
    display: none;
`;

/** a 태그 .list css 
const ListLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    &:first-of-type {border-top: 1px solid #2c2c34;}
    &:last-of-type {margin-bottom: 42px;}
    cursor: pointer;
    &:hover {color: #ff5f37;}
`;*/

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;