import SynopTab from "../../components/common/SynopTab";
import { Link, useNavigate, useParams } from "react-router-dom";
import NovelCreate2SectionList from "../../components/common/NovelCreate2SectionList";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import $ from 'jquery';
import novel2Css from'../../css/novelCreate2.module.css';
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import axios from "axios";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";
import defaultImg from '../../css/images/illustDefault.png'
import Swal from "sweetalert2";
import NovelCreate2DefaultSectionList from "components/common/NovelCreate2DefaultSectionList";
import createNovel5Css from '../../css/createNovel5.module.css'


const NovelCreate3_1 = ({isUpdateBool, setIsUpdateBool}) => {

    const [novel, setNovel] = useState(null);
    const [characters, setCharacters] = useState([]);
    const [category, setCategory] = useState(null);
    const [periodData, setPeriodData] = useState(null);
    const [pubdayData, setPubdayData] = useState(null);
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();
    const [, setImg] = useState(null);
    const [imgDefault, setImgDefault] = useState(null);
    const [, setImgMy] = useState(null);
    const [imgId, setImgId] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [title, setTitle] = useState(null);
    const [synopStory, setSynopStory] = useState(null);

    const params = useParams();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 소설정보 업데이트
    useEffect(() => {
        const urlNovel = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + params.novelId;

        const novelInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlNovel, config)
            .then((response) => {
                if (response?.data?.success) {
                    setNovel(response?.data?.data?.novel);
                    setCategory(response?.data?.data?.novel?.categoryId);
                    setPeriodData(response?.data?.data?.novel?.period);
                    setPubdayData(response?.data?.data?.novel?.pubday);
                    setTitle(response?.data?.data?.novel?.title);
                    setSynopStory(response?.data?.data?.novel?.synopsis);
                    setCharacters(response?.data?.data?.characters);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "invalid novel") {
                        alert("없는 소설 또는 비공개 소설 입니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        novelInfo();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    // novel, userInfo 변경시 소설 userId, 유저 id 비교(비정상접근 방지)
    useEffect(() => {
        if(novel !== null && userInfo !== null) {
            if (novel.userId !== userInfo.id) {
                alert("비정상적인 접근입니다.");
                navigate(URL.MAIN);
            }
        }
    }, [novel, userInfo])


    const token = getCookie("token");
    const [imgSelectType, setImgSelectType] = useState(0);

    // isUpdateBool 변경시 유저정보 업데이트(api 코인차감시 업데이트 용도)
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])

    // 그림 올리는 방법 선택 버튼
    const onClickSelectImgTypeBtn = (e) => {
        
        var targetId = e.target.ariaValueText;

        $(".synop-select-tab-content > div").hide();
        if (targetId === "#synop01") {
            setImgSelectType(1);
        } else if (targetId === "#synop02") {
            setImgSelectType(2);
        } else if (targetId === "#synop03") {
            setImgSelectType(3);
        } else {
            setImgSelectType(0);
        }
        $(".synop-select-tab-nav-1 a").removeClass("active");
        e.target.classList.add("active");
        $(targetId).css("display", "block");
    }

    // 직접올리기 파일 올리기 함수
    const handleFileInput = () => {
        const fileInput = $("#file-input")[0];
        const imagePreview = $("#image-preview");
        const synop01 = $("#synop01");
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];

            if (
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/webp" 
            ) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    imagePreview.attr("src",e.target.result);
                    imagePreview.css("display", "block");
                };
                reader.readAsDataURL(file);
                synop01.css("display", "block");
                $("#image-preview-container").css("display", "block");
            } else {
                alert("png, jpeg, jpg, webp 형태의 이미지 파일만 이용하실 수 있습니다.");
                fileInput.value = "";
            }
        } else {
            imagePreview.attr("src", "");
            imagePreview.css("display", "none");
        }

    }

    

    // 저장하고 다음으로 버튼(저장)
    const onSaveClick = () => {
        let form = new FormData();
        form.append("novelId", novel.id);
        form.append("synopsisId", novel.synopsisId);
        form.append("synopsis", synopStory);
        form.append("title", title);
        form.append("pubState", "public");
        form.append("categoryId", category);
        form.append("pubday", pubdayData);
        form.append("period", periodData);
        if (imgSelectType === 0) {
            if (imgDefault === null) {
                alert("기본이미지를 선택해주세요.");
                return;
            }
            form.append("image", imgDefault);
            form.append("imgUploadKind", "self");
        } else if (imgSelectType === 1) {
            const file = $('#file-input')[0].files[0];
            if(file === undefined || file === null) {
                alert("파일을 업로드 해주세요.");
                return;
            }
            form.append("image", file);
            form.append("imgUploadKind", "self");
            
        } else if (imgSelectType === 2) {
            if(imgId === null || imgId === undefined || imgId === 0) {
                alert("내 그림을 선택해주세요.");
                return;
            }
            form.append("imgUploadKind", "aiimg");
            form.append("aiImgid", imgId);
        } else if (imgSelectType === 3) {
            if(createImg === null) {
                alert("표지를 생성해주세요.");
                return;
            }
            if (isCreateImgLoing === false) {
                alert("표지가 생성중입니다. 생성 후 저장해주세요.");
                return;
            }
            form.append("imgUploadKind", "direct_path");
            form.append("imgPath", createImg?.path);
        } else {
            alert("표지를 생성해주세요.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",

                "authorization": token,
            }
        };

        axios
            .post(apiServer + "/api/asiac_novel/update_novel", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    setIsUpdateBool(!isUpdateBool);
                    navigate(URL.CREATE_NOVEL4 + novel.id);
                    console.log("저장 성공");
                } else {
                    const msg = response?.data?.custMsg
                    if(msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === "invalid info") {
                        alert("제목, 시놉시스를 확인해주세요.");
                    } else if (msg === "invalid user") {
                        alert("비정상적인 접근입니다.");
                    } else if (msg === "router failed") {
                        alert("서버 에러");
                    } else {
                        alert("저장 실패");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }
    const [isCreateImgLoing, setIsCreateImgLoing] = useState(true);

    // 표지 생성 카테고리 선택
    const onClickCreateImg = () => {
        if (clickCreateImgBtnCheck === false) {
            Swal.fire({
                title : "요금 안내",
                text: "표지 생성은 1회 당 1000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 1000 || userInfo?.coinAuth === "unlimited") {
                        setIsCreateImgLoing(false);
                        let form;
                        if (title === "") {
                            alert("제목을 입력해주세요.");
                            return;
                        } else if (synopStory === "") {
                            alert("시놉시스 줄거리를 입력해주세요.");
                            return;
                        } else {
                            form = {"imgType" : "표지", "categoryId" : category, "characters" : characters, "plot" : synopStory };
                        }
                    
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                            }
                        };
                        setClickCreateImgBtnCheck(true);
                        setClickCreateImgBtnCheck2(true);
                        axios
                        .post(apiServer + "/api/aizac_ai_img/generate_episode_ai_img", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                alert("표지가 생성되었습니다.");
                                const copy = [...createImgList];
                                copy.push(response?.data?.data);
                                setCreateImgList(copy);
                                setCreateImg(copy[0]);
                                setImg(response?.data?.data?.imgUrl);
                                setIsUpdateBool(!isUpdateBool);
                                setIsCreateImgLoing(true);
                                setClickCreateImgBtnCheck2(false);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "invalid category") {
                                    alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsCreateImgLoing(true);
                                setClickCreateImgBtnCheck2(false);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setIsCreateImgLoing(true);
                            setClickCreateImgBtnCheck2(false);
                        });
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                                setIsCreateImgLoing(true);
                            } 
                        });
                    }
                } 
            });
        }
    }

    // 표지 재생성 버튼
    const onClickReCreateImg = () => {
        if (clickCreateImgBtnCheck2 === false) {
            Swal.fire({
                title : "요금 안내",
                text: "표지 생성은 1회 당 1000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 1000 || userInfo?.coinAuth === "unlimited") {
                        setIsCreateImgLoing(false);
                        let form;
                        if (title === "") {
                            alert("제목을 입력해주세요.");
                            return;
                        } else if (synopStory === "") {
                            alert("시놉시스 줄거리를 입력해주세요.");
                            return;
                        } else {
                            form = {"imgType" : "표지", "categoryId" : category, "characters" : characters, "plot" : synopStory };
                        }
                    
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                            }
                        };
                        setClickCreateImgBtnCheck2(true);
                        axios
                        .post(apiServer + "/api/aizac_ai_img/generate_episode_ai_img", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                alert("표지가 생성되었습니다.");
                                const copy = [...createImgList];
                                copy.push(response?.data?.data);
                                const num = copy.length - 1;
                                setCreateImgNum(num);
                                setCreateImg(copy[num]);
                                setCreateImgList(copy);
                                setImg(response?.data?.data?.imgUrl);
                                setClickCreateImgBtnCheck2(false);
                                setIsUpdateBool(!isUpdateBool);
                                setIsCreateImgLoing(true);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "invalid category") {
                                    alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setClickCreateImgBtnCheck2(false);
                                setIsCreateImgLoing(true);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setClickCreateImgBtnCheck2(false);
                            setIsCreateImgLoing(true);
                        });
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                    }
                } 
            });
        }
    }

    const [clickCreateImgBtnCheck, setClickCreateImgBtnCheck] = useState(false);
    const [clickCreateImgBtnCheck2, setClickCreateImgBtnCheck2] = useState(false);
    const [createImg, setCreateImg] = useState(null);

    /**======================================= */

    const [createImgList, setCreateImgList] = useState([]);
    const [createImgNum, setCreateImgNum] = useState(0);

    // 표지 리스트 index 감소 버튼
    const onClickDecrease = () => {
        if (createImgNum > 0 && createImgNum < createImgList.length) {
            let copyNum = createImgNum - 1;
            setCreateImgNum(copyNum);
            setCreateImg(createImgList[copyNum]);
        }
    }

    // 표지 리스트 index 증가 버튼
    const onClickIncrease = () => {
        if (createImgList.length > createImgNum + 1) {
            let copyNum = createImgNum + 1;
            setCreateImgNum(copyNum);
            setCreateImg(createImgList[copyNum]);
        }
    }

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    /**======================================= */

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return(
        <>
            <ContainerDiv id="container">
                <SynopTab action={4} />
                <SynopSelectSection className={novel2Css.synop_select + " synop_select synop-select"}>
                    <SynopSelectTitleH1 className={novel2Css.synop_select_title + " synop_select_title synop-select-title"}>작품 표지를 입력해주세요</SynopSelectTitleH1>
                    <DefaultHr />
                    <NovelIlluDiv className={novel2Css.novel_illu + " novel_illu novel-illu"}>
                        <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>표지를 선택해주세요</SynopSelectSubDiv>
                        <SynopSelectTabDiv className={novel2Css.synop_select_tab + " synop_select_tab synop-select-tab"}>
                            <StnopSelectTabUl className={novel2Css.synop_select_tab_nav_1 + " synop_select_tab_nav_1 synop-select-tab-nav-1"}>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop00" onClick={(e) => {onClickSelectImgTypeBtn(e)}} className=" active">기본 이미지</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop01" onClick={(e) => {onClickSelectImgTypeBtn(e)}}>직접 올리기</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop02" onClick={(e) => {onClickSelectImgTypeBtn(e)}} >내 그림 선택</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop03" onClick={(e) => {onClickCreateImg(); onClickSelectImgTypeBtn(e)}} >표지생성</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                            </StnopSelectTabUl>
                            <SynopSelectTabContentDiv className={novel2Css.synop_select_tab_content + " synop_select_tab_content synop-select-tab-content"}>
                                <DefaultDiv id="synop00" style={{display : "block"}}>
                                    <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                                        <DefaultDiv id="image-preview-container-defalut" style={{display: "block"}}>
                                            <NovelCreate2DefaultSectionList setImgDefault={setImgDefault}/>
                                            <ImagePreviewImg src={defaultImg} alt="Image Preview defalut" id="image-preview-defalut" style={{display: "none"}}/>
                                        </DefaultDiv>
                                    </DefaultSection>
                                </DefaultDiv>
                                <DefaultDiv id="synop01">
                                    <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                                        <DefaultFrom
                                            encType="multipart/form-data"
                                            action="#"
                                            method="post"
                                            id="upload-form"
                                        >
                                            <DefaultInput
                                                type="file"
                                                name="file"
                                                id="file-input"
                                                accept=".png, .jpg, .jpeg, .webp"
                                                onChange={handleFileInput}
                                            />
                                        </DefaultFrom>
                                        <DefaultDiv id="image-preview-container" style={{display: "block"}}>
                                            <ImagePreviewImg src="" alt="Image Preview" id="image-preview" />
                                        </DefaultDiv>
                                        <ImguploadIntDiv className={novel2Css.imgupload_int + " imgupload_int imgupload-int"}>
                                        ※업로드 파일: 최대 50mb, jpg, jpeg, png, webp 파일 지원<br />※가로9:세로16
                                        비율의 이미지가 표지로 가장 적합합니다
                                        </ImguploadIntDiv>
                                    </DefaultSection>
                                </DefaultDiv>
                                <DefaultDiv id="synop02">
                                    <DefaultSection className={novel2Css.illu_me + " illu_me illu-me"}>
                                        <NovelCreate2SectionList setImgMy={setImgMy} setImgId={setImgId}/>
                                    </DefaultSection>
                                </DefaultDiv>
                                <DefaultDiv id="synop03">
                                    <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                                        <DefaultDiv id="image-preview-container-defalut" style={{display: "block"}}>
                                            {createImg === null ? <></> : 
                                            <ImagePreviewImg src={createImg?.imgUrl} alt="Image Preview defalut" id="image-preview-create" style={{display: "block"}} onError={noImage}/>}
                                            {createImgList.length !== 0 ? <div style={{marginLeft : "10px", marginTop : "5px", marginBottom : "5px", fontFamily : "Min Sans-Medium", fontSize: "20px"}}>생성결과 {createImgNum + 1}/{createImgList?.length}</div> :
                                            <div style={{marginLeft : "10px", marginTop : "5px", marginBottom : "5px", fontFamily : "Min Sans-Medium", fontSize: "20px"}}>생성결과 {createImgNum}/{createImgList?.length}</div>}
                                            {isCreateImgLoing === false ? <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>표지가 생성중입니다. 잠시만 기다려주세요.</SynopSelectSubDiv> : <></>}
                                            <div className={createNovel5Css.scene_main_select_1 + " scene_main_select_1 scene-main-select-1"} style={{marginLeft : "20px"}}>
                                                <button onClick={onClickDecrease}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18px"
                                                        height="18px"
                                                        viewBox="0 0 320 512"
                                                        style={{fill : "#bcbcbf"}}>
                                                        <path
                                                            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                                        />
                                                    </svg>
                                                </button>
                                                <button onClick={onClickIncrease}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18px"
                                                        height="18px"
                                                        viewBox="0 0 320 512"
                                                        style={{fill : "#bcbcbf"}}>
                                                        <path
                                                            d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                                        />
                                                    </svg>
                                                </button>
                                                <div className={createNovel5Css.rere_scene + " rere_scene rere-scene"} style={{marginLeft : "30px"}} onClick={onClickReCreateImg}>재생성</div>
                                            </div>
                                        </DefaultDiv>
                                    </DefaultSection>
                                </DefaultDiv>
                            </SynopSelectTabContentDiv>
                        </SynopSelectTabDiv>
                    </NovelIlluDiv>
                    <DefaultHr />
                    <EditNextButton className={novel2Css.edit_next + " edit_next edit-next"} type="button" onClick={onSaveClick}>저장하고 다음으로</EditNextButton>
                </SynopSelectSection>
            </ContainerDiv>
        </>
    );
};
export default NovelCreate3_1;


/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    overflow: scroll;
    padding: 0 140px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** section 태그 .synop-select css */
const SynopSelectSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 76px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 60px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
        color: #050409;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: none;
    height: 1px;
    background-color: #ededed;
    width: 100%;
    margin: 32px 0px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 18px;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
`;


/** div 태그 .synop-select-sub css */
const SynopSelectSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 18px;
    color: #050409;
    letter-spacing: -0.3px;
    @media (max-width: 1400px) {
        font-size: 16px;
    }
    @media (min-width: 968px) and (max-width: 1280px) {
        font-size: 16px;
    }
`;

/** div 태그 .novel-illu css */
const NovelIlluDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;

/** div 태그 .synop-select-tab css */
const SynopSelectTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

/** ul 태그 .synop-select-tab > ul css */
const StnopSelectTabUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
`;

/** li 태그 .synop-select-tab-nav > li css */
const SynopSelectTabNavLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
`;

/** div 태그 .synop-select-tab-content css */
const SynopSelectTabContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    border-top: none;
`;

/** section 태그 디폴트 css */
const DefaultSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** form 태그 디폴트 css */
const DefaultFrom = styled.form`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** input 태그 디폴트 css */
const DefaultInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** img 태그 #image-preview css */
const ImagePreviewImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    max-width: 100%;
    max-height: 200px;
    margin-top: 20px;
    display: none;
    @media (min-width: 100px) and (max-width: 768px) {
        width: 100px; /* 이미지 크기 조정 */
        height: 100px;
    }
`;

/** div 태그 .imgupload-int css */
const ImguploadIntDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #828284;
    letter-spacing: -0.3px;
    margin-top: 10px;
    line-height: 24px;
`;

/** button 태그 .edit-next css */
const EditNextButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    background-color: #22222a;
    color: #fff;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 50px 0px 120px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {background-color: #303039;}
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 40px;
        margin-bottom: 80px;
    }
`;


/** a 태그 .synop-select-tab-nav li a css */
const SynopSelectTabNavLiLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    &.active {
        border-bottom: 1px solid #fff;
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        color: #828284;
        border: 1px solid #bdbdbd;
    }
`;
