import styled from '@emotion/styled';
import myPageCss from '../../css/mypage_account.module.css';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { useNavigate } from 'react-router';
import URL from '../../constants/url';
import { apiServerState } from '../../constants/recoilData';


const LevelBar = () => {
    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 유저 정보와 레벨 바 셋팅(현재 기획단계 디자인만 있음)
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
        const update = () => {

            const urlEpi = apiServer + "/api/asiac_login/get_my_info";
            
            const urlEpiCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUser(response?.data?.data?.user);
                            setIsMounted(true);
                            updateProgressBar(38);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };
            if(token !== undefined) {
                urlEpiCall();
            }
            
        };
        update();

        // 경험치 바 표시
        const updateProgressBar = (percentage) => {
        
            $("#myProgressBar").css("width", percentage + '%');
            const text = `다음 레벨업까지 ${percentage}/100`
            $('#progressInfo').text(text);
            
        };
        
    }, [])


    

    return (
        <>
            {isMounted !== false ? <section className={myPageCss.levelBar + " levelBar"}>
                <LevelDiv className={myPageCss.level + " level"}>
                    <PersentP className={myPageCss.percent + " present"}>Lv. {user?.level}</PersentP>
                    <NextP className={myPageCss.next + " next"}>Lv. {user?.level + 1}</NextP>
                </LevelDiv>
                <ProgressContainerDiv className={myPageCss.progress_container + " progress_container"}>
                    <ProgressBarDiv className={myPageCss.progress_bar + " progress_bar"} id="myProgressBar" ></ProgressBarDiv>
                </ProgressContainerDiv>
                <ProgressInfoDiv className={myPageCss.progress_bar + " progress_info"} id="progressInfo" ></ProgressInfoDiv>
                <PopupDiv id="popup" className={myPageCss.popup + " popup"}>
                    <div className="popup-content">
                        <span className={myPageCss.close + " close"} id="closePopup">&times;</span>
                    </div>
                </PopupDiv>
            </section> : <></>}
        </>
    );
}
export default LevelBar;

/** div 태그 .level css */
const LevelDiv = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 36px;
    font-family: "min sans-medium";
    margin-top: 72px;
    margin-bottom: 12px;
    letter-spacing: -0.3px;
    @media(max-width:568px) {
        margin-top: 42px;
        margin-bottom: 6px;
        font-size: 20px;
    }
`;

/** p 태그 .present css */
const PersentP = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    color: #050409;
`;

/** p 태그 .next css */
const NextP = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    color: #BDBDBD;
`;

/** div 태그 .progress_container css */
const ProgressContainerDiv = styled.div`
    width: 100%;
    background-color: #EDEDED;
    margin-bottom: 12px;
`;

/** div 태그 .progress_bar css */
const ProgressBarDiv = styled.div`
    width: 0%;
    height: 6px;
    background-color: #FF5F37;
`;

/** div 태그 .progress_info css */
const ProgressInfoDiv = styled.div`
    font-size: 14px;
    color: #ff5f37;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 32px;
    font-family: "Min Sans-medium";
    letter-spacing: -0.3px;
    display: inline-block;
    white-space: nowrap;
    @media(max-width:568px) {
        margin-bottom: 42px;
    }
`;

/** div 태그 .popup css */
const PopupDiv = styled.div`
    display: none;
`;