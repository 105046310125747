import { Link, useNavigate } from "react-router-dom";
import editPictureCss from '../../css/drawing_modify.module.css'
import styled from "@emotion/styled";
import SynopTabPicture from "../../components/common/SynopTabPicture";
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
//import $ from 'jquery';
import { useEffect, useState } from "react";
import { getCookie, removeCookie, removeCookieOption } from "hooks/Cookie";
import $ from 'jquery';
import axios from "axios";
import URL from "constants/url";
import createNovel5Css from '../../css/createNovel5.module.css'
import Swal from "sweetalert2";
import defaultImg from '../../css/images/illustDefault.png'


const PictureCreate4 = ({isUpdateBool, setIsUpdateBool, isCreateImg, setIsCreateImg}) => {

    const [isMounted, setIsMounted] = useState(false);
    const [category, setCategory] = useState(0);
    const [title, setTitle] = useState("");
    const [synopsis, setSynopsis] = useState("");
    const [price, setPrice] = useState(0);
    const [priceCheck, setPriceCheck] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [imgData, setImgData] = useState(null);
    const [createImg, setCreateImg] = useState(null);
    const [createImgList, setCreateImgList] = useState([]);

    const [isCreateImgLoding, setIsCreateImgLoding] = useState(true);
    
    const apiServer = useRecoilValue(apiServerState);

    const token = getCookie("token");
    const navigate = useNavigate();

    const picture3Data = getCookie("picture3Data");

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    const config = {
        headers: {
          "Content-Type": "application/json",
          "authorization": token,
        }
    };
    // 유저 정보 api 호출 함수
    const userInfoSet = async () => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";
        const token = getCookie("token");
        const config = {
            headers: {
            "Content-Type": "application/json",
            "authorization": token,
            }
        };

        await axios
        .get(urlUser, config)
        .then((response) => {
            if (response?.data?.success) {
                setUserInfo(response?.data?.data?.user);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                    alert("다시 로그인해주세요.");
                    logout();
                    navigate(URL.LOGIN);
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    navigate(URL.MAIN);
                } else if (msg === "invalid user") {
                    alert("다시 로그인해주세요.");
                    logout();
                    navigate(URL.LOGIN);
                } else {
                    alert(msg);
                    navigate(URL.MAIN);
                }
                console.log("로딩 실패");
                console.log(msg);
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    };

    // 최초 랜더링시 그림 생성 api 호출
    useEffect(() => {
        const copy = localStorage.getItem('createImg');
        if(copy !== undefined && copy !== null) {
            alert("비정상적인 접근입니다.");
            navigate(URL.CREATE_MAIN);
        } else {
            if(isCreateImg === false) {
                Swal.fire({
                    title : "요금 안내",
                    text: "표지 생성은 1회 당 1000코인이 소모됩니다",
                    showCancelButton: true,
                    confirmButtonColor: "#FF5F37",
                    cancelButtonColor: "#bdbdbd",
                    cancelButtonText: "취소",
                    confirmButtonText: "사용",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        let user;
                        const userInfoSet = async () => {
                            const urlUser = apiServer + "/api/asiac_login/get_my_info";
                            const token = getCookie("token");
                            const config = {
                                headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                                }
                            };
                    
                            await axios
                            .get(urlUser, config)
                            .then((response) => {
                                if (response?.data?.success) {
                                    user = response?.data?.data?.user;
                                    if (((isNaN(parseInt(user?.coins)) ? 0 : parseInt(user?.coins)) +
                                    (isNaN(parseInt(user?.freeCoin)) ? 0 : parseInt(user?.freeCoin))) >= 1000 || user?.coinAuth === "unlimited") {
                                        setIsCreateImgLoding(false);
                                        let form;
                                        if(picture3Data !== null) {
                                            form = {"imgType" : "그림", "categoryId" : picture3Data?.synop?.categoryId, "characters" : picture3Data?.characterInfo, "synopsis" : picture3Data?.synop?.synopsis, "plot" : picture3Data?.backgroundStory };
                                        } else {
                                            const copy = localStorage.getItem('picture3Data')
                                            form = {"imgType" : "그림", "categoryId" : copy?.synop?.categoryId, "characters" : copy?.characterInfo, "synopsis" : copy?.synop?.synopsis, "plot" : copy?.backgroundStory };
                                        }
                                        const config = {
                                            headers: {
                                                "Content-Type": "application/json",
                                                "authorization": token,
                                            }
                                        };
                                
                                        axios
                                        .post(apiServer + "/api/aizac_ai_img/generate_ai_img", form, config)
                                        .then((response) => {
                                            if (response?.data?.success) {
                                                localStorage.setItem("createImg", JSON.stringify(response?.data?.data));
                                                setCreateImg(response?.data?.data);
                                                const copy = [...createImgList];
                                                copy.push(response?.data?.data);
                                                setCreateImgList(copy);
                                                setIsUpdateBool(!isUpdateBool);
                                                setIsCreateImgLoding(true);
                                                setPriceCheck(true);
                                                setIsCreateImg(true);
                                            } else {
                                                setIsUpdateBool(!isUpdateBool);
                                                setIsCreateImgLoding(true);
                                                setIsCreateImg(true);
                                                const msg = response?.data?.custMsg;
                                                if (msg.includes("timeout")) {
                                                    alert("요청 시간이 초과되었습니다.");
                                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                                    alert("다시 로그인 후 이용해주세요.");
                                                    logout();
                                                } else if (msg === "need more coin") {
                                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                                } else if (msg === "invalid category") {
                                                    alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                                } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                                } else if (msg === "router err") {
                                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                                } else if (msg === "router failed") {
                                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                                } else if (msg === "Network Error") {
                                                    alert("네트워크 오류");
                                                } else {
                                                    alert(msg);
                                                }
                                                navigate(-1);
                                                console.log(msg);
                                            }
                                        })
                                        .catch((e) => {
                                            alert(e.message);
                                            console.log(e);
                                            setIsUpdateBool(!isUpdateBool);
                                            setIsCreateImgLoding(true);
                                            setIsCreateImg(true);
                                        });
                                    } else {
                                        Swal.fire({
                                            title : "작품 구매",
                                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                            showCancelButton: true,
                                            confirmButtonColor: "#FF5F37",
                                            cancelButtonColor: "#bdbdbd",
                                            cancelButtonText: "취소",
                                            confirmButtonText: "코인 충전",
                                            reverseButtons: true,
                                        }).then((result) => {
                                            if (result.dismiss === Swal.DismissReason.confirm) {
                                                navigate(URL.LIST_CHARGE_SHOP);
                                            } 
                                        });
                                    }
                                    setUserInfo(response?.data?.data?.user);
                                } else {
                                    const msg = response?.data?.custMsg;
                                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                        alert("다시 로그인해주세요.");
                                        logout();
                                        navigate(URL.LOGIN);
                                    } else if (msg === "router err") {
                                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                        navigate(URL.MAIN);
                                    } else if (msg === "invalid user") {
                                        alert("다시 로그인해주세요.");
                                        logout();
                                        navigate(URL.LOGIN);
                                    } else {
                                        alert(msg);
                                        navigate(URL.MAIN);
                                    }
                                    console.log("로딩 실패");
                                    console.log(msg);
                                }
                            })
                            .catch(e => {  // API 호출이 실패한 경우
                                alert(e.message);
                                console.log(e);
                                navigate(URL.ERROR, {state: {msg: e.message}});
                            });
                        };
                        userInfoSet();
                    } else {
                        navigate(-1);
                    }
                });
            } else {
                if (createImg === null) {
                    alert("비정상 접근입니다.");
                    navigate(URL.CREATE_MAIN);
                }
            }
        }
    }, [])

    // token 유무 검사(토큰 있을시 유저 정보 셋팅)
    useEffect(() => {
        if(token !== undefined) {
            userInfoSet();
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    // createImg, isUpdateBool 변경, token 있을시 유저정보 셋팅
    useEffect(() => {
        if(token !== undefined) {
            userInfoSet();
        }
    }, [createImg, isUpdateBool])

    const urlImgDetail = apiServer + "/api/aizac_ai_img/ai_a_img?id=" + createImg?.id;
    
    // 그림 정보 api 호출 함수
    const imgDetail = () => {
        axios
            .get(urlImgDetail, config)
            .then((response) => {
                if (response?.data?.success) {
                    setImgData(response?.data?.data?.img);
                    setTitle(response?.data?.data?.img?.title);
                    setSynopsis(response?.data?.data?.img?.synopsis);
                    const payPrice = parseInt(response?.data?.data?.img?.price);
                    setPrice(payPrice);
                    setCategory(response?.data?.data?.img?.categoryId);

                    switch (response?.data?.data?.img?.categoryId) {
                        case 1 : {
                            $('.actionBtn').removeClass("active");
                            $("#category1").addClass("active");
                            $("#category1").addClass("select");
                            $("#category1").select();
                            break;
                        }
                        case 2 : {
                            $('.actionBtn').removeClass("active");
                            $("#category2").addClass("active");
                            $("#category2").addClass("select");
                            $("#category2").select();
                            break;
                        }
                        case 3 : {
                            $('.actionBtn').removeClass("active");
                            $("#category3").addClass("active");
                            $("#category3").addClass("select");
                            $("#category3").select();
                            break;
                        }
                        case 4 : {
                            $('.actionBtn').removeClass("active");
                            $("#category4").addClass("active");
                            $("#category4").addClass("select");
                            $("#category4").select();
                            break;
                        }
                        case 5 : {
                            $('.actionBtn').removeClass("active");
                            $("#category5").addClass("active");
                            $("#category5").addClass("select");
                            $("#category5").select();
                            break;
                        }
                    }
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "invalid img") {
                        alert("비정상적인 경로입니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }

    // createImg 변경(그림이 생성 후) 그림에 대한 상세정보 셋팅
    useEffect (() => {
        if (createImg !== null) {
            setCreateImg2(createImg)
            imgDetail();
        }
    }, [createImg]);

    // 유저정보, 그림정보 셋팅 후 유저 id, 그림 userId 비교(비정상 접근 방지)
    useEffect(() => {
        if (userInfo !== null && imgData !== null) {
            if(userInfo?.id !== imgData?.userId) {
                alert("비정상적인 접근입니다.");
                navigate(URL.MAIN);
            }
        } 
    }, [userInfo, imgData])

    // 카테고리 선택 및 유지
    useEffect (() => {
        switch (category) {
            case 1 : {
                $('.actionBtn').removeClass("active");
                $("#category1").addClass("active");
                $("#category1").addClass("select");
                $("#category1").select();
                break;
            }
            case 2 : {
                $('.actionBtn').removeClass("active");
                $("#category2").addClass("active");
                $("#category2").addClass("select");
                $("#category2").select();
                break;
            }
            case 3 : {
                $('.actionBtn').removeClass("active");
                $("#category3").addClass("active");
                $("#category3").addClass("select");
                $("#category3").select();
                break;
            }
            case 4 : {
                $('.actionBtn').removeClass("active");
                $("#category4").addClass("active");
                $("#category4").addClass("select");
                $("#category4").select();
                break;
            }
            case 5 : {
                $('.actionBtn').removeClass("active");
                $("#category5").addClass("active");
                $("#category5").addClass("select");
                $("#category5").select();
                break;
            }
        } 
    }, [category]);

    // 제목 변경 입력 함수
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    // 그림 정보 변경 입력 함수
    const onChangeSynopsis = (e) => {
        setSynopsis(e.target.value);
    };

    // 그림 가격 변경 입력 함수
    const onChangePrice = (e) => {
        setPrice(e.target.value);
    };

    // 카테고리 선택
    $(document).ready(function () {
        $(".actionBtn").click(function () {
            $('.actionBtn').removeClass("active");
            $(this).addClass("active");
            const val = $(this).val();
            setCategory(val);
        });
    });
    
    // 이대로 저장 버튼
    const onSaveClick = () => {
        
        if (category === 0) {
            alert("카테고리를 입력하세요.");
            return;
        } else if (category === null || category === undefined) {
            alert("카테고리를 입력하세요.");
            return;
        }
        if (title === '') {
            alert("제목을 입력하세요.");
            return;
        } else if (title.length < 2 || title.length > 30) {
            alert("제목은 2자 ~ 30자 이내로 입력해주세요.");
            return;
        } else if (title === null || title === undefined) {
            alert("제목을 입력하세요.");
            return;
        }
        const titleCheck = (String(title)).trim();
        if (titleCheck === "") {
            alert("제목을 입력하세요.");
            return;
        }
        const synopsisCheck = (String(synopsis)).trim();
        if (synopsisCheck === "") {
            alert("그림 소개문을 입력하세요.");
            return;
        }

        if (synopsis === '') {
            alert("그림 소개문을 입력하세요.");
            return;
        } else if (synopsis.length < 2 || synopsis.length > 150) {
            alert("소개문은 2자 ~ 150자 이내로 입력해주세요.");
            return;
        } else  if (synopsis === null || synopsis === undefined) {
            alert("그림 소개문을 입력하세요.");
            return;
        }
        
        if (price === 0) {
            alert("그림가격을 입력하세요.");
            return;
        } else if (price === "") {
            alert("그림가격을 입력하세요.");
            return;
        } else if (price > 1000000000) {
            alert("그림가격 입력이 초과되었습니다.");
            return;
        } else if (price < 10) {
            alert("그림최소가격은 10원입니다.");
            return;
        } else if (price === null || price === undefined) {
            alert("그림가격을 입력하세요.");
            return;
        }
        if (priceCheck === false) {
            alert("그림가격은 숫자만 입력하세요.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",

                "authorization": token,
            }
        };
        let form2 = {
            "id": parseInt(createImg?.id)
            ,"title": titleCheck
            ,"synopsis": synopsisCheck
            ,"price": price
            ,"categoryId": category
            ,"path" : createImg2?.path
            ,"bShow" : true        
            //,"synopsisId":1 //  insert 할시에만 필요
            //,"url":"test1" // insert 할시에만  필요
        };
        axios
            .post(apiServer + "/api/aizac_ai_img/update_ai_img", form2, config)
            .then((response) => {
                if (response?.data?.success ) {
                    console.log("저장하였습니다.");
                    removeCookieOption("picture3Data", {path : ""});
                    alert("저장하였습니다.")
                    navigate(URL.CREATE_MY_PICTURE);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === "invalid info") {
                        alert("입력값을 확인해주세요.");
                    } else if (msg === "invalid aiimg") {
                        alert("그림이 없습니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }

    /**================================================================= */

    const [createImg2, setCreateImg2] = useState(null);
    const [createImgNum, setCreateImgNum] = useState(0);
    const [clickCreateImgBtnCheck, setClickCreateImgBtnCheck] = useState(false);

    // 그림 재생성 버튼
    const onClickReCreate = () => {

        if (clickCreateImgBtnCheck === false) {
            Swal.fire({
                title : "요금 안내",
                text: "표지 생성은 1회 당 1000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 1000 || userInfo?.coinAuth === "unlimited") {
                        setClickCreateImgBtnCheck(true);
                        setIsCreateImgLoding(false);
                        let form;
                        const copy = picture3Data?.synop?.synopsis + " " + picture3Data?.backgroundStory;
                        const copy2 =  picture3Data?.characterInfo + " " + picture3Data?.backgroundStory
                        form = {"imgType" : "그림", "categoryId" : picture3Data?.synop?.categoryId, "characters" : copy2, "plot" : copy };
                        
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                            }
                        };

                        axios
                        .post(apiServer + "/api/aizac_ai_img/generate_episode_ai_img", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                const copy = [...createImgList];
                                copy.push(response?.data?.data);
                                const num = copy.length -1;
                                setCreateImgNum(num);
                                setCreateImgList(copy);
                                setCreateImg2(copy[num]);
                                alert("표지가 생성되었습니다.");
                                setClickCreateImgBtnCheck(false);
                                setIsCreateImgLoding(true);
                                setIsUpdateBool(!isUpdateBool);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "invalid category") {
                                    alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else if (msg === "Network Error") {
                                    alert("네트워크 오류");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setClickCreateImgBtnCheck(false);
                                setIsCreateImgLoding(true);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setClickCreateImgBtnCheck(false);
                            setIsCreateImgLoding(true);
                        });
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                    }
                } 
            });
        }

        
    }

    // 그림 리스트 index 감소 버튼
    const onClickDecrease = () => {
        if (createImgNum > 0 && createImgNum < createImgList.length) {
            let copyNum = createImgNum - 1;
            setCreateImgNum(copyNum);
            setCreateImg2(createImgList[copyNum]);
        }
    }
    
    // 그림 리스트 index 증가 버튼
    const onClickIncrease = () => {
        if (createImgList.length > createImgNum + 1) {
            let copyNum = createImgNum + 1;
            setCreateImgNum(copyNum);
            setCreateImg2(createImgList[copyNum]);
        }
    }

    // 가격 정규식 체크 함수(숫자)
    const priceRegExCheck = (e) => {
        const priceRegEx = new RegExp(/^\d+$/)
        const price = e.target.value;
        const bool = priceRegEx.test(price);

        setPriceCheck(bool);
    }

    
    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        // 새로고침 막기 변수
        const preventClose = () => {
            window.location.href = '/';
        }
        window.addEventListener("popstate", preventClose);    

        
        return () => {
            window.removeEventListener("popstate", preventClose);
        };
    },[]);

    const noImage = (e) => {
        e.target.src = defaultImg
    }
    

    /**================================================================= */

    return (
        <>
            <ContainerDiv id="container">
                <SynopTabPicture action={4}/>
                {createImg2 !== null ? <ContainerH2H2>그림 생성이 완료되었습니다</ContainerH2H2> : <ContainerH2H2>그림 생성이 생성중입니다. 잠시만 기다려주세요.<br/>그림이 생성 후 비공개 저장됩니다. 그림정보 수정 후 공개전환 할 수 있습니다.</ContainerH2H2>}
                {createImg2 !== null && isMounted === true ? <ContentDiv className={editPictureCss.content + " content"}>
                    <BookCoverDiv className={editPictureCss.bookCover + " bookCover"} style={{borderBottom : "none"}}>
                        <BookCoverImgImg src={createImg2?.imgUrl} alt="bpook_cover_img" onError={noImage}/>
                        <div style={{marginLeft : "30px", marginBottom : "5px", fontFamily : "Min Sans-Medium", fontSize: "20px"}}>생성결과 {createImgNum + 1}/{createImgList?.length}</div>
                        {isCreateImgLoding === true ? <></> : <><CateSelectPP>그림이 생성중입니다.</CateSelectPP> 
                        <CateSelectPP>잠시만 기다려주세요.</CateSelectPP></>}
                        <div className={createNovel5Css.scene_main_select_1 + " scene_main_select_1 scene-main-select-1"} style={{marginLeft : "30px"}}>
                            <button onClick={onClickDecrease}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 320 512"
                                    style={{fill : "#bcbcbf"}}>
                                    <path
                                        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                    />
                                </svg>
                            </button>
                            <button onClick={onClickIncrease} style={{marginLeft : "30px"}}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 320 512"
                                    style={{fill : "#bcbcbf"}}>
                                    <path
                                        d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                    />
                                </svg>
                            </button>
                            <div className={createNovel5Css.rere_scene + " rere_scene rere-scene"} style={{marginLeft : "50px"}} onClick={onClickReCreate}>재생성</div>
                        </div>
                    </BookCoverDiv>
                    <CateSelectDiv className={editPictureCss.cateSelect + " cateSelect"}>
                        <CateSelectPP>카테고리를 선택해주세요</CateSelectPP>
                        <CateSelectUlUl>
                            <CateSelectUlLiLi id="category1" className=" actionBtn" value={1}>로맨스</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category2" className=" actionBtn" value={2}>판타지</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category3" className=" actionBtn" value={3}>스릴러</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category4" className=" actionBtn" value={4}>무협</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category5" className=" actionBtn" value={5}>기타</CateSelectUlLiLi>
                        </CateSelectUlUl>
                    </CateSelectDiv>

                    <DefaultLabel htmlFor="imageTitle">그림 제목을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※제목은 2자~30자 이내로 입력해주세요</DescriptionTextSpan>
                    <ImageTitleInput type="text"id="imageTitle" placeholder="제목을 입력해주세요" maxLength="30" onChange={onChangeTitle} value={title} />

                    <DefaultLabel htmlFor="imageDescription">그림 소개문을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※소개문은 2자~150자 이내로 입력해주세요</DescriptionTextSpan>
                    <ImageDescriptionTextarea 
                        id="imageDescription" 
                        name="imageDescription" 
                        rows="4" 
                        cols="50" 
                        maxLength="150" 
                        required 
                        placeholder="소개문을 입력해주세요"
                        onChange={onChangeSynopsis}
                        value={synopsis}></ImageDescriptionTextarea>
                    <br/>
                    <br/>
                    <DefaultLabel htmlFor="imagePrice">그림 가격을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※그림 가격은 1,000,000,000 이내로 입력해주세요</DescriptionTextSpan>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}></DescriptionTextSpan>
                    <InputContainerDiv className={editPictureCss.input_container + " input_container"}>
                        <ImagePriceInput type="number" id="imagePrice" placeholder="가격을 입력해주세요" onChange={(e) => {onChangePrice(e); priceRegExCheck(e)}} value={parseInt(price)}/>
                    </InputContainerDiv>
                    <SaveNextBtn type="submit" className={editPictureCss.saveNextBtn + " saveNextBtn"} onClick={onSaveClick}><Link >이대로 저장</Link></SaveNextBtn>
                </ContentDiv> : <></>}
            </ContainerDiv>
        </>
    );
};
export default PictureCreate4;


/** div 태그 #container css */
const ContainerDiv = styled.div`
    overflow-y: auto; 
    padding: 0 140px;
    @media(max-width:1400px) {
        padding: 0 80px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** h2 태그 #container h2 css */
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-top: 60px;
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    font-weight: bold;
    @media(max-width:568px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;


/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 52px;
`;

/** div 태그 .bookCover css */
const BookCoverDiv = styled.div`
    border-bottom: 1px solid #ededed;
    height: 100%;
`;

/** img 태그 .bookCover img css */
const BookCoverImgImg = styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    @media(max-width:568px) {
        margin-bottom: 20px;
        max-width:52%
    }
`;

/** div 태그 .cateSelect css */
const CateSelectDiv = styled.div`
    margin-bottom: 60px;
    margin-top: 52px;
    @media(max-width:568px) {
        margin: 42px 0;
    }
`;

/** p 태그 .cateSelect p css */
const CateSelectPP = styled.p`
    font-size: 18px;
    color: #050409;
    font-family: "Min Sans-Light";
    margin-bottom: 12px;
`;

/** ul 태그 .cateSelect ul css */
const CateSelectUlUl = styled.ul`
    display: flex;
    gap: 10px;
    padding-left: 0;
`;

/** li 태그 .cateSelect ul li css */
const CateSelectUlLiLi = styled.li`
    padding: 8px 20px;
    border: 1px solid #BDBDBD;
    border-radius: 19px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    text-align: center;
    color: #828284;
    cursor: pointer;
    &.selected {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &.active {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &:hover {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    @media(max-width:568px) {
        font-size: 13px;
        padding: 8px 12px;
    }
`;

/** label 태그 디폴트 css */
const DefaultLabel = styled.label`
    color: #050409;
    font-size: 18px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
`;

/** span 태그 .description_text css */
const DescriptionTextSpan = styled.span`
    font-size: 14px;
    color: #828284;
    font-family: "Min Sans-Light";
    padding-left: 4px;
    @media(max-width:568px) {
        padding: 0;
        display: block;
        padding-top: 4px;
    }
`;

/** input 태그 #imageTitle css */
const ImageTitleInput = styled.input`
    border: 1px solid #BDBDBD;
    padding: 22px 24px;
    width: 100%;
    color: #22222a;
    outline: none;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
    &::placeholder{
        font-size: 24px;
        color: #9B9B9D;
        font-family: "Min Sans-Light";
    }
    margin-top: 12px;
    margin-bottom: 60px;
    border: 1px solid #BDBDBD;
    @media(max-width:568px) {
        font-size: 18px;
        padding: 22px 16px;
        margin-bottom: 42px;
    }
`;

/** textarea 태그 #imageDescription css */
const ImageDescriptionTextarea = styled.textarea`
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    &::placeholder {
        color: #9B9B9D;
    }
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
    margin-top: 12px;
    margin-bottom: 40px;
    @media(max-width:568px) {
        margin-bottom: 20px;
    }
`;

/** div 태그 .input_container css */
const InputContainerDiv = styled.div`
    border-bottom: 1px solid #EDEDED;
    position: relative;
    &::after {
        content: "C";
        position: absolute;
        top: 36px;
        right: 24px; 
        color: #ff5f37;
        font-size: 24px;
        font-family: "Min Sans-Light";
    }
    @media(max-width:568px) {
        &::after{
            top: 32px;
        }
    }
`;

/** input 태그 #imagePrice css */
const ImagePriceInput = styled.input`
    border: 1px solid #BDBDBD;
    padding: 22px 24px;
    width: 100%;
    color: #22222a;
    outline: none;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
    &::placeholder{
        font-size: 24px;
        color: #9B9B9D;
        font-family: "Min Sans-Light";
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        display: none;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        display: none;
    }
    margin-bottom: 52px;
    border: 1px solid #BDBDBD;
    margin-top: 12px;
    @media(max-width:568px) {
        margin-bottom: 42px;
        font-size: 18px;
        padding: 22px 16px;
    }
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtn = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    margin-bottom: 120px;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media(max-width:1400px) {
        margin-bottom: 120px;
    }
    @media(max-width:568px) {
        margin-top: 42px;
        margin-bottom: 80px;
    }
`;