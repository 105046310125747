import AizacChargeShop from 'components/pages/AizacChargeShop';
import PassAiEditor from '../components/ai_editor/PassAiEditor';
import PassAiEditorNovel from '../components/ai_editor/PassAiEditorNovel';
import PassModify from '../components/modify/PassModify';
import PassNovel from '../components/novelCreate/PassNovel';
import AizacAiEditor from '../components/pages/AizacAiEditor';
import AizacCreate from '../components/pages/AizacCreate';
import AizacCreateNovel from '../components/pages/AizacCreateNovel';
import AizacError from '../components/pages/AizacError';
import AizacFind from '../components/pages/AizacFind';
import AizacList from '../components/pages/AizacList';
import AizacLogin from '../components/pages/AizacLogin';
import AizacMain from '../components/pages/AizacMain';
import AizacModify from '../components/pages/AizacModify';
import AizacMyPage from '../components/pages/AizacMyPage';
import AizacNovelDetail from '../components/pages/AizacNovelDetail';
import AizacSign from '../components/pages/AizacSign';
import AizacTerm from '../components/pages/AizacTerm';
import AizacTest from '../components/pages/AizacTest';
import AizacWithdrawal from '../components/pages/AizacWithdrawal';
import URL from '../constants/url';
import { useEffect, useRef, useState } from 'react';
import { Routes, Route, /*useNavigate*/ 
useNavigate} from 'react-router-dom';
import { getCookie, removeCookie, removeCookieOption } from 'hooks/Cookie';
import { useRecoilValue } from 'recoil';
import { apiServerState } from 'constants/recoilData';
import axios from "axios";

const RootRoutes = () => {

  const [mounted,] = useState(true); // 컴포넌트 최초 마운트 후 리렌더링 전 로그인 페이지로 이동하는 조건으로 사용


  if (mounted) {
    // 인증 없이 시스템관리 URL로 접근할 때 렌더링 되는 것을 방지하는 조건추가.
    return (
      <Routes>
        <Route path={URL.ERROR} element={<AizacError />} />
        <Route path="*" element={<SecondRoutes />} />
      </Routes>
    );
  }
};

const SecondRoutes = () => {
  // const [loginVO, setLoginVO] = useState({});

  //useRef객체를 사용하여 페이지 마운트 된 후 ui.js를 로딩 하도록 변경 코드 추가(아래)
  const isMounted = useRef(false); // 아래 로그인 이동 부분이 2번 실행되지 않도록 즉, 마운트 될 때만 실행되도록 변수 생성
  const [, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const apiServer = useRecoilValue(apiServerState);
  const token = getCookie("token");

  const logout = () => {
    removeCookie("token");
    removeCookieOption("token", {path : "/"});
    localStorage.removeItem("pageData");
    localStorage.removeItem("pageDataSynop");
    localStorage.removeItem("categoryNotice");
    localStorage.removeItem("categoryPay");
    localStorage.removeItem("myPicturePageData");
    localStorage.removeItem("rowCnt");
    window.localStorage.clear();
    const token = getCookie("token");
    if (token !== undefined) {
        removeCookieOption("token", {path : "/"});
        navigate(URL.MAIN);
    } else {
        navigate(URL.MAIN);
    }
}

  const update = () => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization" : token,
        }
    };
    
    const urlEpi = apiServer + "/api/asiac_login/get_my_info";
    
    const urlUserCall = async () => {
        axios
            .get(urlEpi, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    };
    urlUserCall();
  };

  useEffect(() => {
    const token = getCookie("token");
    if (token !== null && token !== undefined) {
      update();
    }
    if (!isMounted.current) {
      // 컴포넌트 최초 마운트 시 페이지 진입 전(렌더링 전) 실행
      isMounted.current = true; // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
    } else {
      //initPage();
    }
  }, []);


  

  return (
    <>
      {/* 헤더 넣어줄것 */}
      
      <Routes>
        {/* MAIN */}
        <Route path={URL.MAIN} element={<AizacMain />} />

        {/* LOGIN */}
        <Route path={URL.LOGIN} element={<AizacLogin
                  /*onChangeLogin={(user) => setLoginVO(user)}*/
          />}/>

        {/* CREATE */}
        <Route path={URL.CREATE} element={<AizacCreate />} />

        {/* SIGN */}
        <Route path={URL.SIGN} element={<AizacSign />} />

        {/* FIND */}
        <Route path={URL.FIND} element={<AizacFind />} />

        {/* MY_PAGE */}
        <Route path={URL.MY_PAGE} element={<AizacMyPage />} />
        
        {/* Test */}
        <Route path={'/text/:novelId/:sessionId'} element={<AizacTest />} />

        {/* CREATE-NOVEL */}
        <Route path={URL.CREATENOVEL} element={<AizacCreateNovel/>} />

        {/* LIST */}
        <Route path={URL.LIST} element={<AizacList />} />

        {/* Novel */}
        <Route path={URL.NOVEL_IN} element={<AizacNovelDetail />} />

        {/* Novel */}
        <Route path={URL.AI_EDITOR} element={<AizacAiEditor />} />

        {/* TERM */}
        <Route path={URL.TERM_IN} element={<AizacTerm />} />

        {/* ERROR */}
        <Route path={URL.ERROR} element={<AizacError />} />

        {/*WITHDRAWAL */}
        <Route path={URL.WITHDRAWAL} element={<AizacWithdrawal />} />

        {/*MODIFY */}
        <Route path={URL.MODIFY} element={<AizacModify />} />

        {/* CHARGE-SHOP_MAIN */}
        <Route path={URL.CHARGE_SHOP_MAIN} element={<AizacChargeShop />} />

        {/** Pass */}
        <Route path={'/pass/:novelId/:sessionId'} element={<PassNovel />} />
        
        {/** PassAiEditor */}
        <Route path={'/pass/ai-editor/:editorId'} element={<PassAiEditor />} />
        
        {/** PassAiEditorNovel */}
        <Route path={'/pass/ai-editor/novel/:novelId/:sessionId'} element={<PassAiEditorNovel />} />
        
        {/** PassAiEditorNovel */}
        <Route path={'/pass/modify/:novelId'} element={<PassModify />} />


      </Routes>
      
      {/* footer 추가
        <EgovFooter />
        <EgovInfoPopup /> */}
    </>
  );
};

export default RootRoutes;
