

import URL from "../../constants/url";
import { getCookie } from "../../hooks/Cookie";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";


const PassNovel = () => {

    const params = useParams();
    const [novelId,] = useState(params.novelId);
    const [sessionId,] = useState(params.sessionId);
    const navigate = useNavigate();

    // 페이지 이동 경로 함수
    useEffect(() => {

        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {

        
            const update = () => {
            
                const novelDetail = async () => {
                    navigate(URL.CREATENOVEL_INPUT + novelId + "/" + sessionId);
                };
            
                novelDetail();
                
            };
            update();
        }

    }, [])

    return (
        <>
        </>
    )
}

export default PassNovel;