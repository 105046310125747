import noticeTermsCss from '../../css/noticeTerms.module.css'
import { useEffect, useState } from 'react';
import { getCookie } from 'hooks/Cookie';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { apiServerState } from 'constants/recoilData';
import { useNavigate } from "react-router-dom";
import URL from 'constants/url';

const TermWriterRegistration = () => {

    const [policy, setPolicy] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        };
    
        const url = apiServer + "/api/aizac_notice/get_a_policy?ikind=author_subscription_terms";
        
        axios
        .get(url, config)
        .then((response) => {
            if (response?.data?.success) {
                setPolicy(response?.data?.data?.policy);
                setIsUpdate(true);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "invalid data") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(msg);
                }
                navigate(URL.MAIN);
                console.log("로딩 실패");
                console.log(msg);
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });

    }, [])

    return(
        <>
            <div className={noticeTermsCss.container + " container"}>
                <section className={noticeTermsCss.rank_veteran + " rank_veteran rank-veteran"}>
                    <section className={noticeTermsCss.synop_select + " synop_select synop-select"}>
                        {isUpdate === true ? <div className={noticeTermsCss.rank_title + " rank_title rank-title"}>
                            <h1>{policy?.title}</h1>
                            <hr className={noticeTermsCss.notice_hr + " notice_hr notice-hr"} />
                            <div className={noticeTermsCss.service_use_content + " service_use_content service-use-content"} style={{fontSize: "1.3rem"}} dangerouslySetInnerHTML={{
                                __html: policy && policy?.content,
                            }}>
                            </div>
                        </div> : <></> }
                    </section>
                </section>
            </div>
        </>
    );
};
export default TermWriterRegistration;

