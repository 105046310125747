import ModifyTab from "./ModifyTab";
import { Link, useNavigate, useParams } from "react-router-dom";
import NovelCreate2SectionList from "../../components/common/NovelCreate2SectionList";
import styled from "@emotion/styled";
import novel2Css from'../../css/novelCreate2.module.css';
import defaultImg from '../../css/images/illustDefault.png'
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import axios from "axios";
import URL from "../../constants/url";
import $ from 'jquery';
import Swal from "sweetalert2";
import createNovel5Css from '../../css/createNovel5.module.css'


// 소설 정보 수정페이지 메인 컴포넌트
const ModifyNovel = ({isUpdateBool, setIsUpdateBool}) => {
    const categoryDataList = [{cate: "로맨스", id:1}, {cate: "판타지", id:2}, {cate:"스릴러", id:3}, {cate:"무협", id:4}, {cate:"기타", id:5}];

    const CategoryBtnRef = useRef();
    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie('token');
    const navigate = useNavigate();

    const [isMountedCreateTitle, setIsMountedCreateTitle] = useState(true);
    
    const [title, setTitle] = useState("");
    const [novelDetail,setNovelDetail] = useState(null);
    const [synopsis, setSynopsis] = useState("");
    const [synopsisId, setSynopsisId] = useState("");
    const [category, setCategory] = useState(0);
    const [periodData, setPeriodData] = useState("");
    const [pubdayData, setPubdayData] = useState("");
    const [, setImg] = useState(null);
    const [imgNow, setImgNow] = useState(null);
    const [imgId, setImgId] = useState(0);
    const {novelId} = useParams();
    const [imgSelectType, setImgSelectType] = useState(0);
    const [userInfo, setUserInfo] = useState(null);
    const [characters, setCharacters] = useState([]);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 제목 변경 입력 함수
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    // 시놉시스 스토리 변경 입력 함수
    const onChangeSynopsis = (e) => {
        setSynopsis(e.target.value);
    };

    // 최초 랜더링시 소설 정보 셋팅
    useEffect(() => {

        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };

        const update = () => {
            const url = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + novelId;

        
            const setting = async () => {
                await axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setSynopsis(response?.data?.data?.novel?.synopsis);
                        setSynopsisId(response?.data?.data?.novel?.synopsisId)
                        setCategory(response?.data?.data?.novel?.categoryId);
                        setNovelDetail(response?.data?.data?.novel);
                        setTitle(response?.data?.data?.novel?.title);
                        setImg(((response?.data?.data?.novel?.imgUrl)));
                        setImgNow(((response?.data?.data?.novel?.imgUrl)));
                        setPeriodData(response?.data?.data?.novel?.period);
                        setPubdayData(response?.data?.data?.novel?.pubday);
                        setImgId(response?.data?.data?.novel?.coverFileId);
                        setCharacters(response?.data?.data?.characters);
                        setIsUpdateBool(!isUpdateBool);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "invalid novel") {
                            alert("없는 소설 또는 비공개 소설 입니다.");
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        } else {
                            alert(msg);
                        }
                        navigate(URL.MAIN);
                        console.log("로딩 실패");
                        setIsUpdateBool(!isUpdateBool);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            };
        
            setting();
        };
        update();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    // novelId 변경시 업데이트
    useEffect(() => {
        $('.dayBtn1').removeClass("active");
        $('.dayBtn2').removeClass("active");
        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };

        const update = () => {
            const url = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + novelId;

        
            const setting = async () => {
                await axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setSynopsis(response?.data?.data?.novel?.synopsis);
                        setSynopsisId(response?.data?.data?.novel?.synopsisId)
                        setCategory(response?.data?.data?.novel?.categoryId);
                        setNovelDetail(response?.data?.data?.novel);
                        setTitle(response?.data?.data?.novel?.title);
                        setImg(((response?.data?.data?.novel?.imgUrl)));
                        setImgNow(((response?.data?.data?.novel?.imgUrl)));
                        setPeriodData(response?.data?.data?.novel?.period);
                        setPubdayData(response?.data?.data?.novel?.pubday);
                        setImgId(response?.data?.data?.novel?.coverFileId);
                        setIsUpdateBool(!isUpdateBool);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "invalid novel") {
                            alert("없는 소설 또는 비공개 소설 입니다.");
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        } else {
                            alert(msg);
                        }
                        navigate(URL.MAIN);
                        console.log("로딩 실패");
                        setIsUpdateBool(!isUpdateBool);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            };
        
            setting();
        };
        update();
        
    }, [novelId])

    // isUpdateBool 변경시 유저정보 업데이트(api 코인차감 업데이트 용도)
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])
    
    // 제목 생성 버튼
    const onClickCreateTitle = () => {
        Swal.fire({
            title : "요금 안내",
            text: "자동 생성은 1회 당 50코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50 || userInfo?.coinAuth === "unlimited") {
                    setIsMountedCreateTitle(false);
                    setIsUpdateBool(!isUpdateBool);
                    const config = {
                        headers: {
                            "Content-Type": "application/json",

                            "authorization": token,
                        }
                    };

                    let form = {"synopsis": synopsis};
                    axios
                        .post(apiServer + "/api/asiac_nestyle/initiate/title", form, config)
                        .then((response) => {
                            if (response?.data?.success && response?.data?.data?.title) {
                                setIsMountedCreateTitle(true);
                                setTitle(response.data.data.title);
                                setIsUpdateBool(!isUpdateBool);
                                console.log("제목 생성 성공");
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt" ) {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "axios err" || msg === "ai generator err") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsUpdateBool(!isUpdateBool);
                                setIsMountedCreateTitle(true);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setIsMountedCreateTitle(true);
                        });
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
        
    }

    // 주기별 카테고리 버튼
    const onClickDayBtn1 = (e) => {
        $('.dayBtn1').removeClass("active");
        $('.dayBtn2').removeClass("active");

        e.target.classList.add("active");
        const val = e.target.value;
        
        setPeriodData(val);
        setPubdayData("");
    }

    // 요일별 카테고리 버튼
    const onClickDayBtn2 = (e) => {
        const data = (e.target.classList.value);
        let boolCheck = false;
        const val = e.target.value;
        setPeriodData("");
        $('.dayBtn1').removeClass("active");
        if (data.includes("active")) {
            boolCheck = true
        }

        if (boolCheck) {
            e.target.classList.remove("active");

            let copy2 = pubdayData.split(", ");

            let copy = copy2.filter((element) => element !== val);

            if (copy.length >= 1) {
                // 요일을 정렬
                var sortedDays = copy.sort(function(a, b) {
                    // 요일을 정렬하기 위해 영어로 된 요일을 비교
                    var daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "Saturday", "sunday"];
                    return daysOrder.indexOf(a) - daysOrder.indexOf(b);
                });
                let data = "";
                for (let i = 0; i < sortedDays.length; i++) {
                    if((i + 1) < sortedDays.length) {
                        data = data + sortedDays[i] + ", "; 
                    } else {
                        data = data + sortedDays[i]; 
                    }
                }

                setPubdayData(data);
            } else {
                let data = "";

                setPubdayData(data);
            }
        } else {
    
            e.target.classList.add("active");
    
            if (pubdayData === "") {
                let copy = [];
                copy.push(val);
                setPubdayData(val);
            } else {
                const copy = pubdayData.split(", ");
                if (!(copy.includes(val))) {
                    copy.push(val);
                } 
                if (copy.length > 1) {
                    // 요일을 정렬
                    var sortedDays2 = copy.sort(function(a, b) {
                        // 요일을 정렬하기 위해 영어로 된 요일을 비교
                        var daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "Saturday", "sunday"];
                        return daysOrder.indexOf(a) - daysOrder.indexOf(b);
                    });
                    let data = "";
                    for (let i = 0; i < sortedDays2.length; i++) {
                        if((i + 1) < sortedDays2.length) {
                            data = data + sortedDays2[i] + ", "; 
                        } else {
                            data = data + sortedDays2[i]; 
                        }
                    }
                    setPubdayData(data);
                }
            }
        }
    }
    

    // 이미지 타입 카테고리 입력 함수
    const onClickSelectImgTypeBtn = (e) => {
        
        var targetId = e.target.ariaValueText;
        $(".synop-select-tab-content > div").hide();
        if (targetId === "#synop01") {
            setImgSelectType(1);
        } else if (targetId === "#synop02") {
            setImgSelectType(2);
        } else if (targetId === "#synop03") {
            setImgSelectType(3);
        } else {
            setImgSelectType(0);
        }
        $(".synop-select-tab-nav-1 a").removeClass("active");
        e.target.classList.add("active");
        $(targetId).css("display", "block");
    }

    // 카테고리 선택 버튼
    const onClickActionBtn = (e) => {
        $('.actionBtn').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setCategory(parseInt(val));

    }

    // 직접 올리기 파일 저장 함수
    const handleFileInput = () => {
        const fileInput = $("#file-input")[0];
        const imagePreview = $("#image-preview");
        const synop01 = $("#synop01");
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];
            setImg(file);

            if (
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/webp" 
            ) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    imagePreview.attr("src",e.target.result);
                    imagePreview.css("display", "block");
                };
                reader.readAsDataURL(file);
                synop01.css("display", "block");
                $("#image-preview-container").css("display", "block");
            } else {
                alert(`.png, .jpeg, .jpg, .webp 파일만 이용하실수 있습니다.`);
                fileInput.value = "";
            }
        } else {
            imagePreview.attr("src", "");
            imagePreview.css("display", "none");
        }

    }

    const [isMountedCreateSynopStroy, setIsMountedCreateSynopStroy] = useState(true);

    // 시놉시스 스토리 생성 버튼
    const onClickCreateSynopStory = (category) => {
        Swal.fire({
            title : "요금 안내",
            text: "자동 생성은 1회 당 50코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if(((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50 || userInfo?.coinAuth === "unlimited") {
                    setIsUpdateBool(!isUpdateBool);
                    setIsMountedCreateSynopStroy(false);
                    const config = {
                        headers: {
                            "Content-Type": "application/json",

                            "authorization": token,
                        }
                    };
                    let cate = "";
                    
                    switch (category) {
                        case 1 : {
                            cate = "로맨스";
                            break;
                        }
                        case 2 : {
                            cate = "판타지";
                            break;
                        }
                        case 3 : {
                            cate = "스릴러";
                            break;
                        }
                        case 4 : {
                            cate = "무협";
                            break;
                        }
                        case 5 : {
                            cate = "BL";
                            break;
                        }
                    }

                    const form = {keywords: cate};

                    axios
                        .post(apiServer + "/api/asiac_nestyle/initiate/synopsis", form, config)
                        .then((response) => {
                            if (response?.data?.success && response?.data?.data?.synopsis) {
                                setSynopsis(response.data.data.synopsis);
                                setIsMountedCreateSynopStroy(true);
                                setIsUpdateBool(!isUpdateBool);
                                console.log("시놉시스 스토리 생성 성공");
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "axios err" || msg === "ai generator err") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsUpdateBool(!isUpdateBool);
                                setIsMountedCreateSynopStroy(true);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setIsMountedCreateSynopStroy(true);
                        });
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {

                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
        
    }

    // 이대로 저장 버튼
    const onSaveClick = () => {
        let form = new FormData();
        form.append("novelId", novelId);
        if (category === 0) {
            alert("카테고리를 입력하세요.");
            return;
        } else {
            form.append("categoryId", category);
        }
        if (synopsisId !== null) {
            form.append("synopsisId", synopsisId);
        }
        const titleCheck = (String(title)).trim();
        if (titleCheck === "") {
            alert("제목을 입력해 주세요!");
            return;
        }
        if (title === '') {
            alert("제목을 입력하세요.");
            return;
        } else if (title.length < 2 || title.length > 50) {
            alert("제목은 최소2자~최대50자를 넘을수 없습니다.");
            return;
        } else {
            form.append("title", titleCheck);
        }
        form.append("pubState", novelDetail.pubState);
        const synopsisCheck = (String(synopsis)).trim();
        if (synopsisCheck === "") {
            alert("시놉시스를 입력해 주세요!");
            return;
        }
        if(synopsis.length < 2 || synopsis.length > 500) {
            alert("시놉시스는 2자 이상 500자 이하로 입력해주세요!");
            return;
        }
        if (synopsis === "") {
            alert("시놉시스를 입력하세요.");
            return;
        } else {
            form.append("synopsis", synopsisCheck);
        }

        if (imgSelectType === 1) {
            const file = $('#file-input')[0].files[0];
            if (file === null || file === undefined) {
                alert("이미지 파일을 업로드 해주세요.")
                return;
            }
            setImg(file);
            form.append("image", file);
            form.append("imgUploadKind", "self");
            
        } else if (imgSelectType === 2) {
            if(imgId === null || imgId === undefined) {
                alert("내 그림을 선택해주세요.");
                return;
            }
            form.append("imgUploadKind", "aiimg");
            form.append("aiImgid", imgId);
        } else if (imgSelectType === 3) {
            if(createImg === null || createImg === undefined) {
                alert("그림생성 후 저장해주세요.");
                return;
            }
            form.append("imgUploadKind", "direct_path");
            form.append("imgPath", createImg?.path);
        } else {
            
            form.append("imgPath", novelDetail.imgPath);
            form.append("imgUploadKind", novelDetail.imgUploadKind);
        }
        if (periodData === "" && pubdayData === "") {
            alert("연재주기를 입력하세요.");
            return;
        } else if (periodData === "") {
            form.append("pubday", pubdayData);
        } else {
            form.append("period", periodData);
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",

                "authorization": token,
            }
        };

        axios
            .post(apiServer + "/api/asiac_novel/update_novel", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    console.log("수정 완료");
                    setIsUpdateBool(!isUpdateBool);
                    alert("작품이 수정되었습니다.");
                    navigate(URL.CREATE_MAIN);
                } else {
                    const msg = response?.data?.custMsg
                    if(msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === "invalid info") {
                        alert("제목, 시놉시스를 확인해주세요.");
                    } else if (msg === "invalid user") {
                        alert("비정상적인 접근입니다.");
                    } else if (msg === "router failed") {
                        alert("서버 에러");
                    } else {
                        alert("저장 실패");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message)
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }

    // category, periodData, pubdayData 데이터 셋팅시 버튼 활성화 유지
    useEffect(() => {
        switch (category) {
            case 1 : {
                $('.actionBtn').removeClass("active");
                $("#category1").addClass("active");
                break;
            }
            case 2 : {
                $('.actionBtn').removeClass("active");
                $("#category2").addClass("active");
                break;
            }
            case 3 : {
                $('.actionBtn').removeClass("active");
                $("#category3").addClass("active");
                break;
            }
            case 4 : {
                $('.actionBtn').removeClass("active");
                $("#category4").addClass("active");
                break;
            }
            case 5 : {
                $('.actionBtn').removeClass("active");
                $("#category5").addClass("active");
                break;
            }
        }
        switch (periodData) {
            case "free" : {
                $('.dayBtn1').removeClass("active");
                $("#periodDataFree").addClass("active");
                break;
            }
            case "daily" : {
                $('.dayBtn1').removeClass("active");
                $("#periodDataDaily").addClass("active");
                break;
            }
            case "weekly" : {
                $('.dayBtn1').removeClass("active");
                $("#periodDataWeekly").addClass("active");
                break;
            }
        }
        const pubdayDataList = pubdayData.split(", ");
        for (let i = 0; i < pubdayDataList.length; i++) {
            switch (pubdayDataList[i]) {
                case "monday" : {
                    $("#monday").addClass("active");
                    break;
                }
                case "tuesday" : {
                    $("#tuesday").addClass("active");
                    break;
                }
                case "wednesday" : {
                    $("#wednesday").addClass("active");
                    break;
                }
                case "thursday" : {
                    $("#thursday").addClass("active");
                    break;
                }
                case "friday" : {
                    $("#friday").addClass("active");
                    break;
                }
                case "Saturday" : {
                    $("#Saturday").addClass("active");
                    break;
                }
                case "sunday" : {
                    $("#sunday").addClass("active");
                    break;
                }
            } 
        }
    }, [category, periodData, pubdayData])

    // 표지 생성 버튼
    const onClickCreateImg = () => {
        if (clickCreateImgBtnCheck === false) {
            Swal.fire({
                title : "요금 안내",
                text: "표지 생성은 1회 당 1000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 1000 || userInfo?.coinAuth === "unlimited") {
                        let form;
                        const titleCheck = (String(title)).trim();
                        if (titleCheck === "") {
                            alert("제목을 입력하세요.");
                            return;
                        }
                        const synopsisCheck = (String(synopsis)).trim();
                        if (synopsisCheck === "") {
                            alert("시놉시스 줄거리를 입력해주세요.");
                            return;
                        }
                        if (title === "") {
                            alert("제목을 입력해주세요.");
                            return;
                        } else if (title === null || title === undefined) {
                            alert("제목을 입력해주세요.");
                            return;
                        } else if (synopsis === "") {
                            alert("시놉시스 줄거리를 입력해주세요.");
                            return;
                        } else if (synopsis === null || synopsis === undefined) {
                            alert("시놉시스 줄거리를 입력해주세요.");
                            return;
                        } else {
                            form = {"imgType" : "표지", "categoryId" : category, "characters" : characters, "plot" : synopsis };
                        }
                    
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                            }
                        };
                        setClickCreateImgBtnCheck(true);
                        setIsCreateImgLoing(false);
                        setClickCreateImgBtnCheck2(true);
                        axios
                        .post(apiServer + "/api/aizac_ai_img/generate_episode_ai_img", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                alert("표지가 생성되었습니다.");
                                const copy = [...createImgList];
                                copy.push(response?.data?.data);
                                setCreateImgList(copy);
                                setCreateImg(copy[0]);
                                setImg(response?.data?.data?.imgUrl);
                                setIsUpdateBool(!isUpdateBool);
                                setIsCreateImgLoing(true);
                                setClickCreateImgBtnCheck2(false);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "invalid category") {
                                    alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsUpdateBool(!isUpdateBool);
                                setIsCreateImgLoing(true);
                                setClickCreateImgBtnCheck(false);
                                setClickCreateImgBtnCheck2(false);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setIsCreateImgLoing(true);
                            setClickCreateImgBtnCheck(false);
                            setClickCreateImgBtnCheck2(false);
                        });
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                    }
                } 
            });
        }
    }



    const [clickCreateImgBtnCheck, setClickCreateImgBtnCheck] = useState(false);
    const [clickCreateImgBtnCheck2, setClickCreateImgBtnCheck2] = useState(false);
    const [createImg, setCreateImg] = useState(null);

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    const [createImgList, setCreateImgList] = useState([]);
    const [createImgNum, setCreateImgNum] = useState(0);
    const [isCreateImgLoing, setIsCreateImgLoing] = useState(true);

    // 그림 생성 리스트 index 감소 버튼
    const onClickDecrease = () => {
        if (createImgNum > 0 && createImgNum < createImgList.length) {
            let copyNum = createImgNum - 1;
            setCreateImgNum(copyNum);
            setCreateImg(createImgList[copyNum]);
        }
    }

    // 그림 생성 리스트 index 증가 버튼
    const onClickIncrease = () => {
        if (createImgList.length > createImgNum + 1) {
            let copyNum = createImgNum + 1;
            setCreateImgNum(copyNum);
            setCreateImg(createImgList[copyNum]);
        }
    }

    // 그림 재생성 버튼
    const onClickReCreateImg = () => {
        if (clickCreateImgBtnCheck2 === false) {
            Swal.fire({
                title : "요금 안내",
                text: "표지 생성은 1회 당 1000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 1000 || userInfo?.coinAuth === "unlimited") {
                        setIsCreateImgLoing(false);
                        let form;
                        if (title === "") {
                            alert("제목을 입력해주세요.");
                            return;
                        } else if (synopsis === "") {
                            alert("시놉시스 줄거리를 입력해주세요.");
                            return;
                        } else {
                            form = {"imgType" : "표지", "categoryId" : category, "characters" : characters, "plot" : synopsis };
                        }
                    
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                "authorization": token,
                            }
                        };
                        setClickCreateImgBtnCheck2(true);
                        axios
                        .post(apiServer + "/api/aizac_ai_img/generate_episode_ai_img", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                alert("표지가 생성되었습니다.");
                                const copy = [...createImgList];
                                copy.push(response?.data?.data);
                                const num = copy.length - 1;
                                setCreateImgNum(num);
                                setCreateImg(copy[num]);
                                setCreateImgList(copy);
                                setImg(response?.data?.data?.imgUrl);
                                setClickCreateImgBtnCheck2(false);
                                setIsUpdateBool(!isUpdateBool);
                                setIsCreateImgLoing(true);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청 시간이 초과되었습니다.");
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "invalid category") {
                                    alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsUpdateBool(isUpdateBool);
                                setClickCreateImgBtnCheck2(false);
                                setIsCreateImgLoing(true);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            setClickCreateImgBtnCheck2(false);
                            setIsCreateImgLoing(true);
                        });
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                    }
                } 
            });
        }
    }

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return (
        <>
            <ContainerDiv id="container">
                <ModifyTab action={1} novelId={novelId}/>
                <SynopSelectSection className={novel2Css.synop_select + " synop_select synop-select"}>
                    <SynopSelectTitleH1 className={novel2Css.synop_select_title + " synop_select_title synop-select-title"}>작품 기본정보 수정</SynopSelectTitleH1>
                    <DefaultHr />
                    <CategoryDiv className={novel2Css.category + " category"}>
                        <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>카테고리를 선택해주세요</SynopSelectSubDiv>
                        <CategoryBtnDiv className={novel2Css.category_btn + " category_btn category-btn"}>
                            {categoryDataList.map((item, index) => <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn"} id={"category" + item?.id} key={"categoryModifiy" + index} ref= {CategoryBtnRef} value={item?.id} onClick={(e) => {onClickActionBtn(e)}} >{item?.cate}</CategoryBtnButton>)}
                        </CategoryBtnDiv>
                    </CategoryDiv>
                    <NovelNameDiv className={novel2Css.novel_name + " novel_name novel-name"}>
                        <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>작품 제목을 입력해주세요</SynopSelectSubDiv>
                            <SynopSelectSubSpanSpan>※제목은 2자~50자 이내로 입력해주세요</SynopSelectSubSpanSpan>
                        </SynopSelectSubSpan>
                        <NovelNameInputInput
                            type="text"
                            className={novel2Css.novel_name_input + " novel_name_input novel-name-input"}
                            placeholder="제목을 입력해주세요"
                            maxLength={50}
                            onChange={onChangeTitle}
                            value={title}
                        />
                        {isMountedCreateTitle === true ? <SynopSelectTabNavLi ><SynopSelectTabNavLiButton onClick={onClickCreateTitle}>제목 생성</SynopSelectTabNavLiButton></SynopSelectTabNavLi> : <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>작품 제목이 생성중입니다. 잠시만 기다려주세요.</SynopSelectSubDiv>
                        </SynopSelectSubSpan>}
                    </NovelNameDiv>
                    <NovelIntDiv className={novel2Css.novel_int + " novel_int novel-int"}>
                        <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>
                                선택 시놉시스의 기본 줄거리입니다
                            </SynopSelectSubDiv>
                            <SynopSelectSubSpanSpan>※기본 줄거리는 커스텀이 가능합니다</SynopSelectSubSpanSpan>
                            <SynopSelectSubSpanSpan>소개문은 2자~500자 이내로 입력해주세요</SynopSelectSubSpanSpan>
                        </SynopSelectSubSpan>
                        <NovelIntInputTextarea className={novel2Css.novel_int_input + " novel_int_input novel-int-input"} rows="5" maxLength={500} value={synopsis} onChange={onChangeSynopsis}>
                        </NovelIntInputTextarea>
                        {isMountedCreateSynopStroy === true ? <SynopSelectTabNavLi ><SynopSelectTabNavLiButton onClick={() => {onClickCreateSynopStory(category)}}>시놉시스 스토리 생성</SynopSelectTabNavLiButton></SynopSelectTabNavLi> : <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>시놉시스 스토리가 생성중입니다. 잠시만 기다려주세요.</SynopSelectSubDiv>
                        </SynopSelectSubSpan>}
                    </NovelIntDiv>
                    <NovelIlluDiv className={novel2Css.novel_illu + " novel_illu novel-illu"}>
                        <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>표지를 선택해주세요</SynopSelectSubDiv>
                        <SynopSelectTabDiv className={novel2Css.synop_select_tab + " synop_select_tab synop-select-tab"}>
                            <StnopSelectTabUl className={novel2Css.synop_select_tab_nav_1 + " synop_select_tab_nav_1 synop-select-tab-nav-1"}>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop00" onClick={(e) => {onClickSelectImgTypeBtn(e)}} className=" active">현재 이미지</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop01" onClick={(e) => {onClickSelectImgTypeBtn(e)}}>직접 올리기</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop02" onClick={(e) => {onClickSelectImgTypeBtn(e)}} >내 그림 선택</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                                <SynopSelectTabNavLi><SynopSelectTabNavLiLink aria-valuetext="#synop03" onClick={(e) => {onClickCreateImg(); onClickSelectImgTypeBtn(e)}} >표지생성</SynopSelectTabNavLiLink></SynopSelectTabNavLi>
                            </StnopSelectTabUl>
                            <SynopSelectTabContentDiv className={novel2Css.synop_select_tab_content + " synop_select_tab_content synop-select-tab-content"}>
                                <DefaultDiv id="synop00" style={{display : "block"}}>
                                    <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                                        <DefaultDiv id="image-preview-container-defalut" style={{display: "block"}}>
                                            {imgNow !== "" ? imgNow !== null ?<ImagePreviewImg src={imgNow} alt="Image Preview defalut" onError={noImage} id="image-preview-defalut" style={{display: "block"}}/> : <ImagePreviewImg src={defaultImg} alt="Image Preview defalut" id="image-preview-defalut" style={{display: "block"}}/> : <ImagePreviewImg src={defaultImg} alt="Image Preview defalut" id="image-preview-defalut" style={{display: "block"}}/>}
                                        </DefaultDiv>
                                    </DefaultSection>
                                </DefaultDiv>
                                <DefaultDiv id="synop01">
                                    <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                                        <DefaultFrom
                                            encType="multipart/form-data"
                                            action="#"
                                            method="post"
                                            id="upload-form"
                                        >
                                            <DefaultInput
                                                type="file"
                                                name="file"
                                                id="file-input"
                                                accept=".png, .jpg, .jpeg, .webp"
                                                onChange={handleFileInput}
                                            />
                                        </DefaultFrom>
                                        <DefaultDiv id="image-preview-container" style={{display: "none"}}>
                                            <ImagePreviewImg src="" alt="Image Preview" id="image-preview" />
                                        </DefaultDiv>
                                        <ImguploadIntDiv className={novel2Css.imgupload_int + " imgupload_int imgupload-int"}>
                                        ※업로드 파일: 최대 50mb, jpg, jpeg, png, webp 파일 지원<br />※가로9:세로16
                                        비율의 이미지가 표지로 가장 적합합니다
                                        </ImguploadIntDiv>
                                    </DefaultSection>
                                </DefaultDiv>
                                <DefaultDiv id="synop02">
                                    <DefaultSection className={novel2Css.illu_me + " illu_me illu-me"}>
                                        <NovelCreate2SectionList sectionId="synop02" setImgMy={setImg} setImgId={setImgId}/>
                                    </DefaultSection>
                                </DefaultDiv>
                                <DefaultDiv id="synop03">
                                    <DefaultSection className={novel2Css.illu_upload + " illu_upload illu-upload"}>
                                        <DefaultDiv id="image-preview-container-defalut" style={{display: "block"}}>
                                            {createImg === null ? <></> : 
                                            <ImagePreviewImg src={createImg?.imgUrl} alt="Image Preview defalut" id="image-preview-create" style={{display: "block"}}/>}
                                            {createImgList.length !== 0 ? <div style={{marginLeft : "10px", marginTop : "5px", marginBottom : "5px", fontFamily : "Min Sans-Medium", fontSize: "20px"}}>생성결과 {createImgNum + 1}/{createImgList?.length}</div> :
                                            <div style={{marginLeft : "10px", marginTop : "5px", marginBottom : "5px", fontFamily : "Min Sans-Medium", fontSize: "20px"}}>생성결과 {createImgNum}/{createImgList?.length}</div>}
                                            {isCreateImgLoing === false ? <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>표지가 생성중입니다. 잠시만 기다려주세요.</SynopSelectSubDiv> : <></>}
                                            <div className={createNovel5Css.scene_main_select_1 + " scene_main_select_1 scene-main-select-1"} style={{marginLeft : "20px"}}>
                                                <button onClick={onClickDecrease}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18px"
                                                        height="18px"
                                                        viewBox="0 0 320 512"
                                                        style={{fill : "#bcbcbf"}}>
                                                        <path
                                                            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                                        />
                                                    </svg>
                                                </button>
                                                <button onClick={onClickIncrease}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18px"
                                                        height="18px"
                                                        viewBox="0 0 320 512"
                                                        style={{fill : "#bcbcbf"}}>
                                                        <path
                                                            d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                                        />
                                                    </svg>
                                                </button>
                                                <div className={createNovel5Css.rere_scene + " rere_scene rere-scene"} style={{marginLeft : "30px"}} onClick={onClickReCreateImg}>재생성</div>
                                            </div>
                                        </DefaultDiv>
                                    </DefaultSection>
                                </DefaultDiv>
                            </SynopSelectTabContentDiv>
                        </SynopSelectTabDiv>
                    </NovelIlluDiv>
                    <DefaultHr />
                    <DefaultDiv className={novel2Css.novel_day + " novel_day novel-day"} style={{display : "block"}}>
                        <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>연재 요일을 선택해주세요</SynopSelectSubDiv>
                        <DayBtn1Div className={novel2Css.day_btn1 + " day_btn1 day-btn1"}>
                            <SubTitleDiv className={novel2Css.sub_title + " sub_title sub-title"}>주기별</SubTitleDiv>
                            <DayBtn1Button type="button" className={novel2Css.dayBtn1 + " dayBtn1"} value={"free"} id="periodDataFree" onClick={(e) => {onClickDayBtn1(e)}}>자유 연재</DayBtn1Button>
                            <DayBtn1Button type="button" className={novel2Css.dayBtn1 + " dayBtn1"} value={"daily"} id="periodDataDaily" onClick={(e) => {onClickDayBtn1(e)}}>매일 연재</DayBtn1Button>
                            <DayBtn1Button type="button" className={novel2Css.dayBtn1 + " dayBtn1"} value={"weekly"} id="periodDataWeekly" onClick={(e) => {onClickDayBtn1(e)}}>주말 연재</DayBtn1Button>
                        </DayBtn1Div>
                        <DaySubTitleDiv className={novel2Css.day_btn2 + " day_btn2 day-btn2"}>
                            <SubTitleDiv className={novel2Css.sub_title + " sub_title sub-title"}>요일별(중복 선택 가능)</SubTitleDiv>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"monday"} id="monday" onClick={(e) => {onClickDayBtn2(e)}}>월요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"tuesday"} id="tuesday" onClick={(e) => {onClickDayBtn2(e)}}>화요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"wednesday"} id="wednesday" onClick={(e) => {onClickDayBtn2(e)}}>수요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"thursday"} id="thursday" onClick={(e) => {onClickDayBtn2(e)}}>목요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"friday"} id="friday" onClick={(e) => {onClickDayBtn2(e)}}>금요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"Saturday"} id="Saturday" onClick={(e) => {onClickDayBtn2(e)}}>토요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"sunday"} id="sunday" onClick={(e) => {onClickDayBtn2(e)}}>일요일</DayBtn2Button>
                        </DaySubTitleDiv>
                    </DefaultDiv>
                    <EditNextButton className={novel2Css.edit_next + " edit_next edit-next"} type="button" onClick={onSaveClick}>이대로 저장</EditNextButton>
                </SynopSelectSection>
            </ContainerDiv>
        </>
    );
};
export default ModifyNovel;


/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    overflow: scroll;
    padding: 0 140px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
`;

/** section 태그 .synop-select css */
const SynopSelectSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 76px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 60px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
        color: #050409;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: none;
    height: 1px;
    background-color: #ededed;
    width: 100%;
    margin: 32px 0px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 18px;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
`;



/** div 태그 .category css */
const CategoryDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
`;

/** div 태그 .synop-select-sub css */
const SynopSelectSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 18px;
    color: #050409;
    letter-spacing: -0.3px;
    @media (max-width: 1400px) {
        font-size: 16px;
    }
    @media (min-width: 968px) and (max-width: 1280px) {
        font-size: 16px;
    }
`;

/** div 태그 .category-btn css */
const CategoryBtnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
`;

/** div 태그 .novel-name css */
const NovelNameDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;

/** div 태그 .synop-select-sub-span css */
const SynopSelectSubSpan = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: baseline;
    @media (min-width: 100px) and (max-width: 768px) {
        flex-direction: column;
        gap: 4px;
    }
`;

/** span 태그 .synop-select-sub-span > span css */
const SynopSelectSubSpanSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #828284;
    letter-spacing: -0.3px;
    margin-left: 4px;
`;

/** input 태그 .novel-name-input css */
const NovelNameInputInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    border: 1px solid #bdbdbd;
    font-size: 18px;
    font-family: "Min Sans-Light";
    color: #050409;
    padding: 22px 24px;
    letter-spacing: -0.3px;
    margin-top: 12px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 18px;
        padding: 22px 16px;
    }
`;

/** div 태그 .novel-int css */
const NovelIntDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;

/** textarea 태그 .novel-int-input css */
const NovelIntInputTextarea = styled.textarea`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    border: 1px solid #bdbdbd;
    font-size: 18px;
    font-family: "Min Sans-Light";
    color: #050409;
    padding: 22px 24px;
    letter-spacing: -0.3px;
    margin-top: 12px;
`;

/** div 태그 .novel-illu css */
const NovelIlluDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;

/** div 태그 .synop-select-tab css */
const SynopSelectTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

/** ul 태그 .synop-select-tab > ul css */
const StnopSelectTabUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
`;

/** li 태그 .synop-select-tab-nav > li css */
const SynopSelectTabNavLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
`;

/** div 태그 .synop-select-tab-content css */
const SynopSelectTabContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    border-top: none;
`;

/** section 태그 디폴트 css */
const DefaultSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** form 태그 디폴트 css */
const DefaultFrom = styled.form`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** input 태그 디폴트 css */
const DefaultInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** img 태그 #image-preview css */
const ImagePreviewImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    max-width: 100%;
    max-height: 200px;
    margin-top: 20px;
    @media (min-width: 100px) and (max-width: 768px) {
        width: 100px; /* 이미지 크기 조정 */
        height: 100px;
    }
`;

/** div 태그 .imgupload-int css */
const ImguploadIntDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #828284;
    letter-spacing: -0.3px;
    margin-top: 10px;
    line-height: 24px;
`;

/** div 태그 .day-btn1 css */
const DayBtn1Div = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
`;

/** button 태그 .day-btn1 > button css */
const DayBtn1Button = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right:4px
    }
`;

/** div 태그 .sub-title css */
const SubTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 14px;
    color: #828284;
    margin-bottom: 8px;
`;

/** div 태그 .day-btn2 css */
const DaySubTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 20px;
    }
`;

/** button 태그 .day-btn2 > button css */
const DayBtn2Button = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 968px) and (max-width: 1280px) {
        margin-top: 12px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right:4px;
        margin-bottom:4px
    }
`;

/** button 태그 .edit-next css */
const EditNextButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    background-color: #22222a;
    color: #fff;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 50px 0px 120px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {background-color: #303039;}
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 40px;
        margin-bottom: 80px;
    }
`;

/** button 태그 .category-btn > button css */
const CategoryBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right: 4px;
    }
`;

/** a 태그 .synop-select-tab-nav li a css */
const SynopSelectTabNavLiLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    &.active {
        border-bottom: 1px solid #fff;
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        color: #828284;
        border: 1px solid #bdbdbd;
    }
`;

/** a 태그 .synop-select-tab-nav li button css */
const SynopSelectTabNavLiButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    background: white;
    &:hover {
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    margin-top: 10px;
    &.active {
        border-bottom: 1px solid #fff;
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        color: #828284;
        border: 1px solid #bdbdbd;
    }
`;
