import SynopTab from "../../components/common/SynopTab";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import $ from 'jquery';
import novel2Css from'../../css/novelCreate2.module.css';
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import axios from "axios";
import { getCookie, removeCookie, removeCookieOption} from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";
import Swal from "sweetalert2";



const NovelCreate2 = ({isUpdateBool, setIsUpdateBool}) => {
    
    const synop = getCookie("synop");
    // 최초 실행시 synop 입력 확인(비정상 접근 방지)
    useEffect(() => {
        if(synop === undefined) {
            alert("비정상적인 접근입니다.");
            navigate(URL.MAIN);
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    const [synopItem, setSynopItem] = useState(synop);
    const [category, setCategory] = useState(synop?.categoryId);
    const [periodData, setPeriodData] = useState("");
    const [pubdayData, setPubdayData] = useState("");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState(null);

    const [title, setTitle] = useState('');
    const [synopStory, setSynopStory] = useState(synop?.synopsis);

    const token = getCookie("token");

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 제목 변경 입력 함수
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };
    // 시놉시스 스토리 변경 입력 함수
    const onChangeSynopStory = (e) => {
        setSynopStory(e.target.value);
    };

    // 시놉시스 셋팅시 카테고리 변경 유지
    useEffect(() => {
        if (synop !== null) {
            setSynopItem(synop);

            switch (synop.categoryId) {
                case 1 : {
                    $('.actionBtn').removeClass("active");
                    $("#category1").addClass("active");
                    break;
                }
                case 2 : {
                    $('.actionBtn').removeClass("active");
                    $("#category2").addClass("active");
                    break;
                }
                case 3 : {
                    $('.actionBtn').removeClass("active");
                    $("#category3").addClass("active");
                    break;
                }
                case 4 : {
                    $('.actionBtn').removeClass("active");
                    $("#category4").addClass("active");
                    break;
                }
                case 5 : {
                    $('.actionBtn').removeClass("active");
                    $("#category5").addClass("active");
                    break;
                }
            }
        } else if (synop === null) {
            alert("시놉시스를 선택해야합니다.");
        }    
    }, []);

    // isUpdateBool 변경시 유저정보 업데이트(api 코인차감 업데이트)
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])

    const categoryDataList = [{cate: "로맨스", id:1}, {cate: "판타지", id:2}, {cate:"스릴러", id:3}, {cate:"무협", id:4}, {cate:"기타", id:5}];

    const CategoryBtnRef = useRef();

    // 주기별 카테고리 선택 버튼
    const onClickDayBtn1 = (e) => {
        $('.dayBtn1').removeClass("active");
        $('.dayBtn2').removeClass("active");

        e.target.classList.add("active");
        const val = e.target.value;

        setPeriodData(val);
        setPubdayData("");
    }
    // 요일별 카테고리 선택 버튼
    const onClickDayBtn2 = (e) => {
        const data = (e.target.classList.value);
        let boolCheck = false;
        const val = e.target.value;
        setPeriodData("");
        $('.dayBtn1').removeClass("active");
        if (data.includes("active")) {
            boolCheck = true
        }

        if (boolCheck) {
            e.target.classList.remove("active");

            let copy2 = pubdayData.split(", ");

            let copy = copy2.filter((element) => element !== val);

            if (copy.length >= 1) {
                // 요일을 정렬
                var sortedDays = copy.sort(function(a, b) {
                    // 요일을 정렬하기 위해 영어로 된 요일을 비교
                    var daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "Saturday", "sunday"];
                    return daysOrder.indexOf(a) - daysOrder.indexOf(b);
                });
                let data = "";
                for (let i = 0; i < sortedDays.length; i++) {
                    if((i + 1) < sortedDays.length) {
                        data = data + sortedDays[i] + ", "; 
                    } else {
                        data = data + sortedDays[i]; 
                    }
                }

                setPubdayData(data);
            } else {
                let data = "";

                setPubdayData(data);
            }
        } else {
    
            e.target.classList.add("active");
    
            if (pubdayData === "") {
                let copy = [];
                copy.push(val);
                setPubdayData(val);
            } else {
                const copy = pubdayData.split(", ");
                if (!(copy.includes(val))) {
                    copy.push(val);
                } 
                if (copy.length > 1) {
                    // 요일을 정렬
                    var sortedDays2 = copy.sort(function(a, b) {
                        // 요일을 정렬하기 위해 영어로 된 요일을 비교
                        var daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "Saturday", "sunday"];
                        return daysOrder.indexOf(a) - daysOrder.indexOf(b);
                    });
                    let data = "";
                    for (let i = 0; i < sortedDays2.length; i++) {
                        if((i + 1) < sortedDays2.length) {
                            data = data + sortedDays2[i] + ", "; 
                        } else {
                            data = data + sortedDays2[i]; 
                        }
                    }
                    setPubdayData(data);
                }
            }
        }
    }

    // 카테고리 선택 버튼
    const onClickActionBtn = (e) => {
        $('.actionBtn').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setCategory(parseInt(val));

    }

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    // 저장하고 다음으로 버튼
    const onSaveClick = () => {
        let form = new FormData();
        form.append("novelId", 0);
        if (synop !== null) {
            form.append("synopsisId", synopItem.id);
        }
        if (category === 0) {
            alert("카테고리를 입력하세요.");
            return;
        } else if(category === null || category === undefined) {
            alert("카테고리를 입력하세요.");
            return;
        } else {
            form.append("categoryId", category);
        }

        const titleCheck = (String(title)).trim();
        if (titleCheck === "") {
            alert("제목을 입력하세요.");
            return;
        }
        if (title === '') {
            alert("제목을 입력하세요.");
            return;
        } else if (title.length < 2 || title.length > 50) {
            alert("제목은 2자 ~ 50자 이내로 입력해주세요.");
            return;
        } else if (title === null || title === undefined) {
            alert("제목을 입력하세요.");
            return;
        } else {
            form.append("title", titleCheck);
        }

        const synopStoryCheck = (String(synopStory)).trim();
        if (synopStoryCheck === "") {
            alert("시놉시스 줄거리를 입력하세요.");
            return;
        }
        if (synopStory.length < 2 || synopStory.length > 500) {
            alert("시놉시스 줄거리는 2자 이상 500자 이하로 입력하세요.");
            return;
        }
        if (synopStory === "") {
            alert("시놉시스 줄거리를 입력하세요.");
            return;
        } else if (synopStory === null || synopStory === undefined) {
            alert("시놉시스 줄거리를 입력하세요.");
            return;
        } else {
            form.append("synopsis", synopStoryCheck);
        }
        
        form.append("pubState", "public");
        
        if (periodData === "" && pubdayData === "") {
            alert("연재주기를 입력하세요.");
            return;
        } else if ((periodData === null || periodData === undefined) && (pubdayData === null || pubdayData === undefined)) {
            alert("연재주기를 입력하세요.");
            return;
        } else if (periodData === "") {
            form.append("pubday", pubdayData);
        } else {
            form.append("period", periodData);
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",

                "authorization": token,
            }
        };

        axios
            .post(apiServer + "/api/asiac_novel/update_novel", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    setIsUpdateBool(!isUpdateBool);
                    navigate(URL.CREATE_NOVEL3 + response.data.data.novel.id);
                    console.log("저장 성공");
                } else {
                    const msg = response?.data?.custMsg
                    if(msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === "invalid info") {
                        alert("제목, 시놉시스를 확인해주세요.");
                    } else if (msg === "invalid user") {
                        alert("비정상적인 접근입니다.");
                    } else if (msg === "router failed") {
                        alert("서버 에러");
                    } else {
                        alert("저장 실패");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }
    
    const [isMountedCreateTitle, setIsMountedCreateTitle] = useState(true);
    
    // 제목 생성 버튼
    const onClickCreateTitle = () => {
        Swal.fire({
            title : "요금 안내",
            text: "자동 생성은 1회 당 50코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50 || userInfo?.coinAuth === "unlimited") {
                    if(synopStory === "") {
                        alert("시놉시스 스토리를 생성 후 이용해주세요.");
                        return;
                    } else {
                        setIsMountedCreateTitle(false);
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
        
                                "authorization": token,
                            }
                        };
        
                        let form = {"synopsis": synopStory};
                        axios
                            .post(apiServer + "/api/asiac_nestyle/initiate/title", form, config)
                            .then((response) => {
                                if (response?.data?.success ) {
                                    setIsMountedCreateTitle(true);
                                    setIsUpdateBool(!isUpdateBool);
                                    setTitle(response.data.data.title);
                                } else {
                                    const msg = response?.data?.custMsg;
                                    if (msg.includes("timeout")) {
                                        alert("요청 시간이 초과되었습니다.");
                                    } else if (msg === "no jwt token" || msg === "invalid jwt" ) {
                                        alert("다시 로그인 후 이용해주세요.");
                                        logout();
                                    } else if (msg === "need more coin") {
                                        alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                    } else if (msg === "axios err" || msg === "ai generator err") {
                                        alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                    } else if (msg === "router err") {
                                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                    } else {
                                        alert(msg);
                                    }
                                    console.log(msg);
                                    setIsMountedCreateTitle(true);
                                    setIsUpdateBool(!isUpdateBool);
                                }
                            })
                            .catch((e) => {
                                alert(e.message);
                                console.log(e);
                                setIsMountedCreateTitle(true);
                            });
                    }
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
        
    }

    const [isMountedCreateSynopStroy, setIsMountedCreateSynopStroy] = useState(true);

    // 시놉시스 스토리 생성 버튼
    const onClickCreateSynopStory = () => {
        Swal.fire({
            title : "요금 안내",
            text: "자동 생성은 1회 당 50코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if(((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50 || userInfo?.coinAuth === "unlimited") {

                    if(category === 0) {
                        alert("카테고리를 입력해주세요.");
                        return;
                    } else {
                        setIsMountedCreateSynopStroy(false);
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
        
                                "authorization": token,
                            }
                        };
        
                        let form = {keywords: ""};
                        switch (category) {
                            case 1 : {
                                form.keywords = categoryDataList[0].cate;
                                break;
                            }
                            case 2 : {
                                form.keywords = categoryDataList[1].cate;
                                break;
                            }
                            case 3 : {
                                form.keywords = categoryDataList[2].cate;
                                break;
                            }
                            case 4 : {
                                form.keywords = categoryDataList[3].cate;
                                break;
                            }
                            case 5 : {
                                form.keywords = categoryDataList[4].cate;
                                break;
                            }
                        }
        
                        axios
                            .post(apiServer + "/api/asiac_nestyle/initiate/synopsis", form, config)
                            .then((response) => {
                                if (response?.data?.success) {
                                    setSynopStory(response.data.data.synopsis);
                                    setIsMountedCreateSynopStroy(true);
                                    setIsUpdateBool(!isUpdateBool);
                                } else {
                                    const msg = response?.data?.custMsg;
                                    if (msg.includes("timeout")) {
                                        alert("요청 시간이 초과되었습니다.");
                                        console.log("스토리 생성 시간 초과");
                                    } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                        alert("다시 로그인 후 이용해주세요.");
                                        logout();
                                    } else if (msg === "need more coin") {
                                        alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                    } else if (msg === "axios err" || msg === "ai generator err") {
                                        alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                    } else if (msg === "router err") {
                                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                    } else {
                                        alert(msg);
                                    }
                                    console.log(msg);
                                    setIsMountedCreateSynopStroy(true);
                                    setIsUpdateBool(!isUpdateBool);
                                }
                            })
                            .catch((e) => {
                                alert(e.message);
                                console.log(e);
                                setIsMountedCreateSynopStroy(true);
                            });
                    }
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
    }

    return (
        <>
            <ContainerDiv id="container">
                <SynopTab action={2} />
                <SynopSelectSection className={novel2Css.synop_select + " synop_select synop-select"}>
                    <SynopSelectTitleH1 className={novel2Css.synop_select_title + " synop_select_title synop-select-title"}>작품 기본정보를 입력해주세요</SynopSelectTitleH1>
                    <DefaultHr />
                    <CategoryDiv className={novel2Css.category + " category"}>
                        <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>카테고리를 선택해주세요</SynopSelectSubDiv>
                        <CategoryBtnDiv className={novel2Css.category_btn + " category_btn category-btn"}>
                            {categoryDataList.map((item, index) => <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn"} id={"category" + item?.id} key={"categoryNovelCreate2_" + index} ref= {CategoryBtnRef} value={item?.id} onClick={(e) => {onClickActionBtn(e)}} >{item?.cate}</CategoryBtnButton>)}
                        </CategoryBtnDiv>
                    </CategoryDiv>
                    <NovelNameDiv className={novel2Css.novel_name + " novel_name novel-name"}>
                        <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>작품 제목을 입력해주세요</SynopSelectSubDiv>
                            <SynopSelectSubSpanSpan>※제목은 2자~50자 이내로 입력해주세요</SynopSelectSubSpanSpan>
                        </SynopSelectSubSpan>
                        <NovelNameInputInput
                            type="text"
                            className={novel2Css.novel_name_input + " novel_name_input novel-name-input"}
                            placeholder="제목을 입력해주세요"
                            maxLength={50}
                            onChange={onChangeTitle}
                            value={title}
                        />
                        {isMountedCreateTitle === true ? <SynopSelectTabNavLi ><SynopSelectTabNavLiButton onClick={onClickCreateTitle}>제목 생성</SynopSelectTabNavLiButton></SynopSelectTabNavLi> : <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>작품 제목이 생성중입니다. 잠시만 기다려주세요.</SynopSelectSubDiv>
                        </SynopSelectSubSpan>}
                    </NovelNameDiv>
                    <NovelIntDiv className={novel2Css.novel_int + " novel_int novel-int"}>
                        <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>
                                선택 시놉시스의 기본 줄거리입니다
                            </SynopSelectSubDiv>
                            <SynopSelectSubSpanSpan>※기본 줄거리는 커스텀이 가능합니다</SynopSelectSubSpanSpan>
                            <SynopSelectSubSpanSpan>소개문은 2자~500자 이내로 입력해주세요</SynopSelectSubSpanSpan>
                        </SynopSelectSubSpan>
                        <NovelIntInputTextarea className={novel2Css.novel_int_input + " novel_int_input novel-int-input"} maxLength={500} rows="5" onChange={onChangeSynopStory} value={synopStory}>
                        </NovelIntInputTextarea>
                        {isMountedCreateSynopStroy === true ? <SynopSelectTabNavLi ><SynopSelectTabNavLiButton onClick={onClickCreateSynopStory}>시놉시스 스토리 생성</SynopSelectTabNavLiButton></SynopSelectTabNavLi> : <SynopSelectSubSpan className={novel2Css.synop_select_sub_span + " synop_select_sub_span synop-select-sub-span"}>
                            <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>시놉시스 스토리가 생성중입니다. 잠시만 기다려주세요.</SynopSelectSubDiv>
                        </SynopSelectSubSpan>}
                    </NovelIntDiv>
                    <DefaultHr />
                    <DefaultDiv className={novel2Css.novel_day + " novel_day novel-day"} style={{display : "block"}}>
                        <SynopSelectSubDiv className={novel2Css.synop_select_sub + " synop_select_sub synop-select-sub"}>연재 요일을 선택해주세요</SynopSelectSubDiv>
                        <DayBtn1Div className={novel2Css.day_btn1 + " day_btn1 day-btn1"}>
                            <SubTitleDiv className={novel2Css.sub_title + " sub_title sub-title"}>주기별</SubTitleDiv>
                            <DayBtn1Button type="button" className={novel2Css.dayBtn1 + " dayBtn1"} value={"free"} onClick={(e) => {onClickDayBtn1(e)}}>자유 연재</DayBtn1Button>
                            <DayBtn1Button type="button" className={novel2Css.dayBtn1 + " dayBtn1"} value={"daily"} onClick={(e) => {onClickDayBtn1(e)}}>매일 연재</DayBtn1Button>
                            <DayBtn1Button type="button" className={novel2Css.dayBtn1 + " dayBtn1"} value={"weekly"}onClick={(e) => {onClickDayBtn1(e)}}>주말 연재</DayBtn1Button>
                        </DayBtn1Div>
                        <DaySubTitleDiv className={novel2Css.day_btn2 + " day_btn2 day-btn2"}>
                            <SubTitleDiv className={novel2Css.sub_title + " sub_title sub-title"}>요일별(중복 선택 가능)</SubTitleDiv>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"monday"} onClick={(e) => {onClickDayBtn2(e)}}>월요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"tuesday"} onClick={(e) => {onClickDayBtn2(e)}}>화요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"wednesday"} onClick={(e) => {onClickDayBtn2(e)}}>수요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"thursday"} onClick={(e) => {onClickDayBtn2(e)}}>목요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"friday"} onClick={(e) => {onClickDayBtn2(e)}}>금요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"Saturday"} onClick={(e) => {onClickDayBtn2(e)}}>토요일</DayBtn2Button>
                            <DayBtn2Button type="button" className={novel2Css.dayBtn2 + " dayBtn2"} value={"sunday"} onClick={(e) => {onClickDayBtn2(e)}}>일요일</DayBtn2Button>
                        </DaySubTitleDiv>
                    </DefaultDiv>
                    <EditNextButton className={novel2Css.edit_next + " edit_next edit-next"} type="button" onClick={onSaveClick}>저장하고 다음으로</EditNextButton>
                </SynopSelectSection>
            </ContainerDiv>
        </>
    );
};

export default NovelCreate2;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    overflow: scroll;
    padding: 0 140px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** section 태그 .synop-select css */
const SynopSelectSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 76px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 60px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
        color: #050409;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: none;
    height: 1px;
    background-color: #ededed;
    width: 100%;
    margin: 32px 0px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 18px;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
`;



/** div 태그 .category css */
const CategoryDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
`;

/** div 태그 .synop-select-sub css */
const SynopSelectSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 18px;
    color: #050409;
    letter-spacing: -0.3px;
    @media (max-width: 1400px) {
        font-size: 16px;
    }
    @media (min-width: 968px) and (max-width: 1280px) {
        font-size: 16px;
    }
`;

/** div 태그 .category-btn css */
const CategoryBtnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
`;

/** div 태그 .novel-name css */
const NovelNameDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;

/** div 태그 .synop-select-sub-span css */
const SynopSelectSubSpan = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: baseline;
    @media (min-width: 100px) and (max-width: 768px) {
        flex-direction: column;
        gap: 4px;
    }
`;

/** span 태그 .synop-select-sub-span > span css */
const SynopSelectSubSpanSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #828284;
    letter-spacing: -0.3px;
    margin-left: 4px;
`;

/** input 태그 .novel-name-input css */
const NovelNameInputInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    border: 1px solid #bdbdbd;
    font-size: 18px;
    font-family: "Min Sans-Light";
    color: #050409;
    padding: 22px 24px;
    letter-spacing: -0.3px;
    margin-top: 12px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 18px;
        padding: 22px 16px;
    }
`;

/** div 태그 .novel-int css */
const NovelIntDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;

/** textarea 태그 .novel-int-input css */
const NovelIntInputTextarea = styled.textarea`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    border: 1px solid #bdbdbd;
    font-size: 18px;
    font-family: "Min Sans-Light";
    color: #050409;
    padding: 22px 24px;
    letter-spacing: -0.3px;
    margin-top: 12px;
`;

/** div 태그 .novel-illu css 
const NovelIlluDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 50px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 42px;
    }
`;*/

/** div 태그 .synop-select-tab css 
const SynopSelectTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;*/

/** ul 태그 .synop-select-tab > ul css 
const StnopSelectTabUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
`;*/

/** li 태그 .synop-select-tab-nav > li css */
const SynopSelectTabNavLi = styled.li`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #22222a;
`;

/** div 태그 .synop-select-tab-content css 
const SynopSelectTabContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    border-top: none;
`;*/

/** section 태그 디폴트 css 
const DefaultSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;*/

/** form 태그 디폴트 css 
const DefaultFrom = styled.form`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;*/

/** input 태그 디폴트 css 
const DefaultInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;*/

/** img 태그 #image-preview css 
const ImagePreviewImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    max-width: 100%;
    max-height: 200px;
    margin-top: 20px;
    display: none;
    @media (min-width: 100px) and (max-width: 768px) {
        width: 100px; 
        height: 100px;
    }
`;*/

/** div 태그 .imgupload-int css 
const ImguploadIntDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #828284;
    letter-spacing: -0.3px;
    margin-top: 10px;
    line-height: 24px;
`;*/

/** div 태그 .day-btn1 css */
const DayBtn1Div = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
`;

/** button 태그 .day-btn1 > button css */
const DayBtn1Button = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right:4px
    }
`;

/** div 태그 .sub-title css */
const SubTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 14px;
    color: #828284;
    margin-bottom: 8px;
`;

/** div 태그 .day-btn2 css */
const DaySubTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 20px;
    }
`;

/** button 태그 .day-btn2 > button css */
const DayBtn2Button = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 968px) and (max-width: 1280px) {
        margin-top: 12px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right:4px;
        margin-bottom:4px
    }
`;

/** button 태그 .edit-next css */
const EditNextButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    background-color: #22222a;
    color: #fff;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 50px 0px 120px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {background-color: #303039;}
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 40px;
        margin-bottom: 80px;
    }
`;

/** button 태그 .category-btn > button css */
const CategoryBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right: 4px;
    }
`;

/** a 태그 .synop-select-tab-nav li a css 
const SynopSelectTabNavLiLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    &:hover {
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    &.active {
        border-bottom: 1px solid #fff;
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        color: #828284;
        border: 1px solid #bdbdbd;
    }
`;*/

/** a 태그 .synop-select-tab-nav li button css */
const SynopSelectTabNavLiButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    padding: 8px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    background: white;
    &:hover {
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    margin-top: 10px;
    &.active {
        border-bottom: 1px solid #fff;
        background: #040a20;
        color: #fff;
        border: 1px solid #040a20;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        color: #828284;
        border: 1px solid #bdbdbd;
    }
`;

