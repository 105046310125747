import styled from "@emotion/styled";
import { Link } from "react-router-dom";


// 소설 글쓰기 진행 탭 활성화 컴포넌트
const SynopTab = ({ action }) => {
    
    let action2Bool = false;
    let action3Bool = false;
    let action4Bool = false;
    let action5Bool = false;

    switch (action) {
        case 1:
            break;
        case 2:
            action2Bool = true;
            break;
        case 3:
            action2Bool = true;
            action3Bool = true;
            break;
        case 4:
            action2Bool = true;
            action3Bool = true;
            action4Bool = true;
            break;
        case 5:
            action2Bool = true;
            action3Bool = true;
            action4Bool = true;
            action5Bool = true;
            break;
    }
    
    return (
        <>
            <SynopTabSection className="synop-tab">
                <SynopTabGroupDiv className="synop-tab-group">
                    <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>시놉시스 선택</SynopTabBtnULink></SynopTabBtnUDiv>
                    <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv>
                </SynopTabGroupDiv>
                <SynopTabGroupDiv className="synop-tab-group">
                    {action2Bool ? <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>기본정보</SynopTabBtnULink></SynopTabBtnUDiv>
                     : <SynopTabBtnDiv className="synop-tab-btn"><SynopTabBtnLink onClick={(e) => {e.preventDefault();}}>기본정보</SynopTabBtnLink></SynopTabBtnDiv>}
                    {action2Bool ? <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv> :
                    <SynopTabBtnNlineDiv className="synop-tab-btn-nline"></SynopTabBtnNlineDiv>}
                </SynopTabGroupDiv>
                <SynopTabGroupDiv className="synop-tab-group">
                    {action3Bool ? <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>등장인물</SynopTabBtnULink></SynopTabBtnUDiv> :
                    <SynopTabBtnDiv className="synop-tab-btn"><SynopTabBtnLink onClick={(e) => {e.preventDefault();}}>등장인물</SynopTabBtnLink></SynopTabBtnDiv>}
                    {action3Bool ? <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv> :
                    <SynopTabBtnNlineDiv className="synop-tab-btn-nline"></SynopTabBtnNlineDiv>}
                </SynopTabGroupDiv>
                <SynopTabGroupDiv className="synop-tab-group">
                    {action4Bool ? <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>표지생성</SynopTabBtnULink></SynopTabBtnUDiv> :
                    <SynopTabBtnDiv className="synop-tab-btn"><SynopTabBtnLink onClick={(e) => {e.preventDefault();}}>표지생성</SynopTabBtnLink></SynopTabBtnDiv>}
                    {action4Bool ? <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv> :
                    <SynopTabBtnNlineDiv className="synop-tab-btn-nline"></SynopTabBtnNlineDiv>}
                </SynopTabGroupDiv>
                <SynopTabGroupDiv className="synop-tab-group">
                    {action5Bool ? <SynopTabBtnUDiv className="synop-tab-btn-u"><SynopTabBtnULink onClick={(e) => {e.preventDefault();}}>집필시작</SynopTabBtnULink></SynopTabBtnUDiv> :
                    <SynopTabBtnDiv className="synop-tab-btn"><SynopTabBtnLink onClick={(e) => {e.preventDefault();}}>집필시작</SynopTabBtnLink></SynopTabBtnDiv>}
                    {action5Bool ? <SynopTabBtnLineDiv className="synop-tab-btn-line"></SynopTabBtnLineDiv> :
                    <SynopTabBtnNlineDiv className="synop-tab-btn-nline"></SynopTabBtnNlineDiv>}
                </SynopTabGroupDiv>
            </SynopTabSection>
        </>
    );
};

export default SynopTab;

/** section 태그 .synop-tab css */
const SynopTabSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 108px;
    display: flex;
    justify-content: center;
`;

/** div 태그 .synop-tab-group css */
const SynopTabGroupDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
`;

/** a 태그 .synop-tab-btn > a css */
const SynopTabBtnLink = styled(Link)`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #a8a8a9;
    position: relative;
    letter-spacing: -0.3px;
`;

/** div 태그 .synop-tab-btn-nline css */
const SynopTabBtnNlineDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    height: 4px;
    background-color: #ededed;
    margin-top: 12px;
`;

/** div 태그 .synop-tab-btn-line css */
const SynopTabBtnLineDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    height: 4px;
    background-color: #ff5f37;
    margin-top: 12px;
`;

/** div 태그 .synop-tab-btn-u css */
const SynopTabBtnUDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: center;
`;

/** div 태그 .synop-tab-btn css */
const SynopTabBtnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: center;
`;

/** a 태그 .synop-tab-btn-u > a css */
const SynopTabBtnULink = styled(Link)`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #ff5f37;
    position: relative;
    letter-spacing: -0.3px;
    text-decoration: none;
`;
