import TermWriterRegistration from "components/terms/TermWriterRegistration";
import BottomButton from "../../components/common/BottomButton";
import Footer from "../../components/common/Footer";
import TopButton from "../../components/common/TopButton";
import TermPersonal from "../../components/terms/TermPersonal";
import TermService from "../../components/terms/TermService";
import TermYouth from "../../components/terms/TermYouth";
import URL from "../../constants/url";
import { useState } from "react";
import { Route, Routes } from "react-router";
import TermWriterUse from "components/terms/TermWriterUse";


const AizacTerm = () => {

    const [isAlone,] = useState(false);

    
    return (
        <>
            <TopButton isAlone={isAlone}/>
            <BottomButton/>
            <Routes>
                <Route path={URL.PERSONAL_INFORMATION} element={<TermPersonal/>}/>
                <Route path={URL.TERMS_SERVICE} element={<TermService/>}/>
                <Route path={URL.YOUTH_PROTECTION_POLICY} element={<TermYouth/>}/>
                <Route path={URL.WRITER_REGISTRATION} element={<TermWriterRegistration/>}/>
                <Route path={URL.WRITER_USE} element={<TermWriterUse/>}/>
            </Routes>
            <Footer/>
        </>
    );
}
export default AizacTerm;