import Header from "../../components/common/Header";
import ChangePassword from "../../components/find/ChangePassword";
import FindPassword1 from "../../components/find/FindPassword1";
import FindPassword2 from "../../components/find/FindPassword2";
import URL from "../../constants/url";
import { Route, Routes } from "react-router";


const AizacFind = () => {
    
    return (
        <>
            <Header/>
            <Routes>
                <Route path={URL.PASSWORD} element={<FindPassword1/>}/>
                <Route path={URL.PASSWORD2} element={<FindPassword2/>}/>
                <Route path={URL.CHANGE_PASSWORD} element={<ChangePassword/>}/>
            </Routes>
        </>
    );
};
export default AizacFind;