import Header from "../../components/common/Header";
import TopButton from "../../components/common/TopButton";
import WithDrawal1 from "../../components/withDrawal/WithDrawal1";
import WithDrawal2 from "../../components/withDrawal/WithDrawal2";
import WithDrawal3 from "../../components/withDrawal/WithDrawal3";
import { useState } from "react";
import { Route, Routes } from "react-router";


const AizacWithdrawal = () => {

    const [isAlone,] = useState(false);

    return (
        <>
            <TopButton isAlone={isAlone}/>
            <Header/>
            <Routes>
                <Route path={"/1"} element={<WithDrawal1/>}/>
                <Route path={"/2"} element={<WithDrawal2/>}/>
                <Route path={"/3"} element={<WithDrawal3/>}/>
            </Routes>
        </>
    );
};
export default AizacWithdrawal;