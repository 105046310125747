
import styled from "@emotion/styled";
import LeftAside from "../../components/common/LeftAside";
import URL from "../../constants/url";
import NovelCreate1 from "../../components/novelCreate/NovelCreate1";
import NovelCreate2 from "../../components/novelCreate/NovelCreate2";
import NovelCreate3 from "../../components/novelCreate/NovelCreate3";
import NovelCreate4 from "../../components/novelCreate/NovelCreate4";
import { Route, Routes, useNavigate } from "react-router-dom";
import CreateMain from "../../components/novelCreate/CreateMain";
import MyPicture from "../../components/novelCreate/MyPicture";
import EditPicture from "../../components/novelCreate/EditPicture";
import PictureCreate1 from "../../components/novelCreate/PictureCreate1";
import PictureCreate2 from "../../components/novelCreate/PictureCreate2";
import PictureCreate3 from "../../components/novelCreate/PictureCreate3";
import PictureCreate4 from "../../components/novelCreate/PictureCreate4";
import { getCookie } from "../../hooks/Cookie";
import { BrowserView, MobileView } from 'react-device-detect';
import loginNImg from '../../css/images/login.png'
import { useEffect, useState } from "react";
import NovelCreate3_1 from "components/novelCreate/NovelCreate3_1";
import { useMediaQuery } from "react-responsive";


const AizacCreate = () => {

    const token = getCookie("token");
    const navigate = useNavigate();
    const [isUpdateBool, setIsUpdateBool] = useState(false);
    const [isCreateImg, setIsCreateImg] = useState(false);
    
    useEffect(() => {
        // 토큰 검사
        if (token === undefined) {
            navigate("/login");
        }
    })

    // 이전페이지 이동 버튼
    const onClickMobBtn = () => {
        navigate(-1);
    }

    // 화면 크기 검사
    const isMediumDevice = useMediaQuery( {query : "(min-width : 569px)"});


    return (
        <>
            <BrowserView>
                <WrapDiv id="wrap">
                    <LeftAside isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>
                    <Routes>
                        <Route path={URL.MAIN_NOVEL} element={<CreateMain setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.NOVEL1} element={<NovelCreate1 />} />
                        <Route path={URL.NOVEL2} element={<NovelCreate2 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>} />
                        <Route path={URL.NOVEL3} element={<NovelCreate3 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>} />
                        <Route path={URL.NOVEL3_1} element={<NovelCreate3_1 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>} />
                        <Route path={URL.NOVEL4} element={<NovelCreate4 />} />
                        <Route path={URL.MY_PICTURE} element={<MyPicture />} />
                        <Route path={URL.EDIT_PICTURE} element={<EditPicture />} />
                        <Route path={URL.PICTURE1} element={<PictureCreate1 setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.PICTURE2} element={<PictureCreate2 setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.PICTURE3} element={<PictureCreate3 setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.PICTURE4} element={<PictureCreate4 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} isCreateImg={isCreateImg} setIsCreateImg={setIsCreateImg}/>} />
                    </Routes>
                </WrapDiv>
            </BrowserView>
            {/* 모바일 여부 확인 후 모바일 페이지 랜더링 */}
            <MobileView>
                {isMediumDevice === true ? <WrapDiv id="wrap">
                    <LeftAside isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>
                    <Routes>
                        <Route path={URL.MAIN_NOVEL} element={<CreateMain setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.NOVEL1} element={<NovelCreate1 />} />
                        <Route path={URL.NOVEL2} element={<NovelCreate2 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>} />
                        <Route path={URL.NOVEL3} element={<NovelCreate3 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>} />
                        <Route path={URL.NOVEL3_1} element={<NovelCreate3_1 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool}/>} />
                        <Route path={URL.NOVEL4} element={<NovelCreate4 />} />
                        <Route path={URL.MY_PICTURE} element={<MyPicture />} />
                        <Route path={URL.EDIT_PICTURE} element={<EditPicture />} />
                        <Route path={URL.PICTURE1} element={<PictureCreate1 setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.PICTURE2} element={<PictureCreate2 setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.PICTURE3} element={<PictureCreate3 setIsCreateImg={setIsCreateImg}/>} />
                        <Route path={URL.PICTURE4} element={<PictureCreate4 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} isCreateImg={isCreateImg} setIsCreateImg={setIsCreateImg}/>} />
                    </Routes>
                </WrapDiv> : 
                <MobWriteContainerDiv id="mob-write-container">
                    <MobWriteImgDiv className="mob-write-img">
                        <img src={loginNImg} alt="login_img"/>
                    </MobWriteImgDiv>
                    <MobWriteContainerH2H2 className="title">원활한 서비스를 위해 현재 집필실은<br/> PC와 태블릿에서만 이용하실 수 있습니다</MobWriteContainerH2H2>
                    <EditNextButton className="edit-next" type="button" onClick={onClickMobBtn}>이전 페이지로</EditNextButton>
                </MobWriteContainerDiv>}
            </MobileView>
        </>
    );
  }
  
  export default AizacCreate;

  /** div 태그 #mob-write-container css */
  const MobWriteContainerDiv = styled.div`
    @media (min-width: 569px) {
        display: none;
    }
    @media (max-width: 568px) {
        padding: 0 40px;
        padding-top: 120px;
    }
  `;

  /** h2 태그 #mob-write-container .title css */
  const MobWriteContainerH2H2 = styled.h2`
    @media (max-width: 568px) {
        font-size: 24px;
        font-family: "Min Sans-bold";
        padding-bottom: 20px;
        letter-spacing: -0.03em;
        color: #050409;
        line-height: 40px;
        text-align: center;
        padding-top: 36px;
    }
  `;

  /** div 태그 .mob-write-img css */
  const MobWriteImgDiv = styled.div`
    @media (max-width: 568px) {
        text-align: center;
    }
  `;

  /** button 태그 .edit-next css */
  const EditNextButton = styled.button`
    @media (max-width: 568px) {
        width: 100%;
        background-color: #22222A;
        color: #fff;
        font-size: 17px;
        font-family: "Min Sans-Medium";
        letter-spacing: -0.3px;
        margin: 50px 0px 120px 0px;
        height: 70px;
        border-radius: 20px;
        outline: none;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: #303039;
        }
    }
    
  `;

  /** div 태그 #wrap css */
  const WrapDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
    height: 100vh;
    @media (max-width: 1400px) {
        display: grid;
        grid-template-columns: 1.5fr 4fr;
        grid-template-rows: auto;
        height: 100vh;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
  `;