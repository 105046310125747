import { Link, useNavigate } from 'react-router-dom';
import eye10 from '../css/images/eye-10.svg';
import shoppingCart11 from '../css/images/shopping-cart-11.svg';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import axios from 'axios';
import illustDefault from '../css/images/illustDefault.png';
import mainCss from '../css/main.module.css';
import URL from '../constants/url';
import { apiServerState } from '../constants/recoilData';
import { getCookie } from '../hooks/Cookie';

const IllustrationBestBoard = () => {

  const apiServer = useRecoilValue(apiServerState);
  const navigate = useNavigate();
  const token = getCookie("token");

  const [illustList, setIllustList] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  /** 최초 인기 일러스트 리스트 호출 api 연동 코드 */
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "authorization": token,
      }
    };
  
    const update = () => {
      const url = apiServer + "/asiac_main/popular_ai_imgs";
  
      const illustration = async () => {
        await axios
          .get(url, config)
          .then((response) => {
            if (response?.data?.success) {
              setIllustList(response?.data?.data?.pictureList);
              setIsMounted(true);
            } else {
              const msg = response?.data?.custMsg;
              if (msg === "router err") {
                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
              } else {
                alert(msg);
              }
              navigate(URL.MAIN);
              console.log("일러스트 리스트 로딩 실패!");
            }
          })
          .catch(e => {  // API 호출이 실패한 경우
            alert(e?.message);
            console.log(e);
            navigate(URL?.ERROR, {state: {msg: e?.message}});
          });
      };
  
      illustration();
      
    };
    update();
  }, []);

  const noImage = (e) => {
    e.target.src = illustDefault
  }

  return (
    <>
      {isMounted === false ? <></> : <ContainerLDiv>
        <div className={mainCss.container + " container"} >
          <RankIlluSection>
            <RankTitleDiv>
              <H2>소장 가치 100%</H2>
              <H1>아이작 인기 일러스트</H1>
            </RankTitleDiv>
            <MobileDiv>
              <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              {illustList && illustList.map((illust) => {return (<ColDiv key={"illust" + illust?.id}>
                  <LinkDefault to={`/list/illust/${illust?.id}`}>
                    <IlluViewDiv className="p-3">
                      <IlluViewImg src={illust?.imgUrl !== null ? illust?.imgUrl : illustDefault} onError={noImage} fetchpriority="low" alt="illustration" />
                      {(String(illust?.title)).length < 15 ? <NovelViewTitleDiv>{(String(illust?.title)).trim()}</NovelViewTitleDiv> : 
                      <NovelViewTitleDiv>{((String(illust?.title)).trim()).substring(0, 14) + "..."}</NovelViewTitleDiv>}
                      <NovelViewSubDiv>
                        <span>{illust?.nickname} 작가</span>
                      </NovelViewSubDiv>
                      <IlluViewSubDiv>
                        <span>
                          <img src={eye10} alt="eye10" />
                        </span>
                        <IlluViewCountSpan>{parseInt(illust?.view) >= 1000 ? (parseInt(illust?.view) / 1000).toFixed(1) + "k" : parseInt(illust?.view) }</IlluViewCountSpan>
                        <span style={{marginLeft : "10px"}}>
                          <img src={shoppingCart11} alt="shoppingCart11" />
                        </span>
                        <IlluSellCountSpan>{illust?.sold}</IlluSellCountSpan>
                        <IlluPriceSpan>{illust?.price}c</IlluPriceSpan>
                      </IlluViewSubDiv>
                    </IlluViewDiv>
                  </LinkDefault>
                </ColDiv>)})}
              </div>
            </MobileDiv>
          </RankIlluSection>
        </div>
      </ContainerLDiv>}
    </>
  );
};

export default IllustrationBestBoard;

/**div 태그 mobile css */
const MobileDiv = styled.div`
  @media (min-width: 768px) and (max-width: 1400px) {
    display: block;
  }
`;

/** a 태그 디폴트 css */
const LinkDefault = styled(Link)`
  text-decoration: none;
`;

/** h1 태그 디폴트 css */
const H1 = styled.h1`
  font-size: 46px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
`;

/** h2 태그 디폴트 css */
const H2 = styled.h2`
  font-size: 20px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
`;

/** div 태그 .col css */
const ColDiv = styled.div`
  padding: 0px;
  @media (min-width: 1200px) and (max-width: 1400px) {
    padding-left: 2.5%;
  }
  @media (min-width: 1100px) and (max-width: 1199px) {
    padding-left: 1.5%;
  }
  @media (min-width: 992px) and (max-width: 1100px) {
    padding-left: 0%;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding-left: 5%;
  }
`;

/** div 태그 .novel-view-title css */
const NovelViewTitleDiv = styled.div`
  font-size: 17px;
  font-family: "Min Sans-Medium";
  color: #050409;
  letter-spacing: -0.3px;
  margin-top: 12px;
`;

/** div 태그 .novel-view-sub css */
const NovelViewSubDiv = styled.div`
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #9b9b9d;
  letter-spacing: -0.3px;
`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
  padding-top: 120px;
  @media (min-width: 768px) and (max-width: 1400px) {
    padding-top: 60px;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    padding-top: 60px;
  }
`;

/** div 태그 .container-l css */
const ContainerLDiv = styled.div`
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
`;

/** section 태그 .rank-illu css */
const RankIlluSection = styled.section`
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
`;

/** div 태그 .illu-view css */
const IlluViewDiv = styled.div`
  width: 330px;
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1400px) {
    width: 260px;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    width: 100%;
    height: 360px;
  }
`;

/** img 태그 .illu-view > img css */
const IlluViewImg = styled.img`
  width: 100%;
  height: 80%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
`;

/** div 태그 .illu-view-sub css */
const IlluViewSubDiv = styled.div`
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #050409;
  letter-spacing: -0.3px;
  margin-top: 4px;
`;

/** span 태그 .illu-view-count css */
const IlluViewCountSpan = styled.span`
  margin: 0px 3px;
`;

/** span 태그 .illu-sell-count css */
const IlluSellCountSpan = styled.span`
  margin: 0px 2px;
`;

/** span 태그 .illu-price css */
const IlluPriceSpan = styled.span`
  float: right;
  font-size: 20px;
  color: #ff562c;
  font-family: "Min Sans-bold";
`;
