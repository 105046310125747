import styled from "@emotion/styled";
import aiEiftorCss from '../../css/ai_editor1.module.css'
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import URL from "../../constants/url";
import { apiServerState } from "../../constants/recoilData";
import Swal from "sweetalert2";



// ai 편집기 우측 사이드 컴포넌트
const AiEditorRightAside = ({textData, setTextData, isUpdateBool, setIsUpdateBool, isType, setIsExtendCheck}) => {

    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    //const [isMountedGrammar, setIsMountedGrammar] = useState(true);
    const [isMountedExtend, setIsMountedExtend] = useState(true);
    const [isMountedSummary, setIsMountedSummary] = useState(true);
    const [isMountedExchange, setIsMountedExchange] = useState(true);
    const [isMountedExchange2, setIsMountedExchange2] = useState(true);

    const [mountText, setMountText] = useState("맞춤법 점검");
    const [userInfo, setUserInfo] = useState(null);

    // isUpdateBool 변경시 유저정보 업데이트(api 코인 차감시 업데이트 용도)
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success ) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        removeCookie("token");
                        removeCookieOption("token", {path : "/"});
                        localStorage.removeItem("pageData");
                        localStorage.removeItem("pageDataSynop");
                        localStorage.removeItem("categoryNotice");
                        localStorage.removeItem("categoryPay");
                        localStorage.removeItem("myPicturePageData");
                        localStorage.removeItem("rowCnt");
                        window.localStorage.clear();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        removeCookie("token");
                        removeCookieOption("token", {path : "/"});
                        localStorage.removeItem("pageData");
                        localStorage.removeItem("pageDataSynop");
                        localStorage.removeItem("categoryNotice");
                        localStorage.removeItem("categoryPay");
                        localStorage.removeItem("myPicturePageData");
                        localStorage.removeItem("rowCnt");
                        window.localStorage.clear();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool])
    

    // 글자수 늘리기 버튼
    const onClickEditExtend = () => {
        if (isType) {
            setMountText("글자 수 늘리기");
            if (textData.length > 2500 || textData.length < 1500) {
                Swal.fire({
                    title : "안내",
                    text: "글자 수 늘리기는 1500자 이상 2500자 이하만 가능합니다",
                    confirmButtonColor: "#FF5F37",
                    confirmButtonText: "확인",
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        console.log("글자수 1500자 이상 2500자 이하 범위 이탈");
                        setIsUpdateBool(!isUpdateBool);
                        setIsExtendCheck(false); 
                    } 
                });
            } else {
                Swal.fire({
                    title : "요금 안내",
                    text: "글자수 늘이기는 1회 당 3000코인이 소모됩니다",
                    showCancelButton: true,
                    confirmButtonColor: "#FF5F37",
                    cancelButtonColor: "#bdbdbd",
                    cancelButtonText: "취소",
                    confirmButtonText: "사용",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        if (textData !== "") {
                            if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                            (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 3000 || userInfo?.coinAuth === "unlimited") {
                                setIsUpdateBool(!isUpdateBool);
                                //setIsMountedGrammar(false);
                                setIsMountedExtend(false);
                                setIsMountedSummary(false);
                                setIsMountedExchange(false);
                                setIsMountedExchange2(false);
                                setIsExtendCheck(true);
                                let form = {"text": textData};
                                
                                const config = {
                                    headers: {
                                        "Content-Type": "application/json",
                        
                                        "authorization": token,
                                    }
                                };
                        
                                axios
                                    .post(apiServer + "/api/asiac_nestyle/edit/extend", form, config)
                                    .then((response) => {
                                        if (response?.data?.success ) {
                                            setTextData(response.data.data.modified_text);
                                            //setIsMountedGrammar(true);
                                            setIsMountedExtend(true);
                                            setIsMountedSummary(true);
                                            setIsMountedExchange(true);
                                            setIsMountedExchange2(true);
                                            setIsExtendCheck(false);
                                            setIsUpdateBool(!isUpdateBool);
                                        } else {
                                            const msg = response?.data?.custMsg;
                                            if (msg.includes("timeout")) {
                                                alert("요청 시간이 초과되었습니다.");
                                            } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                removeCookie("token");
                                                removeCookieOption("token", {path : "/"});
                                                localStorage.removeItem("pageData");
                                                localStorage.removeItem("pageDataSynop");
                                                localStorage.removeItem("categoryNotice");
                                                localStorage.removeItem("categoryPay");
                                                localStorage.removeItem("myPicturePageData");
                                                localStorage.removeItem("rowCnt");
                                                window.localStorage.clear();
                                                const token = getCookie("token");
                                                if (token !== undefined) {
                                                    removeCookieOption("token", {path : "/"});
                                                    navigate(URL.MAIN);
                                                } else {
                                                    navigate(URL.MAIN);
                                                }
                                            } else if (msg === "need more coin") {
                                                alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                            } else if (msg === "axios err" || msg === "ai generator err") {
                                                alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                            } else if (msg === "router err") {
                                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                            } else {
                                                alert(msg);
                                            }
                                            console.log(msg);
                                            //setIsMountedGrammar(true);
                                            setIsMountedExtend(true);
                                            setIsMountedSummary(true);
                                            setIsMountedExchange(true);
                                            setIsMountedExchange2(true);
                                            setIsUpdateBool(!isUpdateBool);
                                            setIsExtendCheck(false);
                                        }
                                    })
                                    .catch((e) => {
                                        alert(e.message);
                                        console.log(e);
                                        setIsMountedExtend(true);
                                        setIsMountedSummary(true);
                                        setIsMountedExchange(true);
                                        setIsMountedExchange2(true);
                                        setIsExtendCheck(false);
                                    });
                            } else {
                                Swal.fire({
                                    title : "작품 구매",
                                    text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonColor: "#FF5F37",
                                    cancelButtonColor: "#bdbdbd",
                                    cancelButtonText: "취소",
                                    confirmButtonText: "코인 충전",
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.confirm) {
                                        navigate(URL.LIST_CHARGE_SHOP);
                                    } 
                                });
                            }
                        } else {
                            Swal.fire({
                                title : "안내",
                                text: "내용이 없습니다. 내용을 입력해주세요.",
                                confirmButtonColor: "#FF5F37",
                                confirmButtonText: "확인",
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.confirm) {
                                    console.log("안내");
                                } 
                            });
                        }
                    } 
                });
            }
        } else {
            Swal.fire({
                title : "안내",
                text: "파일을 선택하거나 편집보관함을 이용하셔야 사용할수 있습니다.",
                confirmButtonColor: "#FF5F37",
                confirmButtonText: "확인",
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    console.log("안내");
                } 
            });
        }
        
    }

    // 스토리 재생성 버튼(모달 오픈)
    const onClickEditSummary = () => {
        const confirmationMessage = "스토리 재생성 업로드 방식을 선택해주세요.";
        Swal.fire({
            title: "스토리 재생성",
            text: confirmationMessage,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#ff5f37",
            cancelButtonText: "내 글 리스트에서 선택",
            confirmButtonText: "파일 업로드",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
              // 모달 오픈
              var reuploadModal = document.getElementById("reupload");
              reuploadModal.style.display = "block";
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              var relistModal = document.getElementById("relist");
              relistModal.style.display = "block";
            }
        });
        
    }
    
    // 소설 -> 대본 문체 변환 버튼
    const onClickExchange = () => {
        if (isType) {
            setMountText("소설 ▶ 대본 문체 변환");
            if (textData.length > 4000) {
                Swal.fire({
                    title : "안내",
                    text: "문체 변경은 4000자 이하만 가능합니다",
                    confirmButtonColor: "#FF5F37",
                    confirmButtonText: "확인",
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        console.log("글자수 4000자 이하 범위 이탈");
                        setIsUpdateBool(!isUpdateBool);
                        setIsExtendCheck(false);
                    } 
                });
            } else {
                Swal.fire({
                    title : "요금 안내",
                    text: "문체변환은 1회 당 2000코인이 소모됩니다",
                    showCancelButton: true,
                    confirmButtonColor: "#FF5F37",
                    cancelButtonColor: "#bdbdbd",
                    cancelButtonText: "취소",
                    confirmButtonText: "사용",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        if (textData !== "") {
                            if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                            (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 2000 || userInfo?.coinAuth === "unlimited") {
                                setIsUpdateBool(!isUpdateBool);
                                //setIsMountedGrammar(false);
                                setIsMountedExtend(false);
                                setIsMountedSummary(false);
                                setIsMountedExchange(false);
                                setIsMountedExchange2(false);
                                setIsExtendCheck(true);
                                let form = {"text": textData};
                                
                                const config = {
                                    headers: {
                                        "Content-Type": "application/json",
                        
                                        "authorization": token,
                                    }
                                };
                        
                                axios
                                    .post(apiServer + "/api/asiac_nestyle/edit/exchange", form, config)
                                    .then((response) => {
                                        if (response?.data?.success ) {
                                            setTextData(response.data.data.text);
                                            //setIsMountedGrammar(true);
                                            setIsMountedExtend(true);
                                            setIsMountedSummary(true);
                                            setIsMountedExchange(true);
                                            setIsMountedExchange2(true);
                                            setIsExtendCheck(false);
                                            setIsUpdateBool(!isUpdateBool);
                                        } else {
                                            const msg = response?.data?.custMsg;
                                            if (msg.includes("timeout")) {
                                                alert("요청 시간이 초과되었습니다.");
                                            } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                removeCookie("token");
                                                removeCookieOption("token", {path : "/"});
                                                localStorage.removeItem("pageData");
                                                localStorage.removeItem("pageDataSynop");
                                                localStorage.removeItem("categoryNotice");
                                                localStorage.removeItem("categoryPay");
                                                localStorage.removeItem("myPicturePageData");
                                                localStorage.removeItem("rowCnt");
                                                window.localStorage.clear();
                                                const token = getCookie("token");
                                                if (token !== undefined) {
                                                    removeCookieOption("token", {path : "/"});
                                                    navigate(URL.MAIN);
                                                } else {
                                                    navigate(URL.MAIN);
                                                }
                                            } else if (msg === "need more coin") {
                                                alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                            } else if (msg === "axios err" || msg === "ai generator err") {
                                                alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                            } else if (msg === "router err") {
                                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                            } else {
                                                alert(msg);
                                            }
                                            console.log(msg);
                                            //setIsMountedGrammar(true);
                                            setIsMountedExtend(true);
                                            setIsMountedSummary(true);
                                            setIsMountedExchange(true);
                                            setIsMountedExchange2(true);
                                            setIsExtendCheck(false);
                                            setIsUpdateBool(!isUpdateBool);
                                        }
                                    })
                                    .catch((e) => {
                                        alert(e.message);
                                        console.log(e);
                                        setIsMountedExtend(true);
                                        setIsMountedSummary(true);
                                        setIsMountedExchange(true);
                                        setIsMountedExchange2(true);
                                        setIsExtendCheck(false);
                                    });
                            } else {
                                Swal.fire({
                                    title : "작품 구매",
                                    text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonColor: "#FF5F37",
                                    cancelButtonColor: "#bdbdbd",
                                    cancelButtonText: "취소",
                                    confirmButtonText: "코인 충전",
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.confirm) {
                                        navigate(URL.LIST_CHARGE_SHOP);
                                    } 
                                });
                            }
                        } else {
                            Swal.fire({
                                title : "안내",
                                text: "내용이 없습니다. 내용을 입력해주세요.",
                                confirmButtonColor: "#FF5F37",
                                confirmButtonText: "확인",
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.confirm) {
                                    console.log("안내");
                                } 
                            });
                        }
                    } 
                });
            }
        } else {
            Swal.fire({
                title : "안내",
                text: "파일을 선택하거나 편집보관함을 이용하셔야 사용할수 있습니다.",
                confirmButtonColor: "#FF5F37",
                confirmButtonText: "확인",
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    console.log("안내");
                } 
            });
        }
        
    }

    // 대본 -> 소설 문체 변환 버튼
    const onClickExchange2 = () => {
        if (isType) {
            setMountText("대본 ▶ 소설 문체 변환");
            if (textData.length > 4000) {
                Swal.fire({
                    title : "안내",
                    text: "문체 변경은 4000자 이하만 가능합니다",
                    confirmButtonColor: "#FF5F37",
                    confirmButtonText: "확인",
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        console.log("글자수 4000자 이하 범위 이탈");
                        setIsUpdateBool(!isUpdateBool);
                    } 
                });
            } else {
                Swal.fire({
                    title : "요금 안내",
                    text: "문체변환은 1회 당 2000코인이 소모됩니다",
                    showCancelButton: true,
                    confirmButtonColor: "#FF5F37",
                    cancelButtonColor: "#bdbdbd",
                    cancelButtonText: "취소",
                    confirmButtonText: "사용",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        if (textData !== "") {
                            if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                            (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 2000 || userInfo?.coinAuth === "unlimited") {
                                setIsUpdateBool(!isUpdateBool);
                                //setIsMountedGrammar(false);
                                setIsMountedExtend(false);
                                setIsMountedSummary(false);
                                setIsMountedExchange(false);
                                setIsMountedExchange2(false);
                                setIsExtendCheck(true);
                                let form = {"text": textData};
                                
                                const config = {
                                    headers: {
                                        "Content-Type": "application/json",
                        
                                        "authorization": token,
                                    }
                                };
                        
                                axios
                                    .post(apiServer + "/api/asiac_nestyle/edit/exchange_to_story", form, config)
                                    .then((response) => {
                                        if (response?.data?.success ) {
                                            setTextData(response.data.data.text);
                                            //setIsMountedGrammar(true);
                                            setIsMountedExtend(true);
                                            setIsMountedSummary(true);
                                            setIsMountedExchange(true);
                                            setIsMountedExchange2(true);
                                            setIsExtendCheck(false);
                                            setIsUpdateBool(!isUpdateBool);
                                        } else {
                                            const msg = response?.data?.custMsg;
                                            if (msg.includes("timeout")) {
                                                alert("요청 시간이 초과되었습니다.");
                                            } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                removeCookie("token");
                                                removeCookieOption("token", {path : "/"});
                                                localStorage.removeItem("pageData");
                                                localStorage.removeItem("pageDataSynop");
                                                localStorage.removeItem("categoryNotice");
                                                localStorage.removeItem("categoryPay");
                                                localStorage.removeItem("myPicturePageData");
                                                localStorage.removeItem("rowCnt");
                                                window.localStorage.clear();
                                                const token = getCookie("token");
                                                if (token !== undefined) {
                                                    removeCookieOption("token", {path : "/"});
                                                    navigate(URL.MAIN);
                                                } else {
                                                    navigate(URL.MAIN);
                                                }
                                            } else if (msg === "need more coin") {
                                                alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                            } else if (msg === "axios err" || msg === "ai generator err") {
                                                alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                            } else if (msg === "router err") {
                                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                            } else {
                                                alert(msg);
                                            }
                                            console.log(msg);
                                            //setIsMountedGrammar(true);
                                            setIsMountedExtend(true);
                                            setIsMountedSummary(true);
                                            setIsMountedExchange(true);
                                            setIsMountedExchange2(true);
                                            setIsExtendCheck(false);
                                            setIsUpdateBool(!isUpdateBool);
                                        }
                                    })
                                    .catch((e) => {
                                        alert(e.message);
                                        console.log(e);
                                        setIsMountedExtend(true);
                                        setIsMountedSummary(true);
                                        setIsMountedExchange(true);
                                        setIsMountedExchange2(true);
                                        setIsExtendCheck(false);
                                    });
                            } else {
                                Swal.fire({
                                    title : "작품 구매",
                                    text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonColor: "#FF5F37",
                                    cancelButtonColor: "#bdbdbd",
                                    cancelButtonText: "취소",
                                    confirmButtonText: "코인 충전",
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.confirm) {
                                        navigate(URL.LIST_CHARGE_SHOP);
                                    } 
                                });
                            }
                        } else {
                            Swal.fire({
                                title : "안내",
                                text: "내용이 없습니다. 내용을 입력해주세요.",
                                confirmButtonColor: "#FF5F37",
                                confirmButtonText: "확인",
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.confirm) {
                                    console.log("안내");
                                } 
                            });
                        }
                    } 
                });
            }
        } else {
            Swal.fire({
                title : "안내",
                text: "파일을 선택하거나 편집보관함을 이용하셔야 사용할수 있습니다.",
                confirmButtonColor: "#FF5F37",
                confirmButtonText: "확인",
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    console.log("안내");
                } 
            });
        }
        
    }
    

    return (
        <>
            <RightAsideAside className={aiEiftorCss.rightAside + " rightAside"}>
                <ContentDiv className={aiEiftorCss.content + " content"}>
                    <TitleDiv className={aiEiftorCss.title + " title"}>
                        아이작에서 제공하는<br/>AI 편집 도구를 이용해보세요
                    </TitleDiv>
                    <div className={aiEiftorCss.userAction + " userAction"}>
                        <UserActionUlUl>
                            {isMountedExtend === true ?<div onClick={onClickEditExtend}><UserActionLiLi  >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 5.25V3H15V5.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    <path d="M6.75 15H11.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    <path d="M9 3V15" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                </svg>
                                <NameP className={aiEiftorCss.name + " name"}>글자 수 늘리기</NameP>
                            </UserActionLiLi></div> : <div><UserActionLiLi>
                                {mountText === "글자 수 늘리기" ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 5.25V3H15V5.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    <path d="M6.75 15H11.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    <path d="M9 3V15" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={"0.3"}>
                                    <path d="M3 5.25V3H15V5.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    <path d="M6.75 15H11.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    <path d="M9 3V15" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                </svg>}
                                {mountText === "글자 수 늘리기" ? <NameP className={aiEiftorCss.name + " name"} >생성 중...</NameP> :
                                <NameP className={aiEiftorCss.name + " name"} style={{opacity : "0.3"}}>글자 수 늘리기</NameP>}
                            </UserActionLiLi></div>}
                            {userInfo?.authorKind !== "corp_business" ? <></> : isMountedSummary === true ? <div onClick={onClickEditSummary}><UserActionLiLi>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath={"url(#clip0_1_7845)"}>
                                        <path d="M0.75 3V7.5H5.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M2.6325 11.2498C3.1188 12.6301 4.0405 13.815 5.25874 14.6259C6.47698 15.4368 7.92576 15.8298 9.38679 15.7457C10.8478 15.6616 12.2419 15.105 13.3591 14.1597C14.4763 13.2143 15.2559 11.9316 15.5807 10.5046C15.9054 9.07765 15.7576 7.58381 15.1595 6.24816C14.5614 4.91252 13.5454 3.80744 12.2646 3.09941C10.9839 2.39139 9.50768 2.11879 8.05851 2.32268C6.60934 2.52657 5.26568 3.19591 4.23 4.22984L0.75 7.49984" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7845">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <NameP className={aiEiftorCss.name + " name"}>스토리 재생성</NameP>
                            </UserActionLiLi></div> : <div><UserActionLiLi>
                                {mountText === "스토리 재생성" ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath={"url(#clip0_1_7845)"}>
                                        <path d="M0.75 3V7.5H5.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M2.6325 11.2498C3.1188 12.6301 4.0405 13.815 5.25874 14.6259C6.47698 15.4368 7.92576 15.8298 9.38679 15.7457C10.8478 15.6616 12.2419 15.105 13.3591 14.1597C14.4763 13.2143 15.2559 11.9316 15.5807 10.5046C15.9054 9.07765 15.7576 7.58381 15.1595 6.24816C14.5614 4.91252 13.5454 3.80744 12.2646 3.09941C10.9839 2.39139 9.50768 2.11879 8.05851 2.32268C6.60934 2.52657 5.26568 3.19591 4.23 4.22984L0.75 7.49984" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7845">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={"0.3"}>
                                    <g clipPath={"url(#clip0_1_7845)"}>
                                        <path d="M0.75 3V7.5H5.25" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M2.6325 11.2498C3.1188 12.6301 4.0405 13.815 5.25874 14.6259C6.47698 15.4368 7.92576 15.8298 9.38679 15.7457C10.8478 15.6616 12.2419 15.105 13.3591 14.1597C14.4763 13.2143 15.2559 11.9316 15.5807 10.5046C15.9054 9.07765 15.7576 7.58381 15.1595 6.24816C14.5614 4.91252 13.5454 3.80744 12.2646 3.09941C10.9839 2.39139 9.50768 2.11879 8.05851 2.32268C6.60934 2.52657 5.26568 3.19591 4.23 4.22984L0.75 7.49984" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7845">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>}
                                {mountText === "스토리 재생성" ? <NameP className={aiEiftorCss.name + " name"} >생성 중...</NameP> :
                                <NameP className={aiEiftorCss.name + " name"} style={{opacity : "0.3"}}>스토리 재생성</NameP>}
                            </UserActionLiLi></div>}
                        </UserActionUlUl>
                    </div>
                    {userInfo?.authorKind !== "corp_business" ? <></> : <TitleDiv className={aiEiftorCss.title + " title"}>
                        아이작 AI로 간편하게 <br/>
                        문체를 변경해보세요
                    </TitleDiv>}
                    {userInfo?.authorKind !== "corp_business" ? <></> : <div className={aiEiftorCss.userAction + " userAction"}>
                        <UserActionUlUl>
                            {isMountedExchange === true ?<div onClick={onClickExchange}><UserActionLiLi>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath={"url(#clip0_1_7840)"}>
                                        <path d="M9 14.25L14.25 9L16.5 11.25L11.25 16.5L9 14.25Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M13.5 9.75L12.375 4.125L1.5 1.5L4.125 12.375L9.75 13.5L13.5 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M1.5 1.5L7.1895 7.1895" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M8.25 9.75C9.07843 9.75 9.75 9.07843 9.75 8.25C9.75 7.42157 9.07843 6.75 8.25 6.75C7.42157 6.75 6.75 7.42157 6.75 8.25C6.75 9.07843 7.42157 9.75 8.25 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7840">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <NameP className={aiEiftorCss.name + " name"}>소설 ▶ 대본 문체 변환</NameP>
                            </UserActionLiLi></div> : <div><UserActionLiLi>
                                {mountText === "소설 ▶ 대본 문체 변환" ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath={"url(#clip0_1_7840)"}>
                                        <path d="M9 14.25L14.25 9L16.5 11.25L11.25 16.5L9 14.25Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M13.5 9.75L12.375 4.125L1.5 1.5L4.125 12.375L9.75 13.5L13.5 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M1.5 1.5L7.1895 7.1895" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M8.25 9.75C9.07843 9.75 9.75 9.07843 9.75 8.25C9.75 7.42157 9.07843 6.75 8.25 6.75C7.42157 6.75 6.75 7.42157 6.75 8.25C6.75 9.07843 7.42157 9.75 8.25 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7840">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={"0.3"}>
                                    <g clipPath={"url(#clip0_1_7840)"}>
                                        <path d="M9 14.25L14.25 9L16.5 11.25L11.25 16.5L9 14.25Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M13.5 9.75L12.375 4.125L1.5 1.5L4.125 12.375L9.75 13.5L13.5 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M1.5 1.5L7.1895 7.1895" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M8.25 9.75C9.07843 9.75 9.75 9.07843 9.75 8.25C9.75 7.42157 9.07843 6.75 8.25 6.75C7.42157 6.75 6.75 7.42157 6.75 8.25C6.75 9.07843 7.42157 9.75 8.25 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7840">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>}
                                {mountText === "소설 ▶ 대본 문체 변환" ? <NameP className={aiEiftorCss.name + " name"} >생성 중...</NameP> :
                                <NameP className={aiEiftorCss.name + " name"} style={{opacity : "0.3"}}>소설 ▶ 대본 문체 변환</NameP>}
                            </UserActionLiLi></div> } 
                            {isMountedExchange2 === true ?<div onClick={onClickExchange2}><UserActionLiLi>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath={"url(#clip0_1_7840)"}>
                                        <path d="M9 14.25L14.25 9L16.5 11.25L11.25 16.5L9 14.25Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M13.5 9.75L12.375 4.125L1.5 1.5L4.125 12.375L9.75 13.5L13.5 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M1.5 1.5L7.1895 7.1895" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M8.25 9.75C9.07843 9.75 9.75 9.07843 9.75 8.25C9.75 7.42157 9.07843 6.75 8.25 6.75C7.42157 6.75 6.75 7.42157 6.75 8.25C6.75 9.07843 7.42157 9.75 8.25 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7840">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <NameP className={aiEiftorCss.name + " name"}>대본 ▶ 소설 문체 변환</NameP>
                            </UserActionLiLi></div> : <div><UserActionLiLi>
                                {mountText === "대본 ▶ 소설 문체 변환" ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath={"url(#clip0_1_7840)"}>
                                        <path d="M9 14.25L14.25 9L16.5 11.25L11.25 16.5L9 14.25Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M13.5 9.75L12.375 4.125L1.5 1.5L4.125 12.375L9.75 13.5L13.5 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M1.5 1.5L7.1895 7.1895" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M8.25 9.75C9.07843 9.75 9.75 9.07843 9.75 8.25C9.75 7.42157 9.07843 6.75 8.25 6.75C7.42157 6.75 6.75 7.42157 6.75 8.25C6.75 9.07843 7.42157 9.75 8.25 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7840">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={"0.3"}>
                                    <g clipPath={"url(#clip0_1_7840)"}>
                                        <path d="M9 14.25L14.25 9L16.5 11.25L11.25 16.5L9 14.25Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M13.5 9.75L12.375 4.125L1.5 1.5L4.125 12.375L9.75 13.5L13.5 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M1.5 1.5L7.1895 7.1895" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        <path d="M8.25 9.75C9.07843 9.75 9.75 9.07843 9.75 8.25C9.75 7.42157 9.07843 6.75 8.25 6.75C7.42157 6.75 6.75 7.42157 6.75 8.25C6.75 9.07843 7.42157 9.75 8.25 9.75Z" stroke={"white"} strokeWidth={"2"} strokeLinecap={"round"} strokeLinejoin={"round"} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_7840">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>}
                                {mountText === "대본 ▶ 소설 문체 변환" ? <NameP className={aiEiftorCss.name + " name"} >생성 중...</NameP> :
                                <NameP className={aiEiftorCss.name + " name"} style={{opacity : "0.3"}}>대본 ▶ 소설 문체 변환</NameP>}
                            </UserActionLiLi></div> } 
                        </UserActionUlUl>
                    </div>}
                </ContentDiv>
            </RightAsideAside>
        </>
    );
};
export default AiEditorRightAside;

/** aside 태그 .rightAside css */
const RightAsideAside = styled.aside`
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
    @media(max-width:1400px) {
        min-width: 320px;
    }
    @media(min-width:100px) and (max-width:768px) {
        display: none;
    }
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding: 120px 40px 40px 40px;
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    color: #8F8F99;
    font-family: "Min Sans-Light";
    font-size: 17px;
    letter-spacing: -0.3px;
    line-height: 1.5;
`;

/** ul 태그 .userAction ul css */
const UserActionUlUl = styled.ul`
    margin-top: 20px;
    padding-left: 0px;
`;

/** li 태그 .userAction li css */
const UserActionLiLi = styled.li`
    list-style: none;
    padding:17px 20px;
    border: 1px solid #464651;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 12px;
`;

/** p 태그 .name css */
const NameP = styled.p`
    font-size: 15px;
    color: #fff;
    letter-spacing: -0.03em;
    margin-bottom: 0;
`;