import NovelDetail from "../../components/novelDetail/NovelDetail";
import URL from "../../constants/url";
import { Route, Routes} from "react-router";
import 'bootstrap/dist/css/bootstrap.min.css';
import NovelDetailSession from "../../components/novelDetail/NovelDetailSession";



const AizacNovelDetail = () => {

    

    return (
        <>
            <div id="wrap">
                <Routes>
                    <Route path={URL.NOVEL_DETAIL} element={<NovelDetail/>}/>
                    <Route path={URL.NOVEL_SESSION} element={<NovelDetailSession/>}/>
                </Routes>
            </div>
        </>
    );
}

export default AizacNovelDetail;