import { useNavigate, useParams } from "react-router";
import noticeCss from '../../css/noticeTerms.module.css';
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
import { useEffect, useState } from "react";
import axios from "axios";
import URL from "../../constants/url";
import styled from "@emotion/styled";
import "../../css/notice_custom_img.css";
import $ from "jquery";
import defaultImg from "../../css/images/illustDefault.png"


const NoticeDetail = () => {

    const params = useParams();
    const [noticeId,] = useState(params.noticeId);

    const apiServer = useRecoilValue(apiServerState);
    const [notice, setNotice] = useState(null);
    const [isMountedNotice, setIsMountedNotice] = useState(false);
    const navigate = useNavigate();

    // 최초 랜더링시 api 호출 셋팅
    useEffect(() => {

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
    
        const update = () => {

            let urlNotice = apiServer + "/api/aizac_notice/get_a_notice?id=" + noticeId;
        
            const urlCall = async () => {
                await axios
                    .get(urlNotice, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            let copy = response.data.data.notice;
                            const noticeData = response?.data?.data?.notice?.content;
                            const noticeData2 = String(noticeData).replace(/<iframe/gi, `<iframe sandbox `);
                            const copy2= {...copy, "content" : noticeData2};
                            setNotice(copy2);
                            setIsMountedNotice(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid data") {
                                alert("질못된 접근입니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };
        
            urlCall();
        
        };
        update();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, []);
    
    useEffect(() => {
        //$(".ql-video").attr("autoplay", "false");
        $(".ql-video").attr("sandbox");
        var allVideos = $('video');
        
        // 선택된 video 태그에 대해 자동 재생 속성을 제거
        allVideos.each(function() {
            $(this).removeAttr('autoplay');
        });
    }, [notice])

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return (
        <>
            <div className={noticeCss.container + " container"}>
                <section className={noticeCss.rank_veteran + " rank_veteran rank-veteran"}>
                    {isMountedNotice === true ? <section className={noticeCss.synop_select + " synop_select synop-select"}>
                        <div className={noticeCss.rank_title + " rank_title rank-title"}>
                            <h1>공지사항</h1>
                        </div>
                        <div className={noticeCss.notice_detail + " notice_detail notice-detail"}>
                            <h2 className={noticeCss.title + " title"} style={{wordBreak : "break-all", marginRight : "10px"}}>{notice?.title}</h2>
                            <h4 className={noticeCss.date + " date"}>{notice?.createdAt?.substring(0, 10)}</h4>
                        </div>
                        <hr className={noticeCss.notice_hr + " notice_hr notice-hr"} />
                        <div className={noticeCss.notice_detail_content + " notice_detail_content notice-detail-content"}>
                            <div className={noticeCss.notice_detail_img + " notice_detail_img notice-detail-img"}>
                                {notice?.imgUrl === null ? <></> : notice?.imgUrl !== "" ?<img src={notice?.imgUrl} alt={`notice_${notice?.id}`} onError={noImage}/> : <></>}
                            </div>
                            <NoticeDetailTextDiv className={noticeCss.notice_detail_text + " notice_detail_text notice-detail-text"} style={{whiteSpace : "pre-line", wordBreak: "break-all"}} dangerouslySetInnerHTML={{
                                __html: notice && notice?.content,
                            }} />
                        </div>
                    </section> : <section className={noticeCss.synop_select + " synop_select synop-select"}>
                        <div className={noticeCss.rank_title + " rank_title rank-title"}>
                            <h1>공지사항</h1>
                        </div>
                        <div className={noticeCss.notice_detail + " notice_detail notice-detail"}>
                            <h2 className={noticeCss.title + " title"}>공지사항이 없습니다.</h2>
                            <h4 className={noticeCss.date + " date"}> - </h4>
                        </div>
                        <hr className={noticeCss.notice_hr + " notice_hr notice-hr"} />
                        <div className={noticeCss.notice_detail_content + " notice_detail_content notice-detail-content"}>
                            <div className={noticeCss.notice_detail_img + " notice_detail_img notice-detail-img"}>
                                {/*<img src={test1Img} />*/}
                            </div>
                            <div className={noticeCss.notice_detail_text + " notice_detail_text notice-detail-text"}>
                                공지사항이 없습니다.
                            </div>
                        </div>
                    </section>}
                </section>
            </div>
        </>
    );
};
export default NoticeDetail;

/** div 태그 .notice-detail-text css */
const NoticeDetailTextDiv = styled.div`
    white-space: pre-line;
    word-break: break-all;
    & p {
        word-break: break-all;
    }
`;