
import styled from "@emotion/styled";
//import CreateNovel from "components/novelCreate/CreateNovel";
import { BrowserView, MobileView } from 'react-device-detect';
import loginNImg from '../../css/images/login.png'
import { useNavigate, useParams } from "react-router";
import CreateNovel5 from "../../components/createNovel/CreateNovel5";
import { useEffect, useState } from "react";
import { history } from "hooks/history";
import { useMediaQuery } from "react-responsive";


const AizacCreateNovel = () => {

    const navigate = useNavigate();

    // 화면 크기 검사
    const isMediumDevice = useMediaQuery( {query : "(min-width : 569px)"});

    // 이전페이지 이동 버튼
    const onClickMobBtn = () => {
        navigate(-1);
    }

    const [isUpdate, setIsUpdate] = useState(false);

    const params = useParams();

    const [novelIdState, ] = useState(params.novelId);
    const [sessionIdState, ] = useState(params.sessionId);

    useEffect(() => {
        const listenBackEvent = () => {
          // 뒤로가기 할 때 수행할 동작을 적는다
            window.location.reload();
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
          if (action === "POP") {
            listenBackEvent();
          }
        });
    
        return unlistenHistoryEvent;
      }, [
      // effect에서 사용하는 state를 추가
    ]);

    return (
        <>
            <BrowserView>
                <WrapDiv id="wrap">
                    <CreateNovel5 isUpdate={isUpdate} setIsUpdate={setIsUpdate} novelIdState={novelIdState} sessionIdState={sessionIdState}/>
                </WrapDiv>
            </BrowserView>
            {/* 모바일 여부 검수 후 모바일 페이지 랜더링 */}
            <MobileView>
              {isMediumDevice === true ?<WrapDiv id="wrap">
                    <CreateNovel5 />
                </WrapDiv> : 
                <MobWriteContainerDiv id="mob-write-container">
                    <MobWriteImgDiv className="mob-write-img">
                        <img src={loginNImg} alt="login_img"/>
                    </MobWriteImgDiv>
                    <MobWriteContainerH2H2 className="title">원활한 서비스를 위해 현재 집필실은<br/> PC와 태블릿에서만 이용하실 수 있습니다</MobWriteContainerH2H2>
                    <EditNextButton className="edit-next" type="button" onClick={onClickMobBtn}>이전 페이지로</EditNextButton>
                </MobWriteContainerDiv>}
            </MobileView>
        </>
    );
  }
  
  export default AizacCreateNovel;

  /** div 태그 #mob-write-container css */
  const MobWriteContainerDiv = styled.div`
    @media (min-width: 569px) {
        display: none;
    }
    @media (max-width: 568px) {
        padding: 0 40px;
        padding-top: 120px;
    }
  `;

  /** h2 태그 #mob-write-container .title css */
  const MobWriteContainerH2H2 = styled.h2`
    @media (max-width: 568px) {
        font-size: 24px;
        font-family: "Min Sans-bold";
        padding-bottom: 20px;
        letter-spacing: -0.03em;
        color: #050409;
        line-height: 40px;
        text-align: center;
        padding-top: 36px;
    }
  `;

  /** div 태그 .mob-write-img css */
  const MobWriteImgDiv = styled.div`
    @media (max-width: 568px) {
        text-align: center;
    }
  `;

  /** button 태그 .edit-next css */
  const EditNextButton = styled.button`
    @media (max-width: 568px) {
        width: 100%;
        background-color: #22222A;
        color: #fff;
        font-size: 17px;
        font-family: "Min Sans-Medium";
        letter-spacing: -0.3px;
        margin: 50px 0px 120px 0px;
        height: 70px;
        border-radius: 20px;
        outline: none;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: #303039;
        }
    }
    
  `;

  /** div 태그 #wrap css */
  const WrapDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto;
    height: 100vh;
    @media (max-width: 1400px) {
        display: grid;
        grid-template-columns: 3fr 1.5fr;
        grid-template-rows: auto;
        height: 100vh;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
  `;
  