import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import errorCss from '../../css/page.module.css'
import URL from '../../constants/url';


const AizacError = () => {
  const navigate = useNavigate();
  const location = useLocation();


  let errormessage = '';

  if (location?.state === null) {
    errormessage = '알 수 없는 에러가 발생했습니다.';
  } else if (location?.state?.msg === null) {
    errormessage = '알 수 없는 에러가 발생했습니다.';
  } else {
    errormessage = location?.state?.msg
  }

  // 이전페이지 버튼
  const goBack = () => {
    navigate(-2, { replace: true }); // 이전 URL을 현재 페이지 인식하고 재 로딩하는 코드.
  };

  // 메인페이지 버튼
  const goMain = () => {
    navigate(URL.MAIN);
  }

  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: 'instant'
    })
  }, [])

  return (
    <div className={errorCss.wrap + " wrap"}>
      <div className={errorCss.ERROR + " ERROR"}>
        <h1>Error</h1>
        <div className={errorCss.box + " box"}>
          <p>{errormessage}</p>
          <div className={errorCss.btn_area + " btn_area"}>
            <button className={errorCss.btn + " " + errorCss.btn_blue_h46 + " " + errorCss.w_130 + " btn btn_blue_h46 w_130"} onClick={goBack}>
              이전페이지
            </button>
            <button className={errorCss.btn + " " + errorCss.btn_blue_h46 + " " + errorCss.w_130 + " btn btn_blue_h46 w_130"} onClick={goMain}>
              메인페이지
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AizacError;
