import { atom, selector } from "recoil";
import URL from "./url";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const novelDataState = atom({
    key: 'novelData',
    default : null,
});
 
export const apiServerState = atom({
    key: 'apiserver', 
    //default: 'http://34.22.77.179:4001',// 개발  
    default: 'https://gserver.aizac.io', // 상용    
}) 

// 사업자 번호 유효성 검사 serviceKey
export const businessServiceKeyState = atom({
    key : 'serviceKey',
    default : '22o%2FV62d%2BUc1BC%2BKZiji7HOhyWZFApzC7V%2BNPEcUrrPl5kXdvsnc30MnbqHi%2BmPiYCuk1d841xAr4Eic8wRqhw%3D%3D',
})
  
export const novelApiServerState = atom({
    key: 'NovelApiServer',
    default: 'http://1.215.235.253:27002',
})
  
  export const beforeURLState = atom({
    key: 'beforeURL',
    default : URL.MAIN,
    effects_UNSTABLE: [persistAtom],
})

export const tempPasswordState = atom({
    key : 'tempPassword',
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const textEditDataState = atom({
    key: 'textEditData',
    default : '',
    effects_UNSTABLE: [persistAtom],
})

export const getNovelDataSelector = selector({
    key: 'novelDataSelector',
    get: ({get}) => {
        const novelState = get(novelDataState);
        return novelState;
    }, set: ({set}, newValue) => {
        set(novelDataState, newValue);
    },
    effects_UNSTABLE: [persistAtom],
});

export const episodeCntState = atom({
    key: 'episodeCnt',
    default : 0,
    effects_UNSTABLE: [persistAtom],
})

export const SortValueState = atom({
    key: 'sortValue',
    default : "recent",
    effects_UNSTABLE: [persistAtom],
})

export const synopState = atom({
    key: 'synop',
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const nickNameChangeBoolState = atom({
    key: "nickNameChangeBool",
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const novel2DataState = atom({
    key : "novel2Data",
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const novel3DataState = atom({
    key : "novel3Data",
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const novel4DataState = atom({
    key : "novel4Data",
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const picture2DataState = atom({
    key : "picture2Data",
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const picture3DataState = atom({
    key : "picture3Data",
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const episodeIdSessionState = atom({
    key : "episodeIdSession",
    default : null,
    effects_UNSTABLE: [persistAtom],
})

export const categorySortPageNumState = atom({
    key : "categorySortPageNum",
    default : {
        "sort" : "recent",
        "pageNum" : 1,
    },
    effects_UNSTABLE: [persistAtom],
})

