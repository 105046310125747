

import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import axios from 'axios';
import bannerDefault from '../../css/images/bannerDefault.png'
import mainCss from '../../css/main.module.css'
import { useNavigate } from 'react-router';
import URL from '../../constants/url';
import { apiServerState } from '../../constants/recoilData';
import { getCookie } from '../../hooks/Cookie';
import "../../css/main_carousel.css"

// 배너 컴포넌트
const Banner = () => {
  
  const apiServer = useRecoilValue(apiServerState);
  const navigate = useNavigate();
  const token = getCookie("token");
  
  const [bannerList, setBannerList] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  // isUpdateBool 변경시 유저 정보 업데이트(api 코인차감시 업데이트 용도+)
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "authorization": token,
      }
    };
  
    const update = () => {
      const url = apiServer + "/asiac_main/banner/list";
  
      const banner = async () => {
        await axios
          .get(url, config)
          .then((response) => {
            if (response?.data?.success) {
              setBannerList(response?.data?.data?.bannerList);
              setIsMounted(true);
            } else {
              const msg = response?.data?.custMsg
              if (msg === "router err") {
                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
              } else {
                alert(msg);
              }
              navigate(URL.MAIN);
              console.log("로딩 실패");
            }
          })
          .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
          });
      };
  
      banner();
  
    };
    update();
  }, []);

  // 배너 클릭
  const onClickBanner = (url) => {
    window.location.href = url;
  }

  return (
    <>
      {isMounted === false ? <></> : <div className={mainCss.container + " container"}>
        <section className={mainCss.slider + " slider"} style={{cursor:"pointer"}}>
          <CarouselDiv id="carouselExampleIndicators" className={mainCss.carousel + " carousel slide " + mainCss.slide }>
            <CarouselIndicatorsDiv className={mainCss.carousel + " carousel-indicators"}>
              {bannerList && bannerList.map((banner, index) => {return(<button key={banner?.id}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current="true"
                aria-label={"Slide " + index}
              ></button>)})}
            </CarouselIndicatorsDiv>
            <CarouselInnerDiv className={mainCss.carousel_inner + " carousel-inner"}>
              {bannerList && bannerList.map((banner, index) => {return(<CarouselInnerItemDiv key={banner?.id} className={index === 0 ? (mainCss.carousel_item + " carousel-item active") : (mainCss.carousel_item + " carousel-item")} onClick={() => {onClickBanner(banner?.url);}}>
                <CarouselInnerItemImg src={banner?.coverImageUrl !== null ? banner?.coverImageUrl : bannerDefault} fetchpriority="high" className={mainCss.d_block + " d-block w-100"} alt="banner" />
              </CarouselInnerItemDiv>)})}
            </CarouselInnerDiv>
            <CarouselControlNextPrevButton
              className={mainCss.carousel_control_prev + " carousel-control-prev"}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span className={mainCss.carousel_control_prev_icon + " carousel-control-prev-icon"} aria-hidden="true"></span>
              <span className={mainCss.visually_hidden + " visually-hidden"}>Previous</span>
            </CarouselControlNextPrevButton>
            <CarouselControlNextPrevButton
              className={mainCss.carousel_control_next + " carousel-control-next"}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span className={mainCss.carousel_control_next_icon + " carousel-control-next-icon"} aria-hidden="true"></span>
              <span className={mainCss.visually_hidden + " visually-hidden"}>Next</span>
            </CarouselControlNextPrevButton>
          </CarouselDiv>
        </section>
      </div>}
    </>
  );
};

export default Banner;


/** div 태그 .carousel css */
const CarouselDiv = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
`;

/** div 태그 .carousel-inner css */
const CarouselInnerDiv = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
`;

/** div 태그 .carousel-inner .carousel-item css */
const CarouselInnerItemDiv = styled.div`
  width: 1280px;
  height: 426px;
  padding: 0px 10px;
  margin-left: 0px;
  object-fit: cover;
  transition: transform 0.8s ease-in-out;
  @media (min-width: 768px) and (max-width: 1024px) {
    width : inherit;
    height : fit-content;
  }
  @media (min-width: 280px) and (max-width: 768px) {
    width : inherit;
    height : fit-content;
  }
  @media (min-width: 100px) and (max-width: 280px) {
    width : inherit;
    height : 100px;
  }
  @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx){
    width: 1280px;
    height: 426px;
    padding: 0px 10px;
    margin-left: 0px;
    object-fit: cover;
    @media (min-width: 1024px) and (max-width: 1400px) {
      width : inherit;
      height : 300px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width : inherit;
      height : 225px;
    }
    @media (min-width: 280px) and (max-width: 768px) {
      width : inherit;
      height : 165px;
    }
    @media (min-width: 100px) and (max-width: 280px) {
      width : inherit;
      height : 100px;
    }
  }
`;

/** img 태그 .carousel-inner .carousel-item > img css */
const CarouselInnerItemImg = styled.img`
  border-radius: 20px;
  height: -webkit-fill-available;
  @media (min-width: 100px) and (max-width: 280px) {
    height : 100px;
  }
  @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx) {
    @media (min-width: 100px) and (max-width: 280px) {
      height : 100px;
    }
  }
`;

/** div 태그 .carousel-indicators css */
const CarouselIndicatorsDiv = styled.div`
  right: 10px;
`;

/** button 태그 .carousel-control-next, .carousel-control-prev css  */
const CarouselControlNextPrevButton = styled.button`
  padding: 0px 0px;
  width: 80px;
`;