import SynopTabPicture from "../../components/common/SynopTabPicture";
import { Link, useNavigate } from "react-router-dom";
import drawing3Css from '../../css/drawing_Making_3.module.css'
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
import { useEffect, useState } from "react";
import URL from "../../constants/url";
import { getCookie, removeCookie, removeCookieOption, setCookieOption } from "hooks/Cookie";
import axios from "axios";


const PictureCreate3 = ({setIsCreateImg}) => {

    const synop = getCookie("synop");
    const backgroundStory = getCookie("backgroundStory");

    


    const navigate = useNavigate();

    const [characterInfo, setCharacterInfo] = useState("");

    const apiServer = useRecoilValue(apiServerState);
    const [, setUserInfo] = useState(null);
    const token = getCookie("token");
    const picture3Data = getCookie("picture3Data");

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    useEffect(() => {
        if (picture3Data !== undefined) {
            setCharacterInfo(picture3Data?.characterInfo);
        }
    }, []);

    // 유저정보 api 호출 함수
    const userInfoSet = async () => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";
        const config = {
            headers: {
            "Content-Type": "application/json",
            "authorization": token,
            }
        };

        await axios
        .get(urlUser, config)
        .then((response) => {
            if (response?.data?.success) {
                setUserInfo(response?.data?.data?.user);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                    alert("다시 로그인해주세요.");
                    logout();
                    navigate(URL.LOGIN);
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    navigate(URL.MAIN);
                } else if (msg === "invalid user") {
                    alert("다시 로그인해주세요.");
                    logout();
                    navigate(URL.LOGIN);
                } else {
                    alert(msg);
                    navigate(URL.MAIN);
                }
                console.log("로딩 실패");
                console.log(msg);
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    };

    // token 유무 검사 후 유저정보 셋팅
    useEffect(() => {
        if(token !== undefined) {
            userInfoSet();
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    // 그림 등장인물 정보 변경 입력 함수
    const onChangeCharacterInfo = (e) => {
        setCharacterInfo(e.target.value);
    }

    // 저장하고 다음으로 버튼
    const onClickSaveBtn = () => {
        if (characterInfo === "") {
            alert("캐릭터 정보를 입력해주세요.");
            return;
        } else if (characterInfo === null || characterInfo === undefined) {
            alert("캐릭터 정보를 입력해주세요.");
            return;
        }
        const characterInfoCheck = (String(characterInfo)).trim();
        if (characterInfoCheck === "") {
            alert("캐릭터 정보를 입력해주세요.");
            return;
        }
        const copy = {
            "characterInfo" : characterInfo,
            "backgroundStory" : backgroundStory,
            "synop" : synop
        }
        const option = {path : ""};
        setCookieOption("picture3Data", copy, option)
        localStorage.removeItem("createImg");
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        setIsCreateImg(false);
        navigate(URL.CREATE_PICTURE4);
        
    }

    return (
        <>
            <ContainerDiv id="container">
                <SynopTabPicture action={3}/>
                <ContainerH2H2>등장인물 정보를 입력해주세요</ContainerH2H2>
                <BigSection className={drawing3Css.big + " big"}>
                    <ContentDiv className={drawing3Css.content + " content"}>
                        <TitP className={drawing3Css.tit + " tit"}>등장인물 정보</TitP>
                        <TextAreaTextarea id="textArea" autoFocus placeholder="등장인물 정보를 입력해주세요(ex 중년의 남성, 회색 수염, 블루색의 로브를 입은 모습 등)" value={characterInfo} onChange={onChangeCharacterInfo}></TextAreaTextarea>
                    </ContentDiv>
                </BigSection>
                <SaveNextBtnButton type="submit" className={drawing3Css.saveNextBtn + " saveNextBtn"} onClick={onClickSaveBtn}><DefaultALink >저장하고 다음으로</DefaultALink></SaveNextBtnButton>
            </ContainerDiv>
        </>
    );
};
export default PictureCreate3;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 140px;
    @media(max-width:1400px) {
        padding: 0 80px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** h2 태그 .container h2 css */
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-top: 60px;
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    letter-spacing: -0.03em;
    font-weight: bold;
    margin-top: 3px;
    @media(max-width:568px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;

/** section 태그 .big css */
const BigSection = styled.section`
    display: block;
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 52px;
    @media(max-width:568px) {
        padding-top: 42px;
    }
`;

/** p 태그 .tit css */
const TitP = styled.p`
    font-size: 15px;
    font-family: "Min Sans-Regular";
    color: #050409;
    margin-bottom: 4px;
    letter-spacing: -0.03em;
`;

/** textarea 태그 #textArea css */
const TextAreaTextarea = styled.textarea`
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
    &::placeholder {
        color: #828284;
    }
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media(max-width:568px) {
        margin-top: 42px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;