import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import styled from "@emotion/styled";
import { getCookie, removeCookie, removeCookieOption } from "hooks/Cookie";
import { useRecoilValue } from "recoil";
import { apiServerState } from "constants/recoilData";
import axios from "axios";
import URL from "constants/url";



const ChargeShopSuccess = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);


    useEffect(() => {
        const requestData = {
            orderId: searchParams.get("orderId"),
            amount: searchParams.get("amount"),
            paymentKey: searchParams.get("paymentKey"),
        };

        // TODO: 개발자센터에 로그인해서 내 결제위젯 연동 키 > 시크릿 키를 입력하세요. 시크릿 키는 외부에 공개되면 안돼요.
        // @docs https://docs.tosspayments.com/reference/using-api/api-keys
        //const secretKey = "test_sk_aBX7zk2yd8yW0lYQlZYVx9POLqKQ";

        // 토스페이먼츠 API는 시크릿 키를 사용자 ID로 사용하고, 비밀번호는 사용하지 않습니다.
        // 비밀번호가 없다는 것을 알리기 위해 시크릿 키 뒤에 콜론을 추가합니다.
        // @docs https://docs.tosspayments.com/reference/using-api/authorization#%EC%9D%B8%EC%A6%9D
        //const encryptedSecretKey = `Basic ${btoa(secretKey + ":")}`;

        const userInfoSet = async () => {
            const urlUser = apiServer + "/api/asiac_login/get_my_info";
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
    
            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success ) {
                    const userInfo = response?.data?.data?.user;
                    confirm(userInfo);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        removeCookie("token");
                        removeCookieOption("token", {path : "/"});
                        localStorage.removeItem("pageData");
                        localStorage.removeItem("pageDataSynop");
                        localStorage.removeItem("categoryNotice");
                        localStorage.removeItem("categoryPay");
                        localStorage.removeItem("myPicturePageData");
                        localStorage.removeItem("rowCnt");
                        window.localStorage.clear();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        removeCookie("token");
                        removeCookieOption("token", {path : "/"});
                        localStorage.removeItem("pageData");
                        localStorage.removeItem("pageDataSynop");
                        localStorage.removeItem("categoryNotice");
                        localStorage.removeItem("categoryPay");
                        localStorage.removeItem("myPicturePageData");
                        localStorage.removeItem("rowCnt");
                        window.localStorage.clear();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });

        }

        async function confirm(userInfo) {


            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
            let price = 0;
            switch (parseInt(requestData?.amount)) {
                case 3000 : {
                    price = 3000;
                    break;
                }
                case 5000 : {
                    price = 5000;
                    break;
                }
                case 10000 : {
                    price = 10000;
                    break;
                }
            }

            const url = apiServer + `/api/aizac_trade/toss_normal_callback?orderId=${requestData?.orderId}&paymentKey=${requestData?.paymentKey}&amount=${requestData?.amount}&coin=${price}&userId=${userInfo?.id}`;
            axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success ) {
                    console.log("결재 성공");
                } else {
                    const msg = response?.data?.custMsg;
                    let msg2 = "";

                    if (msg === "invalid user") {
                        msg2 = "결재실패 : 다시 로그인 후 시도해주세요.";
                        removeCookie("token");
                        removeCookieOption("token", {path : "/"});
                        localStorage.removeItem("pageData");
                        localStorage.removeItem("pageDataSynop");
                        localStorage.removeItem("categoryNotice");
                        localStorage.removeItem("categoryPay");
                        localStorage.removeItem("myPicturePageData");
                        localStorage.removeItem("rowCnt");
                        window.localStorage.clear();
                        const token = getCookie("token");
                        if (token !== undefined) {
                            removeCookieOption("token", {path : "/"});
                            navigate(URL.MAIN);
                        } else {
                            navigate(URL.MAIN);
                        }
                    } else if (msg === "invalid data") {
                        msg2 = "결재실패 : 다시 시도해주세요.";
                    } else if (msg === "axios err" || msg === "invalid payment") {
                        msg2 = "결재실패 : 토스페이 오류, 잠시 후 다시 이용해주세요.";
                    } else {
                        msg2 = "결재실패 : 서버오류, 잠시 후 다시 이용해주세요."
                    }
                    navigate(`/charge-shop/fail?message=${msg2}`)
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(`/charge-shop/fail?message=${e.message}`)
            });


        }
        userInfoSet();
            
    }, []);

    return (
        <>
            <WrapperDiv className="result wrapper">
                <BoxSectionDiv className="box_section">  
                    <h2 style={{padding: "20px 0px 10px 0px"}}>
                        <img
                            width="35px"
                            src="https://static.toss.im/3d-emojis/u1F389_apng.png"
                        />
                        결제 성공
                    </h2>
                    <WrapperDiv className="result wrapper">
                        <Link to={URL.MAIN}>
                            <ButtonButton className="button" style={{ marginTop: '30px', marginRight: '10px' }}>
                                메인페이지
                            </ButtonButton>
                        </Link>
                        <Link to={URL.LIST_CHARGE_SHOP}>
                            <ButtonButton className="button" style={{ marginTop: '30px', backgroundColor: '#e8f3ff', color: '#1b64da' }}>
                                코인충전소
                            </ButtonButton>
                        </Link>
                    </WrapperDiv>   
                </BoxSectionDiv>
            </WrapperDiv>
        </>
    )
};
export default ChargeShopSuccess;

/** div 태그 .wrapper css */
const WrapperDiv = styled.div`
    max-width: 800px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-wrap: balance;
`;

/** div 태그 .box_section css */
const BoxSectionDiv = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 40px 30px 50px 30px; 
    margin-top:30px; 
    margin-bottom:50px; 
    color: #333D4B
`;

/** button 태그 .button css */
const ButtonButton = styled.div`
    color: #f9fafb;
    background-color: #3182f6;
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    /* vertical-align: middle; */
    cursor: pointer;
    border: 0 solid transparent;
    user-select: none;
    transition: background 0.2s ease, color 0.1s ease;
    text-decoration: none;
    border-radius: 7px;
    padding: 11px 16px;
    &:hover {
        color: #fff;
        background-color: #1b64da;
    }
`;