import React from 'react';

// TODO: css 정리 후 추가
import './css/active.css';
import RootRoutes from './routes';
import { RecoilRoot } from 'recoil';
import { CookiesProvider } from 'react-cookie';
import './css/sweetModal.css';


function App() {
  return (
    <>
        <RecoilRoot>
          <CookiesProvider>
              <RootRoutes />
          </CookiesProvider>
        </RecoilRoot>
    </>
  );
}

export default App;
