import styled from "@emotion/styled";
import NovelCreate2DefaultSectionCategory from "./NovelCreate2DefaultSectionCategory";
import novel2Css from '../../css/novelCreate2.module.css'
import { useEffect, useRef, useState } from "react";
import $ from 'jquery';


// 기본 이미지 컴포넌트
const NovelCreate2DefaultSectionList = ({setImgDefault}) => {

    const categoryDataList = [{cate: "로맨스", id:1}, {cate: "판타지", id:2}, {cate:"스릴러", id:3}, {cate:"무협", id:4}, {cate:"기타", id:5}];

    const [category, setCategory] = useState(0);
    const sectionRef = useRef([]);
    const CategoryBtnRef = useRef();

    // 카테고리 버튼 클릭
    const onClickActionBtn = (e) => {
        $('.actionBtn3').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setCategory(parseInt(val));
    }

    // 기본 이미지 카테고리 선택에 따른 랜더링
    useEffect(() => {
        switch (category) {
            case 0: {
                $('.actionBtn3').removeClass("active");
                sectionRef.current[0].style.display = "none";
                sectionRef.current[1].style.display = "none";
                sectionRef.current[2].style.display = "none";
                sectionRef.current[3].style.display = "none";
                sectionRef.current[4].style.display = "none";
                break;
            }
            case 1 : {
                $('.actionBtn3').removeClass("active");
                $("#category_1").addClass("active");
                sectionRef.current[0].style.display = "block";
                sectionRef.current[1].style.display = "none";
                sectionRef.current[2].style.display = "none";
                sectionRef.current[3].style.display = "none";
                sectionRef.current[4].style.display = "none";
                break;
            }
            case 2 : {
                $('.actionBtn3').removeClass("active");
                $("#category_2").addClass("active");
                sectionRef.current[0].style.display = "none";
                sectionRef.current[1].style.display = "block";
                sectionRef.current[2].style.display = "none";
                sectionRef.current[3].style.display = "none";
                sectionRef.current[4].style.display = "none";
                break;
            }
            case 3 : {
                $('.actionBtn3').removeClass("active");
                $("#category_3").addClass("active");
                sectionRef.current[0].style.display = "none";
                sectionRef.current[1].style.display = "none";
                sectionRef.current[2].style.display = "block";
                sectionRef.current[3].style.display = "none";
                sectionRef.current[4].style.display = "none";
                break;
            }
            case 4 : {
                $('.actionBtn3').removeClass("active");
                $("#category_4").addClass("active");
                sectionRef.current[0].style.display = "none";
                sectionRef.current[1].style.display = "none";
                sectionRef.current[2].style.display = "none";
                sectionRef.current[3].style.display = "block";
                sectionRef.current[4].style.display = "none";
                break;
            }
            case 5 : {
                $('.actionBtn3').removeClass("active");
                $("#category_5").addClass("active");
                sectionRef.current[0].style.display = "none";
                sectionRef.current[1].style.display = "none";
                sectionRef.current[2].style.display = "none";
                sectionRef.current[3].style.display = "none";
                sectionRef.current[4].style.display = "block";
                break;
            }
        }
    }, [category])

    return(
        <>
            <DefaultDiv className={novel2Css.illu_me_list + " illu_me_list illu-me-list"}>
                <CategoryBtnDiv className={novel2Css.category_btn + " category_btn category-btn"}>
                    {categoryDataList.map((item, index) => <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn3"} id={"category_" + item?.id} key={"categoryNovelCreate2_" + index} ref= {CategoryBtnRef} value={item?.id} onClick={(e) => {onClickActionBtn(e)}} >{item?.cate}</CategoryBtnButton>)}
                </CategoryBtnDiv>
                <div ref={(el) => sectionRef.current[0] = el} style={{display : "none"}}>
                    <NovelCreate2DefaultSectionCategory setImgDefault={setImgDefault}  categoryId={1} />
                </div>
                <div ref={(el) => sectionRef.current[1] = el} style={{display : "none"}}>
                    <NovelCreate2DefaultSectionCategory setImgDefault={setImgDefault}  categoryId={2} />
                </div>
                <div ref={(el) => sectionRef.current[2] = el} style={{display : "none"}}>
                    <NovelCreate2DefaultSectionCategory setImgDefault={setImgDefault}  categoryId={3} />
                </div>
                <div ref={(el) => sectionRef.current[3] = el} style={{display : "none"}}>
                    <NovelCreate2DefaultSectionCategory setImgDefault={setImgDefault}  categoryId={4} />
                </div>
                <div ref={(el) => sectionRef.current[4] = el} style={{display : "none"}}>
                    <NovelCreate2DefaultSectionCategory setImgDefault={setImgDefault}  categoryId={5} />
                </div>
            </DefaultDiv>
        </>
    );
};
export default NovelCreate2DefaultSectionList;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;


/** div 태그 .category-btn css */
const CategoryBtnDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 12px;
`;

/** button 태그 .category-btn > button css */
const CategoryBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #828284;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    &.active {
        border: 1px solid #040a20;
        background-color: #040a20;
        color: #fff;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right: 4px;
    }
`;