import ListSelectTab from "../../components/common/ListselectTab";
import storeCss from '../../css/drawing_store.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "@emotion/styled";
import { useEffect } from "react";


// 그림 스토어 페이지 컴포넌트
const DrawingStore = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    return (
        <>
            <div className={storeCss.container + " container"}>
                <RankIllSection className={storeCss.rank_illu + " rank_illu"}>
                    <RankTitleDiv className={storeCss.rank_title + " rank_title"}>
                        <RankTitleH2H2>소장 가치 100%</RankTitleH2H2>
                        <RankTitleH1H1>아이작 인기 일러스트</RankTitleH1H1>
                    </RankTitleDiv>
                    <ListSelectTab type={"illust"}/>
                </RankIllSection>
            </div>
        </>
    );
};

export default DrawingStore;

/** section 태그 .rank-illu css */
const RankIllSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0px 0px;
    max-width: 1320px;
    margin: 0 auto;
    margin-bottom: 120px;
`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 58px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 20px 0px 0px;
    }
`;

/** h2 태그 .rank-title h2 css */
const RankTitleH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 20px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 0;
`;

/** h1 태그 .rank-title h1 css */
const RankTitleH1H1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 46px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 20px;
`;