import SynopTab from "../../components/common/SynopTab";
import styled from "@emotion/styled";
import { useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router";
import novel4Css from '../../css/novelCreate4.module.css'
import { createBrowserHistory } from "history";
import { getCookie, removeCookie, removeCookieOption } from "hooks/Cookie";
import URL from "constants/url";
import { useRecoilValue } from "recoil";
import { apiServerState } from "constants/recoilData";
import axios from "axios";
import defaultImg from '../../css/images/illustDefault.png'


const NovelCreate4 = () => {

    const history = createBrowserHistory();

    const [locationKeys, setLocationKeys] = useState([]);

    // 뒤로가기, 앞으로 가기 감지
    useEffect(() => {
    return history.listen((location) => {
        if (history.action === "PUSH") {
        setLocationKeys([location.key]);
        }

        if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
            setLocationKeys(([, ...keys]) => keys);

            // 앞으로 가기
        } else {
            setLocationKeys((keys) => [location.key, ...keys]);

            // 뒤로 가기
            history.push("/main");
        }
        }
    });
    }, [locationKeys, history]);

    const editStartButton = useRef();
    const editSaveButton = useRef();
    const navigate = useNavigate();


    const [novel, setNovel] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie('token');
    const params = useParams();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 지금 바로 첫회쓰기 버튼
    const startOnClick = () => {
        navigate("/create-novel/"+ novel.id + "/0");

        removeCookieOption("novel2Data", {path : ""});
        removeCookieOption("synop", {path : ""});
        removeCookieOption("novel3Data", {path : ""});
    }
    
    // 저장하고 집필실 이동 버튼
    const saveOnClick = () => {
        
        navigate("/create/main");
        removeCookieOption("novel2Data", {path : ""});
        removeCookieOption("novel3Data", {path : ""});
        removeCookieOption("synop", {path : ""});
    }

    // 최초 랜더링시 소설정보, 유저정보 셋팅
    useEffect(() => {
        const urlNovel = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + params.novelId;

        const novelInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlNovel, config)
            .then((response) => {
                if (response?.data?.success) {
                    setNovel(response?.data?.data?.novel);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "invalid novel") {
                        alert("없는 소설 또는 비공개 소설 입니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        novelInfo();

        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        
        if(token !== undefined) {
            userInfo();
        }

        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    const [pubday, setPubday] = useState("");
    const [period, setPeriod] = useState("");

    // 소설정보, 유저정보 셋팅시 소설 userId, 유저 id 비교 (비정상 접근 방지)
    useEffect(() => {
        if(novel !== null && userInfo !== null) {
            if (novel.userId !== userInfo.id) {
                alert("비정상적인 접근입니다.");
                navigate(URL.MAIN);
            }
        }
        const data = (String(novel?.pubday)).split(', ');
        let str = "";
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if((i + 1) < data.length ) {
                    switch (data[i]) {
                        case "monday" : {
                            str += "월, ";
                            break;
                        }
                        case "tuesday" : {
                            str += "화, ";
                            break;
                        }
                        case "wednesday" : {
                            str += "수, ";
                            break;
                        }
                        case "thursday" : {
                            str += "목, ";
                            break;
                        }
                        case "friday" : {
                            str += "금, ";
                            break;
                        }
                        case "Saturday" : {
                            str += "토, ";
                            break;
                        }
                        case "sunday" : {
                            str += "일";
                            break;
                        }
                    }
                } else {
                    switch (data[i]) {
                        case "monday" : {
                            str += "월";
                            break;
                        }
                        case "tuesday" : {
                            str += "화";
                            break;
                        }
                        case "wednesday" : {
                            str += "수";
                            break;
                        }
                        case "thursday" : {
                            str += "목";
                            break;
                        }
                        case "friday" : {
                            str += "금";
                            break;
                        }
                        case "Saturday" : {
                            str += "토";
                            break;
                        }
                        case "sunday" : {
                            str += "일";
                            break;
                        }
                    }
                }
            }
        }
        const periodCopy = String(novel?.period);
        switch(periodCopy) {
            case "free" : {
                setPeriod("자유연재");
                break;
            }
            case "daily" : {
                setPeriod("매일연재");
                break;
            }
            case "weekly" : {
                setPeriod("주말연제");
                break;
            }
        }
        setPubday(str);
    }, [novel, userInfo])

    const noImage = (e) => {
        e.target.src = defaultImg
    }
    
    return (
        <>
            <ContainerDiv id="container">
                <SynopTab action={5} />
                {novel !== null ? <SynopSelectSection className={novel4Css.synop_select + " synop_select synop-select"}>
                    <SynopSelectTitleH1 className={novel4Css.synop_select_title + " synop_select_title synop-select-title"}>작품 생성이 완료되었습니다</SynopSelectTitleH1>
                    <DefaultHr />
                    <NovelBoxDiv className={novel4Css.novel_box + " novel_box novel-box"}>
                        <DFlexDiv className={novel4Css.d_flex + " d_flex d-flex"}>
                            <DefaultDiv className={novel4Css.novel_box_img + " novel_box_img novel-box-img"}>
                                <NovelBoxImgImg src={novel?.imgUrl !== null ? (novel?.imgUrl) : defaultImg} alt="novel_img" onError={noImage}/>
                            </DefaultDiv>
                            <NovelBoxInfoDiv className={novel4Css.novel_box_info + " novel_box_info novel-box-info"}>
                                <NovelInfoCateDiv className={novel4Css.novel_info_cate + " novel_info_cate novel-info-cate"}>{novel?.categoryName}</NovelInfoCateDiv>
                                <DFlexNovelInfoTitleDiv className={novel4Css.d_flex + " " + novel4Css.novel_info_title + " d_flex novel_info_title d-flex novel-info-title"}>
                                    <MainTitleDiv className={novel4Css.main_title + " main_title main-title"}>{novel?.title}</MainTitleDiv>
                                    <SubTitleDiv className={novel4Css.sub_title + " sub_title sub-title"}>{period !== "" ? period : pubday}</SubTitleDiv>
                                </DFlexNovelInfoTitleDiv>
                                <NovelInfoIntroDiv className={novel4Css.novel_info_intro + " novel_info_intro novel-info-intro"}>
                                    {novel?.synopsis}
                                </NovelInfoIntroDiv>
                            </NovelBoxInfoDiv>
                        </DFlexDiv>
                    </NovelBoxDiv>
                    <EditStartButton className={novel4Css.edit_start + " edit_start edit-start"} type="button" ref={editStartButton} onClick={startOnClick}>지금 바로 첫회쓰기</EditStartButton>
                    <EditSaveButton className={novel4Css.edit_save + " edit_save edit-save"} type="button" ref={editSaveButton} onClick={saveOnClick}>
                        저장하고 집필실로 이동
                    </EditSaveButton>
                </SynopSelectSection> : <></>}
            </ContainerDiv>
        </>
    );
};

export default NovelCreate4;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    overflow: scroll;
    padding: 0 140px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** section 태그 .synop-select css  */
const SynopSelectSection = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 76px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 60px;
    }
`;

/** h1 태그 .synop-select-title css */
const SynopSelectTitleH1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-bold";
    font-size: 32px;
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 24px;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: none;
    height: 1px;
    background-color: #ededed;
    width: 100%;
    margin: 32px 0px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 18px;
        margin-bottom: 42px;
    }
`;

/** div 태그 .novel-box css */
const NovelBoxDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: auto;
    margin: 10px 0px 0px 0px;
    padding: 32px 32px;
    border: 1px solid #bdbdbd;
    border-radius: 20px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 18px;
        border-color: #EDEDED;
    }
`;

/** div 태그 .d-flex css */
const DFlexDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: flex-end;
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** img 태그 .novel-box-img > img css */
const NovelBoxImgImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: 240px;
`;

/** div 태그 .novel-box-info css */
const NovelBoxInfoDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 600px;
    margin-left: 20px;
    @media (min-width: 100px) and (max-width: 768px) {
        margin: 0;
        width: auto;
    }
`;

/** div 태그 .novel-info-cate css */
const NovelInfoCateDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 6px 15px;
    color: #fff;
    background-color: #22222a;
    font-family: "Min Sans-Medium";
    font-size: 13px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    border-radius: 30px;
    width: -moz-fit-content;
    width: fit-content;
    @media (min-width: 100px) and (max-width: 768px) {
        display: none;
    }
`;

/** div 태그 .d-flex .novel-info-title css */
const DFlexNovelInfoTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    @media (min-width: 100px) and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
    }
`;

/** div 태그 .main-title css */
const MainTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-bold";
    font-size: 20px;
    color: #050409;
    letter-spacing: -0.3px;
    margin-right: 6px;
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 22px;
    }
`;

/** div 태그 .sub-title css */
const SubTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-family: "Min Sans-Regular";
    font-size: 14px;
    color: #828284;
    align-items: baseline;
`;

/** div 태그 .novel-info-intro css */
const NovelInfoIntroDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    color: #22222a;
    line-height: 24px;
    @media (min-width: 100px) and (max-width: 768px) {
        line-height: 26px;
        word-break: normal;
    }
`;

/** button 태그 .edit-start css */
const EditStartButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    background-color: #ff5f37;
    color: #fff;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 50px 0px 16px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #ff7957;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        margin-top: 32px;
    }
`;

/** button 태그 .edit-save css */
const EditSaveButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    background-color: #fff;
    color: #ff5f37;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 0px 0px 120px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: 1px solid #ff5f37;
    cursor: pointer;
    &:hover {
        border: 2px solid #ff5f37;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        margin-bottom: 80px;
        border: 1px solid #ff5f37;
    }
`;