import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import axios from 'axios';
import synopDefault from '../css/images/synopDefault.png';
import mainCss from '../css/main.module.css';
import URL from '../constants/url';
import { apiServerState, synopState } from '../constants/recoilData';
import { getCookie, setCookieOption } from '../hooks/Cookie';


const SynopsisBestBoard = () => {

  const apiServer = useRecoilValue(apiServerState);
  const navigate = useNavigate();
  const token = getCookie("token");

  const [synopsisList, setSynopsisList] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  /** 최초 인기 시놉시스 리스트 호출 api 연동 코드 */
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "authorization": token,
      }
    };
  
    const update = () => {
      const url = apiServer + "/asiac_main/best/synopsis";
  
      const synopsis = async () => {
        await axios
          .get(url, config)
          .then((response) => {
            if (response?.data?.success) {
              setSynopsisList(response?.data?.data?.synopsisList);
              setIsMounted(true);
            } else {
              const msg = response?.data?.custMsg;
              if (msg === "router err") {
                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
              } else {
                alert(msg);
              }
              console.log("시놉시스 리스트 로딩 실패");
            }
          })
          .catch(e => {  // API 호출이 실패한 경우
            alert(e?.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e?.message}});
          });
      };
  
      synopsis();
  
    };
    update();
  }, []); 

  // 전역 상태 시놉시스 저장함수 생성
  const setSynop = useSetRecoilState(synopState);

  /** 시놉시스 이미지 클릭 */
  const onClickImg = async (synop1) => {
    if (token === undefined) {
      navigate("/login");
    }else {
      const config = {
          headers: {
              "Content-Type": "application/json",
              "authorization": token,
          }
      };

      const url = apiServer + "/api/asiac_novel/update_synopsis_view"

      const form = {"synopsisId": synop1?.id}

      await axios
      .post(url, form, config)
      .then((response) => {
          if (response?.data?.success) {
              console.log("success");
          } else {
              console.log(response?.data?.custMsg);
          }
      })
      .catch((e) => {
          console.log(e?.message);
      });

      
      const urlHistory = apiServer + "/api/asiac_novel/update_synopsis_use_history"
      await axios
      .post(urlHistory, form, config)
      .then((response) => {
          if (response?.data?.success) {
              console.log("success");
          } else {
              console.log(response?.data?.custMsg);
          }
      })
      .catch((e) => {
          console.log(e);
      });
      
      // 전역 상태 시놉시스 저장
      setSynop(synop1);
      // 쿠키 시놉시스 저장(새로고침시 전역 상태 휘발대비)
      setCookieOption("synop", synop1, {path : ""});
      navigate(URL?.CREATE_NOVEL2);
    } 
  }

  const noImage = (e) => {
    e.target.src = synopDefault
  }

  return (
    <>
      {isMounted === false ? <></> : <ContainerDDiv>
        <div className={mainCss.container + " container"}>
          <RankSynopSection>
            <RankTitleDDiv>
              <RankTitleDH2>오직 아이작에서만 만나볼 수 있는</RankTitleDH2>
              <RankTitleDH1>아이작 인기 시놉시스</RankTitleDH1>
            </RankTitleDDiv>
            <MobileDiv>
              <RowColsDiv className="row row-cols-2 row-cols-lg-2 g-2 g-lg-3">
              {synopsisList && synopsisList.map((synop) => { return(<ColDiv key={"synopsis" + synop?.id} onClick={() => {onClickImg(synop)}}>
                  <LinkDefault to={URL.CREATE_NOVEL2}>
                    <div className={"p-3 synop_view " + mainCss.synop_view} >
                      <SynopImgDiv>
                        <SynopIlluImg src={synop?.coverImageUrl !== null ? synop?.coverImageUrl : synopDefault} fetchpriority="low" alt="synopIllu" onError={noImage} />
                        <SynopOverlayDiv>
                          {(String(synop?.title)).length < 20 ? <SynopTitleDiv>{(String(synop?.title)).trim()}</SynopTitleDiv> :
                          <SynopTitleDiv>{((String(synop?.title)).trim()).substring(0, 19) + "..."}</SynopTitleDiv>}
                          {(String(synop?.synopsis)).length < 60 ? <SynopSubDiv>{(String(synop?.synopsis)).trim()}</SynopSubDiv> : 
                          <SynopSubDiv>{((String(synop?.synopsis)).trim()).substring(0, 59) + "..."}</SynopSubDiv>}
                        </SynopOverlayDiv>
                      </SynopImgDiv>
                    </div>
                  </LinkDefault>
                </ColDiv>)})}
              </RowColsDiv>
            </MobileDiv>
          </RankSynopSection>
        </div>
      </ContainerDDiv>}
    </>
  );
};

export default SynopsisBestBoard;

/**div 태그 row-cols css */
const RowColsDiv = styled.div`
  @media (min-width: 1100px) and (max-width: 1400px) {
    display: flex;
  }
  @media (min-width: 768px) and (max-width: 1100px) {
    display: block;
  }
  @media (min-width: 360px) and (max-width: 768px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 359px) {
    display: block;
  }
`;


/**div 태그 mobile css */
const MobileDiv = styled.div`
  @media (min-width: 768px) and (max-width: 1100px) {
    display: block;
  }
  @media (min-width: 360px) and (max-width: 768px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 359px) {
    display: block;
  }
`;

/** div 태그 .container-d css */
const ContainerDDiv = styled.div`
  margin: 0;
  padding: 0;
  background: radial-gradient(circle, #070709, #22222a);
  background-size: 150% 150%;
`;

/** a 태그 디퐁트 css */
const LinkDefault = styled(Link)`
  text-decoration: none;
`;

/** h1 태그 디폴트 css */
const RankTitleDH1 = styled.h1`
  font-size: 46px;
  font-family: "Min Sans-bold";
  color: #fff;
  letter-spacing: -0.3px;
`;

/** h2 태그 디폴트 css */
const RankTitleDH2 = styled.h2`
  font-size: 20px;
  font-family: "Min Sans-bold";
  color: #fff;
  letter-spacing: -0.3px;
`;

/** div 태그 .col css */
const ColDiv = styled.div`
  padding: 0px;
  @media (min-width: 768px) and (max-width: 1100px) {
    width: 100%;
  }
`;

/** section 태그 .rank-synop css */
const RankSynopSection = styled.section`
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
`;

/**div 태그 .rank-title-d css */
const RankTitleDDiv = styled.div`
  padding-top: 120px;
  @media (min-width: 768px) and (max-width: 1400px) {
    padding-top: 30px;
  }
`;

/** div 태그 .synop-img css */
const SynopImgDiv = styled.div`
  position: relative;
`;

/** div 태그 .synop-overlay css */
const SynopOverlayDiv = styled.div`
  position: absolute;
  top: 170px;
  left: 20px;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  @media (min-width: 768px) and (max-width: 1400px) {
    top: 150px;
  }
  @media (min-width: 360px) and (max-width: 768px) {
    inset: 40px 0px 0px 10px;
  }
  @media (min-width: 100px) and (max-width: 359px) {
    inset: 5px 0px 0px 10px;
  }
`;

/** img 태그 .synop-illu css */
const SynopIlluImg = styled.img`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  opacity: 0.4;
  border-radius: 20px;
  height: 280px;
  width: 624px;
  @media (max-width: 1400px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 780px) {
    width: 100%;
  }
  @media (min-width: 360px) and (max-width: 767px) {
    width: 220%;
  }
  @media (min-width: 100px) and (max-width: 359px) {
    width: 220%;
  }
`;

/** div 태그 .synop-title css */
const SynopTitleDiv = styled.div`
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.3px;
  opacity: 0.9;
  margin-bottom: 6px;
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 330px;
  }
  @media (min-width: 360px) and (max-width: 767px) {
    width: 220%;
  }
  @media (min-width: 100px) and (max-width: 359px) {
    font-size: 16px;
    width: 220%;
  }
`;

/** div 태그 .synop-sub css */
const SynopSubDiv = styled.div`
  font-size: 15px;
  font-family: "Min Sans-Light";
  letter-spacing: -0.3px;
  opacity: 0.7;
  width: 356px;
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 300px;
  }
  @media (min-width: 360px) and (max-width: 767px) {
    width: 220%;
  }
  @media (min-width: 100px) and (max-width: 360px) {
    font-size: 10px;
    width: 220%;
  }
`;
