import cashImg from '../../css/images/cash.png'
import chargeShop from '../../css/chargeShop1.module.css'
import styled from '@emotion/styled';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import URL from 'constants/url';
//import { loadTossPayments } from '@tosspayments/payment-sdk';

// 충전소 상세 컴포넌트
const ChargeItem = ({item, isBasic}) => {

    const [price, ] = useState(item?.price);

    const navigate = useNavigate();

    const [isSetting, ] = useState(true); 

    // 금액 버튼
    const onClickPayment = async () => {
        if(isSetting === true) {
            if(isBasic === true) {
                navigate(URL.CHARGE_SHOP_MAIN_CHECK_OUT_INPUT + price);
            } else {
                alert("준비중입니다.");
                console.log("준비중입니다.");
            }
        } else {
            alert("준비중입니다.");
            console.log("준비중입니다.");
        }
    }

    
    return (
        <>
            <ContentDiv className={chargeShop.content + " content"}>
                <PackageDiv className={chargeShop.package + " package d-flex"}>
                    <DefaultDiv className={chargeShop.coinImg + " coinImg"}>
                        <DefaltImg src={cashImg} alt='cash_img'/>
                    </DefaultDiv>
                    <PackagePP>{item?.title}</PackagePP>
                </PackageDiv>
                <PriceTabDiv className={chargeShop.priceTab + " priceTab"}>
                    <PriceTabP>{item?.content}</PriceTabP>
                    <MoneySpan className={chargeShop.money + " money"}>{item?.price}{item?.price === "continue" ? <></> : <WonSpan className={chargeShop.won + " won"}>원</WonSpan>}</MoneySpan>
                </PriceTabDiv>
                {item?.payBool === true ? <PaymenetDiv className={chargeShop.payment + " payment"} onClick={() => {onClickPayment(chargeShop.won)}}>
                    <DefaultSvg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath={'url(#clip0_1_4981)'} >
                            <path d="M6.75 16.5C7.16421 16.5 7.5 16.1642 7.5 15.75C7.5 15.3358 7.16421 15 6.75 15C6.33579 15 6 15.3358 6 15.75C6 16.1642 6.33579 16.5 6.75 16.5Z" stroke={'white'} strokeWidth={"1.5"} strokeLinecap={'round'} strokeLinejoin={'round'} />
                            <path d="M15 16.5C15.4142 16.5 15.75 16.1642 15.75 15.75C15.75 15.3358 15.4142 15 15 15C14.5858 15 14.25 15.3358 14.25 15.75C14.25 16.1642 14.5858 16.5 15 16.5Z" stroke={'white'} strokeWidth={"1.5"} strokeLinecap={'round'} strokeLinejoin={'round'} />
                            <path d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5" stroke={'white'} strokeWidth={"1.5"} strokeLinecap={'round'} strokeLinejoin={'round'}/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_4981">
                                <rect width="18" height="18" fill="white"/>
                            </clipPath>
                        </defs>
                    </DefaultSvg>
                    <PaymentSpanSpan>결제하기</PaymentSpanSpan>
                </PaymenetDiv> : <></>}
            </ContentDiv>
        </>
    );
};
export default ChargeItem;

/** svg 태그 기본 css */
const DefaultSvg = styled.svg`
    margin-right: 5px; 
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 28px;
    background-color: #F8F8F8;
    border-radius: 20px;
    width: calc(25% - 12px); /* 4개 중 하나의 너비 */
    float: left; /* 좌측 정렬 */
    @media (max-width: 1400px) {
        width: calc(50% - 12px);  /* 2개 중 하나의 너비 */
    }
    @media (max-width: 568px) {
        width:100%;
        margin-bottom: 12px;
    }
    @media (max-width:360px) {
        width:100%;
    }
`;


/** div 태그 .package css */
const PackageDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #040A20;
    border-radius: 31px;  
    color: #fff;
    justify-content: center;
    gap:6px;
    align-self: center;
    padding: 11px 0 12px 0;
`;

/** p 태그 .package p css */
const PackagePP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-bottom: 0;
    font-size: 18px;
    font-family: "min sans-bold";
    letter-spacing: -0.3px;
    @media (min-width: 100px) and (max-width: 359px) {
        margin-left: 10%;
    }
`;

/** img 태그 디폴트 css */
const DefaltImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (min-width: 100px) and (max-width: 359px) {
        margin-left: 10%;
    }
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
`;


/** div 태그 .priceTab css */
const PriceTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: right;
    padding-top: 28px;
    padding-bottom: 12px;
`;

/** p 태그 .priceTab p css */
const PriceTabP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    font-family: "Min Sans-regular";
    color: #9B9B9D;
    letter-spacing: -0.3px;
    margin-bottom: 0;
`;

/** span 태그 .percent css 
const PercentSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    font-family: "Min Sans-bold";
    color: #FF5F37;
    letter-spacing: -0.3px;
    padding-right: 10px;
`;*/

/** span 태그 .discount css 
const DiscountSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    font-family: "Min Sans-bold";
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: -0.3px;
    position: relative;
    display: inline-block;
    &::before {
        content: '';
        height: 1px;
        background-color: #828284;
        display: block;
        position: absolute;
        top: 50%;
        left: 0; 
        right: 0;
        transform: translateY(-50%);
    }
`;*/

/** span 태그 .money css */
const MoneySpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    padding-left: 10px;
`;

/** span 태그 .won css */
const WonSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
`;

/** div 태그 .payment css */
const PaymenetDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #FF5F37;
    color: #fff;
    text-align: center;
    padding: 11px 0 12px 0;
    border-radius: 31px;
    cursor: pointer;
    &:hover {
        background-color: #ff7957;
    }
`;

/** span 태그 .payment span css */
const PaymentSpanSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    font-family: "min sans-medium";
    letter-spacing: -0.3px;
`;