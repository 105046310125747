import { Link, useNavigate } from "react-router-dom";
import withDrawalCss from '../../css/withDrawal.module.css';
import styled from "@emotion/styled";
import { useEffect, useState } from "react";


const WithDrawal1 = () => {

    const text1 = `1. 서비스 탈퇴약관

① 회원은 회사에 대하여 언제든지 회원탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다. 회원탈퇴는 곧 회원자격 상실을 뜻합니다.

② 탈퇴 시 회원님의 아이작 코인은 모두 삭제되며, 추후 복구가 불가능하니 고객센터를 통해 환불 및 환전 요청 후 탈퇴 요청을 하시기를 바랍니다. 또한 회원이 등록하고 게시한 게시물과 관련된 자료 일체 및 개인 정보 일체는 삭제되나, 제3자에 의해 스크랩 등이 되어 재게시된 게시물의 경우에는 삭제되지 않으니 사전에 삭제 후 탈퇴 요청을 하시기 바랍니다.

③ 회원이 회원탈퇴 또는 회원자격이 상실된 경우에도 추후 서비스 복구의 경우를 대비하여 콘텐츠와 관련된 자료가 백업될 수 있습니다. 이러한 자료는 복구의 경우에만 사용되고 이미 탈퇴 혹은 자격이 상실된 회원의 자료는 복구하지 않습니다.`

    const text2 = `2. 개인정보의 파기

① 관련 법령 및 개인정보처리방침에 따라 보유하는 정보를 제외한 모든 회원 및 계정 정보를 삭제하기 위하여 일정기간 이후 탈퇴처리가 완료됩니다.
                
② 회사는 "개인정보 유효기간제"에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리하여 보관하고 있습니다.
                
③ 회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체 없이 재생이 불가능한 방법으로 파기합니다.
                
④ 전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.`

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);

    // 서비스 탈퇴 약관 체크
    const onChangeCheck1 = (e) => {
        setChecked1(e.target.checked);
    }

    // 개인정보의 파기 체크
    const onChangeCheck2 = (e) => {
        setChecked2(e.target.checked);
    }

    const navigate = useNavigate();

    // 모두 동의합니다 버튼
    const onClickBtn = () => {
        if (checked1 === false) {
            alert("서비스 탈퇴약관을 동의하셔야 회원탈퇴가 진행됩니다.");
            return;
        } else if (checked2 === false) {
            alert("개인정보 파기를 동의하셔야 회원탈퇴가 진행됩니다.");
            return;
        } else if ((checked1 === true) && (checked2 === true)) {
            navigate("/withdrawal/2");
        } 
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    return (
        <>
            <div id="wrap">
                <ContainerDiv id="container">
                    <TitleDiv className={withDrawalCss.title + " title"}>
                        <TitleH4H4>아이작 회원탈퇴</TitleH4H4>
                        <TitleH2H2>탈퇴약관 동의</TitleH2H2>
                    </TitleDiv>
                    <div className={withDrawalCss.serviceOk + " serviceOk"}>
                        <SeviceLabel htmlFor="service">서비스 탈퇴약관</SeviceLabel>
                        <ServiceAgreeTextarea id="serviceAgree" readOnly>
                            {text1}
                        </ServiceAgreeTextarea>
                    </div>
                    <CheckBox1Input type="checkbox" id="checkBox1" onChange={(e) => {onChangeCheck1(e)}}/>
                        <CheckBox1Label htmlFor="checkBox1"><em></em>서비스 탈퇴약관에 동의합니다.</CheckBox1Label>
                    <DefaultHr />
                    <div className={withDrawalCss.serviceOk + " serviceOk"}>
                        <SeviceLabel htmlFor="service">개인정보의 파기</SeviceLabel>
                        <ServiceAgreeTextarea id="serviceAgree" readOnly>
                            {text2}
                        </ServiceAgreeTextarea>
                    </div>
                    <CheckBox1Input type="checkbox" id="checkBox2" onChange={(e) => {onChangeCheck2(e)}}/>
                    <CheckBox1Label htmlFor="checkBox2"><em></em>개인정보의 파기에 동의합니다.</CheckBox1Label>

                    <SaveNextBtnButton type="submit" className={withDrawalCss.saveNextBtn + " saveNextBtn"} onClick={onClickBtn}>
                        <Link>모두 동의합니다</Link>
                    </SaveNextBtnButton>
                </ContainerDiv>
            </div>
        </>
    );
};
export default WithDrawal1;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    max-width: 100%;
    width: auto;
    height: auto;
    padding: 0px 324px;
    padding-top: 120px;
    @media (max-width: 1400px)  {
        max-width: 100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-top: 120px;
    }
    @media (max-width: 568px){
        padding: 0 22px;
    }
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    text-align: center;
    margin-bottom: 82px;
    @media (max-width: 568px) {
        margin-bottom: 62px;
        margin-top: 70px;
    }
`;

/** h4 태그 .title h4 css */
const TitleH4H4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: "Min Sans-bold";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom: 8px;
`;

/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media (max-width: 568px) {
        font-size: 36px;
    }
`;

/** label 태그 for= service css */
const SeviceLabel = styled.label`
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom: 12px;
`;

/** textarea 태그 #serviceAgree css */
const ServiceAgreeTextarea = styled.textarea`
    padding: 24px 32px;
    color: #828284;
    font-size: 16px;
    letter-spacing: -0.3px;
    font-family: "Min Sans-Regular";
    line-height: 1.8;
    width: 100%;
    height: 352px;
    outline: none;
    border: 1px solid #bdbdbd;
    text-align: left;
    cursor: default;
`;

/** input 태그 #checkbox1 css */
const CheckBox1Input = styled.input`
    display: none;
    &  + label em {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        vertical-align: middle;
        background: url(img/checkbox.png) 0 0 no-repeat;
    }
    &:checked + label em {
        background-position: -38px 0;
    }
`;

/** label 태그 for = checkBox1 css */
const CheckBox1Label = styled.label`
    font-family: "Min sans-bold";
    font-size: 18px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-top: 20px;
    @media (max-width: 568px) {
        font-size: 16px;
    }
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    margin: 62px 0;
    color: #d9d9d9;
    @media (max-width: 568px) {
        margin: 42px 0;
    }
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222a;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 82px;
    margin-bottom: 120px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #303039;
    }
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
    @media (max-width: 568px)  {
        margin-top: 42px;
        margin-bottom: 80px;
    }
`;