import { useEffect, useState } from "react";
import romance1 from '../../css/images/Default_Genre_Pictures/romance/00009-4428472fdg517.webp';
import romance2 from '../../css/images/Default_Genre_Pictures/romance/00022255-2954407022.webp';
import romance3 from '../../css/images/Default_Genre_Pictures/romance/00042-12804e45gc33340.webp'
import romance4 from '../../css/images/Default_Genre_Pictures/romance/00056-3305dfefw041371.webp'
import romance5 from '../../css/images/Default_Genre_Pictures/romance/0211900111-2484840710.webp'
import romance6 from '../../css/images/Default_Genre_Pictures/romance/starcrossed_cover_43dsfd3z43433.webp'
import fantasy1 from '../../css/images/Default_Genre_Pictures/phantasy/1700463113769RpX58t9sZt46Rbf7a0a8kXYdSu9ehOBmCl40uqciLThVAqDMlHNRm4QSrKZlXSLbC1pOZ6.webp'
import fantasy2 from '../../css/images/Default_Genre_Pictures/phantasy/1700463113836c9kcB0WGqXUyA9Gca8qx89W0UyloaiSxDQ3ZoI5iUmGjQloMn0wUdgcj6swKaeGwjXeQnM.webp'
import fantasy3 from '../../css/images/Default_Genre_Pictures/phantasy/1700463114060MWFDc6AP9r0DPiVrBAPOHloVniUSKKszHpkoWqBx23sPupuHinefOldiN7TWyT3aurlxF8.webp'
import fantasy4 from '../../css/images/Default_Genre_Pictures/phantasy/1700463114414Gzs5ptKRmjFnFsC5eMupd79PFFraYNFB8dL1B0Brc9ywzcts4uelspwcoCngFyAbaugR87.webp'
import fantasy5 from '../../css/images/Default_Genre_Pictures/phantasy/1700463114757BN6aEY24UsM1wMe9vrPSuZARTtA4AFIULpFbNJMFMLbrENm19OkzEnhY20RgC9kIljIsOU.webp'
import fantasy6 from '../../css/images/Default_Genre_Pictures/phantasy/17004631142851umIXS82GbqGXBypWgZyruz768DUFGvC1cQJRwLg0IQJTqc7dMJhLfDGfvoJ83G4OINCvU.webp'
import thriller1 from '../../css/images/Default_Genre_Pictures/thriller/1700463062631EY00GchEZ8YdnCFdNSGnvGl4pfg6EiTdB20twwvQjTarGjK9t6JqDJyfLoGD73S2sRmN8v.webp'
import thriller2 from '../../css/images/Default_Genre_Pictures/thriller/1700463062716UCQ96dcZAYfT1CC9dNwb03xLpHz5LvfCi5MeLdkPhidjHaBJ2WZPT3dMeG1qMgDRqtQYh3.webp'
import thriller3 from '../../css/images/Default_Genre_Pictures/thriller/1700463062772RyL8Crw1gfjVtgpbnltgEJmr9W82kNBFAGdsNiihYtse4Aqe5ljlRUkqdeKc69e1iuhhYg.webp'
import thriller4 from '../../css/images/Default_Genre_Pictures/thriller/1700463062826IiEJns0SgotN4wwalpTtcG0mCYpJN2pCoE4FFRvwRBFTxhgF9OXq3DRDARZhGqMvjxM6iI.webp'
import thriller5 from '../../css/images/Default_Genre_Pictures/thriller/17004630629249RnP0g6jLoaLPZCcZeJwGT81qGNYiTjZOUzd9DADsXCKprdqoPxSYcLtTJ123BFMv1nmXQ.webp'
import thriller6 from '../../css/images/Default_Genre_Pictures/thriller/17004630630601GYfEayG2UBEC40X4uwb2nwkCSWlulJ3dCqPpcghJ9v9rCLXbIVvZ0lnWfHfuksH6DppkZ.webp'
import oriental1 from  '../../css/images/Default_Genre_Pictures/martial_arts/1700462970966vqWWJOnqHrvX0P9oi9vuCWAjQtcoAA65JNXj7Vgm52CIefHbZfpjUKEOCQr1OWZHRuN5wD.webp'
import oriental2 from  '../../css/images/Default_Genre_Pictures/martial_arts/1700462971321vFiF6PTaWU7L7eZjbn58D2fgbOBTvJNuSlEejQI01SrzxkXjgzocTfLVb6lnvNCao8ANcn.webp'
import oriental3 from  '../../css/images/Default_Genre_Pictures/martial_arts/1700462971409HMTY26RxysBPi6tOxYLvzqGenznCeNeRN3GZEfycf15E5fUVAlu40VD8YOVTBtxASHLxFs.webp'
import oriental4 from  '../../css/images/Default_Genre_Pictures/martial_arts/1700462971497cRuWZ8RYTye0hlfp7f8tDI5m7OOi9lVY9k0XZ0nJ7o2JreWYXtxTkgt0mlfu4QucQkBVcR.webp'
import oriental5 from '../../css/images/Default_Genre_Pictures/martial_arts/17004629715889d9LakBXjyjsz0bdus0sglRQrnfcF2Mj0RK4mhxUaSMGtDbbCkGgFPP95kRhAJbfeDfO5r.webp'
import oriental6 from '../../css/images/Default_Genre_Pictures/martial_arts/170046297084620wYQTT4wGnlXd1hqxh233rF1Gsct4DqeK8ta4BUlHVe6qYfZxDt2NJ0C7ZfuFWyCJzNXa.webp'
import etc1 from '../../css/images/Default_Genre_Pictures/etc/00004-370435dfsf353.webp'
import etc2 from '../../css/images/Default_Genre_Pictures/etc/00013-21828892sd343g98.webp'
import etc3 from '../../css/images/Default_Genre_Pictures/etc/00024-802624dvnk02.webp'
import etc4 from '../../css/images/Default_Genre_Pictures/etc/00028-62540cxsh690.webp'
import etc5 from '../../css/images/Default_Genre_Pictures/etc/00056-2987366367uf830.webp'
import etc6 from '../../css/images/Default_Genre_Pictures/etc/00066-243860dxxtf0598.webp'
import styled from "@emotion/styled";
import novel2Css from '../../css/novelCreate2.module.css'
import DefaultImg from '../../css/images/illustDefault.png'


// 기본 이미지 카테고리 별 상세 컴포넌트
const NovelCreate2DefaultSectionCategory = ({setImgDefault, categoryId}) => {

    const [imgList, setImgList] = useState([]);

    // 최초 카테고리에 따른 랜더링시 기본이미지 리스트 셋팅
    useEffect(() => {
        switch (categoryId) {
            case 1 : {
                setImgList([{"img" : romance1}, {"img" : romance2 }, {"img" : romance3}, {"img" : romance4}, {"img" : romance5 }, {"img" : romance6 }])
                break;
            }
            case 2 : {
                setImgList([{"img" : fantasy1}, {"img" : fantasy2 }, {"img" : fantasy3}, {"img" : fantasy4}, {"img" : fantasy5 }, {"img" : fantasy6 }])
                break;
            }
            case 3 : {
                setImgList([{"img" : thriller1}, {"img" : thriller2 }, {"img" : thriller3}, {"img" : thriller4}, {"img" : thriller5 }, {"img" : thriller6 }])
                break;
            }
            case 4 : {
                setImgList([{"img" : oriental1}, {"img" : oriental2 }, {"img" : oriental3}, {"img" : oriental4}, {"img" : oriental5 }, {"img" : oriental6 }])
                break;
            }
            case 5 : {
                setImgList([{"img" : etc1}, {"img" : etc2 }, {"img" : etc3}, {"img" : etc4}, {"img" : etc5 }, {"img" : etc6 }])
                break;
            }
        }
    }, [])

    // 라디오 버튼 체크
    const handleClickRadioBtn = (e) => {
        const url = e.target.value;
        const convertToBlob = async () => {
            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File([blob], 'image.webp', { type: 'image/webp' })
            setImgDefault(file);
        };
        
        convertToBlob();
    }

    const noImage = (e) => {
        e.target.src = DefaultImg
    }

    return(
        <>
            {imgList && imgList.map((img, index) => {return(
                    <IlluMeListColDiv className={novel2Css.col + " col"} key={"imgKey" + categoryId + "_" + index}>
                        <IlluMeListColImg src={img?.img} aria-valuetext={img?.img} alt="imgList_illu_img" onError={noImage}/>
                        <IlluMeRadioInput
                            type="radio"
                            name="option"
                            value={img?.img}
                            id={"img_" + categoryId + "_" + index}
                            className="illu-me-radio"
                            onChange={handleClickRadioBtn}
                        />
                    </IlluMeListColDiv>
            )})}
        </>
    );
};
export default NovelCreate2DefaultSectionCategory;

/** div 태그 .illu-me-list > .col css */
const IlluMeListColDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-right: 3px;
    display: inline-grid;
`;

/** img 태그 .illu-me-list > .col > img css */
const IlluMeListColImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    max-height: 200px;
    margin-top: 20px;
    @media (min-width: 100px) and (max-width: 768px) {
        width: 100px; /* 이미지 크기 조정 */
        height: 100px;
    }
`;

/** input 태그 .illu-me-radio css */
const IlluMeRadioInput = styled.input`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 8px !important;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    margin-bottom: 8px;
`;