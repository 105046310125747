import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import myPageListCss from '../../css/mypage_list.module.css'
import { getCookie } from "../../hooks/Cookie";
import { useRecoilValue } from "recoil";
import axios from "axios";
import $ from 'jquery';
import URL from "../../constants/url";
import { apiServerState } from "../../constants/recoilData";


const PaymentSection = ({category, pageNum, setPageNum}) => {

    const [totalPage, setTotalPage] = useState(1);
    const [paymentList, setPaymentList] = useState([]);
    const apiServer = useRecoilValue(apiServerState);

    const token = getCookie("token");
    const navigate = useNavigate();

    const cate = JSON.parse(localStorage.getItem("categoryPay"));

    // ======================  페이징 처리  ======================

    let paginationTag = [];

    const currentPageNo = pageNum;
    const pageSize = 5;
    const totalPageCount = totalPage;

    const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
    let currentLastPage = currentFirstPage + pageSize - 1;

    currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

    if (totalPageCount > pageSize) {
        // 첫 페이지 이동
        const firstPageTag = <><PaginationLink onClick={() => {pageCall(category, 1)}}>&lt;&lt;</PaginationLink></>;
        paginationTag.push(firstPageTag);

        // 이전 페이지 이동
        const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
        const previousPageTag = <><PaginationLink onClick={() => {pageCall(category, prevPageIndex)}}>&lt;</PaginationLink></>;
        paginationTag.push(previousPageTag);
    }

    for (let i = currentFirstPage; i <= currentLastPage; i++) {
        if (i === currentPageNo) {
            // 현재 페이지
            const currentPage = <><PaginationLink className={myPageListCss.current_page + " current_page"} onClick={() => {pageCall(category, i)}}>{i}</PaginationLink></>;
            paginationTag.push(currentPage);
        } else {
            // 다른 페이지
            const otherPage = <><PaginationLink onClick={() => {pageCall(category, i)}}>{i}</PaginationLink></>;
            paginationTag.push(otherPage);
        }
    }
    if (totalPageCount > pageSize) {
        // 다음 페이지 이동
        const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
        const nextPageTag = <><PaginationLink onClick={() => {pageCall(category, nextPageIndex)}}>&gt;</PaginationLink></>;
        paginationTag.push(nextPageTag);
        
        // 마지막 페이지 이동
        const lastPageTag = <><PaginationLink onClick={() => {pageCall(category, totalPage)}}>&gt;&gt;</PaginationLink></>;
        paginationTag.push(lastPageTag);
    }

    const pageCall = (category, page) => {
        setPageNum(page);
        urlCallPage(category, page);
        const data = {...cate, "page" : page};
        localStorage.setItem("categoryPay", JSON.stringify(data));
    }

    // =====================  페이징 처리 끝  =========================

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
        }
    };

    const url = apiServer + "/api/aizac_trade/trade_history?itype=" + category + "&page=" + pageNum + "&row=10";
    
    // api 호출 함수
    const urlCall = async () => {
        axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success) {
                    setTotalPage(response.data.data.totalPages);
                    setPaymentList(response.data.data.tradeList);
                } else {
                    alert(response?.data?.custMsg);
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    };

    // api 호출 함수(카테고리, 페이지넘버 입력 -> 카테고리 유지)
    const urlCallPage = async (categoryData, page) => {
        const url = apiServer + "/api/aizac_trade/trade_history?itype=" + categoryData + "&page=" + page + "&row=10";
        axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success ) {
                    setTotalPage(response.data.data.totalPages);
                    setPaymentList(response.data.data.tradeList);
                    
                } else {
                    alert(response?.data?.custMsg);
                    console.log("로딩 실패");
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    };
    
    // 최초 랜더링시 api 호출 셋팅
    useEffect(() => {
        const cate = JSON.parse(localStorage.getItem("categoryPay"));
        if(cate !== null && cate !== undefined) {
            setPageNum(cate.page);
            pageCall(category, cate.page);
        } else {
            if(pageNum !== null && pageNum !== undefined) {
                urlCall();
            }
        }
    }, []);

    const rankSynopRef = useRef();
    
    useEffect(() => {
        if(pageNum !== null && pageNum !== undefined) {
            window.scrollTo({
                top: 0,
                behavior: 'instant'
            })
            rankSynopRef.current.scrollTop = 0;
            $("#container").scrollTop(0);
            pageCall(category, pageNum);
        }
    }, [pageNum])

    return (
        <>
            <RankSynopSection className={myPageListCss.rank_synop + " rank_synop"} ref={rankSynopRef}>
                <div className={myPageListCss.table_container + " table_container"}>
                    <AllTable className={myPageListCss.all + " all"}>
                        {paymentList.length !== 0 ? <tr>
                            <DefaultTh className={myPageListCss.status + " status"} style={{width: "0.01%"}}>구분</DefaultTh>
                            <DefaultTh className={myPageListCss.clock + " clock"} style={{padding: "10px", width: "15%"}}>일시</DefaultTh>
                            <DefaultTh className={myPageListCss.info + " info"} style={{width: "20%"}}>내용</DefaultTh>
                            <DefaultTh className={myPageListCss.user + " user"}>유저</DefaultTh>
                            <DefaultTh className={myPageListCss.money + " money"}>금액</DefaultTh>
                            {category === "point" ? <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>정산가능금액</DefaultTh> :
                            category === "all" ? <>
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>잔여코인</DefaultTh>
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>잔여포인트</DefaultTh>
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>정산가능금액</DefaultTh>
                            </> :
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>잔여코인</DefaultTh>}
                        </tr> : <><tr>
                            <DefaultTh className={myPageListCss.status + " status"} style={{width: "0.01%"}}>구분</DefaultTh>
                            <DefaultTh className={myPageListCss.clock + " clock"} style={{padding: "10px", width: "15%"}}>일시</DefaultTh>
                            <DefaultTh className={myPageListCss.info + " info"} style={{width: "20%"}}>내용</DefaultTh>
                            <DefaultTh className={myPageListCss.user + " user"}>유저</DefaultTh>
                            <DefaultTh className={myPageListCss.money + " money"}>금액</DefaultTh>
                            {category === "point" ? <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>정산가능금액</DefaultTh> :
                            category === "all" ? <>
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>잔여코인</DefaultTh>
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>잔여포인트</DefaultTh>
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>정산가능금액</DefaultTh>
                            </> :
                            <DefaultTh className={myPageListCss.remaincoin + " remaincoin"}>잔여코인</DefaultTh>}
                        </tr>
                        <tr>
                            <DefaultTd className={myPageListCss.status + " status"} style={{width: "0.01%"}}> - </DefaultTd>
                            <DefaultTd className={myPageListCss.clock + " clock"} style={{padding: "10px", width: "15%"}}> - </DefaultTd>
                            <DefaultTd className={myPageListCss.info + " info"} style={{width: "20%"}}> - </DefaultTd>
                            <DefaultTd className={myPageListCss.user + " user"}> - </DefaultTd>
                            <DefaultTd className={myPageListCss.money + " money"}> - </DefaultTd>
                            {category === "point" ? <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}> - </DefaultTd> :
                            category === "all" ? <>
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}> - </DefaultTd>
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}> - </DefaultTd>
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}> - </DefaultTd>
                            </> :
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}> - </DefaultTd>}
                            
                        </tr></>}
                        {paymentList && paymentList.map((payment) => {return(<tr key={payment?.id}>
                            <DefaultTd className={myPageListCss.status + " status"} style={{width: "0.01%"}}>{payment?.ikindName}</DefaultTd>
                            <DefaultTd className={myPageListCss.clock + " clock"} style={{padding: "10px", width: "15%"}}>
                            {(payment?.createdAt + "").substring(0,10)}<br/>
                            {(payment?.createdAt + "").substring(11,19)}</DefaultTd>
                            <DefaultTd className={myPageListCss.info + " info"} style={{width: "25%"}}>{payment?.content}</DefaultTd>
                            <DefaultTd className={myPageListCss.user + " user"}>{payment?.tradeUserNickname}</DefaultTd>
                            {payment?.itype !== "point" ? <DefaultTd className={myPageListCss.money + " money"}>{payment?.ikind} {payment?.price}</DefaultTd> :
                            <DefaultTd className={myPageListCss.money + " money"}>{payment?.ikind} {payment?.price}</DefaultTd>}
                            {category === "point" ? <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}>{payment?.remainingPoint}</DefaultTd> : 
                            category === "all" ? <>
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}>{payment?.remainingCoin}</DefaultTd>
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}>{payment?.remainingFreecoin}</DefaultTd>
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}>{payment?.remainingPoint}</DefaultTd>
                            </> : 
                            <DefaultTd className={myPageListCss.remaincoin + " remaincoin"}>{payment?.remainingPoint}</DefaultTd>}
                        </tr>)})}
                    </AllTable>
                </div>
                <PaginationDiv className={myPageListCss.pagination + " pagination"}>
                    {paginationTag}
                </PaginationDiv>
            </RankSynopSection>
        </>
    );
};
export default PaymentSection;

/** div 태그 .pagination css */
const PaginationDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

/** a 태그 .pagination css */
const PaginationLink = styled(Link)`
    text-decoration: none;
    margin: 5px;
    padding: 5px 10px;
    color: #333;
    font-family: "Min Sans-Light";
    font-size: 14px;
    &:hover {
        background-color: #fff;
        color: #ff5f37;
    }
`;

/** section 태그 .rank_synop css */
const RankSynopSection = styled.section`
    padding: 0px 0px;
    padding-bottom: 120px;
    max-width: 1320px;
    margin: 0 auto;
    @media (min-width: 100px) and (max-width: 768px) {
        padding-bottom: 80px;
    }
`;

/** table 태그 .all css */
const AllTable = styled.table`
    text-align: center;

    border-collapse: collapse;
    color: #22222a;
    font-size: 15px;
    font-family: "min sans-regular";
    letter-spacing: -.3px;
    white-space: nowrap;
    overflow-x: auto;
    display: block;
    &::-webkit-scrollbar{
        height: 6px;
        background-color: #ededed;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #d9d9d9;
        border-radius: 5px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        overflow-x: auto;
    }
`;

/** th 태그 디폴트 css */
const DefaultTh = styled.th`
    padding: 16px 10px;
    border: 1px solid #bdbdbd;
    word-break : break-all;
    font:inherit;
    @media (min-width: 768px) and (max-width: 820px) {
        font-size: 12px;
    }
`;

/** td 태그 디폴트 css */
const DefaultTd = styled.td`
    padding: 20px;
    border: 1px solid #bdbdbd;
    height: auto;
    white-space : normal;
    word-break : break-all;
    @media (min-width: 768px) and (max-width: 820px) {
        font-size: 12px;
    }
`;