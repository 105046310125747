import URL from "../../constants/url";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import sign1Css from '../../css/sign1.module.css'
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { apiServerState } from "../../constants/recoilData";
import { getCookie } from "hooks/Cookie";
import axios from 'axios';


const Sign1 = () => {

    // 토큰 검사
    useEffect(() => {
        const token = getCookie('token');
        if (token !== undefined) {
            navigate(URL.MAIN);
        }
    }, [])

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);

    const [termSertviceData, setTermSertviceData] = useState(null);
    const [termPersonalData, setTermPersonalData] = useState(null);
    const [isUpdateService, setIsUpdateService] = useState(false);
    const [isUpdatePersonal, setIsUpdatePersonal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");

    
    
    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        };
    
        const urlService = apiServer + "/api/aizac_notice/get_a_policy?ikind=service_policy";
        const urlPersonal = apiServer + "/api/aizac_notice/get_a_policy?ikind=privacy_policy";
        
        axios
        .get(urlService, config)
        .then((response) => {
            if (response?.data?.success) {
                setTermSertviceData(response?.data?.data?.policy);
                setIsUpdateService(true);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "invalid data") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(msg);
                }
                navigate(URL.MAIN);
                console.log("로딩 실패");
                console.log(msg);
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
        axios
        .get(urlPersonal, config)
        .then((response) => {
            if (response?.data?.success) {
                setTermPersonalData(response?.data?.data?.policy);
                setIsUpdatePersonal(true);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "invalid data") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(msg);
                }
                navigate(URL.MAIN);
                console.log("로딩 실패");
                console.log(msg);
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    }, [])

    useEffect(() => {
        if (isUpdateService === true && isUpdatePersonal === true) {
            setIsUpdate(true);
        }
    }, [isUpdateService, isUpdatePersonal])


    const navigate = useNavigate();

    // 서비스 이용약관 체크
    const onChangeCheck1 = (e) => {
        setChecked1(e.target.checked);
    }
    // 개인정보 처리방침 체크
    const onChangeCheck2 = (e) => {
        setChecked2(e.target.checked);
    }

    // 두 약관 체크 여부 검사 후 이동(모두 동의합니다 버튼)
    const onClickBtn = () => {
        if (checked1 === false) {
            alert(`서비스 이용약관을 동의하셔야 회원가입이 진행됩니다.`);
            return;
        } else if (checked2 === false) {
            alert(`개인정보 처리방침을 동의하셔야 회원가입이 진행됩니다.`);
            return;
        } else if ((checked1 === true) && (checked2 === true)) {
            navigate(URL.SIGN_SIGN_INPUT);
        } 
    }


    return (
        <>
            <div id="wrap">
                <ContainerDiv id="container">
                    <TitleDiv className={sign1Css.title + " title"}>
                        <TitleH4H4>아이작 회원가입</TitleH4H4>
                        <TitleH2H2>이용약관 동의</TitleH2H2>
                    </TitleDiv>
                    {isUpdate === true ?<div className={sign1Css.serviceOk + " serviceOk"}>
                        <ServiceLabel htmlFor="service">{termSertviceData?.title}</ServiceLabel>
                        <DefaultTextareaDiv id="serviceAgree"  dangerouslySetInnerHTML={{
                                __html: termSertviceData && termSertviceData?.content,
                        }}/>
                    </div> : <></>}
                    <CheckBoxInput type="checkbox" id="checkBox1" onChange={onChangeCheck1}/>
                    <CheckBoxLabel htmlFor="checkBox1"><CheckBoxLabelEm></CheckBoxLabelEm>서비스 이용약관에 동의합니다.</CheckBoxLabel>
                    <DefaultHr/>
                    {isUpdate === true ?<div className={sign1Css.serviceOk + " serviceOk"}>
                        <ServiceLabel htmlFor="service">{termPersonalData?.title}</ServiceLabel>
                        <DefaultTextareaDiv id="serviceAgree"  dangerouslySetInnerHTML={{
                                __html: termPersonalData && termPersonalData?.content,
                        }}/>
                    </div> : <></>}
                    <CheckBoxInput type="checkbox" id="checkBox2" onChange={onChangeCheck2}/>
                    <CheckBoxLabel htmlFor="checkBox2"><CheckBoxLabelEm></CheckBoxLabelEm>개인정보 처리방침에 동의합니다.</CheckBoxLabel>
                    
                    <SaveBextBtnButton type="submit" className={sign1Css.saveNextBtn + " saveNextBtn"} onClick={onClickBtn}><DefaultALink>모두 동의합니다</DefaultALink></SaveBextBtnButton>
                    
                </ContainerDiv>
            </div>
        </>
    );
}
export default Sign1;

/** textarea 태그 디폴트 css 
const DefaultTextarea = styled.textarea`
    padding: 24px 32px;
    color: #828284;
    font-size: 16px;
    letter-spacing: -0.3px;
    font-family: "Min Sans-Regular";
    line-height: 1.8;
    width: 100%;
    height: 352px;
    outline: none;
    border: 1px solid #BDBDBD;
    text-align: left;
    cursor: default;
    white-space: pre-line;
    overflow : auto;
    &::-webkit-scrollbar {
        display: block;
    }
`;*/

/** div 태그 디폴트 css */
const DefaultTextareaDiv = styled.div`
    padding: 24px 32px;
    color: #828284;
    font-size: 16px;
    letter-spacing: -0.3px;
    font-family: "Min Sans-Regular";
    line-height: 1.8;
    width: 100%;
    height: 352px;
    outline: none;
    border: 1px solid #BDBDBD;
    text-align: left;
    cursor: default;
    white-space: pre-line;
    overflow: auto;
    &::-webkit-scrollbar {
        display: block;
        width: 8px;  /* 스크롤바의 너비 */
    }
    &::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: black; /* 스크롤바의 색상 */
        
        border-radius: 10px;
    }
    &body::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
    }
    @media (min-width: 100px) and (max-width: 768px) {
        overflow-x: hidden;
    }
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 324px;
    padding-top: 120px;
    @media (max-width: 1400px) {
        max-width:100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-top: 120px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
    }
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    text-align: center;
    margin-bottom: 82px;
    @media(max-width:568px){
        margin-bottom: 62px;
        margin-top: 70px;
    }  
`;

/** h4 태그 .title h4 css */
const TitleH4H4 = styled.h4`
    font-family: "Min Sans-bold";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom:8px;
    @media(max-width:568px) {
        font-size: 18px;
    }
`;

/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media(max-width:568px) {
        font-size: 36px;
    }
`;

/** label 태그 for="service" css */
const ServiceLabel = styled.label`
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom: 12px;
`;

/** input 태그 #checkBox1 #checkBox2 css */
const CheckBoxInput = styled.input`
    display: none;
    &:checked + label em {
        background-position: -38px 0;
    }
`;

/** label 태그 for="checkBox1", for="checkBox2" css */
const CheckBoxLabel = styled.label`
    font-family: "Min sans-bold";
    font-size: 18px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-top: 20px;
    @media(max-width:568px) {
        font-size: 16px;
    }
`;

/** em 태그 input[id="checkBox1"] + label em input[id="checkBox2"] + label em css */
const CheckBoxLabelEm = styled.em`
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
    background: url(../../css/images/checkbox.png) 0 0 no-repeat;
`;

/** hr 태그 디폴트 css */
const DefaultHr = styled.hr`
    margin: 62px 0;
    color: #d9d9d9;
    @media(max-width:568px) {
        margin: 42px 0;
    }
`;

/** button 태그 .saveNextBtn css */
const SaveBextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 82px;
    margin-bottom: 120px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
    @media(max-width:568px) {
        margin-top: 42px;
        margin-bottom: 80px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;