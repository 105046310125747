import styled from '@emotion/styled';
import noticeTermsCss from '../../css/noticeTerms.module.css'
import { useEffect, useState } from 'react';
import { getCookie } from 'hooks/Cookie';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { apiServerState } from 'constants/recoilData';
import { useNavigate } from "react-router-dom";
import URL from 'constants/url';


const TermYouth = () => {

    const [policy, setPolicy] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        };
    
        const url = apiServer + "/api/aizac_notice/get_a_policy?ikind=youth_protection_policy";
        
        axios
        .get(url, config)
        .then((response) => {
            if (response?.data?.success) {
                setPolicy(response?.data?.data?.policy);
                setIsUpdate(true);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === "invalid data") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert(msg);
                }
                navigate(URL.MAIN);
                console.log("로딩 실패");
                console.log(msg);
            }
        })
        .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });

    }, [])

    return (
        <>
            <ContainerDiv className={noticeTermsCss.container + " container"} >
                <section className={noticeTermsCss.rank_veteran + " rank_veteran rank-veteran"}>
                    <section className={noticeTermsCss.synop_select + " synop_select synop-select"}>
                        {isUpdate === true ? <div className={noticeTermsCss.rank_title + " rank_title rank-title"}>
                            <h1>{policy?.title}</h1>
                            <hr className={noticeTermsCss.notice_hr + " notice_hr notice-hr"} />
                            <div className={noticeTermsCss.service_use_content + " service_use_content service-use-content"} style={{fontSize: "1.3rem"}} dangerouslySetInnerHTML={{
                                __html: policy && policy?.content,
                            }}>   
                            </div>
                        </div> : <></>}
                    </section>
                </section>
            </ContainerDiv>
        </>
    );
};
export default TermYouth;

/** table 태그 .tg css 
const TgTable = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
`;*/

/** th 태그 .tg th .tg-0lax css 
const TgThTg0laxTh = styled.th`
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: "Min Sans-Light";
    font-size: 18px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 20px;
    word-break: normal;
    text-align: left;
    vertical-align: middle;
    text-align: center;
`;*/

/** td 태그 .tg td .tg-0lax css 
const TgTdTg0laxTd = styled.td`
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: "Min Sans-Light";
    font-size: 18px;
    overflow: hidden;
    padding: 10px 20px;
    word-break: normal;
    text-align: left;
    vertical-align: middle;
    text-align: center;
    
`;*/

/** div 태그 .container css */
const ContainerDiv = styled.div`
`;