import { Link, useNavigate } from "react-router-dom";
import find1Css from '../../css/find_password_1.module.css'
import styled from "@emotion/styled";
import { getCookie } from "../../hooks/Cookie";
import URL from "../../constants/url";
import { useState } from "react";
import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import sign2Css from '../../css/sign2.module.css'
import { apiServerState, tempPasswordState } from "../../constants/recoilData";

const FindPassword1 = () => {

    const token = getCookie("token");
    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);

    if (token !== undefined) {
        navigate(URL.MAIN);
    }

    const [name, setName] = useState('');

    // 제목 입력 변경 함수
    const onChangeName = (e) => {
        setName(e.target.value);
    };


    const [email, setEmail] = useState('');

    // 이메일 입력 변경 함수
    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const [errorMessage, setErrorMessage] = useState('');

    const setTempPassword = useSetRecoilState(tempPasswordState);

    // 비밀번호 찾기 버튼
    const onClickFind = () => {
        if (name === '') {
            alert(`이름(실명)을 입력하세요.`);
            return;
        } else if (name === null || name === undefined) {
            alert(`이름(실명)을 입력하세요.`);
            return;
        }
        if (email === '') {
            alert('아이디(이메일)을 입력하세요.');
            return;
        } else if (email === null || email === undefined) {
            alert('아이디(이메일)을 입력하세요.');
            return;
        }
        const form = {realname: name, email: email};

        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };

        axios
        .post(apiServer + "/api/asiac_login/find_password", form, config)
        .then((response) => {
            if (response?.data?.success) {
                setTempPassword(response.data.data.tempPass)
                navigate(URL.FIND_PASSWORD2);
            } else {
                const msg = response?.data?.custMsg;
                if (msg === 'invalid user') {
                    alert("찾을수 없는 유저입니다.");
                    setErrorMessage("찾을수 없는 유저입니다.");
                } else if (msg === "router err") {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else {
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                }
                console.log(msg);
            }
        })
        .catch((e) => {
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    }


    return (
        <>
            <div id="wrap">
                <FindContainerDiv id="findContainer">
                    <FindBoxDiv className={find1Css.findBox + " findBox"}>
                        <DefaultH4>비밀번호 찾기</DefaultH4>
                        <UserInputDiv className={find1Css.userInput + " userInput"}>
                            <InputRowDiv className={find1Css.inputRow + " inputRow"}>
                                <LabelLabel htmlFor="realName" className={find1Css.label + " label"}>이름(실명)</LabelLabel>
                                <UserInputInput type="text" id="realName" placeholder="반드시 실명을 입력해주세요" onChange={onChangeName} />
                            </InputRowDiv>
                            <InputRowDiv className={find1Css.inputRow + " inputRow"}>
                                <LabelLabel htmlFor="nickname" className={find1Css.label + " label"}>아이디(이메일)을 입력해주세요</LabelLabel>
                                <UserInputInput type="text" id="nickname" placeholder="아이디(이메일)을 입력해주세요" onChange={onChangeEmail}/>
                            </InputRowDiv>
                            {errorMessage !== '' ?<div className={sign2Css.descript + " descript"} style={{marginBottom: "10px"}}>
                                <DescriptSpanSpan>{errorMessage}</DescriptSpanSpan>
                            </div> : <></>}
                            <SaveNextBtnButton type="submit" className={find1Css.saveNextBtn + " saveNextBtn"} onClick={onClickFind}><DefaultALink >비밀번호 찾기</DefaultALink></SaveNextBtnButton>
                        </UserInputDiv>
                    </FindBoxDiv>
                </FindContainerDiv>
            </div>
        </>
    );
}
export default FindPassword1;

/** div 태그 #findContainer css */
const FindContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .findBox css */
const FindBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
`;

/** h4 태그 디폴트 css */
const DefaultH4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-top: 140px;
    margin-bottom: 82px;
`;

/** div 태그 .userInput css */
const UserInputDiv = styled.div`
    width: 100%;
`;

/** input 태그 .userInput input css */
const UserInputInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
`;

/** div 태그 .inputRow css */
const InputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    &:first-of-type {
        margin-bottom: 24px;
    }
`;

/** label 태그 .label css */
const LabelLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 52px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;