import ListSelectTab from "../../components/common/ListselectTab";
import freezonCss from '../../css/freezon.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "@emotion/styled";
import { useEffect } from "react";


// 프리존 페이지 컴포넌트
const Freezon = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    return (
        <>
            <div className={freezonCss.container + " container"}>
                <RankVeteranSection className={freezonCss.rank_veteran + " rank_veteran"}>
                    <section className={freezonCss.synop_select + " synop_select"}>
                        <RankTitleDiv className={freezonCss.rank_title + " rank_title"}>
                            <RankTitleH2H2>견습 작가 자유연재 게시판</RankTitleH2H2>
                            <RankTitleH1H1>아이작 프리존</RankTitleH1H1>
                        </RankTitleDiv>
                        <ListSelectTab type={"novice"}/>
                    </section>
                </RankVeteranSection>
            </div>
        </>
    );
};

export default Freezon;

/** section 태그 .rank-veteran css */
const RankVeteranSection = styled.section`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0px 0px;
    margin-bottom: 120px;
`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 60px;
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 20px 0px 0px;
    }
`;

/** h2 태그 .rank-title h2 css */
const RankTitleH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 20px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 0;
`;

/** h1 태그 .rank-title h1 css */
const RankTitleH1H1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 46px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    padding-bottom: 22px;
    padding-top: 8px;
    padding-bottom: 22px;
    padding-top: 8px;
`;