import { Cookies } from "react-cookie"

const cookies = new Cookies();

export const setCookie = (name, value) => {
    return cookies.set(name, value);
}

export const setCookieOption = (name, value, option) => {
    return cookies.set(name, value, option);
}

export const getCookie = (name) => {
    return cookies.get(name);
}

export const removeCookie = (name, data) => {
    //console.log("쿠키 삭제");
    return cookies.remove(name, {path : data});
}
export const removeCookieOption = (name, option) => {
    //console.log("쿠키 삭제");
    return cookies.remove(name, option);
}