import { Link, useNavigate, useParams } from 'react-router-dom'
import SessionCss from '../../css/write-session.module.css'
import styled from '@emotion/styled';
import NovelInfoNav from './NovelInfoNav';
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import URL from '../../constants/url';
import { apiServerState } from '../../constants/recoilData';
import Swal from 'sweetalert2';


const NovelDetail = () => {
    let { novelId, sessionId } = useParams();
    const token = getCookie("token");
    const [episodes, setEpisodes] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();
    const [rowCnt, setRowCnt] = useState(10);
    const [userInfo, setUserInfo] = useState(null);
    const [bPurchased, setBPurchased] = useState(null);
    const [pubState, setPubState] = useState("public");
    const [novel, setNovel] = useState(null);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 소설 정보에 따른 비공개 작품, 구매 여부, 체크 후 소설 에피소드 상세 정보, 유저정보 셋팅
    useEffect(() => {
        if (pubState === "private") {
            alert("비공개 작품입니다.");
            navigate("/list/novel/" + novelId);
            return;
        }
        if (bPurchased === null) {
            return;
        } else if (bPurchased === false) {
            alert("구매후 이용하실수 있습니다.")
            navigate("/list/novel/" + novelId);
            return;
        } else if (bPurchased === true) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : token,
                }
            };
        
            const update = () => {
    
                const urlEpi = apiServer + "/api/aizac/episode/row_cnt_episodes?novelId=" + novelId + "&rowCnt=" + rowCnt;
                const urlUser = apiServer + "/api/asiac_login/get_my_info";
                
                const urlEpiCall = async () => {
                    axios
                        .get(urlEpi, config)
                        .then((response) => {
                            if (response?.data?.success ) {
                                const copy = response?.data?.data?.episodes;
                                const copy2 = [];
                                for (let i = 0; i < copy.length; i++) {
                                    if (copy[i].pubState === "public") {
                                        copy2.push(copy[i]);
                                    }
                                }
                                setEpisodes(copy2);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                navigate(URL.MAIN);
                                console.log("로딩 실패");
                            }
                        })
                        .catch(e => {  // API 호출이 실패한 경우
                            alert(e.message);
                            console.log(e);
                            navigate(URL.ERROR, {state: {msg: e.message}});
                        });
                };
                const userInfo = async () => {
    
                    await axios
                    .get(urlUser, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUserInfo(response?.data?.data?.user);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
    
                urlEpiCall();
                if(token !== undefined) {
                    userInfo();
                }
            };
            update();
        }

    }, [rowCnt, bPurchased, pubState]);

    // 최초 랜더링시 소설 정보 셋팅함수
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
    
        const update = () => {

            const urlEpi = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + sessionId;
            
            const urlEpiCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            const bPurchasedData = response?.data?.data?.episode?.bPurchased;
                            setBPurchased(bPurchasedData);
                            const pubStateData = response?.data?.data?.episode?.pubState;
                            setPubState(pubStateData)
                            const novel = response?.data?.data?.novel;
                            setNovel(novel);
                            if (bPurchasedData === false) {
                                alert("구매후 이용하실수 있습니다.")
                                navigate("/list/novel/" + novelId);
                            }
                            if (pubStateData === "private") {
                                alert("비공개 작품입니다.");
                                navigate("/list/novel/" + novelId);
                            }
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid episode") {
                                alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else if (msg === "router err") {
                                alert("서버 에러");
                                navigate("/list/novel/" + novelId);
                            } else {
                                alert(msg);
                                navigate("/list/novel/" + novelId);
                            }
                            console.log("로딩 실패!");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };


            urlEpiCall();
        };
        update();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        const localData = localStorage.getItem("rowCnt");
        if(localData !== null) {
            setRowCnt(localData);
        }
    }, []);

    // 더보기 버튼 
    const onClickRowCnt = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        if (episodes.length >= rowCnt) {
            const newRow = parseInt(rowCnt) + 10;
            setRowCnt(newRow);
        }
    } 

    // 회차 클릭
    const onClickNovelDetail = (episode) => {
        localStorage.setItem("rowCnt", rowCnt);
        if(novel?.proficiency !== "novice") {
            if (episode.bPurchased) {
                const url = '/novel/session/' + episode.novelId + '/' + episode.id;
                navigate(url);
            } else {
                Swal.fire({
                    title : "작품 구매",
                    text: "유료소설은 1화 당 50코인이 소모됩니다",
                    showCancelButton: true,
                    confirmButtonColor: "#FF5F37",
                    cancelButtonColor: "#bdbdbd",
                    cancelButtonText: "취소",
                    confirmButtonText: "구매",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        if (token === undefined) {
                            navigate(URL.LOGIN);
                            return;
                        }
                        if(((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                        (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50 || userInfo?.coinAuth === "unlimited") {
                            const config = {
                                headers: {
                                    "Content-Type": "application/json",
                                    "authorization": token,
                                }
                            };
    
                            const url = apiServer + "/api/asiac_novel/use_episode_coin"
    
                            const form = {
                                "novelId" : episode.novelId,
                                "episodeId" : episode.id
                            }
    
                            axios
                                .post(url, form, config)
                                .then((response) => {
                                    if (response?.data?.success) {
                                        navigate('/novel/session/' + episode.novelId + '/' + episode.id);
                                    } else {
                                        const msg = response?.data?.custMsg
                                        if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                            alert("다시 로그인 후 이용해주세요.");
                                            logout();
                                        } else if (msg === "invalid novel") {
                                            alert("이용하실수 없는 소설입니다.");
                                        } else if (msg === "invalid episode") {
                                            alert("이용하실수 없는 회차입니다.")
                                        } else if (msg === "router err") {
                                            alert("서버 에러")
                                        } else {
                                            alert("구매 실패 : " + msg);
                                        }
                                        console.log(msg);
                                    }
                                })
                                .catch(e => {  // API 호출이 실패한 경우
                                    alert(e.message);
                                    console.log(e);
                                    navigate(URL.ERROR, {state: {msg: e.message}});
                                });
    
                        } else {
                            Swal.fire({
                                title : "작품 구매",
                                text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                showCancelButton: true,
                                confirmButtonColor: "#FF5F37",
                                cancelButtonColor: "#bdbdbd",
                                cancelButtonText: "취소",
                                confirmButtonText: "코인 충전",
                                reverseButtons: true,
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.confirm) {
                                    navigate(URL.LIST_CHARGE_SHOP);
                                } 
                            });
                        }
                    } 
                });
            }
        } else {
            navigate('/novel/session/' + episode.novelId + '/' + episode.id);
        }
    }

    return(
        <>
            <NovelInfoNav />
            <ContainerDiv id="container">
                {isMounted !== false ? <div className={SessionCss.episode + " episode"}>
                    <EpiTitDiv className={SessionCss.epiTit + " epiTit"}>회차정보 (<span>{novel?.episodeCnt}</span>)</EpiTitDiv>
                    <EpiTableTable className={SessionCss.epiTable + " epiTable"} key={"novel_tabel"}>
                        <tbody>
                        {episodes && episodes.map((episode) => {return(<EpiTableTr key={episode?.id} onClick={() => {onClickNovelDetail(episode)}}>
                            <NumTd className={SessionCss.num + " num"}><DefaultALink >ep.<span>{episode?.chapter}</span></DefaultALink></NumTd>
                            <TitleTd className={SessionCss.title + " title"}><DefaultALink >{(String(episode?.title)).trim()}</DefaultALink></TitleTd>
                            <ViewTd className={SessionCss.view + " view"}>
                                <span>
                                    <svg className="feather feather-eye" xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 24 14" fill="none" style={{stroke: "#a8a8a9", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round", marginBottom:"10px"}} >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </span>
                                <span>조회수 {parseInt(episode?.views) >= 1000 ? (parseInt(episode?.views) / 1000).toFixed(1) + "k" : parseInt(episode?.views) }</span>
                            </ViewTd>
                            <DateTd className={SessionCss.date + " date"}>{(episode?.createdAt + "").substring(0,10)}</DateTd>
                        </EpiTableTr>)})}
                        </tbody>
                    </EpiTableTable>

                    <LoadMoreBtnButton type="button" className={SessionCss.loadMoreBtn + " loadMoreBtn"} onClick={onClickRowCnt}>
                        <LoadMoreBtnSpan>더보기</LoadMoreBtnSpan>
                        <LoadMoreBtnSpan><i className="fa-solid fa-chevron-down"></i></LoadMoreBtnSpan>
                    </LoadMoreBtnButton>
                </div> : <></>}
            </ContainerDiv>
        </>
    );
}
export default NovelDetail;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
    padding-bottom: 100px;
    @media(max-width: 1400px) {
        max-width:100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-bottom: 80px;
    }
    @media(min-width:100px) and (max-width: 768px) {
        padding: 0 22px;
    }
`;

/** div 태그 .epiTit css */
const EpiTitDiv = styled.div`
    color:#FF5F37;
    font-size: 18px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    margin-bottom: 24px;
    margin-top: 24px;
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
    }
`;

/** table 태그 .epiTable css */
const EpiTableTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
`;

/** tr 태그 .epiTable tr css */
const EpiTableTr = styled.tr`
    border-bottom: 1px solid #ededed;
    cursor: pointer;
    &:first-of-type{
        border-top: 1px solid #ededed;
    }
`;

/** td 태그 .num css */
const NumTd = styled.td`
    padding: 20px 0;
    width: 10%;
    text-align: center;
    color: #22222A;
    font-size: 18px;
    @media(max-width:1386px) {
        width: 10%;   
    }
    @media(max-width: 1400px) {
        width: 15%;  
    }
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
        width: 12%;
    }
`;

/** td 태그 .title css */
const TitleTd = styled.td`
    padding: 20px 0;
    width: 60%;
    color: #22222A;
    font-size: 18px;
    font-family: "Min Sans-Regular";
    word-break: break-all;
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
    }
`;

/** td 태그 .view css */
const ViewTd = styled.td`
    width: 12%;
    text-align: center;
    font-size: 16px;
    color: #A8A8A9;
    @media(max-width:1386px) {
        width: 20%;
    }
    @media(max-width: 1400px) {
        width: 25%;
    }
    @media(min-width:100px) and (max-width: 768px) {
        display: none;
    }
`;

/** td 태그 .date css */
const DateTd = styled.td`
    width: 12%;
    text-align: center;
    font-size: 16px;
    color: #A8A8A9;
    @media(max-width:1386px) {
        width: 20%;
    }
    @media(max-width: 1400px) {
        width: 25%;
    }
    @media(min-width:100px) and (max-width: 768px) {
        display: none;
    }
`;

/** button 태그 .loadMoreBtn css */
const LoadMoreBtnButton = styled.button`
    width: 100%;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    background-color: #F8F8F8;
    border: none;
    cursor: pointer;
`;

/** span 태그 .loadMoreBtn span css */
const LoadMoreBtnSpan = styled.span`
    font-size: 16px;
    color: #22222A;
    letter-spacing: -2px;
    font-weight: 400;
    vertical-align: middle;
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    border-top: 0px solid white;
    text-decoration: none;
    color: inherit;
    word-break: break-all;
`;