import NovelInfoNavSession from "./NovelInfoNavSession";
import writeCss from '../../css/detail-write.module.css'
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router";
import { getCookie } from "../../hooks/Cookie";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import { apiServerState } from "../../constants/recoilData";
import defaultImg from '../../css/images/illustDefault.png'


const NovelDetailSession = () => {

    const { novelId, episodeId } = useParams();

    const token = getCookie("token");
    const [episode, setEpisode] = useState(null);
    const [novel, setNovel] = useState(null);
    const [novelIdData] = useState(novelId);
    const [episodeIdData, setEpisodeIdData] = useState(episodeId);
    const [isMounted, setIsMounted] = useState(false);
    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();
    const [bPurchased, setBPurchased] = useState(null);
    const [pubState, setPubState] = useState("public");

    // 최초 랜더링시 에피소드 정보 셋팅
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
    
        const update = () => {

            const urlEpi = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + episodeId;

            const urlEpiCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            const bPurchasedData = response?.data?.data?.episode?.bPurchased;
                            setBPurchased(bPurchasedData);
                            const pubStateData = response?.data?.data?.episode?.pubState;
                            setPubState(pubStateData)
                            if (bPurchasedData === false) {
                                alert("구매후 이용하실수 있습니다.")
                                navigate("/list/novel/" + novelId);
                            }
                            if (pubStateData === "private") {
                                alert("비공개 작품입니다.");
                                navigate("/list/novel/" + novelId);
                            }
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid episode") {
                                alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else if (msg === "router err") {
                                alert("서버 에러");
                                navigate("/list/novel/" + novelId);
                            } else {
                                alert(msg);
                                navigate("/list/novel/" + novelId);
                            }
                            console.log("로딩 실패!");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };


            urlEpiCall();
        };
        update();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        const config2 = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };
  
        const url2 = apiServer + "/api/asiac_novel/insert_episode_view_history"
  
        const form2 = {"novelId": novelId, "episodeId": episodeId}
  
        axios
        .post(url2, form2, config2)
        .then((response) => {
            if (response?.data?.success) {
                console.log("success");
            } else {
                console.log(response?.data?.custMsg);
            }
        })
        .catch((e) => {
            console.log(e.message);
        });
    }, []);
    
    // 에피소드 정보 셋팅 후 token 검사, 비공개 여부, 구매여부 검사
    useEffect(() => {
        if(token === undefined) {
            navigate(URL.LOGIN);
        }
        if (pubState === "private") {
            alert("비공개 작품입니다.");
            navigate("/list/novel/" + novelId);
            return;
        }
        if (bPurchased === null) {
            return;
        } else if (bPurchased === false) {
            alert("구매후 이용하실수 있습니다.")
            navigate("/list/novel/" + novelId);
            return;
        } else if (bPurchased === true) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : token,
                }
            };
        
            const update = () => {
    
                const urlEpi = apiServer + "/api/asiac_novel/get_episode_detail?novelId=" + novelIdData + "&episodeId=" + episodeId;
                
                const urlEpiCall = async () => {
                    axios
                        .get(urlEpi, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                setNovel(response?.data?.data?.novel)
                                setEpisode(response?.data?.data?.episode);
                                setIsMounted(true);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg === "invalid novel") {
                                    alert("없는 소설 또는 비공개 소설입니다.");
                                    navigate(URL.MAIN);
                                } else if (msg === "invalid episode") {
                                    alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                    navigate(URL.MAIN);
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다.");
                                    navigate(URL.LIST_CHARGE_SHOP);
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                    navigate("/list/novel/" + novelIdData);
                                } else {
                                    alert(msg);
                                    navigate("/list/novel/" + novelIdData);
                                }
                                console.log(msg);
                            }
                        })
                        .catch(e => {  // API 호출이 실패한 경우
                            alert(e.message);
                            console.log(e);
                            navigate(URL.ERROR, {state: {msg: e.message}});
                        });
                };
                if (bPurchased) {
                    urlEpiCall();
                }
                
            };
            update();
        }
    }, [novelIdData, episodeIdData, novelId, episodeId, bPurchased, pubState])

    const imgRef = useRef();

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return (
        <>
            <NovelInfoNavSession setEpisodeIdData={setEpisodeIdData} episode={episode} novel={novel}/>
            <ContainerDiv id="container">
                {isMounted !== false ? <ContentBoxDiv className={writeCss.contentBox + " contentBox"}>
                    <ContentTitDiv className={writeCss.contentTit + " contentTit"}>
                        <ContentTitP>ep.{episode?.chapter}</ContentTitP>
                        <ContentTitP>{(String(episode?.title)).trim()}</ContentTitP>
                    </ContentTitDiv>
                    {episode?.imgUrl && <ContentBoxImgImg src={episode?.imgUrl} ref={(el) => imgRef.current = el} alt="novel_detail_img" onError={noImage}/>}
                    <ContentDiv className={writeCss.content + " content"} >
                        {episode?.content}
                    </ContentDiv>
                </ContentBoxDiv> : <></>}
            </ContainerDiv>
        </>
    );
};
export default NovelDetailSession;

/** img 태그 .contentBox > img css */
const ContentBoxImgImg = styled.img`
    margin-bottom : 20px;
    margin-top : 40px;
    @media (min-width: 100px) and (max-width: 768px) {
        width: -webkit-fill-available;
    }
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
    @media(max-width: 1400px) {
        max-width:100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
    }
    @media(max-width: 568px) {
        padding: 0 22px;
    }
`;

/** div 태그 .contentBox css */
const ContentBoxDiv = styled.div`
    margin-top: 66px;
    margin-bottom: 60px;
    @media(max-width: 568px) {
        margin-top: 90px;
    }
`;

/** div 태그 .contentTit css */
const ContentTitDiv = styled.div`
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 32px;
    line-height: 2;
    word-break: break-all;
    @media(max-width: 568px) {
        font-size: 17px;
        line-height: 2.4;
    }
`;

/** p 태그 .contentTit p css */
const ContentTitP = styled.p`
    font-family: "Min Sans-Regular";
    font-size: 18px;
    letter-spacing: -0.03m;
    color: #050409;
    margin-bottom: 0;
    margin-top: 0;
    word-break: break-all;
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    font-family: "Min Sans-Regular";
    font-size: 18px;
    letter-spacing: -0.03m;
    line-height: 2;
    margin-top: 32px;
    color: #050409;
    max-width: 100%;
    white-space: pre-wrap;
    word-break: break-all;
    @media(max-width: 568px) {
        font-size: 17px;
        line-height: 2.4;
    }
`;