import styled from "@emotion/styled";
import URL from "../../constants/url";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import AiEditorSummaryFileModal from "../ai_editor/AiEditorSummaryFileModal";
import AiEditorSummaryListModal from "./AiEditorSummaryListModal";
import AiEditorRightAside2 from "components/common/AiEditorRightAside2";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";
import { removeCookieOption } from "hooks/Cookie";


// .doc .docx 파일 내용 읽기 위한 함수
function str2xml(str) {
    if (str.charCodeAt(0) === 65279) {
      str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
}

// .doc .docx 파일 내용 읽기 위한 함수
// Get paragraphs as javascript array
function getParagraphs(content) {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const paragraphsXml = xml.getElementsByTagName("w:p");
    const paragraphs = [];

    for (let i = 0, len = paragraphsXml.length; i < len; i++) {
        let fullText = "";
        const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
        for (let j = 0, len2 = textsXml.length; j < len2; j++) {
            const textXml = textsXml[j];
            if (textXml.childNodes) {
                fullText += textXml.childNodes[0].nodeValue;
            }
        }
        if (fullText) {
            paragraphs.push(fullText);
        }
    }
    return paragraphs;
}

// 컴포넌트
const AiEditorMain = ({textContent, setTextContent}) => {
    const navigate = useNavigate();
    const [isFile, setIsFile] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [textData, setTextData] = useState("");
    const [isUpdateBool, setIsUpdateBool] = useState(false);
    const [isType,] = useState(false);

    // 최초 실행시 내용 및 쿠기 삭제
    useEffect(() => {
        setTextContent("");
        removeCookieOption("aiEditorContent", {path : ""});
    }, [])

    // 파일 체인지 함수
    const onChangeFile = async (e) => {
        let file = e.target.files[0];

        setFileData(file);
        // .txt file 일시
        if (file.type === "text/plain") {
            const file2 = new FileReader();
            file2.onload = () => {
                const text = file2.result.toString(); 
                setTextData(text);
                setTextContent(text);
                if (file2.result.length >= 4000) {
                    alert('파일 업로드는 4000자를 초과할수 없습니다.');
                    navigate(URL.AI_EDITOR_MAIN_EDIT);
                }
            }
            file2.readAsText(file);
            
        } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            // .docx .doc 파일 일시
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const paragraphs = getParagraphs(content);
                let text = "";
                for (let i = 0; i < paragraphs.length; i++) {
                    text = text + paragraphs[i] + "\n"; 
                }
                if (text.length >= 4000) {
                    alert('파일 업로드는 4000자를 초과할수 없습니다.');
                    navigate(URL.AI_EDITOR_MAIN_EDIT);
                }
                setTextData(text);
                setTextContent(text);
            }
            reader.readAsBinaryString(file);
        }
        // 파일 업로드 여부
        setIsFile(true);
    }

    // 파일이 바뀌었을때 실행
    useEffect(() => {
        
        if (isFile === true) {
            if (textContent.length >= 4000) {
                navigate(URL.AI_EDITOR_MAIN_EDIT);
            } else {
                if (textContent.length >= 4000) {
                    navigate(URL.AI_EDITOR_MAIN_EDIT);
                } else {
                    navigate(URL.AI_EDITOR_NOVEL_EDIT_FILE);
                }
            }
        }
    }, [isFile])

    // 최초 컴포넌트 랜더링시
    useState(() => {
        setTextContent('');
    }, []);


    /** 드래그 앤 드롭  시작 */
    const dragRef = useRef();

    const handleDragIn = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragOut = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);
    
    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);
    
    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const file = e.dataTransfer.files[0];
            setFileData(file);
            // .txt file
            if (file.type === "text/plain") {
                const file3 = new FileReader();
                file3.onload = () => {
                    const text = file3.result.toString();
                    setTextData(text);
                    setTextContent(text);
                    if (file3.result.length >= 4000) {
                        alert('파일 업로드는 4000자를 초과할수 없습니다.')
                        navigate(URL.AI_EDITOR_MAIN_EDIT);
                    }
                }
                file3.readAsText(file);
                setIsFile(true);
            } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                // .doc, docx file
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    const paragraphs = getParagraphs(content);
                    let text = "";
                    for (let i = 0; i < paragraphs.length; i++) {
                        text = text + paragraphs[i] + "\n"; 
                    }
                    if (text.length >= 4000) {
                        alert('파일 업로드는 4000자를 초과할수 없습니다.');
                        navigate(URL.AI_EDITOR_MAIN_EDIT);
                    }
                    setTextData(text);
                    setTextContent(text);
                }
                reader.readAsBinaryString(file);
                setIsFile(true);
            }
        },[fileData]
    );

    const initDragEvents = useCallback(() => {
        // 앞서 말했던 4개의 이벤트에 Listener를 등록합니다. (마운트 될때)
        
        if (dragRef.current !== null) {
          dragRef.current.addEventListener("dragenter", handleDragIn);
          dragRef.current.addEventListener("dragleave", handleDragOut);
          dragRef.current.addEventListener("dragover", handleDragOver);
          dragRef.current.addEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    const resetDragEvents = useCallback(() => {
        // 앞서 말했던 4개의 이벤트에 Listener를 삭제합니다. (언마운트 될때)
        
        if (dragRef.current !== null) {
          dragRef.current.removeEventListener("dragenter", handleDragIn);
          dragRef.current.removeEventListener("dragleave", handleDragOut);
          dragRef.current.removeEventListener("dragover", handleDragOver);
          dragRef.current.removeEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    useEffect(() => {
        initDragEvents();
    
        return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);
    /** 드래그 앤 드롭 끝 */

    return (
        <>
            <ContainerDiv id="container"  >
                <DefaultForm action="upload.php" method="post" encType="multipart/form-data" >
                    <DefaultLabel htmlFor="fileInput" ref={dragRef}>
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M42.4987 10C46.215 10 49.3 12.7029 49.895 16.25H9.99877V12.5C9.99877 8.35788 13.3566 5 17.4988 5H23.9217C25.5917 5 27.2142 5.55745 28.5315 6.584L30.8827 8.416C32.2 9.44255 33.8225 10 35.4925 10H42.4987ZM51.3137 20H8.68355C5.50032 20 3.12754 22.9351 3.79454 26.0478L8.30474 47.0953C9.29274 51.706 13.3674 55 18.0828 55H41.9145C46.6297 55 50.7045 51.706 51.6925 47.0953L56.2027 26.0478C56.8697 22.9351 54.497 20 51.3137 20ZM28.1237 34.5705C28.0072 34.658 27.8952 34.755 27.789 34.8613L23.8246 38.8258C23.0924 39.558 21.9052 39.558 21.1729 38.8258C20.4407 38.0935 20.4407 36.9063 21.1729 36.174L25.1375 32.2095C27.8222 29.5248 32.1752 29.5248 34.8602 32.2095L38.8245 36.174C39.5567 36.9063 39.5567 38.0935 38.8245 38.8258C38.0922 39.558 36.9052 39.558 36.173 38.8258L32.2085 34.8613C32.1022 34.755 31.9905 34.658 31.8737 34.5705V44.9998C31.8737 46.0355 31.0342 46.8748 29.9987 46.8748C28.9632 46.8748 28.1237 46.0355 28.1237 44.9998V34.5705Z" fill="url(#paint0_linear_1_7834)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1_7834" x1="29.9975" y1="5" x2="29.9975" y2="55" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FF5F37"/>
                                    <stop offset="1" stopColor="#FF9737"/>
                                </linearGradient>
                            </defs>
                        </svg>
                            
                        <LabelPP>편집할 파일을 업로드하거나<br/>왼쪽 보관함에서 선택해주세요</LabelPP>
                        <LabelPP>※편집 파일의 최대 글자는 4000자 입니다.</LabelPP>
                        <LabelSpanSpan>
                            ※업로드 파일: 최대 50mb, 워드파일(doc, docx), 텍스트파일(txt) 지원 
                        </LabelSpanSpan>
                    </DefaultLabel>
                    <FileInput type="file" id="fileInput" name="fileInput" accept=".doc, .docx, .txt" onChange={(e) => {onChangeFile(e)}} onDrop={(e) => {onChangeFile(e)}}/>
                </DefaultForm>
            </ContainerDiv>
            <AiEditorRightAside2 textData={textData} setTextData={setTextData} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} isType={isType}/>
            <ModalDiv id="relist" className="modal" >
                <AiEditorSummaryListModal />
            </ModalDiv>
            <ModalDiv id="reupload" className="modal" >
                <AiEditorSummaryFileModal />
            </ModalDiv>
        </>
    );
};
export default AiEditorMain;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 140px;
    @media(min-width:100px) and (max-width:768px) {
        padding: 0 22px;
    }
`;

/** form 태그 디폴트 css */
const DefaultForm = styled.form`
    background-color: #fff;
    padding: 50px;
    border: 1px solid #ff5f37;
    border-radius: 20px;
    margin: 0 auto; /* 가운데 정렬 */
    display: flex;
    justify-content: center;
    margin-top: 120px;
`;

/** label 태그 디폴트 css */
const DefaultLabel = styled.label`
    color: #ff5f37;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.3px;
    text-align: center;
    line-height: 1.4;
    cursor: pointer;
`;

/** p 태그 label p css */
const LabelPP = styled.p`
    margin-top: 30px;
    margin-bottom: 0;
`;

/** span 태그 label span css */
const LabelSpanSpan = styled.span`
    color: #828284;
    font-size: 14px;
`;

/** input 태그 type='file' css */
const FileInput = styled.input`
    display: none;
`;

/** div 태그 .modal css */
const ModalDiv = styled.div`
    display: none; /* 기본 상태는 보이지 않음 */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;