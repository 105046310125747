import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import axios from 'axios';
import illustDefault from '../css/images/illustDefault.png';
import mainCss from '../css/main.module.css';
import URL from '../constants/url';
import { apiServerState } from '../constants/recoilData';
import { getCookie } from '../hooks/Cookie';

const NewNovelBoard = () => {

  const apiServer = useRecoilValue(apiServerState);
  const navigate = useNavigate();
  const token = getCookie("token");

  const [novelList, setNovelList] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  /** 최초 실시간 신규 작품 리스트 호출 api 연동 코드  */
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "authorization": token,
      }
    };
  
    const update = () => {
      const url = apiServer + "/asiac_main/new/novel/list";
  
      const verteran = async () => {
        await axios
          .get(url, config)
          .then((response) => {
            if (response?.data?.success) {
              setNovelList(response?.data?.data?.novelList);
              setIsMounted(true);
            } else {
              const msg = response?.data?.custMsg;
              if (msg === "router err") {
                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
              } else {
                alert(msg);
              }
              console.log("새소식 리스트 로딩 실패!");
            }
          })
          .catch(e => {  // API 호출이 실패한 경우
            alert(e.message);
            console.log(e);
            navigate(URL?.ERROR, {state: {msg: e?.message}});
          });
      };
  
      verteran();
  
    };
    update();
  }, []);

  
  // 작품 클릭 이동 함수
  const onClickNovelBtn = () => {
    navigate(URL?.LIST_FREEZON);
  }

  const noImage = (e) => {
    e.target.src = illustDefault
  }

  return (
    <>
      {isMounted === false ? <></> : <div className={mainCss.container + " container"}>
        <RankVerteranSection>
          <RankTitleDiv>
            <H2>매일매일 새로운 스토리</H2>
            <H1>실시간 신규 작품</H1>
          </RankTitleDiv>
          <MobileDiv>
            <div className={"row row-cols-2 row-cols-lg-5 g-2 g-lg-3 novel_list " + mainCss.novel_list}>
              {novelList && novelList.map((novel) => {return(<ColDiv key={"newNovel" + novel?.id}>
                <LinkDefault to={`/list/novel/${novel?.id}`}>
                  <NovelViewDiv className='p-3'>
                    <NovelViewImg src={novel?.coverImageUrl !== null ? (novel?.coverImageUrl) : illustDefault} onError={noImage} fetchpriority="low" alt='illustration' />
                    {(String(novel?.title)).length < 15 ? <NovelViewTitleDiv>{(String(novel?.title)).trim()}</NovelViewTitleDiv> :
                    <NovelViewTitleDiv>{((String(novel?.title)).trim()).substring(0, 14) + "..."}</NovelViewTitleDiv>}
                    <NovelViewSubDiv>
                      <span>총 {novel?.episodeCnt}화</span>
                      <UserNameSpan>{novel?.nickname} 작가</UserNameSpan>
                    </NovelViewSubDiv>
                  </NovelViewDiv>
                </LinkDefault>
              </ColDiv>)})}
            </div>
          </MobileDiv>
          <MainMoreButton onClick={onClickNovelBtn}>
            <LinkMainMore > 신규작품 더보기 </LinkMainMore>
          </MainMoreButton>
        </RankVerteranSection>
      </div>}
    </>
  );
};

export default NewNovelBoard;

/**div 태그 mobile css */
const MobileDiv = styled.div`
  @media (min-width: 768px) and (max-width: 1400px) {
    display: block;
  }
`;

/** div 태그 .novel-view-title css */
const NovelViewTitleDiv = styled.div`
  font-size: 17px;
  font-family: "Min Sans-Medium";
  color: #050409;
  letter-spacing: -0.3px;
  margin-top: 12px;
`;

/** div 태그 .novel-view css */
const NovelViewDiv = styled.div`
  width: 270px;
  height: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    width: 100%;
    height: 370px;
  }
  @media (min-width: 768px) and (max-width: 1400px) {
    -webkit-box-pack: none;
    -ms-flex-pack: none;
    -webkit-justify-content: none;
    justify-content: none;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
    height: 470px;
  }
  @media (min-width: 100px) and (max-width: 468px) {
    width: 100%;
    height: 370px;
  }
`;

/** img 태그 .novel-view > img css */
const NovelViewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  @media (min-width: 768px) and (max-width: 1400px) {
    height: 258px;
  }
`;

/** div 태그 .novel-view-sub css */
const NovelViewSubDiv = styled.div`
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #9b9b9d;
  letter-spacing: -0.3px;
`;

/** span 태그 .username css */
const UserNameSpan = styled.span`
  margin-left: 6px;
`;

/** section 태그 .rank-verteran css */
const RankVerteranSection = styled.section`
  padding: 0px 0px;
  margin-bottom: 120px;
  @media (min-width: 768px) and (max-width: 1400px) {
    margin-bottom: 10px;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
  padding-top: 120px;
  @media (min-width: 768px) and (max-width: 1400px) {
    padding-top: 30px;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    padding-top: 60px;
  }
`;

/** h2 태그 디폴트 css */
const H2 = styled.h2`
  font-size: 20px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
`;

/** h1 태그 디폴트 css */
const H1 = styled.h1`
  font-size: 46px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
`;

/** div 태그 .col css */
const ColDiv = styled.div`
  padding: 0px;
  @media (min-width: 768px) and (max-width: 1400px) {
    width: 33%;
    height: 100%;
  }
`;

/** button 태그 .main-more css */
const MainMoreButton = styled.button`
  width: 100%;
  margin-top: 32px;
  margin-left: 8px;
  border: none;
  border-radius: 20px;
  height: 70px;
  background-color: #22222a;
`;

/** a 태그 .mainmore > a css */
const LinkMainMore = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.3px;
`;

/** a 태그 디폴트 css */
const LinkDefault = styled(Link)`
  text-decoration: none;
`;
