import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopButton from '../../components/common/TopButton';
import MainComponent from '../../components/main/MainComponent';
import { useState } from 'react';


const AizacMain = () => {
  
  const [isAlone,] = useState(true);

  return (
    <>
      <TopButton isAlone={isAlone}/>
      <Header />
      <MainComponent/>
      <Footer />
    </>
  );
}

export default AizacMain;
