import { useEffect, useRef, useState } from 'react';
import modalCss from '../../css/AiEditorSummaryModal.module.css';
import styled from '@emotion/styled';
import Swal from "sweetalert2";
import URL from 'constants/url';
import { useNavigate } from "react-router";
import { useRecoilValue } from 'recoil';
import { apiServerState } from 'constants/recoilData';
import { getCookie, removeCookie, removeCookieOption } from 'hooks/Cookie';
import axios from 'axios';
import sign2Css from '../../css/sign2.module.css'


const AiEditorSummaryFileModal = () => {

    // 모달 close
    const onClickClose = () => {
        var modal = document.getElementById("reupload");
        modal.style.display = "none";
        setFileList([]);
        fileInput.current.value = "";
    }

    const modalRef = useRef();
    const [userInfo, setUserInfo] = useState(null);

    // 최초 랜더링시(유저 정보 상태 저장)
    useEffect(() => {
        const token = getCookie("token");
        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        const update = () => {
            const url = apiServer + "/api/asiac_novel/get_user_overall_info";
        
            const updateList = async () => {
                await axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success) {
                        setUserInfo(response?.data?.data?.userInfo)
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "no jwt token" || msg === "invalid jwt" || msg === "") {
                            alert("다시 로그인 해주세요.");
                            removeCookie("token");
                            removeCookieOption("token", {path : "/"});
                            localStorage.removeItem("pageData");
                            localStorage.removeItem("pageDataSynop");
                            localStorage.removeItem("categoryNotice");
                            localStorage.removeItem("categoryPay");
                            localStorage.removeItem("myPicturePageData");
                            localStorage.removeItem("rowCnt");
                            window.localStorage.clear();
                            const token = getCookie("token");
                            if (token !== undefined) {
                                removeCookieOption("token", {path : "/"});
                                navigate(URL.MAIN);
                            } else {
                                navigate(URL.MAIN);
                            }
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            };

            updateList();

        };
        update();

    }, []);

    // 모달 외 클릭시(닫기)
    useEffect(() => {
        const handleClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClickClose();
            }
        };
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, [modalRef]);

    const [fileList, setFileList] = useState([]);
    const fileInput = useRef();

    // 파일 추가
    const addFile = (e) => {
        let fileList2 = [...fileList];
        fileList2 = Array.from(e.target.files); // input에서 선택된 파일들을 fileList 배열로 변환
        const copy = [];
        for (let i = 0; i <fileList2.length; i++) {
            if(fileList2[i].type === "text/plain" || fileList2[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                copy.push(fileList2[i]);
            }
        }
        console.log(copy);
        setFileList(copy);
    }

    // 파일 제거
    const removeFile = (index) => {
        fileInput.current.value = "";
        const copy = [...fileList];
        let copy2;
        if (copy.length === 1) {
            copy2 = [];
        } else {
            copy2 = copy.splice(index, 1);
        }
        
        setFileList(copy2);
    }

    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);

    // 스토리 재생성
    const onClickSummaryBtn = () => {
        const titleCheck = (String(title)).trim();
        if (fileList.length === 0) {
            alert("재생성할 파일을 추가해주세요.");
        } else if(fileList.length > 100) {
            alert("파일은 최대 100개까지 가능 합니다.");
        } else if (titleCheck === "") {
            alert("제목을 입력해주세요.");
            return;
        } else if (titleCheck.length > 50) {
            alert("제목은 50자 이내로 설정해주세요.");
            return;
        } else {
            Swal.fire({
                title : "요금 안내",
                text: "스토리 재생성은 1회 당 50000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "사용",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 50000 || userInfo?.coinAuth === "unlimited") {
                        const token = getCookie("token");
                        const config = {
                            headers: {
                                "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
                                "authorization": token,
                            }
                        };
                        const update = () => {
                            const form = new FormData();
                            const url = apiServer + "/api/asiac_nestyle/edit/recreate_files";
                            
                            if (fileList <= 0) {
                                alert("파일은 1개이상 선택해주세요.");
                                return;
                            } else {
                                for (var i = 0; i < fileList.length; i++) {
                                    form.append('files', fileList[i]);
                                }
                                if (title === "") {
                                    alert("제목을 입력해주세요.");
                                    return;
                                } else if (title === null || title === undefined) {
                                    alert("제목을 입력해주세요.");
                                    return;
                                }
                                const titleCheck = (String(title)).trim();
                                if (titleCheck === "") {
                                    alert("제목을 입력해주세요.");
                                    return;
                                }
                                if (titleCheck.length > 50) {
                                    alert("제목은 50자 이내로 설정해주세요.");
                                    return;
                                }
                                form.append('title', titleCheck);
                                const updateList = async () => {
                                    await axios
                                    .post(url, form, config)
                                    .then((response) => {
                                        if (response?.data?.success) {
                                            Swal.fire({
                                                title : "안내",
                                                text: "스토리 재생성 완료 후 \n 자동으로 편집 보관함에 저장됩니다.",
                                                confirmButtonColor: "#FF5F37",
                                                confirmButtonText: "확인",
                                            }).then((result) => {
                                                if (result.dismiss === Swal.DismissReason.confirm) {
                                                    console.log("스토리 재생성 시작");
                                                } 
                                            });
                                            fileInput.current.value = "";
                                            setTitle("");
                                            setFileList([]);
                                            onClickClose();
                                        } else {
                                            const msg = response?.data?.custMsg;
                                            if (msg.includes("timeout")) {
                                                alert("요청 시간이 초과되었습니다.");
                                            } else if (msg.includes("exceeded length")) {
                                                const title = response?.data?.data?.title;
                                                if (title !== undefined && title !== null) {
                                                    alert(`${title} 파일이 4000자 이상입니다.`);
                                                } else {
                                                    alert(`파일이 4000자 이상입니다.`);
                                                }
                                            } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                removeCookie("token");
                                                removeCookieOption("token", {path : "/"});
                                                localStorage.removeItem("pageData");
                                                localStorage.removeItem("pageDataSynop");
                                                localStorage.removeItem("categoryNotice");
                                                localStorage.removeItem("categoryPay");
                                                localStorage.removeItem("myPicturePageData");
                                                localStorage.removeItem("rowCnt");
                                                window.localStorage.clear();
                                                const token = getCookie("token");
                                                if (token !== undefined) {
                                                    removeCookieOption("token", {path : "/"});
                                                    navigate(URL.MAIN);
                                                } else {
                                                    navigate(URL.MAIN);
                                                }
                                            } else if (msg === "need more coin") {
                                                alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                            } else if (msg === "axios err" || msg === "ai generator err") {
                                                alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                            } else if (msg === "router err") {
                                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                            } else if (msg === "ikind invalid") {
                                                alert("법인 유저만 이용할수 있습니다.");
                                            } else {
                                                alert(msg);
                                            }
                                            console.log(msg);
                                        }
                                    })
                                    .catch(e => {  // API 호출이 실패한 경우
                                        alert(e.message);
                                        console.log(e);
                                    });
                                };
    
                                updateList();
                            }

                        };
                        update();
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                    }

                } 
            });
        }
    }

    const [title, setTitle] = useState("");

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    return (
        <>  
            <ModalContentDiv className={modalCss.modal_content + " modal_content modal-content"} ref={(e) => {modalRef.current = e}}>
                <span className={modalCss.close + " close"} onClick={onClickClose} style={{width : "5%", marginLeft : "95%"}}>&times;</span>
                <ReuploadtitleDiv className={modalCss.reuploadtitle + " reuploadtitle"}>파일 업로드</ReuploadtitleDiv>
                <ReuploadinfoDiv className={modalCss.reuploadinfo + " reuploadinfo"}>
                    스토리를 재생성할 파일을 업로드 해주세요.<br />편집 파일 당 최대
                    글자는 4000자입니다. 여러 파일을 동시에 업로드할 수 있습니다.<br/>
                    파일은 최대 100개까지 가능 합니다. 제목은 최대 50자까지 가능 합니다.
                </ReuploadinfoDiv>
                <InsertDiv className={modalCss.insert + " insert"}>
                    <InputRowDiv className={sign2Css.inputRow + " inputRow"}>
                        <LobelLabel htmlFor="realName" className={sign2Css.label + " label"}>제목</LobelLabel>
                        <UserInputTextInput type="text" id="title" placeholder="제목을 입력해주세요" style={{width: "100%"}} onChange={(e) => {onChangeTitle(e)}} value={title}/>
                    </InputRowDiv>
                    <UploadBtnWrapperDiv className={modalCss.upload_btn_wrapper + " upload_btn_wrapper upload-btn-wrapper"}>
                        <input
                            id="file-upload"
                            className={"modalinput hidden"}
                            type="file"
                            multiple="multiple"
                            accept=".doc, .docx, .txt"
                            onChange={(e) => {addFile(e)}}
                            style={{display : "none"}}
                            ref={fileInput}
                        />
                        <CustomFileUploadLabel htmlFor="file-upload" className={modalCss.custom_file_upload + " custom_file_upload custom-file-upload"}>
                            파일 업로드
                        </CustomFileUploadLabel>
                    </UploadBtnWrapperDiv>
                    <FileListDiv className={modalCss.file_list + " file_list file-list"}>
                        {fileList && fileList.map((file, index) => {return(<div className={modalCss.file_item + ' file_item file-item'} key={"fileList_" + index}>
                            {file.name}
                            <button className={modalCss.delete_btn + " delete_btn delete-btn"} onClick={() => {removeFile(index)}}>X</button>
                        </div>)})}
                    </FileListDiv>
                </InsertDiv>
                <ReuploadbtnButton className={modalCss.reuploadbtn + " reuploadbtn"} onClick={onClickSummaryBtn}>
                    스토리 재생성 시작
                </ReuploadbtnButton>
            </ModalContentDiv>
        </>
    );
};

export default AiEditorSummaryFileModal;

/** div 태그 .inputRow css */
const InputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-of-type{
        margin-bottom: 0;
    }
    &:nth-of-type(2){
        margin-bottom: 42px;
    }
    @media(max-width:568px) {
        margin-bottom: 24px;
        &:nth-of-type(2){
            margin-bottom: 0;
        }
    }
`;

/** label 태그 .label css */
const LobelLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
`;

/** input 태그 .userInput type="text" input css */
const UserInputTextInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
    width: 80%;
`;

/** div 태그 .modal-content css */
const ModalContentDiv = styled.div`
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
    text-align: center;
`;



/** div 태그 .reuploadtitle css */
const ReuploadtitleDiv = styled.div`
    font-family: "Min Sans-bold";
    font-size: 24px;
    margin-bottom: 24px;
`;

/** div 태그 .reuploadinfo css */
const ReuploadinfoDiv = styled.div`
    font-family: "Min Sans-regular";
    font-size: 16px;
    color: #828284;
`;

/** div 태그 .insert css */
const InsertDiv = styled.div`
    padding: 20px 30px;
    display: block;
    width: 600px;
    margin: 5vh auto;
    height: 60vh;
    border: 1px solid #dbdbdb;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`;

/** form 태그 .reuploadform css 
const ReuploadformForm = styled.form`
    border: none;
    width: 100%;
    margin-top: 10px;
    display: contents;
`;*/

/** div 태그 .upload-btn-wrapper  css */
const UploadBtnWrapperDiv = styled.div`
    text-align: left;
`;

/** label 태그 .custom-file-upload css */
const CustomFileUploadLabel = styled.label`
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #fff;
    background-color: #ff562c;
    padding: 14px 80px;
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 4px;
`;

/** div 태그 .file-list css */
const FileListDiv = styled.div`
    height: 318px;
    overflow: auto;
    border: 1px solid #989898;
    padding: 10px;
    text-align: left;
    list-style-type: none;
`;

/** button 태그 .reuploadbtn css */
const ReuploadbtnButton = styled.button`
    padding: 14px 110px;
    display: inline;
    margin-bottom: 20px;
    border: none;
    background-color: #ff562c;
    color: #fff;
    border-radius: 4px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
    width: 40%;
    margin-left: 30%;
    &:hover {
        cursor: pointer;
    }
`;