import styled from "@emotion/styled";
import SynopTabPicture from "../../components/common/SynopTabPicture";
import { Link, useNavigate } from "react-router-dom";
import drawing2Css from '../../css/drawing_Making_2.module.css'
import { useEffect, useState } from "react";
import URL from "../../constants/url";
import { getCookie, setCookieOption } from "hooks/Cookie";


const PictureCreate2 = ({setIsCreateImg}) => {


    const [backgroundStory, setBackgroundStory] = useState("");
    setIsCreateImg(false);

    // 배경정보 변경 입력 함수
    const onChangeBackgroundStory = (e) => {
        setBackgroundStory(e.target.value);
    };

    const picture3Data = getCookie("picture3Data");

    useEffect(() => {
        if(picture3Data !== undefined) {
            setBackgroundStory(picture3Data?.backgroundStory);
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, []);

    const navigate = useNavigate();

    // 저장하고 다음으로 버튼
    const onClickSaveBtn = () => {
        if(backgroundStory === "") {
            alert("배경정보를 입력해주세요.");
            return;
        } else if (backgroundStory === null || backgroundStory === undefined) {
            alert("배경정보를 입력해주세요.");
            return;
        }
        const backgroundStoryCheck = (String(backgroundStory)).trim();
        if (backgroundStoryCheck === "") {
            alert("배경정보를 입력해주세요.");
            return;
        }
        setCookieOption("backgroundStory", backgroundStory, {path : ""});
        navigate(URL.CREATE_PICTURE3);
    }

    return (
        <>
            <ContainerDiv id="container">
                <SynopTabPicture action={2}/>
                <ContainerH2H2>배경 정보를 입력해주세요</ContainerH2H2>
                <ContentDiv className={drawing2Css.content + " content"}>
                    <TitP className={drawing2Css.tit + " tit"}>배경정보</TitP>
                    <TextAreaTextarea id="textArea" autoFocus placeholder="배경을 입력해주세요(ex 고대 도서관 내부, 어스름한 조명 아래 책들이 무수히 쌓여있는 곳 등)" value={backgroundStory} onChange={onChangeBackgroundStory}></TextAreaTextarea>
                    <SaveNextBtnButton type="submit" className={drawing2Css.saveNextBtn + " saveNextBtn"} onClick={onClickSaveBtn}><DefaultALink >저장하고 다음으로</DefaultALink></SaveNextBtnButton>
                </ContentDiv>
            </ContainerDiv>
        </>
    );
};
export default PictureCreate2;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 140px;
    @media(max-width:1400px) {
        padding: 0 80px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** h2 태그 #Container h2 css */
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-top: 60px;
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    margin-top: 3px;
    font-weight: bold;
    @media(max-width:568px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 52px;
    @media(max-width:568px) {
        padding-top: 42px;
    }
`;

/** p 태그 .tit css */
const TitP = styled.p`
    font-size: 15px;
    font-family: "Min Sans-Regular";
    color: #050409;
    margin-bottom: 4px;
`;

/** textarea 태그 #textArea css  */
const TextAreaTextarea = styled.textarea`
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
    &::placeholder {
        color: #828284;
    }
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media(max-width:568px) {
        margin-top: 42px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;