import { useEffect } from "react";
import FreezonBestBoard from "../../board/FreezonBestBoard";
import IllustrationBestBoard from "../../board/IllustrationBestBoard";
import NewNovelBoard from "../../board/NewNovelBoard";
import SynopsisBestBoard from "../../board/SynopsisBestBoard";
import VerteranBestBoard from "../../board/VeteranBestBoard";
import Banner from "../../components/common/Banner";


// 메인 페이지 컴포넌트
const MainComponent = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])
    
    return (
        <>
            <Banner />
            <VerteranBestBoard />
            <FreezonBestBoard />
            <SynopsisBestBoard />
            <IllustrationBestBoard />
            <NewNovelBoard />
        </>
    );
};

export default MainComponent;