import styled from "@emotion/styled";
import { useRef } from "react";
import createNovel5Css from '../../css/createNovel5.module.css'


// 글쓰기 5 페이지 좌측 사이드 캐릭터 컴포넌트
const CreateNovelLeftAsideCharacter = ({character}) => {

    const listRef = useRef();
    const arrowRef = useRef();

    // 캐릭터 클릭
    const onClickList = () => {
        if (listRef.current.style.display === 'block') {
            listRef.current.style.display = 'none';
            arrowRef.current.style.setProperty("transform", "rotate(0deg)");
        } else {
            listRef.current.style.display = 'block';
            arrowRef.current.style.setProperty("transform", "rotate(180deg)");
        }
    }


    return (
        <>
            <ListDiv className={createNovel5Css.list + " list"} onClick={() => {onClickList()}}>
                <div className={createNovel5Css.cate + " cate"}>▶</div>
                <div className={createNovel5Css.listInfo + " listInfo"}>
                    <p className={createNovel5Css.title + " title"} style={{marginBottom : "6px", wordBreak : "break-all"}}>{character?.name}</p>
                    <ul className={createNovel5Css.hidden_list + " hidden_list hidden-list"} id="bookList" ref={listRef} style={{paddingLeft: "0"}}>
                        <HiddenListLiLi>{character?.gender}, {character?.age}세, {character?.job}</HiddenListLiLi>
                        <HiddenListLiLi>{character?.role}</HiddenListLiLi>
                        <HiddenListLiLi>{character?.characteristics}</HiddenListLiLi>
                        <HiddenListLiLi>{character?.trauma}</HiddenListLiLi>
                    </ul>
                </div>
                <div className={createNovel5Css.down + " down"} id="downArrow">
                    <svg
                        width="16"
                        height="10"
                        viewBox="0 0 16 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{fill : "#bcbcbf"}} ref={arrowRef}>
                        <mask
                            id="mask0_1_7899"
                            style={{maskType: "alpha"}}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="16"
                            height="10"
                        >
                            <rect width="16" height="10" fill="#D9D9D9" />
                        </mask>
                        <g
                            mask="url(#mask0_1_7899)"
                            transform="rotate(0 8 5)"
                            id="arrowIcon">
                            <path
                            d="M1.25 2.1167L2.34508 1L8 6.76659L13.637 1L14.75 2.11671L8 9L1.25 2.1167Z"
                            fill="#71717D"
                            />
                        </g>
                    </svg>
                </div>
            </ListDiv>
        </>
    );
};
export default CreateNovelLeftAsideCharacter;

/** div 태그 .list css */
const ListDiv = styled.div`
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    cursor: pointer;
    &:hover {
        color: #ff5f37;
    }
    &:first-of-type {
        border-top: 1px solid #2c2c34;
    }
    &:last-of-type {
        margin-bottom: 42px;
    }
`;

/** li 태그 .hidden-list li css */
const HiddenListLiLi = styled.li`
    list-style: none;
    padding: 6px 0;
    color: #8f8f99;
    word-break: break-all;
    &:hover {
        color: #fff;
    }
`;