import { useEffect, useState } from 'react';
import createNovel5Css from '../../css/createNovel5.module.css'
import { useRecoilValue } from 'recoil';
import { apiServerState } from '../../constants/recoilData';
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import URL from '../../constants/url';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import $ from 'jquery';


const V1CteateNovelRightAsideSceneDetail  = ({synopsis, scene_recommend, characters, number, index, isMountOnClickBool, isMountOnClickBoolList, setIsMountOnClickBoolList, selectBool, setSelectBool, dataList, setDataList, setSceneTitleListList, isUpdateBool, setIsUpdateBool, setIsMountOnClickCheckBoolList, setInputTitle, lastScene, previousText, setLengthBool}) => {

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    const [sceneList, setSceneList] = useState([]);
    const [sceneListIndex, setSceneListIndex] = useState(0);
    const [isMountLoding, setIsMountLoding] = useState(true);
    const [userInfo, setUserInfo] = useState(null);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 씬 선택지 클릭여부에 따른 같은 index 컴포넌트 씬 스토리 생성 api 호출
    useEffect(() => {
        if (isMountOnClickBool) {
            setSelectBool(false);
            setIsMountLoding(false);
            let copy = [...isMountOnClickBoolList];
            copy[index][number] = false;
            setIsMountOnClickBoolList(copy);
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
            const form = {synopsis: "", scene_recommend: "", keywords: "", characters: "", reference : ""};
            if (lastScene.scene === '') {
                if(previousText === '') {
                    form.reference = "첫장면";
                } else {
                    form.reference = previousText;
                }
            } else {
                form.reference = lastScene.scene;
            }
            form.synopsis = synopsis;
            form.scene_recommend = scene_recommend;
            form.keywords = "";
            form.characters = characters;

            axios
                .post(apiServer + "/api/asiac_nestyle/scene/recommend/create", form, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        console.log("스토리 생성에 성공!");
                        const copyList = [];
                        copyList.push(response.data.data.scene);
                        setSceneList(copyList);
                        setSceneListIndex(0);
                        setIsMountLoding(true);
                        setIsUpdateBool(!isUpdateBool);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg.includes("timeout")) {
                            alert("요청시간이 초과되었습니다.");    
                        } else if (msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인 후 이용해주세요.");
                            logout();
                        } else if (msg === "need more coin") {
                            alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                        } else if (msg === "axios err" || msg === "ai generator err") {
                            alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        } else {
                            alert(msg);
                        }
                        console.log(msg);
                        setIsMountLoding(true);
                        setIsUpdateBool(!isUpdateBool);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                });
        }
    }, [isMountOnClickBoolList]);

    // isUpdateBool 변경시 유저 정보 업데이트(api 코인차감시 업데이트 용도(코인 금액 검사))
    useEffect(() => {
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            const token = getCookie("token");
            const config = {
                headers: {
                "Content-Type": "application/json",
                "authorization": token,
                }
            };

            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [isUpdateBool]);

    // 씬 스토리 리스트 page 증가 버튼
    const onClickIncrease = () => {
        if (sceneList.length > sceneListIndex + 1) {
            let copyNum = sceneListIndex + 1;
            setSceneListIndex(copyNum);
        }
    }
    
    // 씬 스토리 리스트 page 감소 버튼
    const onClickDecrease = () => {
        if (sceneListIndex > 0) {
            let copyNum = sceneListIndex - 1;
            setSceneListIndex(copyNum);
        }
    }

    // 씬 스토리 재생성 버튼
    const onClickReCreate = () => {
        Swal.fire({
            title : "요금 안내",
            text: "씬 스토리 생성은 1회 당 200코인이 소모됩니다",
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "사용",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 200 || userInfo?.coinAuth === "unlimited") {
                    setIsMountLoding(false);
                    let copy = [...isMountOnClickBoolList];
                    copy[index][number] = false;
                    setIsMountOnClickBoolList(copy);
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            "authorization": token,
                        }
                    };
                    const form = {synopsis: "", scene_recommend: "", keywords: "", characters: ""};
                    form.synopsis = synopsis;
                    form.scene_recommend = scene_recommend;
                    form.keywords = "";
                    form.characters = characters;
    
                    axios
                        .post(apiServer + "/api/asiac_nestyle/scene/recommend/create", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                console.log("스토리 재생성 성공!");
                                const copyList = [...sceneList];
                                copyList.push(response.data.data.scene);
                                setSceneList(copyList);
                                const copyNum = sceneListIndex + 1;
                                setSceneListIndex(copyNum);
                                setIsMountLoding(true);
                                setIsUpdateBool(!isUpdateBool);
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg.includes("timeout")) {
                                    alert("요청시간이 초과되었습니다.");    
                                } else if (msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "need more coin") {
                                    alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                } else if (msg === "axios err" || msg === "ai generator err") {
                                    alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                } else if (msg === "router err") {
                                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                } else {
                                    alert(msg);
                                }
                                console.log(msg);
                                setIsMountLoding(true);
                                setIsUpdateBool(!isUpdateBool);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            setIsUpdateBool(!isUpdateBool);
                            console.log(e);
                        });
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "코인 충전",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            navigate(URL.LIST_CHARGE_SHOP);
                        } 
                    });
                }
            } 
        });
        
    }

    // 씬 스토리 입력 버튼
    const onClickSelect = () => {
        let copy = [...dataList];
        const copyData = { title: scene_recommend, scene: sceneList[sceneListIndex]};
        copy.push(copyData);
        setDataList(copy);
        setSelectBool(true);
        setSceneList([]);
        setSceneTitleListList([]);
        setInputTitle("");
        const copy2 = [[false, false, false, false, false, false]];
        setIsMountOnClickCheckBoolList(copy2);
        setLengthBool(false);
        $("#s0")[0].checked = false;
        const data = $(".radioSceneName");
        for (let i = 0; i < data.length; i++) {
            data[i].checked = false;
        }
        $("#input-area").css("display", "none");
        $("#s0c").css("display", "none");
    }

    return (
        <>
            {selectBool === false ? <> 
            <div className={createNovel5Css.scene_select_list_title + " scene_select_list_title scene-select-list-title"}>
                {scene_recommend}
            </div>
            {isMountLoding === true ?<><div className={createNovel5Css.scene_select_list_content + " scene_select_list_content scene-select-list-content"}>
                {sceneList[sceneListIndex]}
            </div>
            {sceneList.length !== 0 ?<button className={createNovel5Css.scene_select_list_btn + " scene_select_list_btn scene-select-list-btn"} onClick={onClickSelect}>스토리 입력</button> : <></>}
            <div className={createNovel5Css.scene_main_select + " scene_main_select scene-main-select"}>
                <div className={createNovel5Css.scene_main_select_1 + " scene_main_select_1 scene-main-select-1"}>
                    <div>생성결과 {sceneList.length !== 0 ? sceneListIndex + 1 : 0}/{sceneList?.length}</div>
                    <button onClick={onClickDecrease}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18px"
                            height="18px"
                            viewBox="0 0 320 512"
                            style={{fill : "#bcbcbf"}}>
                            <path
                                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                            />
                        </svg>
                    </button>
                    <button onClick={onClickIncrease}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18px"
                            height="18px"
                            viewBox="0 0 320 512"
                            style={{fill : "#bcbcbf"}}>
                            <path
                                d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                            />
                        </svg>
                    </button>
                </div>
                <div className={createNovel5Css.rere_scene + " rere_scene rere-scene"} onClick={onClickReCreate}>재생성</div>
            
            </div></> : <div className={createNovel5Css.scene_select_list_content + " scene_select_list_content scene-select-list-content"}>
                스토리를 생성중입니다. <br/>
                잠시만 기다려주세요.
            </div>}
            </> : <></>}
        </>
    );
};
export default V1CteateNovelRightAsideSceneDetail;