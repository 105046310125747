import createNovel5Css from '../../css/createNovel5.module.css'
import styled from "@emotion/styled";
import axios from 'axios';
import { apiServerState } from '../../constants/recoilData';
import URL from '../../constants/url';
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactTextareaAutosize from "react-textarea-autosize";
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import CreateNovelLeftAside from './CreateNovelLeftAside';
import V1CreateNovelRightAsideSceneCreate from './V1CreateRightAsideSceneCreate';
import DefaultImg from '../../css/images/illustDefault.png'


// 글쓰기5 페이지 컴포넌트
const CreateNovel5V1 = ({isUpdate, setIsUpdate}) => {

    const {novelId, sessionId} = useParams();

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    const [isUpdateBool, setIsUpdateBool] = useState(false);
    
    /** 중앙 ============================= */
    
    const [novelDetail, setNovelDetail] = useState(null);
    const [episodeDetail, setEpisodeDetail] = useState(null);
    const [sceneList, setSceneList] = useState([]);
    const [characters, setCharacters] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [lengthBool, setLengthBool] = useState(false);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }
    
    // 최초 랜더링시 소설 정보, 에피소드 정보, 유저 정보 셋팅
    useEffect(() => {

        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
    
        if(sessionId !== "0") {
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + sessionId;
            
                const setting = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setCharacters(response?.data?.data?.characters)
                            setSceneList(response?.data?.data?.scenes)
                            setNovelDetail(response?.data?.data?.novel)
                            setEpisodeDetail(response?.data?.data?.episode)
                            setIsUpdateBool(!isUpdateBool);
                            setLengthBool(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid episode") {
                                alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else if (msg === "router err") {
                                alert("서버 에러");
                                navigate("/list/novel/" + novelId);
                            } else {
                                alert(msg);
                                navigate("/list/novel/" + novelId);
                            }
                            console.log("로딩 실패!");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
            
                setting();
            };
            update();
        } else {
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_novel_detail_allowprivate?novelId=" + novelId;
            
                const setting = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setCharacters(response?.data?.data?.characters);
                            setNovelDetail(response?.data?.data?.novel);
                            setEpisodeDetail(response?.data?.data?.episodes);
                            setIsUpdateBool(!isUpdateBool);
                            setSceneList([]);
                            setLengthBool(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설 입니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };

                setting();
            };
            update();
        }
        const updateUserInfo = () => {
            const urlUserInfo = apiServer + "/api/asiac_login/get_my_info";
        
            const userInfo = async () => {
                await axios
                .get(urlUserInfo, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setUserInfo(response?.data?.data?.user);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else if (msg === "invalid user") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            };
        
            if (token !== undefined) {
                userInfo();
            }
        };
        updateUserInfo();
        
    }, [])

    // 유저정보, 소설 정보 셋팅시 유저id, 소설 등록 유저 id 비교
    useEffect(() => {
        if (userInfo !== null && novelDetail !== null) {
            if(userInfo?.id !== novelDetail?.userId) {
                alert("비정상적인 접근입니다.");
                navigate(URL.MAIN);
            }
        } 
    }, [userInfo, novelDetail])


    // isUpdate, novelId, sessionId 변경시 업데이트
    useEffect(() => {
        setNovelDetail(null);
        setEpisodeDetail(null);
        setSceneList([]);
        setCharacters([]);
        setUserInfo(null);
        setLengthBool(false);

        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
    
        if(sessionId !== "0") {
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + sessionId;
    
            
                const setting = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setCharacters(response?.data?.data?.characters);
                            setSceneList(response?.data?.data?.scenes);
                            setNovelDetail(response?.data?.data?.novel);
                            setEpisodeDetail(response?.data?.data?.episode);
                            setIsUpdateBool(!isUpdateBool);
                            setCreateImg(null);
                            setLengthBool(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid episode") {
                                alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else if (msg === "router err") {
                                alert("서버 에러");
                                navigate("/list/novel/" + novelId);
                            } else {
                                alert(msg);
                                navigate("/list/novel/" + novelId);
                            }
                            console.log("로딩 실패!");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
            
                setting();
            };
            update();
        } else {
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_novel_detail_allowprivate?novelId=" + novelId;
    
            
                const setting = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setCharacters(response?.data?.data?.characters);
                            setNovelDetail(response?.data?.data?.novel);
                            setEpisodeDetail(response?.data?.data?.episodes);
                            setIsUpdateBool(!isUpdateBool);
                            setSceneList([]);
                            setLengthBool(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설 입니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
            
                setting();
            };
            update();
        }
        
    }, [isUpdate, novelId, sessionId])


    const createApisodeButton = useRef();

    // 새 회차 생성 버튼
    const crateOnClick = () => {
        const confirmationMessage = "집필 중인 작품을 저장하지 않고 새 회차를 생성하시겠습니까?";


        Swal.fire({
            title: "저장 안내",
            text: confirmationMessage,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "새 회차 생성",
            confirmButtonText: "집필 내용 저장",
            reverseButtons: true,
        }).then((result) => {
        if (result.dismiss === Swal.DismissReason.confirm) {
            if (sceneList.length > 0) {

                let boolCheck = false;
                for (let i = 0; i < sceneList.length; i++) {
                    if ((sceneList[i].scene).length === 0)  {
                        boolCheck = true;
                        break;
                    }
                }

                if (boolCheck) {
                    alert("씬 내용을 입력해주세요.");
                    return;
                }

                let trimCheck = false;
                for (let i = 0; i < sceneList.length; i++) {
                    if (((String(sceneList[i].scene)).trim()).length === 0) {
                        trimCheck = true;
                    }
                }
                if (trimCheck) {
                    alert("씬 내용을 입력해주세요.");
                    return;
                }

                let form;
                if (createImg === null) {
                    form = {"id": sessionId,
                            "novelId": novelId
                            ,"chapter": sessionId === "0" ? (parseInt(episodeDetail.length) + 1) : episodeDetail.chapter
                            ,"title": novelDetail.title
                            ,"story": novelDetail.synopsis
                            ,"pubState" : "private"
                            ,"scenes":sceneList};
                } else {
                    form = {"id": sessionId,
                            "novelId": novelId
                            ,"chapter": sessionId === "0" ? (parseInt(episodeDetail.length) + 1) : episodeDetail.chapter
                            ,"title": novelDetail.title
                            ,"story": novelDetail.synopsis
                            ,"pubState" : "private"
                            ,"scenes":sceneList
                            ,"imgPath" : createImg.path};

                }
                
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };
        
                axios
                    .post(apiServer + "/api/asiac_novel/update_episode", form, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            alert(`집필 내용을 저장하였습니다.`);
                            navigate('/create-novel/' + novelId + "/0");

                        } else {
                            const msg = response?.data?.custMsg
                            if(msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 이용해주세요.");
                                logout();
                            } else if (msg === "invalid novel") {
                                alert("비정상적인 접근입니다.");
                            } else if (msg === "router failed") {
                                alert("서버 에러");
                            } else {
                                alert("저장 실패");
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            } else {
                alert("회차 내용을 입력해주세요");
            }
            
        } else {
            navigate('/create-novel/' + novelId + "/0");
        }
        });
    }

    const saveApisodeButton = useRef();

    // 비공개 저장 버튼
    const saveOnClick = () => {
        if (sceneList.length > 0) {

            let boolCheck = false;
            for (let i = 0; i < sceneList.length; i++) {
                if ((sceneList[i].scene).length === 0)  {
                    boolCheck = true;
                    break;
                }
            }

            if (boolCheck) {
                alert("씬 내용을 입력해주세요.");
                return;
            }

            let trimCheck = false;
            for (let i = 0; i < sceneList.length; i++) {
                if (((String(sceneList[i].scene)).trim()).length === 0) {
                    trimCheck = true;
                }
            }
            if (trimCheck) {
                alert("씬 내용을 입력해주세요.");
                return;
            }

            let form;
            if (createImg === null) {
                form = {"id": parseInt(sessionId),
                        "novelId": novelId
                        ,"chapter": sessionId === "0" ? (parseInt(episodeDetail.length) + 1) : episodeDetail.chapter
                        ,"title": novelDetail.title
                        ,"story": novelDetail.synopsis
                        ,"pubState" : "private"
                        ,"scenes":sceneList};
            } else {
                form = {"id": parseInt(sessionId),
                        "novelId": novelId
                        ,"chapter": sessionId === "0" ? (parseInt(episodeDetail.length) + 1) : episodeDetail.chapter
                        ,"title": novelDetail.title
                        ,"story": novelDetail.synopsis
                        ,"pubState" : "private"
                        ,"scenes":sceneList
                        ,"imgPath" : createImg.path};
            }
            
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
    
            axios
                .post(apiServer + "/api/asiac_novel/update_episode", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        alert(`비공개 저장이 완료되었습니다.`);
                        navigate(URL.CREATE_MAIN);
                    } else {
                        const msg = response?.data?.custMsg
                        if(msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인 후 이용해주세요.");
                            logout();
                        } else if (msg === "invalid novel") {
                            alert("비정상적인 접근입니다.");
                        } else if (msg === "router failed") {
                            alert("서버 에러");
                        } else {
                            alert("저장 실패");
                        }
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        } else {
            alert("회차 내용을 입력해주세요");
        }
    }

    const openApisodeButton = useRef();

    // 공개 저장 버튼
    const openOnClick = () => {
        const confirmationMessage =
            "아이작에 집필한 회차를 공개하시겠습니까?";

        let boolCheck = false;
        for (let i = 0; i < sceneList.length; i++) {
            if ((sceneList[i].scene).length === 0)  {
                boolCheck = true;
                break;
            }
        }
        let trimCheck = false;
        for (let i = 0; i < sceneList.length; i++) {
            if (((String(sceneList[i].scene)).trim()).length === 0) {
                trimCheck = true;
            }
        }
        if (trimCheck) {
            alert("씬 내용을 입력해주세요.");
            return;
        }

        if (boolCheck) {
            alert("씬 내용을 입력해주세요.");
            return;
        }
        if (sceneList.length === 0) {
            alert("내용을 입력해주세요.");
            return;
        }
        if (createImgLoding === false) {
            alert("삽화가 생성중입니다.");
            return;
        }

        Swal.fire({
            title: "저장 안내",
            text: confirmationMessage,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "공개 저장",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                if (sceneList.length > 0) {
                    let form;
                    if (createImg === null) {
                        form = {"id": parseInt(sessionId),
                                "novelId": novelId
                                ,"chapter": sessionId === "0" ? (parseInt(episodeDetail.length) + 1) : episodeDetail.chapter
                                ,"title": novelDetail.title
                                ,"story": novelDetail.synopsis
                                ,"pubState" : "public"
                                ,"scenes":sceneList};
                    } else {
                        form = {"id": parseInt(sessionId),
                                "novelId": novelId
                                ,"chapter": sessionId === "0" ? (parseInt(episodeDetail.length) + 1) : episodeDetail.chapter
                                ,"title": novelDetail.title
                                ,"story": novelDetail.synopsis
                                ,"pubState" : "public"
                                ,"scenes":sceneList
                                ,"imgPath" : createImg.path};
                    }
                    
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            "authorization": token,
                        }
                    };
    
                    axios
                        .post(apiServer + "/api/asiac_novel/update_episode", form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                alert(`집필 내용을 저장하였습니다.`);
                                navigate(URL.CREATE_MAIN);
                            } else {
                                const msg = response?.data?.custMsg
                                if(msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else if (msg === "invalid novel") {
                                    alert("비정상적인 접근입니다.");
                                } else if (msg === "router failed") {
                                    alert("서버 에러");
                                } else {
                                    alert("저장 실패");
                                }
                                console.log(msg);
                            }
                        })
                        .catch((e) => {
                            alert(e.message);
                            console.log(e);
                            navigate(URL.ERROR, {state: {msg: e.message}});
                        });
                } else {
                    alert("회차 내용을 입력해주세요");
                }
            } else {
                navigate(URL.CREATENOVEL_INPUT+ novelId + "/" + sessionId);
            }
        });
    }

    /** ================================== */
    /** 우측 사이드 */

    const aside = useRef();

    // 추가 도구 버튼
    const refClick = () => {
        if (
            aside.current.style.display === "none" ||
            aside.current.style.display === ""
        ) {
        aside.current.style.display = "block";
        } else {
        aside.current.style.display = "none";
        }
    }

    //const [sceneListList, setSceneListList] = useState([]);

    const [lastScene, setLastScene] = useState();

    const [totalText, setTotalText] = useState('');
    const [totalLength, setTotalLength] = useState(totalText.length);

    // senList 변경시 
    useEffect(() => {
        if(sceneList.length === 0) {
            setLastScene({"episodeId": sessionId,
            "scene": "",
            "createdAt": "",
            "updatedAt": "",
            "title": ""})
        } else {
            for (let i = (sceneList.length - 1); i < sceneList.length; i++) {
                setLastScene(sceneList[i]);
            }
        }
        let textData = ''
        for (let i = 0; i < sceneList.length; i++) {
            const copy = sceneList[i].scene
            textData += copy
        }
        setTotalText(textData);
        setTotalLength(textData.length);
    }, [sceneList])
    
    const [selectBool, setSelectBool] = useState(false);

    // 씬 내용 변경 함수
    const onChangeText = (e, index) => {
        setSceneList(prevSceneList => {
            return prevSceneList.map((scene, i) => {
              if (i === index) {
                return { ...scene, "scene": e.target.value };
              } else {
                return scene;
              }
            });
        });
        //sceneList[index] = {...sceneList[index], "scene" : e.target.value}
        //console.log(({...sceneList[index], "scene" : e.target.value}));
        //setSceneList([...sceneList]);
    }

    const [createImgLoding, setCreateImgLoding] = useState(true);
    const imgRef = useRef();
    const [createImg, setCreateImg] = useState(null);

    // 소설 삽화 생성하기 버튼
    const onClickCreateImg = () => {
        if (createImgLoding === true) {
            Swal.fire({
                title : "요금 안내",
                text: "삽화 생성은 1회 당 1000코인이 소모됩니다",
                showCancelButton: true,
                confirmButtonColor: "#FF5F37",
                cancelButtonColor: "#bdbdbd",
                cancelButtonText: "취소",
                confirmButtonText: "구매",
                reverseButtons: true,
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.confirm) {
                    if (((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                    (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 1000 || userInfo?.coinAuth === "unlimited") {
                        if(sceneList.length === 0) {
                            alert("씬을 생성후 삽화를 생성해주세요.");
                            return;
                        }
                        let trimCheck = false;
                        for (let i = 0; i < sceneList.length; i++) {
                            if (parseInt(((String(sceneList[i].scene)).trim()).length) === 0) {
                                trimCheck = true;
                            }
                        }
                        if(trimCheck) {
                            alert("씬 내용이 없습니다.");
                            return;
                        }
                        setCreateImgLoding(false);
                        const config = {
                            headers: {
                              "Content-Type": "application/json",
                              "authorization": token,
                            }
                        };
                        let form;
                        let data = novelDetail.synopsis + " ";
                        for (let i = 0; i < sceneList.length; i++) {
                            if (i === sceneList.length - 1) {
                                data += sceneList[i].scene;
                            } else {
                                data += sceneList[i].scene + " ";
                            }
                        }
                        if (data === "") {
                            alert("씬 내용이 없습니다.");
                            return;
                        }
                        form = {"imgType": "삽화", "categoryId" : novelDetail?.categoryId, "characters": characters, "plot": (data)};
                        
                        axios
                            .post(apiServer + "/api/aizac_ai_img/generate_episode_ai_img", form, config)
                            .then((response) => {
                                if (response?.data?.success) {
                                    alert("삽화가 생성되었습니다.");
                                    setCreateImg(response.data.data);
                                    setCreateImgLoding(true);
                                    setIsUpdateBool(!isUpdateBool);
                                    imgRef.current.style.display = "block";
                                } else {
                                    const msg = response?.data?.custMsg;
                                    if (msg === "no jwt token" || msg === "invalid jwt") {
                                        alert("다시 로그인 후 이용해주세요.");
                                        logout();
                                    } else if (msg === "need more coin") {
                                        alert("코인이 부족합니다. 코인 충전 후 이용해주세요.");
                                    } else if (msg === "invalid category") {
                                        alert("카테고리가 없습니다. 작품정보 수정 후 이용해주세요.");
                                    } else if (msg === "invalid data produced" || msg === "invalid img produced") {
                                        alert("AI 생성 요청이 많습니다. 다시 시도바랍니다");
                                    } else {
                                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                    }
                                    console.log(msg);
                                    setCreateImgLoding(true);
                                    setIsUpdateBool(!isUpdateBool);
                                }
                            })
                            .catch((e) => {
                                alert(e.message);
                                console.log(e);
                                setCreateImgLoding(true);
                                setIsUpdateBool(!isUpdateBool);
                            });
                    } else {
                        Swal.fire({
                            title : "작품 구매",
                            text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonColor: "#FF5F37",
                            cancelButtonColor: "#bdbdbd",
                            cancelButtonText: "취소",
                            confirmButtonText: "코인 충전",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.confirm) {
                                navigate(URL.LIST_CHARGE_SHOP);
                            } 
                        });
                        setIsUpdateBool(!isUpdateBool);
                    }
    
                } 
            })
        }
    }

    /** ======================================== */

    // 씬 삭제 버튼
    const onClickDeleteButton = (indexNum) => {
        let copy = [...sceneList]
        copy = copy.filter(function(_, index) {
            return index !== indexNum;
        })
        setSceneList(copy);
    }


    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    const noImage = (e) => {
        e.target.src = DefaultImg
    }

    const focusRef = useRef();

    useEffect(() => {
        if(lengthBool === false) {
            focusRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    }, [sceneList.length])

    return (
        <>
            <LeftAsideAside className={createNovel5Css.leftAside + " leftAside"} ref={aside}>
                <CreateNovelLeftAside novelId={novelId} characters={characters} isUpdateBool={isUpdateBool} isUpdate={isUpdate} setIsUpdate={setIsUpdate}/>
            </LeftAsideAside>

            <ContainerDiv id="container">
                {novelDetail && <div className={createNovel5Css.novel_top + " novel_top novel-top"} >
                    <div className={createNovel5Css.novel_title + " novel_title novel-title"}>{novelDetail?.title}</div>
                    <div className={createNovel5Css.novel_info + " novel_info novel-info"}>
                        <div className={createNovel5Css.novel_no + " novel_no novel-no"}>{parseInt(sessionId) === 0 ? (parseInt(episodeDetail.length) + 1) : episodeDetail?.chapter}화</div>
                        <div className={createNovel5Css.novel_control + " novel_control novel-control"}>
                            <span className={createNovel5Css.novel_count + " novel_count novel-count"}>글자수({totalLength}자)</span>
                            <button type="button" id="create-episode-button" ref={createApisodeButton} onClick={crateOnClick}>
                                새 회차 생성
                            </button>
                            <button type="button" id="save-episode-button" ref={saveApisodeButton} onClick={saveOnClick}>
                                비공개 저장
                            </button>
                            <button type="button" id="open-episode-button" ref={openApisodeButton} onClick={openOnClick}>공개 저장</button>
                        </div>
                    </div>
                </div>}
                <div className={createNovel5Css.novel_bottom + " novel_bottom novel-bottom"}>
                    {createImg !== null ? <img src={createImg?.imgUrl} style={{marginBottom : "20px"}} ref={(el) => imgRef.current = el} alt='' onError={noImage}/> : <img src={episodeDetail?.imgUrl} style={{display : "block" , marginBottom : "20px"}} ref={(el) => imgRef.current = el} alt='' onError={noImage}/>}
                    {sceneList && sceneList.map((scene, index) => {return(<div className={createNovel5Css.scene_group + " scene_group scene-group"} key={`scenelist_${scene.id}`} tabIndex={index}>
                        <div className={createNovel5Css.novel_bottom_scene + " novel_bottom_scene novel-bottom-scene"}>
                            <div className={createNovel5Css.scene_no + " scene_no scene-no"}>#씬 {index + 1}</div>
                            <div className={createNovel5Css.scene_no2 + " scene_no scene-no2"} onClick={() => {onClickDeleteButton(index)}}>삭제</div>
                            <div className={createNovel5Css.scene_text + " scene_text_n scene-text"}>
                                {scene?.title}
                            </div>
                        </div>
                        <div className={createNovel5Css.scene_detail + " scene_detail scene-detail"}>
                            <SceneDetailTextareaTextarea
                                name=""
                                id="auto-height-textarea"
                                className={createNovel5Css.scene_text_area + " scene_text_area scene-text-area"}
                                onChange={(e) => {onChangeText(e, index)}}
                                value={scene?.scene}>
                            </SceneDetailTextareaTextarea>
                        </div>
                    </div>)})}
                    <div className={createNovel5Css.novel_bottom_scene + " novel_bottom_scene novel-bottom-scene"} ref={(el) => focusRef.current = el}>
                        <div className={createNovel5Css.scene_no_n + " scene_no scene-no_n"}>#씬 {sceneList.length + 1}</div>
                        <div className={createNovel5Css.scene_text_n + " scene_text_n scene-text-n"}>우측 메뉴에서 다음 씬을 생성해주세요.</div>
                    </div>
                </div>
            </ContainerDiv>

            <aside className={createNovel5Css.rightAside + " rightAside"}>
                <div className={createNovel5Css.content + " content"}>
                    <ShowLeftAsideButton id="showLeftAside" onClick={refClick}>추가도구</ShowLeftAsideButton>
                    <div className={createNovel5Css.tit + " tit"}>
                        <svg
                            width="25"
                            height="25"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            style={{fill : "#bcbcbf"}}
                        >
                            <path
                                d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                            />
                        </svg>
                        <p style={{marginBottom : "0"}}>삽화 생성</p>
                    </div>
                    <div className={createNovel5Css.scene_btn + " scene_btn scene-btn"}>
                        <div className={createNovel5Css.next_scene + " next_scene next-scene"} onClick={onClickCreateImg}>소설 삽화 생성하기+</div>
                    </div>
                    <div className={createNovel5Css.scene_btn + " scene_btn scene-btn"}>
                        {createImgLoding === false ? <div className={createNovel5Css.scene_n + " scene_n scene-n"}>삽화가 생성중입니다.</div> : <></>}
                    </div>
                    <hr className={createNovel5Css.edit_hr + " edit_hr edit-hr"} />
                    {novelDetail && <V1CreateNovelRightAsideSceneCreate lastScene={lastScene} novelDetail={novelDetail} characters={characters} selectBool={selectBool} setSelectBool={setSelectBool} dataList={sceneList} setDataList={setSceneList} isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setLengthBool={setLengthBool}/>}
                </div>
            </aside>
        </>
    );
};
export default CreateNovel5V1;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    padding: 0 140px;
    overflow: scroll;
    position: relative;
    @media (max-width: 1400px) {
        padding: 0px 80px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        padding: 0 22px;
    }
`;

/** button 태그 #showLeftAside css */
const ShowLeftAsideButton = styled.button`
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    padding: 6px 16px;
    background-color: transparent;
    color: #828284;
    border: 1px solid #828284;
    border-radius: 18px;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 40px;
    display: none;
    &:hover {
        background-color: #2c2c34;
    }
    @media (max-width: 1400px) {
        display: block;
    }
`;

/** div 태그 #input-area css 
const InputAreaDiv = styled.div`
    display: none;
    align-items: center;
    margin: 10px 0px;
    justify-content: space-between;
`;*/

/** aside 태그 .leftAside css */
const LeftAsideAside = styled.aside`
    background-color: #22222a;
    height: 100%;
    overflow-y: auto;
    min-width: 320px;
    @media (max-width: 1400px) {
        display: none;
        position: fixed;
        z-index: 2;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        display: none;
    }
`;


/** textarea 태그 .scene-detail > textarea css */
const SceneDetailTextareaTextarea = styled(ReactTextareaAutosize)`
    border: none;
    font-size: 18px;
    font-family: "Min Sans-Light";
    color: #22222a;
    letter-spacing: -0.3px;
    width: 100%;
    overflow-y: hidden;
    resize: none;
    line-height: 40px;
    &:focus {
        outline: none;
    }
`;

