const URL = {
  //COMMON
  MAIN: '/', // 메인페이지
  MAINROOT: '', // 메인페이지
  CREATE: '/create/*', // 글쓰기페이지
  LIST: '/list/*', // 베테랑, 프리존, 그림스토어 페이지
  SIGN: '/sign/*', // 회원가입 페이지
  FIND: '/find/*', // 찾기 페이지
  MY_PAGE : '/my-page/*', // 마이 페이지
  AI_EDITOR: '/ai-editor/*', // ai 편집기
  WITHDRAWAL : '/withdrawal/*', // 회원탈퇴 페이지
  MODIFY : '/modify/*', // 글정보수정, 캐릭터 수정 페이지
  CHARGE_SHOP_MAIN : '/charge-shop/*', // 결제연동 페이지

  // 회원 탈퇴 페이지 /withdrawal/* 
  WITHDRAWAL_1 : '/withdrawal/1', // 탈퇴1 페이지
  WITHDRAWAL_2 : '/withdrawal/2', // 탈퇴2 페이지
  WITHDRAWAL_3 : '/withdrawal/3', // 탈퇴3 페이지


  // 글쓰기 페이지 /create/*
  MAIN_NOVEL : 'main', // 집필실 메인
  CREATE_MAIN : '/create/main', //집필실 메인
  NOVEL1: 'novel1', // 글생성페이지1
  CREATE_NOVEL1: '/create/novel1', // 글생성페이지1
  NOVEL2: 'novel2', // 글생성페이지2
  CREATE_NOVEL2: '/create/novel2', // 글생성페이지2
  NOVEL2_NOSYNOP: 'novel2-nosynop', // 글생성페이지2 시놉 선택 X
  CREATE_NOVEL2_NOSYNOP: '/create/novel2-nosynop', // 글생성페이지2 시놉선택 X
  NOVEL3: 'novel3/:novelId', // 글생성페이지3
  CREATE_NOVEL3: '/create/novel3/', // 글생성페이지3
  CREATE_NOVEL3_FULL: '/create/novel3/:novelId', // 글생성페이지3
  NOVEL3_1: 'novel3-1/:novelId', // 글생성페이지3-1
  CREATE_NOVEL3_1: '/create/novel3-1/', // 글생성페이지3-1
  CREATE_NOVEL3_1_FULL: '/create/novel3-1/:novelId', // 글생성페이지3-1
  NOVEL4: 'novel4/:novelId', // 글생성페이지4
  CREATE_NOVEL4: '/create/novel4/', // 글생성페이지4
  CREATE_NOVEL4_FULL: '/create/novel4/:novelId', // 글생성페이지4
  EDIT_WORK: 'edit-work', //집필실 작품 편집
  CREATE_EDIT_WORK: '/create/edit-work', // 집필실 작품 편집 페이지
  EDIT_CHARACTER: 'edit-character', // 집필실 등장인물 수정 페이지
  CREATE_EDIT_CHARACTER: '/create/edit-character', // 집필실 등장인물 수정 페이지
  MY_PICTURE: 'my-picture', // 집필실 내 그림 페이지
  CREATE_MY_PICTURE: '/create/my-picture', // 집필실 내 그림 페이지
  EDIT_PICTURE: 'edit-picture/:imgId', // 집필실 그림정보수정 페이지
  CREATE_EDIT_PICTURE : '/create/edit-picture/:id', // 집필실 그림정보수정 페이지
  CREATE_EDIT_PICTURE_INPUT : '/create/edit-picture/', // 집필실 그림정보수정 페이지
  PICTURE1: 'picture1', // 글생성페이지1
  CREATE_PICTURE1: '/create/picture1', // 글생성페이지1
  PICTURE2: 'picture2', // 글생성페이지2
  CREATE_PICTURE2: '/create/picture2', // 글생성페이지2
  PICTURE3: 'picture3', // 글생성페이지3
  CREATE_PICTURE3: '/create/picture3', // 글생성페이지3
  PICTURE4: 'picture4', // 글생성페이지4
  CREATE_PICTURE4: '/create/picture4', // 글생성페이지4

  CREATENOVEL: '/create-novel/:novelId/:sessionId', // 글생성페이지5
  CREATENOVEL2: '/create-novel2/:novelId/:sessionId', // 글생성페이지5
  CREATENOVEL_INPUT: '/create-novel/', // 글생성페이지5
  CREATENOVEL_INPUT2: '/create-novel2/', // 글생성페이지5

  // 결제 연동 페이지 /charge-shop/*
  CHECK_OUT : 'check-out/:priceData',
  CHARGE_SHOP_MAIN_CHECK_OUT : '/charge-shop/check-out/:priceData', // 결제위젯
  CHARGE_SHOP_MAIN_CHECK_OUT_INPUT : '/charge-shop/check-out/', // 결제위젯
  SUCCESS : 'success',
  CHARGE_SHOP_MAIN_SUCCESS : '/charge-shop/success', // 결제성공
  FAIL : 'fail',
  CHARGE_SHOP_MAIN_FAIL : '/charge-shop/fail', // 결제실패


  // ai 편집기 페이지/ai-editor/*
  MAIN_EDIT: 'main', // ai 편집기 메인 페이지
  AI_EDITOR_MAIN_EDIT: '/ai-editor/main', // ai 편집기 메인 페이지
  NOVEL_EDIT: 'novel/:editorId', // ai 편집기 소설편집 페이지
  NOVEL_EDIT_INPUT: 'novel/', // ai 편집기 소설편집 페이지
  EPISODE_EDIT: 'episode/:editorId/:episodeId', // ai 편집기 소설편집 페이지
  EPISODE_EDIT_INPUT: 'episode/', // ai 편집기 소설편집 페이지
  NOVEL_SESSION_EDIT: 'novel/session/:novelId/:sessionId', // ai 편집기 소설편집 페이지
  NOVEL_SESSION_EDIT_INPUT: 'novel/session/', // ai 편집기 소설편집 페이지
  AI_EDITOR_NOVEL_EDIT: '/ai-editor/novel/:editorId', // ai 편집기 소설편집 페이지
  AI_EDITOR_NOVEL_EDIT_INPUT: '/ai-editor/novel/', // ai 편집기 소설편집 페이지
  AI_EDITOR_EPISODE_EDIT: '/ai-editor/episode/:editorId/:episodeId', // ai 편집기 소설편집 페이지
  AI_EDITOR_EPISODE_EDIT_INPUT: '/ai-editor/episode/', // ai 편집기 소설편집 페이지
  AI_EDITOR_NOVEL_SESSION_EDIT: '/ai-editor/novel/session/:novelId/:sessionId', // ai 편집기 소설편집 페이지
  AI_EDITOR_NOVEL_SESSION_EDIT_INPUT: '/ai-editor/novel/session/', // ai 편집기 소설편집 페이지
  NOVEL_EDIT_FILE: 'novel-file', // ai 편집기 소설편집 페이지
  AI_EDITOR_NOVEL_EDIT_FILE: '/ai-editor/novel-file', // ai 편집기 소설편집 페이지


  // list 루트 (베테랑, 프리존, 그림스토어페이지)
  /** 베테랑 페이지(veteran) */
  VETERAN: 'veteran', 
  /** 베테랑 페이지(/list/veteran) */
  LIST_VETERAN: '/list/veteran', 

  /** 프리존 페이지(freezon) */
  FREEZON: 'freezon', 
  /** 프리존 페이지(/list/freezon) */
  LIST_FREEZON: '/list/freezon', 

  /** 그림스토어 페이지(drawing_store) */
  DRAWING_STORE: 'drawing-store', 
  /** 그림스토어 페이지(/list/drawing_store) */
  LIST_DRAWING_STORE: '/list/drawing-store',

  /** 공지사항 페이지(notice) */
  NOTICE: 'notice',
  /** 공지사항 페이지(/list/notice) */
  LIST_NOTICE: '/list/notice',

  /** 공지사항 상세 페이지(notice) */
  NOTICE_ID : 'notice/:noticeId',
  LIST_NOTICE_ID : '/list/notice/:noticeId',

  /** 공지사항 1:1 문의 페이지(Inquiry) */
  INQUIRY : 'inquiry',
  LIST_INQUIRY : '/list/inquiry',
  INQUIRY_ID : 'Inquiry/:inquiryId',
  LIST_INQUIRY_ID : '/list/Inquiry/:inquiryId',

  /** 충전소 페이지(charge-shop) */
  CHARGE_SHOP: 'charge-shop',
  /** 충전소 페이지(/list/charge-shop) */
  LIST_CHARGE_SHOP: '/list/charge-shop',

  /** 작품 상세 페이지(novel/:novelid) */
  NOVELID : `novel/:novelid`,
  /** 작품 상세 페이지(/list/novel/:novelId) */
  LIST_NOVELID: `/list/novel/:novelId`,
  LIST_NOVEL_ID_INPUT: `/list/novel/`,
  /** 그림 상세 페이지(illust/:novelId) */
  ILLUSTID : `illust/:illustId`,
  /** 그림 상세 페이지(/list/illust/:illustId) */
  LIST_ILLUSTID: `/list/illust/:illustId`,
  LIST_ILLUST_ID_INPUT: `/list/illust/`,

  /** 작품 상세 페이지(글 /novel/*) */
  NOVEL_IN : `/novel/*`,


  /** 작품 회차 상세 페이지(:novelId) */
  NOVEL_DETAIL : 'detail/:novelId/:sessionId',
  NOVEL_IN_NOVEL_DETAIL : '/novel/detail/:novelId/:sessionId',
  LIST_NOVEL_IN_NOVEL_DETAIL : '/list/novel/detail/:novelId/:sessionId',
  /** 작품 상세 글 페이지 (session/:novelId/:episodeId) */
  NOVEL_SESSION : 'session/:novelId/:episodeId',
  NOVEL_IN_NOVEL_DETAIL_NOVEL_SESSION: '/novel/session/:novelId/:episodeId',

  /** 개인정보처리방침, 서비스 이용약관, 청소년 보호정책 */
  TERM_IN : '/term/*', 
  /** 개인정보처리방침 */
  PERSONAL_INFORMATION : 'personal-information',
  TERM_IN_PERSONAL_INFORMATION : '/term/personal-information',
  /** 서비스 이용약관 */
  TERMS_SERVICE : 'terms-service',
  TERM_IN_TERMS_SERVICE : '/term/terms-service',
  /** 청소년 보호정책 */
  YOUTH_PROTECTION_POLICY : 'youth-protection-policy',
  TERM_IN_YOUTH_PROTECTION_POLICY : '/term/youth-protection-policy',
  /** 작가 가입 약관 */
  WRITER_REGISTRATION : 'writer-registration',
  TERM_IN_WRITER_REGISTRATION : '/term/writer-registration',
  /** 작가 이용 가이드 */
  WRITER_USE : 'writer-use',
  TERM_IN_WRITER_USE : '/term/writer-use',


  /** 회원가입 페이지 */
  /** 회원가입 1단계 이용약관 페이지(terms) */
  TERMS : 'terms',
  SIGN_TERMS : '/sign/terms',
  /** 회원가입 2단계 정보입력 페이지(sign-input)  */
  SIGN_INPUT : 'sign-input',
  SIGN_SIGN_INPUT : '/sign/sign-input',

  USER_TYPE : 'user-type',
  SIGN_USER_TYPE : '/sign/user-type',

  ACCOUNT_CHANGE : 'account-change',
  SIGN_ACCOUNT_CHANGE : '/sign/account-change',


  /** 찾기 페이지 */
  /** 비밀번호 찾기 페이지(password) */
  PASSWORD : 'password',
  FIND_PASSWORD : '/find/password',
  /** 찾기 완료 페이지(password2) */
  PASSWORD2 : 'password2',
  FIND_PASSWORD2 : '/find/password2',
  /** 변경 페이지(change-password) */
  CHANGE_PASSWORD : 'change-password',
  FIND_CHANGE_PASSWORD : '/find/change-password',



  /** 마이페이지 */
  /** 마이페이지 - 계정정보 페이지(my-info) */
  MY_INFO : 'my-info',
  MY_PAGE_MY_INFO : '/my-page/my-info',
  /** 판매 결제 내역 */
  PAYMENT_DETAIL : 'payment-detail',
  MY_PAGE_PAYMENT_DETAIL : '/my-page/payment-detail',


  /** 글 정보 수정, 캐릭터 수정 페이지 */
  /** 글정보 수정 페이지 - (/modify/novel/:novelId) */
  NOVEL_MODIFY : 'novel/:novelId',
  NOVEL_MODIFY_INPUT : 'novel/',
  MODIFY_NOVEL_MODIFY_INPUT : '/modify/novel/',
  /** 캐릭터 수정 페이지 - (/modify/character/:novelId) */
  CHARACTER_MODIFY : 'character/:novelId',
  CHARACTER_MODIFY_INPUT : 'character/',
  MODIFY_CHARACTER_MODIFY_INPUT : '/modify/character/',

  /** 내 그림 직접 등록 페이지 */
  MY_PICTURE_MODIFY : 'my-picture',
  MODIFY_MY_PICTURE_MODIFY : '/modify/my-picture',


  LOGIN: '/login', // 로그인 페이지
  ERROR: '/error', // 에러 페이지

  TEST: '/test',
};

export default URL;
