import styled from "@emotion/styled";
import AiEditorMain from "../../components/ai_editor/AiEditorMain";
import AiEditorNovel from "../../components/ai_editor/AiEditorNovel";
import AiEditorNovelFile from "../../components/ai_editor/AiEditorNovelFile";
import URL from "../../constants/url";
import { Route, Routes, useNavigate } from "react-router";
import { BrowserView, MobileView } from 'react-device-detect';
import loginNImg from '../../css/images/login.png'
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import LeftAside2 from "../../components/common/LeftAside2";
import AiEditorCreateNovel from "../../components/ai_editor/AiEditorCreateNovel";
import AiEditorEpisode from "components/ai_editor/AiEditorEpisode";


const AizacAiEditor = () => {

    const navigate = useNavigate();

    // 이전페이지로 버튼
    const onClickMobBtn = () => {
        navigate(-1);
    }

    // 화면 크기 확인
    const isMediumDevice = useMediaQuery( {query : "(min-width : 569px)"});

    const [isUpdateBool, setIsUpdateBool] = useState(false);

    const [textTitle, setTextTitle] = useState('');
    const [textContent, setTextContent] = useState('');

    return(
        <>
            <BrowserView>
                <WrapDiv id="wrap">
                    <LeftAside2 isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} setTextTitle={setTextTitle} setTextContent={setTextContent}/>
                    <Routes>
                        <Route path={URL.MAIN_EDIT} element={<AiEditorMain textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.NOVEL_EDIT} element={<AiEditorNovel isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.EPISODE_EDIT} element={<AiEditorEpisode isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.NOVEL_SESSION_EDIT} element={<AiEditorCreateNovel isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.NOVEL_EDIT_FILE} element={<AiEditorNovelFile isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                    </Routes>
                </WrapDiv>
            </BrowserView>
            {/* 휴대폰 여부 검사 후 모바일 페이지 랜더링 */}
            <MobileView>
                {isMediumDevice === true ? <WrapDiv id="wrap">
                    <LeftAside2 isUpdateBool={isUpdateBool}/>
                    <Routes>
                        <Route path={URL.MAIN_EDIT} element={<AiEditorMain textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.NOVEL_EDIT} element={<AiEditorNovel isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/> } />
                        <Route path={URL.EPISODE_EDIT} element={<AiEditorEpisode isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.NOVEL_SESSION_EDIT} element={<AiEditorCreateNovel isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                        <Route path={URL.NOVEL_EDIT_FILE} element={<AiEditorNovelFile isUpdateBool={isUpdateBool} setIsUpdateBool={setIsUpdateBool} textTitle={textTitle} setTextTitle={setTextTitle} textContent={textContent} setTextContent={setTextContent}/>} />
                    </Routes>
                </WrapDiv> : 
                <MobWriteContainerDiv id="mob-write-container">
                    <MobWriteImgDiv className="mob-write-img">
                        <img src={loginNImg} alt="login_img"/>
                    </MobWriteImgDiv>
                    <MobWriteContainerH2H2 className="title">원활한 서비스를 위해 현재 집필실은<br/> PC와 태블릿에서만 이용하실 수 있습니다</MobWriteContainerH2H2>
                    <EditNextButton className="edit-next" type="button" onClick={onClickMobBtn}>이전 페이지로</EditNextButton>
                </MobWriteContainerDiv> }
            </MobileView>
        </>
    );
};
export default AizacAiEditor;

/** div 태그 #wrap css */
const WrapDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto; 
    height: 100vh;
    @media(max-width:1400px) {
        display: grid;
        grid-template-columns: 3fr 1.5fr ;
        grid-template-rows: auto; 
        height: 100vh;
    }
    @media(min-width:100px) and (max-width:768px) {
        grid-template-columns: auto; 
        grid-template-rows: auto;
    }
`;

/** div 태그 #mob-write-container css */
const MobWriteContainerDiv = styled.div`
@media (min-width: 569px) {
    display: none;
}
@media (max-width: 568px) {
    padding: 0 40px;
    padding-top: 120px;
}
`;

/** h2 태그 #mob-write-container .title css */
const MobWriteContainerH2H2 = styled.h2`
@media (max-width: 568px) {
    font-size: 24px;
    font-family: "Min Sans-bold";
    padding-bottom: 20px;
    letter-spacing: -0.03em;
    color: #050409;
    line-height: 40px;
    text-align: center;
    padding-top: 36px;
}
`;

/** div 태그 .mob-write-img css */
const MobWriteImgDiv = styled.div`
@media (max-width: 568px) {
    text-align: center;
}
`;

/** button 태그 .edit-next css */
const EditNextButton = styled.button`
@media (max-width: 568px) {
    width: 100%;
    background-color: #22222A;
    color: #fff;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.3px;
    margin: 50px 0px 120px 0px;
    height: 70px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #303039;
    }
}

`;