import loginImg from '../../css/images/login.png'
import eyeLine1Img from '../../css/images/eye-line 1.png'
import eyeOffImg from '../../css/images/eye-off.png'
import { Link, useNavigate } from 'react-router-dom';
import loginCss from '../../css/login.module.css'
import styled from '@emotion/styled';
import Header from '../../components/common/Header';
import { useEffect, useRef, useState } from 'react';
import URL from '../../constants/url';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import sign2Css from '../../css/sign2.module.css'
import { getCookie, removeCookieOption, setCookieOption } from '../../hooks/Cookie';
import { apiServerState } from '../../constants/recoilData';
import Swal from 'sweetalert2';
import $ from 'jquery';


const AizacLogin = (/*{props}*/) => {

    const inputPasswordRef = useRef();
    const eyeIconRef = useRef();

    let passwordVisible = false;

    // 비밀번호 눈버튼 클릭시 입력값 타입 변경
    const onClickPasswordEye = () => {
        if (passwordVisible) {
            inputPasswordRef.current.type = 'password';
            eyeIconRef.current.src = eyeLine1Img;
            passwordVisible = false;
        } else {
            inputPasswordRef.current.type = 'text';
            eyeIconRef.current.src = eyeOffImg
            passwordVisible = true;
        }
    }

    const [email, setEmail] = useState('');
    // 이메일 변경 입력 함수
    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const [password, setPassword] = useState('');
    // 비밀번호 변경 입력 함수
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);
    const [errorMessage, setErrorMessage] = useState('');
    
    // 최초 랜더링시 토큰 검사
    useEffect(() => {
        const token = getCookie("token");
        if (token !== undefined) {
            navigate(URL.MY_PAGE_MY_INFO);
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    
    // 아이작 로그인 버튼
    const onClickLogin = () => {
        const form = {email : email, password : password};
        const emailCheck2 = (String(email)).trim();
        if (emailCheck2 === "") {
            alert("아이디를 입력해주세요.");
            return;
        }
        const passwordCheck2 = (String(password)).trim();
        if (passwordCheck2 === "") {
            alert("비밀번호를 입력해주세요.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        axios
        .post(apiServer + "/api/asiac_login/login", form, config)
        .then((response) => {
            if (response?.data?.success && response?.data?.data?.userToken) {
                const option = {path : ""};
                setCookieOption("token", response.data.data.userToken, option);
                const form  = {};
                const config2 = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": response.data.data.userToken,
                    }
                };
                axios
                    .post(apiServer + "/api/asiac_login/daily_coin", form, config2)
                    .then((response) => {
                        if (response?.data?.success === true) {
                            Swal.fire({
                                title : "일일 접속 보상",
                                text: "일일 출석보상 100코인이 적립되었습니다 (사용기한 30일)",
                                confirmButtonColor: "#FF5F37",
                                confirmButtonText: "확인",
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.confirm) {
                                    navigate(-1);
                                } 
                            });
                        } else {
                            navigate(-1);
                        }
                    })
                    .catch((e) => {
                        removeCookieOption("token", {path : "/"});
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            } else {
                if (response?.data?.custMsg === 'invalid email') {
                    setErrorMessage("이메일이 바르지 않습니다.");
                    alert("이메일이 바르지 않습니다.");
                } else if (response?.data?.custMsg === 'invalid password' ) {
                    setErrorMessage("비밀번호가 바르지 않습니다.");
                    alert("비밀번호가 바르지 않습니다.");
                } else if (response?.data?.custMsg === 'invalid user') {
                    setErrorMessage("회원정보가 없습니다.");
                    alert("회원정보가 없습니다.");
                } else if (response?.data?.custMsg === 'router err'){
                    alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                } else if (response?.data?.custMsg === "blind user") {
                    alert("블라인드된 계정입니다. 고객센터로 문의해주세요");
                    setErrorMessage("블라인드된 계정입니다. 고객센터로 문의해주세요");
                } else {
                    alert(response?.data?.custMsg);
                    setErrorMessage(response?.data?.custMsg);
                }
                console.log(response?.data?.custMsg);
            }
        })
        .catch((e) => {
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    }

    // 엔터키 막기
    $(document).keypress(function (e) {
        if (e.keyCode == 13 && !$(e.target).is('textarea')) e.preventDefault();
    });

    // 엔터키 입력시 로그인 버튼 클릭 효과
    const handleOnKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickLogin();
        }
    }

    // 아이작이 처음이신가요 클릭
    const onClickNew = () => {
        navigate(URL.SIGN_TERMS);
    }

    return (
        <>
            <Header/>
            <div id="wrap">  
                <LoginContainerDiv id="loginContainer">
                    <LogBoxDiv className={loginCss.logBox + " logBox"}>
                        <div className={loginCss.logImg + " logImg"}>
                            <img src={loginImg} alt='login_img'/>
                        </div>
                        <div className={loginCss.write + " write"}>
                            <WritePP>모두가 작가가 되는 곳</WritePP>
                            <WriteH2H2>아이작 로그인</WriteH2H2>
                        </div>
                        <LoginDiv className={loginCss.login + " login"}>
                            <EmailDiv className={loginCss.email + " email"}>
                                <IconSpan className={loginCss.icon + " icon"}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.49984 2.5H17.4998C17.9601 2.5 18.3332 2.8731 18.3332 3.33333V16.6667C18.3332 17.1269 17.9601 17.5 17.4998 17.5H2.49984C2.0396 17.5 1.6665 17.1269 1.6665 16.6667V3.33333C1.6665 2.8731 2.0396 2.5 2.49984 2.5ZM16.6665 6.0316L10.0597 11.9483L3.33317 6.01328V15.8333H16.6665V6.0316ZM3.75939 4.16667L10.0514 9.71833L16.2507 4.16667H3.75939Z" fill="#22222A"/>
                                    </svg>
                                </IconSpan>    
                                <DefaultInput type="text" id="email" name="name" placeholder="이메일을 입력해주세요" onChange={onChangeEmail}/>
                            </EmailDiv>
                            <PassWordDiv className={loginCss.passWord + " passWord"}>
                                <IconSpan className={loginCss.icon + " icon"}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 6.66634H16.6667C17.1269 6.66634 17.5 7.03944 17.5 7.49967V17.4997C17.5 17.9599 17.1269 18.333 16.6667 18.333H3.33333C2.8731 18.333 2.5 17.9599 2.5 17.4997V7.49967C2.5 7.03944 2.8731 6.66634 3.33333 6.66634H5V5.83301C5 3.07158 7.23857 0.833008 10 0.833008C12.7614 0.833008 15 3.07158 15 5.83301V6.66634ZM4.16667 8.33301V16.6663H15.8333V8.33301H4.16667ZM9.16667 11.6663H10.8333V13.333H9.16667V11.6663ZM5.83333 11.6663H7.5V13.333H5.83333V11.6663ZM12.5 11.6663H14.1667V13.333H12.5V11.6663ZM13.3333 6.66634V5.83301C13.3333 3.99206 11.8409 2.49967 10 2.49967C8.15905 2.49967 6.66667 3.99206 6.66667 5.83301V6.66634H13.3333Z" fill="#22222A"/>
                                    </svg>                            
                                </IconSpan>
                                
                                <DefaultInput type="password" id="password" name="pass" ref={inputPasswordRef} placeholder="비밀번호를 입력해주세요" onChange={onChangePassword} onKeyPress={handleOnKeyPress}/>
                                <EyeSpan className={loginCss.eye + " eye"} onClick={onClickPasswordEye}>
                                    <img src={eyeLine1Img} ref={eyeIconRef} id="togglePassword"  alt='eyeLine_img'/> 
                                </EyeSpan>
                            </PassWordDiv>
                            <FindDiv className={loginCss.find + " find"}>
                                <div className={loginCss.findPassword + " findPassword"}><DefaultALink to={URL.FIND_PASSWORD}>비밀번호 찾기</DefaultALink></div>
                            </FindDiv>
                        </LoginDiv>
                        {errorMessage !== '' ?<div className={sign2Css.descript + " descript"} style={{marginBottom: "10px"}}>
                            <DescriptSpanSpan>{errorMessage}</DescriptSpanSpan>
                        </div> : <></>}
                        <LoginBtnButton type="button" id="loginBtn" onClick={onClickLogin}>아이작 로그인</LoginBtnButton>
                        <FirstVisitBtnButton type="button" id="firstVisitBtn" onClick={onClickNew}><DefaultALink >아이작이 처음이신가요?</DefaultALink></FirstVisitBtnButton>
                    </LogBoxDiv>
                    
                </LoginContainerDiv>
            </div>
        </>
    );
};
export default AizacLogin;

/** div 태그 #loginContainer css */
const LoginContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .logBox css */
const LogBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    @media (max-width: 560px) {
        padding-top: 48px;
    }
`;

/** p 태그 .write p css */
const WritePP = styled.p`
    color: #050409;
    font-family: "Min Sans-bold";
    letter-spacing: -0.3px;
    font-size: 20px;
    padding-top: 32px;
    padding-bottom: 8px;
    text-align: center;
`;

/** h2 태그 .write h2 css */
const WriteH2H2 = styled.h2`
    color: #050409;
    font-family: "Min Sans-bold";
    letter-spacing: -0.3px;
    font-size: 46px;
    text-align: center;
`;

/** div 태그 .login css */
const LoginDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 52px;
    margin-bottom: 28px;
    width: 100%;
`;

/** div 태그 .email css */
const EmailDiv = styled.div`
    border:1px solid #BDBDBD;
    align-items: center;

    display: flex;
`;

/** span 태그 .icon css */
const IconSpan = styled.span`
    padding: 0 18px;
`;

/** div 태그 .passWord css */
const PassWordDiv = styled.div`
    border:1px solid #BDBDBD;
    align-items: center;
    display: flex;
    position: relative;
    &::after {
        position: absolute;
        right: 24px; 
        font-size: 24px;
        font-family: "Min Sans-Light";
    }
`;

/** span 태그 .eye css */
const EyeSpan = styled.span`
    position: absolute;
    right: 20px;
    cursor: pointer;
`;

/** div 태그 .find css */
const FindDiv = styled.div`
    display: flex;
    justify-content: space-between;
    color: #050409;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    font-size: 16px;
    cursor: pointer;
`;

/** button 태그 #loginBtn css */
const LoginBtnButton = styled.button`
    padding: 24px 0;
    width: 100%;
    color: #fff;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    font-size: 17px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #22222a;
    margin-bottom: 12px;
`;

/** button 태그 #firstVisitBtn css */
const FirstVisitBtnButton = styled.button`
    padding: 24px 0;
    width: 100%;
    color: #fff;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    font-size: 17px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ff5f37;
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** input 태그 디폴트 css */
const DefaultInput = styled.input`
    border: none;
    outline: none;
    width: 100%;
    padding: 18px 0;
    color: #050409;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    font-size: 16px;
    &::placeholder{
        color: #828284;
        font-family: "Min Sans-Regular";
        letter-spacing: -0.3px;
        font-size: 16px;
    }
`;

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;