
import styled from "@emotion/styled";
import CreateNovel5V1 from "components/createNovel/CreateNovel5";


// 테스트용 컴포넌트
const AizacTest = () => {


    return (
        <>
            <WrapDiv id="wrap">
                <CreateNovel5V1 />
            </WrapDiv>
        </>
    );
}
  
export default AizacTest;

/** div 태그 #wrap css */
const WrapDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto;
    height: 100vh;
`;
  