import styled from '@emotion/styled';
import mainCss from '../../css/main.module.css'
import chargeShop from '../../css/chargeShop1.module.css'
import { useEffect, useState } from 'react';
import ChargeItem from './ChargeItem';
import { useNavigate } from 'react-router';
import URL from '../../constants/url';
import { apiServerState } from '../../constants/recoilData';
import { getCookie } from '../../hooks/Cookie';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import bannerDefault from '../../css/images/bannerDefault.png'


// 충전소 페이지 컴포넌트
const ChargeShop1 = () => {
    // 베이직 리스트
    const [basicList, ] = useState([{"title" : "3000코인 패키지", "price" : "3000", "content" : "패키지 특별 판매가", "payBool" : true },
                                                {"title" : "5000코인 패키지", "price" : "5000", "content" : "패키지 특별 판매가", "payBool" : true },
                                                {"title" : "10000코인 패키지", "price" : "10000", "content" : "패키지 특별 판매가", "payBool" : true },
                                                {"title" : "준비중", "price" : "continue", "content" : "더 다양한 패키지를 준비 중입니다!", "payBool" : false } ]);
    
    // 프리미엄 리스트
    const [premiumList, ] = useState([{"title" : "50000코인 패키지", "price" : "50000", "content" : "패키지 특별 판매가", "payBool" : true },
                                                    {"title" : "80000코인 패키지", "price" : "80000", "content" : "패키지 특별 판매가", "payBool" : true },
                                                    {"title" : "100000코인 패키지", "price" : "100000", "content" : "패키지 특별 판매가", "payBool" : true },
                                                    {"title" : "준비중", "price" : "continue", "content" : "더 다양한 패키지를 준비 중입니다!", "payBool" : false } ])

    const [isBasic, ] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })

    }, [])

    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();
    const token = getCookie("token");
    
    const [bannerList, setBannerList] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    // isUpdateBool 변경시 유저 정보 업데이트(api 코인차감시 업데이트 용도+)
    useEffect(() => {
        const config = {
        headers: {
            "Content-Type": "application/json",
            "authorization": token,
        }
        };
    
        const update = () => {
        const url = apiServer + "/api/aizac_purchase_banner/get_purchase_banners";
    
        const banner = async () => {
            await axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.success) {
                    setBannerList(response?.data?.data?.banners);
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
    
        banner();
    
        };
        update();
    }, []);

    // 배너 클릭
    const onClickBanner = (url) => {
        window.location.href = url;
    }

    return (
        <>
            <div id="wrap">
                <div className={chargeShop.container + " container"} >
                    <RankTitleDiv className={chargeShop.rank_title + " rank_title"}>
                        <RankTitleH2H2>365일 혜택이 팡팡</RankTitleH2H2>
                        <RankTitleH1H1>아이작 코인 충전소</RankTitleH1H1>
                    </RankTitleDiv>
                    {isMounted === false ? <></> : <div className={mainCss.container + " container"} style={{marginBottom : "10px"}}>
                        <section className={mainCss.slider + " slider"} style={{cursor:"pointer"}}>
                        <CarouselDiv id="carouselExampleIndicators" className={mainCss.carousel + " carousel slide " + mainCss.slide}>
                            <CarouselIndicatorsDiv className={mainCss.carousel + " carousel-indicators"}>
                            {bannerList && bannerList.map((banner, index) => {return(<button key={banner?.id}
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={index}
                                className={index === 0 ? "active" : ""}
                                aria-current="true"
                                aria-label={"Slide " + index}
                            ></button>)})}
                            </CarouselIndicatorsDiv>
                            <CarouselInnerDiv className={mainCss.carousel_inner + " carousel-inner"}>
                            {bannerList && bannerList.map((banner, index) => {return(<CarouselInnerItemDiv key={banner?.id} className={index === 0 ? (mainCss.carousel_item + " carousel-item active") : (mainCss.carousel_item + " carousel-item")} onClick={() => {onClickBanner(banner?.url);}}>
                                <CarouselInnerItemImg src={banner?.path !== null ? banner?.path : bannerDefault} fetchpriority="high" className={mainCss.d_block + " d-block w-100"} alt="banner" />
                            </CarouselInnerItemDiv>)})}
                            </CarouselInnerDiv>
                            <CarouselControlNextPrevButton
                            className={mainCss.carousel_control_prev + " carousel-control-prev"}
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                            >
                            <span className={mainCss.carousel_control_prev_icon + " carousel-control-prev-icon"} aria-hidden="true"></span>
                            <span className={mainCss.visually_hidden + " visually-hidden"}>Previous</span>
                            </CarouselControlNextPrevButton>
                            <CarouselControlNextPrevButton
                            className={mainCss.carousel_control_next + " carousel-control-next"}
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                            >
                            <span className={mainCss.carousel_control_next_icon + " carousel-control-next-icon"} aria-hidden="true"></span>
                            <span className={mainCss.visually_hidden + " visually-hidden"}>Next</span>
                            </CarouselControlNextPrevButton>
                        </CarouselDiv>
                        </section>
                    </div>}
                    <ChargeDiv className={chargeShop.charge + " charge"}>
                        <TitleH2 className={chargeShop.title + " title"}>베이직 충전패키지</TitleH2>
                        <BasicDiv className={chargeShop.basic + " basic"}>
                            {basicList && basicList.map((item, index) => {return( <ChargeItem key={"basicItem" + index} item={item} isBasic={isBasic} /> )})}
                        </BasicDiv>
                    </ChargeDiv>  
                    <ChargePremiumDiv className={chargeShop.charge + " " + chargeShop.premium + " charge premium"}>
                        <TitleH2 className={chargeShop.title + " title"}>프리미엄 충전패키지</TitleH2>
                        <BasicDiv className={chargeShop.basic + " basic"}>
                            {premiumList && premiumList.map((item, index) => {return( <ChargeItem key={"premiumItem" + index} item={item} isBasic={!isBasic} /> )})}
                        </BasicDiv>          
                    </ChargePremiumDiv>
                    <DefaltHr/>
                    <InformationDiv className={chargeShop.information + " information"}>
                        <InformationTitP className={chargeShop.tit + " tit"}>결제상품 이용안내</InformationTitP>
                        <InformationPP>- 구입하신 코인은 모든 디바이스에서 자유롭게 사용이 가능합니다.<br/>
                            - 결제 시 선택하시는 결제 방법에 따라 추가적인 부가세가 부과될 수 있으며, 소비자 부담으로 적용됩니다.<br/>
                            - 각 패키지에 대한 할인 퍼센테이지는 예고없이 종료되거나 변경될 수 있습니다.<br/>
                            - 휴대전화, ARS 결제 취소는 당월 결제 건에 한해서만 가능합니다.<br/>
                            - 결제에 관한 모든 약관 및 정책은 콘텐츠 정책과 서비스 이용약관을 참고해주세요.<br/>
                            - 결제 후 미충전 오류, 기타 결제에 관한 문의는 아래 고객센터로 연락주시면 감사하겠습니다.</InformationPP>
                    </InformationDiv>         
                </div>
            </div>
        </>
    );
}

export default ChargeShop1;

/** div 태그 디폴트 css 
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;*/

/** div 태그 .container css 
const ContainerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 568px) {
        padding: 0 18px;
    }
`;*/

/** div 태그 .carousel css */
const CarouselDiv = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
`;

/** div 태그 .carousel-inner css */
const CarouselInnerDiv = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
`;

/** div 태그 .carousel-inner .carousel-item css */
const CarouselInnerItemDiv = styled.div`
  width: 1280px;
  height: 426px;
  padding: 0px 10px;
  margin-left: 0px;
  object-fit: cover;
  transition: transform 0.8s ease-in-out;
  @media (min-width: 768px) and (max-width: 1024px) {
    width : inherit;
    height : fit-content;
  }
  @media (min-width: 280px) and (max-width: 768px) {
    width : inherit;
    height : fit-content;
  }
  @media (min-width: 100px) and (max-width: 280px) {
    width : inherit;
    height : 100px;
  }
  @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx){
    width: 1280px;
    height: 426px;
    padding: 0px 10px;
    margin-left: 0px;
    object-fit: cover;
    @media (min-width: 1024px) and (max-width: 1400px) {
      width : inherit;
      height : 300px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width : inherit;
      height : 225px;
    }
    @media (min-width: 280px) and (max-width: 768px) {
      width : inherit;
      height : 165px;
    }
    @media (min-width: 100px) and (max-width: 280px) {
      width : inherit;
      height : 100px;
    }
  }
`;

/** img 태그 .carousel-inner .carousel-item > img css */
const CarouselInnerItemImg = styled.img`
  border-radius: 20px;
  height: -webkit-fill-available;
  @media (min-width: 100px) and (max-width: 280px) {
    height : 100px;
  }
  @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx) {
    @media (min-width: 100px) and (max-width: 280px) {
      height : 100px;
    }
  }
`;

/** div 태그 .carousel-indicators css */
const CarouselIndicatorsDiv = styled.div`
  right: 10px;
`;

/** button 태그 .carousel-control-next, .carousel-control-prev css  */
const CarouselControlNextPrevButton = styled.button`
  padding: 0px 0px;
  width: 80px;
`;

/** div 태그 .rank-title css */
const RankTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 60px;
    @media (max-width: 560px) {
        padding-top: 120px;
    }
    @media (max-width: 568px) {
        padding-top: 40px;
    }
`;

/** h2 태그 .rank-title h2 css */
const RankTitleH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 20px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
    @media (max-width: 568px) {
        font-size: 12px;
        margin-bottom: 4px;
    }
`;

/** h1 태그 .rank-title h1 css */
const RankTitleH1H1 = styled.h1`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 46px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    padding-bottom: 20px;
    @media (max-width: 568px) {
        font-size: 26px;
    }
`;

/** div 태그 .mainBanner css 
const MainBannerDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-bottom: 42px;
    @media (max-width: 568px) {
        margin: 0 -26px;
    }
`;*/

/** img 태그 .mainBanner img css 
const MainBannerImgImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    @media (max-width: 568px) {
        width: 100%;
        height: auto;
    }
`;*/

/** div 태그 .charge css */
const ChargeDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 1400px) {
        max-width: 624px;
        margin: 0 auto;
    }
    @media (max-width: 568px) {
        max-width: 300px;
    }
`;

/** h2 태그 .title css */
const TitleH2 = styled.h2`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #050409;
    font-family: "min sans-medium";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    margin-bottom: 12px;
    @media (max-width: 568px) {
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

/** div 태그 .basic css */
const BasicDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

/** div 태그 .content css 
const ContentDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 28px;
    background-color: #F8F8F8;
    border-radius: 20px;
    width: calc(25% - 12px); 
    float: left; 
    @media (max-width: 1400px) {
        width: calc(50% - 12px);  
    }
    @media (max-width: 568px) {
        width:100%;
        margin-bottom: 12px;
    }
    @media (max-width:360px) {
        width:100%;
    }
`;*/

/** div 태그 .package css 
const PackageDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #040A20;
    border-radius: 31px;  
    color: #fff;
    justify-content: center;
    gap:6px;
    align-self: center;
    padding: 11px 0 12px 0;
`;*/

/** p 태그 .package p css 
const PackagePP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-bottom: 0;
    font-size: 18px;
    font-family: "min sans-bold";
    letter-spacing: -0.3px;
`;*/

/** img 태그 디폴트 css 
const DefaltImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;*/

/** div 태그 .priceTab css 
const PriceTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: right;
    padding-top: 28px;
    padding-bottom: 12px;
`;*/

/** p 태그 .priceTab p css 
const PriceTabP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    font-family: "Min Sans-regular";
    color: #9B9B9D;
    letter-spacing: -0.3px;
    margin-bottom: 0;
`;*/

/** span 태그 .percent css 
const PercentSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    font-family: "Min Sans-bold";
    color: #FF5F37;
    letter-spacing: -0.3px;
    padding-right: 10px;
`;*/

/** span 태그 .discount css 
const DiscountSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    font-family: "Min Sans-bold";
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: -0.3px;
    position: relative;
    display: inline-block;
    &::before {
        content: '';
        height: 1px;
        background-color: #828284;
        display: block;
        position: absolute;
        top: 50%;
        left: 0; 
        right: 0;
        transform: translateY(-50%);
    }
`;*/

/** span 태그 .money css 
const MoneySpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    padding-left: 10px;
`;*/

/** span 태그 .won css 
const WonSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
`;*/

/** div 태그 .payment css 
const PaymenetDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #FF5F37;
    color: #fff;
    text-align: center;
    padding: 11px 0 12px 0;
    border-radius: 31px;
    cursor: pointer;
    &:hover {
        background-color: #ff7957;
    }
`;*/

/** span 태그 .payment span css 
const PaymentSpanSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    font-family: "min sans-medium";
    letter-spacing: -0.3px;
`;*/

/** div 태그 .charge .premium css */
const ChargePremiumDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 32px;
    @media (max-width: 1400px) {
        max-width: 624px;
        margin: 0 auto;
    }
    @media (max-width: 568px) {
        max-width: 300px;
    }
`;

/** hr 태그 디폴트 css */
const DefaltHr = styled.hr`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #d9d9d9;
    margin-top: 60px;
`;

/** div 태그 .information css */
const InformationDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 24px;
    padding-bottom: 110px;
    line-height: initial;
    @media (max-width: 568px) {
        padding-bottom: 80px;
    }
`;

/** p 태그 .information .tit p css */
const InformationTitP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #828284;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 1rem;
    @media (max-width: 568px) {
        margin-bottom: 12px;
        line-height: 1.6;
    }
`;
/** p 태그 .information p css */
const InformationPP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #828284;
    font-size: 14px;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
    margin-bottom: 0;
    @media (max-width: 568px) {
        line-height: 1.6;
    }
`;