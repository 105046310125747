import { Link, useNavigate } from "react-router-dom";
import mypageCss from '../../css/mypage_account.module.css'
import styled from "@emotion/styled";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import URL from "../../constants/url";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiServerState } from "../../constants/recoilData";
import Swal from "sweetalert2";


const MyPageAction = () => {

    const navigate = useNavigate();
    const token = getCookie("token");
    const apiServer = useRecoilValue(apiServerState);
    const [user, setUser] = useState(null);
    const [date, setDate] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 로그아웃 버튼
    const onClickLogout = () => {
        const confirmationMessage = "로그아웃 하시겠습니까?";
        Swal.fire({
            title: "로그아웃 안내",
            text: confirmationMessage,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "로그아웃",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                removeCookie("token");
                removeCookieOption("token", {path : "/"});
                localStorage.removeItem("pageData");
                localStorage.removeItem("pageDataSynop");
                localStorage.removeItem("categoryNotice");
                localStorage.removeItem("categoryPay");
                localStorage.removeItem("myPicturePageData");
                localStorage.removeItem("rowCnt");
                window.localStorage.clear();
                const token = getCookie("token");
                if (token !== undefined) {
                    removeCookieOption("token", {path : "/"});
                    navigate(URL.MAIN);
                } else {
                    navigate(URL.MAIN);
                }
            }
        });
    }

    // 비밀번호 변경 버튼
    const onClickChangePassword = () => {
        navigate(URL.FIND_CHANGE_PASSWORD);
    }

    // 유저 정보 셋팅 
    const update = () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
        
        const urlEpi = apiServer + "/api/asiac_login/get_my_info";
        
        const urlUserCall = async () => {
            axios
                .get(urlEpi, config)
                .then((response) => {
                    if (response?.data?.success) {
                        setUser(response?.data?.data?.user);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else if (msg === "invalid user") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        };

        if(token !== undefined) {
            urlUserCall();
        }
        
    };

    const dateSet = () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
        const urlDate = apiServer + "/server_time";
        
        const urlUserCall = async () => {
            axios
                .get(urlDate, config)
                .then((response) => {
                    if (response?.data?.success) {
                        const dateData = response?.data?.data;
                        const date = new Date(dateData);
                        setDate(parseInt(date.getDate()));
                    } else {
                        alert(response?.data?.custMsg);
                        console.log("로딩 실패");
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        };
        urlUserCall();
    }

    // 최초 랜더링시 유저정보 셋팅
    useEffect(() => {
        update();
        dateSet();     
    }, [])

    // isUpdate 변경시 유저정보 업데이트
    useEffect(() => {
        update();     
    }, [isUpdate])

    // 회원탈퇴 버튼
    const onClickWithDrawal = () => {
        navigate("/withdrawal/1");
    }

    const [bcalBtnBool, setBcalBtnBool] = useState(false);

    // 정산 신청 버튼
    const onClickCalculate = () => {
        if (parseInt(user?.point) >= 0) {
            if(user?.bankName === "") {
                alert("정산 계좌를 등록 후 정산신청해주세요.");
                return;
            }
            if(user?.accountNumber === "") {
                alert("정산 계좌를 등록 후 정산신청해주세요.");
                return;
            }
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
            const form = {};
            axios
            .post(apiServer + "/api/point_calc/apply_calc", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    alert("정산 신청이 완료되었습니다.");
                    setBCalcBool(false);
                    setBcalBtnBool(true);
                    setTimeout(() => {
                        setBCalcBool(true);
                        setIsUpdate(!isUpdate);
                    }, 2000);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        } else if (parseInt(user?.point) === null || parseInt(user?.point) === 0) {
            alert("정산 가능금액이 없습니다.");
        }
    }

    // 베테랑 신청 버튼
    const onClickChangeAuthorKind = () => {
        if (user?.proficiency === "novice") {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": token,
                }
            };
            const form = {"nickname" : user?.nickname};
            axios
            .post(apiServer + "/api/asiac_login/apply_veteran", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    alert("베테랑 신청하였습니다.");
                    setIsUpdate(!isUpdate);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === 'invalid user') {
                        alert("탈퇴하거나 없는 유저입니다.");
                    } else if (msg === 'already veteran') {
                        alert("이미 베테랑 유저입니다.");
                    } else if (msg === 'already applied') {
                        alert("관리자가 변경 심사 중입니다.");
                    }  else if (msg === 'router err') {
                        alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                    } else {
                        alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        } else {
            alert("베테랑 유저입니다.");
        }
    }

    // 유저 정보 변경시 현재 날짜 상태 저장


    const [bCalcBool, setBCalcBool] = useState(true);

    const onClickCalBtn = () => {
        alert("정산 신청일이 아닙니다.");
    }
    const onClickCalBtn2 = () => {
        alert("정산을 이미 신청하였습니다.");
    }

    useEffect(() => {
        console.log(date);
    }, [date])

    return(
        <>
            <ActionSection className={mypageCss.action + " action"}>
                {user?.authorKind === "solo_free" ? user?.proficiency === "veteran" ? <></> : <ChangeDiv className={mypageCss.change + " change"} onClick={onClickChangeAuthorKind}>
                    <DefaultALink >
                        <ChangeSpanSpan>베테랑 작가 신청</ChangeSpanSpan>
                    </DefaultALink>
                </ChangeDiv> : <></>}
                {user?.ikind === "author" ? date >= 1 && date <= 6 ? (user?.bCalcApplied === 0 && bCalcBool === true) ? bcalBtnBool === false ? <ChangeDiv className={mypageCss.change + " change"} onClick={onClickCalculate}>
                    <DefaultALink >
                        <ChangeSpanSpan>정산 신청</ChangeSpanSpan>
                    </DefaultALink>
                </ChangeDiv>: <LogoutLeaveDiv className={mypageCss.logout + " logout"} onClick={onClickCalBtn2}>
                    <DefaultALink >
                        <span >정산 금액은 월 말일에 일괄 입금됩니다. </span>
                    </DefaultALink>
                </LogoutLeaveDiv> : <LogoutLeaveDiv className={mypageCss.logout + " logout"} onClick={onClickCalBtn2}>
                    <DefaultALink >
                        <span >정산 금액은 월 말일에 일괄 입금됩니다. </span>
                    </DefaultALink>
                </LogoutLeaveDiv> :  user?.bCalcApplied === 0 && bCalcBool === true ? <LogoutLeaveDiv className={mypageCss.logout + " logout"} onClick={onClickCalBtn}>
                    <DefaultALink >
                        <span >정산 신청은 매월 1일 ~ 6일 사이에 가능합니다. </span>
                    </DefaultALink>
                </LogoutLeaveDiv> : <LogoutLeaveDiv className={mypageCss.logout + " logout"} onClick={onClickCalBtn2}>
                    <DefaultALink >
                        <span >정산 금액은 월 말일에 일괄 입금됩니다. </span>
                    </DefaultALink>
                </LogoutLeaveDiv> : <></>}
                <ChangeDiv className={mypageCss.change + " change"} onClick={onClickChangePassword}>
                    <DefaultALink >
                        <ChangeSpanSpan><ChangeSpanSvg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 6.66536H16.6667C17.1269 6.66536 17.5 7.03846 17.5 7.4987V17.4987C17.5 17.9589 17.1269 18.332 16.6667 18.332H3.33333C2.8731 18.332 2.5 17.9589 2.5 17.4987V7.4987C2.5 7.03846 2.8731 6.66536 3.33333 6.66536H5V5.83203C5 3.07061 7.23857 0.832031 10 0.832031C12.7614 0.832031 15 3.07061 15 5.83203V6.66536ZM4.16667 8.33203V16.6654H15.8333V8.33203H4.16667ZM9.16667 11.6654H10.8333V13.332H9.16667V11.6654ZM5.83333 11.6654H7.5V13.332H5.83333V11.6654ZM12.5 11.6654H14.1667V13.332H12.5V11.6654ZM13.3333 6.66536V5.83203C13.3333 3.99108 11.8409 2.4987 10 2.4987C8.15905 2.4987 6.66667 3.99108 6.66667 5.83203V6.66536H13.3333Z" fill="white"/>
                            </ChangeSpanSvg>
                            </ChangeSpanSpan>
                        <ChangeSpanSpan>비밀번호 변경</ChangeSpanSpan>
                    </DefaultALink>
                </ChangeDiv>
                <LogoutLeaveDiv className={mypageCss.logout + " logout"} onClick={onClickLogout}>
                    <DefaultALink >
                        <span >로그아웃</span>
                    </DefaultALink>
                </LogoutLeaveDiv>
                <LogoutLeaveDiv className={mypageCss.leave + " leave"} onClick={onClickWithDrawal}>
                    <DefaultALink >
                        <span>탈퇴하기</span>
                    </DefaultALink>
                </LogoutLeaveDiv>
            </ActionSection>
        </>
    );
}
export default MyPageAction;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/**section 태그 .action css */
const ActionSection = styled.section`
    margin-top: 32px;
    @media(max-width:568px) {
        margin-top: 42px;
    }
`;

/**div 태그 .change css */
const ChangeDiv = styled.div`
    width:100%;
    padding:18px 20px;
    text-align: center;
    font-family: "min sans-medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    border-radius: 28px;
    margin-bottom:16px;
    cursor: pointer;
    @media(max-width:568px) {
        margin-bottom: 12px;
    }
    color: #fff;
    background-color: #ff5f37;
    &:hover{
        background-color: #ff7957;
    }
`;

/** div 태그 .logout .leave css */
const LogoutLeaveDiv = styled.div`
    width:100%;
    padding:18px 20px;
    text-align: center;
    font-family: "min sans-medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    border-radius: 28px;
    margin-bottom:16px;
    cursor: pointer;
    @media(max-width:568px) {
        margin-bottom: 12px;
    }
    background-color: #F6F6F6;
    color: #9B9B9D;
`;

/** span 태그 .change span css */
const ChangeSpanSpan = styled.span`
    vertical-align: middle;
`;

/** svg 태그 .change span svg css */
const ChangeSpanSvg = styled.svg`
    vertical-align: middle;
`;