import { Link, useNavigate, useParams } from "react-router-dom";
import editPictureCss from '../../css/drawing_modify.module.css'
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import URL from "../../constants/url";
import axios from "axios";
//import reactTextareaAutosize from "react-textarea-autosize";
import $ from 'jquery';
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { apiServerState } from "../../constants/recoilData";
import defaultImg from '../../css/images/illustDefault.png'


const EditPicture = () => {

    const params = useParams();

    const [img, setImg] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [category, setCategory] = useState(0);
    const [title, setTitle] = useState("");
    const [synopsis, setSynopsis] = useState("");
    const [price, setPrice] = useState(0);
    const [priceCheck, setPriceCheck] = useState(true);

    const apiServer = useRecoilValue(apiServerState);
    const token = getCookie("token");
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    const config = {
        headers: {
          "Content-Type": "application/json",
          "authorization": token,
        }
    };

    const urlImgDetail = apiServer + "/api/aizac_ai_img/ai_a_img_simple?id=" + params.imgId;

    // 이미지 정보 api 호출 함수
    const imgDetail = () => {
        axios
            .get(urlImgDetail, config)
            .then((response) => {
                if (response?.data?.success) {
                    setImg(response?.data?.data?.img);
                    setTitle(response?.data?.data?.img?.title);
                    setSynopsis(response?.data?.data?.img?.synopsis);
                    const payPrice = parseInt(response?.data?.data?.img?.price);
                    setPrice(payPrice);
                    setCategory(response?.data?.data?.img?.categoryId);

                    switch (response?.data?.data?.img?.categoryId) {
                        case 1 : {
                            $('.actionBtn').removeClass("active");
                            $("#category1").addClass("active");
                            $("#category1").addClass("select");
                            $("#category1").select();
                            break;
                        }
                        case 2 : {
                            $('.actionBtn').removeClass("active");
                            $("#category2").addClass("active");
                            $("#category2").addClass("select");
                            $("#category2").select();
                            break;
                        }
                        case 3 : {
                            $('.actionBtn').removeClass("active");
                            $("#category3").addClass("active");
                            $("#category3").addClass("select");
                            $("#category3").select();
                            break;
                        }
                        case 4 : {
                            $('.actionBtn').removeClass("active");
                            $("#category4").addClass("active");
                            $("#category4").addClass("select");
                            $("#category4").select();
                            break;
                        }
                        case 5 : {
                            $('.actionBtn').removeClass("active");
                            $("#category5").addClass("active");
                            $("#category5").addClass("select");
                            $("#category5").select();
                            break;
                        }
                    }
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "invalid img") {
                        alert("비정상적인 경로입니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    navigate(URL.MAIN);
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }

    // 유저정보 호출 함수
    const updateUserInfo = () => {
        const urlUserInfo = apiServer + "/api/asiac_login/get_my_info";
    
        const userInfo = async () => {
            await axios
            .get(urlUserInfo, config)
            .then((response) => {
                if (response?.data?.success ) {
                    setUserInfo(response?.data?.data?.user);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
    
        if (token !== undefined) {
            userInfo();
        }
    };
    
    // 최초 랜더링시 호출
    useEffect (() => {
        imgDetail();  
        updateUserInfo();
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, []);

    // userInfo, img 셋팅 후 유저 id 와 img userId 비교(비정상 접근 방지)
    useEffect(() => {
        if (userInfo !== null && img !== null) {
            if(userInfo?.id !== img?.userId) {
                alert("비정상적인 접근입니다.");
                navigate(URL.MAIN);
            }
        } 
    }, [userInfo, img])

    // 카테고리 셋팅시 유지
    useEffect (() => {
        switch (category) {
            case 1 : {
                $('.actionBtn').removeClass("active");
                $("#category1").addClass("active");
                $("#category1").addClass("select");
                $("#category1").select();
                break;
            }
            case 2 : {
                $('.actionBtn').removeClass("active");
                $("#category2").addClass("active");
                $("#category2").addClass("select");
                $("#category2").select();
                break;
            }
            case 3 : {
                $('.actionBtn').removeClass("active");
                $("#category3").addClass("active");
                $("#category3").addClass("select");
                $("#category3").select();
                break;
            }
            case 4 : {
                $('.actionBtn').removeClass("active");
                $("#category4").addClass("active");
                $("#category4").addClass("select");
                $("#category4").select();
                break;
            }
            case 5 : {
                $('.actionBtn').removeClass("active");
                $("#category5").addClass("active");
                $("#category5").addClass("select");
                $("#category5").select();
                break;
            }
        } 
    }, [category]);

    // 제목 변경 입력 함수
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    // 그림 정보 변경 입력 함수
    const onChangeSynopsis = (e) => {
        setSynopsis(e.target.value);
    };

    // 그림 가격 변경 입력 함수
    const onChangePrice = (e) => {
        setPrice(e.target.value);
    };

    // 카테고리 버튼 클릭
    $(document).ready(function () {
        $(".actionBtn").click(function () {
            $('.actionBtn').removeClass("active");
            $(this).addClass("active");
            const val = $(this).val();
            setCategory(val);
        });
    });

    // 이대로 저장 버튼
    const onSaveClick = () => {
        
        if (category === 0) {
            alert("카테고리를 입력하세요.");
            return;
        } else if (category === null || category === undefined) {
            alert("카테고리를 입력하세요.");
            return;
        }
        if (title === '') {
            alert("제목을 입력하세요.");
            return;
        } else if (title.length < 2 || title.length > 30) {
            alert("제목은 2자 ~ 30자 이내로 입력해주세요.");
            return;
        }
        const titleCheck = (String(title)).trim();
        if (titleCheck === "") {
            alert("제목을 입력하세요.");
            return;
        }
        const synopsisCheck = (String(synopsis)).trim();
        if (synopsisCheck === "") {
            alert("그림 소개문을 입력하세요.");
            return;
        }
        if (synopsis === '') {
            alert("그림 소개문을 입력하세요.");
            return;
        } else if (synopsis.length < 2 || synopsis.length > 150) {
            alert("소개문은 2자 ~ 150자 이내로 입력해주세요.");
            return;
        }
        
        if (priceCheck === false) {
            alert("그림가격은 숫자만 입력하세요.");
            return;
        }
        if (parseInt(price) === 0) {
            alert("그림가격을 입력하세요.");
            return;
        } else if (price === "") {
            alert("그림가격을 입력하세요.");
            return;
        } else if (price > 1000000000) {
            alert("그림가격 입력이 초과되었습니다.");
            return;
        } else if (price < 10) {
            alert("그림최소가격은 10원입니다.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",

                "authorization": token,
            }
        };
        let form2 = {
            "id": parseInt(params.imgId)
            ,"title": titleCheck
            ,"synopsis": synopsisCheck
            ,"price": price
            ,"categoryId": category
            ,"bShow" : true        
            //,"synopsisId":1 //  insert 할시에만 필요
            //,"url":"test1" // insert 할시에만  필요
        };
        axios
            .post(apiServer + "/api/aizac_ai_img/update_ai_img", form2, config)
            .then((response) => {
                if (response?.data?.success ) {
                    console.log("저장하였습니다.");
                    removeCookieOption("picture3Data", {path : ""});
                    alert("저장하였습니다.")
                    navigate(URL.CREATE_MY_PICTURE);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인 후 이용해주세요.");
                        logout();
                    } else if (msg === "invalid info") {
                        alert("입력값을 확인해주세요.");
                    } else if (msg === "invalid aiimg") {
                        alert("그림이 없습니다.");
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                    } else {
                        alert(msg);
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
    }

    // 가격 정규식 체크(숫자만)
    const priceRegExCheck = (e) => {
        const priceRegEx = new RegExp(/^\d+$/)
        const price = e.target.value;
        const bool = priceRegEx.test(price);
        setPriceCheck(bool);
    }

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return(
        <>
            {isMounted === true ? <ContainerDiv id="container">
                <SynopTabSection className={editPictureCss.synop_tab + " synop_tab"}>
                    <SynopTabGroupDiv className={editPictureCss.synop_tab_group + " synop_tab_group"}>
                        <SynopTabBtnUDiv className={editPictureCss.synop_tab_btn_u + " synop_tab_btn_u"}><SynopTabBtnUALink >시놉시스 선택</SynopTabBtnUALink></SynopTabBtnUDiv>
                        <SynopTabBtnLineDiv className={editPictureCss.synop_tab_btn_line + " synop_tab_btn_line"}></SynopTabBtnLineDiv>
                    </SynopTabGroupDiv>
                    <SynopTabGroupDiv className={editPictureCss.synop_tab_group + " synop_tab_group"}>
                        <SynopTabBtnUDiv className={editPictureCss.synop_tab_btn_u + " synop_tab_btn_u"}><SynopTabBtnUALink >배경정보</SynopTabBtnUALink></SynopTabBtnUDiv>
                        <SynopTabBtnLineDiv className={editPictureCss.synop_tab_btn_line + " synop_tab_btn_line"}></SynopTabBtnLineDiv>
                    </SynopTabGroupDiv>
                    <SynopTabGroupDiv className={editPictureCss.synop_tab_group + " synop_tab_group"}>
                        <SynopTabBtnUDiv className={editPictureCss.synop_tab_btn_u + " synop_tab_btn_u"}><SynopTabBtnUALink >등장인물</SynopTabBtnUALink></SynopTabBtnUDiv>
                        <SynopTabBtnLineDiv className={editPictureCss.synop_tab_btn_line + " synop_tab_btn_line"}></SynopTabBtnLineDiv>
                    </SynopTabGroupDiv>
                    <SynopTabGroupDiv className={editPictureCss.synop_tab_group + " synop_tab_group"}>
                        <SynopTabBtnUDiv className={editPictureCss.synop_tab_btn_u + " synop_tab_btn_u"}><SynopTabBtnUALink >그림생성</SynopTabBtnUALink></SynopTabBtnUDiv>
                        <SynopTabBtnLineDiv className={editPictureCss.synop_tab_btn_line + " synop_tab_btn_line"}></SynopTabBtnLineDiv>
                    </SynopTabGroupDiv>
                </SynopTabSection>
                <ContainerH2H2>그림 정보 수정</ContainerH2H2>
                <ContentDiv className={editPictureCss.content + " content"}>
                    <BookCoverDiv className={editPictureCss.bookCover + " bookCover"}>
                        <BookCoverImgImg src={img?.imgUrl} alt="bookImg" onError={noImage}/>
                    </BookCoverDiv>
                    <CateSelectDiv className={editPictureCss.cateSelect + " cateSelect"}>
                        <CateSelectPP>카테고리를 선택해주세요</CateSelectPP>
                        <CateSelectUlUl>
                            <CateSelectUlLiLi id="category1" className=" actionBtn" value={1}>로맨스</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category2" className=" actionBtn" value={2}>판타지</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category3" className=" actionBtn" value={3}>스릴러</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category4" className=" actionBtn" value={4}>무협</CateSelectUlLiLi>
                            <CateSelectUlLiLi id="category5" className=" actionBtn" value={5}>기타</CateSelectUlLiLi>
                        </CateSelectUlUl>
                    </CateSelectDiv>

                    <DefaultLabel htmlFor="imageTitle">그림 제목을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※제목은 2자~30자 이내로 입력해주세요</DescriptionTextSpan>
                    <ImageTitleInput type="text"id="imageTitle" placeholder="제목을 입력해주세요" maxLength="30" onChange={onChangeTitle} defaultValue={img?.title}/>

                    <DefaultLabel htmlFor="imageDescription">그림 소개문을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※소개문은 2자~150자 이내로 입력해주세요</DescriptionTextSpan>
                    <ImageDescriptionTextarea 
                        id="imageDescription" 
                        name="imageDescription" 
                        rows="4" 
                        cols="50" 
                        maxLength="150" 
                        required 
                        placeholder="소개문을 입력해주세요"
                        onChange={onChangeSynopsis}
                        defaultValue={img?.synopsis}></ImageDescriptionTextarea>
                    <br/>
                    <br/>
                    <DefaultLabel htmlFor="imagePrice">그림 가격을 입력해주세요</DefaultLabel>
                    <DescriptionTextSpan className={editPictureCss.description_text + " description_text"}>※그림가격은 1,000,000,000 이내로 입력해주세요</DescriptionTextSpan>
                    <InputContainerDiv className={editPictureCss.input_container + " input_container"}>
                        <ImagePriceInput type="number" id="imagePrice" placeholder="가격을 입력해주세요" onChange={(e) => {onChangePrice(e); priceRegExCheck(e)}} defaultValue={img?.price}/>
                    </InputContainerDiv>
                    <SaveNextBtn type="submit" className={editPictureCss.saveNextBtn + " saveNextBtn"} onClick={onSaveClick}><DefaultALink >이대로 저장</DefaultALink></SaveNextBtn>
                </ContentDiv>
            </ContainerDiv> : <ContainerDiv id="container">
                <></>
            </ContainerDiv>}
        </>
    );
};
export default EditPicture;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    overflow-y: auto; 
    padding: 0 140px;
    @media(max-width:1400px) {
        padding: 0 80px;
    }
    @media(max-width:568px) {
        padding: 0 22px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 50px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** h2 태그 #container h2 css */
const ContainerH2H2 = styled.h2`
    margin: 0;
    padding: 0;
    font-family: normal;
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-top: 60px;
    padding-bottom: 28px;
    border-bottom: 1px solid #EDEDED;
    font-weight: bold;
    @media(max-width:568px) {
        font-size: 24px;
        padding-bottom: 18px;
    }
`;

/**section 태그 .synop_tab css */
const SynopTabSection = styled.section`
    margin-top: 108px;
    display: flex;
    justify-content: center;
`;

/** div 태그 .synop_tab_group css */
const SynopTabGroupDiv = styled.div`
    width: 100%;
`;

/** div 태그 .synop_tab_btn_u css */
const SynopTabBtnUDiv = styled.div`
    text-align: center;
`;

/** a 태그 .synop_tab_btn_u > a css */
const SynopTabBtnUALink = styled(Link)`
    text-decoration: none;
    color: inherit;
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #ff5f37;
    position: relative;
    letter-spacing: -0.3px;
`;

/** div 태그 .synop_tab_btn_line css */
const SynopTabBtnLineDiv = styled.div`
    width: 100%;
    height: 4px;
    background-color: #ff5f37;
    margin-top: 12px;
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 52px;
`;

/** div 태그 .bookCover css */
const BookCoverDiv = styled.div`
    width: 300px;
    height: 476px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

/** img 태그 .bookCover img css */
const BookCoverImgImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    @media(max-width:568px) {
        margin-bottom: 42px;
        max-width:52%
    }
`;

/** div 태그 .cateSelect css */
const CateSelectDiv = styled.div`
    margin-bottom: 60px;
    margin-top: 52px;
    @media(max-width:568px) {
        margin: 42px 0;
    }
`;

/** p 태그 .cateSelect p css */
const CateSelectPP = styled.p`
    font-size: 18px;
    color: #050409;
    font-family: "Min Sans-Light";
    margin-bottom: 12px;
`;

/** ul 태그 .cateSelect ul css */
const CateSelectUlUl = styled.ul`
    display: flex;
    gap: 10px;
    padding-left: 0;
`;

/** li 태그 .cateSelect ul li css */
const CateSelectUlLiLi = styled.li`
    padding: 8px 20px;
    border: 1px solid #BDBDBD;
    border-radius: 19px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    text-align: center;
    color: #828284;
    cursor: pointer;
    &.selected {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &.active {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    &:hover {
        background-color: #040A20;
        color: #fff;
        border: 1px solid #040A20;
    }
    @media(max-width:568px) {
        font-size: 13px;
        padding: 8px 12px;
    }
`;

/** label 태그 디폴트 css */
const DefaultLabel = styled.label`
    color: #050409;
    font-size: 18px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
`;

/** span 태그 .description_text css */
const DescriptionTextSpan = styled.span`
    font-size: 14px;
    color: #828284;
    font-family: "Min Sans-Light";
    padding-left: 4px;
    @media(max-width:568px) {
        padding: 0;
        display: block;
        padding-top: 4px;
    }
`;

/** input 태그 #imageTitle css */
const ImageTitleInput = styled.input`
    border: 1px solid #BDBDBD;
    padding: 22px 24px;
    width: 100%;
    color: #22222a;
    outline: none;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
    &::placeholder{
        font-size: 24px;
        color: #9B9B9D;
        font-family: "Min Sans-Light";
    }
    margin-top: 12px;
    margin-bottom: 60px;
    border: 1px solid #BDBDBD;
    @media(max-width:568px) {
        font-size: 18px;
        padding: 22px 16px;
        margin-bottom: 42px;
    }
`;

/** textarea 태그 #imageDescription css */
const ImageDescriptionTextarea = styled.textarea`
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    &::placeholder {
        color: #9B9B9D;
    }
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
    margin-top: 12px;
    margin-bottom: 40px;
    @media(max-width:568px) {
        margin-bottom: 20px;
    }
`;

/** div 태그 .input_container css */
const InputContainerDiv = styled.div`
    border-bottom: 1px solid #EDEDED;
    position: relative;
    &::after {
        content: "C";
        position: absolute;
        top: 36px;
        right: 24px; 
        color: #ff5f37;
        font-size: 24px;
        font-family: "Min Sans-Light";
    }
    @media(max-width:568px) {
        &::after{
            top: 32px;
        }
    }
`;

/** input 태그 #imagePrice css */
const ImagePriceInput = styled.input`
    border: 1px solid #BDBDBD;
    padding: 22px 24px;
    width: 100%;
    color: #22222a;
    outline: none;
    font-size: 24px;
    font-family: "Min Sans-Light";
    letter-spacing: -0.03em;
    &::placeholder{
        font-size: 24px;
        color: #9B9B9D;
        font-family: "Min Sans-Light";
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        display: none;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        display: none;
    }
    margin-bottom: 52px;
    border: 1px solid #BDBDBD;
    margin-top: 12px;
    @media(max-width:568px) {
        margin-bottom: 42px;
        font-size: 18px;
        padding: 22px 16px;
    }
`;

/** button 태그 .saveNextBtn css */
const SaveNextBtn = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    margin-bottom: 120px;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media(max-width:1400px) {
        margin-bottom: 120px;
    }
    @media(max-width:568px) {
        margin-top: 42px;
        margin-bottom: 80px;
    }
`;