import styled from "@emotion/styled";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import novel1Css from'../../css/novelCreate1.module.css';
import URL from "../../constants/url";
import { getCookie, removeCookie, removeCookieOption } from "../../hooks/Cookie";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "axios";
import $ from 'jquery';
import { apiServerState, episodeCntState, novel4DataState, novelDataState } from "../../constants/recoilData";
import Swal from "sweetalert2";


const LeftAsideBookListAiEditor = ({book, rendering, setRendering,isUpdateBool, setIsUpdateBool}) => {
    const bookListRef = useRef();
    const arrowRef = useRef();

    // 작품 제목 클릭시 화살표 모양 변경
    const toggleList = () => {

        var list = $((".bookList" + book.id));

        var arrow = $((".arrow" + book.id));
        if (list.css("display") === "none") {
          list.css("display", "block");
          arrow.css("transform", "rotate(180deg)");
        } else {
          list.css("display", "none");
          arrow.css("transform", "rotate(0deg)");
        }
    }

    
    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);
    const setNovelState = useSetRecoilState(novelDataState);
    const setEpisodeCnt = useSetRecoilState(episodeCntState);

    // 새로운 회차 쓰기 버튼
    const onClickCreateNovel = (novelId) => {
        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {
            const config = {
                headers: {
                  "Content-Type": "application/json", 
                  "authorization": token,
                }
            };
        
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + novelId;
            
                const novelDetail = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setNovelState(response?.data?.data?.novel);
                            setEpisodeCnt(response?.data?.data?.novel?.episodeCnt);
                            const bool = rendering;
                            setRendering(!bool);
                            setNovel4Data({"novel" : response?.data?.data?.novel, "episodeCnt" : response?.data?.data?.novel?.episodeCnt, "dataList" : []});
                            navigate('/create-novel/' + novelId + "/0");
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설 입니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                        }
                    })
                    .catch((e) => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
            
                novelDetail();

            };
            update();
        }
        
    }

    const setNovel4Data = useSetRecoilState(novel4DataState);

    // 회차 클릭시 회차 ai 편집기 이동
    const onClickUpdateNovel = (novelId, episodeId) => {
        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {
            const url = URL.AI_EDITOR_NOVEL_SESSION_EDIT_INPUT + novelId + "/" + episodeId;
            //const url = '/pass/ai-editor/novel/' + novelId + "/" + episodeId;
            navigate(url);
            /*const config = {
                headers: {
                  "Content-Type": "application/json", 
                  "authorization": token,
                }
            };
            const update = () => {

                const urlepi = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + episodeId;

                const episodeList = async () => {
                    await axios
                    .get(urlepi, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setTextTitle(response?.data?.data?.episode?.title);
                            if (response?.data?.data?.episode?.content !== null) {
                                setTextContent(response?.data?.data?.episode?.content);
                            } else {
                                setTextContent("");
                            }
                            
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid episode") {
                                alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else if (msg === "router err") {
                                alert("서버 에러");
                                navigate("/list/novel/" + novelId);
                            } else {
                                alert(msg);
                                navigate("/list/novel/" + novelId);
                            }
                            console.log("로딩 실패!");
                            console.log(msg);
                        }
                    })
                    .catch((e) => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
            
                episodeList();
            };
    
            update();*/
        }
        
    }

    // 작품 정보 수정 버튼
    const onClickModify = (novelId) => {
        navigate('/modify/novel/' + novelId);
    }

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 작품 삭제 버튼
    const onClickDeleteNovel = (novelId) => {
        

        const confirmationMessage =
            "소중한 작품을 정말로 삭제하시겠습니까?";

        Swal.fire({
            title: "삭제 안내",
            text: confirmationMessage,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "삭제하기",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                const token = getCookie("token");

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };
                const form = {"novelId" : novelId};
        
                axios
                    .post(apiServer + "/api/asiac_novel/delete_novel", form, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            alert(`집필 내용을 삭제하였습니다.`);
                            setIsUpdateBool(!isUpdateBool);
                        } else {
                            const msg = response?.data?.custMsg;
                            if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 진행해주세요.");
                                logout();
                            } else if (msg === "invalid data") {
                                alert("비정상적인 접근입니다.");
                                navigate(URL.CREATE_MAIN);
                            } else if (msg === "router failed") {
                                alert("서버 에러");
                                navigate(URL.CREATE_MAIN);
                            } else {
                                alert(response?.data?.custMsg);
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            }
        });
    }

    return(
        <>
            <ListDiv className={novel1Css.list + " list"} id={`list${book?.id}`} >
                <CateDiv className={novel1Css.cate + " cate"} onClick={toggleList}>▶</CateDiv>
                <DefaultDiv className={novel1Css.listInfo + " listInfo"}>
                    <ListInfoTitleP className={novel1Css.title + " title"} onClick={toggleList}>{book?.title}</ListInfoTitleP>
                    <HiddenListUl className={novel1Css.hidden_list + " hidden_list hidden-list " + "bookList" + book?.id} id="bookList" ref={bookListRef}>
                        <HiddenListUlLi onClick={() => {onClickModify(book?.id)}}><DefaultLink >작품 정보 수정</DefaultLink></HiddenListUlLi>
                        <HiddenListUlLi onClick={() => {onClickDeleteNovel(book?.id)}} ><DefaultLink >작품 삭제</DefaultLink></HiddenListUlLi>
                        <HiddenListUlLi onClick={() => {onClickCreateNovel(book?.id)}}><DefaultLink >+ 새로운 회차쓰기</DefaultLink></HiddenListUlLi>
                        {true && book.episodes.map((item) => <HiddenListUlLi key={item?.id} onClick={() => {onClickUpdateNovel(book?.id, item?.id)}}><DefaultLink >{item?.chapter}화</DefaultLink></HiddenListUlLi>)}
                    </HiddenListUl>
                </DefaultDiv>
                <ListGroupDownDiv className={novel1Css.down + " down " + "arrowIcon" + book?.id} id="arrowIcon" ref={arrowRef}>
                    <DefaultSvg
                        width="16"
                        height="10"
                        viewBox="0 0 16 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={"arrow" + book?.id}
                    >
                        <DefaultMask
                            id="mask0_1_7899"
                            style={{maskType: 'alpha'}}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="16"
                            height="10"
                        >
                            <DefaultRect width="16" height="10" fill="#D9D9D9" />
                        </DefaultMask>
                        <DefaultG mask="url(#mask0_1_7899)">
                            <DefaultPath
                                d="M1.25 2.1167L2.34508 1L8 6.76659L13.637 1L14.75 2.11671L8 9L1.25 2.1167Z"
                                fill="#71717D"
                            />
                        </DefaultG>
                    </DefaultSvg>
                </ListGroupDownDiv>
            </ListDiv>
        </>
    );
}

export default LeftAsideBookListAiEditor;


/** div 태그 .list css */
const ListDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    &:first-of-type {border-top: 1px solid #2c2c34;}
    &:last-of-type {margin-bottom: 36px;}
    &:hover {color: #ff5f37;}
    cursor: pointer;
`;

/** div 태그 .cate css */
const CateDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-right: 4px;
    &:hover {color: #ff5f37;}
`;

/** svg 태그 디폴트 css */
const DefaultSvg = styled.svg`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** div 태그 디폴트 css */
const DefaultDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** p 태그 .listInfo .title css */
const ListInfoTitleP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    margin-bottom: 6px;
    &:hover {color: #ff5f37;}
`;

/** ul 태그 .hidden-list css */
const HiddenListUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
    &:hover {color: #ff5f37;}
`;

/** div 태그 .listGroup .down css */
const ListGroupDownDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-left: auto;
    &:hover {color: #ff5f37;}
`;

/** mask 태그 디폴트 css */
const DefaultMask = styled.mask`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** rect 태그 디폴트 css */
const DefaultRect = styled.rect`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** g 태그 디폴트 css */
const DefaultG = styled.g`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;

/** path 태그 디폴트 css */
const DefaultPath = styled.path`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    &:hover {color: #ff5f37;}
`;


/** a 태그 디폴트 css */
const DefaultLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    &:hover {color: #ff5f37;}
`;

/** li 태그 .hidden-list li css */
const HiddenListUlLi = styled.li`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    list-style: none;
    padding: 6px 0;
    color: #8f8f99;
`;