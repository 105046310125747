import { Link, useNavigate } from 'react-router-dom';
import myPictuerCss from '../../css/writeroom_mydrwaing.module.css'
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import axios from 'axios';
import $ from 'jquery';
import URL from '../../constants/url';
import novel1Css from '../../css/novelCreate1.module.css'
import { apiServerState } from '../../constants/recoilData';
import defaultImg from '../../css/images/illustDefault.png'


const MyPictureSection = ({category, pageNum, setPageNum}) => {

    const apiServer = useRecoilValue(apiServerState);
    const navigate = useNavigate();

    const [isMounted, setIsMounted] = useState(false);
    const token = getCookie("token");
    const [imgList, setImgList] = useState([]); 
    const [totalPage, setTotalPage] = useState(1);
    const localData = JSON.parse(localStorage.getItem("myPicturePageData"));

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    useEffect(() => {
        if(pageNum !== null) {
            if(localData !== null && localData !== undefined) {
                if(localData.section === "buy") {
                    localStorage.removeItem("pageData");
                    localStorage.removeItem("pageDataSynop");
                    localStorage.removeItem("categoryNotice");
                    localStorage.removeItem("categoryPay");
                    localStorage.removeItem("myPicturePageData");
                    localStorage.removeItem("rowCnt");
                    const copy = {"section" : "buy", "page" : pageNum};
                    localStorage.setItem("myPicturePageData", JSON.stringify(copy));
                } else {
                    localStorage.removeItem("pageData");
                    localStorage.removeItem("pageDataSynop");
                    localStorage.removeItem("categoryNotice");
                    localStorage.removeItem("categoryPay");
                    localStorage.removeItem("myPicturePageData");
                    localStorage.removeItem("rowCnt");
                    const copy = {"section" : "my", "page" : pageNum};
                    localStorage.setItem("myPicturePageData", JSON.stringify(copy));
                }
            }
        } 
    }, [pageNum])

    let paginationTag = [];

    const config = {
        headers: {
          "Content-Type": "application/json",
          "authorization": token,
        }
    };

    // 이미지 api page 입력 호출 함수
    const imgApipage = async (category1, page) => {
        if (category1 === "my") {
            const url = apiServer + "/api/aizac_ai_img/my_img_list?page=" + page + "&row=10";
    
            axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setImgList(response?.data?.data?.imgList);
                        setTotalPage(response?.data?.data?.totalPages);
                        setIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e}});
                });
        } else if (category1 === "buy") {
            const url = apiServer + "/api/aizac_ai_img/buy_img_list?page=" + page + "&row=10";

            await axios
                .get(url, config)
                .then((response) => {
                    if (response?.data?.success ) {
                        setImgList(response?.data?.data?.imgList);
                        setTotalPage(response?.data?.data?.totalPages);
                        setIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        }
    };

    const [modalIsMounted, setModalIsMounted] = useState(false);

    // 모달 버튼 클릭 여부에 따라 api 호출
    useEffect(() => {
        if(pageNum !== null) {
            imgApipage(category, pageNum);
        }
    }, [modalIsMounted, pageNum, category]);

    // 그림 삭제 버튼(모달 오픈)
    const onClickDeleteBtn = (itemId) => {
        setModalIsMounted(false);
        const modal = $("#myModal" + itemId);
        const cancelBtn = $("#cancelBtn" + itemId);
        const reportBtn = $("#reportBtn" + itemId);
        const modalText = $("#modalTextBtn" + itemId);
        modalText.text("그림을 삭제하시겠습니까?");
        cancelBtn.css("display", "inline"); // 취소 버튼 표시
        //cancelBtn.style.display = "inline"; // 취소 버튼 표시
        reportBtn.css("display", "inline"); // 삭제 버튼 표시
        //reportBtn.style.display = "inline"; // 삭제 버튼 표시
        reportBtn.css("background-color", "#FF5F37");
        //reportBtn.style.backgroundColor = "#FF5F37";
        reportBtn.css("color", "#fff");
        //reportBtn.style.color = "#fff";
        modalText.css("margin-bottom", "24px"); // 스타일 초기화
        //modalText.style.marginBottom = "24px"; // 스타일 초기화

        modal.css("display", "block");
        //modal.style.display = "block";
    }

    // 그림 삭제 모달 취소 버튼
    const onClickCancelBtn = (itemId) => {
        if(category === "my") {
            const modal = $("#myModal" + itemId);
            modal.css("display", "none");
        } else if (category === "buy") {
            const modal = $("#drawModal" + itemId);
            modal.css("display", "none");
        }
    }

    // 그림 삭제 모달 삭제 버튼
    const onClickReportBtn = (itemId) => {
        if(category === "my") {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  "authorization": token,
                }
            };
            let form = { "aiImgId": parseInt(itemId) };
    
            axios
                .post(apiServer + "/api/aizac_ai_img/delete_my_ai_img", form, config)
                .then((response) => {
                    if (response?.data?.success) {
                        const cancelBtn = $("#cancelBtn" + itemId);
                        const reportBtn = $("#reportBtn" + itemId);
                        const modalText = $("#modalTextBtn" + itemId);
                        cancelBtn.css("display", "none");
                        reportBtn.css("display", "none");
                        modalText.css("margin-bottom", "0"); // margin-bottom 제거
                        //modalText.style.marginBottom = "0"; // margin-bottom 제거
                        modalText.css("padding", "0px 40px"); // margin-bottom 제거
                        //modalText.style.padding = "0px 40px"; // margin-bottom 제거
    
    
    
                        setModalIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인 후 이용해 주세요.");
                            logout();
                        } else if (msg === "invalid data") {
                            alert("비정상적인 접근입니다.");
                        } else if(msg === "router err") {
                            alert("서버 에러");
                        }
                        console.log(msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
        } else if (category === "buy") {
            const cancelBtn = $("#cancelBtn" + itemId);
            const reportBtn = $("#reportBtn" + itemId);
            const modalText = $("#modalTextBtn" + itemId);

            cancelBtn.css("display", "none");
            reportBtn.css("display", "none");
            modalText.css("margin-bottom", "0"); // margin-bottom 제거
            //modalText.style.marginBottom = "0"; // margin-bottom 제거
            modalText.css("padding", "0px 40px"); // margin-bottom 제거
            //modalText.style.padding = "0px 40px"; // margin-bottom 제거
            setModalIsMounted(true);
        }

    }

    // 그림 삭제 모달 닫기 버튼
    const onClickCloseModal = (itemId) => {
        if (category === "my") {
            const modal = $("#myModal" + itemId);
            modal.css("display", "none");
        } else if (category === "buy") {
            const modal = $("#drawModal" + itemId);
            modal.css("display", "none");
        }
    }

    // 그림 다운로드 버튼
    const onClickDownBtn = (imgUrl, imgTitle) => {
        downloadFile(imgUrl, imgTitle);
    }

    
    // 그림 정보 관리 버튼
    const onClickDetailBtn = (itemId) => {
        navigate(URL.CREATE_EDIT_PICTURE_INPUT + itemId)
    }

    // ================  페이징 처리  =====================

    const currentPageNo = pageNum;
    const pageSize = 5;
    const totalPageCount = totalPage;

    const currentFirstPage = Math.floor((currentPageNo - 1) / pageSize) * pageSize + 1;
    let currentLastPage = currentFirstPage + pageSize - 1;

    currentLastPage = (currentLastPage > totalPageCount) ? totalPageCount : currentLastPage;

    if (totalPageCount > pageSize) {
        // 첫 페이지 이동
        const firstPageTag = <><PaginationLink onClick={() => {pageCall(1)}}>&lt;&lt;</PaginationLink></>;
        paginationTag.push(firstPageTag);

        // 이전 페이지 이동
        const prevPageIndex = (currentPageNo - 1 > 0) ? currentPageNo - 1 : 1;
        const previousPageTag = <><PaginationLink onClick={() => {pageCall(prevPageIndex)}}>&lt;</PaginationLink></>;
        paginationTag.push(previousPageTag);
    }

    for (let i = currentFirstPage; i <= currentLastPage; i++) {
        if (i === currentPageNo) {
            // 현재 페이지
            const currentPage = <><PaginationLink className={novel1Css.current_page + " current_page"} onClick={() => {pageCall(i)}}>{i}</PaginationLink></>;
            paginationTag.push(currentPage);
        } else {
            // 다른 페이지
            const otherPage = <><PaginationLink onClick={() => {pageCall(i)}}>{i}</PaginationLink></>;
            paginationTag.push(otherPage);
        }
    }
    if (totalPageCount > pageSize) {
        // 다음 페이지 이동
        const nextPageIndex = (currentPageNo + 1 < totalPageCount) ? currentPageNo + 1 : totalPageCount;
        const nextPageTag = <><PaginationLink onClick={() => {pageCall(nextPageIndex)}}>&gt;</PaginationLink></>;
        paginationTag.push(nextPageTag);
        
        // 마지막 페이지 이동
        const lastPageTag = <><PaginationLink onClick={() => {pageCall(totalPage)}}>&gt;&gt;</PaginationLink></>;
        paginationTag.push(lastPageTag);
    }

    const pageCall = (page) => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        const copy = {"section" : category, "page" : page};
        localStorage.setItem("myPicturePageData", JSON.stringify(copy));
        setImgList([]);
        setPageNum(page);
        imgApipage(category, page);
    }

    // =====================  페이징 처리 끝  =================

    // 그림 다운로드 함수
    const downloadFile = (url, title) => {
      
        fetch(url, { method: 'GET' })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = title + ".png";
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 60000);
                a.remove();
            })
            .catch((err) => {
                console.error('err: ', err.message);
            });
    };

    const noImage = (e) => {
        e.target.src = defaultImg
    }


    return (
        <>
            {category === "my" && isMounted === true ? <section className={myPictuerCss.rank_synop + " rank_synop"}>
                {imgList.length === 0 ? <BoxDiv className={myPictuerCss.box + " box"} >
                    <ContentDiv className={myPictuerCss.content + " content"}>
                        <TitleH2 className={myPictuerCss.title + " title"}>내 그림이 없습니다.</TitleH2>
                    </ContentDiv>
                </BoxDiv> : <></>}
                {imgList && imgList.map((img) => {return(<BoxDiv className={myPictuerCss.box + " box"} key={"section1_" + img?.id} >
                    <ImgBoxDiv className={myPictuerCss.imgBox + " imgBox"}>
                        <ImgBoxImgImg src={img?.path} alt='imgList_img' onError={noImage}/>
                    </ImgBoxDiv>
                    <ContentDiv className={myPictuerCss.content + " content"}>
                        <TitleH2 className={myPictuerCss.title + " title"}>{img?.title}</TitleH2>
                        <PriceDiv className={myPictuerCss.price + " price"}>
                            <NameSpan className={myPictuerCss.name + " name"}>판매가</NameSpan>
                            <NumSpan className={myPictuerCss.cum + " num"}>{img?.price}c</NumSpan>
                        </PriceDiv>
                        <ShowDiv className={myPictuerCss.show + " show"}>
                            <ViewDiv className={myPictuerCss.view + " view"}>
                                <span>
                                    <ShowSvg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath={"url(#clip0_1_8098)"}>
                                            <path d="M0.707031 8.49967C0.707031 8.49967 3.54036 2.83301 8.4987 2.83301C13.457 2.83301 16.2904 8.49967 16.2904 8.49967C16.2904 8.49967 13.457 14.1663 8.4987 14.1663C3.54036 14.1663 0.707031 8.49967 0.707031 8.49967Z" stroke={"#050409"} strokeWidth={"1.5"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                            <path d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z" stroke={"#050409"} strokeWidth={"1.5"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_8098">
                                                <rect width="17" height="17" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </ShowSvg>
                                </span>
                                <span>{img?.view}</span>
                            </ViewDiv>
                            <div className={myPictuerCss.cart + " cart"}>
                                <span>
                                    <ShowSvg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath={"url(#clip0_1_8062)"}>
                                            <path d="M6.37435 15.5837C6.76555 15.5837 7.08268 15.2665 7.08268 14.8753C7.08268 14.4841 6.76555 14.167 6.37435 14.167C5.98315 14.167 5.66602 14.4841 5.66602 14.8753C5.66602 15.2665 5.98315 15.5837 6.37435 15.5837Z" stroke={"#050409"} strokeWidth={"1.5"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                            <path d="M14.1673 15.5837C14.5585 15.5837 14.8757 15.2665 14.8757 14.8753C14.8757 14.4841 14.5585 14.167 14.1673 14.167C13.7761 14.167 13.459 14.4841 13.459 14.8753C13.459 15.2665 13.7761 15.5837 14.1673 15.5837Z" stroke={"#050409"} strokeWidth={"1.5"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                            <path d="M0.708984 0.708008H3.54232L5.44065 10.1926C5.50542 10.5187 5.68284 10.8116 5.94183 11.0201C6.20082 11.2286 6.5249 11.3394 6.85732 11.333H13.7423C14.0747 11.3394 14.3988 11.2286 14.6578 11.0201C14.9168 10.8116 15.0942 10.5187 15.159 10.1926L16.2923 4.24967H4.25065" stroke={"#050409"} strokeWidth={"1.5"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_8062">
                                                <rect width="17" height="17" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </ShowSvg>
                                </span>
                                <span>{img?.sold}</span>
                            </div>
                        </ShowDiv>
                        <UserSelectDiv className={myPictuerCss.userSelect + " userSelect"} >
                            <UserSelectButtonButton type="button" id={"manageBtn" + img?.id} onClick={() => {onClickDetailBtn(img?.id)}}><DefaultALink >그림 정보 관리</DefaultALink></UserSelectButtonButton>
                            <UserSelectButtonButton type="button" id={"downloadBtn" + img?.id} onClick={() => {onClickDownBtn(img?.path, img?.title)}}>그림 다운로드</UserSelectButtonButton>
                            <UserSelectDeclareBtnButton type="button" className={myPictuerCss.declareBtn + " declareBtn"}  id={"declareButton" + img?.id} onClick={() => {onClickDeleteBtn(img?.id)}}>
                                <span>그림 삭제</span>
                            </UserSelectDeclareBtnButton>
                        </UserSelectDiv>
                        {modalIsMounted === false ? <ModalDiv id={"myModal" + img?.id} className={myPictuerCss.modal + " modal"}>
                            <ModalContentDiv className={myPictuerCss.modal_content + " modal_content"}>
                                <ModalPP id={"modalText" + img?.id} >그림을 삭제하시겠습니까?</ModalPP>
                                <div className={myPictuerCss.selectBtn + " selectBtn"}>
                                    <ModalCancelBtnButton id={"cancelBtn" + img?.id} onClick={() => {onClickCancelBtn(img?.id)}}>취소</ModalCancelBtnButton>
                                    <ModalReportBtnButton id={"reportBtn" + img?.id} onClick={() => {onClickReportBtn(img?.id)}}>삭제</ModalReportBtnButton>
                                </div>
                            </ModalContentDiv>
                        </ModalDiv>: <ModalDiv id={"myModal" + img?.id} className={myPictuerCss.modal + " modal"} onClick={() => {onClickCloseModal(img?.id)}}>
                            <ModalContentDiv className={myPictuerCss.modal_content + " modal_content"}>
                                <ModalPP id={"modalText" + img?.id} >그림이 삭제 되었습니다.</ModalPP>
                            </ModalContentDiv>
                        </ModalDiv>}
                    </ContentDiv>
                </BoxDiv>)})}
                <PaginationDiv className={novel1Css.pagination + " pagination"}>
                    {paginationTag}
                </PaginationDiv>
            </section> : <></>}
            {category === "buy" && isMounted === true ? <section className={myPictuerCss.rank_synop + " rank_synop"}>
                {imgList && imgList.map((img) => {return(<BoxDiv className={myPictuerCss.box + " box"} key={"section2_" + img?.id}>
                    <div className={myPictuerCss.imgBox + " imgBox"}>
                        {img?.imgUrl !== "" ? img?.imgUrl !== null ? <img src={img?.imgUrl} alt={`img_${img?.id}`} onError={noImage}/> : <img src={defaultImg} alt='defaultImg'/> : <img src={defaultImg} alt='defaultImg'/>}
                    </div>
                    <ContentDiv className={myPictuerCss.content + " content"}>
                        <TitleH2 className={myPictuerCss.title + " title"}>{img?.title}</TitleH2>
                        <PriceDiv className={myPictuerCss.price + " price"}>
                            <NameSpan className={myPictuerCss.name + " name"}>구매가</NameSpan>
                            <NumSpan className={myPictuerCss.num + " num"}>{img?.price}c</NumSpan>
                        </PriceDiv>
                        <UserSelectDiv className={myPictuerCss.userSelect + " userSelect"}>
                            <UserSelectButtonButton type="button" id={"downloadBtn" + img?.id} onClick={() => {onClickDownBtn(img?.imgUrl, img?.title)}}>그림 다운로드</UserSelectButtonButton>
                            {/*<UserSelectDeleteBtnButton type="button" className={myPictuerCss.deleteBtn + " deleteBtn"}  id={"deleteButton"+img.id} onClick={() => {onClickDeleteBtn(img.id)}}>
                                <span>그림 삭제</span>
                            </UserSelectDeleteBtnButton>*/}
                        </UserSelectDiv>
                        {modalIsMounted === false ? <ModalDiv id={"drawModal" + img?.id} className={myPictuerCss.modal + " modal"}>
                            <ModalContentDiv className={myPictuerCss.modal_content + " modal_content"}>
                                <ModalPP id={"modalText" + img?.id}>그림을 삭제하시겠습니까?</ModalPP>
                                <div className={myPictuerCss.selectBtn + " selectBtn"}>
                                    <ModalCancelBtnButton id={"cancelButton" + img?.id} onClick={() => {onClickCancelBtn(img?.id)}}>취소</ModalCancelBtnButton>
                                    <ModalReportBtnButton id={"reportButton" + img?.id} onClick={() => {onClickReportBtn(img?.id)}}>삭제</ModalReportBtnButton>
                                </div>
                            </ModalContentDiv>
                        </ModalDiv> : <ModalDiv id={"drawModal" + img?.id} className={myPictuerCss.modal + " modal"} onClick={() => {onClickCloseModal(img?.id)}}>
                            <ModalContentDiv className={myPictuerCss.modal_content + " modal_content"}>
                                <ModalPP id={"modalText" + img?.id}>그림이 삭제 되었습니다.</ModalPP>
                            </ModalContentDiv>
                        </ModalDiv>}
                    </ContentDiv>
                </BoxDiv> )})}
                <PaginationDiv className={novel1Css.pagination + " pagination"}>
                    {paginationTag}
                </PaginationDiv>
            </section> : <></>}
        </>
    )
};
export default MyPictureSection;


/** div 태그 .imgBox css */
const ImgBoxDiv = styled.div`
    width: 140px;
    height: 224px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

/** img 태그 .imgBox > img css */
const ImgBoxImgImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

/** div 태그 .box css */
const BoxDiv = styled.div`
    width: 100%;
    padding: 32px;
    border: 1px solid #EDEDED;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin-bottom: 20px;
    &:last-of-type {
        margin-bottom: 120px;
    }
`;

/** div 태그 .content css */
const ContentDiv = styled.div`
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    @media(max-width:568px) {
        padding-top: 0;
    }
`;

/** h2 태그 .title css */
const TitleH2 = styled.h2`
    margin: 0;
    padding: 0;
    padding-bottom: 6px;
    font-size: 20px;
    font-family: "min sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    padding-bottom: 6px;
    @media(max-width:568px) {
        font-size: 16px;
        font-family: "min sans-bold";
        text-overflow: ellipsis;
    }
`;

/** div 태그 .price css */
const PriceDiv = styled.div`
    color: #FF5F37;
    font-family: "min sans-bold";
    letter-spacing: -0.3px;
    padding-bottom: 16px;
`;

/** span 태그 .name css */
const NameSpan = styled.span`
    font-size: 14px;
    padding-right: 4px;
`;

/** span 태그 .num css */
const NumSpan = styled.span`
    font-size: 20px;
`;

/** div 태그 .show css */
const ShowDiv = styled.div`
    display: flex;
    color: #050409;
    font-size: 14px;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
`;

/** svg 태그 .show css */
const ShowSvg = styled.svg`
    vertical-align: middle;
    margin-right: 3px;
`;

/** div 태그 .view css */
const ViewDiv = styled.div`
    margin-right: 12px;
`;

/** div 태그 .userSelect css */
const UserSelectDiv = styled.div`
    display: flex;
    gap: 16px;
    padding-top: 20px;
    @media(max-width:568px) {
        flex-direction: column;
    }
`;

/** button 태그 .userSelect button css */
const UserSelectButtonButton = styled.button`
    border:none;
    background-color: #F6F6F6;
    border-radius: 21px;
    padding: 11px 20px;
    font-family: "min sans-medium";
    letter-spacing: -0.3px;
    font-size: 15px;
    cursor: pointer;
    &:hover{
        background-color: #22222A;
        color: #fff;
    }
    @media(max-width:568px) {
        padding: 11px 16px;
        max-width: 120px;
    }
`;

/** button 태그 .userSelect button .declareBtn css */
const UserSelectDeclareBtnButton = styled.button`
    border:none;
    background-color: #F6F6F6;
    border-radius: 21px;
    padding: 11px 20px;
    font-family: "min sans-medium";
    letter-spacing: -0.3px;
    font-size: 15px;
    cursor: pointer;
    color:#A8A8A9;
    &:hover{
        background-color: #22222A;
        color: #fff;
    }
    @media(max-width:568px) {
        padding: 11px 16px;
        max-width: 120px;
    }
`;

/** div 태그 .modal css */
const ModalDiv = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 배경색 및 투명도 설정 */
    z-index: 1; /* 모달을 다른 요소 위에 표시 */
`;

/** div 태그 .modal_content css */
const ModalContentDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 20px;
    max-width: 330px;
`;

/** p 태그 .modal p css */
const ModalPP = styled.p`
    font-size: 18px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    margin-bottom: 24px;
`;

/** button 태그 .modal #cancelBtn css */
const ModalCancelBtnButton = styled.button`
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: #22222A;
    &:hover {opacity: 0.5;}
`;

/** button 태그 .modal #reportBtn css */
const ModalReportBtnButton = styled.button`
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
    background-color: #FF5F37;
    color: #fff;
    &:hover {opacity: 0.5;}
`;

/** div 태그 .pagination */
const PaginationDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
`;

/** a 태그 .pagination a css */
const PaginationLink = styled(Link)`
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    margin: 5px;
    padding: 5px 10px;
    color: #333;
    font-family: "Min Sans-Light";
    font-size: 14px;
    &:hover {
        background-color: #fff;
        color: #ff5f37;
    };
`;

