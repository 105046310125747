import UserTypeChange from "../../components/sign/UserTypeChange";
import Header from "../../components/common/Header";
import Sign1 from "../../components/sign/Sign1";
import Sign2 from "../../components/sign/Sign2";
import URL from "../../constants/url";
import { Route, Routes } from "react-router";
import UserAccountChange from "components/sign/UserAccountChange";


const AizacSign = () => {

    return (
        <>
            <Header/>       
            <Routes>
                <Route path={URL.TERMS} element={<Sign1/>}/>
                <Route path={URL.SIGN_INPUT} element={<Sign2/>}/>
                <Route path={URL.USER_TYPE} element={<UserTypeChange/>}/>
                <Route path={URL.ACCOUNT_CHANGE} element={<UserAccountChange/>}/>
            </Routes>
        </>
    );
}
export default AizacSign;