import { Link, useNavigate } from "react-router-dom";
import sign2Css from '../../css/sign2.module.css'
import styled from "@emotion/styled";
import { getCookie, removeCookie, removeCookieOption, setCookieOption } from "../../hooks/Cookie";
import URL from "../../constants/url";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { apiServerState, businessServiceKeyState } from "../../constants/recoilData";
import novel2Css from'../../css/novelCreate2_2.module.css';
import $ from 'jquery';
import join_2Css from '../../css/join_2.module.css';
import defaultImg from '../../css/images/illustDefault.png'


const UserTypeChange = () => {

    const navigate = useNavigate();
    const apiServer = useRecoilValue(apiServerState);
    const [userInfo, setUserInfo] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [proficiency, setProficiency] = useState(null);
    const [img, setImg] = useState(null);

    const token = getCookie('token');

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 토큰 검사 후 유저정보 셋팅
    useEffect(() => {
        if (token === undefined) {
            navigate(URL.MAIN);
        }
        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        const urlUser = apiServer + "/api/asiac_login/get_my_info";

        const userInfo = async () => {
            await axios
            .get(urlUser, config)
            .then((response) => {
                if (response?.data?.success ) {
                    setUserInfo(response?.data?.data?.user);
                    setImg(response?.data?.data?.user?.path);
                    setIsMounted(true);
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else if (msg === "router err") {
                        alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        navigate(URL.MAIN);
                    } else if (msg === "invalid user") {
                        alert("다시 로그인해주세요.");
                        logout();
                        navigate(URL.LOGIN);
                    } else {
                        alert(msg);
                        navigate(URL.MAIN);
                    }
                    console.log("로딩 실패");
                    console.log(msg);
                }
            })
            .catch(e => {  // API 호출이 실패한 경우
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });
        };
        if(token !== undefined) {
            userInfo();
        }
    }, [])

    // 유저정보 셋팅 후 각 입력란 셋팅 및 유지
    useEffect(() => {
        if(userInfo !== null) {
            const ikind = userInfo?.ikind;
            switch (ikind) {
                case "normal" : {
                    $('.actionBtn').removeClass("active");
                    $('.writerBtn').removeClass("active");
                    $("#type1").addClass("active");
                    authorKindTypeRef.current.style.display = "none";
                    coRegNoRef.current.style.display = "none";
                    corpRegNoRef.current.style.display = "none";
                    fileRef.current.style.display = "none";
                    setIkind("normal");
                    setAuthorKind("");
                    setCoRegNo("");
                    setCorpRegNo("");
                    break;
                }
                case "author" : {
                    $('.actionBtn').removeClass("active");
                    $("#type0").addClass("active");
                    authorKindTypeRef.current.style.display = "block";
                    setIkind("author");
                    break;
                }
            }

            if (ikind === "author") {
                const authorKind = userInfo?.authorKind;
                switch (authorKind) {
                    case "solo_free" : {
                        $('.writerBtn').removeClass("active");
                        $("#writer0").addClass("active");
                        coRegNoRef.current.style.display = "none";
                        corpRegNoRef.current.style.display = "none";
                        fileRef.current.style.display = "none";
                        setAuthorKind("solo_free");
                        break;
                    }
                    case "solo_proprietor" : {
                        $('.writerBtn').removeClass("active");
                        $("#writer1").addClass("active");
                        coRegNoRef.current.style.display = "block";
                        corpRegNoRef.current.style.display = "none";
                        fileRef.current.style.display = "block";
                        setAuthorKind("solo_proprietor");
                        break;
                    }
                    case "corp_business" : {
                        $('.writerBtn').removeClass("active");
                        $("#writer2").addClass("active");
                        coRegNoRef.current.style.display = "block";
                        corpRegNoRef.current.style.display = "block";
                        fileRef.current.style.display = "block";
                        setAuthorKind("corp_business");
                        break;
                    }
                }

                if (authorKind === "solo_proprietor") {
                    setCoRegNo(userInfo?.coRegNo);
                } else if (authorKind === "corp_business") {
                    setCoRegNo(userInfo?.coRegNo);
                    setCorpRegNo(userInfo?.corpRegNo);
                }
            }
        }
    }, [userInfo])

    // 저장 버튼
    const onClickChange = () => {
        var formData = new FormData();
        if (ikind === null) {
            alert("가입 유형을 선택해주세요.");
            return;
        } else {
            if (authorKind === "solo_proprietor") {
                if(coRegNoCheck === false) {
                    alert("사업자 등록번호 인증검사를 해주세요.");
                    return;
                }
                if(img === null && accountImgFile === null) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;
                } else if(img === null && accountImgFile === undefined) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;
                }
                if(img === undefined && accountImgFile === null) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;    
                } else if(img === undefined && accountImgFile === undefined) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;    
                }
            } else if (authorKind === "corp_business") {
                if(coRegNoCheck === false) {
                    alert("사업자 등록번호 인증검사를 해주세요.");
                    return;
                }
                if(img === null && accountImgFile === null) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;
                } else if(img === null && accountImgFile === undefined) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;
                }
                if(img === undefined && accountImgFile === null) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;    
                } if(img === undefined && accountImgFile === undefined) {
                    alert("사업자 통장 사본을 입력해주세요.");
                    return;    
                }
            }
        }

        if(ikind === "normal") {
            formData.append("ikind", ikind);
            formData.append("bDeleteImg", "true");
        } else {
            if(authorKind === "solo_free") {
                formData.append("ikind", ikind);
                formData.append("authorKind", authorKind);
                formData.append("proficiency", proficiency);
                formData.append("bDeleteImg", "true");
            } else if (authorKind === "solo_proprietor") {
                formData.append("ikind", ikind);
                formData.append("authorKind", authorKind);
                if(coRegNo === "") {
                    alert("사업자 번호를 입력해주세요.");
                    return;
                } else if (coRegNo === null || coRegNo === undefined) {
                    alert("사업자 번호를 입력해주세요.");
                    return;
                }
                formData.append("coRegNo", coRegNo);
                formData.append("proficiency", proficiency);
                if (accountImgFile !== null) {
                    formData.append("image", accountImgFile);
                }
                formData.append("bDeleteImg", "false");
            } else if (authorKind === "corp_business") {
                const corpRegNoCheck = (String(corpRegNo)).trim();
                if (corpRegNoCheck === "") {
                    alert("법인 이름을 입력해주세요.");
                    return;
                }
                if(coRegNo === "") {
                    alert("사업자 번호를 입력해주세요.");
                    return;
                } else if (coRegNo === null || coRegNo === undefined) {
                    alert("사업자 번호를 입력해주세요.");
                    return;
                }
                if(corpRegNo === "") {
                    alert("법인 이름을 입력해주세요.");
                    return;
                } else if (corpRegNo === null || corpRegNo === undefined) {
                    alert("법인 이름을 입력해주세요.");
                    return;
                }
                formData.append("ikind", ikind);
                formData.append("authorKind", authorKind);
                formData.append("coRegNo", coRegNo);
                formData.append("corpRegNo", corpRegNo);
                if(accountImgFile !== null) {
                    formData.append("image", accountImgFile);
                }
                formData.append("bDeleteImg", "false");
            } else {
                alert("작가 유형을 선택해주세요.");
                return;
            }
        }
        
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "authorization": token,
            }
        };

        axios
        .post(apiServer + "/api/asiac_login/update_author_kind", formData, config)
        .then((response) => {
            if (response?.data?.success ) {
                alert("정상적으로 변경되었습니다.");
                const option = {path : ""};
                setCookieOption("token", response.data.data.userToken, option);
                navigate(URL.MY_PAGE_MY_INFO);
            } else {
                const msg = response?.data?.custMsg;
                if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                    alert("다시 로그인 후 이용해 주세요.");
                    logout();
                } else if(msg === 'invalid data') {
                    setErrorMessage("가입 유형을 선택해주세요.")
                } else if (msg === 'invalid user') {
                    setErrorMessage("잘못된 유저거나 탈퇴한 유저입니다.")
                } else if (msg === 'router err') {
                    setErrorMessage("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.")
                }
                console.log(msg);
            }
        })
        .catch((e) => {
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });
    }
    const [ikind, setIkind] = useState(null);
    const typeBtnRef = useRef([]);

    // 가입 유형 선택 버튼
    const onClickActionBtn = (e) => {
        $('.actionBtn').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setIkind(val);
        if (val === "author") {
            authorKindTypeRef.current.style.display = "block";
        } else {
            $('.writerBtn').removeClass("active");
            authorKindTypeRef.current.style.display = "none";
            coRegNoRef.current.style.display = "none";
            corpRegNoRef.current.style.display = "none";
            fileRef.current.style.display = "none";
            setAuthorKind("");
            setCoRegNo("");
            setCorpRegNo("");
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("novice");
        }
    }

    const [authorKind, setAuthorKind] = useState(null);
    const authorKindTypeRef = useRef();
    const authorKindBtnRef = useRef([]);

    // 작가 유형 선택 버튼
    const onClickWriterTypeBtn = (e) => {
        $('.writerBtn').removeClass("active");
        e.target.classList.add("active");
        const val = e.target.value;
        setAuthorKind(val);
        if (val === "solo_proprietor") {
            coRegNoRef.current.style.display = "block";
            corpRegNoRef.current.style.display = "none";
            fileRef.current.style.display = "block";
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("veteran");
        } else if (val === "corp_business") {
            coRegNoRef.current.style.display = "block";
            corpRegNoRef.current.style.display = "block";
            fileRef.current.style.display = "block";
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("veteran");
        } else {
            coRegNoRef.current.style.display = "none";
            corpRegNoRef.current.style.display = "none";
            fileRef.current.style.display = "none";
            setCoRegNoCheck(false);
            setCoRegNoCheckMessage('');
            setProficiency("novice");
        }
    }


    const [coRegNo, setCoRegNo] = useState('');
    const coRegNoRef = useRef();

    // 사업자 번호 변경 입력 함수
    const onChangecoRegNo = (e) => {
        setCoRegNo(e.target.value);
        setCoRegNoCheck(false);
    };

    const [coRegNoCheck, setCoRegNoCheck] = useState(false);
    const [coRegNoCheckMessage, setCoRegNoCheckMessage] = useState('');
    const serviceKey = useRecoilValue(businessServiceKeyState);

    // 사업자 번호 유효성 검사
    const onClickCoRegNoCheckCheck = () => {
        let form;
        if (coRegNo === null) {
            alert("사업자 등록번호를 입력해주세요.");
            return;
        } else if (coRegNo === "") {
            alert("사업자 등록번호를 입력해주세요.");
            return;
        } else {
            form = {
                "b_no": [
                    coRegNo
                ]
            }
        }
    
        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };

        const url = "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=" + serviceKey

        axios
        .post(url, form, config)
        .then((response) => {
            if (response?.data?.status_code === "OK") {
                const b_stt_cd = response?.data?.data[0].b_stt_cd;
                if(b_stt_cd === "01") {
                    setCoRegNoCheckMessage("사용가능한 사업자 번호 입니다.")
                    setCoRegNoCheck(true);
                } else if (b_stt_cd === "02") {
                    setCoRegNoCheckMessage("휴업 사업자 번호로 사용불가한 사업자 번호 입니다.");
                    setCoRegNoCheck(false);
                } else if (b_stt_cd === "03") {
                    setCoRegNoCheckMessage("폐업 사업자 번호로 사용불가한 사업자 번호 입니다.");
                    setCoRegNoCheck(false);
                } else if (b_stt_cd === "") {
                    setCoRegNoCheckMessage("국세청에 등록되지 않은 사업자등록번호입니다.");
                    setCoRegNoCheck(false);
                }
            } else {
                setCoRegNoCheckMessage("사용불가 사업자 번호 입니다.");
                setCoRegNoCheck(false);
                console.log(response?.data?.custMsg);
            }
        })
        .catch((e) => {
            alert(e.message);
            console.log(e);
            navigate(URL.ERROR, {state: {msg: e.message}});
        });

    }

    const [corpRegNo, setCorpRegNo] = useState('');
    const corpRegNoRef = useRef();
    // 법인 이름 변경 입력 함수
    const onChangeCorporationNumber = (e) => {
        setCorpRegNo(e.target.value);
    }


    // 사업자 번호 정규식 체크(숫자)
    const coRegNoRegExCheck = (e) => {
        setCoRegNoCheckMessage('');
        const coRegNoRegEx = new RegExp(/^[0-9]+$/)
        const coRegNo = e.target.value;
        const bool = coRegNoRegEx.test(coRegNo);
        if (bool) {
            setCoRegNoCheckMessage("정상적인 사업자 번호 양식입니다.");
        } else {
            setCoRegNoCheckMessage("사업자 번호는 숫자만 가능합니다.");
        }
        if (coRegNo === '') {
            setCoRegNoCheckMessage('');
        }
    }

    // 새로고침 막기 변수
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[]);

    const fileRef = useRef();

    const [accountImgFile, setAccountImgFile] = useState(null);

    // 사업자 통장 사본 이미지 파일 업로드
    const onChangeUpdateFileName = () => {
        var input = $("#file-upload")[0];
        var fileName = $("#file-name")[0];
        var fileImg = $("#file_img");
        
        if(input.files && input.files[0]) {
            var reader = new FileReader();
            
            reader.onload = function (e) {
                if (input.files[0].type.includes("image")) {
                    fileImg.attr("src", e.target.result);
                    setImg(e.target.result);
                    setAccountImgFile(input.files[0]);
                }
                
                fileName.innerHTML = input.files[0].name;
            };
            
            reader.readAsDataURL(input.files[0]);
        } else {
            fileName.innerHTML = "파일을 업로드해주세요";
        }
    }

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return(
        <>
            {isMounted !== false ? <div id="wrap">
                <JoinContainerDiv id="joinContainer">
                    <JoinBoxDiv className={sign2Css.joinBox + " joinBox"}>
                        <TitleDiv className={sign2Css.title + " title"}>
                            <TitleH2H2>계정 유형 변경</TitleH2H2>
                        </TitleDiv>
                        <UserInputDiv className={sign2Css.userInput + " userInput"}>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} >
                                <div className={sign2Css.descript + " descript"}>
                                    <LobelLabel htmlFor="nickname" className={sign2Css.label + " label"}>가입 유형을 선택해주세요</LobelLabel>
                                    <DescriptSpanSpan>유형은 마이페이지에서 수정하실 수 있습니다.</DescriptSpanSpan>
                                </div>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn"} id={"type0"} ref= {(el) => {typeBtnRef.current[0] = el}} value={"author"} onClick={(e) => {onClickActionBtn(e)}} >작가로 가입해요.</CategoryBtnButton>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " actionBtn"} id={"type1"} ref= {(el) => {typeBtnRef.current[1] = el}} value={"normal"} onClick={(e) => {onClickActionBtn(e)}} >독자로 가입해요.</CategoryBtnButton>
                                </OverlapDiv>
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(el) => authorKindTypeRef.current = el} style={{display : "none"}}>
                                <div className={sign2Css.descript + " descript"}>
                                    <LobelLabel htmlFor="nickname" className={sign2Css.label + " label"}>작가 유형을 선택해주세요</LobelLabel>
                                </div>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " writerBtn"} id={"writer0"} ref= {(el) => {authorKindBtnRef.current[0] = el}} value={"solo_free"} onClick={(e) => {onClickWriterTypeBtn(e)}} >개인 프리랜서</CategoryBtnButton>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " writerBtn"} id={"writer1"} ref= {(el) => {authorKindBtnRef.current[1] = el}} value={"solo_proprietor"} onClick={(e) => {onClickWriterTypeBtn(e)}} >개인 사업자</CategoryBtnButton>
                                    <CategoryBtnButton className={novel2Css.actionBtn + " writerBtn"} id={"writer2"} ref= {(el) => {authorKindBtnRef.current[2] = el}} value={"corp_business"} onClick={(e) => {onClickWriterTypeBtn(e)}} >법인 사업자</CategoryBtnButton>
                                </OverlapDiv>
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(el) => {coRegNoRef.current = el}} style={{display:"none", marginTop : "0px"}}>
                                <LabelEmailLabel htmlFor="private" className={sign2Css.label + " label"}>사업자 번호를 입력해주세요.</LabelEmailLabel>
                                <DescriptSpanSpan>숫자만 입력해주세요.</DescriptSpanSpan>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <UserInputTextInput type="text" id="privateBusinessNumber" placeholder="사업자 번호를 입력해주세요." onChange={(e) => {onChangecoRegNo(e); coRegNoRegExCheck(e)}} value={coRegNo}/>
                                    <CheckButtonButton id="privateBusinessNumberCheck" className={sign2Css.checkButton + " checkButton"} onClick={onClickCoRegNoCheckCheck}>인증검사</CheckButtonButton>
                                </OverlapDiv>
                                {coRegNoCheckMessage !== '' ?<div className={sign2Css.descript + " descript"} >
                                    {coRegNoCheckMessage === '사용가능한 사업자 번호 입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{coRegNoCheckMessage}</DescriptSpanSpan> : 
                                    coRegNoCheckMessage === '정상적인 사업자 번호 양식입니다.' ? <DescriptSpanSpan style={{color : 'green'}}>{coRegNoCheckMessage}</DescriptSpanSpan> :
                                    <DescriptSpanSpan>{coRegNoCheckMessage}</DescriptSpanSpan>}
                                </div> : <></>}
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(el) => {corpRegNoRef.current = el}} style={{display:"none"}}>
                                <LabelEmailLabel htmlFor="private" className={sign2Css.label + " label"}>법인 이름을 입력해주세요.</LabelEmailLabel>
                                <OverlapDiv className={sign2Css.overlap + " overlap"}>
                                    <UserInputTextInput type="text" id="corporationNumber" style={{width : "100%"}} placeholder="법인 이름을 입력해주세요." onChange={(e) => {onChangeCorporationNumber(e)}} value={corpRegNo}/>
                                </OverlapDiv>
                            </InputRowDiv>
                            <InputRowDiv className={sign2Css.inputRow + " inputRow"} ref={(e) => {fileRef.current = e}} style={{display : "none", marginBottom : "0px"}}>
                                <div className={sign2Css.descript + " descript"}>
                                    <label htmlFor="nickname" className={sign2Css.label + " label"}>사업자 통장 사본을 업로드해주세요</label>
                                </div>
                                <div className={sign2Css.overlap + " overlap"}>
                                    <div className={join_2Css.file_upload_wrapper + " file_upload_wrapper file-upload-wrapper"} style={{display: "flex"}}>
                                        <label htmlFor="file-upload" className={join_2Css.custom_file_upload + " custom_file_upload custom-file-upload"}>
                                            파일업로드
                                        </label>
                                        <FileInput
                                            id="file-upload"
                                            type="file"
                                            name="file-upload"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            onChange={onChangeUpdateFileName}
                                        />
                                        <UploadInstructionDiv id="file-name" className="upload-instruction">
                                            파일을 업로드해주세요
                                        </UploadInstructionDiv>
                                    </div>
                                </div>
                                <div id="file-preview" style={{marginTop: "12px"}}>
                                    {(img !== null) && img !== "" ? <img src={img} alt="img" onError={noImage}/> : <img src="" id="file_img" alt="" style={{display : "none"}}/>}
                                </div>
                            </InputRowDiv>
                            {errorMessage !== '' ? <div className={sign2Css.descript + " descript"}>
                                <DescriptSpanSpan >{errorMessage}</DescriptSpanSpan>
                            </div> : <></>}
                        </UserInputDiv>
                        <SaveNextBtnButton type="submit" className={sign2Css.saveNextBtn + " saveNextBtn"} onClick={onClickChange}><DefaultALink>저장 </DefaultALink></SaveNextBtnButton>
                    </JoinBoxDiv>
                </JoinContainerDiv>
            </div> : <></>}
        </>
    );
};
export default UserTypeChange;

/** div 태그 #joinContainer css */
const JoinContainerDiv = styled.div`
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
`;

/** div 태그 .joinBox css */
const JoinBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    @media (max-width: 1400px) {
        padding-top: 120px;
    }
    @media(max-width:568px) {
        padding-top: 70px;
    }
`;

/** div 태그 .title css */
const TitleDiv = styled.div`
    text-align: center;
    margin-bottom: 82px;  
    @media(max-width:568px) {
        margin-bottom: 62px;
    }
`;


/** h2 태그 .title h2 css */
const TitleH2H2 = styled.h2`
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
    @media(max-width:568px) {
        font-size: 36px;
    }
`;

/** div 태그 .userInput css */
const UserInputDiv = styled.div`
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    @media(max-width:568px) {
        margin: 0;
    }
`;



/** input 태그 .userInput type="text" input css */
const UserInputTextInput = styled.input`
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    &::placeholder{
        font-family: "Min Sans-regular";
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #828284;
    }
    width: 80%;
`;

/** div 태그 .inputRow css */
const InputRowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-of-type{
        margin-bottom: 0;
    }
    &:nth-of-type(2){
        margin-bottom: 42px;
    }
    @media(max-width:568px) {
        margin-bottom: 24px;
        &:nth-of-type(2){
            margin-bottom: 0;
        }
    }
`;

/** label 태그 .label css */
const LobelLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
`;

/** label 태그 .label label[for="email"] css */
const LabelEmailLabel = styled.label`
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    margin-top: 42px;
    @media(max-width:568px){
        margin-top: 0;
    }
`;

/** span 태그 .descript span css */
const DescriptSpanSpan = styled.span`
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;
`;

/** div 태그 .overlap css */
const OverlapDiv = styled.div`
    display: flex;
`;

/** button 태그 .checkButton css */
const CheckButtonButton = styled.button`
    margin-left: 0px;
    border: none;
    background-color: #ff562c;
    padding: 18px 22px;
    color: #fff;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    &:hover{
        background-color: #ff7957;
    }
`;



/**button 태그 .saveNextBtn css */
const SaveNextBtnButton = styled.button`
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 52px;
    margin-bottom: 24px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #303039;
    }
    @media (max-width: 1400px) {
        margin-bottom: 120px;
    }
    @media (max-width: 560px) {
        margin-top: 42px;
        margin-bottom: 40px;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;


/** button 태그 .category-btn > button css */
const CategoryBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 8px 20px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: rgba(130, 130, 132, 1);
    border: 1px solid #bdbdbd;
    background-color: #fff;
    margin-right: 6px;
    width: 50%;
    height: 4rem;
    cursor: pointer;
    &:hover {
        border: 1px solid #ff562c;
        background-color: #ff562c;
        color: #fff;
    }
    &.active {
        border: 1px solid #ff562c;
        color: #fff;
        background-color: #ff562c;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        font-size: 13px;
        padding: 8px 12px;
        margin-right: 4px;
    }
`;

/** div 태그 .upload-instruction css  */
const UploadInstructionDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #dcdcdc;
    padding: 6px 12px;
    margin-left: -5px;
    background: #f0f0f0;
    height: 60px;
    width: 375px;
    color: #828284;
    @media (max-width: 568px) {
        width: auto;
    }
`;

/** input 태그 file input css */
const FileInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;