import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const { useState, useEffect } = require("react")


const BottomButton = () => {
    const [showButton, setShowButton] = useState(false);

    const root = document.getElementById("root");
    const scrollHeight = root.scrollHeight;

    // 탑 버튼 클릭
    const scrollTobottom = () => {
        window.scrollTo({
            top: scrollHeight,
            behavior: 'instant'
        })
    }

    // 최초 렌더링시 버튼을 보이기 위한 조건
    useEffect(() => {
        const showButtonClick = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        }
        window.addEventListener("scroll", showButtonClick);
        return () => {
            window.addEventListener("scroll", showButtonClick);
        }
    }, [])

    return (
        <>
            {showButton && 
                <BottomButtonButton type="button" onClick={scrollTobottom}>
                    <Link class="fa-solid fa-arrow-down" style={{color: "#ffffff"}}></Link>
                </BottomButtonButton>
            }
        </>
    )

}

export default BottomButton;


/** div 태그 */
const BottomButtonButton = styled.button`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    text-decoration: none;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    background-color : #5A5A5A;
    opacity: 0.2;
    :hover {
        background: #ff5f37;
        color: #fff;
        border: 1px solid #ff5f37;
    };
    padding: 8px 16px;
    font-size: 14px;
    position: fixed;
    bottom: 30px;
    right: 30px;
`;