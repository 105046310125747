import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import veteranCss from '../../css/veteran.module.css'
import DefaultImg from '../../css/images/illustDefault.png'


const ColNovel = ({novelData, type}) => {
    
    const navigate = useNavigate();

    const onClickNovelDetail = (id) => {
        navigate(`/list/novel/${id}`)
    }

    const noImage = (e) => {
        e.target.src = DefaultImg
    }

    return (
        <>
            <ColDiv className={veteranCss.col + " col"} key={`${type}_${novelData?.id}_`} onClick={() => {onClickNovelDetail(novelData?.id)}}>
                <DefaultLinkA>
                    <NovelViewDiv className={veteranCss.novel_view + " p-3 novel_view"}>
                        {novelData?.imgUrl !== null ? <NovelViewImgImg src={novelData?.imgUrl} alt={`${type.type}_${novelData?.id}`} onError={noImage}/> : <NovelViewImgImg src={DefaultImg} alt="defaultImg"/>}
                        {(String(novelData?.title)).length < 15 ? <NovelViewTitleDiv className={veteranCss.novel_view_title + " novel_view_title"}>{(String(novelData?.title)).trim()}</NovelViewTitleDiv> :
                        <NovelViewTitleDiv className={veteranCss.novel_view_title + " novel_view_title"}>{((String(novelData?.title)).trim()).substring(0,13) + " ..."}</NovelViewTitleDiv>}
                        <NovelViewSubDiv className={veteranCss.novel_view_sub + " novel_view_sub"}>
                            <DefaultSpan>총 {novelData?.episodeCnt}화  </DefaultSpan><DefaultSpan className={veteranCss.username + " username"}>{novelData?.nickname} 작가</DefaultSpan>
                        </NovelViewSubDiv>
                    </NovelViewDiv>
                </DefaultLinkA>
            </ColDiv>
        </>
    );
};

export default ColNovel;

/** div 태그 .col css */
const ColDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 0px;
`; 

/** a 태그 디폴트 css */
const DefaultLinkA = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;

`;

/** div 태그 .novel-view css */
const NovelViewDiv = styled.div`
    width: 270px;
    height: 450px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: 1100px) and (max-width: 1400px) {
        width: auto;
        height: 370px;
    }
    @media (max-width: 1100px) {
        width: 208px;
        height: 370px;
    }
    @media (min-width: 100px) and (max-width: 359px) {
        width: 140px;
        height: 370px;
    }
`;

/** img 태그 .novel-view > img css */
const NovelViewImgImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    @media (min-width: 360px) and (max-width: 400px) {
        width: 90%;
    }
`;

/** div 태그 .novel-view-title css */
const NovelViewTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    font-size: 17px;
    font-family: "Min Sans-Medium";
    color: #050409;
    letter-spacing: -0.3px;
    margin-top: 12px;
`;

/** div 태그 .novel-view-sub css */
const NovelViewSubDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    font-size: 14px;
    font-family: "Min Sans-Regular";
    color: #9b9b9d;
    letter-spacing: -0.3px;
`;

/** span 태그 디폴트 css */
const DefaultSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
`;