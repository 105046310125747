

import URL from "../../constants/url";
import { getCookie } from "../../hooks/Cookie";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";




// url 변경시 직접이동 컴포넌트
const PassAiEditor = () => {

    const params = useParams();
    const [editorId,] = useState(params.editorId);
    const navigate = useNavigate();

    useEffect(() => {

        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {

        
            const update = () => {
            
                const editor = async () => {
                    navigate(URL.AI_EDITOR_NOVEL_EDIT_INPUT + editorId);
                };
            
                editor();
                
            };
            update();
        }

    }, [])

    return (
        <>
        </>
    )
}

export default PassAiEditor;